import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel } from 'react-bootstrap';
import { OrderBlockWidget } from '../../Widgets/OrderBlockWidget';
import { useTranslation } from 'react-i18next';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../../../../../utils/vlStrings';
import PromoCodeIcon from '../images/promo_code_icon.svg';
import RedeemCode from '../../../../Modal/Promotions/RedeemCode';
import { applyPromotion } from '../../../../../store/reducers/ordering';
import { convertToPrice } from '../../../../../utils/rhutils';
import userManager from '../../../../../managers/userManager';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'center',
    letterSpacing: '-0.41px',
  },
  detail: {
    color: '#C8CACF',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'center',
  },
  container: {
    background: '#3A2D5C',
    borderRadius: '24px',
    height: '72px',
  },
  icon: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    background: '#FFFFFF1A',
  },
}));

const OrderAddPromoCodeBlock = (props) => {
  const { user, isBusiness, creator, applyPromotion } = props;
  const [showRedeemCode, setShowRedeemCode] = useState(false);
  const [promotion, setPromotion] = useState(undefined);
  const classes = useStyles();
  const { t } = useTranslation();
  const hebrew = isHebrew();
  const currentUserCurrency = userManager.currentUserCurrency();

  const title = promotion
    ? promotion.code.toUpperCase()
    : t('add_promo_code_title');
  const detail = promotion
    ? t('promo_code_redeemed')
    : t('add_promo_code_detail');

  const handleApplyPromotion = (promotion) => {
    setPromotion(promotion);
    const price = promotion.toUpdatedPromotionPrice(creator);
    const priceInUserCurrency = convertToPrice(
      price,
      creator.currency,
      currentUserCurrency
    );
    //now get updated value in current user currency w/ price
    applyPromotion({
      total_price: priceInUserCurrency,
      currency: currentUserCurrency,
      promotion_id: promotion.id,
    });
    setShowRedeemCode(false);
  };

  return (
    <OrderBlockWidget hideBackground={true} noSpacing={true}>
      <Box className={classes.root}>
        <Box
          onClick={() => setShowRedeemCode(true)}
          m={1}
          style={{ cursor: 'pointer' }}
          textAlign="center"
          className={classes.container}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box
              id="icon"
              className={classes.icon}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={PromoCodeIcon}
                alt="promo"
                style={{ width: '16px', height: '16px', objectFit: 'cover' }}
              />
            </Box>
            <Box
              p={1.5}
              height="100%"
              id="titles"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Box
                color={promotion ? 'secondary.main' : 'text.secondary'}
                className={classes.title}
              >
                {title}
              </Box>
              <Box
                color={promotion ? 'secondary.main' : 'text.secondary'}
                mt={1}
                className={classes.detail}
              >
                {detail}
              </Box>
            </Box>
          </Box>
          <Box color="white" id="carrot">
            {hebrew ? <ChevronLeft /> : <ChevronRight />}
          </Box>
        </Box>
      </Box>
      <RedeemCode
        open={showRedeemCode}
        type="booking"
        creatorId={creator.id}
        onClose={() => setShowRedeemCode(false)}
        onSuccess={(promotion) => handleApplyPromotion(promotion)}
      />
    </OrderBlockWidget>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    user: state.user.details,
    creator: state.ordering.creator,
  };
};

export default connect(mapStateToProps, { applyPromotion })(
  OrderAddPromoCodeBlock
);
