import * as app from './../App';
import { baseURL } from '../utils/rhutils.js';

require('dotenv').config();

class RHAPI {
  BASE_URL = '';

  constructor() {
    // console.log(
    //   "RHAPI constructor called..is production? =",
    //   app.isProduction()
    // );
    // this.BASE_URL = app.isProduction()
    //   ? 'https://us-central1-celebrate-io.cloudfunctions.net/'
    //   : app.isDev()
    //   ? 'http://localhost:5000/celebrate-staging-1337/us-central1/'
    //   : 'https://us-central1-celebrate-staging-1337.cloudfunctions.net/';

    this.BASE_URL = process.env.REACT_APP_BASE_API_URL
      ? process.env.REACT_APP_BASE_API_URL
      : app.isProduction()
      ? 'https://us-central1-celebrate-io.cloudfunctions.net/'
      : 'https://us-central1-celebrate-staging-1337.cloudfunctions.net/';

    /* Main */
    this.FETCH_HOME = this.BASE_URL + 'fetchHome';

    /* Personalized Videos */
    this.SUBMIT_BOOKING_REQUEST = this.BASE_URL + 'submitBookingRequest';
    this.UPDATE_BOOKING_REQUEST = this.BASE_URL + 'updateRequest';
    this.FETCH_TRANSACTION = this.BASE_URL + 'fetchTransaction';

    /* Requests */
    this.DECLINE_REQUEST = this.BASE_URL + 'declineRequest';
    this.CANCEL_REQUEST = this.BASE_URL + 'cancelRequest';
    this.EXPIRE_REQUEST = this.BASE_URL + 'expireRequest';
    this.SEND_NUDGE_REQUEST = this.BASE_URL + 'sendNudgeForRequest';

    /* Transactions */
    this.SUBMIT_TRANSACTION = this.BASE_URL + 'submitTransaction';
    this.SUBMIT_TRANSACTION_REVIEW = this.BASE_URL + 'submitTransactionReview';
    this.TRANSACTION_VIDEO_VIEWED = this.BASE_URL + 'transactionVideoViewed';

    /* Account */
    this.SUBMIT_PAYMENT_METHOD = this.BASE_URL + 'submitPaymentMethod';

    /* Close Circle */
    this.PURCHASE_CLOSE_FAN_SUBSCRIPTION =
      this.BASE_URL + 'purchaseCloseFanSubscription';
    this.CANCEL_CLOSE_FAN_SUBSCRIPTION = this.BASE_URL + 'cancelSubscription';

    /* Category */
    this.FETCH_CATEGORY_INFO = this.BASE_URL + 'fetchCategoryInfo';

    /* Users */
    this.FETCH_USER_DETAILS = this.BASE_URL + 'fetchUserDetails';
    this.FETCH_BASIC_USER_DETAILS = this.BASE_URL + 'fetchBasicUserDetails';
    this.DID_VIEW_PROFILE = this.BASE_URL + 'didViewProfile';
    // this.FETCH_ACTIVITY = this.BASE_URL + "fetchActivity";
    this.FETCH_ACTIVITY = this.BASE_URL + 'fetchBookingRequests';
    this.FETCH_ACCOUNT_DETAILS = this.BASE_URL + 'fetchAccountDetails';
    this.UPDATE_USER = this.BASE_URL + 'updateUser';
    this.UPDATE_PROFILE = this.BASE_URL + 'updateProfile';
    this.VALIDATE_USERNAME = this.BASE_URL + 'checkUsernameValidity';

    /* Sessions */
    this.PURCHASE_SESSION_ACCESS = this.BASE_URL + 'purchaseSessionAccess';
    this.SUBMIT_SESSION = this.BASE_URL + 'submitSession';
    this.FETCH_SESSION_DETAILS = this.BASE_URL + 'fetchSessionDetails';
    this.FETCH_SESSION_INFO = this.BASE_URL + 'fetchSessionInfo';

    /* Payouts */
    this.CREATE_PAYOUT = this.BASE_URL + 'createPayout';

    /* Social */
    this.FETCH_SOCIAL = this.BASE_URL + 'fetchSocial';

    /* Creations */
    this.PURCHASE_CREATION_ACCESS = this.BASE_URL + 'purchaseCreationAccess';
    this.SUBMIT_CREATION_ITEM = this.BASE_URL + 'submitCreation';

    /* Admin */
    this.FETCH_ADMIN_DASHBOAD_DETAILS =
      this.BASE_URL + 'fetchAdminDashboardDetails';

    /* Promotions */
    this.FETCH_PROMOTION = this.BASE_URL + 'fetchPromotion';
    this.SEND_PROMOTION = this.BASE_URL + 'giftPromotion';
    this.REDEEM_PROMO_CODE = this.BASE_URL + 'redeemPromotionCode';

    //V2
    this.EDIT_PROFILE_V2 = this.BASE_URL + 'editProfileV2';

    this.FETCH_CHARITIES = this.BASE_URL + 'fetchCharities';

    this.USERS = this.BASE_URL + 'users';
    this.FOLLOW_PROFILES = this.BASE_URL + 'followUsers';
    this.SUBMIT_VERIFICATION_REQUEST =
      this.BASE_URL + 'submitVerificationRequest';
  }
}

var api = new RHAPI();
export default api;
