import { call, put, takeLatest } from "redux-saga/effects";
import { fetchAdminDashboardData } from "../../routines";
import adminManager from "../../../managers/adminManager";

function* handleTriggerAction(action: any) {
  try {
    yield put(fetchAdminDashboardData.request());
    const result = yield call(adminManager.fetchAdminDashboardData);
    yield put(fetchAdminDashboardData.success(result));
  } catch (e) {
    console.log('e', e)
    yield put(fetchAdminDashboardData.failure());
  }
}

export default function* fetchAdminDashboardDataSaga() {
  yield takeLatest(fetchAdminDashboardData.TRIGGER, handleTriggerAction);
}
