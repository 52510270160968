export enum CreationDataType {
  ANNOUNCEMENT = 0,
  TEXT = 1,
  IMAGE = 2,
  VIDEO = 3,
  RECORDING = 4,
  REQUEST = 5,
  POLL = 6,
  THREAD = 7,
  AUDIO = 8,
  UNKNOWN = 99,
}

export function fromStringType(stringType: string) {
  switch (stringType) {
    case 'image':
      return CreationDataType.IMAGE;
    case 'video':
      return CreationDataType.VIDEO;
    case 'text':
      return CreationDataType.TEXT;
    default:
      return CreationDataType.UNKNOWN;
  }
}
