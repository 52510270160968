import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import React, { useState, useEffect } from 'react';
import styles from './EditProfileButtons.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import right_arrow from '../Images/right_arrow.svg';
import icon_1 from '../Images/icon_1.svg';
import icon_2 from '../Images/icon_2.svg';
import icon_3 from '../Images/icon_3.svg';
import icon_4 from '../Images/icon_4.svg';
import icon_5 from '../Images/icon_5.svg';
import icon_6 from '../Images/icon_6.svg';
import icon_7 from '../Images/icon_7.svg';
import icon_8 from '../Images/icon_8.svg';
import submitTransactionModal from '../../components/submitTransactionModal';

/* 
All the buttons at the main editProfile component
*/
const EditProfileButtons = (props) => {
  const { t, i18n } = useTranslation();

  const setShowScreen = (screen) => {
    props.setShowScreen(screen);
  };

  const user = props.User;

  const SkeletonLoader = () => {
    const Box = () => {
      return (
        <div className={`${styles.button}`}>
          <Skeleton height={'120px'} style={{ borderRadius: '16px' }} />
        </div>
      );
    };

    const Row = () => {
      return (
        <div className="w-100 d-flex flex-row justify-content-center">
          <Box />
          <Box />
        </div>
      );
    };
    return (
      <div className="pb-5">
        <div>
          <Row />
          <Row />
          <Row />
          {/* <Row /> */}
        </div>
      </div>
    );
  };

  const AllButtons = () => {
    const personalizeRequestsStateTitle = user.availableForPersonalizedRequests()
      ? t('editProfile_on')
      : t('editProfile_off');
    const businessRequestsStateTitle = user.availableForBusinessRequests()
      ? t('editProfile_on')
      : t('editProfile_off');
    const subscriptionRequestsStateTitle = user.hasActiveCloseCircleSubscription()
      ? t('editProfile_on')
      : t('editProfile_off');

    return (
      <div className={styles.wrapper}>
        <div
          className={`${styles.button} ${styles.button1}`}
          onClick={() => {
            setShowScreen('editAccount');
          }}
        >
          <div className={styles.icon}>
            <img src={icon_1} />
          </div>
          <div className={styles.bottom}>
            <div className={styles.text}>{t('editProfile_account')}</div>
            <img src={right_arrow} />
          </div>
        </div>
        <div
          className={`${styles.button} ${styles.button2}`}
          onClick={() => {
            setShowScreen('social');
          }}
        >
          <div className={styles.icon}>
            <img src={icon_2} />
          </div>
          <div className={styles.bottom}>
            <div className={styles.text}>{t('editProfile_socials')}</div>
            <img src={right_arrow} />
          </div>
        </div>
        <div
          className={`${styles.button} ${styles.button3}`}
          onClick={() => {
            setShowScreen('personalRequests');
          }}
        >
          <div className={styles.toggle}>{personalizeRequestsStateTitle}</div>
          <div className={styles.icon}>
            <img src={icon_3} />
          </div>
          <div className={styles.bottom}>
            <div className={styles.text}>
              {t('editProfile_personal_equests')}
            </div>
            <img src={right_arrow} />
          </div>
        </div>
        <div
          className={`${styles.button} ${styles.button4}`}
          onClick={() => {
            setShowScreen('businessRequests');
          }}
        >
          <div className={styles.toggle}>{businessRequestsStateTitle}</div>
          <div className={styles.icon}>
            <img src={icon_4} />
          </div>
          <div className={styles.bottom}>
            <div className={styles.text}>
              {t('editProfile_business_requests')}
            </div>
            <img src={right_arrow} />
          </div>
        </div>
        <div
          className={`${styles.button} ${styles.button5}`}
          onClick={() => {
            setShowScreen('subscription');
          }}
        >
          <div className={styles.toggle}>{subscriptionRequestsStateTitle}</div>
          <div className={styles.icon}>
            <img src={icon_5} />
          </div>
          <div className={styles.bottom}>
            <div className={styles.text}>{t('editProfile_subscription')}</div>
            <img src={right_arrow} />
          </div>
        </div>
        <div
          className={`${styles.button} ${styles.button6}`}
          onClick={() => {
            setShowScreen('charity');
          }}
        >
          <div className={styles.icon}>
            <img src={icon_6} />
          </div>
          <div className={styles.bottom}>
            <div className={styles.text}>{t('editProfile_charity')}</div>
            <img src={right_arrow} />
          </div>
        </div>
        {/* <div className={`opacity-2 ${styles.button} ${styles.button7}`}>
          <div className={styles.icon}>
            <img src={icon_7} />
          </div>
          <div className={styles.bottom}>
            <div className={styles.text}>{t('editProfile_get_verified')}</div>
            <img src={right_arrow} />
          </div>
        </div>
        <div
          className={`${styles.button} ${styles.button8}`}
          onClick={() => {
            setShowScreen('addLink');
          }}
        >
          <div className={styles.icon}>
            <img src={icon_8} />
          </div>
          <div className={styles.bottom}>
            <div className={styles.text}>{t('editProfile_custom_links')}</div>
            <img src={right_arrow} />
          </div>
        </div> */}
      </div>
    );
  };

  const Content = () => {
    return user ? <AllButtons /> : <SkeletonLoader />;
  };
  return (
    <div
      className={`${styles.buttons} ${
        i18n.language === 'he' ? styles.heb : ''
      }`}
    >
      <div className={styles.whiteBackground}></div>
      <Content />
    </div>
  );
};

export default withRouter(EditProfileButtons);
