import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styles from './EditProfile.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import Footer from '../components/NewFooter/Footer';
import { NewNavbar } from '../components/NewNavbar';
import { useTranslation } from 'react-i18next';
import userManager from '../managers/userManager';
import Path from '../components/Path/Path';
import EditProfileTop from './EditProfileTop/EditProfileTop';
import EditProfileBox from './EditProfileBox/EditProfileBox';
import img_icon from './Images/img_icon.svg';
import EditProfileButtons from './EditProfileButtons/EditProfileButtons';
import AddLink from '../AddLink/AddLink';
import EditProfileAccount from './EditProfileAccount/EditProfileAccount';
import EditProfileRequests from './EditProfileRequests/EditProfileRequests';
import EditProfileSocial from './EditProfileSocial/EditProfileSocial';
import EditProfileCharity from './EditProfileCharity/EditProfileCharity';
import EditProfileAdmin from './EditProfileAdmin/EditProfileAdmin';
import PageLoader from '../components/pageLoader';
import profileManager from '../managers/profileManager';
import VLProfileTip from '../model/enums/VLProfileTip';
import { centerFlexColumn } from '../utils/bootstrapUtils';
import { ACCOUNT_AUTHED, HOME } from '../constants/routes';
const AuthModal = React.lazy(() => import('../signup/AuthModal/AuthModal'));
const Toast = React.lazy(() => import('../Toasts/Toast'));

let firstTime = true;
const EditProfile = (props) => {
  const screenQuery = new URLSearchParams(props.location.search).get(
    'showScreen'
  );
  const hijackedUserId = new URLSearchParams(props.location.search).get(
    'hijackedUserId'
  );

  // console.log('props:', props);

  const { t, i18n } = useTranslation();
  const [User, setUser] = useState(null);
  const [showScreen, setShowScreen] = useState(screenQuery);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({ title: null, detail: null });
  const [didFetch, setDidFetch] = useState(false);
  const [redirectToAcount, setRedirectToAccount] = useState(false);
  const history = useHistory();

  const loadUser = async (hardReload = false, triedOnce = false) => {
    //get the user from user api
    // console.log('loadUser: initial');
    if (props.isConnected === false) {
      // console.log('loadUser: no connected');
      return;
    }

    if (User) {
      //already loaded
      return;
    }

    if (firstTime || hardReload || !didFetch) {
      let profileId = userManager.getUser().profileId;
      // console.log('loadUser:', profileId);
      if (profileId) {
        firstTime = false;

        let UserAPI;
        if (hijackedUserId) {
          UserAPI = await userManager.fetchPublicUserData(hijackedUserId);
        } else {
          UserAPI = await userManager.fetchUserFromProfile(profileId);
        }
        setDidFetch(true);
        setUser(UserAPI);
        // console.log('response:', UserAPI);
        if (UserAPI) {
          // console.log('firing listener:', UserAPI);
          userManager.addUserDetailsListener(
            hijackedUserId ?? UserAPI.id,
            (result) => {
              // console.log('listener callback:', result);
              setUser(result);
            }
          );
        } else if (User) {
          // console.log('loadUser: cached', User);
          setDidFetch(true);
          setUser(User);
        }
      } else if (User) {
        // console.log('loadUser: cached', User);
        setDidFetch(true);
        setUser(User);
      } else {
        // console.log('loadUser: failed');
        if (triedOnce === false && profileId) {
          setTimeout(() => {
            loadUser(false, true);
          }, 1000);
        } else {
          //stop trying
          // console.log('stop trying...', profileId);
        }
      }
    }
  };

  loadUser(); //load the first user

  // console.log('render EditProfile ');

  const setShowScreenFunc = (screen) => {
    window.scrollTo(0, 0);
    setShowScreen(screen);
  };

  const onError = (title, detail) => {
    // console.log('on error detail', detail);
    setError({ title: title, detail: detail });
    setShowError(true);

    setTimeout(() => {
      setShowError(false);
    }, 3000);
  };

  const onTipClicked = (tip) => {
    switch (tip) {
      case VLProfileTip.Title:
        return setShowScreen('editAccount');
      case VLProfileTip.Description:
        return setShowScreen('editAccount');
      case VLProfileTip.ProfilePicture:
        return setShowScreen('editAccount');
      case VLProfileTip.ProfileVideo:
        return setShowScreen('editAccount');
      case VLProfileTip.Tags:
        return setShowScreen('editAccount');
      case VLProfileTip.Socials:
        return setShowScreen('social');
      case VLProfileTip.PersonalRequests:
        return setShowScreen('personalRequests');
      case VLProfileTip.BusinessRequests:
        return setShowScreen('businessRequests');
      case VLProfileTip.Subscriptions:
        return setShowScreen('subscription');
      case VLProfileTip.Cause:
        return setShowScreen('charity');
      default:
        break;
    }
  };

  let profileId = User && User.profileId ? `@${User.profileId}` : '';

  /**
   * General function to be called after save is succesfull on child edit screens
   */
  const onEditSaveSuccess = () => {
    // setShowScreenFunc('');
  };

  /**
   * Error toast container
   * @param {*} props
   */

  const ErrorToast = (props) => {
    return showError ? (
      <Toast
        error
        title={props.title}
        detail={props.detail}
        show={showError}
        onClick={() => setShowError(false)}
      />
    ) : null;
  };

  /**
   * General profile content screen that decides what main content should be displayed
   * If showScreen === true, will show the child edit screen (account, socials, etc)
   * Otherwise will show the edit profile "home" screen
   */
  const EditProfileContentScreen = () => {
    if (!showScreen) {
      window.history.replaceState(null, null, `/editProfile`);
    }
    return showScreen && User ? (
      <div className="fixed-top w-100 h-100 bg-white overflow-auto">
        <ComponentToRender
          setShowScreenFunc={setShowScreenFunc}
          showScreen={showScreen}
          setShowScreen={setShowScreen}
          onError={onError}
          User={User}
          hijackedUserId={hijackedUserId}
        />
        <ErrorToast title={error.title} detail={error.detail} />
      </div>
    ) : (
      <div>
        <EditProfileButtons setShowScreen={setShowScreenFunc} User={User} />
      </div>
    );
  };

  const TopSection = () => {
    const popToHome = () => {
      history.replace(HOME);
    };

    const handlePathClick = (path) => {
      switch (path) {
        case t('profile_main_home'):
          popToHome();
          return;
        case profileId:
          setRedirectToAccount(true);
          return;
        default:
          return;
      }
    };

    if (redirectToAcount) {
      return <Redirect to={ACCOUNT_AUTHED} />;
    }
    return (
      <div className={styles.wrapper}>
        {profileId ? (
          <Path
            onPathClicked={(path) => handlePathClick(path)}
            data={[
              t('profile_main_home'),
              profileId,
              t('edit_profile').toLowerCase(),
            ]}
          />
        ) : null}
        <EditProfileTop user={User} setShowScreen={setShowScreen} />
      </div>
    );
  };

  const AuthModalContainer = () => {
    // console.log('props.isConnected', props.isConnected);
    if (props.isConnected === null) {
      return null;
    }
    return props.isConnected === true ? null : (
      <AuthModal
        onAuthSuccess={() => loadUser()}
        onClose={() => {
          loadUser(true);
        }}
      />
    );
  };

  return (
    <div
      className={`${styles.MasterContainer} ${
        i18n.language === 'he' ? styles.heb : ''
      }`}
    >
      <AuthModalContainer />
      <NewNavbar theme={'white'} />
      <div className={`${styles.ContentContainer} ${centerFlexColumn()}`}>
        <div className={`${styles.ContentContainerChild} `}>
          <TopSection />
          <Boxes user={User} onTipClicked={onTipClicked} />
          <EditProfileContentScreen setShowScreen={setShowScreen} />
        </div>
      </div>
      <div className={`${styles.footer} bg-white`}>
        <Footer theme={'white'} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { isConnected: state.user.isConnected };
};

export default connect(mapStateToProps)(withRouter(EditProfile));

/* the row of boxed 
just send the t for the translations*/

const Content = (props) => {
  const {
    setShowScreen,
    setShowLoading,
    setShowScreenFunc,
    showScreen,
    onError,
    User,
  } = props;

  const onEditSaveSuccess = () => {
    // console.log('edit succes!');
  };

  // props.history.replace({
  //   pathname: `/editProfile?showScreen=editAccount`,
  // });

  window.history.replaceState(
    null,
    null,
    `/editProfile?showScreen=${showScreen}`
  );

  if (!props.User || !props.User.profileId) {
    //nop
    return null;
  } else if (showScreen === 'addLink') {
    return (
      <AddLink
        {...props}
        onEditSaveSuccess={onEditSaveSuccess}
        close={() => {
          setShowScreenFunc('');
        }}
      />
    );
  } else if (
    showScreen === 'personalRequests' ||
    showScreen === 'businessRequests' ||
    showScreen === 'subscription'
  ) {
    return (
      <EditProfileRequests
        kind={showScreen}
        {...props}
        onEditSaveSuccess={onEditSaveSuccess}
        close={() => {
          setShowScreenFunc('');
        }}
      />
    );
  } else if (showScreen === 'editAccount') {
    return (
      <EditProfileAccount
        {...props}
        onEditSaveSuccess={onEditSaveSuccess}
        close={() => {
          setShowScreenFunc('');
        }}
      />
    );
  } else if (showScreen === 'charity') {
    return (
      <EditProfileCharity
        {...props}
        onEditSaveSuccess={onEditSaveSuccess}
        close={() => {
          setShowScreenFunc('');
        }}
      />
    );
  } else if (showScreen === 'social') {
    return (
      <EditProfileSocial
        {...props}
        onEditSaveSuccess={onEditSaveSuccess}
        close={() => {
          setShowScreenFunc('');
        }}
      />
    );
  } else if (showScreen === 'admin') {
    return (
      <EditProfileAdmin
        {...props}
        onEditSaveSuccess={onEditSaveSuccess}
        close={() => {
          setShowScreenFunc('');
        }}
      />
    );
  } else {
    setShowScreen(null);
    return null;
  }
};

const ComponentToRender = (props) => {
  const [showLoading, setShowLoading] = useState(props.showLoading);

  /**
   * Loading component wrapper
   */
  const Loading = () => {
    return showLoading ? (
      <div className="fixed-top w-100 h-100">
        <PageLoader darkOpacity={true} withBackground={true} />
      </div>
    ) : null;
  };

  return (
    <div>
      <Content {...props} setShowLoading={setShowLoading} />
      <Loading />
    </div>
  );
};
const Boxes = (props) => {
  const { t, i18n } = useTranslation();

  const user = props.user;

  const TipsSection = () => {
    if (!user) {
      return null;
    }
    const tips = profileManager.availableTipsForUser(user);
    // console.log('tips:', tips);
    const resources = tips.map((tip) =>
      profileManager.VLProfileTipImageTitle(tip)
    );

    // console.log('tips:', tips);
    let index = Number(0);
    if (tips.length === 0) {
      return null;
    }

    // console.log('resources:', resources);

    return (
      <>
        {resources.map((resource) => (
          <EditProfileBox
            img={resource[0]}
            text={resource[1]}
            tip={tips[index++]}
            onTipClicked={props.onTipClicked}
          />
        ))}
      </>
    );
  };

  //Check if should
  const tips = user ? profileManager.availableTipsForUser(user) : null;
  const hideSection = tips && tips.length === 0 ? true : false;

  return hideSection ? null : (
    <div className={styles.boxes}>
      {user ? <TipsSection /> : null}
      <div className={styles.spacer}>s</div>
    </div>
  );
};

/* 
All the edit profile pages
*/
