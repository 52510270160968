import React, { useState } from 'react';
import styles from './Creation.module.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import locked from '../Images/locked.svg';
import { timeAgoString } from '../../utils/dateUtils';
import icon_video from '../Images/icon_video.svg';

const Request = React.memo((props) => {
  const [imageHaveError, setImageHaveError] = useState(false);
  const { t, i18n } = useTranslation();

  const buildTime = () => {
    //build the time of the title
    let time = props.data && props.data.createdOn ? props.data.createdOn : '';
    let date = time ? new Date(time) : '';

    if (i18n.language === 'he') {
      date = timeAgoString(date, true); //date ? moment(date).format("hh:mm:ss DD/MM/YYYY") : ''
    } else {
      date = date ? moment(date).fromNow() : '';
    }

    date = date ? date.toString().toUpperCase() : '';

    return typeof date === 'string' ? date : '';
  };
  let title = props.data && props.data.title ? props.data.title : '';
  let lock = props.data && props.data.isLocked ? props.data.isLocked() : false;
  let isPurchased =
    props.data && props.data.isPurchased ? props.data.isPurchased() : false;
  let lockText = props.data.buttonTitle ? props.data.buttonTitle() : null;

  const getStyle = () => {
    let style = {};
    if (props.width) {
      style.width = props.width;
    }

    if (props.height) {
      style.height = props.height;
    }

    return style;
  };
  const showVideo = () => {
    //show the image modal
    let videoURL = props.data && props.data.videoURL ? props.data.videoURL : '';

    if (lock) {
      if (lockText === 'buy_to_watch') {
        // props.showModal();
        props.toggleShowSubscription(true, props.data);
      } else if (props.toggleShowSubscription) {
        props.toggleShowSubscription();
      }
    } else if (
      props.showVideo &&
      (!props.kind || props.kind === 'video') &&
      props.data &&
      props.data.isSubscriberOnly &&
      !props.data.isSubscriberOnly()
    ) {
      props.showVideo(videoURL);
    } else {
      //should show go to app modal?
      props.toggleViewContentModal(true);
    }
  };

  const onError = () => {
    setImageHaveError(true);
  };

  const convertNum = (num) => {
    if (num > 999 && i18n.language === 'he') {
      return (num / 1000).toFixed(2) + 'K';
    } else if (num > 999) {
      return (num / 1000).toFixed(2) + 'K';
    }

    return num;
  };

  let desc = props.data && props.data.desc ? props.data.desc : '';
  let time =
    props.data && props.data.lastUpdated
      ? buildTime(props.data.lastUpdated)
      : '';

  let previewPicUrl =
    props.data && props.data.thumbURL ? props.data.thumbURL : false;
  let profilePic =
    props.User && props.User.profilePictureURL
      ? props.User.profilePictureURL
      : '';
  let profileImage = false; //if we are showing the profilePic
  let likesCount =
    props.data && props.data.likesCount !== undefined
      ? convertNum(props.data.likesCount)
      : 0;
  let viewsCount =
    props.data && props.data.viewsCount !== undefined
      ? convertNum(props.data.viewsCount)
      : 0;
  let commentsCount =
    props.data && props.data.commentsCount !== undefined
      ? convertNum(props.data.commentsCount)
      : 0;

  if (props.kind === 'image') {
    previewPicUrl =
      props.data && props.data.imageURL ? props.data.imageURL : false;
  }

  if ((!previewPicUrl && profilePic) || imageHaveError) {
    //if we don't have an image take the profile image of the user
    previewPicUrl = profilePic;
    profileImage = true;
  }

  // if (lockText === 'buy_to_watch' && !window.opened) {
  //   window.opened = true;
  //   showVideo();
  // }

  const footer = () => {
    return (
      <div className={styles.Creation_footer_likes}>
        {/* <div className={styles.Creation_footer_likes_box}>
                  <img src={views}/>
                  <span className={`${styles.margins} ${styles.viewsText}`}>{viewsCount}</span>
              </div>
              <div className={styles.Creation_footer_likes_box}>
                  <img src={likes} className={styles.Creation_footer_likes_box_v} />
                  <span className={`${styles.margins} ${styles.marginsNoLeft}`}>{likesCount}</span>
              </div> */}
        {/* <div className={styles.Creation_footer_likes_box}>
                  <img src={comments} />
                  <span className={styles.margins}>{commentsCount}</span>
              </div> */}
      </div>
    );
  };

  return (
    <div
      className={`${i18n.language === 'he' ? styles.heb : ''} 
                    ${styles.Creation} 
                    ${lock ? styles.creationsLock : ''}
                    ${props.fromBuy ? styles.fromBuy : ''}
                    ${props.theme === 'white' ? styles.whiteTheme : ''}
                    ${props.width === 'allPage' ? styles.allPageInMobil : ''}
                    ${props.kind === 'text' ? styles.CreationText : ''} 
                    ${props.kind === 'video' ? styles.CreationVideo : ''}
                    ${props.size === 'dynamic' ? styles.CreationDynamic : ''}`}
      data-e2e="Creation"
      style={getStyle()}
      onClick={showVideo}
    >
      {isPurchased || lock ? (
        <div className={styles.lockIcon}>
          <img src={locked} className={styles.imageLock} />
          {t(lockText)}
        </div>
      ) : null}
      {previewPicUrl ? (
        <img
          src={previewPicUrl}
          onError={onError}
          className={`${styles.image} ${profileImage ? styles.imageBlur : ''}`}
        />
      ) : null}
      <div className={styles.Creation_profile}>
        <div className={styles.Creation_profile_textTitle}>
          {props.kind === 'video' ? (
            <img
              src={icon_video}
              className={styles.videoIcon}
              style={{ marginBottom: '0.5px' }}
            />
          ) : (
            ''
          )}
          {title}
        </div>
        <div className={styles.Creation_profile_time}>{time}</div>
      </div>

      {/* <div className={styles.play}>
                <img src={play} />
            </div> */}

      <div className={styles.text}>{desc}</div>
      <div className={styles.Creation_footer}>
        {/* <div className={styles.Creation_footer_title}>{title}</div> */}
        {footer()}
      </div>
    </div>
  );
});

export default Request;
