import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProfileActionButtonContainer.module.scss';
import * as bootstrapUtils from '../../utils/bootstrapUtils';
import * as utils from '../../utils/rhutils';
import * as priceUtils from '../../utils/priceUtils';
import VLUser, { ActionType } from '../../model/vlUser';
import * as anim from '../../components/animations/vlFadeIn';
import ProfileActionButton from '../Buttons/ProfileActionButton';
const ProfileActionButtonContainer = React.memo((props) => {
  const { t, i18n } = useTranslation();

  const { user, showSkeleton } = props;

  // utils.log.debug('ProfileActionButtonContainer');

  if (!user) {
    utils.log.debug(`rth:  no user`);
    return null;
  }

  const vlUser = new VLUser(user);

  let profileActions = vlUser.profileActions();

  let index = profileActions.indexOf(ActionType.subscription);
  //remove subscribe object if we alrready subscribed
  // if (index > -1 && vlUser.isCurrentUserASubscriber()) {
  //   profileActions[index] = null;
  // }
  let customProfileActions = vlUser.customProfileActions;
  // utils.log.debug('user: ', user);
  // utils.log.debug('vlUser: ', vlUser);
  // utils.log.debug('profileActions: ', profileActions);

  const skeletonButton = () => {
    return (
      <SkeletonTheme color="#202020" highlightColor="#444">
        <div className="mb-3">
          <Skeleton
            className="w-100"
            style={{ height: '80px', borderRadius: '40px' }}
          />
        </div>
      </SkeletonTheme>
    );
  };

  let skeletons = [0, 1, 2, 3, 4]; //5;

  const skeletonViews = () => {
    return (
      <div className="w-100">
        {skeletons.map((item, index) => {
          // utils.log.debug(`rth item: ${index}`);
          return skeletonButton();
        })}
      </div>
    );
  };

  const onCustomProfileActionButtonClicked = (customProfileAction) => {
    let link = customProfileAction.link;
    if (!link) {
      // utils.log.error('no link for custom action ', customProfileAction);
    }
    let win = window.open(link, '_blank');
  };

  const buttonViews = () => {
    return (
      <div className="w-100">
        <div>
          {customProfileActions.map((item, index) => {
            const customProfileAction = customProfileActions[index];
            // utils.log.debug('customProfileAction: ', customProfileAction);
            const title = customProfileAction.localizedTitle();
            const customImageURL = customProfileAction.buttonImageURL;
            const price = customProfileAction.price;
            const currency = customProfileAction.currency
              ? customProfileAction.currency
              : vlUser.currency;

            const priceString =
              price && currency
                ? priceUtils.toPriceString(currency, price, true, true)
                : null;

            const specialStyle = customProfileAction.specialStyle ?? null;

            // utils.log.debug('customProfileAction title: ', title);
            // utils.log.debug('customProfileAction price: ', price);

            return (
              <div className="mb-3">
                <div
                  onClick={() =>
                    onCustomProfileActionButtonClicked(customProfileAction)
                  }
                >
                  <ProfileActionButton
                    key={index}
                    title={title}
                    outline={props.outline}
                    customImageURL={customImageURL}
                    price={priceString}
                    specialStyle={specialStyle}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div>
          {profileActions.map((item, index) => {
            const profileAction = profileActions[index];

            //hide subscription
            if (profileAction === ActionType.subscription) return null;

            const assets = vlUser.assetsFromActionType(profileAction, true);
            const title = assets.title;
            const image = assets.image;
            const price = assets.price;
            const specialStyle = assets.specialStyle;
            const id = assets.id;

            // utils.log.debug('profileAction title: ', title);
            // utils.log.debug('profileAction price: ', price);

            return (
              <div className="mb-3">
                <div onClick={() => props.onActionButtonClicked(profileAction)}>
                  <ProfileActionButton
                    key={index}
                    disabled={
                      vlUser.isAvailableForAction(profileAction) === false
                    }
                    customId={id}
                    title={title}
                    outline={props.outline}
                    image={image}
                    price={price}
                    specialStyle={specialStyle}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const content = () => {
    return showSkeleton ? skeletonViews() : buttonViews();
  };

  const column = showSkeleton ? '' : `${bootstrapUtils.centerFlexColumn()}`;
  return (
    <div className={`w-100 ${column}`}>
      <div id="profile-actions" className={`w-100 ${column}`}>
        {content()}
      </div>
    </div>
  );
});

export default ProfileActionButtonContainer;
