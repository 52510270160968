import React, { useState, useEffect, useRef } from 'react';
import styles from './EditProfileAdmin.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditProfileHeader from '../EditProfileHeader/EditProfileHeader';
import { Form, Spinner } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import profileManager from '../../managers/profileManager';
import { centerFlexColumn } from '../../utils/bootstrapUtils';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { Switch } from '@material-ui/core';

const Input = (props) => {
  const { title, inputValue, placeholder, onChange } = props;
  return (
    <div className={styles.InputRow}>
      <div className={styles.CheckboxText}>{title}</div>

      <input
        type="text"
        value={inputValue}
        onChange={onChange}
        className={styles.inputElement}
        placeholder={placeholder}
      />
    </div>
  );
};

const Inputs = (props) => {
  return (
    <div>
      <Input
        title={'Category ids (comma separated)'}
        inputValue={props.categoryIds}
        placeholder={'Example: v2_il_actors,v2_il_singers'}
        onChange={(e) => props.setCategoryIds(e.target.value)}
      />
      <Input
        title={'Tags (comma separated)'}
        inputValue={props.tags}
        placeholder={'Example: actor,singer,dancer'}
        onChange={(e) => props.setTags(e.target.value)}
      />
    </div>
  );
};

/* All the edit profile component, it's for business requests private requests and subscription */
const EditProfileAdmin = (props) => {
  const { t, i18n } = useTranslation();
  const user = props.User;

  // console.log('user.verified', user);

  //the main product checkbox (personalized request, business requests, subscription, etc)
  const [verified, setVerified] = useState(user.verified);
  const [featuredOnMainPage, setFeaturedOnMainPage] = useState(user.active);
  const [visibleOnSearch, setVisibleOnSearch] = useState(
    user.visibleOnSearch()
  );
  const [tags, setTags] = useState(user.tags);
  const [categoryIds, setCategoryIds] = useState(user.categoryId);
  const [currency, setCurrency] = useState(user.currency);

  /**
   * Checks if should allow saving (if there are any changes effectively)
   */
  const allowSave = () => {
    if (!user || !user.id) {
      return false;
    } else {
      // console.log('user = ', user);

      let diff = false;
      diff = true;
      return diff;
    }
  };

  /**
   * Save function that saves the current screens data to the user's profile
   */
  const save = async () => {
    props.setShowLoading(true);

    const parsedTags = tags ? tags.toString().split(',') : [];

    let updateObj = {
      active: featuredOnMainPage,
      verified: verified,
      visibleOnSearch: visibleOnSearch,
      category_id: categoryIds,
      tags: parsedTags,
      currency: currency,
    };

    let attemptSave = await profileManager.updateProfileAdmin(
      updateObj,
      props.hijackedUserId
    );

    props.setShowLoading(false);
    // console.log('attemptSave = ', attemptSave);
    if (attemptSave.success) {
      props.onEditSaveSuccess();
    } else {
      //show error
      window.alert(
        attemptSave.error && attemptSave.error.localizedTitle()
          ? attemptSave.error && attemptSave.error.localizedTitle()
          : 'error'
      );
      const errorTitle = '';
      const errorDetail = '';
      props.onError(errorTitle, errorDetail);
    }
  };

  const getTitle = () => {
    return 'Admin Edit';
  };

  const ProductToggle = (props) => {
    const { title, isOn } = props;

    /* checkbox component */
    const CheckBox = (props) => {
      return (
        <div className={styles.checkboxRow}>
          <div className={styles.CheckboxText}>{props.text}</div>
          <span onClick={props.onChange}>
            <Switch
              color="primary"
              checked={props.value}
              onChange={(e) => props.onChange(e)}
              name="main-checkbox"
              inputProps={{ 'aria-label': 'main product checkbox' }}
            />
          </span>
        </div>
      );
    };

    return <CheckBox text={title} onChange={props.onChange} value={isOn} />;
  };

  const CurrencySelection = (props) => {
    return (
      <div className={styles.InputRow}>
        <div className={styles.CheckboxText}>{'Currency'}</div>

        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            variant={props.currency === 'ILS' ? 'contained' : 'outlined'}
            color={props.currency === 'ILS' ? 'primary' : 'secondary'}
            onClick={() => props.onSelect('ILS')}
          >
            ILS
          </Button>
          <Button
            variant={props.currency === 'USD' ? 'contained' : 'outlined'}
            color={props.currency === 'USD' ? 'primary' : 'secondary'}
            onClick={() => props.onSelect('USD')}
          >
            USD
          </Button>
        </ButtonGroup>
      </div>
    );
  };

  return (
    <div className={` ${i18n.language === 'he' ? styles.heb : ''}`}>
      <EditProfileHeader
        title={getTitle()}
        save={save}
        close={props.close}
        allowSave={allowSave()}
      />
      <div className={`${centerFlexColumn()}`}>
        <div className={`${styles.wrapper}`}>
          <ProductToggle
            title={'Verified creator'}
            isOn={verified}
            onChange={() => setVerified(!verified)}
          />
          <ProductToggle
            title={'Featured on home page'}
            isOn={featuredOnMainPage}
            onChange={() => setFeaturedOnMainPage(!featuredOnMainPage)}
          />
          {/* <ProductToggle
            title={'Visible on Search'}
            isOn={visibleOnSearch}
            onChange={() => setVisibleOnSearch(!visibleOnSearch)}
          /> */}
          <Inputs
            setCategoryIds={setCategoryIds}
            setTags={setTags}
            tags={tags}
            categoryIds={categoryIds}
          />
          <CurrencySelection
            currency={currency}
            onSelect={(currency) => setCurrency(currency)}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(EditProfileAdmin);
