import { connect } from 'react-redux';
import React, { useEffect, Suspense, useState } from 'react';
import { SIGN_IN, SIGN_UP } from '../../constants/routes';
import { Navbar, Row } from 'react-bootstrap';
import styles from './Navbar.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import * as bootstrapUtils from '../../utils/bootstrapUtils';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import icon_profile from './Profile.svg';
import icon_profile_black from './ProfileBlack.svg';
import userManager from '../../managers/userManager';
import { useDispatch } from 'react-redux';
import { fetchCurrentUserDetails, fetchHome } from '../../store/routines';
import { withCurrentUser } from '../../shared/hoc';
import VLRegion from './../../model/vlRegion';
import { setRegion } from '../../store/actions/global';
import { Sidebar } from '../Sidebar';
import icon_activity from './header_ask.svg';
import icon_activity_soft from './header_askSoft.svg';
import CloseButton from '../closeButton';
import { routes } from '../../constants';
import OnboardingFlow from '../../Flows/OnboardingFlow';
import { Box, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MailIcon from '@material-ui/icons/Mail';
import Badge from '@material-ui/core/Badge';

const RegionPicker = React.lazy(() => import('./RegionPicker'));
const NavSearch = React.lazy(() =>
  import('../NavSearch').then(({ NavSearch }) => ({ default: NavSearch }))
);
const LangSwitcher = React.lazy(() =>
  import('../LangSwitcher').then(({ LangSwitcher }) => ({
    default: LangSwitcher,
  }))
);
// const Sidebar = React.lazy(() =>
//   import('../Sidebar').then(({ Sidebar }) => ({ default: Sidebar }))
// );
const AuthModal = React.lazy(() => import('../../signup/AuthModal/AuthModal'));

const AUTH_STATE_SHOW_LOGIN = SIGN_IN;
const AUTH_STATE_SHOW_SIGNUP = SIGN_UP;
const AUTH_STATE_SHOW_NONE = 'none';

const AuthModalContainer = (props: any) => {
  const { showAuth, onCloseAuthModal, onAuthSuccess } = props;

  return (
    <AuthModal
      show={showAuth}
      onAuthSuccess={onAuthSuccess}
      onClose={onCloseAuthModal}
    />
  );
};

/**
 *
 */
type NewNavbarProps = {
  onLanguageChanged?: any;
  transparent: boolean;
  theme?: any; //if it's a drak or white theme
  currentUser: any;
  width: any;
  showSearch?: Boolean;
  hideCenterLogo?: Boolean;
  background: any;
  currentRegion?: VLRegion;
  regionsList: VLRegion[];
  onShopNowClicked?: any;
  shopNowCustomTitle?: string;
  onLoginPressed?: any;
  onSignupPressed?: any;
  onQueryChanged?: any;
  onQuerySubmitted?: any;
  onRegionChanged?: Function;
  hideActivity?: Boolean;
  searchPlaceholder?: string;
  isConnected?: Boolean;
  withBorder?: boolean;
  hideAuthComponents?: boolean;
  showCloseButtonOnly?: boolean;
  onCloseButtonPressed?: Function;
  accountDetails?: any;
};

const NewNavbar = (props: NewNavbarProps) => {
  const {
    onLanguageChanged,
    transparent,
    theme,
    currentUser,
    showSearch,
    background,
    currentRegion,
    regionsList,
    hideCenterLogo,
    onShopNowClicked,
    shopNowCustomTitle,
    onQueryChanged,
    onQuerySubmitted,
    onRegionChanged,
    searchPlaceholder,
    withBorder,
    hideAuthComponents,
    showCloseButtonOnly,
    onCloseButtonPressed,
    accountDetails,
  } = props;

  const authorized = props.isConnected && currentUser;

  console.trace();

  const { t, i18n } = useTranslation();
  const [showSidebar, setShowSidebar] = React.useState(false);
  const [showSearchMobile, setShowSearchMobile] = React.useState(false);
  const [internalCurrentRegion, setInternalCurrentRegion] = useState<
    VLRegion | undefined
  >(undefined);
  interface AuthState {
    state: string;
  }
  const [showAuth, setShowAuth] = useState(false);

  const history = useHistory();

  // console.log('showAuthState?.state: ', showAuthState?.state);

  const showLoginFunc = () => {
    // console.log('showLoginFunc');
    setShowAuth(true);
    // history.push('/signup#signin');
  };

  const showSignUpFunc = () => {
    // console.log('showSignUpFunc');
    setShowAuth(true);
    // history.push('/signup');
  };

  const onCloseAuthModal = () => {
    // console.log('onCloseAuthModal');
    setShowAuth(false);
  };

  const onAuthSuccess = () => {
    // console.log('onCloseAuthModal');
    // console.log('onAuthSuccess');
    setShowAuth(false);
    // console.log('onAuthSuccess done');
    //take to onboarding!!
    //fetch account details
  };

  let pathname = window.location.pathname.toString();

  let isHomeScreen = pathname === '/' || pathname === '';

  const dispatch = useDispatch();
  useEffect(() => {
    if (!!currentUser) {
      // dispatch(fetchCurrentUserDetails.success(userManager.currentUser));
    }
  }, [currentUser]);

  // console.log(`showSearch = ${showSearch}`);

  let searchVisible = pathname === '/' || pathname === '';

  const showCenterLogo = !searchVisible && hideCenterLogo === false;
  const RegionPickerContainer = () => {
    return (
      <span className={`ml-2 mr-2`}>
        <RegionPicker
          fromSideBar={false}
          hideText={false}
          menuAlignToBottom={false}
          currentRegion={internalCurrentRegion ?? currentRegion}
          regions={regionsList}
          theme={theme}
          onRegionSelected={(region: VLRegion) => {
            setInternalCurrentRegion(region);
            if (onRegionChanged) onRegionChanged(region);
          }}
        />
      </span>
    );
  };

  /**
   * Authenicated user info
   * @returns component
   */
  const AuthedUserInfo = () => {
    const user = userManager.getUser();
    if (!user.fullName || !authorized) {
      return null;
    }

    // console.log('user', user);
    let name = user.fullName();
    let profilePictureURL = user.profilePictureURL;

    const ActivityIcon = () => {
      return authorized ? (
        <Link to={{ pathname: `/activity` }} className={styles.hideInMobile}>
          <div className={`${styles.buttonSmall} ${styles.messageIcon}`}>
            <Badge badgeContent={0} color="primary">
              <MailIcon style={{ color: isDarkMode ? 'white' : 'black' }} />
            </Badge>
          </div>
        </Link>
      ) : null;
    };

    return (
      <div
        className={`${styles.userInfo} d-flex flex-row align-items-center justify-content-center`}
      >
        <Link to={{ pathname: `/account` }} className={styles.hideInMobile}>
          <div className="flex-fill d-flex flex-row align-items-center justify-content-center">
            <div className={styles.profile}>
              {profilePictureURL ? (
                <img className={styles.profile_img} src={profilePictureURL} />
              ) : null}
            </div>
            <div className={styles.profile_text}>{name}</div>
          </div>
        </Link>
        <ActivityIcon />
      </div>
    );
  };

  /**
   * Non authed user header button container on dekstop
   * @returns
   */
  const NonAuthedUserInfoDekstop = () => {
    return authorized ? null : (
      <div
        className={`d-flex flex-row align-items-center ${styles.nonAuthedUserInfo}`}
      >
        <div
          onClick={showLoginFunc}
          className={`btn ${bootstrapUtils.centerFlexColumn()} ${
            styles.logInButton
          }`}
        >
          {t('log_in')}
        </div>
        <div
          onClick={showSignUpFunc}
          className={`btn ${bootstrapUtils.centerFlexColumn()} ${
            styles.signUpButton
          }`}
        >
          {t('sign_up')}
        </div>
      </div>
    );
  };

  /**
   * Non authed user header button container on mobile
   * @returns
   */
  const NonAuthedUserInfoMobile = () => {
    return authorized ? null : (
      <div
        className={`d-flex flex-row align-items-center ${styles.nonAuthedUserInfo}`}
      >
        <div
          onClick={showLoginFunc}
          className={`btn ${bootstrapUtils.centerFlexColumn()} ${
            styles.authButtonMobile
          }`}
        >
          {t('log_in')}
        </div>
      </div>
    );
  };

  /**
   * Search bar component with textfield
   */
  const SearchBarDesktop = () => {
    if (!showSearch) {
      return null;
    }
    const onQueryChangedFired = (query?: any) => {
      // console.log('navbar: onQueryChanged', query);
      onQueryChanged && onQueryChanged(query);
    };

    const onQuerySubmittedFired = (query?: any) => {
      // console.log('navbar: onQuerySubmittedFired', query);
      onQuerySubmitted(query);
    };
    return (
      <Box display={{ xs: 'none', sm: 'block' }}>
        <NavSearch
          searchPlaceholder={searchPlaceholder}
          theme={theme}
          onQueryChanged={onQueryChangedFired}
          onQuerySubmitted={onQuerySubmittedFired}
          hide={false}
        />
      </Box>
    );
  };

  /**
   * Search bar component with textfield
   */
  const SearchBarMobile = () => {
    if (!showSearch) {
      return null;
    }
    const onQueryChangedFired = (query?: any) => {
      // console.log('navbar: onQueryChanged', query);
      onQueryChanged && onQueryChanged(query);
    };

    const onQuerySubmittedFired = (query?: any) => {
      // console.log('navbar: onQuerySubmittedFired', query);
      onQuerySubmitted(query);
    };
    return (
      <Box display={{ xs: 'block', sm: 'none' }} id="search-button-mobile">
        <NavSearch
          searchPlaceholder={searchPlaceholder}
          theme={theme}
          onQueryChanged={onQueryChangedFired}
          onQuerySubmitted={onQuerySubmittedFired}
          hide={false}
        />
      </Box>
    );
  };

  const onCloseSideBarPressed = (close: boolean) => {
    setShowSidebar(close);
  };

  /**
   * Hamburger menu (change this asap)
   * @returns
   */
  const HamburgerMenu = () => {
    return (
      <div
        className={`position-relative`}
        style={{ height: '50px', width: '50px' }}
      >
        <div
          className={`${styles.hamburger} ${
            showSidebar ? styles.hamburgerOpen : ''
          }`}
          onClick={(event) => {
            event.stopPropagation();
            onCloseSideBarPressed(!showSidebar);
          }}
        >
          <div className={`${styles.line} ${styles.line1}`}></div>
          <div className={`${styles.line} ${styles.line2}`}></div>
          <div className={`${styles.line} ${styles.line3}`}></div>
        </div>
      </div>
    );
  };

  /**
   * Side bar container
   * @returns
   */
  const SidebarContainer = () => {
    return (
      <Sidebar
        onClose={() => onCloseSideBarPressed(true)}
        theme={theme}
        regions={regionsList}
        showLogin={showSignUpFunc}
        currentRegion={internalCurrentRegion ?? currentRegion}
        isVisible={showSidebar}
        onLanguageChanged={onLanguageChanged}
        onRegionSelected={(region: VLRegion) => {
          setInternalCurrentRegion(region);
          if (onRegionChanged) onRegionChanged(region);
        }}
      />
    );
  };

  /**
   * kre8.tv brand logo
   * @returns
   */
  const BrandLogo = () => {
    return (
      <Navbar.Brand
        href="/"
        className={cn(
          styles.logo,
          { [styles.centerLogo]: showCenterLogo },
          'm-0'
        )}
      >
        kre8.tv
      </Navbar.Brand>
    );
  };

  /**
   * Shop now CTA button
   * @returns
   */
  const ShopNowButton = () => {
    return onShopNowClicked ? (
      <div
        onClick={onShopNowClicked}
        className={`clickable ${styles.shopNowButton} btn d-flex align-items-center justify-content-center`}
      >
        {shopNowCustomTitle ?? t('shop_now')}
      </div>
    ) : null;
  };

  /**
   * Trailing elements in navbar - mobile
   * @returns
   */
  const TrailingSideElementsMobile = () => {
    return (
      <div className={`${styles.trailingSideElementsMobile}`}>
        <Row className="d-lg-none d-xl-none align-items-center" noGutters>
          {showCloseButtonOnly ? null : <ShopNowButton />}
          {showCloseButtonOnly || showSearch === false ? null : (
            <IconButton
              onClick={() => setShowSearchMobile(!showSearchMobile)}
              style={{ background: showSearchMobile ? '#5d36bd' : 'none' }}
            >
              <SearchIcon style={{ color: 'white' }} />
            </IconButton>
          )}
          {showCloseButtonOnly ? null : <HamburgerMenu />}
          {showCloseButtonOnly ? (
            <CloseButton
              onClose={() => {
                if (onCloseButtonPressed) onCloseButtonPressed();
              }}
            />
          ) : null}
        </Row>
        <SidebarContainer />
      </div>
    );
  };

  /**
   * Trailing elements in navbar - dekstop
   * @returns
   */
  const TrailingSideElementsDesktop = () => {
    return (
      <div className={styles.trailingSideInputs}>
        <div className="d-flex flex-row align-items-center">
          <RegionPickerContainer />
          <LangSwicherContainer />
        </div>
      </div>
    );
  };

  /**
   * Lang switcher container
   */
  const LangSwicherContainer = () => {
    return <LangSwitcher onLanguageChanged={onLanguageChanged} theme={theme} />;
  };

  const isDarkMode = theme === 'dark';

  return (
    <>
      <Navbar
        sticky="top"
        style={background ? { background, border: 'none' } : {}}
        bg={theme === 'dark' ? '' : 'white'}
        className={cn(styles.navbar, 'w-100', 'justify-content-between', {
          [styles.rtl]: i18n.language === 'he',
          [styles.transparent]: transparent,
          [styles.heb]: i18n.language === 'he',
          [styles.dark]: theme === 'dark',
          [styles.white]: theme === 'white',
          [styles.withBorder]: withBorder ?? true,
        })}
      >
        <div className={`${bootstrapUtils.centerFlexColumn()} w-100`}>
          <div
            className={`${
              styles.wrapper
            } ${bootstrapUtils.lclFlexRow()}  d-flex flex-grow-1 w-100`}
            style={showSidebar ? { zIndex: 100 } : { zIndex: 100 }}
          >
            <div
              className={`${''} d-flex flex-row justify-content-between align-items-center flex-grow-1 `}
            >
              {showSearchMobile ? <SearchBarMobile /> : <BrandLogo />}
              <SearchBarDesktop />
              <AuthedUserInfo />
              {hideAuthComponents ? null : <NonAuthedUserInfoDekstop />}
              {hideAuthComponents ? null : <NonAuthedUserInfoMobile />}
              <TrailingSideElementsDesktop />
              <TrailingSideElementsMobile />
            </div>
          </div>
          <AuthModalContainer
            showAuth={showAuth}
            onAuthSuccess={onAuthSuccess}
            onCloseAuthModal={onCloseAuthModal}
          />
        </div>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isConnected: state.user.isConnected,
    accountDetails: state.user.accountDetails,
  };
};

// @ts-ignore
export default connect(mapStateToProps)(withCurrentUser(NewNavbar));
