import Session from "./session";

class SessionAccess {
  id = "";
  sessionId = ""; //the currentUser who is following
  userId = ""; //the currentUser who is following
  createdOn = null;
  lastUpdated = null;

  //Pricing
  price = 0.0;
  currency = "USD";

  valid = true;

  user = null;
  session = null;

  purchaseDateString() {
    let date = new Date(this.createdOn);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }
  priceString() {
    let price = this.price;
    let currency = this.currency;

    // console.log("currency =", currency);
    var settings = {
      style: "currency",
      currency: currency,
    };
    if (currency === "ILS") {
      settings.maximumSignificantDigits = 1;
      settings.minimumSignificantDigits = 1;
    }
    var formatter = new Intl.NumberFormat("en-US", settings);

    let str = formatter.format(price);
    return str;
  }

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  static fromJSON(json) {
    var access = new SessionAccess();
    access.id = json.id;
    access.sessionId = json.session_id;
    access.userId = json.purchaser_id;
    access.lastUpdated = Number(json.last_updated);
    access.createdOn = Number(json.created_on);
    access.price = Number(json.price);
    access.currency = json.currency;
    access.createdOn = json.created_on;
    access.valid = json.valid ? Boolean(json.valid) : true; //true by default
    return access;
  }
}

export default SessionAccess;
