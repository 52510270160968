import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import 'video-react/dist/video-react.css';
import * as ROUTES from './constants/routes';
import { updateBrowserLanguage, updateUserLanauge } from './utils/vlStrings';
import userManager from './managers/userManager';
import AccountScreenNew from './AccountScreenNew/AccountScreenNew';
import firebase from 'firebase';
import { SignUp, SignIn } from './signup';
import { hideAuth, signOut } from './store/routines';
import { connect } from 'react-redux';
import Confirm from './signup/Verification/Confirm';
import { RouteConfig } from 'react-router-config';
import { IntercomProvider } from 'react-intercom-hook';
import { getI18n } from 'react-i18next';
import AuthenticationScreen from './signup/AuthenticationScreen';
import Profilev2 from './Profile/Profile';
import Discover from './components/pages/Discover/Discover';
import EditProfile from './EditProfile/EditProfile';
import UserSettingsScreen from './AccountScreenNew/UserSettingsScreen/UserSettingsScreen';
import { ThemeProvider } from 'react-bootstrap';
import { AuthDialog } from './signup/AuthDialog';
import NewAuthenticationFlowContainer from './signup/NewAuthenticationFlow/NewAuthenticationFlowContainer';
import FinishSignupScreen from './signup/FinishSignup/FinishSignup';
import ClaimAccountScreen from './components/pages/ClaimAccount/ClaimAccountScreen';
import ChallengeDetail from './components/pages/Challenges/Detail/ChallengeDetail';
import CampaignBooking from './components/pages/Campaigns/CampaignBooking';
import SearchMaster from './components/pages/Search';
import GetComissionScreen from './components/pages/UserProfile/GetComissionScreen';
import { AuthModal } from './signup/AuthModal';
import OnboardingFlow from './Flows/OnboardingFlow';
import BusinessLandingPage from './LandingPages/business';
import { UnderConstruction } from './components/WebsiteBlockerView';
const LandingPage = React.lazy(
  () => import('./components/landingPage/landingPage')
);
const AccountScreen = React.lazy(
  () => import('./components/pages/accountScreen')
);
const PaywallScreen = React.lazy(
  () => import('./components/pages/paywallScreen')
);
const AddCardScreen = React.lazy(
  () => import('./components/pages/addCardScreen')
);
const CloseFanScreen = React.lazy(
  () => import('./components/pages/closeFanScreen')
);
const EditProfileScreen = React.lazy(
  () => import('./components/pages/editProfileScreen')
);
const SessionPlayerScreen = React.lazy(
  () => import('./components/pages/sessionPlayerScreen')
);
const MainScreen = React.lazy(() => import('./components/pages/mainScreen'));
const ViewDeliveryScreen = React.lazy(
  () => import('./components/pages/viewDeliveryScreen')
);
const AdminDashboardScreen = React.lazy(() =>
  import('./admin').then(({ AdminDashboardScreen }) => ({
    default: AdminDashboardScreen,
  }))
);
const SessionPreviewScreen = React.lazy(
  () => import('./components/pages/sessionPreviewScreen')
);
const SessionOverviewScreen = React.lazy(
  () => import('./components/pages/sessionOverviewScreen')
);
const CreateSessionScreen = React.lazy(
  () => import('./components/pages/createSessionScreen')
);
const UserManagementScreen = React.lazy(
  () => import('./components/pages/userManagementScreen')
);
const RequestBookingItemScreen = React.lazy(
  () => import('./components/pages/requestBookingItemScreen')
);
const BookingPage = React.lazy(
  () => import('./components/pages/BookingPage/BookingPage')
);
const PostScreen = React.lazy(() => import('./components/pages/postScreen'));
const CreatorPayoutScreen = React.lazy(
  () => import('./components/pages/creatorPayoutScreen')
);
const CheckoutScreen = React.lazy(
  () => import('./components/pages/checkoutScreen')
);
const RateDeliveryScreen = React.lazy(
  () => import('./components/pages/rateDeliveryScreen')
);
const QuickSignUpScreen = React.lazy(
  () => import('./components/pages/quickSignUpScreen')
);

const UploadContentScreen = React.lazy(
  () => import('./components/pages/UploadContent/uploadContentScreen')
);

const VLBaseHome = React.lazy(() => import('./VLBase/VLBaseHome'));

const CreatorCategories = React.lazy(
  () => import('./components/CreatorCategories/CreatorCategories')
);

const SubscriptionPaywall = React.lazy(
  () => import('./components/Paywall/SubscriptionPaywall')
);

const SubscriptionFlow = React.lazy(
  () => import('./Flows/SusbcriptionFlow/SusbcriptionFlow')
);

const Page404 = React.lazy(() => import('./page404/page404'));

const log = require('loglevel');

require('dotenv').config();

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    foregroundText: Palette['primary'];
  }
  interface PaletteOptions {
    foregroundText: PaletteOptions['primary'];
  }
}

let developmentConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
let productionConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export function isProduction() {
  let env = process.env.REACT_APP_ENV;
  //console.log("proccess.env = ", env);
  return env === 'production';
}

export function isDev() {
  let env = process.env.REACT_APP_ENV;
  //console.log("proccess.env = ", env);
  return env === 'development';
}

export const fb = firebase.initializeApp(
  isProduction() ? productionConfig : developmentConfig
);

const initUser = userManager.init();

interface IProfile {
  profileId?: string;
  flow: 'none' | 'booking' | 'subscription';
  showNavBar: boolean;
}

// const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const advancedMatching = {};
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

export function isHebrew() {
  return getI18n().language == 'he';
}

export function isInIsraelTimeZone() {
  return (
    Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.toLowerCase()
      .indexOf('israel') > -1 ||
    Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.toLowerCase()
      .indexOf('jerusalem') > -1
  );
}

//@ts-ignore
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'kre8font',
      'Montserrat',
      'Assistant',
      'Rubik',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: 'Montserrat, Rubik',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '32px',
      lineHeight: '110%',
      letterSpacing: '0px',
      color: '#111111',
    },
    h2: {
      fontFamily: 'Montserrat, Rubik',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '100%',
      letterSpacing: '-0.41px',
      color: '#111111',
    },
    h3: {
      fontFamily: 'Montserrat, Rubik',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '100%',
      letterSpacing: '0px',
      color: '#111111',
    },
    h4: {
      fontFamily: 'Montserrat, Rubik',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '120%',
      letterSpacing: '-0.41px',
      color: '#111111',
    },
    h5: {
      fontFamily: 'Montserrat, Rubik',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '100%',
      letterSpacing: '-0.44px',
      color: '#111111',
    },
    h6: {
      fontFamily: 'Montserrat, Rubik',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '100%',
      letterSpacing: '-0.44px',
      color: '#111111',
    },
    subtitle1: {
      fontFamily: 'Assistant',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '100%',
      letterSpacing: '-0.15px',
      color: '#000000',
    },
    subtitle2: {
      fontFamily: 'Assistant',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '12px',
      letterSpacing: '-0.15px',
      color: '#000000',
    },
    body1: {
      fontFamily: 'Assistant',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '14px',
      letterSpacing: '-0.3px',
      color: '#000000',
    },
    body2: {
      fontFamily: 'Assistant',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '12px',
      color: '#000000',
    },
    button: {
      fontFamily: 'Assistant',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: '-0.44px',
      color: '#000000',
    },
  },
  palette: {
    type: 'light',
    background: {
      default: '#ffffff',
      paper: '#fafafa',
    },
    primary: {
      light: '#AE9BDE',
      main: '#5d36bd',
      dark: '#22068c',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ff9c40',
      main: '#FFBE8E',
      dark: '#c43a00',
      contrastText: '#ffffff',
    },
    foregroundText: {
      main: '#111111',
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
    },
  },
});

export const baseURL = (): string => {
  if (window.location.href.includes('localhost')) {
    return 'localhost:3000/';
  } else if (window.location.href.includes('1337')) {
    return 'https://celebrate-staging-1337.web.app/';
  } else {
    return 'https://kre8.tv/';
  }
};
const routes: RouteConfig[] = [
  {
    path: '/confirm',
    render() {
      return (
        <AuthenticationScreen>
          <Confirm />
        </AuthenticationScreen>
      );
    },
  },
  {
    path: ROUTES.SIGN_UP,
    render() {
      return (
        <AuthenticationScreen>
          <SignUp />
        </AuthenticationScreen>
      );
    },
  },
  {
    path: ROUTES.SIGN_IN,
    render() {
      return (
        <AuthenticationScreen>
          <SignIn />
        </AuthenticationScreen>
      );
    },
  },
  {
    path: ROUTES.ACCOUNT_TOKEN,
    component: AccountScreen,
  },
  {
    path: ROUTES.ADMIN_DASHBOARD,
    component: AdminDashboardScreen,
  },
  {
    path: ROUTES.USER_DASHBOARD,
    component: UserManagementScreen,
  },
  {
    path: ROUTES.ADD_CARD,
    component: AddCardScreen,
  },
  {
    path: ROUTES.PAY_WALL,
    component: PaywallScreen,
  },
];

const RenderProfile = (props: IProfile) => {
  const { profileId, flow, showNavBar } = props;

  return <UnderConstruction />;

  return (
    <Profilev2
      //@ts-ignore
      profileId={profileId}
      showBooking={flow === 'booking'}
    ></Profilev2>
  );
};

class App extends PureComponent {
  render() {
    //@ts-ignore
    let redirectUrl = new URLSearchParams(window.location.search).get(
      'redirectUrl'
    );
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Helmet>
          <title>
            1000+ creators on KRE8.TV. Enjoy exclusive content, personalized
            videos, and more
          </title>
        </Helmet>
        <Router>
          <IntercomProvider
            appId={process.env.REACT_APP_INTERCOM_APP_ID ?? ''}
            autoBoot={false}
          >
            <Switch>
              <Route path={ROUTES.HOME}>
                {this.renderMainScreenWithTab(0)}
              </Route>
              <Route path={ROUTES.DISCOVER}>
                <Discover />
              </Route>
              <Route path="/confirm">
                <AuthenticationScreen>
                  <Confirm />
                </AuthenticationScreen>
              </Route>
              <Route path={ROUTES.SIGN_UP}>
                <AuthenticationScreen />
              </Route>
              <Route path={ROUTES.SIGN_IN}>
                <AuthenticationScreen>
                  <AuthDialog />
                </AuthenticationScreen>
              </Route>
              <Route
                exact
                path={ROUTES.LOG_OUT}
                component={() => {
                  // @ts-ignore
                  this.props.logout();
                  userManager.signOut();
                  return <Redirect to={ROUTES.HOME} />;
                }}
              />
              <Route path={ROUTES.UPLOAD_CONTENT}>
                <UploadContentScreen />
              </Route>
              <Route path={ROUTES.BASE_PROFILE_HOME}>
                <VLBaseHome />
              </Route>
              <Route
                path={ROUTES.SUBSCRIPTION_PROFILE}
                render={({ match }) => {
                  return this.renderFlow(
                    'subscription',
                    match.params.profileId
                  );
                }}
              />
              <Route path={ROUTES.WEB_APP}>
                {this.renderMainScreenWithTab(0)}
              </Route>
              <Route path={ROUTES.ACCOUNT_TOKEN}>
                <AccountScreen />
              </Route>
              <Route
                path={ROUTES.PROFILE_PAGE}
                render={({ match }) => {
                  return (
                    <RenderProfile
                      showNavBar={false}
                      flow="none"
                      profileId={match.params.profileId}
                    />
                  );
                }}
              ></Route>
              <Route path={ROUTES.PAGE_404} component={Page404}></Route>
              <Route
                path={ROUTES.ACCOUNT_DETAILS}
                render={({ match }) => {
                  // //console.log("ROUTES.SESSION this.props = ", this.match);
                  //pass token to user manager
                  userManager.tokenId = match.params.tokenId;
                  return this.renderMainScreenWithTab(3);
                }}
              />
              <Route path={ROUTES.VIEW_DELIVERY_SCREEN}>
                <ViewDeliveryScreen />
              </Route>
              <Route
                path={ROUTES.RATE_DELIVERY}
                render={({ match }) => {
                  return (
                    <RateDeliveryScreen
                      // @ts-ignore
                      transactionId={match.params.transactionId}
                    />
                  );
                }}
              />
              <Route
                path={ROUTES.VIEW_POST}
                render={({ match }) => {
                  // @ts-ignore
                  return <PostScreen postId={match.params.postId} />;
                }}
              />
              <Route path={ROUTES.CHECKOUT}>{this.renderCheckout()}</Route>
              <Route path={ROUTES.ADMIN_DASHBOARD}>
                <AdminDashboardScreen />
              </Route>
              <Route path={ROUTES.USER_DASHBOARD}>
                <UserManagementScreen />
              </Route>
              <Route path={ROUTES.ACCOUNT_AUTHED}>
                {this.renderMainScreenWithTab(3)}
              </Route>
              <Route path={ROUTES.LANDING_V2}>
                {/* <LandingPage /> */}
                <UnderConstruction />
              </Route>
              <Route path={ROUTES.LANDING_BUSINESS}>
                {/* <BusinessLandingPage /> */}
                <UnderConstruction />
              </Route>
              <Route path={ROUTES.ACTIVITY}>
                {this.renderMainScreenWithTab(1)}
              </Route>
              <Route path={ROUTES.ADD_CARD}>
                <AddCardScreen />
              </Route>
              <Route path={ROUTES.PAY_WALL}>
                <PaywallScreen />
              </Route>
              <Route
                path={ROUTES.SESSION_FROM_APP_WITH_TOKEN}
                render={({ match }) => {
                  // //console.log("ROUTES.SESSION this.props = ", this.match);
                  //pass token to user manager
                  userManager.tokenId = match.params.tokenId;
                  return this.renderSessionPreviewScreen(
                    match.params.sessionId
                  );
                }}
              />
              <Route
                path={ROUTES.SESSION_FROM_APP}
                render={({ match }) => {
                  return this.renderSessionPreviewScreen(
                    match.params.sessionId
                  );
                }}
              />
              <Route
                path={ROUTES.SESSION_DASHBOARD}
                render={({ match }) => (
                  <SessionOverviewScreen
                    // @ts-ignore
                    sessionId={match.params.sessionId}
                  />
                )}
              />
              <Route
                path={ROUTES.SESSION_NO_AUTH}
                render={({ match }) => {
                  // //console.log("ROUTES.SESSION_NO_AUTH this.props = ", this.match);
                  return (
                    <SessionPreviewScreen
                      // @ts-ignore
                      sessionId={match.params.sessionId}
                    />
                  );
                }}
              />
              <Route
                path={ROUTES.SESSION_PLAYER}
                render={({ match }) => {
                  //console.log("ROUTES.SESSION_PLAYER");
                  return (
                    // @ts-ignore
                    <SessionPlayerScreen sessionId={match.params.sessionId} />
                  );
                }}
              />
              <Route
                path={ROUTES.CLOSE_CIRCLE_FROM_APP}
                render={({ match }) => {
                  userManager.tokenId = match.params.tokenId;
                  return this.renderCloseFanScreen(
                    match.params.profileId,
                    true
                  );
                }}
              />
              <Route
                path={ROUTES.CLOSE_CIRCLE}
                render={({ match }) => {
                  //console.log("ROUTES.CLOSE_CIRCLE this.props = ", this.match);
                  return this.renderCloseFanScreen(match.params.id);
                }}
              />
              <Route
                path={ROUTES.ONBOARDING}
                render={({ match }) => {
                  //console.log("ROUTES.CLOSE_CIRCLE this.props = ", this.match);
                  return <OnboardingFlow />;
                }}
              />
              <Route
                path={ROUTES.BUSINESS_REQUEST_REFERRAL}
                render={({ match }) => {
                  return this.renderBusinessRequest(
                    match.params.profileId,
                    match.params.referralId
                  );
                }}
              />
              <Route
                path={ROUTES.BUSINESS_REQUEST}
                render={({ match }) => {
                  return this.renderBusinessRequest(match.params.profileId);
                }}
              />
              <Route
                path={ROUTES.BUSINESS_REQUEST_REFERRAL_SHORT_REFERRAL}
                render={({ match }) => {
                  return this.renderBusinessRequest(
                    match.params.profileId,
                    match.params.referralId
                  );
                }}
              />
              <Route
                path={ROUTES.BUSINESS_REQUEST_REFERRAL_SHORT}
                render={({ match }) => {
                  return (
                    <BookingPage
                      // @ts-ignore
                      flow="forBusiness"
                      profileId={match.params.profileId}
                    />
                  );
                }}
              />
              <Route
                path={ROUTES.QUICK_SIGN_UP}
                render={({ match }) => <QuickSignUpScreen />}
              />
              <Route
                path={ROUTES.INVITATION}
                component={() => {
                  window.location.replace('https://kre8.tv/get');
                  return null;
                }}
              />
              <Route
                path={ROUTES.INVITE}
                render={({ match }) => <QuickSignUpScreen />}
              />
              <Route
                path={ROUTES.BUSINESS_REQUEST_FROM_IOS}
                render={({ match }) => {
                  return this.renderBusinessRequest();
                }}
              />
              <Route
                path={ROUTES.BOOKING_WITH_ITEM}
                render={({ match }) => {
                  return this.renderBookingScreen(
                    match.params.profileId,
                    match.params.itemId
                  );
                }}
              />
              <Route
                exact
                path={ROUTES.SETTINGS}
                render={({ match }) => {
                  return <UserSettingsScreen />;
                }}
              />
              <Route
                path={ROUTES.BOOKING_GENERAL}
                render={({ match }) => {
                  return this.renderBookingScreen(match.params.profileId);
                }}
              />
              <Route
                exact
                path={ROUTES.WEB_SITE_IL_WEB_APP}
                render={({ match }) => {
                  //console.log("ROUTES.CLOSE_CIRCLE this.props = ", this.match);
                  updateUserLanauge(true);
                  return <MainScreen />;
                }}
              />
              <Route
                exact
                path={ROUTES.WEB_SITE_IL}
                component={() => {
                  //console.log("ROUTES.LANDING");
                  window.location.href = baseURL() + 'israel/';
                  return null;
                }}
              />
              <Route
                path={ROUTES.PROFILE_GOTO}
                render={({ match }) => {
                  return (
                    <RenderProfile
                      flow="none"
                      profileId={match.params.profileId}
                      showNavBar={false}
                    />
                  );
                }}
              ></Route>
              <Route
                path={ROUTES.PROFILE}
                render={({ match }) => {
                  return (
                    <RenderProfile
                      showNavBar={false}
                      flow="none"
                      profileId={match.params.id}
                    />
                  );
                }}
              />
              <Route
                path={ROUTES.EDIT_PROFILE_PAGE}
                render={({ match }) => {
                  //console.log("ROUTES.PROFILE this.props = ", this.match);
                  return <EditProfile />;
                }}
              />
              <Route
                path={ROUTES.EDIT_PROFILE_NOT_LOGIN}
                render={({ match }) => {
                  return <EditProfile />;
                }}
              />
              <Route
                path={ROUTES.CATEGORY_CREATOR_EMPTY}
                render={({ match }) => {
                  // console.log("ROUTES.CATEGORY_CREATOR this.props = ", match);
                  return (
                    // @ts-ignore
                    <CreatorCategories categoryId={match.params.categoryId} />
                  );
                }}
              />
              <Route
                path={ROUTES.CATEGORY_BUSINESS}
                render={({ match }) => {
                  // console.log("ROUTES.CATEGORY_CREATOR this.props = ", match);
                  return (
                    // @ts-ignore
                    <CreatorCategories isBusiness={true} />
                  );
                }}
              />
              <Route
                path={ROUTES.CATEGORY_CREATOR}
                render={({ match }) => {
                  // console.log("ROUTES.CATEGORY_CREATOR this.props = ", match);
                  return (
                    // @ts-ignore
                    <CreatorCategories categoryId={match.params.categoryId} />
                  );
                }}
              />
              <Route
                path={ROUTES.OFFER_UNLOCK_100_PERCENT_EARNINGS}
                render={({ match }) => {
                  return (
                    // @ts-ignore
                    <GetComissionScreen />
                  );
                }}
              />
              <Route
                path={ROUTES.PROFILE_LANDING_PAYMENT}
                render={({ match }) => {
                  return (
                    <RenderProfile
                      showNavBar={false}
                      flow="none"
                      profileId={match.params.profile_id}
                    />
                  );
                }}
              ></Route>
              {/* show landing page for now when landing */}
              <Route
                exact
                path={ROUTES.LANDING}
                render={({ match }) => {
                  //console.log("ROUTES.CLOSE_CIRCLE this.props = ", this.match);
                  updateUserLanauge(true);
                  // return this.renderMainScreenWithTab(0);
                  // return <BusinessLandingPage />;
                  return <UnderConstruction />;
                }}
              />
              <Route
                path={ROUTES.CREATIONS_REQUEST_REFERRAL}
                render={({ match }) => {
                  if (match.params.referralId === 'mabrook') {
                    updateUserLanauge(true);
                  }
                  return this.renderPersonalRequestScreen(
                    match.params.profileId,
                    match.params.referralId
                  );
                }}
              />
              <Route
                path={ROUTES.CREATIONS_REQUEST}
                render={({ match }) => {
                  return this.renderPersonalRequestScreen(
                    match.params.profileId
                  );
                }}
              />
              <Route
                path={ROUTES.AUTH_CONNECT}
                render={({ match }) => {
                  return (
                    <AuthModal
                      show={true}
                      onClose={null}
                      onAuthSuccess={() =>
                        // @ts-ignore
                        this.onAuthSuccess(redirectUrl)
                      }
                    />
                  );
                }}
              />
              <Route
                path={ROUTES.FINISH_CONNECT}
                render={({ match }) => {
                  return (
                    <AuthModal
                      show={true}
                      onClose={null}
                      onAuthSuccess={() =>
                        // @ts-ignore
                        this.onAuthSuccess(redirectUrl)
                      }
                      startStep={'email-finish-connect'}
                    />
                  );
                }}
              />
              <Route
                path={ROUTES.CREATIONS_REQUEST_SHORT_REFERRAL}
                render={({ match }) => {
                  if (match.params.referralId === 'mabrook') {
                    updateUserLanauge(true);
                  }
                  return this.renderPersonalRequestScreen(
                    match.params.profileId,
                    match.params.referralId
                  );
                }}
              />
              <Route
                path={ROUTES.CREATIONS_REQUEST_SHORT}
                render={({ match }) => {
                  return (
                    <BookingPage
                      // @ts-ignore
                      profileId={match.params.profileId}
                    />
                  );
                }}
              />
              <Route
                path={ROUTES.CREATIONS_REQUEST_SHORT2}
                render={({ match }) => {
                  return (
                    <BookingPage
                      // @ts-ignore
                      profileId={match.params.profileId}
                    />
                  );
                }}
              />
              <Route
                path={ROUTES.NEW_SESSION}
                render={({ match }) => {
                  return <CreateSessionScreen />;
                }}
              />
              <Route
                path={ROUTES.NEW_SESSION_SHORT}
                render={({ match }) => {
                  return <CreateSessionScreen />;
                }}
              />
              <Route
                path={ROUTES.CATEGORY}
                render={({ match }) => {
                  //console.log("ROUTES.PROFILE_LANDING this.props = ", this.match);
                  return this.renderCategory(match.params.categoryId);
                }}
              ></Route>
              <Route
                path={ROUTES.CREATOR_PAYOUT_PROFILE}
                render={({ match }) => {
                  //console.log("ROUTES.PROFILE_LANDING this.props = ", this.match);
                  return this.renderCreatorPayoutScreen(match.params.profileId);
                }}
              ></Route>
              <Route
                path={ROUTES.CREATOR_PAYOUT}
                render={({ match }) => {
                  //console.log("ROUTES.PROFILE_LANDING this.props = ", this.match);
                  return this.renderCreatorPayoutScreen();
                }}
              ></Route>
              <Route
                exact
                path={ROUTES.CLAIM_ACCOUNT}
                render={({ match }) => {
                  return this.renderClaimScreen(match.params.id);
                }}
              />
              <Route
                exact
                path={ROUTES.CHALLENGE_DETAIL}
                render={({ match }) => {
                  return (
                    // @ts-ignore
                    <ChallengeDetail challengeId={match.params.challengeId} />
                  );
                }}
              />
              <Route
                path={ROUTES.EDIT_PROFILE}
                render={({ match }) => {
                  return <EditProfileScreen />;
                }}
              />
              <Route
                path={ROUTES.CAMPAIGN_BOOKING_HOME}
                render={({ match }) => {
                  updateUserLanauge(true);
                  return <CampaignBooking />;
                }}
              />
              <Route
                path={ROUTES.SEARCH}
                render={({ match }) => {
                  return <SearchMaster />;
                }}
              />
              <Route
                path={ROUTES.PROFILE_LANDING}
                render={({ match }) => {
                  return (
                    <RenderProfile
                      showNavBar={false}
                      flow={
                        window.location.href
                          .toString()
                          .includes('orderBiz=true')
                          ? 'booking'
                          : 'none'
                      }
                      profileId={match.params.profile_id}
                    />
                  );
                }}
              ></Route>
              <Route
                path={ROUTES.PROFILE_LANDING_PAYMENT}
                render={({ match }) => {
                  //console.log("ROUTES.PROFILE_LANDING this.props = ", this.match);
                  return (
                    <RenderProfile
                      showNavBar={false}
                      flow="none"
                      profileId={match.params.profile_id}
                    />
                  );
                }}
              ></Route>
              {/* show landing page for now when landing */}
              <Route
                exact
                path={ROUTES.LANDING}
                render={({ match }) => {
                  //console.log("ROUTES.CLOSE_CIRCLE this.props = ", this.match);
                  updateUserLanauge(true);
                  return (
                    <React.Fragment>
                      <MainScreen></MainScreen>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path="/"
                render={({ match }) => {
                  //console.log("ROUTES.CLOSE_CIRCLE this.props = ", this.match);
                  updateUserLanauge(true);
                  return (
                    <React.Fragment>
                      <MainScreen></MainScreen>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path=""
                render={({ match }) => {
                  //console.log("ROUTES.CLOSE_CIRCLE this.props = ", this.match);
                  updateUserLanauge(true);
                  return <MainScreen />;
                }}
              />
              <Route path="*">{() => this.NoMatch()}</Route>
              <AuthModal
                //@todo - implement some HOC which simply has this screen manage all authentication?
                show={
                  // @ts-ignore
                  this.props.showAuth
                }
                onClose={() =>
                  // @ts-ignore
                  this.props.hideAuth()
                }
                onAuthSuccess={() => {
                  // @ts-ignore
                  this.props.hideAuth();
                }}
              />
            </Switch>
          </IntercomProvider>
        </Router>
      </MuiThemeProvider>
    );
  }

  /* logic methods */

  onAuthSuccess(redirectUrl?: string) {
    if (redirectUrl) {
      //redirect
      window.location.href = redirectUrl;
    } else {
      window.location.href = '/home';
    }
  }

  /* Render Methods */
  NoMatch() {
    return this.renderMainScreenWithTab(0);
  }
  renderCategory(categoryId: string) {
    // @ts-ignore
    return this.renderMainScreenWithTab(0);
    // return <CategoryScreen categoryId={categoryId} />;
  }

  renderAgency(agencyId: string) {
    // @ts-ignore
    return this.renderMainScreenWithTab(0);
    // return <CategoryScreen agencyId={agencyId} />;
  }
  renderBusinessRequest(profileId?: string, referralId?: string) {
    return (
      <BookingPage
        referralId={referralId}
        flow="forBusiness"
        profileId={profileId}
      />
    );
  }
  renderBookingScreen(profileId?: string, itemId?: string) {
    return (
      <RequestBookingItemScreen
        // @ts-ignore
        profileId={profileId}
        itemId={itemId}
      />
    );
  }
  renderPersonalRequestScreen(profileId?: any, referralId?: any) {
    return (
      <BookingPage
        // @ts-ignore
        profileId={profileId}
        referralId={referralId}
      />
    );
  }
  renderCreatorPayoutScreen(profileId?: any) {
    return <CreatorPayoutScreen profileId={profileId} />;
  }
  renderCloseFanScreen(profileId: any, fromApp = false) {
    return (
      <CloseFanScreen
        // @ts-ignore
        profileId={profileId}
        fromApp={fromApp}
      />
    );
  }
  renderSessionPreviewScreen(sessionId: any) {
    return (
      <SessionPreviewScreen
        // @ts-ignore
        sessionId={sessionId}
      />
    );
  }
  renderMainScreenWithTab(tab: any) {
    return <MainScreen currentTab={tab} />;
  }
  renderCheckout() {
    return <CheckoutScreen />;
  }

  renderClaimScreen(profileId: string) {
    // @ts-ignore
    return <ClaimAccountScreen profileId={profileId} />;
  }

  //FLOWS

  renderFlow(flow: 'subscription', profileId: string) {
    switch (flow) {
      case 'subscription':
        return <SubscriptionFlow profileId={profileId} />;
      default:
        break;
    }
    return this.renderMainScreenWithTab(0);
  }

  componentDidMount() {
    //console.log("🚨 componentWillMount, ", firebase.apps.length);
    //console.log("env enviornment: ", process.env.REACT_APP_ENV);
    updateBrowserLanguage(navigator.language);
  }

  componentWillMount() {
    if (isProduction()) {
      log.setLevel('silent');
    } else {
      log.setLevel('trace');
      log.debug('💪🏻In debug...printing logs');
    }
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(signOut()),
  hideAuth: () => dispatch(hideAuth()),
});

export default connect(null, mapDispatchToProps)(App);
