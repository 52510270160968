import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './intercom';
import './index.css';
import { isProduction } from './App';
import * as serviceWorker from './serviceWorker';
import './custom.scss';

import { store } from './store';
import './i18n';
import PageLoader from './components/pageLoader';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './store/store';
import { auth } from 'firebase';
import { analytics } from './utils/analytics.js';

// import userStore from './store/user.store';
// console.log("US: ", userStore);

// import App from './App'
const App = React.lazy(() => import('./App'));

if (process.env.REACT_APP_ENV === 'testing') {
  auth().settings.appVerificationDisabledForTesting = true;
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Suspense fallback={<PageLoader animatedLoader={false} />}>
        <App />
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

analytics.init(isProduction());

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
