import { createReducer } from "@reduxjs/toolkit";
import { fetchAdminDashboardData } from "../../routines";

const initialState = {
  data: null,
  loading: false,
  error: null,
  filter: {
    requestsStatus: null,
  },
};

export default createReducer(initialState, {
  [fetchAdminDashboardData.SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
  }),
});
