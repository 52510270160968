import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Container } from '@material-ui/core';
import WelcomeScreen from './Welcome/WelcomeScreen';
import NavigationBar from './NavigationBar';
import WhoFor from './WhoFor';
import OcassionsScreen from './Ocassions';
import CategoriesScreen from './Categories';
import CreatorsScreen from './Creators';
import BriefScreen from './Brief';
import SummaryScreen from './Summary';
import SubmissionScreen from './Submission';
import SuccessScreen from './Success';

import { fetchHomeFeed } from '../../../../managers/v2';
import BottomBar from './BottomBar';
import { AuthModal } from '../../../../signup/AuthModal';
import Modal from '../../../Modal/Modal';
import { useTranslation } from 'react-i18next';
import {
  onSubmitCampaignClicked,
  reset,
} from '../../../../store/reducers/campaigns';
import { lclDirection } from '../../../../utils/vlStrings';
import { withSnackbar } from '../../../../shared/hoc';

/**
 * Container for the "add card" flow/modal
 */
const Paywall = (props) => {
  const { t, creator, price, onCreditCardAdded, onClose, currency } = props;

  // console.log('props...', props);
  if (!props.showAddCardScreen) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      kind="campaign"
      wrapperCustomStyle={{
        padding: '0px',
        width: '100%',
        maxWidth: '100%',
      }}
      User={creator}
      title={t('order_from')}
      buttonText={t('confirm_purchase')}
      paymentData={{ price: price, currency: currency }}
      onAddPaymentMethodSuccess={onCreditCardAdded}
    />
  );
};

const CampaignBooking = (props) => {
  const { t, i18n } = useTranslation();
  const [showAuth, setShowAuth] = useState(false);
  const [campaignPriceDetails, setCampaignPriceDetails] = useState(null);
  const [showAddCardScreen, setShowAddCardScreen] = useState(false);

  const { step, type, fromRegularBooking } = props;

  useEffect(() => {
    fetchHomeFeed((response, error) => {});
  }, []);

  const onAuthSuccess = () => {
    setShowAuth(false);
  };

  const onNeedAddPaymentMethod = (priceDetails) => {
    setCampaignPriceDetails(priceDetails);
    setShowAddCardScreen(true);
  };

  const onCardAddedSuccess = () => {
    props.onSubmitCampaignClicked();
    setShowAddCardScreen(false);
  };

  return (
    <Box maxWidth="100%" dir={lclDirection()}>
      <Container maxWidth="sm" disableGutters>
        <Box width="100%" height="100vh" position="relative">
          <NavigationBar {...props} />
          <WelcomeScreen show={step === 0} {...props} />
          <WhoFor show={step === 1} />
          <OcassionsScreen show={step === 2} type={type} {...props} />
          <CategoriesScreen show={step === 3} type={type} {...props} />
          <CreatorsScreen show={step === 4} type={type} {...props} />
          <BriefScreen show={step === 5} type={type} {...props} />
          <SummaryScreen
            reset={() => props.reset()}
            show={step === 6}
            type={type}
            onNeedAddPaymentMethod={(currency, price) =>
              onNeedAddPaymentMethod(currency, price)
            }
            onNeedAuth={() => setShowAuth(true)}
            {...props}
          />
          <SubmissionScreen show={step === 7} {...props} />
          <SuccessScreen show={step === 8} />
          <BottomBar fromRegularBooking={fromRegularBooking} />
        </Box>
      </Container>
      <AuthModal
        show={showAuth}
        onClose={() => setShowAuth(false)}
        onAuthSuccess={onAuthSuccess}
      />
      <Paywall
        price={campaignPriceDetails?.price}
        currency={campaignPriceDetails?.currency}
        onClose={() => setShowAddCardScreen(false)}
        t={t}
        showAddCardScreen={showAddCardScreen}
        onCreditCardAdded={() => onCardAddedSuccess()}
      />
    </Box>
  );
};

const mapStateToProps = ({ campaign }) => {
  return {
    step: campaign.step,
    type: campaign.type,
    fromRegularBooking: campaign.fromRegularBooking,
  };
};

export default connect(mapStateToProps, { onSubmitCampaignClicked, reset })(
  withSnackbar(CampaignBooking)
);
