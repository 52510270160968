import profileManager from './profileManager';
import api, * as RHAPI from '../constants/api';
import userManager from './userManager';
import merge from 'lodash/merge';

class RecruitmentManager {
  cachedPrefilledDetails: any = null;

  constructor() {
    this.cachedPrefilledDetails = null;
  }

  cacheDetails(details: any) {
    this.cachedPrefilledDetails = details;
    localStorage.setItem('claim_details', JSON.stringify(details));
  }

  grabDetails() {
    let raw = localStorage.getItem('claim_details');
    if (raw) {
      let details = JSON.parse(raw);
      this.cachedPrefilledDetails = null;
      localStorage.removeItem('claim_details');
      return details;
    } else {
      return null;
    }
  }

  async saveClaimAccountDetailsToCurrentUser(userDetails: any) {
    /* main account details */
    let accountObj = {
      first_name: userDetails.firstName,
      last_name: userDetails.lastName,
      profile_id: userDetails.username,
      profilePictureURL: userDetails.profilePictureUrl,
      title: userDetails.title,
      desc: userDetails.description,
      tags: userDetails.tags,
    };

    /* admin / internal details */
    let adminObj = {
      active: true,
      verified: true,
      profileStatus: 5,
      category_id: userDetails.category_id,
      currency: userDetails.currency,
    };

    /* social networks details */
    const socialsObj = {
      custom_url: userDetails.custom_website_url,
      instagram_url: userDetails.instagram_url,
      facebook_url: userDetails.facebook_url,
      youtube_url: userDetails.youtube_url,
      twitter_url: userDetails.twitter_url,
      tiktok_url: userDetails.tiktok_url,
    };

    /* perosnal bookings */
    const personalRequestsObj = {
      available_for_booking: userDetails.personal_requests,
      price: userDetails.personal_requests_price,
    };

    /* business details */
    const businessRequestsObj = {
      available_for_booking_business: userDetails.business_requests,
      price: userDetails.business_requests_price,
    };

    const url = api.USERS;
    let headers = await userManager.getRequestHeaders();
    let base = { command: 'claim' };
    let data = merge(
      base,
      accountObj,
      adminObj,
      socialsObj,
      personalRequestsObj,
      businessRequestsObj
    );
    let body = JSON.stringify(data);
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });

    let json = await response.json();
    // console.log('claim account', json);

    return {
      success: response.status === 200,
      error: json.error,
    };
  }
}

const recruitmentManager = new RecruitmentManager();
export default recruitmentManager;
