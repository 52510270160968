import * as priceUtils from '../utils/priceUtils';
import * as utils from '../utils/rhutils';
import * as constants from '../constants/rhconstants';
import * as dateUtils from '../utils/dateUtils';
import * as vlImages from '../utils/vlImages';
import * as strings from '../utils/vlStrings';
import BookingItem from './bookingItem';
import CloseFanPlan from './closeFanPlan';
import VLCharityCause from './vlCharityCause';
import dbManager from '../managers/dbManager';
import { getI18n } from 'react-i18next';
import VLCustomProfileAction from './vlCustomProfileAction.jsx';
import memoize from 'lodash/memoize';
import VLPromotion from './vlPromotion';

const UserType = Object.freeze({
  NORMAL: 0,
  BUSINESS: 1,
  AGENCY: 2,
  CHARITY: 3,
  AFFILIATE: 4,
  PERSONA: 5,
  MANAGER: 6,
  ADMIN: 10,
});

export const ActionType = Object.freeze({
  personalRequest: 'requests_personal',
  businessRequest: 'requests_business',
  subscription: 'subscription',
  businessPromotion: 'business_promotion',
});

class VLUser {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  id = '';
  firstName = null;
  firstNameHebrew = null;
  lastName = null;
  lastNameHebrew = null;
  profilePictureURL = null;
  pushTokenId = null;
  uid = null;
  profileId = null;
  title = null;
  titleHebrew = null;
  neighbourhood = null;
  membershipStatusRaw = 0;
  profileStatus = 0; //not completed
  userType = 0; //normal
  occupation = null;
  email = null;
  birthday = null;
  profileVideoURL = null;
  customProfileURL = null;
  facebookProfileURL = null;
  instagramProfileURL = null;
  youtubeProfileURL = null;
  tiktokProfileURL = null;
  twitterProfileURL = null;
  desc = null;
  gender = null;
  allowPurchase = true;
  lastUpdated = 0; //epoch
  createdOn = null;

  agencyId = null;
  defaultBookingLangauge = null;

  //persona stuff
  price = 0; //personal requests - in shekels
  currency = 'ILS'; //default to ILS
  availableForBooking = false; //personal requests

  priceBusiness = 0; //business requests - in shekels

  availableForBookingBusiness = false; //business requests

  closeFanPlan = null;
  cause = null;

  spotlightedBookingItemId = null;

  rating = 0;
  numberOfRatings = 0;
  responseTime = 0; //in hours
  needsReviewBusiness = false;
  active = false;
  verified = false;

  //social
  totalCreations = 0;
  totalLikes = 0;
  totalFollowers = 0;
  totalCloseFans = 0;
  totalSessions = 0;

  tags = [];
  categoryId = null;

  customProfileActions = [];

  //extras
  isCreator = false;

  fullName() {
    return this.firstName + ' ' + this.lastName;
  }

  localizedFullName() {
    if (strings.isHebrew() && (this.firstNameHebrew || this.lastNameHebrew)) {
      return this.firstNameHebrew + ' ' + this.lastNameHebrew;
    } else {
      return this.fullName();
    }
  }

  localizedTitle() {
    if (strings.isHebrew() && this.titleHebrew) {
      return this.titleHebrew;
    } else {
      return this.title;
    }
  }

  handleAndFullName() {
    return `${this.fullName()} | ${this.handle()}`;
  }

  handle() {
    return '@' + this.profileId;
  }

  handleAndTitle() {
    return `${this.handle()} | ${this.title}`;
  }

  isManager() {
    return this.userType === UserType.MANAGER && this.managerId;
  }

  isAffiliate() {
    return this.userType === UserType.AFFILIATE && this.affiliateId;
  }

  isAdmin() {
    return this.userType === UserType.ADMIN;
  }

  shareURL() {
    return `${utils.baseShareURL()}/${this.profileId}`;
  }

  availableForPersonalizedRequests() {
    if (this.availableForBooking === false) {
      return false;
    }
    let price = this.price;
    let currency = this.currency;
    return price !== null && currency !== null;
  }

  availableForBusinessRequests() {
    if (
      !this.availableForBookingBusiness ||
      this.availableForBookingBusiness === false
    ) {
      return false;
    }
    let price = this.priceBusiness;
    let currency = this.currency;
    return price !== null && price > 0 && currency !== null;
  }
  availableForPersonalRequests() {
    return this.availableForBooking;
  }
  personalizedVideoPriceString(priceBid) {
    if (this.availableForPersonalizedRequests() === false) {
      let customMessage = this.custom_personalized_video_not_available_message;
      return customMessage
        ? customMessage
        : getI18n().t('booking_not_available');
    }

    let price = priceBid ? priceBid : this.price;
    let currency = this.currency;
    let priceStr = priceUtils.toPriceString(currency, price, true, true);
    let str = strings.parse(
      getI18n().t('send_request_price'),
      price === 0 ? getI18n().t('free') : priceStr
    );
    return str;
  }

  convertCurrencyToSymbol(simbol) {
    let currency_symbols = {
      USD: '$', // US Dollar
      EUR: '€', // Euro
      CRC: '₡', // Costa Rican Colón
      GBP: '£', // British Pound Sterling
      ILS: '₪', // Israeli New Sheqel
      INR: '₹', // Indian Rupee
      JPY: '¥', // Japanese Yen
      KRW: '₩', // South Korean Won
      NGN: '₦', // Nigerian Naira
      PHP: '₱', // Philippine Peso
      PLN: 'zł', // Polish Zloty
      PYG: '₲', // Paraguayan Guarani
      THB: '฿', // Thai Baht
      UAH: '₴', // Ukrainian Hryvnia
      VND: '₫', // Vietnamese Dong
    };

    if (currency_symbols[simbol]) {
      return currency_symbols[simbol];
    }

    return simbol;
  }

  addComma(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  getCurrencySymbol() {
    return this.convertCurrencyToSymbol(this.currency);
  }

  getPrice() {
    let price = this.price ? this.price : 0;
    let currency = this.currency
      ? this.convertCurrencyToSymbol(this.currency)
      : 'ILS';

    return currency + this.addComma(price);
  }

  getPriceBusiness() {
    let priceBusiness = this.priceBusiness ? this.priceBusiness : 0;
    let currency = this.currency
      ? this.convertCurrencyToSymbol(this.currency)
      : 'ILS';

    return currency + this.addComma(priceBusiness);
  }

  minPrice(isBusiness) {
    return isBusiness ? this.minimumBusinessCreationPriceBid() : this.minimumPersonalCreationPriceBid();
  }
  minimumPersonalCreationPriceBid() {
    let sub = this.closeCircleSubscription();
    if (!sub) {
      return this.price;
    } else {
      let finalPrice = this.price;
      // utils.log.debug('minimumPersonalCreationPriceBid sub =', sub);
      // utils.log.debug('finalPrice  =', finalPrice);
      return finalPrice;
    }
  }
  minimumBusinessCreationPriceBid() {
    return this.priceBusiness ?? 0;
  }
  priceCurrencyTitle() {
    return `${strings.nameFromCurrency(
      this.currency
    )} ${strings.codeFromCurrency(this.currency)}`;
  }
  businessVideoPriceString() {
    if (this.availableForBusinessRequests() === false) {
      let customMessage = this.custom_personalized_video_not_available_message;
      return customMessage
        ? customMessage
        : getI18n().t('booking_not_available_business');
    }

    let price = this.priceBusiness;
    let currency = this.currency;
    let priceStr = priceUtils.toPriceString(currency, price);
    let str = strings.parse(
      getI18n().t('send_business_request_price'),
      price === 0 ? getI18n().t('free') : priceStr
    );
    return str;
  }

  closeCirclePriceValue() {
    if (!this.closeFanPlan) {
      return 0;
    }
    let price = this.closeFanPlan.price;
    return price;
  }
  closeCircleSubscriptionString(short = false, forcePrice = false) {
    if (!this.closeFanPlan) {
      return '';
    }
    let price = this.closeFanPlan.price;
    let currency = this.closeFanPlan.currency;

    if (
      this.hasActiveCloseCircleSubscription() == false &&
      forcePrice == false
    ) {
      return getI18n().t('close_circle_sub_not_active');
    }

    let priceStr = priceUtils.toPriceString(currency, price, true, true);

    if (short === true) {
      return priceStr;
    }
    // utils.log.debug("priceStr...", priceStr);
    let str = strings.parse(
      getI18n().t('close_circle_subscription_action_title'),
      price === 0 ? getI18n().t('free') : priceStr
    );
    // utils.log.debug("str...", str);
    return str;
  }

  /**
   * This effectively means if this user even has set up their close fan subscripion (memberships)
   */
  hasActiveCloseCircleSubscription() {
    return this.closeFanPlan !== null && this.closeFanPlan.isActive();
  }

  /**
   * Checks if the current connected user is an active subscriber of this creator
   */
  isCurrentUserASubscriber() {
    let subscription = this.closeCircleSubscription();
    // utils.log.debug('subscription...', subscription);
    return subscription !== null;
  }

  /**
   * Decide if should even show option for current user to purchase a subscription
   */
  showOptionForPurchaseSubscription() {
    return (
      this.hasActiveCloseCircleSubscription() &&
      this.isCurrentUserASubscriber() === false
    );
  }

  //MARK: Subscriptions pricing
  //@todo change this at some point..
  //discount of total value, so lets say regular price is $100.00 and discount is 0.2, finalPrice is $80.00
  fullYearDiscountOff = 0.2;

  subscriptionPricingInfoMonthly(promotion = null) {
    let price = this.closeFanPlan.price;
    let currency = this.closeFanPlan.currency;
    if (promotion) {
      price = promotion.updatedPrice(price, currency);
    }
    let period = getI18n().t('period_monthly');

    return {
      title: priceUtils.toPriceString(currency, price, true),
      period: period,
      price: price,
      currency: currency,
    };
  }

  /**
   * Returns the price of the annual membership (charged once every 12 months)
   */
  subscriptionPricingInfoAnually(promotion = null) {
    let price =
      this.closeFanPlan.price * 12 -
      this.closeFanPlan.price * this.fullYearDiscountOff * 12;
    let currency = this.closeFanPlan.currency;

    if (promotion) {
      price = promotion.updatedPrice(price, currency);
    }
    let period = getI18n().t('period_annually');

    return {
      title: priceUtils.toPriceString(currency, price, true),
      period: period,
      originalPrice: priceUtils.toPriceString(
        currency,
        this.closeFanPlan.price * 12,
        false
      ),
      discount: utils.toPercentage(this.fullYearDiscountOff, false),
      price: price,
      currency: currency,
    };
  }

  defaultSubscriptionPaymentData() {
    if (this.hasActiveCloseCircleSubscription() === false) {
      return {
        price: 0,
        currency: 'USD',
        chargeType: 'monthly',
      };
    }

    return {
      price: this.closeFanPlan.price,
      currency: this.closeFanPlan.currency,
      chargeType: 'monthly',
    };
  }

  /**
   * Returns the current user's local subscrption object to this user
   * ... aka tells you if current user is susbcribed to this user
   */
  closeCircleSubscription() {
    if (!utils.currentUser()) {
      return null;
    }
    let currentUserId = utils.currentUser().id;
    const subscriptionsCollection = dbManager.getCollection(
      constants.LOCAL_DB_COLLECTION_SUBSCRIPTIONS
    );
    if (!subscriptionsCollection) {
      return null;
    }
    var subscription = subscriptionsCollection.findOne({
      creatorId: this.id,
      userId: currentUserId,
      active: true,
    });
    return subscription;
  }

  priceString() {
    let { price, currency } = this;
    if (!price || price === 0) {
      return 'FREE';
    } else {
      return priceUtils.toPriceString(currency, price, true, true);
    }
  }

  businessPriceString() {
    let { priceBusiness, currency } = this;
    if (!priceBusiness || priceBusiness === 0) {
      return '';
    } else {
      return priceUtils.toPriceString(currency, priceBusiness, true, true);
    }
  }

  lastUpdateTimeAgoString() {
    return dateUtils.timeAgoString(new Date(this.lastUpdated));
  }

  createdOnTimeAgoString() {
    return dateUtils.timeAgoString(new Date(this.createdOn));
  }

  isFree() {
    return this.price === 0;
  }

  isFollowing() {
    return true;
  }

  //MARK: Cause / Charity
  /**
   * Returns true if the user has a cause or false if they don't
   */
  hasCause() {
    return (
      this.cause !== null &&
      this.cause.id !== null &&
      this.cause.infoURL !== null
    );
  }

  /**
   * Tells us if the user is a marked creator or not
   */
  isUserCreator() {
    return this.verified || this.isCreator;
  }

  toMap() {
    var map = {};
    if (this.firstName) {
      map['first_name'] = this.firstName;
    }
    if (this.lastName) {
      map['last_name'] = this.lastName;
    }
    if (this.email) {
      map['email'] = this.email;
    }
    map['id'] = this.id;
    return map;
  }

  toAnalyticsMap() {
    var map = {};
    map['id'] = this.id;
    map['profile_id'] = this.profileId;
    map['full_name'] = this.fullName();
    return map;
  }

  static fromJSON = memoize((json) => {
    var user = new VLUser();
    try {
      //Add parsing for future
      // if (!json.id) {
      //   throw Error("missing json");
      // }
      // utils.log.debug(json);
      user.id = json.id;
      user.availableForBooking = json.available_for_booking
        ? Boolean(json.available_for_booking)
        : false;
      user.availableForBookingBusiness = json.available_for_booking_business
        ? Boolean(json.available_for_booking_business)
        : false;
      user.desc = json.desc;
      //universal
      user.firstName = json.first_name;
      user.lastName = json.last_name;
      user.title = json.title ? String(json.title) : null;
      //hebrew
      user.firstNameHebrew = json.first_name_hebrew;
      user.lastNameHebrew = json.last_name_hebrew;
      user.titleHebrew = json.title_hebrew;
      user.profileId = json.profile_id;
      user.profilePictureURL = json.profilePictureURL;
      user.membershipStatusRaw = json.membershipStatus;
      user.profileStatus = json.profileStatus;
      user.userType = json.userType;
      user.closeFanPlan = CloseFanPlan.fromJSON(json.close_fan_plan);
      user.cause = VLCharityCause.fromJSON(json.charity);
      user.email = json.email ? json.email : null;
      user.lastUpdated = json.last_updated
        ? json.last_updated
        : user.lastUpdated;
      user.createdOn = json.created_on ? json.created_on : user.createdOn;
      user.rating = json.rating;
      user.numberOfRatings = json.numberOfRatings;
      user.responseTime = json.responseTime; //in hours
      user.price = json.price ? Number(json.price).toFixed(0) : 0;
      user.priceBusiness = json.price_business
        ? Number(json.price_business).toFixed(0)
        : 0;
      user.currency = json.currency ? json.currency : user.currency;
      user.profileVideoURL = json.profileVideoURL;
      user.custom_personalized_video_not_available_message = json.custom_personalized_video_not_available_message
        ? json.custom_personalized_video_not_available_message
        : null;
      user.customBookingItemButtonTitle = json.custom_booking_item_button_title;
      user.managerId = json.manager_id;
      user.referralId = json.referral_id;
      user.affiliateId = json.affiliate_id;
      user.agencyId = json.agency_id;
      user.active = json.active ? json.active : false;
      user.verified = json.verified ? json.verified : false;
      user.defaultBookingLangauge = json.default_booking_language;
      user.totalVees = json.total_likes ?? 0;
      user.totalFollowers = json.total_followers ?? 0;
      user.totalCloseFans = json.total_close_fans ?? 0;
      user.totalCreations = json.total_bookings ? json.total_bookings : 0;
      user.tags = json.tags ? Array.from(json.tags) : [];
      user.categoryId = json.category_id ? json.category_id : '';

      //social external
      user.facebookProfileURL = json.facebook_url;
      user.twitterProfileURL = json.twitter_url;
      user.instagramProfileURL = json.instagram_url;
      user.youtubeProfileURL = json.youtube_url;
      user.tiktokProfileURL = json.tiktok_url;
      user.customProfileURL = json.custom_url;

      user.isCreator = json.is_creator;

      //spotlighted items
      user.spotlightedBookingItemId = json.spotlighted_booking_item_id
        ? json.spotlighted_booking_item_id
        : null;

      user.showAdditionalBookingItems = json.show_additional_booking_items
        ? json.show_additional_booking_items
        : false;

      //custom booking items (zoom, etc)
      let bookingItems = json.booking_items;
      if (bookingItems && bookingItems.length > 0) {
        user.bookingItems = [];
        for (const itemJSON in bookingItems) {
          let item = BookingItem.fromJSON(itemJSON);
          if (item) {
            user.bookingItems.push(item);
          }
        }
      }

      //custom profile actions (used for displaying extra buttons on profile)
      let customProfileActionsJSON = json.custom_profile_actions;
      // utils.log.debug(
      //   'customProfileActionsJSON json..',
      //   customProfileActionsJSON
      // );
      if (customProfileActionsJSON && customProfileActionsJSON.length > 0) {
        for (const itemJSON in customProfileActionsJSON) {
          let profileAction = VLCustomProfileAction.fromJSON(
            customProfileActionsJSON[itemJSON]
          );
          // utils.log.debug('profileAction json..', itemJSON);
          if (profileAction) {
            user.customProfileActions.push(profileAction);
          }
        }
      }

      if (!user.id || user.id === 'undefined') {
        return null;
      }
      return user;
    } catch (error) {
      return null;
    }
  });

  totalCreatedVideosString() {
    return `${this.totalCreations} VIDS`;
  }
  visibleOnSearch() {
    return this.profileStatus > 2;
  }
  resposneTimeString() {
    let hours = this.responseTime ? this.responseTime : 24;
    if (hours <= 24) {
      return strings.parse(getI18n().t('response_time_hours'), parseInt(hours));
    } else {
      return strings.parse(
        getI18n().t('response_time_days'),
        parseInt(hours / 24)
      );
    }
  }
  matchesQuery(query) {
    let lcQuery = query.toLowerCase();
    return (
      this.fullName().toLowerCase().includes(lcQuery) ||
      (this.profileId && this.profileId.toLowerCase().includes(lcQuery))
    );
  }

  saveToLocal() {
    try {
      localStorage.setItem(this.id, JSON.stringify(this));
    } catch (error) {
      utils.log.error(error);
    }
  }
  hasSpotlightedItem() {
    return this.spotlightedBookingItemId !== null;
  }

  static copy(object) {
    var user = new VLUser();
    for (const property in object) {
      // utils.log.debug(`${property}: ${object[property]}`);
      let value = object[property];
      user[property] = value;
    }
    return user;
  }

  customCloseFanPlanMessage() {
    return this.closeFanPlan ? this.closeFanPlan.customMessage : null;
  }

  static local(userId) {
    let local = localStorage.getItem(userId);
    if (local) {
      let json = JSON.parse(local);
      let user = new VLUser(json);
      return user;
    } else {
      return null;
    }
  }

  //URLS

  facebookURL() {
    if (utils.isValidUrl(this.facebookProfileURL)) {
      return this.facebookProfileURL;
    }
    return null;
  }
  twitterURL() {
    if (utils.isValidUrl(this.twitterProfileURL)) {
      return this.twitterProfileURL;
    }
    return null;
  }
  youtubeURL() {
    if (utils.isValidUrl(this.youtubeProfileURL)) {
      return this.youtubeProfileURL;
    }
    return null;
  }
  customURL() {
    if (utils.isValidUrl(this.customProfileURL)) {
      return this.customProfileURL;
    }
    return null;
  }
  instagramURL() {
    if (utils.isValidUrl(this.instagramProfileURL)) {
      return this.instagramProfileURL;
    } else if (
      this.instagramProfileURL &&
      utils.isValidUrl(`https://instagram.com/${this.instagramProfileURL}`)
    ) {
      return `https://instagram.com/${this.instagramProfileURL}`;
    } else {
      return null;
    }
  }

  tiktokURL() {
    if (utils.isValidUrl(this.tiktokProfileURL)) {
      return this.tiktokProfileURL;
    } else if (
      this.tiktokProfileURL &&
      utils.isValidUrl(`https://tiktok.com/@${this.tiktokProfileURL}`) &&
      this.tiktokProfileURL.length > 0
    ) {
      return `https://tiktok.com/@${this.tiktokProfileURL}`;
    } else {
      return null;
    }
  }

  //MARK: Helpers

  /**
   * Check if user has profile video
   */
  hasProfileVideo() {
    return this.profileVideoURL !== null && this.profileVideoURL !== undefined;
  }
  /**
   * Check if user has description
   */
  hasDescription() {
    return this.desc && this.desc.length > 0;
  }
  /**
   * Check if user has tags
   */
  hasTags() {
    return this.tags && this.tags.isEmpty !== false;
  }

  /**
   * Checks if the user has any social accounts added
   */
  hasSocial() {
    const links = [
      this.customURL(),
      this.instagramURL(),
      this.facebookURL(),
      this.twitterURL(),
      this.tiktokURL(),
      this.youtubeURL(),
    ];

    for (const link in links) {
      if (link !== null) {
        return true;
      }
    }

    return false;
  }

  //MARK: Action Buttons
  mainAction() {
    if (this.hasActiveCloseCircleSubscription() === true) {
      return ActionType.subscription;
    }
    if (this.availableForBooking === true) {
      return ActionType.personalRequest;
    }
    return null;
  }
  isAvailableForAction(actionType) {
    switch (actionType) {
      case 'requests_personal':
        return this.availableForPersonalizedRequests();
      case 'requests_business':
        return this.availableForBusinessRequests();
      case 'business_promotion':
        return this.availableForBusinessRequests();
      case 'subscription':
        return false;
      default:
        return false;
    }
  }
  /**
   * Set up availalbe profile actions
   * @returns Array of profile actions
   */
  profileActions() {
    let actions = [];
    actions.push(ActionType.personalRequest);
    // actions.push(ActionType.businessRequest);
    actions.push(ActionType.businessPromotion);
    return actions;
  }
  assetsFromActionType(actionType, allowBig = false) {
    switch (actionType) {
      case ActionType.personalRequest:
        return {
          image: vlImages.vlImagePlayActionImage,
          title: getI18n().t('profile_action_button_order_content_personal'),
          price: this.priceString(),
          id: 'personal_order_button',
        };
      case ActionType.businessRequest:
        return {
          image: vlImages.vlImageBusinessActionImage,
          title: getI18n().t('profile_action_button_order_content_business'),
          price: `${this.businessPriceString()}`,
          id: 'business_order_button',
        };
      case ActionType.businessPromotion:
        return {
          image: vlImages.vlImageBusinessActionImage,
          title: getI18n().t('profile_action_button_order_content_collab'),
          price: ``,
          id: 'business_collab_button',
        };
      case ActionType.subscription:
        const title = this.hasActiveCloseCircleSubscription()
          ? getI18n().t('profile_action_button_access_subscription')
          : getI18n().t('profile_action_button_subscription_coming_soon');
        return {
          image: vlImages.vlImageSubscriptionActionImage,
          title: title,
          price: this.closeCircleSubscriptionString(true, true),
          specialStyle: allowBig ? 'GradientButtonOneBig' : 'GradientButtonOne',
          id: 'subscription_order_button',
        };
      default:
        return {
          image: vlImages.vlImageSubscriptionActionImage,
          title: getI18n().t('profile_action_button_access_subscription'),
        };
    }
  }
  randomPurchaseItemAssetsFromActionType(allowBig = false) {
    const rand = utils.getRndInteger(0, 3);
    const types = [
      ActionType.personalRequest,
      ActionType.businessRequest,
      ActionType.subscription,
    ];
    const actionType = types[rand];
    const assets = this.purchaseItemAssetsFromActionType(
      actionType,
      false,
      allowBig
    );
    assets.actionType = actionType;
    // utils.log.debug('rth:::', rand);
    return assets;
  }
  purchaseItemAssetsFromActionType(
    actionType,
    fullStyle = true,
    allowBig = false
  ) {
    const rand = utils.getRndInteger(0, 3);
    const captions = [
      'profile_action_caption_subscribe_to_close_circle',
      'profile_action_caption_subscribe_to_close_circle_2',
      'profile_action_caption_subscribe_to_close_circle_3',
    ];
    const businessCaptions = [
      'profile_action_caption_order_promotional_content',
      'profile_action_caption_order_promotional_content_2',
      'profile_action_caption_order_promotional_content_3',
    ];
    const perosonalizedCaptions = [
      'profile_action_caption_order_personalized_content',
      'profile_action_caption_order_personalized_content_2',
      'profile_action_caption_order_personalized_content_3',
    ];
    switch (actionType) {
      case ActionType.personalRequest:
        return {
          image: vlImages.vlImagePlayActionImage,
          title: fullStyle
            ? getI18n().t('profile_action_button_order_content_personal')
            : getI18n().t('item_action_button_order'),
          price: this.priceString(),
          caption: getI18n().t(perosonalizedCaptions[rand]),
        };
      case ActionType.businessRequest:
        return {
          image: vlImages.vlImageBusinessActionImage,
          title: fullStyle
            ? getI18n().t('profile_action_button_order_content_business')
            : getI18n().t('item_action_button_order'),
          price: this.businessPriceString(),
          caption: getI18n().t(businessCaptions[rand]),
        };
      case ActionType.subscription:
        const title = this.hasActiveCloseCircleSubscription()
          ? fullStyle
            ? getI18n().t('profile_action_button_access_subscription')
            : getI18n().t('item_action_button_subscribe')
          : fullStyle
          ? getI18n().t('profile_action_button_subscription_coming_soon')
          : getI18n().t('item_action_button_sign_up');
        return {
          image: vlImages.vlImageSubscriptionActionImage,
          title: title,
          price:
            this.closeCirclePriceValue() === 0 ||
            this.hasActiveCloseCircleSubscription() === false
              ? null
              : this.closeCircleSubscriptionString(true, true),
          specialStyle: allowBig ? 'GradientButtonOneBig' : 'GradientButtonOne',
          caption: getI18n().t(captions[rand]),
        };
      default:
        return {
          image: vlImages.vlImageSubscriptionActionImage,
          title: getI18n().t('profile_action_button_access_subscription'),
        };
    }
  }

  benefit(index = 0) {
    const titles = [
      getI18n().t('benefits_feature_1'),
      getI18n().t('benefits_feature_2'),
      getI18n().t('benefits_feature_3'),
    ];
    const images = [
      vlImages.vlImageBenefitVideo,
      vlImages.vlImageBenefitRequest,
      vlImages.vlImageBenefitViews,
    ];

    return { title: titles[index], image: images[index] };
  }

  toICreator() {
    return {
      kre8tv_user_id: this.id,
      id: this.id,
      title: this.title,
      image_url: this.profilePictureURL,
      thumbnail_image_url: this.profilePictureURL,
      video_url: this.profileVideoURL,
      currency: this.currency,
      price: this.price,
      price_business: this.priceBusiness,
      category_ids: this.categoryId,
    };
  }
}

export default VLUser;
