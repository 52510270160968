/**
 * Custom Table
 */
import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Container,
  ButtonBase,
} from '@material-ui/core';
import { withRouter, NavLink, Link, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { NewNavbar } from '../../../NewNavbar';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import TikTok from '../../../../images/social_networks/tiktok_white.svg';
import Money from '../../../../images/challenges/money.svg';
import BlurredPlayButton from './images/play_button.png';
import ChallengeSubmissionBox from './ChallengeSubmissionBox';
import InfoDialog from './InfoDialog';
import { AirtableManager } from '../../../../VLBase/Managers/VLAirtableManager';
import PageLoader from '../../../pageLoader';
import CircularGradientProgress from './CircularGradientProgress';
import PreviewVideoDialog from './PreviewVideoDialog';
import ChallengeSubsmissionDialog from './ChallengeSubmissionDialog';
import { withTranslation } from 'react-i18next';
import { lclDirection } from '../../../../utils/bootstrapUtils';
import { isHebrew } from '../../../../App';
import { analytics, AnalyticsEvent } from '../../../../utils/analytics';
import { AuthModal } from '../../../../signup/AuthModal';
import withIntercom from '../../../../shared/hoc/with-intercom';

const airtable = new AirtableManager();

//this is the main data for the screen - loaded from airtable!
let json = {};

const prettyDetailsString = () => {
  let body = '';
  json.details.map((str, index) => (body = body + `${str}\n`));
  return body;
};

const styles = (theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingBottom: '0px',
      marginBottom: '8px',
    },
    paddingBottom: '8px',
    background: '#121029',
  },
  navbar: {
    position: '-webkit-sticky',
    position: 'sticky',
  },
  header: {
    height: '400px',
  },
  imageOverlayGradient: {
    background: 'linear-gradient(180deg, rgba(18, 16, 41, 0) 0%, #121029 100%)',
  },
  playButton: {
    textTransform: 'none',
    color: 'white',
  },
  backButton: {
    textTransform: 'none',
    color: 'white',
  },
  infoButton: {
    color: 'white',
  },
  acceptChallengeButton: {
    color: 'white',
    width: '100%',
    maxWidth: '328px',
    height: '72px',
    background:
      'linear-gradient(90.64deg, #E17B61 0.55%, #C97E96 46.46%, #A285D4 98.9%)',
    borderRadius: '24px',
  },
  platformButtonContainer: {
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(16px)',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    marginRight: isHebrew() ? 0 : theme.spacing(1),
    marginLeft: isHebrew() ? theme.spacing(1) : 0,
  },
  progressViewBackgroundCircle: {
    position: 'absolute',
    left: '50%',
    width: '180px',
    height: '180px',
    borderRadius: '50%',
    zIndex: '1',
    transform: 'translate(-50%, 0)',
    // border: '2px solid rgba(255, 255, 255, 0.2)',
  },
  progressViewForegroundCircle: {
    position: 'absolute',
    zIndex: '2',
    transform: 'translate(-50%, 0)',
    left: '50%',
    borderRadius: '50%',
    width: '180px',
    height: '180px',
    border:
      '4px solid linear-gradient(90.64deg, #E17B61 0.55%, #C97E96 46.46%, #A285D4 98.9%)',
    // background:
    //   'linear-gradient(90.64deg, #E17B61 0.55%, #C97E96 46.46%, #A285D4 98.9%)',
  },
  progressViewValues: {
    position: 'absolute',
    zIndex: '3',
    transform: 'translate(-50%, 0)',
    left: '50%',
    borderRadius: '50%',
    width: '180px',
    height: '180px',
  },
  milestoneBox: {
    position: 'relative',
    height: '136px',
  },
  milestoneBoxChild: {
    position: 'relative',
    background: '#3A2D5C',
    borderRadius: '24px',
  },
  milestoneBoxIcon: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(16px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepper: {
    direction: 'ltr',
    background: '#121029',
    color: 'white',
  },
  stepBoxContainer: {
    background: '#231942',
    borderRadius: '4px',
  },
  challengeBox: {
    cursor: 'pointer',
    maxWidth: '528px',
    width: '90vw',
    height: 'calc(90vw * 1.3275)',
    maxHeight: '703.5px',
  },
});

const Navbar = (props) => {
  const { classes } = props;
  const hideNavBar = false;
  return (
    <NewNavbar
      theme="dark"
      searchVisible={false}
      showSearch={false}
      hideCenterLogo={true}
    />
  );
};

const Header = (props) => {
  const { t, thumbnail, title, detail, classes, showGoBack, showVideo } = props;

  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [showVideoDialog, setShowVideoDialog] = useState(false);

  const onShowInfoModalClicked = () => {
    setShowInfoDialog(true);
  };

  const hideInfoDialog = () => {
    setShowInfoDialog(false);
  };

  const onShowVideoClicked = () => {
    setShowVideoDialog(true);
  };

  const hideVideoDialog = () => {
    setShowVideoDialog(false);
  };

  return (
    <Box className={classes.header} position="relative">
      <img
        id="background-hero-image"
        alt="thumbnail"
        style={{
          height: '100%',
          opacity: '100%',
          position: 'absolute',
          objectFit: 'cover',
          display: !showVideo ? 'block' : 'none',
        }}
        className="w-100"
        src={thumbnail}
      ></img>
      <video
        controls
        autoPlay
        loop
        playsInline
        muted={true}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          objectFit: 'cover',
          display: showVideo ? 'block' : 'none',
        }}
      >
        <source src={json.explainer_video} type="video/mp4" />
      </video>
      <Box
        position="relative"
        height="100%"
        zIndex="100"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        className={classes.imageOverlayGradient}
      >
        <Box
          id="top-bar"
          position="absolute"
          top={16}
          left={0}
          height="50px"
          width="100%"
          color="white"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Button
            style={{ visibility: showGoBack ? 'visible' : 'hidden' }}
            onClick={() => props.history.goBack()}
            startIcon={<ChevronLeft />}
            className={classes.backButton}
            variant="text"
          >
            Back
          </Button>
          <IconButton
            className={classes.infoButton}
            onClick={onShowInfoModalClicked}
          >
            <InfoOutlined />
          </IconButton>
          <InfoDialog
            open={showInfoDialog}
            handleClose={hideInfoDialog}
            title={t('challenges_info_title')}
            body={t('challenges_info_detail')}
            buttonTitle={t('challenges_info_button_title')}
          />
          <PreviewVideoDialog
            open={showVideoDialog}
            handleClose={hideVideoDialog}
            videoUrl={json.explainer_video}
          />
        </Box>
        <Box id="play-button">
          <Button
            onClick={onShowVideoClicked}
            startIcon={
              isHebrew() ? null : (
                <img
                  style={{ width: '48px', height: '48px' }}
                  src={BlurredPlayButton}
                  alt="Play"
                />
              )
            }
            endIcon={
              !isHebrew() ? null : (
                <img
                  style={{ width: '48px', height: '48px', marginRight: '16px' }}
                  src={BlurredPlayButton}
                  alt="Play"
                />
              )
            }
            className={classes.playButton}
            variant="text"
            style={{ textTransform: 'none', color: '' }}
          >
            {t('play_video')}
          </Button>
        </Box>
        <Box
          id="headline"
          position="absolute"
          bottom={16}
          left={isHebrew() ? 0 : 16}
          right={isHebrew() ? 16 : 0}
        >
          <Typography variant="h4">
            <Box fontSize="20px" color="text.secondary" mb={1}>
              {title}
            </Box>
          </Typography>
          <Typography variant="body2">
            <Box color="primary.light">{detail}</Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
const AcceptChallengeButton = (props) => {
  const { classes, t } = props;
  const [showSubmissionDialog, setShowSubmissionDialog] = useState(false);
  const [showAuth, setShowAuth] = useState(false);

  const onAcceptChallengeClick = () => {
    const properties = [{ challenge_id: props.challengeId }];
    analytics.event(
      new AnalyticsEvent('accept challenge button clicked', properties)
    );
    if (props.userId) {
      setShowSubmissionDialog(true);
    } else {
      //should show dialog
      setShowAuth(true);
    }
  };

  const onAuthSuccess = () => {
    setShowAuth(false);
  };

  return (
    <Box
      width="100%"
      mt={2}
      mb={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <ButtonBase
        style={{ maxWidth: '100%', width: '325px', height: '100%' }}
        onClick={onAcceptChallengeClick}
      >
        <Box
          className={classes.acceptChallengeButton}
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          p={2}
          fontSize="18px"
          fontWeight="bold"
        >
          <Box>
            <Box
              id="platform-icon"
              display="flex"
              flexDirection="row"
              alignItems="center"
              height="100%"
            >
              <Box
                className={classes.platformButtonContainer}
                display="flex"
                color="white"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  alt="tiktok"
                  style={{ width: '16px', height: '16px' }}
                  src={TikTok}
                />
              </Box>
              <Box ml={1} mr={1}>
                {t('accept_challenge')}
              </Box>
            </Box>
          </Box>
          <Box id="right-chevron">
            {isHebrew() ? <ChevronLeft /> : <ChevronRight />}
          </Box>
        </Box>
      </ButtonBase>
      <ChallengeSubsmissionDialog
        challengeId={props.challengeId}
        title={t('accept_challenge')}
        open={showSubmissionDialog}
        details={json.details}
        soundUrl={json.soundUrl}
        buttonTitle={t('challenge_submit_button')}
        onClose={() => setShowSubmissionDialog(false)}
        t={t}
      />
      <AuthModal
        show={showAuth}
        onClose={() => setShowAuth(false)}
        onAuthSuccess={onAuthSuccess}
      />
    </Box>
  );
};
const ProgressView = (props) => {
  const { classes, t } = props;
  let percentage = json.values.percentageComplete ?? 0;
  return (
    <Box
      width="100%"
      mt={2}
      mb={2}
      height="180px"
      className={classes.progressViewContainer}
      position="relative"
    >
      <Box className={classes.progressViewBackgroundCircle}></Box>
      <Box className={classes.progressViewForegroundCircle}>
        <CircularGradientProgress percentage={percentage} width={180} />
      </Box>

      <Box
        className={classes.progressViewValues}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Box>
          <Box
            color="#D77D77"
            fontSize="32px"
            lineHeight={1.2}
            fontWeight="bold"
          >
            {json.values.givenOut}
          </Box>
          <Box fontWeight="bold" fontSize="13px" color="white">
            {percentage > 0
              ? `${percentage}% ${t('given_out')}`
              : t('giving_out')}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const Milestones = (props) => {
  const MilestsoneBox = (props) => {
    const { t, big, small, isAvailable, classes } = props;
    return (
      <Box className={classes.milestoneBox}>
        <Box
          pt={2}
          pl={2}
          pr={2}
          pb={4}
          className={classes.milestoneBoxChild}
          style={{
            opacity: isAvailable ? 1.0 : 0.5,
            width: '100%',
            height: '100%',
          }}
          color="white"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box
              className={classes.milestoneBoxIcon}
              mr={isHebrew() ? 0 : 1.5}
              ml={isHebrew() ? 1.5 : 0}
            >
              <img src={Money} alt="money" />
            </Box>
            <Typography variant="h2">
              <Box color="white">{big}</Box>
            </Typography>
          </Box>
          <Box fontWeight="bold">{small}</Box>
        </Box>
        <Box
          position="absolute"
          bottom={16}
          left={16}
          color="#FFBE8E"
          fontWeight="bold"
          display={isAvailable ? 'none' : 'block'}
        >
          {t('not_available')}
        </Box>
      </Box>
    );
  };
  return (
    <Box width="100%" p={2}>
      <Grid container spacing={2} direction="row">
        {json.milestones.map((ms, key) => {
          const length = json.milestones.length;
          return (
            <Grid
              item
              xs={6}
              sm={12 / length}
              md={12 / length}
              lg={12 / length}
              key={key}
            >
              <MilestsoneBox
                big={ms.prize}
                small={ms.detail}
                isAvailable={ms.isAvailable}
                {...props}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
const Details = (props) => {
  const { classes, t } = props;

  const onSoundClicked = (soundUrl) => {
    window.open(soundUrl, '_blank');
  };

  return (
    <Box color="white" p={2} mt={2} mb={2}>
      <Typography variant="h2">
        <Box color="white">{t('challenge_details')}:</Box>
      </Typography>

      <Container maxWidth="sm" disableGutters>
        <Stepper orientation="vertical" className={classes.stepper}>
          {json.details.map((label, index) => (
            <Step key={index} expanded={true} active={true}>
              <StepLabel>
                <Typography>
                  <Box
                    dir={lclDirection()}
                    color="white"
                    className={classes.stepBoxContainer}
                    p={1.5}
                  >
                    {label}
                    {index === 0 ? (
                      <Box mt={1} style={{ wordBreak: 'break-all' }}>
                        <Link
                          onClick={() => onSoundClicked(json.soundUrl)}
                          style={{ color: '#AE9BDE' }}
                        >
                          {json.soundUrl}
                        </Link>
                      </Box>
                    ) : null}
                  </Box>
                </Typography>
              </StepLabel>
              {/* <StepContent>
              <Typography>
                <Box color="white" className={classes.stepBoxContainer} p={1.5}>
                  {label}
                </Box>
              </Typography>
            </StepContent> */}
            </Step>
          ))}
        </Stepper>
      </Container>
    </Box>
  );
};
const Submissions = (props) => {
  const { t, classes, show, submissions } = props;
  if (!show || !submissions || submissions.length === 0) {
    return null;
  }
  return (
    <Box width="100%" p={2} mt={2} mb={2}>
      <Box mb={2}>
        <Typography variant="h2">
          <Box color="white">{t('submissions')}</Box>
        </Typography>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {submissions.map((submission, index) => {
          return (
            <Box mb={3} className={classes.challengeBox}>
              <ChallengeSubmissionBox
                key={index}
                thumbnail={submission.thumbnail}
                author={submission.author}
                title={submission.title}
                link={submission.link}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const ChallengeDetail = (props) => {
  const { classes } = props;
  const challengeId = props.challengeId;

  const [isFetchingChallenge, setIsFetchingChallenge] = useState(true);
  const [failedFetchingChallenge, setFailedFetchingChallenge] = useState(false);

  useEffect(() => {
    //should fetch challenge details?
    //fetch data
    airtable.fetchChallenge(challengeId, (result, error) => {
      if (result) {
        json = result.data;
        setIsFetchingChallenge(false);
      } else {
        setFailedFetchingChallenge(true);
      }
    });

    const properties = [{ challenge_id: challengeId }];
    analytics.event(new AnalyticsEvent('Challenge detail visited', properties));
  }, []);

  if (failedFetchingChallenge) {
    return <Redirect to="/home" />;
  }

  return (
    <div className={classes.root} dir={lclDirection()}>
      <Navbar {...props} />
      <Container maxWidth="md" disableGutters>
        {isFetchingChallenge ? (
          <PageLoader
            animatedLoader={true}
            darkOpacity={true}
            withBackground={false}
          />
        ) : null}
        {isFetchingChallenge ? null : (
          <div>
            <Header
              showVideo={json.show_video}
              thumbnail={json.thumbnail}
              title={json.title}
              detail={json.detail}
              {...props}
            />
            <AcceptChallengeButton {...props} />
            <ProgressView {...props} />
            <Milestones {...props} />
            <Details {...props} />
            <Submissions
              {...props}
              submissions={json.submissions}
              show={json.show_submissions}
            />
          </div>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return { isConnected: user.isConnected, userId: user.userId };
};

export default withIntercom(
  withRouter(
    connect(
      mapStateToProps,
      null
    )(
      withStyles(styles, { withTheme: true })(
        withTranslation()(ChallengeDetail)
      )
    )
  )
);
