enum VLProfileAction {
  Account = 0,
  Socials = 1,
  PersonalRequests = 2,
  BusinessRequests = 3,
  Subscription = 4,
  Cause = 5,
  Verify = 6,
}

function toString(action: VLProfileAction): string {
  switch (action) {
    case VLProfileAction.Account:
      return 'profile_action_account';
    case VLProfileAction.Socials:
      return 'profile_action_socials';
    case VLProfileAction.PersonalRequests:
      return 'profile_action_personal_requests';
    case VLProfileAction.BusinessRequests:
      return 'profile_action_business_requests';
    case VLProfileAction.Subscription:
      return 'profile_action_subscription';
    case VLProfileAction.Cause:
      return 'profile_action_cause';
    case VLProfileAction.Verify:
      return 'profile_action_verify';
    default:
      return '';
  }
}

function toColor(action: VLProfileAction): string {
  switch (action) {
    case VLProfileAction.Account:
      return '5D36BD';
    case VLProfileAction.Socials:
      return '4BD7B5';
    case VLProfileAction.PersonalRequests:
      return 'EE665D';
    case VLProfileAction.BusinessRequests:
      return '4D76E1';
    case VLProfileAction.Subscription:
      return 'AB97DB';
    case VLProfileAction.Cause:
      return 'F89A51';
    case VLProfileAction.Verify:
      return '89BBF8';
    default:
      return '';
  }
}

function toUpdateType(action: VLProfileAction): string {
  switch (action) {
    case VLProfileAction.Account:
      return 'account';
    case VLProfileAction.Socials:
      return 'socials';
    case VLProfileAction.PersonalRequests:
      return 'personal_requests';
    case VLProfileAction.BusinessRequests:
      return 'business_requests';
    case VLProfileAction.Subscription:
      return 'subscription';
    case VLProfileAction.Cause:
      return 'cause';
    case VLProfileAction.Verify:
      return 'verify';
    default:
      return '';
  }
}

function toIconImage(action: VLProfileAction): any {
  switch (action) {
    case VLProfileAction.Account:
      return 'profile_action_account'; // @todo to image needed
    case VLProfileAction.Socials:
      return 'profile_action_social'; // @todo to image needed
    case VLProfileAction.PersonalRequests:
      return 'profile_action_user_request'; // @todo to image needed
    case VLProfileAction.BusinessRequests:
      return 'profile_action_business_request'; // @todo to image needed
    case VLProfileAction.Subscription:
      return 'profile_action_subscribers'; // @todo to image needed
    case VLProfileAction.Cause:
      return 'profile_action_charity'; // @todo to image needed
    case VLProfileAction.Verify:
      return 'profile_action_verify'; // @todo to image needed
    default:
      return '';
  }
}

// static func fromProfileTip(profileTip: VLProfileTip) : VLProfileAction? {
//     switch profileTip {
//     case VLProfileAction.profilePicture, .profileVideo, .title, .tags, .description:
//         return .Account
//     case VLProfileAction.Socials:
//         return .Socials
//     case VLProfileAction.PersonalRequests:
//         return .PersonalRequests
//     case VLProfileAction.BusinessRequests:
//         return .BusinessRequests
//     case VLProfileAction.Subscriptions:
//         return .Subscription
//     case VLProfileAction.Cause:
//         return .Cause
//     case VLProfileAction.Verify:
//         return .Verify
//     default:
//         return nil
//     }
// }
// }

export default VLProfileAction;
