import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel } from 'react-bootstrap';
import { OrderBlockWidget } from '../../Widgets/OrderBlockWidget';

import { BookingOccassion } from '../../../../../model/enums/v2';
import { useTranslation } from 'react-i18next';

import personal_booking_icon_question from './../images/personal/personal_booking_icon_question.svg';
import personal_booking_icon_motivation from './../images/personal/personal_booking_icon_motivation.svg';
import personal_booking_icon_event from './../images/personal/personal_booking_icon_event.svg';
import personal_booking_icon_advice from './../images/personal/personal_booking_icon_advice.svg';
import personal_booking_icon_birthday from './../images/personal/personal_booking_icon_birthday.svg';
import personal_booking_icon_other from './../images/personal/personal_booking_icon_other.svg';

import biz_booking_icon_promotional from './../images/biz/biz_booking_icon_promotional.svg';
import biz_booking_icon_testimonial from './../images/biz/biz_booking_icon_testimonial.svg';
import biz_booking_icon_collab from './../images/biz/biz_booking_icon_collab.svg';
import biz_booking_icon_product_placement from './../images/biz/biz_booking_icon_product_placement.svg';
import biz_booking_icon_consultation from './../images/biz/biz_booking_icon_consultation.svg';
import biz_booking_icon_other from './../images/biz/biz_booking_icon_other.svg';

import { updateOcassion } from '../../../../../store/reducers/ordering';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '-0.41px',
  },
  entityImage: {
    width: '80px',
    height: '80px',
    borderRadius: '16px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  entitySelected: {
    border: '1px solid #DBCAFE',
  },
  entityDefault: {
    border: '1px solid #3A2D5C',
  },
  pricingLabels: {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'white',
  },
}));

const personalOcassions = [
  {
    title: 'profile_req1',
    image: personal_booking_icon_question,
    value: BookingOccassion.QUESTION,
  },
  {
    title: 'profile_req2',
    image: personal_booking_icon_motivation,
    value: BookingOccassion.MOTIVATION,
  },
  {
    title: 'profile_req3',
    image: personal_booking_icon_event,
    value: BookingOccassion.EVENT,
  },
  {
    title: 'profile_req4',
    image: personal_booking_icon_advice,
    value: BookingOccassion.ADVICE,
  },
  {
    title: 'profile_req5',
    image: personal_booking_icon_birthday,
    value: BookingOccassion.BIRTHDAY,
  },
  {
    title: 'profile_req6',
    image: personal_booking_icon_other,
    value: BookingOccassion.OTHER,
  },
];

const businessOcassions = [
  {
    title: 'promotional',
    image: biz_booking_icon_promotional,
    value: BookingOccassion.PROMOTIONAL,
  },
  {
    title: 'testimonial',
    image: biz_booking_icon_testimonial,
    value: BookingOccassion.TESTIMONIAL,
  },
  {
    title: 'collaboration',
    image: biz_booking_icon_collab,
    value: BookingOccassion.COLLABORATION,
  },
  {
    title: 'productPlacement',
    image: biz_booking_icon_product_placement,
    value: BookingOccassion.PRODUCT_PLACEMENT,
  },
  {
    title: 'profile_req4',
    image: biz_booking_icon_consultation,
    value: BookingOccassion.ADVICE,
  },
  {
    title: 'profile_req6',
    image: biz_booking_icon_other,
    value: BookingOccassion.OTHER,
  },
];

const EntityBox = (props) => {
  const { imageUrl, title, selected, onSelect, value } = props;
  const classes = useStyles();
  return (
    <Box
      style={{ cursor: 'pointer' }}
      mt={1}
      mb={1}
      width="33%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      onClick={() => onSelect(value, title)}
    >
      <Box
        className={`${classes.entityImage} ${
          selected ? classes.entitySelected : classes.entityDefault
        }`}
      >
        <img
          alt="icon"
          src={imageUrl}
          style={{ width: '24px', height: '24px' }}
        />
      </Box>
      <Box mt={1} color="white" textAlign="center">
        <Typography variant="body2" color="inherit">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

const EntityBoxes = (props) => {
  const { t, i18n } = useTranslation();
  const { occasions, onOccasionSelected, selectedOccasion } = props;
  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      {occasions.map((occ, index) => {
        return (
          <EntityBox
            onSelect={onOccasionSelected}
            value={occ.value}
            imageUrl={occ.image}
            title={t(occ.title)}
            selected={selectedOccasion === occ.value}
          />
        );
      })}
    </Box>
  );
};

const OrderOccasionBlock = (props) => {
  const {
    user,
    isBusiness,
    selectedOccasion,
    updateOcassion,
    showStepError,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const title = t('selectOccasion');

  const occasions = isBusiness ? businessOcassions : personalOcassions;

  return (
    <OrderBlockWidget showError={showStepError}>
      <Box className={classes.root}>
        <Box m={1} textAlign="center" className={classes.title}>
          {title}
        </Box>
      </Box>
      <Box id="occasions" width="100%" display="flex" mt={2}>
        <EntityBoxes
          selectedOccasion={selectedOccasion}
          occasions={occasions}
          onOccasionSelected={(value, title) =>
            updateOcassion({ value, title })
          }
        />
      </Box>
    </OrderBlockWidget>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    user: state.user.details,
    selectedOccasion: state.ordering.ocassion_value,
    isBusiness: state.ordering.type === 'business',
    showStepError: state.ordering.errors.occasion === true,
  };
};

export default connect(mapStateToProps, { updateOcassion })(OrderOccasionBlock);
