import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import styles from './BookingNextAndBack.module.scss';
import { useTranslation } from 'react-i18next';
import back from '../../Images/back.svg';
import {
  centerFlexColumn,
  lclDirection,
} from '../../../../utils/bootstrapUtils';
import { isHebrew } from '../../../../App';
import { CircularProgress, Fade } from '@material-ui/core';

const BookingNextAndBack = (props) => {
  const { t, i18n } = useTranslation();

  const next = () => {
    props.next();
  };

  const { show, showLoading } = props;

  let confirmText =
    window.innerSize > 1024
      ? t('booking_confirm')
      : `${t('booking_confirm_req')} ${props.price}`;
  let confirm =
    props.currentUserAlreadyHasPaymentMethods || props.isConnected !== true
      ? confirmText
      : t('booking_confirm_credit_card');

  // console.log('step = ', props.step);

  const lastStep = props.lastStep === true;
  const extraStyle = lastStep ? `${styles.smallerFont}` : '';

  return (
    <div dir={lclDirection()} className={`${styles.marginTop} d-flex flex-row`}>
      <div
        className={`d-flex justify-content-center flex-row ${
          isHebrew() ? styles.heb : ''
        }`}
      >
        <Fade in={props.step && !props.hideBackButton}>
          <div className={`${styles.back} `} onClick={props.back}>
            <img src={back} />
          </div>
        </Fade>
        <Fade in={show}>
          <div
            className={`${styles.next} ${centerFlexColumn()} ${extraStyle}`}
            onClick={next}
          >
            {showLoading ? (
              <CircularProgress size={14} style={{ color: 'white' }} />
            ) : (
              <>{props.step < 4 ? t('next') : confirm}</>
            )}
          </div>
        </Fade>
      </div>
      {props.error ? <div className={styles.error}>{props.error}</div> : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { isConnected: state.user.isConnected };
};

export default connect(mapStateToProps)(BookingNextAndBack);
