import { createReducer } from '@reduxjs/toolkit';
import {
  sendVerificationCode,
  verifyCode,
  signOut,
  signUp,
} from '../../routines';
import { auth } from 'firebase';

export interface SignInState {
  stage: string | null;
  confirmResult: auth.ConfirmationResult | null;
  loading: boolean;
  error: any | null;
  authorized: boolean;
  phone: string | null;
}

const initialState: SignInState = {
  stage: null,
  confirmResult: null,
  loading: false,
  error: null,
  authorized: false,
  phone: null,
};

export default createReducer<SignInState>(initialState, {
  [sendVerificationCode.TRIGGER]: (state, action) => ({
    ...state,
    confirmResult: null,
    phone: action.payload,
  }),
  [sendVerificationCode.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [sendVerificationCode.SUCCESS]: (state, action) => ({
    ...state,
    confirmResult: action.payload,
  }),
  [sendVerificationCode.FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [sendVerificationCode.FULFILL]: (state) => ({
    ...state,
    loading: false,
  }),
  [verifyCode.TRIGGER]: (state) => ({
    ...state,
    error: null,
  }),
  [verifyCode.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [verifyCode.SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    authorized: true,
    confirmResult: null,
    stage: null,
  }),
  [verifyCode.FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    authorized: false,
    error: action.payload,
    stage: null,
    phone: null,
  }),
  [signOut.SUCCESS]: (state, action) => ({
    ...state,
    authorized: false,
    phone: null
  }),
  [signUp.TRIGGER]: (state, action) => ({
    ...state,
    confirmResult: null,
    error: null,
    stage: 'signup',
  }),
  [signUp.REQUEST]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [signUp.FULFILL]: (state, action) => ({
    ...state,
    loading: false,
  }),
});
