import React, { Component, useEffect, useState } from 'react';
import {
  BodyText,
  HeadingText,
} from '../../LandingPages/business/components/TextHelpers';

//A blocker component that shows that the website is currently under construction
export const UnderConstruction = () => {
  return (
    <div className="position-fixed h-100 w-100 d-flex justify-content-center align-items-center bg-dark">
      <div className="text-center">
        <HeadingText fontSize="36px">Something new is coming</HeadingText>
        <BodyText fontSize="24px">
          <div className="text-light">
            {/* add a light purple link below */}
            Tap {/* under line */}
            <a
              style={{ color: '#CBC3E3', 'text-decoration': 'underline' }}
              href="https://supercreator.ai/?utm_source=website&utm_medium=kre8&utm_campaign=kre8_to_supercreator"
            >
              here
            </a>{' '}
            for early access
          </div>
        </BodyText>
      </div>
    </div>
  );
};
