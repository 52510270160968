import { createReducer } from '@reduxjs/toolkit';
import {fetchCurrentUserDetails, signOut} from '../../routines';

export interface CurrentUserState {
  data: any;
  loading: boolean;
  error: any;
}

const initialState: CurrentUserState = {
  data: null,
  loading: false,
  error: null,
};

export default createReducer<CurrentUserState>(initialState, {
  [fetchCurrentUserDetails.SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
  }),
  [signOut.TRIGGER]:(state,action)=>({
    ...state,
    data:{}
  }),
  [signOut.SUCCESS]:(state,action)=>({
    ...state,
    data:{}
  })
});
