import React, { useState } from 'react';
import {
  createMuiTheme,
  withStyles,
  Theme,
  createStyles,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Paper,
} from '@material-ui/core';
import VLSideNote from '../../Reusables/Texts/VLSideNote';
import VLParagraph from '../../Reusables/Texts/VLParagraph';
import VLSmallHeading from '../../Reusables/Texts/VLSmallHeading';
import { centerFlexColumn } from '../../utils/bootstrapUtils';
import VLSmallTitle from '../../Reusables/Texts/VLSmallTitle';

interface IVLCreationBaseView {
  type: 'text' | 'image' | 'video' | 'none';
  display: 'square' | 'landscape' | 'portrait';
  title?: string;
  detail?: string;
  text?: string;
  audio_url?: string;
  video_url?: string;
  image_url?: string;
  thumb_url?: string;
  deep_link_url?: string;
  external_url?: string;
  creator_title?: string;
  creator_detail?: string;
  creator_image_url?: string;
  creator_deep_link_url?: string;
  timestamp?: number;
  for_subscribers_only?: boolean;
  purchase_to_view?: boolean;
  locked_view?: boolean;
  price?: number;
  currency?: string;
  total_vees?: number;
  total_comments?: number;
  total_views?: number;
  are_counts_visible?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: any) => ({
      backgroundColor: '#3E3A4B',
      padding: theme.spacing(0),
      borderRadius: '30px',
    }),
    paper: (props: any) => ({
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.background.default,
    }),
    media: {
      height: '100%',
      width: '100%',
    },
    displaySquare: {
      height: '400px',
      width: '100%',
    },
    displayPortrait: {
      height: '150px',
      width: '100px',
    },
    displayLandscape: {
      height: '100px',
      width: '150px',
    },
    displayNone: {
      height: '100px',
      width: '100px',
    },
    text: {
      height: '100%',
      width: '100%',
    },
    overlayContent: {
      background: 'rgba(0, 0, 0, 0.2)',
      padding: theme.spacing(2),
      zIndex: 1000,
    },
  })
);

interface ICreationInfoSection {
  icon?: any;
  title?: string;
  detail?: string;
}

/**
 * Creation info section (icon of type, title, detail, etc)
 * @param props
 * @returns
 */
const CreationInfoSection = (props: ICreationInfoSection) => {
  const { icon, title, detail } = props;
  return (
    <div className="w-100 d-flex flex-column align-items-start">
      <VLSideNote color="textSecondary">{title}</VLSideNote>
      <VLSideNote color="textSecondary" style={{ opacity: 0.5 }}>
        {detail}
      </VLSideNote>
    </div>
  );
};

interface ICreatorInfoSection {
  image_url?: string;
  title?: string;
  detail?: string;
}
/**
 * Creator info section (avatar image, name, handle)
 * @param props
 * @returns
 */
const CreatorInfoSection = (props: ICreatorInfoSection) => {
  const { image_url, title, detail } = props;
  return (
    <div className="d-flex flex-row align-items-center">
      <img
        style={{ width: '30px', height: '30px', borderRadius: '12px' }}
        src={image_url}
      />
      <div className="mr-2 ml-2" />
      <VLSideNote color="textSecondary">{title}</VLSideNote>
      <VLSideNote color="secondary">{detail}</VLSideNote>
    </div>
  );
};

const VisibilityInfoSection = () => {
  return <div className="h-100 position-absolute"></div>;
};

interface IContentViewSection {
  image_url?: string;
  thumb_url?: string;
  video_url?: string;
  audo_url?: string;
  text?: string;
}
const ContentView = (props: IContentViewSection) => {
  const { image_url, thumb_url, video_url, audo_url, text } = props;

  const classes = useStyles(props);

  return (
    <div className="w-100 h-100 position-absolute" id="content-view">
      {!text ? (
        <CardMedia
          component={video_url ? 'video' : 'image'}
          className={classes.media}
          // image={thumb_url}
          src={image_url ?? video_url}
          title="Contemplative Reptile"
          // autoPlay
          controls
        />
      ) : null}
      {text ? (
        <CardContent className={classes.text}>
          <div className={`${centerFlexColumn()} h-100 bg-white`}>
            <div>
              <VLSmallTitle align="center" color="textPrimary">
                {text}
              </VLSmallTitle>
            </div>
          </div>
        </CardContent>
      ) : null}
    </div>
  );
};

export default function VLCreationBaseView(props: IVLCreationBaseView) {
  const classes = useStyles(props);

  const classForDisplay = (display: string) => {
    switch (display) {
      case 'portrait':
        return classes.displayPortrait;
      case 'square':
        return classes.displaySquare;
      case 'landscape':
        return classes.displayLandscape;
      default:
        return classes.displayNone;
    }
  };

  return (
    <Card className={`${classes.root} ${classForDisplay(props.display)}`}>
      <Paper className={`${classes.paper} position-relative`}>
        <ContentView
          text={props.text}
          image_url={props.image_url}
          thumb_url={props.thumb_url}
          video_url={props.video_url}
          audo_url={props.audio_url}
        />
        <div
          className={`${classes.overlayContent} h-100 w-100 position-relative d-flex flex-column justify-content-between`}
        >
          <CreatorInfoSection
            image_url={props.creator_image_url}
            title={props.creator_title}
            detail={props.creator_detail}
          />
          <CreationInfoSection title={props.title} detail={props.detail} />
          <VisibilityInfoSection />
        </div>
      </Paper>
    </Card>
  );
}
