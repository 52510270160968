import React, { useState, useEffect } from 'react';
import styles from './BookingUpload.module.scss';
import { useTranslation } from 'react-i18next';
import { Form, Spinner } from 'react-bootstrap';
import { lclDirection } from '../../../../utils/bootstrapUtils';

const BookingProfile = (props) => {
  const { t, i18n } = useTranslation();
  let currency =
    props.User && props.User.getCurrency ? props.User.getCurrency() : '';

  const fullScreen = window.innerWidth > 1024;
  return (
    <div
      dir={lclDirection()}
      className={`${styles.BookingUpload} ${
        i18n.language === 'he' || i18n.language === 'global' || !i18n.language
          ? styles.heb
          : ''
      }`}
    >
      <label
        for="BookingBox_file_upload"
        className={`d-flex align-items-center ${
          fullScreen ? 'flex-column' : 'flex-row'
        }`}
      >
        {props.imageLoading ? (
          <div className={styles.loading}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : props.mainImage && fullScreen ? (
          <label for="BookingBox_file_upload">
            <img src={props.mainImage} className={styles.image} />
          </label>
        ) : (
          <div
            className={`d-flex ${
              fullScreen ? 'flex-column' : `${styles.boxWrapper} flex-row`
            } align-items-center`}
          >
            <div className={`${styles.text} ${fullScreen ? 'mb-3' : ''}`}>
              {t('booking_attach_video')}
            </div>
            {!props.mainImage ? (
              <div className={styles.uploadBox}>+</div>
            ) : (
              <div className={styles.uploadBox + ' ' + styles.uploadBoxImg}>
                <img src={props.mainImage} />
              </div>
            )}
          </div>
        )}
      </label>
      <input
        type="file"
        id="BookingBox_file_upload"
        style={{ display: 'none' }}
        onChange={(e) => {
          props.uploadImage(e.target, 'main');
        }}
      />
      <div
        className={`d-flex flex-row justify-content-between ${styles.hideVideo}`}
      >
        <div className={styles.bottomText}>{t('booking_hide_video')}</div>
        <div onClick={props.toggleShowCreatorName}>
          <Form.Check
            type="switch"
            checked={props.showCreatorName}
            className={styles.checkboxBootstrap}
          />
        </div>
      </div>
      <div
        className={`d-flex flex-row justify-content-between ${styles.totalRow}`}
      >
        <div className={styles.bottomPriceText}>{t('booking_video_total')}</div>
        <div className={styles.bottomPriceText}>{props.price}</div>
      </div>
    </div>
  );
};
export default BookingProfile;
