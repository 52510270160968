import * as strings from '../utils/vlStrings.js';
import * as utils from '../utils/rhutils';

export const ERROR_MUST_SIGN_IN = 3;
class VLCustomProfileAction {
  id = null;
  userId = null;
  title = null;
  titleHebrew = null;
  buttonImageURL = null;
  link = null;

  price = null; //personal requests - in shekels
  currency = null; //custom current or use user's default
  backgroundColor = null; //hex

  localizedTitle() {
    if (strings.isHebrew() && this.titleHebrew) {
      return this.titleHebrew;
    } else {
      return this.title;
    }
  }

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  static fromJSON(json) {
    var action = new VLCustomProfileAction();
    try {
      action.id = json.id;
      action.userId = json.user_id;
      action.title = json.title ? json.title : null;
      action.titleHebrew = json.title_hebrew ? json.title_hebrew : null;

      action.buttonImageURL = json.button_image_url;
      action.link = json.link;

      action.price = json.price ? Number(json.price) : null;
      action.currency = json.currency ? json.currency : null;
      action.backgroundColor = json.background_color
        ? json.background_color
        : null;
      return action;
    } catch (error) {
      utils.log.error('error parsing custo profile action', json);
      return null;
    }
  }
}

export default VLCustomProfileAction;
