import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Category.module.scss';
import { Image } from '../../../image/image.component';
import { ICategory } from '../../../../model/interfaces';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';

type CategoryProps = {
  key: number;
  index: number;
  category: ICategory;
  discover?: boolean;
};

const Category: React.FC<CategoryProps> = React.memo(
  ({ key, index, category, discover }) => {
    const { i18n } = useTranslation();
    const history = useHistory();

    return (
      <div
        className={`${styles.categoryContainer} ${
          discover ? styles.categoryContainerDiscover : ''
        }`}
        style={{
          marginLeft: discover
            ? i18n.language === 'he'
              ? 15
              : 0
            : index === 0 && i18n.language !== 'he'
            ? 20
            : 0,
          marginRight: discover && i18n.language === 'he' ? 0 : 15,
        }}
        onClick={() => history.push(category.deep_link_url)}
      >
        {category.image_url && (
          <div className={styles.imageContainer}>
            <Image src={category.image_url} />
          </div>
        )}
        <Typography variant="subtitle1">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={styles.titleContainer}
          >
            <span>{category.title}</span>
          </Box>
        </Typography>
      </div>
    );
  }
);

export default Category;
