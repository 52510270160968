import * as utils from '../utils/rhutils.js';
import VLError from '../model/vlError';
import api, * as RHAPI from './../constants/api';
import userManager from './userManager.js';
import CloseFan from '../model/closeFan';
import VLUser from '../model/vlUser.js';
import dBManager from './dbManager.jsx';
import { logPurchaseMembership } from '../utils/amplitude.js';
import { LOCAL_DB_COLLECTION_SUBSCRIPTIONS } from '../constants/rhconstants.js';
import * as constants from '../constants/rhconstants.js';
import firebase from 'firebase';

var _ = require('lodash');

interface PurchaseSubscriptionResponse {
  success: boolean;
  errorMessage?: string;
  subscription?: CloseFan;
}

interface FetchSubscriptionResponse {
  success: boolean;
  errorMessage?: string;
  subscription?: CloseFan;
}

class StoreManager2 {
  constructor() {}

  async purchaseCloseFanSubscription(
    creator: VLUser,
    price: number,
    currency: string,
    chargeType: 'monthly' | 'annually',
    referralId?: string,
    affiliateDealId?: string,
    promotionId?: string
  ): Promise<PurchaseSubscriptionResponse> {
    // console.log("headers: ", headers);

    //setup params
    var params = {
      creator_id: creator.id,
      price: price,
      currency: currency,
      withdraw_funds: 0, //0 if we should charge a card/payment method, 1 if we should charge their a account "balance"
      referral_id: referralId,
      affiliate_deal_id: affiliateDealId,
      chargeType: chargeType,
      promotion_id: promotionId,
    };

    const body = JSON.stringify(params);

    // prepare headers;
    var headers = await userManager.getRequestHeaders();

    //url
    const url = api.PURCHASE_CLOSE_FAN_SUBSCRIPTION;

    //make request
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    // utils.log.debug('purchase subscription result', json);

    let result = <PurchaseSubscriptionResponse>{};
    let subscription = CloseFan.fromJSON(json);

    if (subscription) {
      dBManager.addObject(LOCAL_DB_COLLECTION_SUBSCRIPTIONS, subscription);
      logPurchaseMembership(subscription);
      result.subscription = subscription;
    } else {
      let error = VLError.fromJSON(json.error);
      result.errorMessage =
        error?.localizedTitle() ?? 'Could not complete purchase';
    }
    // utils.log.debug('purchase subscription parsed result:', result);
    return result;
  }

  async fetchSubscriptionObject(
    creator_id: string,
    user_id: string,
    currency: string
  ) {
    // console.log("headers: ", headers);

    const db = firebase.firestore();
    const resultsSnapshot = await db
      .collection(constants.FIREBASE_COLLECTION_SUBSCRIPTIONS)
      .where('creator_id', '==', creator_id)
      .where('user_id', '==', user_id)
      .get();

    //2. Generate array of creators
    var results: CloseFan[] = [];
    resultsSnapshot.docs.forEach((element) => {
      let request = CloseFan.fromJSON(element.data());
      if (request) results.push(request);
    });
    return results.length > 0 ? results[0] : null;
  }
}

var storeManager2 = new StoreManager2();
export default storeManager2;
