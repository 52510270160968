import React, { useState } from 'react';
import styles from './BookingBox.module.scss';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

const BookingBox = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`${styles.BookingBox} d-flex justify-content-between ${
        i18n.language === 'he' || i18n.language === 'global' || !i18n.language
          ? styles.heb
          : ''
      }`}
    >
      <div className={styles.main}>
        <div className={styles.title}>{props.title}</div>
        <div className={`d-flex ${styles.profile}`}>
          {props.img ? (
            <img
              src={props.img}
              className={`${styles.image} ${
                props.icon ? styles.imageIcon : ''
              }`}
            />
          ) : null}
          <div className={styles.text}>{props.text}</div>
        </div>
      </div>
      {props.showCheckbox ? (
        <div className={styles.checkbox}>
          <div className={styles.checkbox_text}>
            {t('hide_my_name_on_order')}
          </div>
          <span onClick={props.toggleShowMyName}>
            <Form.Check
              type="switch"
              checked={props.showMyName}
              className={styles.checkboxBootstrap}
            />
          </span>
        </div>
      ) : null}
    </div>
  );
};
export default BookingBox;
