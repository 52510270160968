import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Links: React.FC<{ isDark: boolean }> = React.memo(({ isDark }) => {
  const { t, i18n } = useTranslation();

  const changeToEnglish = () => {
    i18n.changeLanguage('en');
  };

  const changeToHeb = () => {
    i18n.changeLanguage('he');
  };

  return (
    <Row>
      {/*<Col>*/}
      {/*  <h6>Links</h6>*/}
      {/*  <ul className="list-unstyled">*/}
      {/*    <li>Team</li>*/}
      {/*    <li>Media</li>*/}
      {/*    <li>FAQ</li>*/}
      {/*  </ul>*/}
      {/*</Col>*/}
      <Col>
        <h6>Socials</h6>
        <div className="text-center">
          <div>
            <a href="https://facebook.com/kre8.tv" target="_blank">
              Facebook
            </a>
          </div>
          <br></br>
          <div>
            <a
              href="https://www.instagram.com/kre8.tv.official/"
              target="_blank"
            >
              Instagram
            </a>
          </div>
        </div>
      </Col>
      <Col>
        <div className={isDark ? 'text-white' : 'text-black'}>
          <h6>Language</h6>
          <div className="text-center">
            <div onClick={changeToEnglish}>English</div>
            <br></br>
            <div onClick={changeToHeb}>עברית</div>
          </div>
        </div>
      </Col>
    </Row>
  );
});

export default Links;
