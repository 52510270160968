import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
  TextField,
  SvgIcon,
  ButtonBase,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useTranslation } from 'react-i18next';
import { withOnboardingStage } from '../../../shared/hoc';

import UploadImageIcon from '../images/upload_image_icon.svg';
import ShareIcon from '@material-ui/icons/Share';

//General
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../../../App';
import VLButton from '../../../Reusables/Buttons/VLButton';
import uploadManager from '../../../managers/uploadManager';
import profileManager from '../../../managers/profileManager';
import VLFlowManager, {
  OnboardingFlowEventEditProfile,
} from '../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {},

  activeSceneLine: {
    background: '#5D36BD',
    opacity: 1.0,
    borderRadius: '4px',
    width: '26px',
  },
  sceneLine: {
    width: '10px',
    cursor: 'pointer',
    opacity: 0.5,
    background: '#DBCAFE',
    borderRadius: '4px',
  },
  textField: {
    '& .MuiFormLabel-root': {
      color: 'white',
    },
    color: 'white',
  },
  textFieldRoot: {
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff8', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#fff', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff', // Solid underline on focus
    },
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid #C8CACF',
      height: '2px',
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      height: '2px',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      height: '2px',
    },
    '&:hover:not($disabled):not($focused):not($error):after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      height: '2px',
    },
  },
  uploadImageBackground: {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '20px',
    overflow: 'auto',
  },
  shareIcon: {
    color: 'white',
    background: 'rgba(255, 255, 255, 0.1)',
  },
}));

const InviteCodeSection = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const title = t('onboarding_profile_info_invite_code_title');
  const detail = t('onboarding_profile_info_invite_code_detail');
  return (
    <Box mt={2} mb={2} height="72px" width="100%">
      <ButtonBase style={{ width: '100%', height: '100%' }}>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            id="icon-container"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              borderRadius="50%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              className={classes.shareIcon}
              width="48px"
              height="48px"
            >
              <ShareIcon />
            </Box>
          </Box>
          <Box
            mr={2}
            ml={2}
            id="text-container"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            flexGrow={1}
          >
            <Box>
              <Typography variant="h2">
                <Box
                  fontSize={16}
                  fontWeight={700}
                  color="text.secondary"
                  fontFamily="Assistant"
                >
                  {title}
                </Box>
              </Typography>
            </Box>
            <Box>
              <Typography variant="h2">
                <Box
                  fontSize={12}
                  fontWeight={700}
                  color="#C8CACF"
                  fontFamily="Assistant"
                >
                  {detail}
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box
            id="icon-container"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            color="text.secondary"
          >
            {isHebrew() ? <ChevronLeft /> : <ChevronRight />}
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
};
const ProfileImageSection = (props) => {
  const classes = useStyles();
  const { setImageLink, setImageSource, imageLink, defaultValue } = props;
  const { t, i18n } = useTranslation();
  const title = imageLink
    ? t('onboarding_profile_info_selected_image')
    : t('onboarding_profile_info_upload_image');

  /**
   * Logic for taking user selected resource and and processing to result as local link which can be uploaded and saved
   * on our end (firebase hosting rn)
   * @param {*} data
   * @param {*} kind
   */
  const prepareImage = (data) => {
    //upload image to server and update the image in client side
    let reader = new FileReader();
    reader.onload = (e) => {
      // console.log('FileReader fired', data.files[0]);

      setImageLink(e.target.result);
      setImageSource(data.files[0]);
    };

    reader.readAsDataURL(data.files[0]);
  };

  return (
    <Box height="180px">
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <label htmlFor="EditProfileAccountImage">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width="56px"
              height="56px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              className={classes.uploadImageBackground}
            >
              {!imageLink && !defaultValue ? (
                <img
                  alt="upload"
                  color="#AE9BDE"
                  style={{ width: '16px', height: '16px' }}
                  src={UploadImageIcon}
                ></img>
              ) : null}
              {imageLink || defaultValue ? (
                <img
                  alt="profile"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  src={imageLink ?? defaultValue}
                ></img>
              ) : null}
            </Box>
            <Typography variant="h2">
              <Box
                fontSize={16}
                fontWeight={700}
                color="#AE9BDE"
                fontFamily="Assistant"
                mt={2}
                textAlign="center"
              >
                {title}
              </Box>
            </Typography>
          </Box>
        </label>
      </Box>
      <input
        accept="image/*"
        type="file"
        hidden
        id="EditProfileAccountImage"
        onChange={(e) => {
          prepareImage(e.target);
        }}
      />
    </Box>
  );
};

const VLTextField = (props) => {
  const classes = useStyles();
  const { placeholder, inputRef, defaultValue } = props;
  return (
    <Box width="100%" height="50px">
      <TextField
        defaultValue={defaultValue}
        inputRef={inputRef}
        InputProps={{
          classes: {
            underline: classes.underline,
            input: classes.textField,
            root: classes.textFieldRoot,
          },
        }}
        inputProps={{
          classes: {
            underline: classes.underline,
            input: classes.textField,
            root: classes.textFieldRoot,
          },
        }}
        fullWidth={true}
        placeholder={placeholder}
      ></TextField>
    </Box>
  );
};
const ProfileEdit = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [internalStep, setInternalStep] = useState(0);
  const { onConfirm, user } = props;

  const [showLoading, setShowLoading] = useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [showError, setShowError] = React.useState(false);
  const [imageSource, setImageSource] = useState(null);
  const [imageLink, setImageLink] = useState(null);

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new OnboardingFlowEventEditProfile());
  });

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const usernameRef = useRef(null);
  const titleRef = useRef(null);

  let didUploadProfilePicture = false;
  let updatedProfilePictureURL = null;

  const title = t('onboarding_profile_info_title');
  const detail = t('onboarding_profile_info_detail');

  const onConfirmPressed = () => {
    saveProfile();
  };

  const displayError = (detail) => {
    setErrorMsg(detail);
    setShowError(true);
  };

  const allowSave = () => {
    return true;
  };

  /**
   * Complex save function that handles uploading assets (profile pic/video) and data
   */
  const saveProfile = async () => {
    if (allowSave() === false) {
      // console.log('not allowed to save');
      return;
    }

    const updateObj = {
      first_name: firstNameRef.current?.value,
      last_name: lastNameRef.current?.value,
      profile_id: usernameRef.current?.value,
      title: titleRef.current?.value,
    };

    const uploadProfilePicture = (callback) => {
      return uploadManager.uploadProfilePicture(
        imageSource,
        (downloadURL, progress, error) => {
          // console.log('👨‍💻 callbacks...', downloadURL, progress, error);
          // console.log('this...', this);
          if (error) {
            const errorDetail = t('could_not_upload_profile_picture');
            displayError(errorDetail);
            callback(false);
          } else if (downloadURL) {
            // console.log('✅ finished uploading profile picture!', downloadURL);
            updatedProfilePictureURL = downloadURL;
            didUploadProfilePicture = true;
            //great .. continue
            callback(true);
          } else if (progress) {
            // console.log('🔼 updating progress... ', progress);
            //todo: do something with progress
          }
        }
      );
    };

    if (imageSource && didUploadProfilePicture === false) {
      //upload profile picture
      setShowLoading(true);
      uploadProfilePicture((success) => {
        //continue recursively
        if (success) {
          saveProfile();
        } else {
          setShowLoading(false);
          displayError('Could not upload profile picture');
        }
      });
      return;
    }

    if (didUploadProfilePicture && updatedProfilePictureURL) {
      updateObj['profilePictureURL'] = updatedProfilePictureURL;
    }

    //make sure if we even need to save anything
    const { user } = props;
    let diff = user.title !== updateObj.title && updateObj.title.length > 0;
    diff =
      (user.firstName !== updateObj.first_name &&
        updateObj.first_name.length > 0) ||
      diff;
    diff =
      (user.lastName !== updateObj.last_name &&
        updateObj.last_name.length > 0) ||
      diff;
    diff =
      (user.profileId !== updateObj.profile_id &&
        updateObj.profile_id.length > 0) ||
      diff;

    if (!diff && !updateObj.profilePictureURL) {
      onConfirm();
      return;
    }

    setShowLoading(true);
    const attemptSave = await profileManager.updateProfileAccount(updateObj);
    setShowLoading(false);
    // console.log('attemptSave = ', attemptSave);
    if (attemptSave.success) {
      onConfirm();
    } else {
      const errorDetail =
        attemptSave.error && attemptSave.error.localizedTitle();
      displayError(errorDetail);
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box pl={2} pr={2} mt={4} mb={2}>
        <Typography variant="h2">
          <Box fontSize={24} fontWeight={700} color="text.secondary" mb={2}>
            {title}
          </Box>
        </Typography>
        <Typography variant="body1">
          <Box
            fontSize={16}
            fontWeight={600}
            color="text.secondary"
            lineHeight={1.3}
          >
            {detail}
          </Box>
        </Typography>
        <ProfileImageSection
          defaultValue={user.profilePictureURL}
          imageLink={imageLink}
          setImageLink={setImageLink}
          setImageSource={setImageSource}
        />
        <Box>
          <VLTextField
            defaultValue={user.firstName}
            inputRef={firstNameRef}
            placeholder={t('onboarding_profile_info_textfield_first_name')}
          />
          <VLTextField
            defaultValue={user.lastName}
            inputRef={lastNameRef}
            placeholder={t('onboarding_profile_info_textfield_last_name')}
          />
          <VLTextField
            defaultValue={user.profileId}
            inputRef={usernameRef}
            placeholder={t('onboarding_profile_info_textfield_creator_id')}
          />
          <VLTextField
            defaultValue={user.title}
            inputRef={titleRef}
            placeholder={t('onboarding_profile_info_textfield_title')}
          />
        </Box>
        {/* <InviteCodeSection /> */}
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <VLButton onClick={() => onConfirmPressed()} fullWidth={true}>
            {showLoading ? (
              <CircularProgress style={{ color: 'white' }} size={20} />
            ) : (
              t('onboarding_button_confirm')
            )}
          </VLButton>
        </Box>
      </Box>
    </Box>
  );
};
const mapStateToProps = ({ user }) => {
  return { user: user.details };
};

export default connect(mapStateToProps, null)(withOnboardingStage(ProfileEdit));
