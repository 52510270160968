import React, { useState, useEffect } from 'react';
import styles from './AddLinkCreate.module.scss';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import add_main_image from '../Images/add_main_image.svg'
import icon_down from '../Images/icon_down.svg'
import AddLinkSelect from './AddLinkSelect/AddLinkSelect'

const AddImage = (props)=>{
  return (
    <span>
      <input type="file" id='AddLinkImage' style={{display:'none'}} onChange={props.onChange} />
      <label htmlFor="AddLinkImage" className={styles.mainImage}>
        {props.image ? (
          <img className={styles.img} src={props.image} />
          ) : (
            <span>
          <img className={styles.img} src={add_main_image} />
          <div className={styles.mainImage_text}>{props.text}</div>
        </span>)}
      </label>
    </span>)
}

const Inputs = (props)=>{
  return  (<span>
    <input placeholder={props.t('editProfile_create_text')} type="text" className={styles.input} value={props.data.title} onChange={(e)=>{props.updateLinkData(e.target.value,'title')}} />
    <input placeholder={props.t('editProfile_create_url')} type="text" className={styles.input} value={props.data.url} onChange={(e)=>{props.updateLinkData(e.target.value,'url')}}/>
    <div className={`${styles.input} ${styles.selectList}`} onClick={()=>{props.setShowSelectPrice(true)}}>
      <span className={props.priceType ? styles.selectedType : ''}>{props.priceType ? props.priceType : props.t('editProfile_create_setPrice')}</span> <img src={icon_down} />
    </div>
    {props.priceType === props.t('editProfile_list_from') ? (
      <div className={styles.fromLabel}>
        <div className={styles.label}>{props.t('editProfile_list_from')}</div>
        <input placeholder={props.t('editProfile_create_price')} type="text" className={styles.input} value={props.price} onChange={(e)=>{props.updateLinkData(e.target.value,'priceValue')}}/>
      </div>
    ) : null}
  </span>)
}

const Button = (props)=>{
  return (
    <span style={{width:'100%'}}>
      <div className={`${styles.button} ${props.checkIfActive() ? styles.button_active : null}`} onClick={props.save}>{props.text}</div>
      {props.error ? (<div className={styles.error}>{props.error}</div>) : null} 
    </span>
  )
}
let timeout
const AddLinkCreate = (props) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState('')
  const [showSelectPrice,setShowSelectPrice] = useState(false)

  const checkIfActive = () => {//check if the add button suppose to be active or not
    let data = props.data
    
    if(data.title && data.url && props.data.price && props.data.price.type){
      return true
    }

    return false
  }

  const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  const cancelAddLink = () => {//close the price without save
    setShowSelectPrice(false)
  }

  const saveAddLink = () => {//close the price with save
    setShowSelectPrice(false)
  }

  const save = () => {
    if(!validURL(props.data.url)){
      setError('You must write a valid url')
    }
    else if(checkIfActive()){
      props.save()
      setError('')
    }
    else{
      setError('You must write url, title and price')
    }
  }

  let priceType = props.data && props.data.price && props.data.price.type ? props.data.price.type : ''
  let price = props.data && props.data.price && props.data.price.value ? props.data.price.value : ''

  return (
    <div className={`${styles.wrapper} ${i18n.language === 'he' ? styles.heb : ''}`}>
        {/* Upload image */}
        <AddImage 
          image={props.data && props.data.img && props.data.img.imgLink ? props.data.img.imgLink : ''}
          onChange={(e)=>{props.updateLinkData(e.target,'image')}} text={t('editProfile_create_addImage')}/>

        {/* Input */}
        <Inputs data={props.data}  price={price} priceType={priceType} t={t} updateLinkData={props.updateLinkData} setShowSelectPrice={setShowSelectPrice} />

        {showSelectPrice ? (
          <AddLinkSelect cancelAddLink={cancelAddLink} saveAddLink={saveAddLink} onChange={props.updateLinkData} selected={priceType} />
        ) : null}

        {/* Submit button and error */}
        <Button save={save} checkIfActive={checkIfActive} text={t('editProfile_create_add')} error={error} />
    </div>
  );
};

export default withRouter(AddLinkCreate);
