import * as strings from '../utils/vlStrings.js';
import * as priceUtils from '../utils/priceUtils';
import { convertToPrice, toPercentage } from '../utils/rhutils.js';

class VLAccountDetails {
  id = '';
  balance = 0;
  pendingBalance = 0;
  currency = 'ILS';
  pendingAgreementURL = null;
  invitationCode = null;
  referralPercent = 0.05; //for talents
  expectedPayoutDate = Date(0);
  payoutMilestone = -1;
  showBankAccountInfo = false;
  showCharitySelectionOnEdit = false;
  paypalEmail = null;
  totalEarnings = 0;
  noComissionApplicationStatus = 'none';
  baseCurrency = 'ILS';
  didCompleteOnboarding = false;
  email = null;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  totalEarningsPriceString() {
    if (!this.totalEarnings) {
      return '0';
    }
    return priceUtils.toPriceString(this.currency, this.totalEarnings);
  }

  balancePriceString() {
    if (!this.balance) {
      return '0';
    }
    return priceUtils.toPriceString(this.currency, this.balance);
  }

  leftToWithdrawal(allowNegative = false) {
    if (!this.balance) {
      return this.payoutMilestone;
    }

    const balanceInPayoutCurrency = convertToPrice(
      this.balance,
      this.baseCurrency,
      this.currency
    );

    const milestoneInCurrentCurrency = convertToPrice(
      this.payoutMilestone,
      this.baseCurrency,
      this.currency
    );
    const left = milestoneInCurrentCurrency - balanceInPayoutCurrency;
    return allowNegative ? left : Math.max(left, 0);
  }

  leftToWithdrawalPriceString() {
    return priceUtils.toPriceString(this.currency, this.leftToWithdrawal());
  }

  payoutPercentString() {
    if (!this.balance) {
      return '0%';
    }

    const percent = this.payoutPercent();
    return `${toPercentage(percent, false)}`;
  }

  payoutPercent() {
    if (!this.balance) {
      return '0';
    }
    const balanceInPayoutCurrency = convertToPrice(
      this.balance,
      this.baseCurrency,
      this.currency
    );

    const percent = balanceInPayoutCurrency / this.payoutMilestone;
    return percent;
  }

  pendingBalancePriceString() {
    if (!this.pendingBalance) {
      return '0';
    }
    return priceUtils.toPriceString(this.currency, this.pendingBalance);
  }

  inviteURL() {
    if (this.invitationCode) {
      return `https://kre8.tv/i/${this.invitationCode}`;
    } else {
      return '';
    }
  }

  showNoComissionBanner = () => {
    return this.noComissionApplicationStatus === 'none';
  };

  static fromJSON(json) {
    var ad = new VLAccountDetails();
    try {
      ad.id = json.id;
      ad.balance = Number(json.balance);
      ad.pendingBalance = Number(json.pending_balance);
      ad.currency = json.currency ? json.currency : ad.currency;
      ad.totalEarnings = Number(json.total_earnings);
      ad.invitationCode = json.invitation_code;
      ad.payoutMilestone = Number(json.payout_milestone ?? 1000);
      ad.noComissionApplicationStatus =
        json.no_commission_application_status ??
        ad.noComissionApplicationStatus;
      ad.didCompleteOnboarding = json.did_complete_onboarding ?? false;
      ad.email = json.email;
      return ad;
    } catch (error) {
      return null;
    }
  }
}

export default VLAccountDetails;
