import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isHebrew } from '../../../App';
import VLButton from '../../../Reusables/Buttons/VLButton';
import VLBigTitle from '../../../Reusables/Texts/VLBigTitle';
import VLParagraph from '../../../Reusables/Texts/VLParagraph';
import PhoneEmailInputTextField from '../PhoneEmailInputTextField';
import IconFacebook from '../images/auth_small_facebook.svg';
import IconGoogle from '../images/auth_small_google.svg';
import IconApple from '../images/auth_small_apple.svg';
import ModalBackground from '../images/modal_bg.jpg';
import { centerFlexColumn } from '../../../utils/bootstrapUtils';
import { vlAppLogoMainWhite } from '../../../utils/vlImages';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  main: {
    maxHeight: '720px',
    height: '100vh',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0px',
      maxHeight: '100%',
      height: '100%',
    },
  },
  LowerCardContainer: {
    padding: theme.spacing(3),
    background: 'white',
    borderRadius: '24px 24px 0px 0px',
    overflow: 'hidden',
  },
  buttonContainer: {
    width: '100%',
  },
  providerButton: {
    border: '1px solid #518ef8',
    boxSizing: 'border-box',
    borderRadius: '100px',
    height: '64px',
    marginTop: '10px',
    img: {
      height: '24px',
      width: '24px',
      borderRadius: '0px',
    },
  },
  providerButtonTitle: {
    textTransform: 'none',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '120%',
    textAlign: 'center',
    letterSpacing: '-0.41px',
    flex: 'none',
    order: 1,
    flexGrow: 0,
    margin: '0px 10px',
  },
  sep: {
    width: '40%',
    height: '2px',
    background: '#e3e3ee',
    borderRadius: '74px',
  },
  text: {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.165px',
    color: '#000000',
  },
  heb: {
    direction: 'rtl',
  },
  IconButton: {
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
  },
}));

type Providers = 'google' | 'facebook' | 'phone' | 'email';

const SimpleNavbar = (props: any) => {
  const { handleClose } = props;
  const classes = useStyles();

  return (
    <div>
      <Box
        p={1.5}
        zIndex={2}
        width="100%"
        position="absolute"
        top={0}
        left={0}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <IconButton
          className={classes.IconButton}
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </div>
  );
};

const ButtonsTop = (props: any) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { showLoadingFacebook, showLoadingGmail, showLoadingApple } = props;

  const button = (
    id: string,
    img: any,
    title: string,
    color: string,
    onButtonClicked: any,
    showLoading: boolean,
    disabled: boolean
  ) => {
    return (
      <Button
        disabled={disabled}
        onClick={() => onButtonClicked(id)}
        style={{ color: `#${color}`, borderColor: `#${color}` }}
        className={`${classes.providerButton} ${disabled ? `opacity-2` : ''}`}
      >
        {showLoading ? (
          <CircularProgress size={36} style={{ color: `#${color}` }} />
        ) : (
          <div className={`d-flex flex-row `} style={{ width: '220px' }}>
            <img src={img} />
            <div
              style={{ color: `#${color}` }}
              className={classes.providerButtonTitle}
            >
              {title}
            </div>
          </div>
        )}
      </Button>
    );
  };

  const disabled = showLoadingGmail || showLoadingFacebook || showLoadingApple;
  return props.show === false ? null : (
    <div className={`${classes.buttonContainer}  d-flex flex-column`}>
      {button(
        'google',
        IconGoogle,
        t('auth_provider_connect_with_google'),
        '518EF8',
        props.onButtonClicked,
        showLoadingGmail,
        disabled
      )}
      {button(
        'facebook',
        IconFacebook,
        t('auth_provider_connect_with_facebook'),
        '3B5998',
        props.onButtonClicked,
        showLoadingFacebook,
        disabled
      )}
      {/* {button(
        'apple',
        IconApple,
        t('auth_provider_connect_with_apple'),
        '000000',
        props.onButtonClicked,
        showLoadingApple,
        disabled
      )} */}
    </div>
  );
};

const LowerCardContainer = (props: any) => {
  const classes = useStyles();
  return (
    <Box zIndex={1} className={classes.LowerCardContainer} width="100%">
      <Box display="flex" flexDirection="column" alignItems="center">
        {props.children}
      </Box>
    </Box>
  );
};

const Disclaimer = (props: any) => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      width="100%"
      lineHeight={1.3}
      mt={2}
      mb={2}
      textAlign="center"
      className="text-muted"
      fontSize="14px"
      fontWeight={600}
    >
      By using KRE8.TV you agree to our
      <div className="list-inline-item ml-1 mr-1">
        <a href="https://kre8.tv/termsofservice.html">
          {` ${t('terms_of_service')} `}
        </a>
      </div>
      and
      <div className="list-inline-item ml-1 mr-1">
        <a href="https://kre8.tv/privacypolicy.html">{t('privacy_policy')}</a>
      </div>
    </Box>
  );
};

const Welcome = (props: any) => {
  const { t, i18n } = useTranslation();
  const {
    isInputValid,
    showLoadingFacebook,
    showLoadingGmail,
    showLoadingApple,
  } = props;

  const TitleSection = () => {
    return (
      <Box
        style={{
          zIndex: 2,
          position: 'absolute',
          top: 70,
          left: 0,
          width: '100%',
        }}
        zIndex={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Box mb={2}>
          <img
            alt="logo"
            src={vlAppLogoMainWhite}
            style={{ width: '120px', height: 'auto' }}
          />
        </Box>
        <Typography variant="h5">
          <Box
            color="white"
            mt={2}
            fontWeight="bold"
            fontFamily="Assistant"
            textAlign="center"
            p={2}
          >
            {t('auth_flow_container_detail')}
          </Box>
        </Typography>
      </Box>
    );
  };

  const Separator = (props: any) => {
    const classes = useStyles();
    return props.show === false ? null : (
      <Box position="relative" width="100%">
        <div
          className={`d-flex flex-row justify-content-between align-items-center`}
        >
          <div className={classes.sep}></div>
          <div className={classes.text}>or</div>
          <div className={classes.sep}></div>
        </div>
      </Box>
    );
  };

  const ConnectButton = () => {
    return (
      <Box mt={2} width="100%">
        <Grid container justify="center">
          <VLButton
            disableElevation={true}
            fullWidth={true}
            style={{ opacity: isInputValid ? 1.0 : 0.7 }}
            // disabled={
            //   isInputValid == false || showLoadingFacebook || showLoadingGmail
            // }
            onClick={() => (isInputValid ? props.onConnectClick() : null)}
          >
            {t('connect_screen_cta')}
          </VLButton>
        </Grid>
      </Box>
    );
  };

  const PromoCodeSection = () => {
    return (
      <div>
        <div></div>
        <div></div>
      </div>
    );
  };

  const classes = useStyles();

  const {
    step,
    isValid,
    onButtonClicked,
    onEmailRecognized,
    onPhoneRecognized,
    onConnectClick,
    onInvalidRecognized,
    value,
    onClose,
    hideClose,
  } = props;

  return step === 'initial' ? (
    <div
      className={`position-relative ${isHebrew() ? classes.heb : ''} ${
        classes.main
      }`}
    >
      <img
        alt="background"
        src={ModalBackground}
        style={{
          zIndex: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      {hideClose ? null : <SimpleNavbar handleClose={() => onClose()} />}
      <TitleSection />
      <Box
        style={{
          zIndex: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <LowerCardContainer>
          <div className={`${centerFlexColumn()} w-100`}>
            <Typography variant="h5">
              <Box
                color="black"
                mb={3}
                fontSize="24px"
                fontWeight="bold"
                fontFamily="Assistant"
              >
                {t('auth_flow_container_title')}
              </Box>
            </Typography>
            <div className={`w-100`}>
              <PhoneEmailInputTextField
                onEnterPressed={() => onConnectClick()}
                onInvalidRecognized={onInvalidRecognized}
                value={value}
                onEmailRecognized={onEmailRecognized}
                onPhoneRecognized={onPhoneRecognized}
              />
            </div>
          </div>
          <ConnectButton {...props} />
          <Separator show={true} {...props} />
          <ButtonsTop
            {...props}
            show={true}
            onButtonClicked={(id: Providers) => onButtonClicked(id)}
          />
          <Disclaimer />
          <PromoCodeSection />
        </LowerCardContainer>
      </Box>
      <div id="recaptcha-hidden-container" />
    </div>
  ) : null;
};

export default Welcome;
