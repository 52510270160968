import React, { useState, useEffect } from 'react';
import styles from './EditProfileAccountTags.module.scss';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditProfileHeader from '../../EditProfileHeader/EditProfileHeader'
import plus_icon from '../../Images/plus_icon.svg'
import v_icon from '../../Images/v_icon.svg'

const Tag = (props)=>{
  return (<div 
    onClick={()=>{props.toggleTag(props.tag)}}
    className={`${styles.tag} ${props.selected ? styles.active : ''}`}>
        <img src={props.selected ? v_icon : plus_icon} /> {props.tag}
    </div>)
}

const Category = (props)=>{
  return (<div className={styles.category}>
      <div className={styles.title}>{props.category}</div>
      <div className={styles.tags}>
          {props.categoryData.tags.map(tag=>{
            if(props.searchValue && !tag.toLowerCase().includes(props.searchValue.toLowerCase())){
            return <span></span>
            }

            let selected = props.unsaveTags.includes(tag)
            return <Tag toggleTag={props.toggleTag} tag={tag} selected={selected}/>
          })}
      </div>
  </div>)
}

const EditProfileAccountTags = (props) => {
  const { t, i18n } = useTranslation();
  const [unsaveTags, setUnsaveTags] = useState([''])//save all the tags before save
  const [searchValue, setSearchValue] = useState('')//if there is a search value (from the header)

  const tags = [
    {category:'Music',tags:['Songwriter','hello','Entertainment']},
    {category:'Art',tags:['Inspiration','Designer','Architect']},
    {category:'Business',tags:['Marketing','Motivation','Entrepreneurship']},
  ]

  useEffect(()=>{//if the tags has changed we need to update the sate
    setUnsaveTags(props.saveTags)
  },[props.saveTags])

  const toggleTag = (tag)=>{//turn on/off a tag
    let find = unsaveTags.find(oldTag=>{return oldTag === tag})
    let newTags
    if(find){
        newTags = unsaveTags.filter(oldTag=>{return oldTag !== tag})
    }
    else{
        newTags = [...unsaveTags]
        newTags.push(tag)
    }

    setUnsaveTags(newTags)
  }

  const onChangeSearch = (newSearch)=>{
    setSearchValue(newSearch)
  }

  return (
    <div className={`${styles.wrapperBig} ${i18n.language === 'he' ? styles.heb : ''}`}>
       <EditProfileHeader onChangeSearch={onChangeSearch} title={t('editProfileAccountTags_title')} close={props.hide}/>

       <div className={styles.wrapper}>
           <div className={styles.text}>{t('editProfileAccountTags_text')}</div>
           {tags.map((categoryData,index)=>{
               return <Category key={index} unsaveTags={unsaveTags} toggleTag={toggleTag} categoryData={categoryData} searchValue={searchValue}/>
           })}

           <div className={styles.save} onClick={()=>{props.addTags(unsaveTags)}}>{t('editProfileAccountTags_add')}</div>
       </div>
    </div>
  );
};

export default withRouter(EditProfileAccountTags);
