import React, { useState } from 'react';
import {
  ICategory,
  ICreator,
  IHomepageSection,
} from '../../../model/interfaces';
import Category from './Category/Category';
import Creator from './Creator/Creator';
import CreatorRankRow from './CreatorRankRow/CreatorRankRow';
import Hero from './Hero/Hero';
import styles from './Homepage.module.scss';
import RequestBox from './RequestBox/RequestBox';
import HomepageSection from './Section/HomepageSection';
import { getI18n } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import { Component } from 'react';
import { useComponentVisible } from '../../../shared/hooks';
import { Box, Fade, Zoom } from '@material-ui/core';
import Header from './Header/Header';
import HomeVideoWidget from './Videos/HomeVideoWidget';

const isMobile = window.innerWidth < 768;

const CategoriesSection = ({
  sectionData,
  discover,
}: {
  sectionData: IHomepageSection | undefined;
  discover?: boolean;
}) => (
  <HomepageSection
    title={sectionData?.title || ''}
    actionLabel={getI18n().t('see_all')}
    actionLink={sectionData?.deep_link_url}
    discover={discover}
    hideSeeMore={true}
  >
    {sectionData?.children.map((category: ICategory, index: number) => (
      <Category
        key={index}
        index={index}
        category={category}
        discover={discover}
      />
    ))}
  </HomepageSection>
);

const CreatorCategorySection = ({
  sectionData,
  discover,
}: {
  sectionData: IHomepageSection | undefined;
  discover?: boolean;
}) => {
  return (
    <HomepageSection
      title={sectionData?.title || ''}
      actionLabel={getI18n().t('see_all')}
      actionLink={sectionData?.deep_link_url}
      discover={discover}
    >
      {sectionData?.children?.map((creator, index) => (
        <Box mr={1} ml={1} component="span">
          <Creator
            key={creator.id}
            index={index}
            creator={creator}
            discover={discover}
          />
        </Box>
      ))}
    </HomepageSection>
  );
};

const VideosSection = ({
  sectionData,
  discover,
  onVideoClicked,
}: {
  sectionData: IHomepageSection | undefined;
  discover?: boolean;
  onVideoClicked?: any;
}) => {
  return (
    <HomepageSection
      title={sectionData?.title || ''}
      actionLabel={getI18n().t('see_all')}
      actionLink={sectionData?.deep_link_url}
      discover={discover}
      hideSeeMore={true}
    >
      <Box display="flex" flexDirection="row">
        {sectionData?.children?.map((video, index) => (
          <Box
            mr={1}
            ml={1}
            component="span"
            onClick={() => onVideoClicked(video)}
          >
            <HomeVideoWidget
              key={video.id}
              index={index}
              videoURL={video.video_url}
              imageURL={video.image_url}
              profilePictureURL={video.profile_picture_url}
              title={video.title}
              detail={video.detail}
              profileId={video.profile_id}
              discover={discover}
            />
          </Box>
        ))}
      </Box>
    </HomepageSection>
  );
};

const TopCreators = ({
  sectionData,
  discover,
}: {
  sectionData: IHomepageSection | undefined;
  discover?: boolean;
}) => (
  <HomepageSection
    title={sectionData?.title || ''}
    actionLabel={getI18n().t('see_all')}
    actionLink={sectionData?.deep_link_url}
    discover={discover}
  >
    <div className={styles.topCreatorsRanks}>
      {(
        sectionData?.children.slice(0, isMobile && !discover ? 5 : 15) || []
      ).map((creator: ICreator, index: number) => (
        <CreatorRankRow
          key={creator.id}
          position={index + 1}
          creator={creator}
        />
      ))}
    </div>
  </HomepageSection>
);

const ItemsCategorySection = ({
  sectionData,
  discover,
}: {
  sectionData: IHomepageSection | undefined;
  discover?: boolean;
}) => (
  <HomepageSection
    title={sectionData?.title || ''}
    actionLabel={getI18n().t('see_all')}
    actionLink={sectionData?.deep_link_url}
    discover={discover}
  >
    {sectionData?.children.map((request, index) => (
      <RequestBox index={index} request={request} />
    ))}
  </HomepageSection>
);

const WrapperComponent = (props: any) => {
  const { forceShow } = props;
  const [isVisible, setIsVisible] = useState(false);
  const { index } = props;

  return (
    <div>
      <VisibilitySensor
        partialVisibility={true}
        onChange={(visible) => {
          if (!isVisible) {
            setIsVisible(visible);
          }
        }}
      >
        <div style={{ minHeight: '100px' }}>
          <Zoom
            in={true}
            timeout={1000}
            style={{ transitionDelay: `${index + 1 * 300}ms` }}
          >
            <Fade in={true} timeout={2000}>
              <div>{isVisible || forceShow ? props.children : null}</div>
            </Fade>
          </Zoom>
        </div>
      </VisibilitySensor>
    </div>
  );
};
type SctionsProps = {
  feed: IHomepageSection[];
  discover: boolean;
  onVideoClicked?: any;
};

const Sections: React.FC<SctionsProps> = React.memo(
  ({ feed, discover, onVideoClicked }) => {
    let component = null;
    const renderSection = (sectionData: IHomepageSection, index: number) => {
      let hasData = sectionData.children.length > 0;
      if (hasData === false) {
        return null;
      }
      let forceShow = false;
      switch (sectionData.kind) {
        case 'featured':
          forceShow = true;
          // component = <Hero creators={sectionData?.children.slice(0, 9) || []} />;
          component = null;
          break;
        case 'creator_category':
          component = (
            <CreatorCategorySection
              key={sectionData.id}
              sectionData={sectionData}
              discover={discover}
            />
          );
          break;
        case 'categories':
          component = discover ? null : (
            <CategoriesSection sectionData={sectionData} discover={discover} />
          );
          break;
        case 'items_category':
          component = (
            <ItemsCategorySection
              sectionData={sectionData}
              discover={discover}
            />
          );
          break;
        case 'list':
          component = (
            <TopCreators sectionData={sectionData} discover={discover} />
          );
          break;
        case 'videos':
          component = (
            <VideosSection
              sectionData={sectionData}
              discover={discover}
              onVideoClicked={onVideoClicked}
            />
          );
          break;
        default:
          component = null;
      }

      return index === 0 && !discover ? (
        <Box mt={2}>
          <Zoom in={true} timeout={1000} style={{ transitionDelay: `0ms` }}>
            <Header />
          </Zoom>
        </Box>
      ) : component ? (
        <div>
          <WrapperComponent index={index} forceShow={forceShow}>
            {component}
          </WrapperComponent>
        </div>
      ) : null;
    };

    return (
      <>
        {feed
          ?.filter((section) => section.children)
          .map((section: IHomepageSection, index) =>
            renderSection(section, index)
          )}
      </>
    );
  }
);

export default Sections;
