import React from "react";
import styles from "../AuthDialog.module.scss";

import { withTranslation } from 'react-i18next';
import { Image } from '../../components/image/image.component';

import DropImage from '../../assets/icons/icon_image.svg';
import Camera from '../../assets/icons/icon_camera.svg';
import Download from '../../assets/icons/icon_download.svg';

class UploadPictureBody extends React.Component<any, any>
{
  fileRef?: any;

  constructor(props: any) {
    super(props);

    this.state = {
      title: "",
      thumb: null
    }
  }

  onTitleChange(event: any)
  {
    let { title } = this.state;
    title = event.target.value;

    this.setState({ title });
  }

  onFileChange(event: any)
  {
    let file = event.currentTarget.files[0];

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ thumb: reader.result });
    };

    reader.readAsDataURL(file);

    this.props.uploadProfilePicture(file, this.state.title)
  }

  render()
  {
    let { className, t } = this.props;
    let { title, thumb } = this.state;

    const Dropzone = () => {
    return <div className={styles.dropzone}>
        <div>
          {thumb && [<img key={`dz.1`} src={thumb} className={styles.previewImage}/>, <div key={`dz.2`} className={styles.previewImageAfter} />]}
          {!thumb && <Image src={DropImage} />}
          {/* <UploadInput/> */}
        </div>
      </div>
    }

    const UploadInput = () => {
      return <input accept="image/*" type={"file"} ref={ref => this.fileRef = ref} onChange={this.onFileChange.bind(this)} />
    }

    const CameraInput = () => {
      return <input type="file" accept="image/*;capture=camera" />;
    }

    return <div className={className}>
      <Dropzone/>
      <section className={styles.manualUpload}>
        <div>
          <button>
              <Image src={Camera} />
              <span>Upload</span>
              <UploadInput/>
          </button>
          {/*<button>*/}
          {/*  <Image src={Camera} />*/}
          {/*  <span>Open Camera</span>*/}
          {/*  <CameraInput />*/}
          {/*</button>*/}
        </div>
      </section>

      <section className={styles.naming}>
        <input type={"text"} placeholder={t("upload_pic_step_input_placeholder")} value={title} onChange={this.onTitleChange.bind(this)} maxLength={70}/>
      </section>
    </div>
  }
}

export default withTranslation()(UploadPictureBody);