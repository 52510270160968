import React from 'react';
import VLSnackbar from '../../components/Widgets/Snackbar';

const withErrorSnackbar = (Component: React.FC) => (props: any) => {
  const show = props.show;
  const [showError, setShowError] = React.useState(null);

  return (
    <>
      <Component {...props} setShowError={setShowError} />
      <VLSnackbar
        open={showError}
        severity="error"
        message={showError}
        onShouldClose={() => setShowError(null)}
      />
    </>
  );
};
export default withErrorSnackbar;
