import { Player } from '@lottiefiles/react-lottie-player';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Fade,
} from '@material-ui/core';
import React from 'react';
import PrimaryLoader from './cube-loader-white.json';
import { makeStyles } from '@material-ui/core/styles';
import { lclDirection } from '../../utils/vlStrings';

/**
 * Loading component wrapper
 */
interface LoaderProps {
  title?: string;
  show: boolean;
}

const useStyles = makeStyles({
  paper: {
    borderRadius: '24px',
    width: '240px',
    height: '240px',
  },
  title: {
    fontFamily: 'Assistant',
  },
});
const VLProgressLoaderDialog = (props: LoaderProps) => {
  const classes = useStyles();

  const { title, show } = props;
  return (
    <Dialog classes={{ paper: classes.paper }} open={show}>
      <DialogContent>
        <Box
          dir={lclDirection()}
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box id="loader-dialog-title" fontSize="24px">
            {title}
          </Box>
          <Box id="loader-dialog-progress" mt={4}>
            <CircularProgress size={44} />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VLProgressLoaderDialog;
