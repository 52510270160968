import * as strings from "../utils/vlStrings.js";
import VLUser from "../model/vlUser";
import memoize from 'lodash/memoize'

export const ERROR_MUST_SIGN_IN = 3;
class VLCategory {
  id = null;
  title = null;
  subtitle = null;
  imageURL = null;
  videoURL = null;
  users = null;
  isActive = false;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  static fromJSON = memoize((json) => {
    var category = new VLCategory();
    try {
      category.id = json.id;
      category.title = json.title;
      category.subtitle = json.subtitle;
      category.imageURL = json.imageURL;
      category.jumboImageURL = json.jumbo_image_url;

      category.headerTitle = json.header_title;

      category.videoURL = json.videoURL;
      category.isActive = json.is_active ? json.is_active : false;
      const users = json.users
        ? json.users.map((userJSON) => memoize(VLUser.fromJSON(userJSON)))
        : null;
      category.users = users;
      return category;
    } catch (error) {
      return null;
    }
  })
}

export default VLCategory;
