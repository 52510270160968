import { createReducer } from '@reduxjs/toolkit';
import { setMobileView } from '../../routines';

export interface MobileState {
  view: boolean;
}

const initialState: MobileState = {
  view: false,
};

export default createReducer<MobileState>(initialState, {
  [setMobileView.SUCCESS]: (state, action) => ({
    ...state,
    view: action.payload,
  }),
});
