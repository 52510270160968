import {
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

export const VLSmallButton = (props: ButtonProps) => {
  const { t, i18n } = useTranslation();

  const useStyles = makeStyles((theme) => ({
    root: {
      fontFamily: 'Montserrat, Rubik',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '120%',
      textTransform: 'none',
    },
  }));

  const classes = useStyles();

  return (
    <Button className={classes.root} {...props}>
      {props.children}
    </Button>
  );
};

export default VLSmallButton;
