import React from 'react';
import { Dropdown, ListGroup } from 'react-bootstrap';
import * as strings from '../../utils/vlStrings';
import * as utils from '../../utils/rhutils';
import styles from './LangSwitcher.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

type LangSwitcherProps = {
  onLanguageChanged?: any;
  isSidebar?: boolean;
  theme?: any;
};

const LangSwitcher: React.FC<LangSwitcherProps> = React.memo(
  ({ onLanguageChanged, isSidebar, theme }) => {
    const languageSelected = React.useCallback(
      (toHebrew: boolean) => {
        utils.log.debug('toHebrew...', toHebrew);
        strings.updateUserLanauge(toHebrew);
        onLanguageChanged && onLanguageChanged(toHebrew);
      },
      [onLanguageChanged]
    );
    const { i18n } = useTranslation();
    const isHebrew = i18n.language === 'he';
    const isEnglish = !isHebrew;
    const isDark = theme === 'dark';

    if (isSidebar) {
      // console.log(`is sider bar language = ${i18n.language}`)

      return (
        <ListGroup horizontal className={` ${cn(styles.sidebarSwitcher)}`}>
          <ListGroup.Item
            active={isEnglish}
            onClick={() => {
              i18n.changeLanguage('en');
              languageSelected(false);
            }}
          >
            <span className={cn({ [styles.active]: isEnglish })}>English</span>
          </ListGroup.Item>

          <ListGroup.Item
            active={isHebrew}
            onClick={() => {
              i18n.changeLanguage('he');
              languageSelected(true);
            }}
          >
            <span className={cn({ [styles.active]: isHebrew })}>עברית</span>
          </ListGroup.Item>
        </ListGroup>
      );
    }

    return (
      <Dropdown
        className={`${theme === 'dark' || true ? styles.dark : ''} ${cn(
          styles.switcher,
          'd-none d-xl-inline-block d-lg-inline-block'
        )}`}
      >
        <Dropdown.Toggle
          className={`${isDark ? 'btn text-light' : 'btn text-dark'}`}
          id="dropdown-basic"
        >
          {isHebrew ? 'עברית' : 'En'}
        </Dropdown.Toggle>

        <Dropdown.Menu
          className={cn(styles.menu, 'mt-2', {
            [styles.rtl]: isHebrew,
          })}
        >
          <Dropdown.Item
            onClick={() => {
              i18n.changeLanguage('he');
              languageSelected(true);
            }}
          >
            עברית
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => {
              i18n.changeLanguage('en');
              languageSelected(false);
            }}
          >
            English
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

LangSwitcher.defaultProps = {
  isSidebar: false,
};

export default LangSwitcher;
