/**
 * Custom Table
 */
import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Container,
  ButtonBase,
  TextField,
  useMediaQuery,
  InputAdornment,
  useTheme,
} from '@material-ui/core';
import { withRouter, NavLink, Link, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { useTranslation, withTranslation } from 'react-i18next';
import { NewNavbar } from '../../NewNavbar';
import withIntercom from '../../../shared/hoc/with-intercom';
import { AnalyticsEvent, analytics } from '../../../utils/analytics';
import { lclDirection } from '../../../utils/vlStrings';
import { isHebrew } from '../../../App';
import SearchIcon from '@material-ui/icons/Search';
import Creator from '../Homepage/Creator/Creator';
import { AirtableManager } from '../../../VLBase/Managers/VLAirtableManager';
import CreatorRecommendationBox from './CreatorRecommendationBox';
import { orderBy } from 'lodash';

const airtable = new AirtableManager();

const styles = (theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingBottom: '0px',
      marginBottom: '8px',
    },
    paddingBottom: '8px',
    background: '#121029',
  },
  navbar: {
    position: '-webkit-sticky',
    position: 'sticky',
  },
  search: {
    height: '88px',
    fontSize: 32,
    fontWeight: 'bold',
    background: '#060423',
    border: '1px solid rgba(134, 129, 145, 0.3)',
    boxSizing: 'border-box',
    borderRadius: '24px',
  },
  inputRoot: { color: 'rgba(134, 129, 145, 1.0)' },
  inputInput: {
    color: 'white',
    fontSize: 24,
    fontWeight: 'bold',
  },
  CreatorResults: {
    maxHeight: '80vh',
    paddingBottom: '200px',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '80vh',
      paddingBottom: '240px',
    },
  },
});

const Navbar = (props) => {
  const { classes, smallScreen } = props;
  const hideNavBar = false;
  return (
    <NewNavbar
      theme="dark"
      searchVisible={false}
      showSearch={false}
      hideCenterLogo={true}
    />
  );
};

const SearchBarContainer = (props) => {
  let incomingURL = new URL(window.location.href);
  let defaultQuery = incomingURL.searchParams.get('q');

  const { classes, query, smallScreen, onQueryChanged, placeholder } = props;
  return (
    <Box
      id="search-view-container"
      mt={2}
      mb={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="4px"
      height="100%"
      width="100%"
      border={1}
      borderColor="grey.200"
      pl={1}
      pr={1}
      className={classes.search}
    >
      <TextField
        defaultValue={defaultQuery}
        InputProps={{
          disableUnderline: true,
          endAdornment: smallScreen ? null : (
            <InputAdornment position="start">
              <Box
                p={0.5}
                width="56px"
                height="56px"
                borderRadius="20px"
                bgcolor="primary.main"
                color="white"
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <SearchIcon style={{ color: 'white' }} />
              </Box>
            </InputAdornment>
          ),
          autoComplete: 'off',
          classes: {
            input: classes.inputInput,
            root: classes.inputRoot,
          },
        }}
        value={query}
        inputProps={{ disableUnderline: true }}
        style={{
          width: '100%',
          marginLeft: isHebrew() ? '0px' : '16px',
          marginRight: !isHebrew() ? '0px' : '16px',
        }}
        fullWidth
        type="text"
        name="search-users"
        id="search"
        placeholder={placeholder}
        onChange={(e) => onQueryChanged(e.target.value)}
      />
    </Box>
  );
};

const CreatorResults = (props) => {
  const { creators, classes } = props;
  const slicesResults = creators.slice(0, 50);
  return (
    <Box
      className={classes.CreatorResults}
      overflow="auto"
      display="flex"
      alignItems="center"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="center"
    >
      {slicesResults.map((creator, index) => {
        return (
          <Box mr={1} ml={1} key={index}>
            <Creator
              key={creator.id}
              index={index}
              creator={creator}
              discover={false}
              disableSpacing={true}
            />
          </Box>
        );
      })}
    </Box>
  );
};
const SearchMaster = (props) => {
  const { classes, creators } = props;
  const [allResults, setAllResults] = useState(null); //null means failed / not fetched anything yet
  const [isFetchingResults, setisFetchingResults] = useState(true);
  const [failedFetchingChallenge, setFailedFetchingChallenge] = useState(false);
  const [queryValue, setQueryValue] = useState(undefined);
  const [results, setResults] = useState(null);

  const { t, i18n } = useTranslation();

  const muiTheme = useTheme();
  const smallScreen = useMediaQuery(muiTheme.breakpoints.down('xs'));

  useEffect(() => {
    //log
    let incomingURL = new URL(window.location.href);
    let query = incomingURL.searchParams.get('q');
    const properties = [{ query: query }];
    analytics.event(
      new AnalyticsEvent('web - Search master visited'),
      properties
    );
    //fetch initial data
    airtable.fetchCreators(true, null, (creators, any) => {
      //this means it was saved to the store so we can just filter
      if (query && query.length > 0) onChange(query, creators);
    });
  }, []);

  const onChange = (value, forcedCreators) => {
    const query = value;
    setQueryValue(query);

    let lcQuery = value?.toLowerCase();
    if (!creators) return;
    let filterFrom = forcedCreators ?? creators;
    lcQuery = lcQuery === undefined ? '' : lcQuery;
    let res = filterFrom.filter(
      (creator) =>
        creator.id.toLowerCase().indexOf(lcQuery) +
          creator.title.toLowerCase().indexOf(lcQuery) +
          creator.detail.toLowerCase().indexOf(lcQuery) >=
        -2
    );
    setResults(res);
  };

  return (
    <div className={classes.root} dir={lclDirection()}>
      <Navbar {...props} />
      <Container maxWidth="md">
        <SearchBarContainer
          placeholder={t('search_for_creators')}
          query={queryValue}
          smallScreen={smallScreen}
          {...props}
          onQueryChanged={onChange}
        />
        <CreatorResults creators={results ?? creators} classes={classes} />
      </Container>
      <Box
        position="fixed"
        bottom={16}
        width="100%"
        display="flex"
        alignItems="center"
      >
        <Container maxWidth="md">
          <CreatorRecommendationBox smallScreen={smallScreen} />
        </Container>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  let creators = state.home.data.allUsers;
  let sorted = orderBy(creators, ['ranking_score'], ['desc']);
  return {
    creators: sorted,
  };
};

export default withIntercom(
  withRouter(
    connect(
      mapStateToProps,
      null
    )(withStyles(styles, { withTheme: true })(withTranslation()(SearchMaster)))
  )
);
