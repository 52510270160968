import { createSlice } from '@reduxjs/toolkit';
import VLFlowManager, {
  CampaignFlowEventBriefEntry,
  CampaignFlowEventCategorySelect,
  CampaignFlowEventFailure,
  CampaignFlowEventOccassionSelect,
  CampaignFlowEventSuccess,
  CampaignFlowEventSummary,
  CampaignFlowEventTypeSelection,
} from '../../../managers/v2/VLFlowManager';
import store from '../../store';

export interface CampaignsState {
  step: number;
  type: 'personal' | 'business' | undefined;
  ocassion: any;
  ocassion_value: any;
  selectedCategory: any;
  selectedCreators: any[];
  forWho?: string;
  fromWho?: string;
  businessName?: string;
  message?: string;
  deliveryDeadline?: number;
  total_price?: number;
  currency?: string;
  error?: string;
  lastSubmittedBookingRequest?: any;
  selectedEntity: 'for me' | 'someone else' | 'business' | undefined;
  fromRegularBooking: boolean;
}

/* Basic Campaign Model */
const initialState: CampaignsState = {
  step: 0,
  type: undefined,
  selectedEntity: undefined,
  ocassion: undefined,
  ocassion_value: undefined,
  selectedCategory: undefined,
  selectedCreators: [],
  message: undefined,
  deliveryDeadline: undefined,
  currency: undefined,
  total_price: undefined,
  error: undefined,
  lastSubmittedBookingRequest: undefined,
  fromRegularBooking: false,
};

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    // Redux actions
    reset(state, action) {
      state.step = 0;
      state.type = undefined;
      state.selectedEntity = undefined;
      state.ocassion = undefined;
      state.selectedCategory = undefined;
      state.selectedCreators = [];
      state.forWho = undefined;
      state.fromWho = undefined;
      state.businessName = undefined;
      state.message = undefined;
      state.deliveryDeadline = undefined;
      state.currency = undefined;
      state.total_price = undefined;
      state.lastSubmittedBookingRequest = undefined;
      state.fromRegularBooking = false;
    },
    previousStep(state, action) {
      state.step = Math.max(state.step - 1, 0);
    },
    updateStep(state, action) {
      state.step = action.payload;
    },
    updateType(state, action) {
      state.type = action.payload;
      state.step = state.step + 1;
      state.deliveryDeadline = new Date().getTime();
      setTimeout(() => {
        VLFlowManager.sendFlowAnalytics(new CampaignFlowEventTypeSelection());
      }, 500);
    },
    updateOcassion(state, action) {
      state.ocassion = action.payload.title;
      state.ocassion_value = action.payload.value;
      state.step = state.step + 1;
      setTimeout(() => {
        VLFlowManager.sendFlowAnalytics(new CampaignFlowEventOccassionSelect());
      }, 500);
    },
    updateCategory(state, action) {
      state.selectedCategory = action.payload;
      state.step = state.step + 1;
    },
    updateBrief(state, action) {
      state.forWho = action.payload.forWho;
      state.fromWho = action.payload.fromWho;
      state.businessName = action.payload.businessName;
      state.message = action.payload.message;
    },
    onSubmitCampaignClicked(state, action) {
      state.total_price = action.payload.total_price;
      state.currency = action.payload.currency;
      state.step = state.step + 1;
    },
    onSubmitCampaignFailed(state, action) {
      // should assign and show error
      state.error = action.payload.error;
      state.step = state.step - 1;

      setTimeout(() => {
        VLFlowManager.sendFlowAnalytics(new CampaignFlowEventFailure());
      }, 500);
    },
    onDismissError(state, action) {
      // should assign and show error
      state.error = undefined;
    },
    onCampaignSubmitted(state, action) {
      state.step = state.step + 1;
      //success...
      setTimeout(() => {
        VLFlowManager.sendFlowAnalytics(new CampaignFlowEventSuccess());
      }, 500);
    },
    onSelectCreator(state, action) {
      let creator = action.payload;
      let creators = state.selectedCreators;
      creators.push(creator);
      state.selectedCreators = creators;
    },
    onDeselectCreator(state, action) {
      let creator = action.payload;
      let creators = state.selectedCreators;
      creators = creators.filter((c) => c.id !== creator.id);
      state.selectedCreators = creators;
    },

    //quick access to steps
    goToTypeSelection(state, action) {
      state.step = 1;
    },
    goToOcassionSelection(state, action) {
      state.step = 2;
    },
    goToCreatorSelection(state, action) {
      state.step = 4;
    },
    goToBriefSelection(state, action) {
      state.step = 5;
    },
    onRegularBookingRequestCompleted(state, action) {
      const bookingrequest = action.payload;
      state.ocassion = bookingrequest.ocassion;
      state.forWho = bookingrequest.forWho;
      state.fromWho = bookingrequest.fromWho;
      state.type = bookingrequest.businessRequest ? 'business' : 'personal';
      state.message = bookingrequest.requestDescription;
      state.step = 8;
    },
    //after ordering
    startNewCampaignFromScratch(state, action) {
      //clear all
      state.type = undefined;
      state.ocassion = undefined;
      state.selectedEntity = undefined;
      state.selectedCategory = undefined;
      state.selectedCreators = [];
      state.forWho = undefined;
      state.fromWho = undefined;
      state.businessName = undefined;
      state.message = undefined;
      state.deliveryDeadline = undefined;
      state.currency = undefined;
      state.total_price = undefined;
      state.selectedCategory = action.payload;
      state.step = 1;
    },
    startNewCampaignWithCategory(state, action) {
      //clear just the creators
      state.selectedCreators = [];
      state.selectedCategory = action.payload;
      state.step = 4;
    },
    onUpdateLastBookingRequest(state, action) {
      //clear just the creators
      state.lastSubmittedBookingRequest = action.payload;
    },
    startNewCampaignFromRegularOrder(state, action) {
      //clear just the creators
      state.type = action.payload.type;
      state.selectedEntity = action.payload.selectedEntity;
      state.ocassion = action.payload.ocassion;
      state.ocassion_value = action.payload.ocassion_value;
      state.forWho = action.payload.forWho;
      state.fromWho = action.payload.fromWho;
      state.businessName = action.payload.businessName;
      state.message = action.payload.message;
      state.currency = action.payload.currency;
      state.selectedCategory = undefined;
      state.selectedCreators = [];
      state.fromRegularBooking = true;
      state.step = 3; //select categories
    },
  },
});

/* Export Redux Actions */
export const {
  reset,
  updateStep,
  updateType,
  updateOcassion,
  updateCategory,
  onSubmitCampaignClicked,
  onSubmitCampaignFailed,
  onCampaignSubmitted,
  onSelectCreator,
  onDeselectCreator,
  updateBrief,
  previousStep,
  goToTypeSelection,
  goToOcassionSelection,
  goToCreatorSelection,
  goToBriefSelection,
  startNewCampaignWithCategory,
  startNewCampaignFromScratch,
  onDismissError,
  onRegularBookingRequestCompleted,
  onUpdateLastBookingRequest,
  startNewCampaignFromRegularOrder,
} = campaignsSlice.actions;

/* Export the reducer */
export default campaignsSlice.reducer;
