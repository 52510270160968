import { ICategory, ICreator, IVideo } from '../interfaces';
import shuffle from 'lodash/shuffle';

export function HomepageFactory(json: any) {
  return {
    id: json.id,
    kind: json.kind,
    title: json.title,
    deep_link_url: json.deep_link_url,
    children: json.children,
  };
}

export function FeaturedCategoryFactory(
  id: string,
  title: string,
  deep_link_url: string,
  children: any
) {
  return {
    id: id,
    kind: 'featured',
    title: title,
    deep_link_url: deep_link_url,
    children: shuffle(children),
  };
}
export function CategoriesCategoryFactory(
  id: string,
  title: string,
  deep_link_url: string,
  children: ICategory[]
) {
  let addedDeepLink = children.map((child) => {
    child.deep_link_url = `/discover?category_id=${child.id}&title=${child.title}`;
    return child;
  });

  return {
    id: id,
    kind: 'categories',
    title: title,
    deep_link_url: deep_link_url,
    children: addedDeepLink,
  };
}

export function CreatorCategoriesCategoryFactory(
  id: string,
  title: string,
  deep_link_url: string,
  children: ICreator[]
) {
  return {
    id: id,
    kind: 'creator_category',
    title: title,
    deep_link_url: deep_link_url,
    children: shuffle(children),
  };
}

export function VideosCategoryCategoryFactory(
  id: string,
  title: string,
  deep_link_url: string,
  children: IVideo[]
) {
  return {
    id: id,
    kind: 'videos',
    title: title,
    deep_link_url: deep_link_url,
    children: shuffle(children),
  };
}

/**
 *
 * @param id
 * @param title
 * @param deep_link_url
 * @param children Children of type ICreator[]  with image, title, detail, info (rank / number), deep_link_url
 * @returns
 */
export function ListCategoryFactory(
  id: string,
  title: string,
  deep_link_url: string,
  children: ICreator[]
) {
  return {
    id: id,
    kind: 'list',
    title: title,
    deep_link_url: deep_link_url,
    children: children,
  };
}
