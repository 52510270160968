import React, { useState, useRef, useEffect } from 'react';
import styles from './SubscriptionPaywall.module.scss';
import { useTranslation } from 'react-i18next';
import iconClose from '../../assets/icons/icon_close_white.svg';
import VLUser from '../../model/vlUser';
import userManager from '../../managers/userManager';
import { centerFlexColumn } from '../../utils/bootstrapUtils';
import { ButtonBase } from '@material-ui/core';
import { URL_TERMS_OF_SERVICE } from '../../constants/rhconstants';
import CloseFanPlan from '../../model/closeFanPlan';
import NewAuthenticationFlowContainer from '../../signup/NewAuthenticationFlow/NewAuthenticationFlowContainer';
import { AuthModal } from '../../signup/AuthModal';
import firebase from 'firebase';

/**
 * @param profileId Profile id of creator
 * @param onClose Callback function on close button
 * @param creator Creator object if exists
 */
export interface Props {
  onClose: any;
  creator: VLUser;
  onSubscribeClicked: any;
  closeFanPlan: CloseFanPlan;
}

interface State {
  creator?: VLUser;
  error?: String;
}

/**
 * Subscription paywall component
 */
const SubscriptionPaywall: React.FC<Props> = React.memo(
  ({ onClose, creator, onSubscribeClicked, closeFanPlan }) => {
    const { t } = useTranslation();
    const [showAuth, setShowAuth] = useState(false);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async user => {
            const beforeAuthAction = localStorage.getItem('beforeAuthAction');
            if (user && beforeAuthAction) {
                localStorage.removeItem('beforeAuthAction');
                onSubscribeClicked();
            }
        });
    }, []);

    /**
     * Close button click handler
     */
    const onCloseClick = () => {
      onClose();
    };

    /**
     * On close auth click
     */
    const onCloseAuth = () => {
      setShowAuth(false);
    };

    /**
     * Top navbar
     */
    const NavBar = () => {
      return (
        <div
          className={`${styles.navbar} w-100 d-flex flex-row align-items-center justify-content-start`}
        >
          <img
            onClick={onCloseClick}
            className={`clickable ${styles.closeIcon}`}
            src={iconClose}
          />
        </div>
      );
    };

    const onActionButtonClicked = () => {
      if (userManager.isLoggedIn()) {
        onSubscribeClicked();
      } else {
        //show auth
        setShowAuth(true);
        localStorage.setItem('beforeAuthAction', 'subscribe');
      }
    };

    const onAuthSuccess = () => {
      onActionButtonClicked();
    };
    /**
     * Creators information
     */
    const CreatorHeader = () => {
      const title = closeFanPlan.customName ?? t('subscribed_to');
      return (
        <div className={`${styles.CreatorHeader} w-100 d-flex flex-row`}>
          <img
            className={`${styles.avatar}`}
            src={creator.profilePictureURL}
          ></img>
          <div
            className={`d-flex flex-column flex-fill mr-3 ml-3 justify-content-center`}
          >
            <div className={`${styles.title}`}>{`${title}`}</div>
            <div className={`${styles.detail}`}>{creator.fullName()}</div>
          </div>
        </div>
      );
    };

    /**
     * Welcome message and info
     */
    const InfoSection = () => {
      return (
        <div className={`${styles.InfoSection} d-flex flex-row`}>
          <div className="d-flex flex-column">
            <div className={`${styles.title} d-none`}>Welcome!</div>
            <div className={`${styles.detail}`}>
              {closeFanPlan.customWelcomeMessage}
            </div>
          </div>
        </div>
      );
    };

    /**
     * Benefits list
     */
    const BenefitsSection = () => {
      const Benefit = (props: any) => {
        return (
          <div
            className={`${styles.Benefit} d-flex flex-row align-items-center`}
          >
            <img src={props.image} />
            <div className="flex-fill mr-3 ml-3">{props.title}</div>
          </div>
        );
      };

      const benefits = closeFanPlan.benefitsList();
      // console.log('benefits:', benefits);
      {
        benefits.forEach((element: any) => {
          // console.log('benefit:', element);
        });
      }
      return (
        <div className={`${styles.BenefitsSection}`}>
          {benefits.map((element: any) => {
            return <Benefit title={element.title} image={element.image} />;
          })}
        </div>
      );
    };

    /**
     * Subscribe button
     */
    const SubscribeButton = () => {
      return (
        <ButtonBase
          className={`${styles.SubscribeButton} w-100`}
          onClick={onActionButtonClicked}
        >
          {t('subscription_flow_subscribe_cta')}
        </ButtonBase>
      );
    };

    /**
     * Terms and conditions button
     */
    const TermsButton = () => {
      return (
        <div
          className={`${styles.TermsButton}`}
          onClick={() => window.open(URL_TERMS_OF_SERVICE)}
        >
          {t('subscribe_termsAndConditions')}
        </div>
      );
    };

    const MainContentContainer = () => {
      return (
        <div className={`${centerFlexColumn()}`}>
          <CreatorHeader />
          <InfoSection />
          <BenefitsSection />
          <div>
            <SubscribeButton />
            <TermsButton />
          </div>
        </div>
      );
    };

    const redirectToLink = `${window.location.href}?goto=payment`;

    return (
      <div className={`w-100 h-100 ${centerFlexColumn()}`}>
        <div className={`${styles.contentContainer}`}>
          <NavBar />
          <MainContentContainer />
        </div>
        <AuthModal
          redirectToLink={redirectToLink}
          show={showAuth}
          onAuthSuccess={onAuthSuccess}
          onClose={() => {
            setShowAuth(false);
          }}
        />
      </div>
    );
  }
);

export default SubscriptionPaywall;
