import { fadeIn, flash, zoomIn } from "react-animations";
import styled, { keyframes } from "styled-components";
const FadeInAnimation = keyframes`${fadeIn}`;
const ScaleInAnimation = keyframes`${zoomIn}`;
export const FadeInDiv = styled.div`
  animation: 1.5s ${FadeInAnimation};
`;

export const FadeInDivFast = styled.div`
  animation: 0.3s ${FadeInAnimation};
`;

export const ScaleInFast = styled.div`
  animation: 0.3s ${ScaleInAnimation};
`;
