import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const VLSnackbar = (props) => {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.onShouldClose();
  };

  const { severity, message, open } = props;

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          <Box
            fontSize="18px"
            fontWeight={600}
            lineHeight={1.1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            {message}
          </Box>
        </Alert>
      </Snackbar>
    </div>
  );
};
export default VLSnackbar;
