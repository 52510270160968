import React from "react";
import styles from "../AuthDialog.module.scss";

import { withTranslation } from 'react-i18next';
import { Image } from '../../components/image/image.component';

import Copy from '../../assets/icons/icon_copy.svg';

class ShareProfileBody extends React.Component<any, any>
{
  inputRef?: HTMLInputElement;

  constructor(props: any) {
    super(props);

    this.state = {
      copied: false
    }
  }

  /**
   * Copying user's URL
   * @param event
   */
  copyUrl(event: any)
  {
      if(!this.inputRef) return;

      this.inputRef.focus();
      this.inputRef.select();

      document.execCommand("copy");

      this.setState({
        copied: true
      });
  }

  mouseOut(event: any) {
    let { copied } = this.state;
    if(copied) setTimeout(() => this.setState({ copied:  false }), 100);
  }

  render()
  {
    let { className, t, user } = this.props;
    let { copied } = this.state;

    let { profile } = user;
    let userUrl = `https://kre8.tv/${profile && profile.link ? profile.link : ""}`;

    let networks = [{
      name: "Facebook",
      link: `https://www.facebook.com/sharer/sharer.php?u=${userUrl}`,
      color: "#3B5998"
    },{
      name: "Twitter",
      link: `https://twitter.com/intent/tweet?text=${userUrl}`,
      color: "#00ACEE"
    }
    // ,{
    //   name: "Instagram",
    //   link: "",
    //   color: "#A5308E"
    // }, {
    //   name: "TikTok",
    //   link: "",
    //   color: "#000"
    // }, {
    //   name: "YouTube",
    //   link: "",
    //   color: "#C4302B"
    // }
    ];

    return <div className={className}>
        <section>
          <input type={"url"} value={userUrl} readOnly={true} ref={(ref: HTMLInputElement) => this.inputRef = ref} />
          <button onClick={this.copyUrl.bind(this)} data-tooltip={copied ? t("share_profile_step_copied") : t("share_profile_step_copy")} onMouseLeave={this.mouseOut.bind(this)}>
            <Image src={Copy} />
          </button>
        </section>
        <nav>
          {networks.map((network, index) => {
            return <a key={`n.${index}`} href={network.link} target={"_blank"} style={{
              borderColor: network.color
            }}>
              <span style={{
                color: network.color
              }}>{network.name}</span>
            </a>
          })}
        </nav>
    </div>;
  }
}

export default withTranslation()(ShareProfileBody);