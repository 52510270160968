import { Player } from '@lottiefiles/react-lottie-player';
import { Fade } from '@material-ui/core';
import React from 'react';
import PrimaryLoader from './cube-loader-white.json';

/**
 * Loading component wrapper
 */
const VLAnimatedLoader = (props: any) => {
  return (
    <Fade
      in={props.in ?? true}
      timeout={2000}
      style={{ transitionDelay: `500ms` }}
    >
      <Player
        autoplay
        loop
        src={PrimaryLoader}
        // src="https://assets3.lottiefiles.com/packages/lf20_UJNc2t.json"
        style={{ height: '300px', width: '300px' }}
      ></Player>
    </Fade>
  );
};

export default VLAnimatedLoader;
