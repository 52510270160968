import React from "react";
import styles from "../AuthDialog.module.scss";

import { withTranslation } from 'react-i18next';
import { Image } from '../../components/image/image.component';

import Alignment from '../../assets/icons/icon_aligment.svg';
import FontSize from '../../assets/icons/icon_text size.svg';

class CreationDescBody extends React.Component<any, any>
{
  constructor(props: any) {
    super(props);

    this.state = {
      focused: false
    }
  }

  onSaveCreation(content: string)
  {
    this.props.onSaveCreation(content);
  }

  onBlur(event: any)
  {
    console.log('onBlur')
    this.setState({focused: false});
    this.onSaveCreation(event.target.value);
  }

  render()
  {
    let { className, t } = this.props;
    let { focused } = this.state;

    return <div className={className}>
      <section>
        <textarea placeholder={t("details_step_placeholder")} onFocus={() => this.setState({focused: true})} onBlur={this.onBlur.bind(this)}/>
        <div className={focused ? styles.sectionFocus : ""}>
          <button><Image src={FontSize} /></button>
          <button><Image src={Alignment} /></button>
        </div>
      </section>
    </div>;
  }
}

export default withTranslation()(CreationDescBody);