import loki from 'lokijs';
import * as utils from '../utils/rhutils.js';
import * as constants from '../constants/rhconstants.js';

var _ = require('lodash');

var db = new loki('create.db', {
  autoload: true,
  autoloadCallback: databaseInitialize,
  env: 'BROWSER',
  autosave: true,
  autosaveInterval: 4000,
});

function databaseInitialize() {
  var users = db.getCollection(constants.LOCAL_DB_COLLECTION_USERS);
  if (users === null) {
    db.addCollection(constants.LOCAL_DB_COLLECTION_USERS);
  }

  var createdSessions = db.getCollection(
    constants.LOCAL_DB_COLLECTION_SESSIONS
  );
  if (createdSessions === null) {
    db.addCollection(constants.LOCAL_DB_COLLECTION_SESSIONS, {
      unique: 'id',
    });
  }

  var purchasedSessionsAccess = db.getCollection(
    constants.LOCAL_DB_PURCHASED_SESSIONS_ACCESS
  );
  if (purchasedSessionsAccess === null) {
    db.addCollection(constants.LOCAL_DB_PURCHASED_SESSIONS_ACCESS, {
      unique: 'id',
    });
  }

  var purchasedSubscriptions = db.getCollection(
    constants.LOCAL_DB_COLLECTION_SUBSCRIPTIONS
  );
  if (purchasedSubscriptions === null) {
    db.addCollection(constants.LOCAL_DB_COLLECTION_SUBSCRIPTIONS, {
      unique: 'id',
    });
  }

  var bookingRequests = db.getCollection(
    constants.LOCAL_DB_COLLECTION_BOOKING_REQUESTS
  );
  if (bookingRequests === null) {
    db.addCollection(constants.LOCAL_DB_COLLECTION_BOOKING_REQUESTS, {
      unique: 'id',
    });
  }

  var creationPurchases = db.getCollection(
    constants.LOCAL_DB_COLLECTION_CREATION_PURCHASES
  );
  if (creationPurchases === null) {
    db.addCollection(constants.LOCAL_DB_COLLECTION_CREATION_PURCHASES, {
      unique: 'id',
    });
  }
  // console.log("db autload callback completed", db);
}

class DBManager {
  constructor() {
    this.db = db;
  }

  /**
   * Clears a collection of all objects
   * @param {*} collectionName The name of the collection
   */
  clearCollection(collectionName) {
    try {
      let collection = this.db.getCollection(collectionName);
      collection.clear();
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Inserts a collection of objects to a DB collection
   * @param {*} collectionName The name of the collection
   * @param {*} objects The objects to add to the collection
   */
  addObjects(collectionName, objects) {
    try {
      let collection = this.db.getCollection(collectionName);
      collection.insert(objects);
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Inserts an object to a DB collection
   * @param {*} collectionName The name of the collection
   * @param {*} object The objects to add to the collection
   */
  addObject(collectionName, object) {
    try {
      let collection = this.db.getCollection(collectionName);
      collection.insert(object);
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Returns a collection from the local db
   * @param {*} collectionName The name of the collection
   */
  getCollection(collectionName) {
    let collection = this.db.getCollection(collectionName);
    return collection;
  }
}

var dBManager = new DBManager();
export default dBManager;
