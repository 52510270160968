import memoize from 'lodash/memoize'


class VLTransactionReview {
    
    //general info
    id = ""
    transactionId = ""
    requestId = "";
    personaId = "";
    reviewerId = "";
    content = null;
    rating = -1.0;
    date = Date();

    //tip
    tip = -1.0;
    tipCurrency = null;

    //review reason
    reviewReason = null;
    reviewReasonMessage = null;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  static fromJSON = memoize((json)=> {
    var review = new VLTransactionReview();
    try {
        review.id = json.id;
        review.transactionId = json.transaction_id;
        review.requestId = json.request_id;
        review.personaId = json.persona_id;
        review.reviewerId = json.reviewer_id;
        review.content = json.content;
        review.rating = json.rating;
        review.date = Date(json.timestamp);

        review.tip = json.tip;
        review.tipCurrency = json.tip_currency;

        review.reviewReason = json.review_reason
        review.reviewReasonMessage = json.review_reason_message;
        return review;
    } catch (error) {
      return null;
    }
  })
}

export default VLTransactionReview;
