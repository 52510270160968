import { call, put, takeLatest } from 'redux-saga/effects';
import { signOut } from '../../routines';

function* handleTriggerAction(action: any) {
  try {
    yield put(signOut.request());
    // console.log('result1', result);
    yield put(signOut.success());
  } catch (e) {
    console.log('e', e);
    yield put(signOut.failure());
  }
}

export default function* signOutSaga() {
  yield takeLatest(signOut.TRIGGER, handleTriggerAction);
}
