import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import styles from './RegionPicker.module.scss';
import { fetchHome } from '../../store/routines';
import { useTranslation } from 'react-i18next';
import * as utils from '../../utils/rhutils';
import { connect, useSelector } from 'react-redux';
import VLRegion from '../../model/vlRegion';
import clock from './clock.svg';
import clockBlack from './clockBlack.svg';
import { isMobile } from 'react-device-detect';

import { updateRegion } from '../../store/reducers/user/user.reducer.js';
import { store } from '../../store';

interface RegionPickerProps {
  currentRegion?: VLRegion;
  regions?: VLRegion[];
  onRegionSelected: Function;
  theme: 'light' | 'dark';
  fromSideBar: boolean;
  hideText: boolean;
  menuAlignToBottom: boolean;
}
const RegionPicker = (props: RegionPickerProps) => {
  const {
    currentRegion,
    regions,
    onRegionSelected,
    theme,
    fromSideBar,
    hideText,
    menuAlignToBottom,
  } = props;

  const { t, i18n } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  let url = window.location.pathname ? window.location.pathname.toString() : '';
  if (
    url !== '' &&
    url !== '/' &&
    url !== '/home' &&
    !url.includes('category')
  ) {
    return <span></span>;
  }

  const onChangeSearch = (e: any) => {
    if (e && e.target) {
      setInputValue(e.target.value);
    }
  };

  const numberOfSearches = () => {
    //how many results there is on the scren
    let number = 0;

    if (regions) {
      regions.map((item) => {
        if (
          inputValue &&
          item &&
          item.title &&
          !item.title.toLowerCase().includes(inputValue.toLowerCase())
        ) {
        } else {
          number++;
        }
      });
    }

    return number;
  };
  const selectRegion = (item: VLRegion) => {
    // utils.log.debug('selectRegion picker: ', item);
    if (item.isWaitlist === true) {
      // utils.log.debug('ignroing waitlisted selected region: ', item);
      return;
    }

    const regionId = item.id;
    store.dispatch(updateRegion(regionId));
    onRegionSelected(item);
  };

  // utils.log.debug('region picker regions: ', regions);

  //don't continue if regions are empty
  if (regions && regions.length === 0) {
    utils.log.debug('returning empty');
    return <span></span>;
  }

  const isHebrew = i18n.language === 'he';
  const align = isHebrew
    ? isMobile
      ? 'left'
      : 'right'
    : isMobile
    ? 'right'
    : 'left';

  const mainRegion = currentRegion
    ? currentRegion
    : regions && regions.length > 0
    ? regions[0]
    : null;

  if (!mainRegion) {
    return null;
  }

  return (
    <span
      className={`${isHebrew ? styles.heb : ''} ${
        theme === 'light' ? styles.white : ''
      } ${fromSideBar ? styles.fromSideBar : ''}`}
    >
      <Dropdown>
        <Dropdown.Toggle className={styles.countries} id={mainRegion.title}>
          <div className={styles.countries_flag_main}>
            {mainRegion.roundedFlag()}
          </div>
          <span className={styles.countries_text}>
            {mainRegion.title && !hideText ? mainRegion.title : ''}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu
          className={`${styles.countries_menu} ${
            menuAlignToBottom ? styles.menuAlignToBottom : ''
          }`}
        >
          {regions &&
            regions.map((item) => {
              if (
                item.id === mainRegion?.id ||
                (inputValue &&
                  item &&
                  item.title &&
                  !item.title.toLowerCase().includes(inputValue.toLowerCase()))
              ) {
                return <span></span>;
              }
              return (
                <Dropdown.Item
                  key={item.id}
                  className={`d-flex ${styles.countries_menu_item} ${
                    item.isWaitlist ? 'opacity-2' : ''
                  }`}
                  active={mainRegion?.id === item.id}
                  onClick={() => {
                    selectRegion(item);
                  }}
                >
                  <div className={`${styles.countries_flag}`}>
                    {item.roundedFlag()}
                  </div>
                  {item.title}
                  {item.isWaitlist ? (
                    <img
                      src={theme === 'light' ? clockBlack : clock}
                      className={styles.clock}
                    />
                  ) : null}
                </Dropdown.Item>
              );
            })}
          {!numberOfSearches() ? (
            <div className={styles.noResults}>{t('region_not_found')}</div>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    </span>
  );
};

// @ts-ignore
const mapDispatchToProps = (dispatch) => ({
  fetchHome: (x: any) => dispatch(fetchHome(x)),
});

export default connect(mapDispatchToProps)(RegionPicker);
