import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import styles from './Booking.module.scss';
import { useTranslation } from 'react-i18next';
import BookingProfile from './BookingProfile/BookingProfile';
import BookingStepsProgressBar from './BookingSteps/BookingSteps';
import BookingWhoIsFor from './BookingWhoIsFor/BookingWhoIsFor';
import BookingNextAndBack from './BookingNextAndBack/BookingNextAndBack';
import BookingStepsBoxes from './BookingStepsBoxes/BookingStepsBoxes';
import BookingOccasion from './BookingOccasion/BookingOccasion';
import BookingPrice from './BookingPrice/BookingPrice';
import BookingDescription from './BookingDescription/BookingDescription';
import BookingUpload from './BookingUpload/BookingUpload';
import BookingLoading from './BookingLoading/BookingLoading';
import i_icon from '../Images/i.svg';
import infoMobile from '../Images/infoMobile.svg';
import paymentManager from '../../../managers/paymentManager';
import UserManager from '../../../managers/userManager';
import bookingRequestManager from '../../../managers/bookingRequestManager.js';
import EarnCredit from '../EarnCredit/EarnCredit';
import uploadManager from '../../../managers/uploadManager';
import HowItWorks from '../HowItWorks/HowItWorks';
import icon_close from '../Images/icon_close1.svg';
import * as utils from '../../../utils/rhutils';
import * as constants from '../../../constants/rhconstants';
import AuthModal from '../../../signup/AuthModal/AuthModal';
import Modal from '../Modal';
import VLUser from '../../../model/vlUser';
import IconClose from '../../../components/Modal/GenericPaywallModal/images/icon_close_purple.svg';
import {
  onSelectCreator,
  reset,
  onRegularBookingRequestCompleted,
} from '../../../store/reducers/campaigns';
import {
  AnalyticsEvent,
  analytics,
  Consumables,
} from '../../../utils/analytics';
import { centerFlexColumn } from '../../../utils/bootstrapUtils';
import { vlImageCoupon } from '../../../utils/vlImages';
import RedeemCode from '../Promotions/RedeemCode';
import VLPromotion from '../../../model/vlPromotion';
import VLFlowManager, {
  BookingLanding,
  BookingStarted,
  BookingSelectedOcassion,
  BookingPriceConfirmed,
  BookingDescriptionConfirmed,
  BookingAttemptingSubmit,
  BookingShowingPaywall,
  BookingCompleted,
  BookingFailure,
  BookingSubmitting,
} from '../../../managers/v2/VLFlowManager';
import { max } from 'lodash';
import {
  Backdrop,
  Box,
  Button,
  Fade,
  IconButton,
  Slide,
  Tooltip,
} from '@material-ui/core';
import VLBigButton from '../../../Reusables/Buttons/VLBigButton';
import userManager from '../../../managers/userManager';
import VLButton from '../../../Reusables/Buttons/VLButton';
import CampaignBooking from '../../pages/Campaigns/CampaignBooking';
import { routes } from '../../../constants';
import PageLoader from '../../pageLoader';

let occasionNumber = -1;
let bookingCurrency = 'ILS';

/* Enums */
export const ORDER_STEP_CHOOSE_FLOW = 0; //always start here
export const ORDER_STEP_SELECT_OCASSION = 1;
export const ORDER_STEP_SET_DESCRIPTION = 2;
export const ORDER_STEP_SELECT_PRICE = 3;
export const ORDER_STEP_CONFIRM_AND_SEND = 4;

export const ORDER_FLOW_INITIAL = 'reset';
export const ORDER_FLOW_FOR_ME = 'forMe';
export const ORDER_FLOW_FOR_SOMEONE_ELSE = 'forFriend';
export const ORDER_FLOW_FOR_BUSINESS = 'forBusiness';

export const allSupportedFlows = [
  ORDER_FLOW_INITIAL,
  ORDER_FLOW_FOR_ME,
  ORDER_FLOW_FOR_SOMEONE_ELSE,
  ORDER_FLOW_FOR_BUSINESS,
];

/**
 * Top navbar
 */
const NavBar = (props: any) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { step, setStep, showPromotion } = props;
  const onCloseClick = () => {
    // console.log('onCloseClick', history);
    if (step > 0) {
      setStep(ORDER_STEP_CHOOSE_FLOW);
    } else if (props.onClose) {
      props.onClose();
    } else if (history.length === 0) {
      //go to home
      history.push('/');
    } else {
      //go to profile
      history.push(`/${props.creator.profileId}`);
    }
  };

  const onEnterPromoCodeClicked = () => {
    props.setShowRedeemCode(true);
  };

  return (
    <div
      className={`${styles.navbar} w-100 d-flex flex-row align-items-center justify-content-between`}
    >
      <img
        alt="close"
        onClick={onCloseClick}
        className={`clickable ${styles.closeIcon}`}
        src={IconClose}
      />
      <Box display="flex" flexDirection="row" alignItems="center">
        {showPromotion ? (
          <Box color="primary.main" mr={1} ml={1} fontWeight="bold">
            {t('promotion_applied')} ✓
          </Box>
        ) : null}
        <img
          alt="coupon"
          onClick={() => onEnterPromoCodeClicked()}
          className={`clickable ${styles.closeIcon}`}
          src={vlImageCoupon}
        />
      </Box>
    </div>
  );
};

/**
 * Container for the auth flow
 */
const AuthModalContainer = (props: any) => {
  return (
    <AuthModal
      show={props.showAuth}
      onClose={props.onClose}
      onAuthSuccess={props.onAuthSuccess}
    />
  );
};

/**
 * How it works info container
 */
const HowItWorksContentContainerView = (props: any) => {
  const { showHowItWorks, setShowHowItWorks } = props;

  return (
    <Fade
      style={{ width: '100%', height: showHowItWorks ? '100%' : '0%' }}
      in={true}
      timeout={500}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <Slide
          style={{ width: '100%', height: '100%' }}
          direction="up"
          in={showHowItWorks}
          mountOnEnter
          unmountOnExit
        >
          <div className="w-100">
            <HowItWorks
              close={() => {
                setShowHowItWorks(false);
              }}
            />
          </div>
        </Slide>
      </div>
    </Fade>
  );
  // );
};

/**
 * "how it works" mobile container
 */
const HowItWorksMobile = (props: any) => {
  const { showHowItWorks, setShowHowItWorks, t } = props;
  return !showHowItWorks ? (
    <div
      className={styles.termsMobile}
      onClick={() => {
        setShowHowItWorks(true);
      }}
    >
      {t('booking_how_it_works')}
      <img className={`${styles.info1}`} src={infoMobile} />
    </div>
  ) : null;
};

const StepsChildView = (props: any) => {
  return (
    <BookingStepsBoxes
      showBusinessNameInputError={props.showBusinessNameInputError}
      uploadImage={props.uploadImage}
      changedText={props.changedText}
      User={props.creator}
      price={props.price}
      flow={props.flow}
      showMyName={props.showMyName}
      toggleShowMyName={props.toggleShowMyName}
      forWhoImageLoading={props.forWhoImageLoading}
      fromWhoImageLoading={props.fromWhoImageLoading}
      showFromWhoError={props.showFromWhoError}
      showForWhoError={props.showForWhoError}
      selectedOccasionImg={props.selectedOccasionImg}
      description={props.description}
      selectedOccasion={props.selectedOccasion}
      step={props.step}
      {...props}
      fromWhoImage={props.fromWhoImage}
      whoIsFromImage={props.forWhoImage}
    />
  );
};
/**
 * Vertical steps boxes that are shown to aid flow and edit certain steps
 * @param {*} props
 */
const VerticalStepsContainer = (props: any) => {
  const fromWhoImage =
    props.fromWhoImage && props.fromWhoImage.base64
      ? props.fromWhoImage.base64
      : '';
  const forWhoImage =
    props.whoIsFromImage && props.whoIsFromImage.base64
      ? props.whoIsFromImage.base64
      : '';

  // console.log('occasionNumber:', occasionNumber);
  return props.step == ORDER_STEP_CHOOSE_FLOW || props.showHowItWorks ? null : (
    <StepsChildView
      showBusinessNameInputError={props.showBusinessNameInputError}
      uploadImage={props.uploadImage}
      changedText={props.changedText}
      User={props.creator}
      price={props.price}
      flow={props.flow}
      showMyName={props.showMyName}
      toggleShowMyName={props.toggleShowMyName}
      forWhoImageLoading={props.forWhoImageLoading}
      fromWhoImageLoading={props.fromWhoImageLoading}
      showFromWhoError={props.showFromWhoError}
      showForWhoError={props.showForWhoError}
      selectedOccasionImg={props.selectedOccasionImg}
      description={props.description}
      selectedOccasion={props.selectedOccasion}
      step={props.step}
      fromWhoImage={fromWhoImage}
      whoIsFromImage={forWhoImage}
      {...props}
    />
  );
};

/**
 * Booking select flow (for me, someone else, business, etc)
 */
const CarouselStepSelectFlow = (props: any) => {
  return <BookingWhoIsFor User={props.creator} setFlow={props.startNewFlow} />;
};

/**
 * Booking ocassion step
 */
const CarouselStepSelectOcassion = (props: any) => {
  return (
    <BookingOccasion
      User={props.creator}
      flow={props.flow}
      setSelectedOccasion={props.setSelectedOccasion}
      selectedOccasion={props.selectedOccasion}
    />
  );
};

/**
 * Booking price step
 */
const CarouselStepSetPrice = (props: any) => {
  return (
    <BookingPrice
      startEmpty={props.flow === ORDER_FLOW_FOR_BUSINESS}
      showPriceInputError={props.showPriceInputError}
      next={props.attemptGoToNextStep}
      User={props.creator}
      flow={props.flow}
      price={props.price}
      setPrice={props.setPrice}
    />
  );
};

/**
 * Booking description step
 */
const CarouselStepSetDescription = (props: any) => {
  return (
    <BookingDescription
      showInputError={props.showDescriptionError}
      next={props.attemptGoToNextStep}
      User={props.creator}
      description={props.description}
      setDescription={props.setDescription}
    />
  );
};

/**
 * Content uploading step
 */
const CarouselStepUpload = (props: any) => {
  return (
    <BookingUpload
      toggleShowCreatorName={() => {
        props.setShowCreatorName(!props.showCreatorName);
      }}
      showCreatorName={props.showCreatorName}
      imageLoading={props.mainImageLoading}
      mainImageLoading={props.mainImageLoading}
      mainImage={
        props.mainImage && props.mainImage.base64 ? props.mainImage.base64 : ''
      }
      uploadImage={props.uploadImage}
      User={props.creator}
      price={props.currencySymbol + props.price}
    />
  );
};

/**
 * Container of the booking steps carousel
 */
const BookingStepCarouselContainer = (masterProps: any) => {
  // console.log('BookingStepCarouselContainer', masterProps);
  function steps() {
    return [
      (props: any) => (
        <CarouselStepSelectFlow
          creator={masterProps.creator}
          startNewFlow={masterProps.startNewFlow}
          {...props}
        />
      ),
      (props: any) => (
        <CarouselStepSelectOcassion
          setSelectedOccasion={masterProps.setSelectedOccasion}
          selectedOccasion={masterProps.selectedOccasion}
          {...props}
        />
      ),
      (props: any) => (
        <CarouselStepSetDescription {...props} {...masterProps} />
      ),
      (props: any) => <CarouselStepSetPrice {...props} {...masterProps} />,
      (props: any) => <CarouselStepUpload {...props} {...masterProps} />,
    ];
  }

  // utils.log.debug('props.step:', props.step);
  // const comp = steps()[props.step];
  // utils.log.debug('masterProps:', masterProps.attemptGoToNextStep);

  return (
    <div className="w-100">
      {steps()[Math.min(masterProps.step, ORDER_STEP_CONFIRM_AND_SEND)]({
        showInputError: masterProps.showInputError,
        User: masterProps.creator,
        flow: masterProps.flow,
        ocassion: masterProps.ocassion,
        price: masterProps.price,
        setPrice: masterProps.setPrice,
        currency: masterProps.currency,
        next: masterProps.attemptGoToNextStep,
        description: masterProps.description,
        setDescription: masterProps.setDescription,
      })}
    </div>
  );
};

/**
 * Container for the "add card" flow/modal
 */
const Paywall = (props: any) => {
  const { t, creator, price, onCreditCardAdded, onClose, currency } = props;

  // console.log('props...', props);
  if (!props.showAddCardScreen) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      kind={
        props.currentFlow === ORDER_FLOW_FOR_BUSINESS
          ? 'order_paywall_business'
          : 'order_paywall_personal'
      }
      wrapperCustomStyle={{
        padding: '0px',
        width: '100%',
        maxWidth: '100%',
      }}
      User={creator}
      title={t('order_from')}
      buttonText={t('confirm_purchase')}
      paymentData={{ price: price, currency: currency }}
      onAddPaymentMethodSuccess={onCreditCardAdded}
    />
  );
};

/**
 * Horizontal line steps
 */
const BookingHorizontalLineSteps = (props: any) => {
  const { updateToStep, flow, creator, step } = props;

  return (
    <BookingStepsProgressBar
      goToPage={(page: any) => {
        if ((flow && page !== null) || !flow) {
          utils.log.debug('goto page ', page);
          updateToStep(page, true);
        }
      }}
      hideFirstStep={false}
      User={creator}
      step={step}
    />
  );
};

/**
 * Screen title element
 */
const Title = (props: any) => {
  const { step, creator } = props;
  const { t, i18n } = useTranslation();

  /**
   * Get the title to display inside the carousel view
   * @returns title of carousel view
   */
  const getTitle = () => {
    if (step === ORDER_STEP_CHOOSE_FLOW) {
      return t('whoIsFor');
    } else if (step === ORDER_STEP_SELECT_OCASSION) {
      return t('selectOccasion');
    } else if (step === ORDER_STEP_SET_DESCRIPTION) {
      return t('order_screen_description');
    } else if (step === ORDER_STEP_SELECT_PRICE) {
      return t('order_flow_title_select_price');
    } else if (step === ORDER_STEP_CONFIRM_AND_SEND) {
      return t('Confirmation');
    }
  };

  return (
    <div className={styles.title}>
      {getTitle()}
      {/* //@todo: add tooltip here */}
      <CampaignUpsellViewLargeScreen
        reset={reset}
        show={step === 'initial'}
        creator={creator}
        onSelectCreator={onSelectCreator}
      />
    </div>
  );
};

const CampaignUpsellView = (props: any) => {
  const { show, creator, onSelectCreator, reset } = props;
  const history = useHistory();

  const { t, i18n } = useTranslation();

  //should only show on first step
  if (!show) return null;

  const onClick = () => {
    reset();
    onSelectCreator(creator.toICreator());
    const properties = [{ profile_id: creator.profileId }];
    analytics.event(
      new AnalyticsEvent(
        'web - flow - campaign - moved user from regular booking',
        properties
      )
    );
    history.push('/campaign');
  };
  return (
    <Box
      position="absolute"
      top={65}
      left={0}
      width="100%"
      display={{ xs: 'flex', sm: 'none' }}
      flexDirection="column"
      alignItems="center"
    >
      <VLButton
        onClick={() => onClick()}
        size="small"
        style={{ height: '24px', fontSize: '12px' }}
      >
        {t('order_from_campaign_banner')}
      </VLButton>
    </Box>
  );
};

const CampaignUpsellViewLargeScreen = (props: any) => {
  const { creator, onSelectCreator, reset } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const onClick = () => {
    reset();
    const properties = [{ profile_id: creator.profileId }];
    analytics.event(
      new AnalyticsEvent(
        'web - flow - campaign - moved user from regular booking',
        properties
      )
    );
    onSelectCreator(creator.toICreator());
    history.push('/campaign');
  };
  return (
    <Box
      width="100%"
      display={{ xs: 'none', sm: 'flex' }}
      flexDirection="column"
      alignItems="center"
      mt={2}
    >
      <VLButton onClick={() => onClick()} style={{}}>
        {t('order_from_campaign_banner')}
      </VLButton>
    </Box>
  );
};

interface RightAreaProps {
  updateToStep: any;
  setShowHowItWorks: any;
  showHowItWorks: boolean;
  step: any;
  creator: any;
  attemptGoToNextStep: any;
  price: any;
  currency: any;
  currencySymbol: any;
  backStep: any;
  checkIfCurrentStepIsValid: any;
  paymentError: any;
  currentUserAlreadyHasPaymentMethods: any;
  uploadImage: any;
  flow: any;
  showDescriptionError: any;
  showPriceInputError: any;
  startNewFlow: any;
  showCreatorName: any;
  setShowCreatorName: any;
  selectedOccasion: any;
  setOccasion: any;
  setBookingDetails: any;
  bookingDetails: any;
  description: any;
  mainImage: any;
  toggleShowMyName: any;
  showMyName: any;
  setDescription: any;
  showInputError: any;
  next: any;
  whoIsFromImage: any;
  fromWhoImage: any;
  whoIsFor: any;
  fromWho: any;
  activeField: any;
  setActiveField: any;
  showFromWhoError: any;
  showForWhoError: any;
  showConfirmButtonLoader: boolean;
  onSelectCreator?: any;
  reset?: any;
  onRegularBookingRequestCompleted?: any;
  lastSubmittedBookingRequest?: any;
}
const RightArea = ({
  updateToStep,
  setShowHowItWorks,
  showHowItWorks,
  step,
  creator,
  attemptGoToNextStep,
  price,
  currency,
  currencySymbol,
  backStep,
  checkIfCurrentStepIsValid,
  paymentError,
  currentUserAlreadyHasPaymentMethods,
  uploadImage,
  flow,
  showDescriptionError,
  showPriceInputError,
  startNewFlow,
  showCreatorName,
  setShowCreatorName,
  selectedOccasion,
  setOccasion,
  setBookingDetails,
  bookingDetails,
  description,
  mainImage,
  toggleShowMyName,
  showMyName,
  setDescription,
  showInputError,
  next,
  whoIsFromImage,
  fromWhoImage,
  whoIsFor,
  fromWho,
  activeField,
  setActiveField,
  showFromWhoError,
  showForWhoError,
  showConfirmButtonLoader,
  onSelectCreator,
  reset,
  onRegularBookingRequestCompleted,
  lastSubmittedBookingRequest,
}: RightAreaProps) => {
  const { t, i18n } = useTranslation();

  return !showHowItWorks || window.innerWidth > 1024 ? (
    <div className={styles.rightArea}>
      <div className={`${centerFlexColumn()}`}>
        <div
          style={{ maxWidth: '330px' }}
          className={`${styles.lineStepsContainer} w-100`}
        >
          <BookingHorizontalLineSteps
            updateToStep={updateToStep}
            flow={flow}
            creator={creator}
            step={step}
          />
        </div>
      </div>
      <div
        className={`${
          styles.rightAreaFlex
        } ${centerFlexColumn()} justify-content-between`}
      >
        <Title step={step} creator={creator} />
        <CampaignUpsellView
          reset={reset}
          show={step === 0}
          creator={creator}
          onSelectCreator={onSelectCreator}
        />
        <BookingStepCarouselContainer
          uploadImage={uploadImage}
          creator={creator}
          ocassion={occasionNumber}
          price={price}
          currency={currency}
          step={step}
          attemptGoToNextStep={attemptGoToNextStep}
          flow={flow}
          showDescriptionError={showDescriptionError}
          showPriceInputError={showPriceInputError}
          startNewFlow={startNewFlow}
          showCreatorName={showCreatorName}
          setShowCreatorName={setShowCreatorName}
          selectedOccasion={selectedOccasion?.selectedOccasion}
          setSelectedOccasion={(newOccasion: any, newImgUrl: any, num: any) => {
            setOccasion(newOccasion, newImgUrl, num);
          }}
          setPrice={(price: number) => {
            setBookingDetails({ ...bookingDetails, price });
          }}
          currencySymbol={currencySymbol}
          description={description}
          mainImage={mainImage}
          setDescription={(description: string) =>
            setBookingDetails({ ...bookingDetails, description })
          }
        />
        <BookingNextAndBackButton
          showLoading={showConfirmButtonLoader}
          show={step > ORDER_STEP_CHOOSE_FLOW}
          step={step}
          creator={creator}
          isActive={true}
          attemptGoToNextStep={attemptGoToNextStep}
          price={price}
          currency={currencySymbol}
          backStep={backStep}
          checkIfCurrentStepIsValid={checkIfCurrentStepIsValid}
          paymentError={paymentError}
          currentUserAlreadyHasPaymentMethods={
            currentUserAlreadyHasPaymentMethods
          }
        />
      </div>
    </div>
  ) : null;
};

/**
 * Dekstop only - component for displaying the back and next buttons of the ordering flow
 */
const BookingNextAndBackButton = (props: any) => {
  const {
    step,
    creator,
    attemptGoToNextStep,
    price,
    currency,
    backStep,
    checkIfCurrentStepIsValid,
    paymentError,
    currentUserAlreadyHasPaymentMethods,
    show,
    showLoading,
  } = props;

  return window.innerWidth < 1024 && step < 2 ? null : (
    <BookingNextAndBack
      show={show}
      User={creator}
      next={attemptGoToNextStep}
      lastStep={step === ORDER_STEP_SET_DESCRIPTION} //price step
      price={currency + price}
      back={backStep}
      currentUserAlreadyHasPaymentMethods={currentUserAlreadyHasPaymentMethods}
      step={step}
      showLoading={showLoading}
      error={step === ORDER_STEP_SET_DESCRIPTION ? paymentError : ''}
      isActive={true}
    />
  );
};

interface BookingProps {
  onClose: any; // on close callback method
  isConnected?: boolean;
  forceAuth: boolean;
  preFlow: typeof allSupportedFlows[number];
  fullScreen: boolean;
  removeBackgroundFromEarn: boolean;
  creator?: VLUser;
  onSelectCreator?: any;
  onRegularBookingRequestCompleted?: any;
  lastSubmittedBookingRequest?: any;
}

const flowManagerRequesTypeFromFlow = (flow?: string) => {
  switch (flow) {
    case ORDER_FLOW_FOR_ME:
      return 'for self';
    case ORDER_FLOW_FOR_SOMEONE_ELSE:
      return 'for someone else';
    case ORDER_FLOW_FOR_BUSINESS:
      return 'business';
    default:
      return undefined;
  }
};
/**
 * IMPORTANT -
 * Treat this as a black box component.
 * If using from an external loation, look @ BookingProps to see API
 */
const Booking = React.memo((props: BookingProps) => {
  const history = useHistory();
  let isConnected = props.isConnected;
  const { t, i18n } = useTranslation();
  const leftScrollRef = useRef(null);

  /* State managemnt of booking flow */
  interface BookingOrderFlowState {
    whoIsFor: string; //personalized  - someone else
    fromWho: string; //personalized  - someone else
    whoIsForBusiness: string; //business
    whoIsForBusinessImage: any; //business
    price: number | undefined;
    description: string;
    fromWhoImage: any;
    whoIsFromImage: any;
    mainImage: any;
  }
  const [bookingDetails, setBookingDetails] = useState<BookingOrderFlowState>({
    whoIsFor: '',
    fromWho: '',
    whoIsForBusiness: '',
    whoIsForBusinessImage: null,
    price: undefined,
    description: '',
    fromWhoImage: '',
    whoIsFromImage: '',
    mainImage: null,
  });
  const {
    price,
    description,
    whoIsFor,
    fromWho,
    whoIsForBusiness,
    fromWhoImage,
    whoIsFromImage,
    mainImage,
  } = bookingDetails;

  /* State managemnt of booking flow */
  interface SelectedOccasionState {
    selectedOccasionImg?: string;
    selectedOccasion?: string;
  }

  const [selectedOccasion, setSelectedOccasion] = useState<
    SelectedOccasionState | undefined
  >(undefined);
  const [promotion, setPromotion] = useState<VLPromotion | undefined>(
    undefined
  );

  const [step, setStep] = useState(
    props.preFlow && props.preFlow !== ORDER_FLOW_INITIAL
      ? ORDER_STEP_CHOOSE_FLOW
      : ORDER_STEP_CHOOSE_FLOW
  );
  const [flow, setFlow] = useState(props.preFlow ? props.preFlow : null); //what flow? reset/forMe/forFriend/business

  const [showHowItWorks, setShowHowItWorks] = useState(false); //open ad close how it work
  const [loading, setLoading] = useState(false); //if the page is in loading mode (after adding credit card)
  const [orderCompleted, setOrderCompleted] = useState(false); //if the order completed succesfully
  const [paymentError, setPaymentError] = useState('');
  const [showInputError, setShowInputError] = useState(false); //error in pricing modal and description modal
  const [showPriceInputError, setShowPriceInputError] = useState(false); //error in pricing modal
  const [showFromWhoError, setShowFromWhoError] = useState(false); //show from who is error
  const [showForWhoError, setShowForWhoError] = useState(false); //show for who is error
  const [showMyName, setShowMyName] = useState(false); //show my name checkbox
  const [showCreatorName, setShowCreatorName] = useState(false); //checkbox of show the video on the creator profile page
  const [fromWhoImageLoading, setFromWhoImageLoading] = useState(false);
  const [forWhoImageLoading, setForWhoImageLoading] = useState(false);
  const [mainImageLoading, setMainImageLoading] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [profilePicAfterLogin, setProfilePicAfterLogin] = useState('');
  const [showAddCardScreen, setShowAddCardScreen] = useState(false);
  const [activeField, setActiveField] = useState<string | undefined>(undefined);
  const [showRedeemCode, setShowRedeemCode] = useState(false);
  const [showBookingLoading, setShowBookingLoading] = useState(false);
  const [showDescriptionError, setShowDescriptionError] = useState(false);
  const [showBusinessNameInputError, setShowBusinessNameInputError] = useState(
    false
  );
  const [showConfirmButtonLoader, setShowConfirmButtonLoader] = useState(false);
  //refs for analytics
  const flowRef = useRef(flow);
  const stepRef = useRef(step);
  // const ocassionRef = useRef(ocassion);

  const isMobile = useMediaQuery('(max-width:767px)'); //mobile width

  const TAG = 'Booking: ';
  // console.log('BOOKING - ', props);

  //Setup user info
  // utils.log.warn('BOOKING props.creator- ', props.creator);
  const creator = new VLUser(props.creator);
  bookingCurrency = creator.currency;

  useEffect(() => {
    //nop
  }, []);

  if (
    props.forceAuth === true &&
    props.isConnected === false &&
    showAuth === false
  ) {
    utils.log.debug('forcing show auth');
    setTimeout(() => {
      setShowAuth(true);
    }, 2000);
  } else {
    // utils.log.debug('no forceAuth: ', props);
  }

  /**
   * Logging helper function based on key triggers in screen
   * @param {*} trigger Trigger to log
   * @param {*} error Additional error detail (optional)
   */
  const logEventForTrigger = (
    trigger: string,
    error = null,
    ocassionNumber: any = null
  ) => {
    //steps for logging (just use for reference now)
    const steps = [
      'initial', //(optional initial state - where user has to select if personalized or business)
      'started', //done
      'price confirmed', //done
      'description confirmed', //done
      'selected occasion', //done
      'showing paywall', //done
      'input failure',
      'submit order', //done
      'submitting order', //done
      'failed', //done
      'completed', //done
    ];

    if (!creator) {
      // utils.log.warn('not logging - missing user obj');
      return;
    }

    switch (trigger) {
      case 'submit order':
        VLFlowManager.sendFlowAnalytics(
          new BookingAttemptingSubmit(
            creator.profileId,
            creator.id,
            flowManagerRequesTypeFromFlow(flow ?? undefined),
            String(ocassionNumber),
            String(price),
            creator.currency
          )
        );
        break;
      case 'showing paywall':
        VLFlowManager.sendFlowAnalytics(
          new BookingShowingPaywall(
            creator.profileId,
            creator.id,
            flowManagerRequesTypeFromFlow(flow ?? undefined),
            String(ocassionNumber),
            String(price),
            creator.currency
          )
        );
        break;
      case 'submitting order':
        VLFlowManager.sendFlowAnalytics(
          new BookingSubmitting(
            creator.profileId,
            creator.id,
            flowManagerRequesTypeFromFlow(flow ?? undefined),
            String(ocassionNumber),
            String(price),
            creator.currency
          )
        );
        break;
      case 'failed':
        VLFlowManager.sendFlowAnalytics(
          new BookingFailure(
            creator.profileId,
            creator.id,
            flowManagerRequesTypeFromFlow(flow ?? undefined),
            String(ocassionNumber),
            String(price),
            creator.currency
          )
        );
        break;
      case 'completed':
        VLFlowManager.sendFlowAnalytics(
          new BookingCompleted(
            creator.profileId,
            creator.id,
            flowManagerRequesTypeFromFlow(flow ?? undefined),
            String(ocassionNumber),
            String(price),
            creator.currency
          )
        );
        break;
      default:
        break;
    }

    //call with ms delay
    setTimeout(() => {
      const currentFlow = flowRef.current;
      const currentStep = stepRef.current;

      const forBusiness = currentFlow === ORDER_FLOW_FOR_BUSINESS;
      const forSomeoneElse = currentFlow === ORDER_FLOW_FOR_SOMEONE_ELSE;
      const forSelf = currentFlow === ORDER_FLOW_FOR_ME;

      // utils.log.debug('currentFlow: ', currentFlow);
      // utils.log.debug('currentStep: ', currentStep);

      const requestType =
        currentStep > 0
          ? forBusiness
            ? 'business'
            : forSomeoneElse
            ? 'someone else'
            : forSelf
            ? 'for self'
            : null
          : null;

      const finalPrice = price;
      const currency = finalPrice ? creator.currency : null;
      const ocassion =
        currentStep > ORDER_STEP_CHOOSE_FLOW ? occasionNumber : null;

      analytics.event(
        new AnalyticsEvent(`content request - ${trigger}`, [
          {
            'request type': requestType,
            name: creator.profileId,
            price: Number(finalPrice),
            currency: currency,
            creator_id: creator.id,
            ocassion: ocassion,
            error: error,
          },
        ])
      );
    }, 2000);
  };

  const onCloseAuth = () => {
    // setForceAuth(true);
    setShowAuth(false);
  };

  useEffect(() => {
    //log initial event
    logEventForTrigger('initial');
    //if preselected flow.. log second step event as well
    VLFlowManager.sendFlowAnalytics(
      new BookingLanding(creator.profileId, creator.id)
    );
    if (flow && flow !== ORDER_FLOW_INITIAL) {
      logEventForTrigger('started');
      VLFlowManager.sendFlowAnalytics(
        new BookingStarted(
          creator.profileId,
          creator.id,
          flowManagerRequesTypeFromFlow(flow ?? undefined)
        )
      );
    }
  }, []);

  const onAuthSuccess = () => {
    setShowConfirmButtonLoader(true);
    setLoading(false);
    setShowAuth(false);
    updateToStep(step, true);
    setTimeout(() => {
      setProfilePicAfterLogin(
        UserManager &&
          UserManager.currentUser &&
          UserManager.currentUser.profilePictureURL
          ? UserManager.currentUser.profilePictureURL
          : ''
      );

      setShowConfirmButtonLoader(false);
      handleConfirmStepActionButtonPressed(); //attempt buy;
    }, 2000);
    setTimeout(() => {
      setProfilePicAfterLogin(
        UserManager &&
          UserManager.currentUser &&
          UserManager.currentUser.profilePictureURL
          ? UserManager.currentUser.profilePictureURL
          : ''
      );
    }, 4000);
  };

  let currentUserAlreadyHasPaymentMethods =
    paymentManager.validPaymentMethod() != null;

  const setOccasion = (
    occasion: any,
    newImgUrl: any,
    newOccasionNumber: any
  ) => {
    setSelectedOccasion({
      selectedOccasionImg: newImgUrl,
      selectedOccasion: occasion,
    });
    occasionNumber = newOccasionNumber;

    //attempt go to next step if all input is already valid
    attemptGoToNextStep(true);
  };

  /**
   *
   * @param {*} didUpdateFlow Flag to mark if flow has been updated/changed
   */
  const attemptGoToNextStep = (didUpdateFlow = false) => {
    utils.log.debug('attemptGoToNextStep', didUpdateFlow);

    //if invalid step.. don't go to next step
    if (!checkIfCurrentStepIsValid(true, didUpdateFlow)) {
      return;
    }

    //step is valid.. move to next step
    utils.log.debug('step is valid');

    //Select ocassion step
    if (step === ORDER_STEP_CHOOSE_FLOW) {
      updateToStep(step + 1, true); //trick here for bumping to "2nd" step
    } else if (step === ORDER_STEP_SELECT_OCASSION) {
      updateToStep(step + 1, true);
      VLFlowManager.sendFlowAnalytics(
        new BookingSelectedOcassion(
          creator.profileId,
          creator.id,
          flowManagerRequesTypeFromFlow(flow ?? undefined),
          String(occasionNumber),
          String(price),
          creator.currency
        )
      );
    }
    //Description step
    else if (step === ORDER_STEP_SET_DESCRIPTION) {
      updateToStep(step + 1, true);
      logEventForTrigger('description confirmed');
      VLFlowManager.sendFlowAnalytics(
        new BookingDescriptionConfirmed(
          creator.profileId,
          creator.id,
          flowManagerRequesTypeFromFlow(flow ?? undefined),
          String(occasionNumber),
          String(price),
          creator.currency
        )
      );
    } //Price step
    else if (step === ORDER_STEP_SELECT_PRICE) {
      updateToStep(step + 1, true);
      logEventForTrigger('price confirmed');
      VLFlowManager.sendFlowAnalytics(
        new BookingPriceConfirmed(
          creator.profileId,
          creator.id,
          flowManagerRequesTypeFromFlow(flow ?? undefined),
          String(occasionNumber),
          String(price),
          creator.currency
        )
      );
    } else if (step === ORDER_STEP_CONFIRM_AND_SEND) {
      handleConfirmStepActionButtonPressed();
    }
  };

  const handleConfirmStepActionButtonPressed = () => {
    if (!currentUserAlreadyHasPaymentMethods) {
      if (isConnected === false && showAuth === false) {
        /* not logged in - show auth */
        setShowAuth(true);
      } else if (showAddCardScreen === false) {
        /* logged in but no cards - show add card flow */
        setShowAddCardScreen(true);
        setShowHowItWorks(false);
        //log event
        logEventForTrigger('showing paywall');
      }
    } else {
      /* great - user is logged in and has payment methods, now lets proceed with purchase) */
      buy();
      updateToStep(step + 1, true);
      setShowHowItWorks(false);
      setTimeout(() => {
        if (leftScrollRef && leftScrollRef.current) {
          const target: any = leftScrollRef.current;
          target.scrollTop = 2000;
        }
      }, 100);
      // logEventForTrigger('description confirmed');
    }
  };

  const backStep = () => {
    utils.log.debug('backStep');
    let newStep = step - 1;
    if (newStep < ORDER_STEP_CHOOSE_FLOW) {
      newStep = ORDER_STEP_CHOOSE_FLOW;
    }

    updateToStep(newStep, true);
  };

  const changedText = (text: string, variable: string) => {
    if (variable === 'whoIsFor') {
      setBookingDetails({ ...bookingDetails, whoIsFor: text });
    } else if (variable === 'fromWho') {
      utils.log.debug('fromWho...', text);
      setBookingDetails({ ...bookingDetails, fromWho: text });
    } else if (variable === 'whoIsForBusiness') {
      utils.log.debug('whoIsForBusiness...', text);
      setBookingDetails({ ...bookingDetails, whoIsForBusiness: text });
    }
  };

  /**
   * Helper method to check if current step input is valid
   * @param showError Should show error if can't advance to next step
   * @param flowUpdated Was the flow updated or not
   * @returns
   */
  const checkIfCurrentStepIsValid = (
    showError = false,
    flowUpdated = false
  ) => {
    let creatorPrice = creator.price !== undefined ? creator.price : '';

    /* adjust business flow settings */
    if (flow === ORDER_FLOW_FOR_BUSINESS) {
      creatorPrice =
        creator.priceBusiness !== undefined ? creator.priceBusiness : '';
    }

    switch (step) {
      case ORDER_STEP_CHOOSE_FLOW:
        return flow !== null || flowUpdated;
      case ORDER_STEP_SELECT_OCASSION:
        //validate values here
        switch (flow) {
          case ORDER_FLOW_FOR_ME:
            return true; //no need for validation
          case ORDER_FLOW_FOR_SOMEONE_ELSE:
            var fromWho = bookingDetails.fromWho;
            var forWho = bookingDetails.whoIsFor;
            setShowForWhoError(forWho.length === 0);
            setShowFromWhoError(fromWho.length === 0);
            return fromWho.length > 0 && forWho.length > 0;
          case ORDER_FLOW_FOR_BUSINESS:
            var whoIsForBusiness = bookingDetails.whoIsForBusiness;
            const isValidEntry = whoIsForBusiness.length > 0;
            setShowBusinessNameInputError(!isValidEntry);
            setTimeout(() => {
              setShowPriceInputError(false);
              setShowBusinessNameInputError(false);
            }, 3500);
            return isValidEntry;
          default:
            break;
        }
        return true;
      case ORDER_STEP_SET_DESCRIPTION:
        if (
          !description ||
          description.length < constants.FLOW_BOOKING_MIN_DESCRIPTION_CHARS
        ) {
          if (showError) {
            //invalid description - show input error for 500 ms
            setShowDescriptionError(true);
            setTimeout(() => {
              setShowDescriptionError(false);
            }, 3500);
          }
          return false;
        }
        return true;
      case ORDER_STEP_SELECT_PRICE:
        if (Number(price) < Number(creatorPrice)) {
          //invalid price - show error alert ORDER_STEP_SELECT_PRICEor 500 ms
          if (showError) {
            setShowPriceInputError(true);
            setTimeout(() => {
              setShowPriceInputError(false);
            }, 3500);
            return false;
          }
        } else {
          if (price) {
            if (setShowPriceInputError) {
              setShowPriceInputError(false);
            }
            return true;
          } else {
            setShowPriceInputError(true);
            return false;
          }
        }
        return true;
      case ORDER_STEP_CONFIRM_AND_SEND:
        return true;
      default:
        break;
    }
    // console.log('checkIfCurrentStepIsValid 3');
    return false;
  };

  const showTermsOfService = () => {
    let url = `https://kre8.tv/termsofservice.html`;
    window.open(url);
  };

  const uploadImage = (input: any, kind: any) => {
    // console.log(TAG, 'uploadImage ', input, kind);
    if (
      kind === 'fromWho' ||
      kind === 'whoIsFor' ||
      kind === 'main' ||
      kind === 'whoIsForBusiness'
    ) {
      if (input.value && input.files && input.files[0]) {
        if (kind === 'fromWho') {
          setFromWhoImageLoading(true);
        } else if (kind === 'whoIsFor') {
          setForWhoImageLoading(true);
        } else if (kind === 'main') {
          setMainImageLoading(true);
        }

        let file = input.files[0];
        let path = input.value;

        var reader = new FileReader();

        reader.onload = (e) => {
          if (kind === 'fromWho') {
            setFromWhoImageLoading(false);
            setBookingDetails({
              ...bookingDetails,
              fromWhoImage: { path, file, base64: e?.target?.result },
            });
          } else if (kind === 'whoIsFor') {
            setForWhoImageLoading(false);
            setBookingDetails({
              ...bookingDetails,
              whoIsFromImage: { path, file, base64: e?.target?.result },
            });
          } else if (kind === 'whoisForBusiness') {
            setMainImageLoading(false);
            setBookingDetails({
              ...bookingDetails,
              whoIsForBusinessImage: { path, file, base64: e?.target?.result },
            });
          } else if (kind === 'main') {
            setMainImageLoading(false);
            setBookingDetails({
              ...bookingDetails,
              mainImage: { path, file, base64: e?.target?.result },
            });
          }
        };

        reader.readAsDataURL(file);
        return;
        /*uploadManager.uploadFile(path, file, (result, percentage, error : any) => {
          if (kind === 'fromWho' && typeof result === 'string') {
            setFromWhoImageLoading(false);
            setFromWhoImage(result);
          } else if (kind === 'whoIsFor' && typeof result === 'string') {
            setForWhoImageLoading(false);
            setWhoIsFromImage(result);
          } else if (kind === 'main' && typeof result === 'string') {
            setMainImageLoading(false);
            setMainImage(result);
          }
        });*/
      }
    }
  };

  /**
   * Uploads all images / assets for request to backend
   * @returns
   */
  const uploadAllImages = async () => {
    //links for files
    let mainImageUploadedLink: string | null;
    let fromWhoImageUploadedLink: string | null;
    let forWhoImageUploadedLink: string | null;

    let mainImagePromise: any;
    let fromWhoImagePromise: any;
    let fromWhoImagePromise1: any;

    if (mainImage) {
      mainImagePromise = new Promise((resolved) => {
        uploadManager.uploadFile(
          mainImage.path,
          mainImage.file,
          (result?: string, percentage?: number, error?: any) => {
            if (typeof result === 'string') {
              mainImageUploadedLink = result;
              resolved(result);
            }
          }
        );
      });
    }

    if (whoIsFromImage) {
      fromWhoImagePromise = new Promise((resolved) => {
        uploadManager.uploadFile(
          whoIsFromImage.path,
          whoIsFromImage.file,
          (result?: string, percentage?: number, error?: any) => {
            if (typeof result === 'string') {
              forWhoImageUploadedLink = result;
              resolved(result);
            }
          }
        );
      });
    }

    if (fromWhoImage) {
      fromWhoImagePromise1 = new Promise((resolved) => {
        uploadManager.uploadFile(
          fromWhoImage.path,
          fromWhoImage.file,
          (result?: string, percentage?: number, error?: any) => {
            if (result) {
              setMainImageLoading(false);
              fromWhoImageUploadedLink = result;
              resolved(result);
            }
          }
        );
      });
    }

    return Promise.all([
      mainImagePromise,
      fromWhoImagePromise,
      fromWhoImagePromise1,
    ]).then(() => {
      return {
        mainImageUploadedLink,
        fromWhoImageUploadedLink,
        forWhoImageUploadedLink,
      };
    });
  };

  /**
   * A callback to handle application of promotion on current booking flow
   * @param promotion Promotion object to use
   */
  const handleApplyPromotion = (promotion: VLPromotion) => {
    //@todo: here we need to update all prices relevant to the booking
    const price = promotion.toUpdatedPromotionPrice(creator);
    setBookingDetails({ ...bookingDetails, price });
    setPromotion(promotion);
    setShowRedeemCode(false);
  };

  /**
   * General and main function for executing purchase of booking
   */
  const buy = async () => {
    setLoading(true);
    let allImages = await uploadAllImages();

    let firstName = userManager.getUser()?.firstName ?? '';
    let lastName = userManager.getUser()?.lastName ?? '';
    let currentUserFullName = `${firstName} ${lastName}`;

    let fromWhoName: string;
    let forWhoName: string;
    let forSomeoneElse: boolean;
    let business_request: number;

    switch (flow) {
      case ORDER_FLOW_FOR_ME:
        fromWhoName = currentUserFullName;
        forWhoName = currentUserFullName; //for myself
        forSomeoneElse = false;
        business_request = 0;
        break;
      case ORDER_FLOW_FOR_SOMEONE_ELSE:
        fromWhoName = fromWho ?? currentUserFullName;
        forWhoName = whoIsFor;
        forSomeoneElse = true;
        business_request = 0;
        break;
      case ORDER_FLOW_FOR_BUSINESS:
        fromWhoName = currentUserFullName;
        forWhoName = whoIsForBusiness; //for business
        forSomeoneElse = true; //must be set for business
        business_request = 1;
        break;
      default:
        return undefined;
    }

    setPaymentError('');
    var params = {
      business_request: business_request,
      requester_id: UserManager.currentUserId,
      persona_id: creator.id,
      price: price,
      ocassion: occasionNumber,
      ocassion_title: occasionNumber,
      from_who_image_url: allImages.fromWhoImageUploadedLink,
      for_who_image_url: allImages.forWhoImageUploadedLink,
      currency: creator.currency,
      marked_as_anonymous_by_requester: !showMyName,
      for_who: forWhoName,
      requester_attached_image_url: allImages.mainImageUploadedLink,
      from_who: fromWhoName,
      marked_as_private_by_requester: showCreatorName,
      for_someone_else: forSomeoneElse,
      description: description,
      withdraw_funds: price && price > 0 ? 0 : 1, //for credit card withdrawal (1 means take from credits on iOS (old))
      referral_id: UserManager.referralId,
    };

    setTimeout(() => {
      logEventForTrigger('submitting order');
    }, 500);

    //show booking loading
    setShowBookingLoading(true);

    //log event
    logEventForTrigger('submit order');

    const result = await bookingRequestManager.submitBookingRequest(params);

    if (
      result &&
      result.error &&
      (result.error.hebrewText || result.error.text)
    ) {
      // on fail
      updateToStep(ORDER_STEP_SET_DESCRIPTION, true);
      setPaymentError(
        i18n.language === 'he' ? result.error.hebrewText : result.error.text
      );
      setShowBookingLoading(false);
      setLoading(false);
      //log event
      logEventForTrigger('failed');
    } else {
      //on success
      setLoading(false);
      //log event
      logEventForTrigger('completed');
    }
  };

  /**
   *
   * @param {*} newStep The new step to update to
   * @param {*} updateProps Decide if should attempt props update method as well
   */
  const updateToStep = (newStep: number, updateProps = false) => {
    setStep(newStep);
  };

  /**
   * Proceed to a ordering flow with a selected flow type
   * @param {*} newFlow The flow to proceed with the ordering flow
   */
  const startNewFlow = (newFlow: typeof allSupportedFlows[number]) => {
    utils.log.debug('startNewFlow:', newFlow);
    flowRef.current = newFlow;

    setFlow(newFlow);
    attemptGoToNextStep(true);

    // utils.log.debug('oldflow:', flow);
    logEventForTrigger('started');

    VLFlowManager.sendFlowAnalytics(
      new BookingStarted(
        creator.profileId,
        creator.id,
        flowManagerRequesTypeFromFlow(newFlow ?? undefined)
      )
    );
  };

  /**
   * Callback for when payment method has been added
   */
  const confirmCreditCard = () => {
    utils.log.debug('confirmCreditCard booking');
    updateToStep(ORDER_STEP_CONFIRM_AND_SEND, true);
    setShowAddCardScreen(false);
    buy();
  };

  let creatorPrice = creator.price !== undefined ? Number(creator.price) : null;
  let currencySymbol = creator.getCurrencySymbol
    ? creator.getCurrencySymbol()
    : '';
  let profilePictureURL =
    UserManager &&
    UserManager.currentUser &&
    UserManager.currentUser.profilePictureURL
      ? UserManager.currentUser.profilePictureURL
      : '';

  if (flow === ORDER_FLOW_FOR_BUSINESS) {
    creatorPrice =
      creator.priceBusiness !== undefined
        ? Number(creator.priceBusiness)
        : null;
  }

  creatorPrice = Math.round(creatorPrice!);

  if (orderCompleted) {
    //update step to last step of campaign...
    //@todo - add some nice transition to move user from this booking to another
    const bookingRequest = props.lastSubmittedBookingRequest;
    props.onRegularBookingRequestCompleted(bookingRequest);
    //show campaign

    //old + current = show earn credit screen
    return (
      <EarnCredit
        onClose={props.onClose}
        removeBackgroundFromEarn={props.removeBackgroundFromEarn}
      />
    );
  }

  if (showBookingLoading) {
    // console.log('LOADING = ', loading);
    return (
      <span>
        <BookingLoading
          profilePictureURL={profilePictureURL}
          creatorProfilePictureURL={creator.profilePictureURL}
          loading={loading}
          nextStep={() => setOrderCompleted(true)}
        />
      </span>
    );
  }

  /**
   * Creator profile info section (usually on top of screen)
   */
  const CreatorProfileInfo = () => {
    return step === ORDER_STEP_CHOOSE_FLOW && !showHowItWorks ? (
      <BookingProfile User={creator} />
    ) : null;
  };

  /**
   * Render return functions (renders the whole component depending on state,props,etc)
   */
  return (
    <div
      className={`${window.innerWidth > 1024 ? `d-flex flex-wrap` : ''} ${
        props.fullScreen ? styles.heightAllPage : ''
      } ${styles.booking} ${
        i18n.language === 'he' || !i18n.language || i18n.language === 'global'
          ? `${styles.heb}`
          : ''
      }`}
    >
      <Fade in={true} timeout={500}>
        <div className="w-100 h-100 d-flex flex-wrap">
          <Fade in={!showHowItWorks || !isMobile} timeout={500}>
            <div className="w-100">
              <NavBar
                showPromotion={promotion}
                step={step}
                setStep={setStep}
                onClose={props.onClose}
                creator={creator}
                setShowRedeemCode={setShowRedeemCode}
              />
            </div>
          </Fade>
          <div
            className={`${centerFlexColumn()} ${styles.leftArea} ${
              showHowItWorks ? styles.leftAreaBig : ''
            }`}
            ref={leftScrollRef}
          >
            <CreatorProfileInfo />
            <VerticalStepsContainer
              showHowItWorks={showHowItWorks}
              uploadImage={uploadImage}
              changedText={changedText}
              flow={flow}
              step={step}
              User={creator}
              startNewFlow={startNewFlow}
              ocassion={occasionNumber}
              selectedOccasion={selectedOccasion?.selectedOccasion}
              selectedOccasionImg={selectedOccasion?.selectedOccasionImg}
              setSelectedOccasion={(
                newOccasion: any,
                newImgUrl: any,
                num: any
              ) => {
                setOccasion(newOccasion, newImgUrl, num);
              }}
              price={price}
              // setPrice={setPrice}
              currency={currencySymbol}
              toggleShowMyName={() => {
                setShowMyName(!showMyName);
              }}
              showMyName={showMyName}
              description={description}
              setDescription={(description: string) =>
                setBookingDetails({ ...bookingDetails, description })
              }
              setBusinessName={(whoIsForBusiness: string) =>
                setBookingDetails({ ...bookingDetails, whoIsForBusiness })
              }
              showBusinessNameInputError={showBusinessNameInputError}
              showInputError={showInputError}
              next={attemptGoToNextStep}
              whoIsFromImage={whoIsFromImage}
              fromWhoImage={fromWhoImage}
              whoIsFor={whoIsFor}
              fromWho={fromWho}
              activeField={activeField}
              setActiveField={setActiveField}
              showFromWhoError={showFromWhoError}
              showForWhoError={showForWhoError}
            />
            <HowItWorksContentContainerView
              setShowHowItWorks={setShowHowItWorks}
              showHowItWorks={showHowItWorks}
            />
            <div
              className={`${
                isMobile ? 'd-none' : ''
              } position-fixed w-100 text-center`}
              style={{ bottom: '0', marginBottom: '80px' }}
            >
              <VLBigButton
                style={{ minWidth: '200px' }}
                color={showHowItWorks ? 'secondary' : 'primary'}
                variant={showHowItWorks ? 'contained' : 'contained'}
                onClick={() => setShowHowItWorks(!showHowItWorks)}
              >
                {showHowItWorks ? t('close') : t('booking_how_it_works')}
              </VLBigButton>
            </div>
          </div>
          <RightArea
            reset={reset}
            onSelectCreator={onSelectCreator}
            setShowHowItWorks={setShowHowItWorks}
            showHowItWorks={showHowItWorks}
            showConfirmButtonLoader={showConfirmButtonLoader}
            updateToStep={updateToStep}
            setShowCreatorName={setShowCreatorName}
            setOccasion={setOccasion}
            setBookingDetails={setBookingDetails}
            bookingDetails={bookingDetails}
            mainImage={mainImage}
            currentUserAlreadyHasPaymentMethods={
              currentUserAlreadyHasPaymentMethods
            }
            showDescriptionError={showDescriptionError}
            showPriceInputError={showPriceInputError}
            showCreatorName={showCreatorName}
            attemptGoToNextStep={attemptGoToNextStep}
            backStep={backStep}
            checkIfCurrentStepIsValid={checkIfCurrentStepIsValid}
            paymentError={paymentError}
            currencySymbol={currencySymbol}
            uploadImage={uploadImage}
            flow={flow}
            step={step}
            creator={creator}
            startNewFlow={startNewFlow}
            selectedOccasion={selectedOccasion?.selectedOccasion}
            price={price}
            // setPrice={setPrice}
            currency={currencySymbol}
            toggleShowMyName={() => {
              setShowMyName(!showMyName);
            }}
            showMyName={showMyName}
            description={description}
            setDescription={(description: string) =>
              setBookingDetails({ ...bookingDetails, description })
            }
            showInputError={showInputError}
            next={attemptGoToNextStep}
            whoIsFromImage={whoIsFromImage}
            fromWhoImage={fromWhoImage}
            whoIsFor={whoIsFor}
            fromWho={fromWho}
            activeField={activeField}
            setActiveField={setActiveField}
            showFromWhoError={showFromWhoError}
            showForWhoError={showForWhoError}
          ></RightArea>
          <HowItWorksMobile
            t={t}
            showHowItWorks={showHowItWorks}
            setShowHowItWorks={setShowHowItWorks}
          />
          <AuthModalContainer
            showAuth={showAuth}
            onClose={onCloseAuth}
            onAuthSuccess={onAuthSuccess}
          />
          <Paywall
            price={price}
            currency={bookingCurrency}
            currentFlow={flow}
            onClose={() => setShowAddCardScreen(false)}
            t={t}
            creator={creator}
            showAddCardScreen={showAddCardScreen}
            onCreditCardAdded={() => confirmCreditCard()}
          />
          <RedeemCode
            open={showRedeemCode}
            type="booking"
            creatorId={creator.id}
            onClose={() => setShowRedeemCode(false)}
            onSuccess={(promotion: VLPromotion) =>
              handleApplyPromotion(promotion)
            }
          />
        </div>
      </Fade>
    </div>
  );
});

const mapStateToProps = (state: any) => {
  return {
    isConnected: state.user.isConnected,
    lastSubmittedBookingRequest: state.campaign.lastSubmittedBookingRequest,
  };
};

export default connect(mapStateToProps, {
  onSelectCreator,
  reset,
  onRegularBookingRequestCompleted,
})(Booking);
