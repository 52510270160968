import * as utils from '../utils/rhutils.js';
import VLError from '../model/vlError';
import api, * as RHAPI from './../constants/api';
import userManager from './userManager.js';

var _ = require('lodash');

class PaymentManager {
  constructor() {}

  userToken() {
    return userManager.tokenId;
  }

  async addCard(card, token) {
    let userToken = token ? token : this.userToken();
    if (!userToken) {
      //no token!
      // console.log("no token...exiting add card");
      return null;
    } else {
      // console.log("submitting with token ", userToken);
    }

    let headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${userToken}`,
    };

    // console.log("headers: ", headers);
    var body = JSON.stringify(card);

    const url = api.SUBMIT_PAYMENT_METHOD;
    // console.log(`submit ${body} to ${url}`);
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    // console.log("add card:", json);
    let cardResponse = json.payment_method;
    if (cardResponse) {
      //push to the card
      userManager.addPaymentMethod(cardResponse);
      return true;
    } else {
      return json && json.error ? json.error : false;
    }
  }

  async addPaymentMethod(card, token) {
    let userToken = token ? token : this.userToken();
    if (!userToken) {
      //no token!
      utils.log.debug('no token...exiting add card');
      return null;
    } else {
      utils.log.debug('submitting with token ', userToken);
    }

    // console.log("headers: ", headers);
    var body = JSON.stringify(card);
    var headers = await userManager.getRequestHeaders();
    //add token
    headers['Authorization'] = `Bearer ${token}`;

    const url = api.SUBMIT_PAYMENT_METHOD;
    // console.log(`submit ${body} to ${url}`);
    // console.log(`token ${token}`);
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    utils.log.debug('add payment method result', json);
    let result = {};
    if (json.error) {
      let error = VLError.fromJSON(json.error);
      result.error = error;
      result.success = false;
      return result;
    } else {
      result.success = true;
      return result;
    }
  }

  //MARK: - Local DB
  cardFromId(id) {
    return null;
  }

  //MARK: Util
  primaryPaymentMethod() {
    return _.find(userManager.paymentMethods, { primary: true });
  }

  validPaymentMethod() {
    let sorted = _.orderBy(userManager.paymentMethods, ['addedOn'], ['desc']);
    // console.log('validPaymentMethod:::', sorted);
    let result = _.find(sorted, { removed: false });
    // console.log('result:::', result);
    return result;
  }
}

var paymentManager = new PaymentManager();
export default paymentManager;
