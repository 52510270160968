import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CreatorRankRow.module.scss';
import { Image } from '../../../image/image.component';
import { FaStar } from 'react-icons/fa';
import { ICreator } from '../../../../model/interfaces';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';

type CreatorRankRowProps = {
  position: number;
  creator: ICreator;
};

const CreatorRankRow: React.FC<CreatorRankRowProps> = React.memo(
  ({ position, creator }) => {
    const { i18n } = useTranslation();
    const history = useHistory();

    return (
      <div
        className={styles.creatorRankRowContainer}
        onClick={() => {
          window.open(creator.deep_link_url, '_blank');
          window.focus();
        }}
      >
        <div className={styles.creatorRankRow}>
          <span className={styles.position}>{position}.</span>
          <div className={styles.imgContinaer}>
            <Image src={creator.image_url} />
          </div>
          <div className={styles.userDetails}>
            <Typography variant="h3">
              <Box color="text.secondary">
                <span className={styles.title}>{creator.title}</span>
              </Box>
            </Typography>

            <Typography variant="subtitle1">
              <Box color="primary.light">
                <span className={styles.detail}>{creator.detail}</span>
              </Box>
            </Typography>
          </div>
          <div
            className={styles.rank}
            style={{
              marginLeft: i18n.language === 'he' ? 0 : 'auto',
              marginRight: i18n.language === 'he' ? 'auto' : 0,
            }}
          >
            {creator.info ? <FaStar /> : null} <span>{creator.info}</span>
          </div>
        </div>
      </div>
    );
  }
);

export default CreatorRankRow;
