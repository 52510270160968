import { GridColDef } from '@material-ui/data-grid';
import {
  ITableRowsCreations,
  MapRecordToRow,
  TableRowsCreations,
  VLColumnsForTable,
  ICreatorRecruitmentDetails,
} from '../DataMappers/VLDataMapper';
import {
  IHomepageRegion,
  ICreator,
  IHomepageSection,
  ICategory,
  IVideo,
} from '../../model/interfaces';
import {
  FeaturedCategoryFactory,
  CreatorCategoriesCategoryFactory,
  ListCategoryFactory,
  CategoriesCategoryFactory,
  VideosCategoryCategoryFactory,
} from '../../model';
import { store } from '../../store';
import { updateData, updateCreators } from '../../store/reducers/home/';

import Challenge from '../../model/Challenge';
import { isHebrew } from '../../App';
var Airtable = require('airtable');
var _ = require('lodash');

export class AirtableManager {
  base?: any;
  constructor() {
    this.base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);
  }

  fetchRecords(
    callback: (
      columns: GridColDef[],
      rows: ITableRowsCreations[],
      errorMsg?: string
    ) => void
  ) {
    this.base!('Creations')
      .select({
        // Selecting the first 3 records in Base view:
        maxRecords: 20,
        view: 'Base view',
      })
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.

          let rows: TableRowsCreations[] = [];
          records.forEach(function (record: any) {
            // console.log('Retrieved', record.get('id'));
            rows.push(MapRecordToRow(record));
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();

          const columns = VLColumnsForTable('creations');

          callback(columns, rows, undefined);
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback([], [], err);
            return;
          }
        }
      );
  }

  /**
   * Fetches the creator airtable info from the recruitment airtable (different base)
   * @param callback
   */
  fetchCreatorAirtableInfo(
    profileHandle: string,
    callback: (result?: ICreatorRecruitmentDetails, error?: any) => void
  ) {
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base('app8C74QZS3auBK7Z');
    base('Main List')
      .select({
        // Selecting the first 20 records in Base view:
        maxRecords: 1,
        view: 'All',
        filterByFormula: `FIND("${profileHandle}",username)`,
      })
      .eachPage(
        function page(records: any[], fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.

          const json = records.length > 0 ? records[0] : undefined;
          if (json) {
            let fields = json.fields;
            let obj: ICreatorRecruitmentDetails = {};
            //general
            obj.username = fields['username'];
            obj.name = fields['Creator name'];
            obj.firstName = fields['first name'];
            obj.lastName = fields['last name'];
            obj.title = fields['profile_headline'];
            obj.description = fields['profile_description'];
            obj.profilePictureUrl = fields['profile_picture_url'];
            obj.tags = [];
            //internal
            obj.category_id = fields['category_id'];
            obj.currency = fields['currency'];
            //socials / links
            obj.custom_url = fields['custom_website_url'];
            obj.instagram_url = fields['instagram_url'];
            obj.facebook_url = fields['facebook_url'];
            obj.youtube_url = fields['youtube_url'];
            obj.twitter_url = fields['twitter_url'];
            obj.tiktok_url = fields['tiktok_url'];
            //personal requests

            obj.personal_requests = Boolean(fields['personal_requests'] ?? 0);
            obj.personal_requests_price = Number(
              fields['personal_requests_price'] ?? 0
            );
            //business requests
            obj.business_requests = Boolean(fields['business_requests'] ?? 0);
            obj.business_requests_price = Number(
              fields['business_requests_price'] ?? 0
            );

            callback(obj, fields ? undefined : 'Could not fetch info');
          } else {
            callback(undefined, 'Could not fetch info');
          }
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback(undefined, err);
            return;
          }
        }
      );
  }

  /**
   * Fetches the home feed data
   * @param callback
   */
  async fetchHomeFeed(
    callback: (
      feed?: { regions?: IHomepageRegion[]; feed?: IHomepageSection[] },
      error?: any
    ) => void
  ) {
    let result: { regions?: IHomepageRegion[]; feed?: IHomepageSection[] } = {};

    const regionId = store.getState().user.regionId;

    this.fetchRegions((regions, any) => {
      // console.log('fetched regions: ', regions);
      if (regions) {
        result.regions = regions;
        this.fetchCategories(regionId, (categories, any) => {
          if (categories) {
            this.fetchCreators(false, undefined, (creators, any) => {
              //great - now we have creators, and can do the homepage preperation
              if (creators) {
                this.fetchVideos(undefined, (videos, any) => {
                  let generateFeed = this.feedGenerator(
                    categories,
                    creators,
                    videos
                  );
                  result.feed = generateFeed;
                  callback(result, undefined);

                  //update store here
                  store.dispatch(
                    updateData({
                      allUsers: creators,
                      categories: categories,
                      regions: regions,
                      videos: videos,
                    })
                  );
                });
              } else {
                callback(undefined, 'not completed');
              }
            });
          } else {
            callback(undefined, 'not completed');
          }
        });
      } else {
        callback(undefined, 'not completed');
      }
    });
  }

  /**
   * Fetches data for the discover feed (detail view on a category)
   * @param callback
   */
  async fetchDiscoverFeed(
    categoryId: string,
    callback: (
      feed?: { regions?: IHomepageRegion[]; feed?: IHomepageSection[] },
      error?: any
    ) => void
  ) {
    //prepare results
    let result: { regions?: IHomepageRegion[]; feed?: IHomepageSection[] } = {};

    //fetch categories? (can improve later)
    this.fetchCategories('', (categories, any) => {
      if (categories) {
        //fetch creators
        this.fetchCreators(false, undefined, (creators, any) => {
          //great - now we have creators, and can do the homepage preperation
          if (creators) {
            let filtered = categories.filter(
              (cat: ICategory) => cat.id === categoryId
            );
            if (filtered.length > 0) {
              let generateFeed = this.feedGenerator(
                filtered,
                creators,
                undefined,
                false
              );
              result.feed = generateFeed;
              callback(result, undefined);
              return;
            }
            callback(undefined, 'category not found');
          } else {
            callback(undefined, 'not completed');
          }
        });
      } else {
        callback(undefined, 'not completed');
      }
    });
  }

  /**
   * Creates a feed from fetched data
   * @param categories array of categories
   * @param creators array of creators
   * @returns
   */
  feedGenerator(
    categories: ICategory[],
    creators: ICreator[],
    videos?: IVideo[],
    includeCategories = true
  ): IHomepageSection[] {
    let sections: IHomepageSection[] = [];
    //1. Grab featured creators

    categories.forEach((category) => {
      //Get all creators that match this category
      let children = creators.filter((creator) => {
        if (
          creator.category_ids &&
          creator.category_ids.some((el) => {
            return el === category.id;
          })
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (category.kind === 'featured') {
        sections.push(
          FeaturedCategoryFactory(
            category.id,
            category.title,
            `/discover?category_id=${category.id}&title=${category.title}`,
            children
          )
        );
      } else if (category.kind === 'list') {
        sections.push(
          ListCategoryFactory(
            category.id,
            category.title,
            `/discover?category_id=${category.id}&title=${category.title}`,
            children
          )
        );
      } else if (category.kind === 'creator_category') {
        sections.push(
          CreatorCategoriesCategoryFactory(
            category.id,
            category.title,
            `/discover?category_id=${category.id}&title=${category.title}`,
            children
          )
        );
      }
    });

    //Add category of categories at index (2) and

    if (includeCategories) {
      let categoriesCategory = CategoriesCategoryFactory(
        'categories',
        isHebrew() ? 'קטגוריות' : 'Categories',
        `/discover?category_id=categories&title=Categories`,
        categories
      );

      sections.splice(1, 0, categoriesCategory);
    }

    //add another section for the videos
    if (videos && videos.length > 0) {
      let videoCat = {
        id: 'short_form_videos',
        title: isHebrew() ? 'סרטונים' : 'Videos',
      };
      sections.splice(
        2,
        0,
        VideosCategoryCategoryFactory(
          videoCat.id,
          videoCat.title,
          `/discover?category_id=${videoCat.id}&title=${videoCat.title}`,
          videos
        )
      );
    }

    return sections;
  }

  /**
   * Fetches the available regions
   * @param callback
   */
  fetchRegions(callback: (regions?: any, error?: any) => void) {
    //1. Fetch regions
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base('appjlLQJ6BVoh7oQ7');

    base('Regions')
      .select({
        // Selecting the first 20 records in Base view:
        fields: ['id', 'country_code', 'title', 'status'],
        maxRecords: 20,
        view: 'Live view',
      })
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.
          let regions: IHomepageRegion[] = [];
          records.forEach(function (record: any) {
            const { fields } = record;

            let parsedRegion = {
              id: fields.id, //the unique id of the subscription
              title: fields.title, //the user who purchased
              country_code: fields.country_code,
              is_waitlist: fields.status === 'waitlist',
              percentOfComplete: fields.percent_of_complete,
            };
            regions.push(parsedRegion);
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.

          fetchNextPage();
          callback(regions, undefined);
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback(undefined, undefined);
            return;
          }
        }
      );
  }

  /**
   * Fetches the available categories
   * @param callback
   */
  fetchCategories(
    regionId: string,
    callback: (regions?: any, error?: any) => void
  ) {
    //1. Fetch regions
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base('appjlLQJ6BVoh7oQ7');

    let categories: ICategory[] = [];

    base('Categories')
      .select({
        // Selecting the first 20 records in Base view:
        fields: [
          'id',
          'title',
          'title_hebrew',
          'kind',
          'image_url',
          'campaign_image_url',
          'emoji',
        ],
        maxRecords: 50,
        view: 'Live view',
        filterByFormula: `FIND("${regionId}",region)`,
      })
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record: any) {
            const { fields } = record;

            let parsedCategory = {
              id: fields.id,
              title: isHebrew() ? fields.title_hebrew : fields.title,
              kind: fields.kind,
              image_url: fields.image_url,
              campaign_image_url: fields.campaign_image_url,
              deep_link_url: fields.deep_link_url,
              emoji: fields.emoji,
            };
            categories.push(parsedCategory);
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback(undefined, undefined);
            return;
          } else {
            callback(categories, undefined);
          }
        }
      );
  }

  fetchCreators(
    saveToStore: boolean,
    categoryId?: string,
    callback?: (creators?: any, error?: any) => void
  ) {
    //1. Fetch regions
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base('appjlLQJ6BVoh7oQ7');

    let creators: ICreator[] = [];

    let queryOptions = {
      // Selecting the first 20 records in Base view:
      fields: [
        'kre8tv_user_id',
        'profile_id',
        'full_name',
        'title',
        'profile_picture_url',
        'profile_video_url',
        'category_ids',
        'followers_instagram',
        'followers_youtube',
        'followers_tiktok',
        'base_currency',
        'price_personal_bookings',
        'price_promotional_bookings',
        'ranking_score',
      ],
      maxRecords: 400,
      view: 'Live view',
    };

    if (categoryId) {
      queryOptions = {
        // Selecting the first 20 records in Base view:
        fields: [
          'kre8tv_user_id',
          'profile_id',
          'full_name',
          'title',
          'profile_picture_url',
          'profile_video_url',
          'category_ids',
          'followers_instagram',
          'followers_youtube',
          'followers_tiktok',
          'base_currency',
          'price_personal_bookings',
          'price_promotional_bookings',
          'ranking_score',
        ],
        maxRecords: 200,
        view: 'Live view',
        //@ts-ignore
        filterByFormula: `FIND("${categoryId}",category_ids)`,
      };
    }

    base('Creators')
      .select(queryOptions)
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record: any) {
            const { fields } = record;

            let parsedCreator = {
              kre8tv_user_id: fields.kre8tv_user_id,
              id: fields.profile_id,
              title: fields.full_name, //see this thing
              detail: fields.title, //see this thing
              image_url: fields.profile_picture_url,
              thumbnail_image_url: fields.profile_picture_url,
              video_url: fields.profile_video_url,
              info: fields.info,
              currency: fields.base_currency,
              price: fields.price_personal_bookings,
              price_business: fields.price_promotional_bookings,
              icon_url: fields.icon_url,
              category_ids: fields.category_ids,
              followers_instagram: fields.followers_instagram,
              followers_youtube: fields.followers_youtube,
              followers_tiktok: fields.followers_tiktok,
              deep_link_url: `/${fields.profile_id}`,
              deep_link_url_secondary: `/${fields.profile_id}?goto=subscribe`,
              ranking_score: fields.ranking_score ?? 0,
            };
            creators.push(parsedCreator);
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.

          fetchNextPage();
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            if (callback) callback(undefined, err);
            return;
          } else {
            if (callback) {
              callback(creators, undefined);
            }

            if (saveToStore) store.dispatch(updateCreators(creators));
          }
        }
      );
  }

  fetchVideos(
    categoryId?: string,
    callback?: (regions?: any, error?: any) => void
  ) {
    //1. Fetch regions
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base('appjlLQJ6BVoh7oQ7');

    let videos: IVideo[] = [];

    let queryOptions = {
      // Selecting the first 20 records in Base view:
      fields: [
        'id',
        'title',
        'detail',
        'image_url',
        'video_url',
        'auto_video_url',
        'profile_picture_url',
        'profile_id',
      ],
      maxRecords: 100,
      view: 'Live view',
    };

    base('Videos')
      .select(queryOptions)
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record: any) {
            const { fields } = record;

            let parsedVideos = {
              id: fields.id,
              title: fields.title,
              detail: fields.detail,
              image_url: fields.image_url,
              video_url: fields.auto_video_url,
              profile_picture_url: fields.profile_picture_url,
              profile_id:
                fields.profile_id && fields.profile_id.length > 0
                  ? fields.profile_id[0]
                  : null,
            };
            videos.push(parsedVideos);
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.

          fetchNextPage();
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            if (callback) callback(undefined, err);
            return;
          } else {
            if (callback) callback(videos, undefined);
          }
        }
      );
  }

  /**
   * Fetches the template from an id
   * @param callback
   */
  fetchChallenge(
    id: string,
    callback: (result?: Challenge, error?: any) => void
  ) {
    //1. Fetch challenges

    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base('appjlLQJ6BVoh7oQ7');

    base('Challenges')
      .select({
        // Selecting the first 20 records in Base view:
        maxRecords: 1,
        view: 'Production view',
        filterByFormula: `FIND("${id}",id)`,
      })
      .eachPage(
        function page(records: any[], fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.
          const json = records.length > 0 ? records[0] : undefined;
          if (json) {
            let fields = json.fields;
            // For now, always show hebrew
            if (isHebrew() || fields.data_he) {
              fields.data = fields.data_he;
            }
            const challenge = Challenge.fromJSON(fields);
            if (!challenge) {
              callback(undefined, 'No good');
            } else {
              callback(challenge, challenge ? undefined : 'No good');
            }
          } else {
            callback(undefined, 'No good');
          }
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback(undefined, err);
            return;
          }
        }
      );
  }

  /**
   * Fetches the template from an id
   * @param callback
   */
  saveBusinessLead(
    firstName: string,
    lastName: string,
    companyName: string,
    email: string,
    phone: string,
    message: string,
    callback: (success: boolean, error?: any) => void
  ) {
    //1. Fetch challenges
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base('appqxBsRpUXVbGjsZ');

    base('Leads').create(
      [
        {
          fields: {
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
            email: email,
            phone: phone,
            message: message,
          },
        },
      ],
      function (err: any, records: any) {
        if (err) {
          console.error(err);
          callback(false, err);
        } else {
          callback(true, undefined);
        }
      }
    );
  }
}
