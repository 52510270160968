import * as strings from './vlStrings';
import * as utils from './rhutils';
import { getI18n } from 'react-i18next';

export function toPriceString(
  currency,
  price,
  showFree = false,
  toSocialString = false
) {
  if (toSocialString) {
    var settings = {
      style: 'currency',
      currency: currency ?? 'ILS',
    };

    if (currency === 'USD') {
      // settings['maximumSignificantDigits'] = 2;
    } else {
      settings['maximumSignificantDigits'] = 2;
    }
    var formatter = new Intl.NumberFormat('en-US', settings);
    let adjustedNumber = strings.toSocialString(price, false, true);
    // utils.log.debug(`original ${price} -> ${adjustedNumber}`);

    let priceStr = formatter
      .format(price < 1000 ? parseInt(adjustedNumber) : adjustedNumber)
      .replace(/\D00$/, '');
    // utils.log.debug(`original priceStr ${adjustedNumber} -> ${priceStr}`);
    let suffix = strings.toSocialString(price, true, false);
    let str = price === 0 && showFree ? getI18n().t('free') : priceStr + suffix;
    return str;
  } else {
    var settings = {
      style: 'currency',
      currency: currency,
    };
    var formatter = new Intl.NumberFormat('en-US', settings);
    let priceStr = formatter.format(price).replace(/\D00$/, '');
    let str = price === 0 && showFree ? getI18n().t('free') : priceStr;
    return str;
  }
}

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function toRoundedPriceString(currency, price) {
  let rounded = Math.ceil(price / 5) * 5;
  return toPriceString(currency, rounded);
}

export function toConvertedPriceString(
  currency,
  fromCurrency,
  price,
  showFree = false
) {
  var settings = {
    style: 'currency',
    currency: currency,
  };

  var adjustedPrice = utils.convertToPrice(price, fromCurrency, currency);
  var formatter = new Intl.NumberFormat('en-US', settings);
  let priceStr = formatter.format(adjustedPrice).replace(/\D00$/, '');
  let str = adjustedPrice === 0 && showFree ? getI18n().t('free') : priceStr;
  return str;
}

var currency_symbols = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
};

export function symbolFromCode(code) {
  if (currency_symbols[code] !== undefined) {
    return currency_symbols[code];
  } else {
    return code;
  }
}

export function cleanInputPrice(price) {
  var result = price
    .split('₪')
    .join('')
    .split('$')
    .join('')
    .split(',')
    .join('')
    .split('.')
    .join('');
  return result;
}
