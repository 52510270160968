import { Typography, TypographyProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const VLSubtitle = (props: TypographyProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Typography variant="h4" {...props}>
      {props.children}
    </Typography>
  );
};

export default VLSubtitle;
