import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
  Checkbox,
  TextField,
  Chip,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  ButtonBase,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { getI18n, useTranslation } from 'react-i18next';

import { withBookingStage } from '../../../../../shared/hoc';
import {
  updateCategory,
  onSelectCreator,
  onDeselectCreator,
} from '../../../../../store/reducers/campaigns/';

import { store } from '../../../../../store';

import { Virtuoso } from 'react-virtuoso';

import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { isHebrew } from '../../../../../App';

import { sortBy } from 'lodash';
import { toRoundedPriceString } from '../../../../../utils/priceUtils';
import { parse } from '../../../../../utils/vlStrings';
import VLFlowManager, {
  CampaignFlowEventCreatorSelect,
} from '../../../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
  },
  CreatorBox: {
    position: 'relative',
    cursor: 'pointer',
  },
  imageBox: {
    color: theme.palette.primary.main,
    width: '60px',
    height: '60px',
    minHeight: '60px',
    background: '#F9F9F9',
  },
  checkboxContainer: {
    border: '2px solid #DBCAFE',
    boxSizing: 'border-box',
    borderRadius: '5px',
  },
}));

const CustomColorCheckbox = withStyles({
  root: {
    transform: 'scale(1.35)',
    color: '#DBCAFE',
    '&$checked': {
      color: '#DBCAFE',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CreatorBox = (props) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const { title, detail, image, checked, price, id, onCreatorClicked } = props;

  const handleChange = (event) => {};

  return (
    <Box
      onClick={onCreatorClicked}
      className={classes.CreatorBox}
      mt={1}
      mb={1}
      pl={!isHebrew() ? 1 : 2}
      pr={isHebrew() ? 1 : 2}
      width="100%"
      display="flex"
      flexDirection="row"
      alignItems="center"
      height="40px"
    >
      <ButtonBase
        style={{
          width: '100%',
          height: '100%',
          textAlign: isHebrew() ? 'right' : 'left',
        }}
      >
        <Box mr={1} flexShrink={0}>
          <CustomColorCheckbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Box>
        <Box width="36px" height="36px" borderRadius={11} overflow="hidden">
          <img
            id="profile-picture"
            alt="ocassion"
            src={image}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
        <Box
          ml={2}
          mr={2}
          flexGrow={1}
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Box fontWeight="bold" fontSize="16px" mb={0.5} letterSpacing={-0.41}>
            {title}
          </Box>
          <Box
            fontSize="12px"
            color="primary.light"
            component="div"
            textOverflow="ellipsis"
          >
            {detail}
          </Box>
        </Box>
        <Typography variant="h2">
          <Box flexShrink={0} fontSize="16px">
            {price}
          </Box>
        </Typography>
      </ButtonBase>
    </Box>
  );
};

const SearchBox = (props) => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const { show, onSearchUpdated } = props;
  if (!show) {
    return null;
  }

  const onChange = (e) => {
    let val = e.target.value;
    onSearchUpdated(val);
  };

  return (
    <Box flexGrow={1} mr={1}>
      <TextField
        autoFocus
        margin="dense"
        id="search-for-creators"
        label={t('search_for_creators')}
        type="url"
        fullWidth
        onChange={onChange}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
      ></TextField>
    </Box>
  );
};

const FilterBox = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const { show, categories, selectedCategories, setSelectedCategories } = props;
  if (!show) {
    return null;
  }

  const handleDelete = (category) => {
    //should add cateogry to array
    selectedCategories.splice(selectedCategories.indexOf(category.id), 1);
    setSelectedCategories([...selectedCategories]);
  };
  const handleClick = (category) => {
    //should add cateogry tot array
    selectedCategories.push(category.id);
    setSelectedCategories([...selectedCategories]);
  };

  return (
    <Box width="100%">
      <Box
        width="100%"
        id="category-filter"
        display="flex"
        flexDirection="row"
        alignItems="center"
        overflow="auto"
        pb={2}
        pt={2}
        pl={isHebrew() ? 0 : 2}
        pr={!isHebrew() ? 0 : 2}
      >
        {categories.map((cat, index) => {
          return (
            <Box mr={1}>
              <Chip
                style={{
                  paddingRight: isHebrew() ? '16px' : '0px',
                  paddingLeft: isHebrew() ? '16px' : '0px',
                  fontFamily: 'Assistant',
                }}
                key={index}
                label={cat.title}
                onClick={() => handleClick(cat)}
                onDelete={() => handleDelete(cat)}
                color={
                  selectedCategories.indexOf(cat.id) >= 0 ? 'primary' : 'none'
                }
              />
            </Box>
          );
        })}
      </Box>
      <Box id="sort-by"></Box>
    </Box>
  );
};

const TopSection = (props) => {
  const {
    showSearch,
    title,
    onSearchUpdated,
    setShowSearch,
    setShowFilter,
    showFilter,
    categories,
    selectedCategories,
    setSelectedCategories,
  } = props;
  return (
    <div>
      <Box
        p={2}
        flexDirection="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box height="15px" display={showSearch ? 'none' : 'block'}>
          <Typography variant="h5">
            <Box fontWeight="bold" color="text.primary" mb={2}>
              {title}
            </Box>
          </Typography>
        </Box>
        <SearchBox
          show={showSearch}
          onSearchUpdated={(search) => onSearchUpdated(search)}
        />
        <Box>
          <IconButton
            size="small"
            id="search"
            variant=""
            style={{
              marginRight: '8px',
              marginLeft: '8px',
              color: showSearch ? 'white' : '#5D36BD',
              background: showSearch ? '#5D36BD' : 'white',
            }}
            onClick={() => setShowSearch(!showSearch)}
          >
            <SearchIcon />
          </IconButton>
          <IconButton
            size="small"
            id="filter"
            style={{
              color: showFilter ? 'white' : '#5D36BD',
              background: showFilter ? '#5D36BD' : 'white',
            }}
            onClick={() => setShowFilter(!showFilter)}
          >
            <TuneIcon />
          </IconButton>
        </Box>
      </Box>
      <FilterBox
        show={showFilter}
        categories={categories}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
    </div>
  );
};

const CreatorsScreen = (props) => {
  const classes = useStyles();
  const [activeCreators, setActiveCreators] = useState([]);
  const [showFilteredCreators, setShowFilteredCreators] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [showFilter, setShowFilter] = useState(props.selectedCategory);
  const [selectedCategories, setSelectedCategories] = useState(
    props.selectedCategory ? [props.selectedCategory] : []
  );

  const { t, i18n } = useTranslation();
  let { creators, categories, selectedCreators, type } = props;

  const count = selectedCreators.length;
  const suffix = count > 0 ? ` (${count})` : '';
  const title = parse(t('campaigns_ocassion_creators_select'), suffix);

  const onSearchUpdated = (query) => {
    setSearchQuery(query.length > 0 ? query : null);
  };

  useEffect(() => {
    onUpdatedFilter();
  }, [searchQuery, selectedCategories]);

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new CampaignFlowEventCreatorSelect());
  }, []);

  const users = showFilteredCreators ? activeCreators : creators;

  const onUpdatedFilter = () => {
    let filtered = creators;
    /* filter by query */
    if (searchQuery) {
      filtered = creators.filter(
        (creator) =>
          creator.title.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0 ||
          creator.detail.toLowerCase().indexOf(searchQuery.toLowerCase()) >=
            0 ||
          creator.id.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
      );
    }

    /* filter by categories */
    if (selectedCategories.length > 0) {
      const connected = selectedCategories.join(',').toLowerCase();
      filtered = filtered.filter((creator) => {
        let match = false;
        creator.category_ids.forEach((cat) => {
          if (connected.indexOf(cat.toLowerCase()) >= 0) match = true;
        });
        return match;
      });
    }

    setActiveCreators(filtered);
    setShowFilteredCreators(true);
  };

  const onCreatorClicked = (creator, remove) => {
    if (remove) {
      props.onDeselectCreator(creator);
    } else {
      props.onSelectCreator(creator);
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box overflow="auto" style={{ marginTop: '80px' }}>
        <TopSection
          showSearch={showSearch}
          title={title}
          onSearchUpdated={onSearchUpdated}
          setShowSearch={setShowSearch}
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          categories={categories}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
        <Virtuoso
          style={{ width: '100%', height: '80vh' }}
          totalCount={users.length}
          itemContent={(index) => {
            let selected = selectedCreators.indexOf(users[index]) >= 0;
            return (
              <CreatorBox
                onCreatorClicked={() =>
                  onCreatorClicked(users[index], selected)
                }
                key={index}
                price={toRoundedPriceString(
                  users[index].currency,
                  type === 'personal'
                    ? users[index].price
                    : users[index].price_business
                )}
                title={users[index].title}
                detail={users[index].detail}
                image={users[index].image_url}
                value={users[index].id}
                checked={selected}
              />
            );
          }}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ home, campaign }) => {
  let cats = campaign.selectedCategory
    ? sortBy(home.data.categories, (cat) =>
        cat.id === campaign.selectedCategory ? 0 : 1
      )
    : home.data.categories;

  const type = campaign.type;
  let creators = home.data.allUsers;

  if (type === 'personal') {
    creators = creators.filter(
      (creator) => creator.price > 0 && creator.currency
    );
  } else {
    creators = creators.filter(
      (creator) => creator.price_business > 0 && creator.currency
    );
  }
  //remove any creators with 0 as price
  return {
    type: type,
    categories: cats,
    selectedCategory: campaign.selectedCategory,
    creators: creators,
    selectedCreators: campaign.selectedCreators,
  };
};

export default connect(mapStateToProps, {
  updateCategory,
  onSelectCreator,
  onDeselectCreator,
})(withBookingStage(CreatorsScreen));
