import { connect } from 'react-redux';
import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './VerifyEmailScreen.module.scss';
import { Alert, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Navbar from '../../components/NewNavbar/Navbar';
import {
  ButtonBase,
  Container,
  Button,
  Grid,
  CircularProgress,
  Box,
} from '@material-ui/core';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import IconFacebook from './images/auth_small_facebook.svg';
import IconGoogle from './images/auth_small_google.svg';
import PhoneEmailInputTextField from './PhoneEmailInputTextField';
import LoadingOverlay from '../../components/Modal/Loading/LoaderOverlay';
import { CodeConfirmationScreen } from '../Verification';
import userManager from '../../managers/userManager';
import Toast from '../../Toasts/Toast';
import { centerFlexColumn, lclDirection } from '../../utils/bootstrapUtils';
import { parse } from '../../utils/vlStrings';
import { withRouter, Redirect } from 'react-router-dom';

type Providers = 'google' | 'facebook' | 'phone' | 'email';

interface VerifyEmailScreenProps {
  onClick?: any;
}

interface PhoneInputField {
  onPhoneRecognized: any;
  onEmailRecognized: any;
  showPhoneField: boolean;
  setShowPhoneField: any;
}

const VerifyEmailScreen = (props: any) => {
  const { t, i18n } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);

  const { isConnected } = props;

  const TitleSection = () => {
    return (
      <div>
        <Box mb={2}>
          <div className={styles.title}>{t('check_inbox_screen_title')}</div>
        </Box>
        <div className={styles.detail}>
          {parse(
            t('check_inbox_screen_detail'),
            userManager.getSignUpFlowEmail()
          )}
        </div>
      </div>
    );
  };

  const AccountAuthedSection = () => {
    return (
      <div className="text-center">
        <div className={styles.title}>
          {t('check_inbox_account_authed_title')}
        </div>

        <div className={styles.detail}>
          {parse(
            t('check_inbox_account_authed_screen_detail'),
            userManager.getSignUpFlowEmail()
          )}
        </div>
      </div>
    );
  };

  const onOpenInboxClicked = () => {
    // console.log('hessssllo');
  };

  const OpenInboxButton = () => {
    return (
      <Grid container justify="center">
        <Button
          onClick={() => onOpenInboxClicked()}
          className={`${styles.OpenInboxButton}`}
        >
          <div className={styles.button_title}>{t('open_inbox')}</div>
        </Button>
      </Grid>
    );
  };

  const onResendEmailClicked = () => {
    // console.log('hello');
  };

  const ResendButtonAndSpinner = () => {
    return isConnected ? null : (
      <Grid container justify="center">
        {/* <Button
          onClick={() => onResendEmailClicked()}
          className={`${styles.ResendButton}`}
        > */}
        {/* <div className={styles.button_title}>{t('resend_code')}</div> */}
        {/* </Button> */}
        <Box width="100%" textAlign="center">
          {<CircularProgress size={24} />}
        </Box>
      </Grid>
    );
  };
  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-between align h-100`}
      dir={lclDirection()}
    >
      <Box p={3} height="75%">
        {isConnected ? null : <TitleSection />}
        {isConnected ? <AccountAuthedSection /> : null}
        <div style={{ height: '43px' }}></div>
        {/* <OpenInboxButton /> */}
        <div style={{ height: '10px' }}></div>
        <ResendButtonAndSpinner />
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return { isConnected: state.user.isConnected };
};

export default connect(mapStateToProps)(withRouter(VerifyEmailScreen));
