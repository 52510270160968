import React from 'react';
import globe from '../drawables/region_globe.svg';
import Flags from 'country-flag-icons/react/3x2';
import Components from 'react';
import { hasFlag } from 'country-flag-icons';

class VLRegion {
  id = ''; //the unique id of the subscription
  title = ''; //the user who purchased
  countryCode = null;
  isWaitlist = false;
  percentOfComplete = 0.0;

  creator = null;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
  static fromJSON(json) {
    var region = new VLRegion();
    region.id = json.id;
    region.title = json.title;
    region.countryCode = json.country_code;
    region.isWaitlist = json.is_waitlist;
    region.percentOfComplete = json.percentOfComplete;
    return region;
  }

  static globalRegion() {
    var region = new VLRegion();
    region.id = 'global';
    region.title = 'Global';
    region.countryCode = 'global';
    region.isWaitlist = false;
    region.percentOfComplete = 1.0;
    return region;
  }

  static israelRegion() {
    var region = new VLRegion();
    region.id = 'israel';
    region.title = 'Israel';
    region.countryCode = 'israel';
    region.isWaitlist = false;
    region.percentOfComplete = 1.0;
    return region;
  }

  roundedFlag() {
    if (!this.countryCode || hasFlag(this.countryCode) === false) {
      return <img src={globe} />;
    } else if (this.id === 'global') {
      return <img src={globe} />;
    } else {
      var FlagComponent = Flags[this.countryCode];
      return React.createElement(FlagComponent, {});
    }
  }
}

export default VLRegion;
