import React, { Component, Fragment } from 'react';
import * as utils from '../utils/rhutils.js';
import { parse, lclString, dateLocale } from '../utils/vlStrings';
import * as priceUtils from '../utils/priceUtils';

class BookingItem {
  id = null;
  active = false;
  removed = false;
  price = null;
  currency = 'ILS';
  description = null;
  title = null;
  details = '';
  type = 0; // 0 => LIVE SESSION / ZOOM..requires confirmation, and url
  userId = null;
  lastUpdated = null; //epoch
  createdOn = null;
  showDate = false;
  requireRequestDescription = false;
  chargeOnBooking = false;

  transactionId = null;
  postPurchaseResponse = null;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  priceString(priceBid, justPrice) {
    if (this.active === false && !justPrice) {
      return lclString('booking_not_available');
    }

    let price = priceBid ? priceBid : this.price;
    let currency = this.currency;
    let priceStr = priceUtils.toPriceString(currency, price);
    if (justPrice === true) {
      return priceStr;
    }
    let str = parse(
      lclString('send_booking_item_request_price'),
      price === 0 ? lclString('free') : priceStr
    );
    return str;
  }

  requestPlaceholder() {
    if (this.showDate) {
      return `${lclString(
        'rcs_desc_placeholder_generic_booking_item'
      )}\n\n${lclString('rcs_desc_placeholder_generic_booking_item_2')}`;
    } else {
      return lclString('request_booking_item_placeholder_leave_message');
    }
  }

  howItWorksDescription() {
    if (this.chargeOnBooking) {
      return (
        <div>
          - {lclString('rcs_business_explainer_detail_4_generic')}
          <br></br>- {lclString('rcs_business_explainer_detail_5_generic')}
          <br></br>
          <br></br>
          {/* {lclString("rcs_business_explainer_detail_5")} */}
        </div>
      );
    } else {
      return (
        <div>
          - {lclString('rcs_business_explainer_detail_3_generic')}
          <br></br>- {lclString('rcs_business_explainer_detail_2_generic')}
          <br></br>- {lclString('rcs_business_explainer_detail_2')}
          <br></br>- {lclString('rcs_business_explainer_detail_4_generic')}
          <br></br>- {lclString('rcs_business_explainer_detail_5_generic')}
          <br></br>
          <br></br>
          {lclString('rcs_business_explainer_detail_5')}
        </div>
      );
    }
  }
  toMap() {
    var map = {};
    map['id'] = this.id;
    map['active'] = this.active;
    map['price'] = Number(this.price);
    map['currency'] = this.currency;
    map['title'] = this.title;
    map['description'] = this.description;
    map['details'] = this.details;
    map['user_id'] = this.userId;
    map['show_date'] = this.showDate;
    map['require_request_description'] = this.requireRequestDescription;
    map['charge_on_booking'] = this.chargeOnBooking;
    map['show_date'] = this.showDate;
    map['post_purchase_response'] = this.postPurchaseResponse;
    if (this.lastUpdated) {
      map['last_updated'] = this.lastUpdated;
    }
    if (this.createdOn) {
      map['created_on'] = this.createdOn;
    }

    map['transaction_id'] = this.transactionId;

    return map;
  }

  static fromJSON(json) {
    try {
      const bookingItem = new BookingItem();
      bookingItem.id = json.id;
      bookingItem.active = Boolean(json.active);
      bookingItem.removed = json.removed ? json.removed : false;
      bookingItem.price = Number(json.price);
      bookingItem.currency = json.currency
        ? json.currency
        : bookingItem.currency;
      bookingItem.title = json.title;
      bookingItem.description = json.description;
      bookingItem.details = json.details ? json.details : bookingItem.details;
      bookingItem.userId = json.user_id;
      bookingItem.lastUpdated = json.last_updated
        ? json.last_updated
        : bookingItem.lastUpdated;
      bookingItem.showDate = json.show_date ? json.show_date : false;
      bookingItem.requireRequestDescription =
        json.require_request_description ?? false;
      bookingItem.chargeOnBooking = json.charge_on_booking ?? false;
      bookingItem.transactionId = json.transaction_id;
      bookingItem.postPurchaseResponse = json.post_purchase_response;
      return bookingItem;
    } catch (error) {
      return null;
    }
  }
}

export default BookingItem;
