import IconSVGPlayButton from '../images/icon_svg_play_button.svg';
import IconSVGBusinessButton from '../images/icon_svg_suitcase.svg';
import IconSVGSubscribersButton from '../images/icon_svg_subscribers.svg';
import IconSVGVerifiedCheckmark from '../images/verified_checkmark.svg';
import IconSVGCauseHeart from '../images/cause_heart.svg';
import IconSVGAppStoreTransparent from '../images/transparent_appstore.png';
import IconSVGGoogleStoreTransparent from '../images/transparent_playstore.png';
import IconSVGCloseBlack from '../images/icon_close.svg';
import IconSVGCloseWhite from '../assets/icons/icon_close_white.svg';

import IconSVGShareNormal from '../images/share_simple.svg';
import IconSVGShareArrow from '../Profile/Images/Share.svg';

//Benefits
import IconBenefitAsk from '../images/benefit_icon_ask.svg';
import IconBenefitRequest from '../images/benefit_icon_request.svg';
import IconBenefitVideo from '../images/benefit_icon_video.svg';
import IconBenefitViews from '../images/benefit_icon_views.svg';
import IconBenefitBuisness from '../images/icon_benefits_business.svg';
import IconBenefitContent from '../images/icon_benefits_video.svg';
import IconBenefitZoom from '../images/icon_benefits_zoom.svg';

//App Logo
import LogoMainWhite from '../assets/logo/main_logo_white.svg';

//Profile Tips
//#1
import ProfileTipAddTitle from '../EditProfile/Images/ProfileTips/profile_tip_title.svg';
import ProfileTipAddTags from '../EditProfile/Images/ProfileTips/profile_tip_tags.svg';
import ProfileTipSubscription from '../EditProfile/Images/ProfileTips/profile_tip_subscription.svg';
//#1
import ProfileTipSocials from '../EditProfile/Images/ProfileTips/profile_tip_socials.svg';
import ProfileTipProfileVideo from '../EditProfile/Images/ProfileTips/profile_tip_profile_video.svg';
import ProfileTipProfileImage from '../EditProfile/Images/ProfileTips/profile_tip_profile_picture.svg';
//#3
import ProfileTipPersonalRequests from '../EditProfile/Images/ProfileTips/profile_tip_personal_requests.svg';
import ProfileTipBusinessRequests from '../EditProfile/Images/ProfileTips/profile_tip_business_requests.svg';
import ProfileTipCharity from '../EditProfile/Images/ProfileTips/profile_tip_charity.svg';
//Paywall
import IconPaywallBirthday from '../images/paywall/icon_birthday.svg';
import IconPaywallDiscounts from '../images/paywall/icon_discounts.svg';
import IconPaywallStar from '../images/paywall/icon_star.svg';
import IconPaywallVideo from '../images/paywall/icon_video.svg';
import IconPaywallZoom from '../images/paywall/icon_zoom.svg';
import IconPaywallGeneric from '../images/paywall/icon_generic_benefit.svg';

//Ocassions
import Requests_1 from '../Profile/Images/Requests_1.svg';
import Requests_2 from '../Profile/Images/Requests_2.svg';
import Requests_3 from '../Profile/Images/Requests_3.svg';
import Requests_4 from '../Profile/Images/Requests_4.svg';
import Requests_5 from '../Profile/Images/Requests_5.svg';
import Requests_6 from '../Profile/Images/Requests_6.svg';

//Ocassions - New
import ocassion_icon_ask from '../components/pages/Campaigns/CampaignBooking/Ocassions/images/icon_ask.png';
import ocassion_icon_birthday from '../components/pages/Campaigns/CampaignBooking/Ocassions/images/icon_birthday.png';
import ocassion_icon_testimonial from '../components/pages/Campaigns/CampaignBooking/Ocassions/images/icon_testimonial.png';
import ocassion_icon_event from '../components/pages/Campaigns/CampaignBooking/Ocassions/images/icon_event.png';
import ocassion_icon_other from '../components/pages/Campaigns/CampaignBooking/Ocassions/images/icon_other.png';
import ocassion_icon_product_placement from '../components/pages/Campaigns/CampaignBooking/Ocassions/images/icon_product_placement.png';
import ocassion_icon_promotional from '../components/pages/Campaigns/CampaignBooking/Ocassions/images/icon_promotional.png';
import ocassion_icon_rocket from '../components/pages/Campaigns/CampaignBooking/Ocassions/images/icon_rocket.png';
import ocassion_icon_collab from '../components/pages/Campaigns/CampaignBooking/Ocassions/images/icon_shout_out.png';
import ocassion_icon_thumbs_up from '../components/pages/Campaigns/CampaignBooking/Ocassions/images/icon_thumbs_up.png';

//Promotions
import IconPromoCode from '../images/promo_code.svg';

//Booking ocassions
export const OcassionImageQuestion = ocassion_icon_ask;
export const OcassionImageMotivation = ocassion_icon_thumbs_up;
export const OcassionImageEvent = ocassion_icon_event;
export const OcassionImageAdvice = ocassion_icon_rocket;
export const OcassionImageBirthday = ocassion_icon_birthday;
export const OcassionImageOther = ocassion_icon_other;

export const OcassionImageProductPlacement = ocassion_icon_product_placement;
export const OcassionImagePromotional = ocassion_icon_promotional;
export const OcassionImageCollaboration = ocassion_icon_collab;
export const OcassionImageTestimonial = ocassion_icon_testimonial;

// Profile action buttons
export const vlImagePlayActionImage = IconSVGPlayButton;
export const vlImageBusinessActionImage = IconSVGBusinessButton;
export const vlImageSubscriptionActionImage = IconSVGSubscribersButton;
export const vlImageVerifiedCheckmarkImage = IconSVGVerifiedCheckmark;
export const vlImageCauseHeart = IconSVGCauseHeart;
export const vlImageAppStoreTransparent = IconSVGAppStoreTransparent;
export const vlImagePlayStoreTransparent = IconSVGGoogleStoreTransparent;

//General
export const vlImageShareNormal = IconSVGShareNormal;
export const vlImageShareArrow = IconSVGShareArrow;
export const vlImageGenericCloseBlack = IconSVGCloseBlack;
export const vlImageGenericCloseWhite = IconSVGCloseWhite;

export const vlRandomImage = 'https://picsum.photos/200/300';

//Benefits
export const vlImageBenefitAsk = IconBenefitAsk;
export const vlImageBenefitRequest = IconBenefitRequest;
export const vlImageBenefitVideo = IconBenefitVideo;
export const vlImageBenefitViews = IconBenefitViews;
export const vlImageBenefitBuisness = IconBenefitBuisness;
export const vlImageBenefitContent = IconBenefitContent;
export const vlImageBenefitZoom = IconBenefitZoom;

//Tips
//#1
export const vlImageTipAddTitle = ProfileTipAddTitle;
export const vlImageTipAddTags = ProfileTipAddTags;
export const vlImageTipSubscription = ProfileTipSubscription;
//#2
export const vlImageTipProfileVideo = ProfileTipProfileVideo;
export const vlImageTipProfileImage = ProfileTipProfileImage;
export const vlImageTipPersonalRequests = ProfileTipPersonalRequests;
//#3
export const vlImageTipBusinessRequests = ProfileTipBusinessRequests;
export const vlImageTipCharity = ProfileTipCharity;
export const vlImageTipSocials = ProfileTipSocials;
//Paywall
export const vlImagePaywallBirthday = IconPaywallBirthday;
export const vlImagePaywallDiscounts = IconPaywallDiscounts;
export const vlImagePaywallStar = IconPaywallStar;
export const vlImagePaywallVideo = IconPaywallVideo;
export const vlImagePaywallZoom = IconPaywallZoom;
export const vlImagePaywallGenericBenefit = IconPaywallGeneric;
//Promo code
export const vlImageCoupon = IconPromoCode;
//Logos
export const vlAppLogoMainWhite = LogoMainWhite;
