import { getI18n } from 'react-i18next';
import * as priceUtils from './priceUtils';

export function parse(str) {
  if (str == null) {
    return '';
  }
  // console.log("parse = ", str);
  var args = [].slice.call(arguments, 1),
    i = 0;

  return str.replace(/%s/g, () => args[i++]);
}

export function parseStringFormat(str) {
  return parse(str);
}

export function lclString(key) {
  if (isHebrew()) {
    return hebrew[key];
  } else {
    return english[key];
  }
}
export function isHebrew() {
  //return isHebrewLanguageSaved();
  return getI18n().language === 'he';
}

export function dateLocale() {
  return isHebrew() ? 'he-IL' : 'en-US';
}

export function updateBrowserLanguage(language) {
  // console.log("updateBrowserLanguage =", language);
  if (isHebrewLanguageSaved()) {
    return;
  }
  let local = localStorage.getItem('cr8:lang');
  var isHeb = false;
  if (local) {
    isHeb = local === 'he';
  } else {
    isHeb = language != null && language.toLowerCase().includes('he');
  }
  updateUserLanauge(isHeb);
}

export function isBrowserLanguageHebrew(language) {
  return language.toLowerCase().includes('he');
}

/**
 * Converts the value to a social string (1,123 = > 1.23k, etc)
 * @param {*} value The value to convert
 * @param {*} extensionOnly True if just want extension (k instead of 1.23k)
 * @param {*} numberOnly True if want just number (1234 instead of 1.2k)
 */
export function toSocialString(
  value,
  extensionOnly = false,
  numberOnly = false
) {
  let number = Number(value);
  let thousands = number / 1000;
  if (thousands < 1) {
    return numberOnly ? number : extensionOnly ? '' : String(number);
  } else if (thousands < 1000) {
    return numberOnly
      ? priceUtils.round(thousands, 1)
      : extensionOnly
      ? 'k'
      : String(priceUtils.round(thousands, 1)) + 'k';
  } else {
    thousands = thousands / 1000;
    return numberOnly
      ? thousands
      : extensionOnly
      ? 'm'
      : String(thousands) + 'm';
  }
}

export function updateUserLanauge(toHebrew) {
  // console.log("updateUserLanauge =", toHebrew);
  localStorage.setItem('cr8:lang', toHebrew ? 'he' : 'en');
}

export function isHebrewLanguageSaved() {
  return localStorage.getItem('cr8:lang') === 'he';
}

export function lclTextAlignmentClass() {
  return isHebrew() ? 'text-right' : 'text-left';
}

export function lclDirection() {
  return isHebrew() ? 'rtl' : 'ltr';
}

export function flippedHorizImage() {
  return isHebrew() ? 'flip-image-horiz' : '';
}

export function lclFlexRow() {
  return isHebrew() ? 'flex-row-reverse' : 'flex-row';
}

export function lclFlexRightFill() {
  return isHebrew() ? 'mr-auto' : 'ml-auto';
}

export function flexWrapAlignment() {
  return isHebrew() ? 'justify-content-end' : 'justify-content-start';
}

export function lclMarginRight(spacing) {
  return isHebrew() ? `ml-${spacing}` : `mr-${spacing}`;
}

export function lclMarginLeft(spacing) {
  return isHebrew() ? `mr-${spacing}` : `ml-${spacing}`;
}

export function lclCurrencyCode() {
  return isHebrew() ? 'ILS' : 'USD';
}

export function codeFromCurrency(currency) {
  // utils.log.debug("wierd..", currency);
  return currency === 'ILS' ? '₪' : '$';
}

export function nameFromCurrency(currency) {
  // utils.log.debug("wierd..", currency);
  return currency === 'ILS'
    ? lclString('currency_name_ils')
    : lclString('currency_name_usd');
}

const hebrew = {
  jumbo_title: 'בואו לעשות למישהו את היום',
  booking_request_success: 'הבקשה נשלחה בהצלחה',
  success: 'הסתיים בהצלחה',
  free: 'חינם',
  operation_completed_success: 'הבקשה שלכם הושלמה בהצלחה',
  membership_confirmed_title: 'הצטרפת בהצלחה',
  membership_confirmed_detail: '',
  back_to_create: ' KRE8.TV ל',
  auth_blocker_modal_title: 'אנחנו צריכים לבדוק שאתה בן אדם אמיתי קודם!',
  complete_purchase: 'השלמת רכישה',
  enter_number_log_in: 'הכניסו את המספר טלפון שהתחברתם איתו',
  enter_number_sign_up: 'תקבלו קוד אימות בעל 6 ספרות לנייד לצורך אימות',
  log_in: 'יצרתי כבר משתמש',
  sign_up: 'אין לי עדיין משתמש',
  confirm_sign_up: 'המשך',
  send_verification_code: 'שליחת קוד אימות',
  confirm_code: 'אימות קוד',
  enter_6_digits: 'הכניסו את הקוד בעל 6 ספרות שקיבלתם למכשירכם',
  error_title: 'שגיאה',
  button_ok: 'אישור',
  button_actions: 'פעולות',
  view_session_purchase_button: 'צפייה בלעדית ב %s',
  actually_purchase_session_button: 'רכשו צפייה בלעדית בלבד ב %s',
  purchased_sessions: 'תכנים שנרכשו',
  payment_methods: 'אמצעי תשלום',
  subscriptions: 'המנויים שלי',
  no_sessions_yet: 'אין עדיים תכנים...',
  no_subs_yet: 'אין עדיין מנויים....',
  error_card_declined: 'הכרטיס נדחה. אנא נסו שנית עם כרטיס אחר, תודה!',
  couldnt_auth_user: 'לא הצלחנו לאמת את המשתמש. אנא נסו שנית',
  log_out: 'התנתקות',
  back: 'חזרה',
  send_me_request: 'הזמנת סרטון אישי',
  send_request_price: 'הזמנת סרטון ב%s',
  send_business_request_price: 'הזמנת סרטון עסקי ב%s',
  request_charge_subtitle:
    'תבוצע הפקדה זמנית וחיוב סופי יתבצע רק לאחר קבלת הסרטון',
  personal_video_request_modal_title: 'חדשות טובות! הבקשה נשלחה בהצלחה ל%s',
  personal_video_request_modal_detail:
    'אם היוצר/ת לא עונה לך תוך 7 ימים, החיוב יבוטל יחד עם הבקשה בצורה אוטומטית ולא יגבה תשלום',
  personal_video_request_modal_subtitle:
    'תוכלו לבדוק לגבי הסטטוס של הבקשה שלכם ומתי היא מוכנה דרך עמוד ה״פעילות״ שלכם',
  download_on_ios: 'להורדה לאייפון',
  download_on_android: 'להורדה לאנדרויד',
  complete_request: 'השלמת שליחה',
  confirm_request_for: 'אישור הזמנת סרטון ב %s',
  personalized_video_request_paywall_info_first:
    'תבוצע הפקדה זמנית וחיוב סופי יתבצע רק לאחר קבלת הסרטון',
  personalized_video_request_paywall_info_second:
    'אם הוא/היא לא מגיבים תוך 7 ימים, הבקשה תבוטל אוטומטית ולא יגבה תשלום',
  send_a_request: 'הזמנת סרטון',
  send_a_biz_request: 'הזמנת סרטון עסקי',
  rcs_who_for: 'עבור מי הבקשה',
  rcs_your_name: 'מה השם שלך?',
  rcs_their_name: 'מה השם שלהם?',
  rcs_request_desc: 'תיאור הבקשה',
  rcs_myself: 'בשבילי',
  rcs_someone_else: 'מישהו אחר',
  chars_left: '%s אותיות נותרו לשליחת הבקשה',
  enter_your_name: 'הכניסו את שמכם',
  enter_their_name: 'הכניסו את שמם',
  rcs_desc_placeholder:
    'תרשמו את הבקשה שלכם כאן?\n\nזה יכול להיות התייעצות בנושא מסוים, טיפ למקרה ספציפי, מידע על משהו שהייתם רוצים לדעת, או אפילו סרטון למטרות בידור',
  rcs_desc_placeholder_tip:
    'אם אתם מרוצים מהסרטון, כתבו משהו חיובי כדי שהיוצר ידע! אם לא, כתבו כמה איזורים שבהם יוכלו לשפר להבא',
  add_card_bottom_subtitle:
    'על מנת לשמור על בטיחותכם, אנו משתמשים במערכת סליקה ביןלאומית של Stripe ואף פעם לא שומרים את פרטי האשראי המלאים שלכם',
  add_card: 'הוספת כרטיס',
  add_payment_method: 'הוספת אמצעי תשלום',
  purchase_session_for: 'רכישת תוכן לצפייה בלעדית ב%s',
  profile_tab_title: 'הפרופיל שלי',
  tab_home: 'בית',
  tab_profile: 'פרופיל',
  close_circle: 'המעגל הקרוב שלי',
  cf_pv_discount_title: '50% הנחה על סרטונים אישיים',
  cf_pv_discount_detail:
    'תוכלו לשלוח בקשות לסרטונים אישיים ב50% הנחה מהמחירון הרגיל של יוצר',
  cf_sessions_title: 'צפייה בלתי מוגבלת בתוכן בלעדי',
  cf_sessions_detail:
    'תוכן בהזמנה בלעדית כמו כיתות-אמן, וובינרים, קטעי מאחורי הקלעים בבלעדיות, ועוד תוכן שיתן לכם ערך',
  cf_thoughts_title: 'מחשבות - קבוצת צ׳אט פרטית עם היוצר (בקרוב)',
  cf_thoughts_detail:
    ' גישה אקסקלוסיבית לעמוד ה״מחשבות״ של היוצר, שהוא מעין קבוצת צ׳אט פרטית עם היוצר בו תוכלו לדבר ולהיחשף לתוכן אותנטי בצורה רציפה ונקייה',
  close_circle_subscription_action_title: 'הצטרפו למעגל הקרוב שלי ב %s לחודש',
  close_circle_sub_not_active: 'ההרשמה למעגל הקרוב עדיין לא פתוחה',
  booking_not_available: 'לא זמין לבקשות כרגע',
  create_session_page_title: 'יצירת סשן חדש',
  create_session_input_title: 'כותרת',
  create_session_input_title_placeholder: 'תיאור קצר של התוכן שלך',
  create_session_input_desc: 'הסבר',
  create_session_input_desc_placeholder:
    'הסבר יותר מורחב על על התוכן שהם עומדים לרכוש',
  create_session_input_price: 'מחיר לרכישה',
  create_session_input_preview_video: 'סרטון מקדים',
  create_session_input_submission_video: 'העלאת קובץ',
  create_session_action_button: 'סיום ושיתוף קישור',
  cs_preview_file_input_placeholder: 'סרטון תצוגה מקדימה - לא חובה',
  cs_submission_file_input_placeholder: 'הסרטון המלא שאנשים ירכשו',
  cs_upload_preview_rec: '(מוצג בצורה הטובה ביותר באנכי)',
  submitting: 'מסיים בקשה',
  uploading: 'מעלה תכנים',
  session_dashboard_title: 'נתוני הסשן שלי',
  session_purchases: 'רכישות',
  no_purchases_yet: 'אין עדיין רכישות...',
  share_and_earn: 'שתפו את הקישור ותתחילו להרוויח',
  total_earnings: 'סה״כ רווחים:',
  create_session_header_title: 'תיצרו, תעלו, ותרוויחו',
  create_session_header_subtitle:
    'תוך 30 שניות תוכלו לעלות את התוכן שלכם ולהתחיל להרוויח את מה שמגיע לכם',
  set_a_price: 'מחיר לרכישה',
  browse: 'עיון',
  session_url_copied: 'הקישור הועתק בהצלחה',
  enter_phone_number_placeholder: 'הכנסו מספר טלפון',
  enter_code_placeholder: 'הכנסו את הקוד שהתקבל',
  first_name_placeholder: 'שם פרטי',
  last_name_placeholder: 'שם משפחה',
  email_placeholder: 'הכנסת דוא״ל',
  error_session_preview_file_too_big:
    'הקובץ שבחרת גדול מדי. תצוגות מקדימות לא יכולות להיות יותר מ%s מגהבייט. אנא בחרו קובץ אחר ונסו שנית',
  error_session_submission_file_too_big:
    'הקובץ שבחרת גדול מדי. קובץ הסרטון של הסשן לא יכולות להיות יותר מ%s מגהבייט. אנא בחרו קובץ אחר ונסו שנית',
  set_your_price_here: '(קבעו מחיר כאן (לדוג׳ 5 ש״ח',
  tab_create_session: 'חדש',
  terms_of_service: 'תנאי שימוש',
  privacy_policy: 'מדיניות פרטיות',
  agree_to_terms_signup:
    'בלחיצה על על ״המשך״ אתה מאשרים את תנאי השימוש של KRE8.TV (בגדול אנחנו רוצים ליצור עולם טוב יותר דרך תוכן, אבל מוזמנים לקרוא אם ממש משעמם לכם)',
  first_name: 'שם פרטי',
  last_name: 'שם משפחה',
  email: 'דוא״ל',
  fill_in_your_details: 'אנא מלאו את הפרטים הבסיסיים',
  tab_activity: 'פעילות',
  empty_activity_placeholer: 'כאן תוכלו לראות  את כל התוכן שביקשתם או העלתם',
  for_someone_else_request_label: 'הוזמן מ%s ל%s',
  for_myself_request_label: 'הוזמן ל%s',
  booking_status_pending: 'ממתין להעלאה',
  booking_status_declined: 'נדחה',
  booking_status_accepted: 'אושר',
  booking_status_delivered: 'נשלח',
  booking_status_cancelled: 'בוטל',
  booking_status_awaiting_payment: 'ממתין לתשלום',
  booking_status_expired: 'פג תוקף',
  booking_status_archived: 'ארכיון',
  booking_status_long_pending:
    'הבקשה נשלחה אל היוצר בהצלחה וממתינה לביצוע היצירה שלו 🤳🏻',
  booking_status_long_declined: 'הבקשה שלכם לא אושרה',
  booking_status_long_accepted: 'הבקשה שלכם אושרה',
  booking_status_long_delivered: 'הבקשה שלכם מוכנה! לחצו עכשיו כדי לצפות 🎁',
  booking_status_long_cancelled: 'הבקשה שלכם בוטלה',
  booking_status_long_awaiting_payment: 'הבקשה שלכם ממתינה לתשלום',
  booking_status_long_expired: 'הבקשה שלכם פגה תוקף ולכן בוטלה אוטומטית',
  booking_status_long_archived: 'הבקשה שלכם הועברה לארכיון',
  great_thanks: 'מגניב, תודה!',
  booking_not_available_business: 'זמנית לא פנוי/ה לבקשות עסקיות',
  rcs_business_name: 'שם העסק',
  rcs_your_name_biz: 'מה שם העסק?',
  rcs_desc_placeholder_biz: 'מה הייתם רוצים שיעשו או יאמרו',
  rcs_desc_placeholder_biz_2:
    'זה יכול להיות סרטון פנים ארגוני לשמח את הקולגות, סרטון למטרות שיווק של העסק שלכם, או לכל מטרה אחרת שלא מיועדת לשימוש אישי',
  rcs_desc_placeholder_biz_3:
    'תשתדלו לתת כמה שיותר פרטים שיהיה ליוצר עם מה לעבוד!',
  rcs_desc_placeholder_helper:
    '📢 חשוב! במידה ואתם מכינים ליוצר טקסט שיקריא, נסו להכין לו את זה כמה שיותר נוח לקריאה ומתומצת ככל שאפשר. ככה אתם תהיו יותר מרוצים מהתוצאה הסופית',
  rcs_business_explainer_title: 'איך זה עובד?',
  rcs_business_explainer_detail_1:
    'ברגע שתשלחו בקשה ליוצר, היא תגיע אליו/ה ויהיה להם עד שבוע (בדרך כלל זה באותו יום או למחרת) לענות וליצור לכם סרטון עסקי או שיווקי בהזמנה',
  rcs_business_explainer_detail_2:
    'במידה ויש צורך, ניצור עמכם קשר על מנת לוודא את תכולת הבקשה. אם בכל זאת תרצו שניצור קשר, תוכלו לציין זאת בתיאור הבקשה',
  rcs_business_explainer_detail_3:
    'עם סיום ההזמנה יתבצע הפקדה זמנית בסכום הבקשה אך החיוב הסופי מתבצע רק **לאחר** קבלת הסרטון',
  rcs_business_explainer_detail_4:
    'ברגע שהסרטון שלכם מוכן, תקבלו התראה לחשבון שלכם ותוכלו לצפות ולהוריד את הסרטון ישירות מהאתר',
  rcs_business_explainer_detail_5:
    'אנחנו ממליצים להוריד את האפלקיציה שלנו כדי שתוכלו לקבל גם עדכונים לגבי סטטוס ההזמנה שלכם',
  rcs_business_explainer_detail_6:
    'לכל שאלה נוספת מוזמנים לשלוח לנו בקשה אישית לחשבון KRE8.TV או לפנות למייל שלנו contact@kre8.tv',
  biz_must_connect_to_see_price: 'לחצו כדי להתחבר ולשלוח את ההזמנה',
  upload_video_to_send: 'בחרו את הסרטון לעלות',
  request_click_to_upload_video:
    'הבקשה מחכה לכם! לחצו עכשיו לעלות ולשלוח את הסרטון 🚨',
  submit_video: 'העלאת סרטון',
  uploading_file: 'מעלה את הקובץ... קצת סבלנות',
  click_to_upload: 'לחצו כדי לעלות את הקובץ!',
  choose_file_first: 'בחרו את הקובץ לעלות',
  upload_success_tap_to_close: 'הסרטון נשלח בהצלחה - לחצו לסגירת המסך',
  response_time_hours: '%s שעות',
  response_time_days: '%s ימים',
  avg_response_time: 'זמן תגובה ממוצע',
  avg_rating: '%s דירוגים',
  profile_request_personalized_video: 'הזמנת סרטון אישי',
  profile_request_business_video: 'הזמנת סרטון לעסק/ארגון',
  request_success_download_app_first: 'לקבלת התראות ועדכונים על ההזמנה שלכם ',
  request_success_download_app_second:
    'ניתן להוריד את האפליקציה ולהתחבר מחדש עם אותו מספר שהתחברתם באתר - כל ההזמנות מסונכרנות',
  download_our_apps_2: '',
  download_our_apps_1: 'גלו עוד יוצרים באפליקציות שלנו',
  rcs_request_price_biz_title: 'הצעת מחיר ב%s',
  rcs_request_price_biz_placeholder: '%s מינימום (%s)',
  send_business_request_bid: 'שליחת הצעה ליוצר',
  request_charge_subtitle_business:
    'לא יתבצע חיוב אלא רק הפקדה זמנית על סכום ההצעה',
  admin_requests: 'בקשות',
  admin_graphs: 'גרפים',
  admin_users: 'משתמשים',
  admin_payouts: 'תשלומים',
  admin_active_creators: 'יוצרים פעילים',
  admin_recently_connected: 'התחברו לאחרונה',
  admin_completed_creators: 'סיימו ליצור פרופיל יוצרים',
  admin_new_users: 'משתמשים חדשים',
  admin_asking_price: 'מחיר נוכחי',
  admin_videos_made: 'סה״כ סרטונים שהוכנו',
  admin_money_earned: 'סה״כ הכנסות',
  admin_last_seen: 'נראה לאחרונה',
  admin_joined: 'תאריך הצטרפות',
  admin_active_creators_detail:
    '%s  סרטונים ממוצע ליוצר (מעל 3 סרטונים מינימום)',
  admin_bookings: 'סרטונים',
  admin_creators: 'יוצרים',
  edit_profile_tab_title: 'עריכת פרופיל',
  save_profile: 'שמור שינויים',
  error_saving_profile: 'שגיאה בעדכון הפרופיל',
  saved_profile_success: 'הפרופיל שלך עודכן בהצלחה',
  updating_profile: 'מעדכן את הפרופיל שלך',
  edit_profile_username: 'שם משתמש',
  edit_profile_title: 'תיאור קצר',
  edit_profile_description: 'תיאור ארוך',
  edit_profile_price_personal: 'סרטונים אישיים',
  edit_profile_price_business: 'סרטונים עסקיים',
  edit_profile_price_personal_placeholder: 'מחיר לסרטון',
  edit_profile_price_business_placeholder: 'מינימום הצעת מחיר',
  edit_profile_categories: 'קטגוריות',
  edit_button: 'עריכה',
  edit_profile_tags: 'תגיות',
  manager: 'מנהל',
  create_new_user: 'יצירת משתמש חדש חדש',
  userId_placeholder: 'שם משתמש נגיד roy_temp',
  submit_and_create: 'שליחה ויצירת משתמש',
  new_user_created_success: 'המשתמש נוצר בהצלחה!',
  close: 'סגירה',
  featured_active: 'מופיע',
  featured_not_active: 'לא מופיע',
  edit_profile_featured: 'מוצג בעמוד הראשי',
  edit_profile_manager_id: 'מזהה מנהל',
  edit_profile_agency_id: 'מזהה סוכנות',
  edit_profile_referral_id: 'מזהה מפנה / רפרל',
  edit_profile_currency: 'מטבע',
  ils: 'ש״ח ₪',
  usd: 'דולר $',
  admin_affiliates: 'אפיליציה',
  admin_posts: 'פוסטים',
  personal_url: 'קישור הזמנה אישית',
  business_url: 'קישור הזמנה עסקית',
  brands_that_work_with_us: 'מותגים שכבר עובדים איתנו',
  picker_select_date_and_time: 'בחרו תאריך ושעה',
  booking_background_title: 'פירוט והוספת רקע ליוצר',
  date_and_time: 'תאריך ושעה',
  rcs_desc_placeholder_generic_booking_item:
    'אנא תנו קצת פרטי רקע לגבי ההזמנה שלכם כדי שהיוצר יוכל להתכונן בהתאם ולהכיר את הקהל שלו בצורה הכי טובה\n\nפרטים למשל, כמות משתתפים מוערכת, גיליאים, תחביבים, וכו׳',
  rcs_desc_placeholder_generic_booking_item_2:
    '📢 חשוב - במידה והזמנתם את היוצר להצטרף לזום, אנא הוסיפו קישור לפגישת הזום שבו תרצו שהיוצר יצטרף אליה בתאריך שציינתם. במידה ויש סיסמה, אנא וודאו שכללתם גם אותה',
  request_charge_subtitle_booking_generic:
    'תבוצע הפקדה זמנית וחיוב סופי יתבצע רק לאחר אישור סופי של ההזמנה',
  rcs_business_explainer_detail_2_generic:
    'תשתדלו לשלוח את הבקשה כמה שיותר לפני התאריך המיועד בכדי שיהיה ליוצר מספיק זמן מראש לאשר/לדחות אותה',
  rcs_business_explainer_detail_3_generic:
    'עם סיום ההזמנה תתבצע הפקדה זמנית בסכום הבקשה אך החיוב הסופי מתבצע רק **לאחר** אישור ההזמנה על ידי היוצר',
  rcs_business_explainer_detail_4_generic:
    'במידה והבקשה לא תאושר מסיבה כזאת או אחרת, ההפקדה שלכם תמחק אוטומטית כעבור מספר ימי עבודה',
  rcs_business_explainer_detail_5_generic:
    'במידה ויש לכם שאלות, תרגישו חופשי תמיד לפנות אלינו ב contact@kre8.tv',
  send_booking_item_request_price: 'שליחת הזמנה ב%s',
  creatorId_placeholder: 'מזדהה משתמש',
  request_creation_bid_helper_label:
    'אם תרצו שהסרטון שלכם יהיה מוכן בהקדם האפשרי, או שהיוצר יתן אקסטרה מאמץ, תוכלו להציע מחיר גבוה יותר ממחירו הבסיסי של היוצר',
  order_me_on_zoom: 'הזמן אותי לזום!',
  date_picker_time: 'מתי?',
  generic_booking_item_request_paywall_info_first:
    'תבוצע הפקדה זמנית וחיוב סופי יתבצע רק לאחר אישור ההזמנה על ידי היוצר',
  generic_booking_item_request_paywall_info_second:
    'אם הוא/היא לא מגיבים תוך 7 ימים, ההזמנה תבוטל אוטומטית ולא יגבה תשלום',
  edit_profile_additional_booking_items: 'פרטי הזמנה נוספים',
  add_new_booking_item: 'הוספת פריט הזמנה חדש +',
  booking_item_create_modal_title: 'יצירת פריט הזמנה חדש',
  booking_item_update_modal_title: 'עדכון פריט הזמנה',
  booking_item_title_placeholder: 'כותרת - מופע זום יחודי',
  booking_item_details_placeholder:
    'פרטים חשובים - לדוג׳ משך זמן, מה זה כולל..',
  booking_item_description_placeholder:
    'תיאור - תזמינו אותי להצטרף אליכם לזום ותקבלו...',
  booking_item_price_placeholder: 'מחיר מינימלי',
  booking_item_modal_submit_button: 'שליחה ויצירת פריט',
  booking_item_updated_success: 'הפריט עודכן בהצלחה',
  booking_item_created_success: 'הפריט נוצר בהצלחה',
  additional_details: 'פרטים נוספים:',
  request_booking_item: 'שליחת הזמנה',
  request_booking_item_bid_helper_label:
    'אם אתם לחוצים מבחינת זמן ורוצים שההזמנה שלכם תאושר בהקדם, או שהיוצר יתן עוד אקסטרה מאמץ, תוכלו להציע מחיר גבוה יותר ממחירו הבסיסי של היוצר',
  additional_booking_items: 'אפשרויות הזמנה נוספות',
  date_to_deliver_request: 'תאריך לביצוע: %s',
  edit_profile_external_links: 'קישורים חיצוניים',
  edit_profile_custom_url: 'קישור לאתר בית שלי',
  edit_profile_instagram_url: 'קישור לעמוד אינסטגרם',
  edit_profile_facebook_url: 'קישור לעמוד פייסבוק',
  edit_profile_youtube_url: 'קישור לעמוד יוטיוב',
  edit_profile_twitter_url: 'קישור לעמוד טוויטר',
  edit_profile_tiktok_url: 'קישור לעמוד טיקטוק',
  create_new_payout: 'יצירת תשלום יוצרים חדש',
  edit_booking_item_title: 'כותרת',
  edit_booking_item_description: 'תיאור',
  edit_booking_item_details: 'פרטים',
  edit_booking_item_price: 'מחיר בסיס',
  book_me: 'הזמינו עוד דברים',
  book_me_on_zoom: 'הזמינו שיחת ZOOM',
  edit_booking_item_scheduled_booking: 'זימון מתואם מראש',
  edit_booking_item_scheduled_booking_title: 'חייב קביעת תאריך ושעה להזמנה',
  request_booking_item_placeholder_leave_message: 'כתבו הודעה אישית ליוצר',
  session_paywall_alert_message:
    'על מנת לצפות בתוכן המלא, עליכם לרכוש אותו ב%s. אם זה משהו שישמח, יחזק, או יחכים אותך, כנראה שזה שווה את זה - תשקיעו בעצמכם!\n\nרכישת התוכן תיתן לכם גישה וצפייה בלעדית אליו בכל הפלטפורמות, תמיד.\n\nלחצו אישור על מנת להמשיך בתהליך הרכישה',
  session_paywall_alert_message_short:
    'על מנת לצפות בתוכן המלא, עליכם לרכוש אותו ב%s. אם זה משהו שישמח, יחזק, או יחכים אותך, כנראה שזה שווה את זה - תשקיעו בעצמכם!\n\n.רכישת התוכן תיתן לכם גישה וצפייה בלעדית אליו בכל הפלטפורמות, תמיד',
  edit_booking_item_require_description: 'חייב הכנסת תיאור בבקשה',
  edit_booking_item_charge_on_booking: 'חיוב אוטומטתי לאחר הזמנה',
  edit_booking_item_additional_options: 'הגדרות נוספות',
  edit_booking_response_text: 'הודעה קצרה אחרי רכישה (לא חובה)',
  edit_booking_response_text_placeholder:
    'רשמו הודעה קצרה לאחר רכישה שתופיע למשתמשים',
  checkout_success_title: 'אמצעי תשלום התווסף בהצלחה',
  checkout_success_detail:
    'אתם יכולים לסגור את המסך הזה ולחזור לאפליקציה על מנת להשלים את הרכישה',
  checkout_personalized_on_demand_label:
    'עוד שנייה סיימתם! עליכם להוסיף אמצעי תשלום על מנת לבצע הזמנה לסרטון אישי',
  checkout_personalized_on_demand_biz_label:
    'עוד שנייה סיימתם! עליכם להוסיף אמצעי תשלום על מנת לבצע הזמנה לסרטון עסקי',
  checkout_session_label:
    'עוד שנייה סיימתם! עליכם להוסיף אמצעי תשלום על מנת לרכוש את התוכן לצפייה בלעדית',
  checkout_personalized_on_demand_close_circle_label:
    'עוד שנייה סיימתם! עליכם להוסיף אמצעי תשלום על מנת להצטרף למעגל הקרוב של היוצר',
  profile_join_close_circle: 'הצטרפו למעגל הקרוב שלי',
  profile_close_circle_already_subscribed: 'אתם מנויים למעגל הקרוב שלי',
  business_request: 'בקשה עסקית',
  profile_screen_you_are_already_subscribed_title: 'אתם כבר מנויים',
  profile_screen_you_are_already_subscribed_message:
    'כמנויים למעגל הקרוב של היוצר הזה, אתם מקבלים הנחה על הזמנת סרטונים אישיים, צפייה חינמית ובלתי מוגבלת לתכנים בתשלום, וגישה בלעדית לתוכן אמיתי ושוטף ששמור רק לקהילת המנויים',
  modal_alert_session_preview_ended_title: 'תצוגה מקדימה הסתיימה',
  modal_alert_session_preview_ended_message: `על מנת לצפות בתוכן המלא, עליכם לרכוש אותו ב%s. אם זה משהו שישמח, יחזק, או יחכים אותך, כנראה שזה שווה את זה - תשקיעו בעצמכם!
  רכישת התוכן תיתן לכם גישה וצפייה בלעדית אליו בכל הפלטפורמות, תמיד.
  לחצו אישור על מנת להמשיך בתהליך הרכישה`,
  session_upsell_close_circle_subscription:
    'עם זאת, תוכלו לצפות בכל התוכן הבלעדי בחינם וללא הגבלה אם תצטרפו ל״מעגל הקרוב״ של היוצר על ידי תשלום דמי של %s לחודש',
  close_circle_subscription_title: 'מנוי ״מעגל קרוב״',
  purchase_session: 'רכישת תוכן בלעדי',
  watch_exclusively_now: 'צפו עכשיו בבלעדיות',
  exclusive_access_available_soon: 'צפייה בלעדית תהיה זמינה בקרוב',
  session_watch_no_permissions_alert_title: 'אין אישור לצפייה',
  session_watch_no_permissions_alert_message:
    'עליכם לרכוש גישה לצפייה בלעדית בתוכן או להיות מנויים במעגל הקרוב של היוצר כדי לצפות',
  button_cancel: 'סגירה',
  cancel_subscription: 'ביטול מנוי',
  what_would_you_like_to_do: 'מה תרצו לעשות?',
  cancel_subscription_alert_message:
    'במידה ותחליטו לבטל את המנוי, גישתכם הבלעדית והנחות של מנויי ה״מעגל הקרוב״ גם יופסקו, ולא תחויבו יותר על דמי המנוי',
  edit_profile_verfied: 'משתמש מאומת',
  verified: 'מאומת',
  subscribers_only: 'צפייה בלעדית למנויים בלבד',
  post_subscribe_button: 'הצטרפות כמנוי',
  /* rating */
  rate_delivery_screen_title: 'השארת ביקורת',
  rate_delivery_screen_rating_detail: 'דרגו את החוויה שלכם',
  rate_delivery_screen_message_detail: 'הוסיפו הודעה אנונימית',
  rate_delivery_screen_submit_button: 'שליחת ביקורת',
  rate_delivery_screen_review_submitted_title: 'תודה רבה!',
  rate_delivery_screen_review_submitted_body: 'הביקורת נשלחה בהצלחה',
  submit_review_tip_error_alert_title: 'סכום טיפ לא תקין',
  submit_review_tip_error_message_title:
    'הסכום שמילאתם למתן הטיפ לא תקין וחייב להיות על סך %s או יותר. לחצו על ׳ביטול׳ לעדכן אותו ואז לשלוח, לחצו על ׳לשלוח בלי טיפ׳ אם ברצונכם לשלוח ללא טיפ.',
  submit_review_tip_error_submit_anyways: 'שליחה ללא טיפ',
  /* review tip */
  booking_request_tip: '%s טיפ',
  leave_a_review_tip_placeholder:
    'אהבתם את מה שהיוצר הכין לכם? תוכלו לתת להם ערך חזרה ולרשום להם ביקורת חיובית מפרגנת ואפילו להשאיר טיפ',
  option_or: 'או',
  leave_a_tip: 'השארת טיפ (%s)',
  tip_amount_placeholder: 'סכום הטיפ',
  tip_must_be_above: 'הטיפ המינימלי הוא %s',
  download_video: 'להורדת הסרטון',
  tip_string: '+ %s',
  account_tab_not_logged_in_placeholder: 'לחצו על מנת להתחבר',
  order_tiktok_video: 'רכישת תוכן לטיקטוק',
  order_business_video: 'הזמנת סרטון שיווקי',
  admin_subscriptions: 'מנויים',
  request_not_long_enough:
    'הבקשה שלכם קצרה מדי. תוסיפו עוד קצת עומק לבקשה שלכם ותנסו שוב',
  one_moment_alert_title: 'רק רגע!',
  promotion_modal_title: 'אנשים סקרנים תמיד מרוויחים...',
  promotion_modal_detail:
    'כי התעניינתם, ואנחנו יודעים שאתם תעופו על מה שתזמינו,  אנחנו מציעים הנחה על ההזמנה ב%s אם תחליטו להזמין עכשיו.',
  promotion_modal_button: 'מימוש הטבה עכשיו',
  request_missing_for_who: 'אנא רשמו למי ההזמנה מיועדת',
  request_price_invalid:
    'נראה שהמחיר שהכנסתם לא תקין. שימו לב שהמחיר צריך להיות גדול או שווה להצעת המחיר הבסיס של היוצר',
  sub_send_free_promotion_to_subscriber: 'שליחת שובר להזמנת סרטון חינם',
  edit_booking_request: 'עריכת בקשה',
  save_edit_booking_request: 'שמור שינויים',
  save_edit_booking_request_creator_only: 'שמור שינויים ליוצר בלבד',
  cancel_edit_booking_request: 'ביטול שינויים',
  currency_name_ils: 'ש״ח',
  currency_name_usd: 'דולר',
  rcs_personal_explainer_detail_1:
    'ברגע שתשלחו בקשה ליוצר, היא תגיע אליו/ה ויהיה להם עד שבוע (בדרך כלל זה באותו יום או למחרת) לענות וליצור לכם סרטון אישי ומקורי',
  rcs_request_desc_tip: 'כתיבת הודעה',
  search_for_creators: 'חיפוש יוצרים...',
  create_new_account: 'יצירת חשבון יוצר',
  new_creator_sign_up_title: 'דף הצטרפות יוצר חדש',
  we_sent_code_to_you: 'הרגע שלחנו לכם קוד אימות ל%s',
  creator_joined_succesfully: 'החשבון נוצר בהצלחה!',
  creator_joined_instructions:
    'יופי, אז עכשיו תוכלו להוריד את האפליקציה שתיתן לכם כוחות על ואפשרות לעדכן את הפרופיל מתי שתרצו, לראות את הבקשות, וליצור תוכן בצורה קלה ונוחה',
  home_jumbo_message: 'הדרך שלכם להנות ולתמוך בעולם התרבות גם בימי הקורונה',
  end_file_he: '',
};

const english = {
  edit_button: 'Edit',
  jumbo_title: 'A new world of content from creators all over the globe',
  booking_request_success: 'Request Sent',
  success: 'Success',
  operation_completed_success: 'Your request was completed sucesfully',
  free: 'Free',
  membership_confirmed_title: 'Membership confirmed!',
  membership_confirmed_detail: "You are now %s's Close Friend",
  back_to_create: 'Back to KRE8.TV',
  back: 'Back',
  auth_blocker_modal_title: 'We need to verify you are a real human!',
  complete_purchase: 'Complete Purchase',
  enter_number_log_in: 'Enter the phone number you signed up with',
  enter_number_sign_up:
    "You'll get a 6 digit code sent to you for confirmation",
  log_in: 'Log In',
  sign_up: 'Sign Up',
  rcs_desc_placeholder_helper: 'Give the creator as much ',
  confirm_sign_up: 'Continue',
  send_verification_code: 'Send verification code',
  confirm_code: 'Confirm Code',
  enter_6_digits: 'Enter the 6 digit code you received',
  error_title: 'Error',
  button_ok: 'OK',
  view_session_purchase_button: 'VIEW EXCLUSIVELY ON KRE8.TV FOR %s',
  actually_purchase_session_button: 'Purchase exclusive content only for %s',
  purchased_sessions: 'Purchased Sessions',
  payment_methods: 'Payment Methods',
  subscriptions: 'Subscriptions',
  no_sessions_yet: 'No sessions yet...',
  no_subs_yet: 'No subscriptions yet...',
  error_card_declined: 'Card declined. Please try a different payment method',
  couldnt_auth_user:
    "Couldn't authenticate user. Please log in or sign up to continue",
  log_out: 'Log out',
  send_me_request: 'Send me a request',
  send_request_price: 'Order video for %s',
  send_business_request_price: 'Send business request for %s',
  request_charge_subtitle:
    'You will only get charged after receiving the video',
  personal_video_request_modal_title: 'Great! A request was just sent to %s',
  personal_video_request_modal_detail:
    'If the creator does not respond within 7 days this request will be automatically cancelled and you will not be charged',
  personal_video_request_modal_subtitle:
    'Stay up to date and check the status of your request on one of our mobile apps. Your account data is synced between platforms',
  download_on_ios: 'Download on iOS',
  download_on_android: 'Download on Android',
  complete_request: 'Complete Request',
  confirm_request_for: 'Confirm request for %s',
  personalized_video_request_paywall_info_first:
    'You will not be charged until you recieve the personalized video from the creator',
  personalized_video_request_paywall_info_second:
    'If they do not respond within 7 days, the request will be automatically cancelled and the hold on your card will be terminated',
  send_a_request: 'Send a Request',
  send_a_biz_request: 'Business Request',
  rcs_who_for: 'Who is this request for?',
  rcs_your_name: "What's your name?",
  rcs_their_name: "What's their name?",
  rcs_request_desc: 'Request description',
  rcs_myself: 'Myself',
  rcs_someone_else: 'Someone else',
  chars_left: '%s chars left',
  enter_your_name: 'Enter your name',
  enter_their_name: 'Enter their name',
  rcs_desc_placeholder:
    "What would you like to see or know?\n\nIt can be advice for a problem you're facing, tips for a specific situation, educational content on something you'd like to know or even content for entertainment purposes. Be sure to give as much details and background as possible so that the Creator will have substance to work with.",
  rcs_desc_placeholder_tip:
    "If the creator gave you value, leave them a review to let them and others know. If they didn't, write some constructive feedback that will help them know where they can improve",
  add_card_bottom_subtitle:
    'To keep you protected, we use Stripe as processing service provider and never store your full credit card information',
  add_card: 'Add Card',
  add_payment_method: 'Add Payment Method',
  purchase_session_for: 'Purchase session for %s',
  profile_tab_title: 'My Profile',
  tab_home: 'Home',
  tab_profile: 'Profile',
  close_circle: 'Close Circle',
  cf_pv_discount_title: '50% off Personalized Videos',
  cf_pv_discount_detail:
    "You'll be able to send personalized video requests to this creator for 50% of their current asking price.",
  cf_sessions_title: 'Unlimited access to exclusive content',
  cf_sessions_detail:
    'On-Demand and exclusive content such as mini-masterclasses, behind the scenes sneak peeks, and world-premiers that will make you feel alive',
  cf_thoughts_title:
    'Thoughts - Private Chat Group with the Creator (Coming Soon)',
  cf_thoughts_detail:
    'An intimate look at how this Creator thinks through thoughts in the form of exclusive texts, voice notes, images, and videos in a simple chat-like interface',
  close_circle_subscription_action_title: 'Join close circle for %s/month',
  close_circle_sub_not_active: 'Close circle membership isnt available yet',
  booking_not_available: 'Currently unavailable',
  create_session_page_title: 'New Session',
  create_session_input_title: 'Title',
  create_session_input_title_placeholder: 'Short title of your session',
  create_session_input_desc: 'Description',
  create_session_input_desc_placeholder:
    'Give a brief overview about the content that people can see before purchasing your content',
  create_session_input_price: 'Price to purchase',
  create_session_input_preview_video: 'Preview video',
  create_session_input_submission_video: 'Video file',
  create_session_action_button: 'UPLOAD & GET LINK',
  cs_preview_file_input_placeholder:
    'Optional preview of your content (1m max)',
  cs_submission_file_input_placeholder: 'Full content file to upload',
  cs_upload_preview_rec: '(Best viewed in portrait)',
  submitting: 'Submitting',
  uploading: 'Uploading',
  session_dashboard_title: 'Session Dashboard',
  session_purchases: 'Session Purchases',
  no_purchases_yet: 'No purchases yet...',
  share_and_earn: 'Share URL & Start Earning',
  total_earnings: 'Total Earnings:',
  create_session_header_title: 'Create, Connect, Collect',
  create_session_header_subtitle:
    "You're 30 seconds away from starting to get what you deserve for your content",
  set_a_price: 'Set a price',
  browse: 'Browse',
  session_url_copied: 'Link copied to clipboard',
  enter_phone_number_placeholder: 'Enter phone number',
  enter_code_placeholder: 'Enter code',
  first_name_placeholder: 'First name',
  last_name_placeholder: 'Last name',
  email_placeholder: 'Enter your email',
  error_session_preview_file_too_big:
    'Session preview files can be no larger than %s MB. Try selecting a smaller file and uploading again',
  error_session_submission_file_too_big:
    'Session video files can be no larger than %s MB. Try selecting a smaller file and uploading again',
  set_your_price_here: 'For example - $5',
  tab_create_session: 'New',
  terms_of_service: 'Terms of Service',
  privacy_policy: 'Privacy Policy',
  agree_to_terms_signup:
    "By clicking 'Continue' you agree to the terms of service of this platform",
  first_name: 'First name',
  last_name: 'Last Name',
  email: 'Email',
  fill_in_your_details: 'Fill in your details',
  tab_activity: 'Activity',
  empty_activity_placeholer:
    'This is where you will see all of your requests, sessions, and more',
  for_someone_else_request_label: 'From %s, for %s',
  for_myself_request_label: 'For %s',
  booking_status_pending: 'Awaiting creation',
  booking_status_declined: 'Declined',
  booking_status_accepted: 'Accepted',
  booking_status_delivered: 'Delivered',
  booking_status_cancelled: 'Cancelled',
  booking_status_awaiting_payment: 'Awaiting Payment',
  booking_status_expired: 'Expired',
  booking_status_archived: 'Archived',
  booking_status_long_pending:
    'The request has been sent to the creator and waiting for them to respond!',
  booking_status_long_declined: 'Your request has been declined',
  booking_status_long_accepted: 'Your request has been accepted',
  booking_status_long_delivered:
    'Your request has been completed by the creator. Click to watch now!',
  booking_status_long_cancelled: 'Your request has been cancelled',
  booking_status_long_awaiting_payment: 'Your request is awaiting Payment',
  booking_status_long_expired: 'Your request has expired',
  booking_status_long_archived: 'Your request has been archived',
  great_thanks: 'Great, thanks!',
  admin_dashboard_title: 'Admin Dashboard',
  booking_not_available_business: 'Currently not available for biz requests',
  rcs_business_name: 'Business Name',
  rcs_your_name_biz: "What's the name of the business?",
  rcs_desc_placeholder_biz: 'What would you them to say or do?',
  rcs_desc_placeholder_biz_2:
    'This can be a video for your staff, a marketing or business oriented video for promotional needs, or for any other reason that is not intended strictly for personal use',
  rcs_desc_placeholder_biz_3:
    'Be sure to give as much details and background as possible so that the Creator will have substance to work with.',
  rcs_business_explainer_title: 'How does it work?',
  rcs_business_explainer_detail_1:
    "Once you send this creator a request, they'll have 7 days to send you back a unique and original marketing or business-related video for your use",
  rcs_business_explainer_detail_2:
    'Our business liaison will contact you if there is any additional info needed. If you would like us to contact you anyways, please mention so in the request description',
  rcs_business_explainer_detail_3:
    'There will be a temporary hold put on your card for the sum of your order, but you will only be charged **after** you receive the video',
  rcs_business_explainer_detail_4:
    'Once the video is ready, you\'ll be able to see it in your "Activity" screen and view or download it directly',
  rcs_business_explainer_detail_5:
    'We recommend downloading our mobile app (iOS & Android) to get updates about your order',
  rcs_business_explainer_detail_6:
    'For any additional questions, feel free to send us a request directly to our official account on KRE8.TV or at contact@kre8.tv',
  biz_must_connect_to_see_price: 'Sign in to see price',
  upload_video_to_send: 'Upload the video to send',
  request_click_to_upload_video:
    'This request is waiting for you! Click to upload video now',
  submit_video: 'Submit Video',
  uploading_file: 'Uploading file...',
  click_to_upload: 'Click to Upload',
  choose_file_first: 'Choose file first',
  upload_success_tap_to_close: 'UPLOAD SUCCESS - TAP TO CLOSE',
  response_time_hours: '%sh',
  response_time_days: '%sd',
  avg_response_time: 'Avg response time',
  avg_rating: '%s Ratings',
  profile_request_personalized_video: 'Order a personal video',
  profile_request_business_video: 'Order video for business',
  request_success_download_app_first:
    'To get notified when your video is ready ',
  request_success_download_app_second:
    'download our mobile app and sign in with the same number you used here - all orders are synced between platforms',
  download_our_apps_1: 'Come discover more on our mobile apps',
  download_our_apps_2: '',
  rcs_request_price_biz_title: 'Offer in %s',
  rcs_request_price_biz_placeholder: '%s minimum (%s)',
  send_business_request_bid: 'Send request to creator',
  request_charge_subtitle_business:
    'A temporary hold on your card will be made to ensure your bid is valid',
  admin_requests: 'Requests',
  admin_graphs: 'Graphs',
  admin_users: 'Users',
  admin_payouts: 'Payouts',
  admin_active_creators: 'Active Creators',
  admin_recently_connected: 'Recently Connected',
  admin_completed_creators: 'Completed Creator Profile',
  admin_new_users: 'New Users',
  admin_asking_price: 'Current Asking Price',
  admin_videos_made: 'Total Videos made',
  admin_money_earned: 'Money earned to date',
  admin_last_seen: 'Last seen',
  admin_joined: 'Join date',
  admin_active_creators_detail:
    '%s avg per top creator (min 3 orders completed)',
  admin_bookings: 'bookings',
  admin_creators: 'creators',
  edit_profile_tab_title: 'Edit Profile',
  save_profile: 'Save Changes',
  error_saving_profile: 'Error saving profile',
  saved_profile_success: 'Succesfully updated profile',
  updating_profile: 'Upating Profile',
  edit_profile_username: 'Username',
  edit_profile_title: 'Short title',
  edit_profile_description: 'Longer description',
  edit_profile_price_personal: 'Personal Videos',
  edit_profile_price_business: 'Promotional Videos',
  edit_profile_price_personal_placeholder: 'Minimum price',
  edit_profile_price_business_placeholder: 'Minimum price',
  manager: 'Manager',
  create_new_user: 'Create New User',
  userId_placeholder: 'User id... example roy_temp',
  submit_and_create: 'Submit & Create',
  new_user_created_success: 'New user created succesfully',
  close: 'Close',
  featured_active: 'Featured',
  featured_not_active: 'Not featured',
  edit_profile_featured: 'Featured',
  edit_profile_manager_id: 'Manager ID',
  edit_profile_agency_id: 'Agency ID',
  edit_profile_referral_id: 'Referral ID',
  edit_profile_currency: 'Currency',
  ils: 'ILS ₪',
  usd: 'USD $',
  admin_affiliates: 'Affiliates',
  admin_posts: 'Posts',
  personal_url: 'Personal Booking URL',
  business_url: 'Business Booking URL',
  brands_that_work_with_us: 'Brands that work with us',
  picker_select_date_and_time: 'Selet a date & time',
  date_and_time: 'Date & Time',
  booking_background_title: 'Description & Additional Background',
  rcs_desc_placeholder_generic_booking_item:
    'Give the creator some additional information describing ideally what you would expect from them to talk about.\n\nGive some background information as well pertaining the audience and enviornment they might be interacting with so that they can come best prepared and provide you with the most value',
  request_charge_subtitle_booking_generic:
    'There will be a temp hold on your card, but you will only get charged after receiving the video',
  rcs_business_explainer_detail_2_generic:
    'Make sure to send your booking request a few days in advance so that the creator can have enough time to accept/decline your request',
  rcs_business_explainer_detail_3_generic:
    'There will be a temporary hold put on your card for the sum of your order, but you will only be charged **after** the creator confirms your request',
  rcs_business_explainer_detail_4_generic:
    'For any reason, if the creator does not approve your request - your temporary hold will dissapear within several business days',
  rcs_business_explainer_detail_5_generic:
    'If you have any additional questions, please feel free to send us a message over at contact@kre8.tv',
  send_booking_item_request_price: 'Submit booking request for %s',
  creatorId_placeholder: 'Creator ID',
  request_creation_bid_helper_label:
    "If you'd like your video to be done ASAP, or with additional extra effort, you can incentivize the creator by choosing a higher amount than their base asking price",
  order_me_on_zoom: 'Order me on Zoom',
  book_me: 'Book more',
  book_me_on_zoom: 'Book Zoom',
  date_picker_time: 'TIME',
  generic_booking_item_request_paywall_info_first:
    'A temporary hold will be put on your card, but you will only be charged once the creator confirms your order request',
  generic_booking_item_request_paywall_info_second:
    'If they do not respond within 7 days, the request will be automatically cancelled and the hold on your card will be terminated',
  edit_profile_additional_booking_items: 'Additional Booking Items',
  add_new_booking_item: 'Add new booking item +',
  booking_item_create_modal_title: 'Create New Booking Item',
  booking_item_update_modal_title: 'Update Booking Item',
  booking_item_title_placeholder: 'A longer description of your offering',
  booking_item_details_placeholder: 'A longer description of your offering',
  booking_item_description_placeholder: 'A longer description of your offering',
  booking_item_price_placeholder: 'A longer description of your offering',
  booking_item_modal_submit_button: 'Submit & Create',
  booking_item_updated_success: 'New item updated succesfully!',
  booking_item_created_success: 'New item created succesfully!',
  additional_details: 'Additional Details:',
  request_booking_item: 'Send Booking Request',
  request_booking_item_bid_helper_label:
    "If your request is time sensitive, or you'd like the creator to be incentivized to deliver an extra special performance, you can incentivize them by choosing a higher amount than their base asking price",
  additional_booking_items: 'Additional Booking Items',
  date_to_deliver_request: 'Delivery date: %s',
  edit_profile_external_links: 'External Links',
  edit_profile_custom_url: 'Custom Website URL',
  edit_profile_instagram_url: 'Instagram Profile URL',
  edit_profile_facebook_url: 'Facebook Profile URL',
  edit_profile_youtube_url: 'YouTube Profile URL',
  edit_profile_twitter_url: 'Twitter Profile URL',
  edit_profile_tiktok_url: 'TikTok Profile URL',
  create_new_payout: 'Create New Payout',
  edit_booking_item_title: 'Title',
  edit_booking_item_description: 'Description',
  edit_booking_item_details: 'Details',
  edit_booking_item_price: 'Base Price',
  edit_booking_item_scheduled_booking: 'Scheduled Booking',
  edit_booking_item_scheduled_booking_title: 'Require date & time',
  request_booking_item_placeholder_leave_message:
    'Leave a personal message we promise this creator will see!',
  session_paywall_alert_message:
    'In order to watch this full session, you\'ll need to purchase it first for %s. If it makes you happier, smarter, or stronger, its probably worth it - Invest in yourself!\n\nPurchasing this session will give you exclusive access to watch this session on all platforms, always.\nTap "Confirm" to proceed with your purchase',
  session_paywall_alert_message_short:
    "In order to watch this full session, you'll need to purchase it first for %s. If it makes you happier, smarter, or stronger, its probably worth it - Invest in yourself!\n\nPurchasing this session will give you exclusive access to watch this session on all platforms, always.",
  edit_booking_item_require_description: 'Require request descripion',
  edit_booking_item_charge_on_booking: 'Charge on booking',
  edit_booking_item_additional_options: 'Additional settings',
  edit_booking_response_text: 'Message after they purchase (optional)',
  edit_booking_response_text_placeholder:
    'Give some additional details after purchasing',
  checkout_success_title: 'Payment method added sucesfully',
  checkout_success_detail:
    'You can now close this screen and go back to Create to complete your purchase',
  checkout_personalized_on_demand_label:
    'Almost done! You must add a payment method in order to send a personalized video request',
  checkout_personalized_on_demand_biz_label:
    'Almost done! You must add a payment method in order to send a on demand video request',
  checkout_session_label:
    'Almost done! You must add a payment method in order to purchase exclusive content',
  checkout_personalized_on_demand_close_circle_label:
    'Almost done! You must add a payment method in order to purchase a Close Circle membership',
  profile_join_close_circle: 'Join my close circle',
  profile_close_circle_already_subscribed:
    'You are subscribed to their close circle',
  business_request: 'Business Request',
  profile_screen_you_are_already_subscribed_title: 'You already a subscriber',
  profile_screen_you_are_already_subscribed_message:
    "As a subscriber to this creator's Close Circle, you get discounts on personalized videos, free and unlimited access to pay-per-view content, as well as exclusive access to subcriber only content",
  cc_modal_alert_title: 'Close Circle Subscription Required',
  modal_alert_session_preview_ended_title: 'Session Preview Ended',
  modal_alert_session_preview_ended_message: `In order to watch this full session, you\'ll need to purchase it first for %s. If it makes you happier, smarter, or stronger, its probably worth it - Invest in yourself!
    
    Purchasing this session will give you exclusive access to watch this session on all platforms, always.
    
    Tap "Confirm" to proceed with your purchase`,
  session_upsell_close_circle_subscription:
    'Alternatively, you can get FREE and unlimited access to all of this creator\'s exclusive content by purchasing a "Close Circle" subscription for just %s/month (recommended)',
  close_circle_subscription_title: '"Close Circle" Susbscription',
  purchase_session: 'Purchase Exclusive Content',
  watch_exclusively_now: 'Watch exclusively now',
  exclusive_access_available_soon: 'Exclusive access will be available soon',
  session_watch_no_permissions_alert_title: 'Permission Denied',
  session_watch_no_permissions_alert_message:
    'You must either purchase exclusive access to this session or join this creator\'s "Close Circle" subscription to watch',
  button_cancel: 'Cancel',
  cancel_subscription: 'Cancel Subscription',
  what_would_you_like_to_do: 'What would you like to do?',
  cancel_subscription_alert_message:
    'If you decide to cancel your subscription, you will stop getting exclusive access and discounts to this creator',
  edit_profile_verfied: 'Verified user',
  verified: 'Verified',
  subscribers_only: 'Available for subscribers only',
  post_subscribe_button: 'Subscriber now',
  /* rating */
  rate_delivery_screen_title: 'Video Review',
  rate_delivery_screen_rating_detail: 'Rate your experience',
  rate_delivery_screen_message_detail: 'Add an Anonymous Message',
  rate_delivery_screen_submit_button: 'Submit Review',
  rate_delivery_screen_review_submitted_title: 'Thank you!',
  rate_delivery_screen_review_submitted_body:
    'Your review was succesfully submitted!',
  submit_review_tip_error_alert_title: 'Invalid Tip Amount',
  submit_review_tip_error_message_title:
    "The minimum tip amount must be %s or greater. Tap 'Cancel' if you'd like to adjust your tip and then submit the review, or 'Submit Without Tip' to submit your review without sending a tip.",
  submit_review_tip_error_submit_anyways: 'Submit Without Tip',
  /* review tip */
  booking_request_tip: '%s TIP',
  leave_a_review_tip_placeholder:
    'If this creator just gave you value, you can let them know by writing a personal message and leaving them a tip.',
  leave_a_tip: 'Leave a Tip (%s)',
  tip_amount_placeholder: 'Tip amount',
  tip_must_be_above: 'Minimum tip is %s',
  download_video: 'Download Video',
  tip_string: '+ %s',
  account_tab_not_logged_in_placeholder: 'Tap to Log in or Sign up',
  order_tiktok_video: 'Order video for TikTok',
  order_business_video: 'Order a promotional video',
  admin_subscriptions: 'Subscriptions',
  request_not_long_enough:
    'Your request is too short. Add a few more words and then try submitting again',
  one_moment_alert_title: 'Hold on...',
  promotion_modal_title: 'Hold your horses 🐴🐴',
  promotion_modal_detail:
    "Since you were being curious, we've decided to give you a %s discount only if you choose to order now.\n\nWe know its worth it.",
  promotion_modal_button: 'Redeem Discount Now',
  request_missing_for_who: 'Please fill in who this request is intended for',
  request_price_invalid:
    'Looks like you entered an invalid price. Please adjust it to be at least equal or greather than the minimum bid price and try again',
  sub_send_free_promotion_to_subscriber: 'Send free Promotion to Subscriber',
  option_or: 'OR',
  button_actions: 'Actions',
  edit_booking_request: 'Edit Request',
  save_edit_booking_request: 'Save changes',
  save_edit_booking_request_creator_only: 'Save changes for Creator ONLY',
  cancel_edit_booking_request: 'Cancel changes',
  currency_name_ils: 'ILS',
  currency_name_usd: 'USD',
  rcs_personal_explainer_detail_1:
    "Once you send this creator a request, they'll have 7 days to send you back a unique and original personalized video",
  rcs_request_desc_tip: 'Review Message',
  search_for_creators: 'Search creators...',
  create_new_account: 'New Creator Account',
  new_creator_sign_up_title: 'New creator sign up form',
  we_sent_code_to_you: 'We just sent a verification code to %s',
  creator_joined_succesfully: 'Account created successfully!',
  creator_joined_instructions:
    'Great, now we recommend you take a moment to download our app which will give you superpowers, allow you to edit or update your profile, as well as see all of your requests, create content, and much much more',
  home_jumbo_message: 'Your way to enjoy and support the passion industry',
  end_file_en: '',
};
