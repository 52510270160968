export class AdminNotificationReminder {
  id: string;
  created_on: number;
  notifications_attempted = 0;
  notifications_failed = 0;
  notifications_ignored = 0;
  notifications_sent = 0;
  requests: { [key: string]: number } = {};
  notifications: { [key: string]: number } = {};

  constructor(json: any) {
    this.id = json.id;
    this.created_on = json.created_on;
    this.notifications_attempted = json.notifications_attempted;
    this.notifications_failed = json.notifications_failed;
    this.notifications_ignored = json.notifications_ignored;
    this.notifications_sent = json.notifications_sent;
    this.requests = json.requests;
    this.notifications = json.notifications;
  }
}
