import React from 'react';
import starIcon from '../Images/starNew.png';
import LightStarIcon from '../Images/icon_star_light.svg';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { isHebrew } from '../../App';

const useStyles = makeStyles((theme) => ({
  quanitfiersNumbers: {
    fontFamily: 'Assistant',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '13px',
    letterSpacing: '-0.165px',
    textAlign: 'center',
    color: 'white',
  },
  sectionSpacing: {
    marginTop: '40px',
    marginBottom: '40px',
  },
  reviewBox: {
    background: '#3A2D5C',
    width: '225px',
    height: '135px',
    borderRadius: '24px',
  },
}));

const Quanitifers = (props) => {
  const { likes, creations, subscribers, followers } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  /**
   * Box for each quantifier
   * @param {*} props
   * @returns
   */
  const QuantifierBox = (props) => {
    const { title, detail } = props;
    const classes = useStyles();
    return (
      <Box ml={2.5} mr={2.5} width="50px">
        <Typography>
          <Box className={classes.quanitfiersNumbers}>{title}</Box>
        </Typography>
        <Typography variant="body2">
          <Box
            textAlign="center"
            mt={1}
            className="text-muted"
            style={{ textTransform: 'capitalize' }}
          >
            {detail}
          </Box>
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      mt={2}
      mb={2}
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent="center"
    >
      <Box id="likes">
        <QuantifierBox title={likes} detail={t('likes')} />
      </Box>
      <Box id="creations">
        <QuantifierBox title={creations} detail={t('creations')} />
      </Box>
      {/* <Box id="subscribers">
        <QuantifierBox title={subscribers} detail={t('subscribers')} />
      </Box> */}
      <Box id="followers">
        <QuantifierBox title={followers} detail={t('followers')} />
      </Box>
    </Box>
  );
};

const StarsSection = (props) => {
  const classes = useStyles();
  const { rating } = props;
  return (
    <div>
      {rating ? <img alt="star" src={LightStarIcon} /> : null}
      {rating > 1 ? <img alt="star" src={LightStarIcon} /> : null}
      {rating > 2 ? <img alt="star" src={LightStarIcon} /> : null}
      {rating > 3 ? <img alt="star" src={LightStarIcon} /> : null}
      {rating > 4 ? <img alt="star" src={LightStarIcon} /> : null}
    </div>
  );
};

const Review = (props) => {
  const { index, content, rating } = props;
  const classes = useStyles();
  return (
    <Box
      p={2}
      className={classes.reviewBox}
      mr={isHebrew() ? 0 : 2}
      ml={!isHebrew() ? 0 : 2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box lineHeight={1.2}>{content}</Box>
      <StarsSection rating={rating} />
    </Box>
  );
};

const AboutSection = React.memo((props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const { user } = props;

  let desc = user && user.desc ? user.desc : '';
  let reviews = user && user.transactionReviews ? user.transactionReviews : [];

  return (
    <div>
      <Box id="about-info-container">
        <Box id="about-title">
          <Typography variant="h2">
            <Box color="text.secondary" lineHeight={1.2}>
              {t('profile_about')}
            </Box>
          </Typography>
        </Box>
        <Box id="about-content" mt={2}>
          <Typography variant="body1">
            <Box color="text.secondary" fontSize={16} lineHeight={1.6}>
              {desc}
            </Box>
          </Typography>
        </Box>
        <Box id="quantifiers" className={classes.sectionSpacing}>
          <Quanitifers
            likes={user.totalVees}
            creations={user.totalCreations}
            subscribers={user.totalCloseFans}
            followers={user.totalFollowers}
          />
        </Box>
      </Box>
      <Box
        id="reviews-container"
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        minWidth="100%"
        overflow="auto"
      >
        {reviews
          .filter((item) => item.content && item.content.length > 0)
          .map((item, index) => {
            return (
              <Box flexShrink={0} m={1}>
                <Review
                  index={index}
                  content={item.content}
                  rating={item.rating}
                />
              </Box>
            );
          })}
      </Box>
    </div>
  );
});

export default AboutSection;
