import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
  TextField,
  SvgIcon,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Input,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useTranslation } from 'react-i18next';
import { withOnboardingStage } from '../../../shared/hoc';

import UploadImageIcon from '../images/upload_image_icon.svg';
import ShareIcon from '@material-ui/icons/Share';

//General
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../../../App';
import VLButton from '../../../Reusables/Buttons/VLButton';
import profileManager from '../../../managers/profileManager';
import { OnboardingFlowEventVerifyProfile } from '../../../managers/v2';
import VLFlowManager from '../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {},

  activeSceneLine: {
    background: '#5D36BD',
    opacity: 1.0,
    borderRadius: '4px',
    width: '26px',
  },
  sceneLine: {
    width: '10px',
    cursor: 'pointer',
    opacity: 0.5,
    background: '#DBCAFE',
    borderRadius: '4px',
  },
  textField: {
    '& .MuiFormLabel-root': {
      color: 'white',
    },
    color: 'white',
  },
  textFieldRoot: {
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff8', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#fff', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff', // Solid underline on focus
    },
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid #C8CACF',
      height: '2px',
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      height: '2px',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      height: '2px',
    },
    '&:hover:not($disabled):not($focused):not($error):after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      height: '2px',
    },
  },
  uploadImageBackground: {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '20px',
  },
  shareIcon: {
    color: 'white',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  formControl: {
    minWidth: '100%',
    color: 'white',
  },
  select: {
    color: 'white',
    label: { color: 'white' },
  },
  icon: { fill: 'white' },
}));

const VLTextField = (props) => {
  const classes = useStyles();
  const { placeholder, inputRef } = props;
  return (
    <Box width="100%" height="50px">
      <TextField
        inputRef={inputRef}
        InputProps={{
          classes: {
            underline: classes.underline,
            input: classes.textField,
            root: classes.textFieldRoot,
          },
        }}
        inputProps={{
          classes: {
            underline: classes.underline,
            input: classes.textField,
            root: classes.textFieldRoot,
          },
        }}
        fullWidth={true}
        placeholder={placeholder}
      ></TextField>
    </Box>
  );
};

const VLSelect = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { placeholder, inputRef } = props;
  const [type, setType] = React.useState('');

  const handleChange = (event) => {
    setType(event.target.value);
  };
  return (
    <Box width="100%" height="50px" color="white" mb={2}>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.select} id="demo-simple-select-label">
          {t('onboarding_follow_verify_textfield_select_account')}
        </InputLabel>
        <Select
          inputRef={inputRef}
          className={classes.select}
          input={
            <Input
              classes={{
                underline: classes.underline,
              }}
              name="type"
              id="type-helper"
            />
          }
          classes={{
            root: classes.select,
            select: classes.select,
            icon: classes.icon,
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          onChange={handleChange}
        >
          <MenuItem value={'personal'}>
            {t('onboarding_follow_verify_textfield_select_account_personal')}
          </MenuItem>
          <MenuItem value={'business'}>
            {t('onboarding_follow_verify_textfield_select_account_business')}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const VerifyProfile = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);
  const { onConfirm, onSkip } = props;

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new OnboardingFlowEventVerifyProfile());
  });

  const typeRef = useRef(null);
  const fullNameRef = useRef(null);
  const linkRef = useRef(null);

  const title = t('onboarding_follow_verify_profile_title');
  const detail = t('onboarding_follow_verify_profile_detail');

  const onConfirmPressed = async () => {
    //@todo - should save
    setShowLoading(true);
    const perform = await profileManager.submitVerificationRequest(
      typeRef.current?.value,
      fullNameRef.current?.value,
      linkRef.current?.value
    );
    onConfirm();
    setShowLoading(false);
  };

  const onSkipPressed = () => {
    onSkip();
  };

  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box height="50%" pl={2} pr={2} mt={4} mb={2}>
        <Typography variant="h2">
          <Box fontSize={24} fontWeight={700} color="text.secondary" mb={2}>
            {title}
          </Box>
        </Typography>
        <Typography variant="body1">
          <Box
            fontSize={16}
            fontWeight={600}
            color="text.secondary"
            lineHeight={1.3}
          >
            {detail}
          </Box>
        </Typography>
        <Box mt={2} mb={2}>
          <VLSelect
            inputRef={typeRef}
            placeholder={t('onboarding_follow_verify_textfield_select_account')}
          />
          <VLTextField
            inputRef={fullNameRef}
            placeholder={t('onboarding_follow_verify_textfield_legal_name')}
          />
          <VLTextField
            inputRef={linkRef}
            placeholder={t('onboarding_follow_verify_textfield_social_link')}
          />
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <VLButton
            disabled={showLoading}
            onClick={() => onConfirmPressed()}
            fullWidth={true}
          >
            {showLoading ? (
              <CircularProgress style={{ color: 'white' }} size={20} />
            ) : (
              t('onboarding_button_confirm')
            )}
          </VLButton>
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <VLButton
            disabled={showLoading}
            variant="default"
            onClick={() => onSkipPressed()}
            fullWidth={true}
          >
            {t('onboarding_button_skip')}
          </VLButton>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(null, null)(withOnboardingStage(VerifyProfile));
