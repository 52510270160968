import firebase from 'firebase';
import * as dateUtils from '../utils/dateUtils';
import * as app from '../App';
import Session from '../model/session';
import VLUser from '../model/vlUser';
import VLAccountDetails from '../model/vlAccountDetails';
import VLAffiliateDeal from '../model/vlAffiliateDeal';
import VLResponse from '../model/vlResponse';
import VLError from '../model/vlError';
import VLBookingRequest from '../model/bookingRequest';
import VLCategory from '../model/vlCategory';
import api, * as RHAPI from '../constants/api';
import * as strings from '../utils/vlStrings';
import { useMemo } from 'react';
import userManager from './userManager.js';
import * as utils from '../utils/rhutils.js';
import * as constants from '../constants/rhconstants';
import VLPost from '../model/vlPost';
import bookingRequestManager from './bookingRequestManager';
import dbManager from './dbManager';
import BookingRequest from '../model/bookingRequest';
import CloseFan from '../model/closeFan';
import { getI18n } from 'react-i18next';
import { analytics, AnalyticsEvent } from '../utils/analytics';
import PostVisibilityType from '../model/enums/PostVisibilityType';
import { AdminNotificationReminder } from '../model/v2/admin-notification-reminder.model';
var _ = require('lodash');

const masterCurrency = 'ILS';
class AdminManager {
  /**
   * Fetches the relevant admin dashboard data for the current user (for now, just requests relevant to them)
   */
  async fetchAdminDashboardData() {
    var existingRequests = this.localBookingRequests();
    utils.log.debug('local br...', existingRequests);

    var url = `${api.FETCH_ADMIN_DASHBOAD_DETAILS}`;

    if (existingRequests.length > 0) {
      existingRequests = _.map(existingRequests, (er) =>
        BookingRequest.copy(er)
      );
      let lastUpdated = existingRequests[0].lastUpdated;
      url = `${url}?requests_last_updated=${lastUpdated}&requests_status=1`;
    }

    utils.log.debug('fetching admin dashboard with url...', url);
    /* refresh token */
    let headers = await userManager.getRequestHeaders();
    utils.log.debug('🏃‍♂️ fetching admin dashboard data w/ headers...', headers);
    const results = await fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then(async (response) => response.json())
      .then(async (json) => {
        utils.log.debug('Success:', json);
        let allRequestsJSON = json.requests;
        if (allRequestsJSON) {
          var allRequests = [];
          for (var i = 0; i < allRequestsJSON.length; i++) {
            let bookingRequestJSON = allRequestsJSON[i];
            let br = VLBookingRequest.fromJSON(bookingRequestJSON);
            allRequests.push(br);
          }
        }

        let response = {};
        if (allRequests) {
          response.allRequests = allRequests;
        }
        utils.log.debug('fetch adin dashboard data response:', response);
        return response;
      })
      .catch(async (error) => {
        console.error('fetchuseractivity Error:', error);
        return null;
      });
    return results;
  }
  async fetchAdminSubscriptionsData() {
    utils.log.debug('fetching all subscriptions...');
    const db = firebase.firestore();
    const subsRef = db
      .collection(constants.FIREBASE_COLLECTION_SUBSCRIPTIONS)
      .orderBy('created_on', 'desc');
    const subsSnapshot = await subsRef.get();
    var subs = [];
    subsSnapshot.docs.forEach((element) => {
      // utils.log.debug("element...", element.data());
      let closeFan = CloseFan.fromJSON(element.data());
      subs.push(closeFan);
    });
    const result = {};
    result.subscriptions = subs;
    result.error = null;
    utils.log.debug('subsSnapshot...', subs);
    return result;
  }
  /**
   * Get a numerical overview of the requests
   * @param {*} requests The requests to calculate into the overview data
   */
  getOverviewDataFromSubscriptions(subscriptions) {
    var total_subs = subscriptions.length;
    var total_revenue = 0;
    //active
    var total_active_subs = 0;
    var total_active_revenue = 0;
    //inactive
    var total_inactive_subs = 0;
    var total_inactive_revenue = 0;

    for (var i = 0; i < subscriptions.length; i++) {
      let subscription = subscriptions[i];
      let price = utils.convertToPrice(
        subscription.price,
        subscription.currency,
        masterCurrency
      );
      if (subscription.active) {
        total_active_subs++;
        total_active_revenue = total_active_revenue + price;
      } else {
        total_inactive_subs++;
        total_inactive_revenue = total_inactive_revenue + price;
      }
    }

    total_subs = total_active_subs + total_inactive_subs;
    total_revenue = total_active_revenue + total_inactive_revenue;

    utils.log.debug('masterCurrency....', masterCurrency);
    return {
      total_revenue,
      total_subs,
      total_active_subs, //completed
      total_active_revenue,
      total_inactive_subs, //pending
      total_inactive_revenue,
      currency: masterCurrency,
    };
  }

  localBookingRequests() {
    const bookingRequestsCollection = dbManager.getCollection(
      constants.LOCAL_DB_COLLECTION_BOOKING_REQUESTS
    );
    if (!bookingRequestsCollection) {
      return [];
    }

    let results = bookingRequestsCollection
      .chain()
      .simplesort('lastUpdated')
      .data()
      .reverse();
    return results;
  }
  /**
   *
   * @param {*} subscriptions The subscriptions to filter
   * @param {*} days Days to include (1 just today, 7 this week)
   * @param {*} offset Offset of days (1 days + 1 offset = just tomorrow)
   */
  filterSubscriptionsForDaysBack(subscriptions, days, offset) {
    utils.log.debug(
      `filtering subscriptions span of ${days} with offset ${offset}`
    );
    let now = new Date();
    var filtered = subscriptions.filter((sub) => {
      let subDate = new Date(sub.lastUpdated);
      if (days === 1) {
        if (offset === 1) {
          let yesterday = dateUtils.yesterday();
          return dateUtils.isSameCalendarDay(yesterday, subDate);
        } else {
          return dateUtils.isSameCalendarDay(now, subDate);
        }
      } else if (days === 7) {
        return dateUtils.isSameCalendarWeek(now, subDate);
      } else if (days === 30) {
        if (offset === 30) {
          //last month
          let lastMonth = new Date();
          lastMonth.setDate(1);
          lastMonth.setMonth(lastMonth.getMonth() - 1);
          return dateUtils.isSameCalendarMonth(lastMonth, subDate);
        } else if (offset === 60) {
          //last month
          let twoMonthsAgo = new Date();
          twoMonthsAgo.setDate(1);
          twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
          return dateUtils.isSameCalendarMonth(twoMonthsAgo, subDate);
        } else {
          return dateUtils.isSameCalendarMonth(now, subDate);
        }
      } else {
        let distanceInDays = dateUtils.daysBetweenDates(now, subDate);
        return distanceInDays < days;
      }
    });
    // utils.log.debug("filtered subscriptions...", filtered);
    return filtered;
  }

  /**
   *
   * @param {*} requests The requests to filter
   * @param {*} days Days to include (1 just today, 7 this week)
   * @param {*} offset Offset of days (1 days + 1 offset = just tomorrow)
   */
  filterResultsForDaysBack(requests, days, offset) {
    utils.log.debug(`filtering requests span of ${days} with offset ${offset}`);
    let now = new Date();
    var filtered = requests.filter((req) => {
      let reqDate = new Date(req.lastUpdated);
      if (days === 1) {
        if (offset === 1) {
          let yesterday = dateUtils.yesterday();
          return dateUtils.isSameCalendarDay(yesterday, reqDate);
        } else {
          return dateUtils.isSameCalendarDay(now, reqDate);
        }
      } else if (days === 7) {
        return dateUtils.isSameCalendarWeek(now, reqDate);
      } else if (days === 30) {
        if (offset === 30) {
          //last month
          let lastMonth = new Date();
          lastMonth.setDate(1);
          lastMonth.setMonth(lastMonth.getMonth() - 1);
          return dateUtils.isSameCalendarMonth(lastMonth, reqDate);
        } else if (offset === 60) {
          //two months ago - for this one show just older requests
          let twoMonthsAgo = new Date();
          twoMonthsAgo.setDate(1);
          twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
          return twoMonthsAgo > reqDate;
        } else {
          return dateUtils.isSameCalendarMonth(now, reqDate);
        }
      } else {
        let distanceInDays = dateUtils.daysBetweenDates(now, reqDate);
        return distanceInDays < days;
      }
    });
    // utils.log.debug("filtere requests...", filtered);
    return filtered;
  }
  /**
   * Get a numerical overview of the requests
   * @param {*} requests The requests to calculate into the overview data
   */
  getOverviewDataFromRequests(requests) {
    var total_requests = requests.length;
    var total_revenue = 0;
    //completed
    var total_completed_requests = 0;
    var total_completed_revenue = 0;
    //pending
    var total_pending_requests = 0;
    var total_pending_revenue = 0;
    //cancelled
    var total_cancelled_requests = 0;
    var total_cancelled_revenue = 0;
    //expired
    var total_expired_requests = 0;
    var total_expired_revenue = 0;
    //declined
    var total_declined_requests = 0;
    var total_declined_revenue = 0;

    for (var i = 0; i < requests.length; i++) {
      let request = requests[i];
      if (request.isToFromCreateTeam() === false) {
        let price = utils.convertToPrice(
          request.price,
          request.currency,
          masterCurrency
        );
        if (request.isCompleted()) {
          total_completed_requests++;
          total_completed_revenue = total_completed_revenue + price;
        } else if (request.isPending()) {
          total_pending_requests++;
          total_pending_revenue = total_pending_revenue + price;
        } else if (request.isCancelled()) {
          total_cancelled_requests++;
          total_cancelled_revenue = total_cancelled_revenue + price;
        } else if (request.isExpired()) {
          total_expired_requests++;
          total_expired_revenue = total_expired_revenue + price;
        } else if (request.isDeclined()) {
          total_declined_requests++;
          total_declined_revenue = total_declined_revenue + price;
        }
      }
    }

    total_requests =
      total_completed_requests +
      total_pending_requests +
      total_cancelled_requests +
      total_expired_requests +
      total_declined_requests;
    total_revenue =
      total_completed_revenue +
      total_pending_revenue +
      total_cancelled_revenue +
      total_expired_revenue +
      total_declined_revenue;

    utils.log.debug('masterCurrency....', masterCurrency);
    return {
      total_revenue,
      total_requests,
      total_completed_requests, //completed
      total_completed_revenue,
      total_pending_requests, //pending
      total_pending_revenue,
      total_cancelled_requests, //cancelled
      total_cancelled_revenue,
      total_declined_requests, //decined
      total_declined_revenue,
      total_expired_requests, //expired
      total_expired_revenue,
      currency: masterCurrency,
    };
  }

  /**
   * Fetches all categories from the db for the admin
   */
  async fetchAllPosts() {
    utils.log.debug('fetching all posts...');
    const db = firebase.firestore();
    const postsRef = db
      .collection(constants.FIREBASE_POSTS)
      .limit(250)
      .orderBy('updated_on', 'desc');
    const postsSnapshot = await postsRef.get();
    var posts = [];
    postsSnapshot.docs.forEach((element) => {
      // utils.log.debug("element...", element.data());
      let post = VLPost.fromJSON(element.data());
      posts.push(post);
    });
    const result = {};
    result.posts = posts;
    result.error = null;
    utils.log.debug('postsSnapshot...', posts);
    return result;
  }
  /**
   * Fetches all categories from the db for the admin
   */
  async fetchAllCategories() {
    utils.log.debug('fetching all categories...');
    const db = firebase.firestore();
    const categoryRef = db.collection(constants.FIREBASE_COLLECTION_CATEGORIES);
    const fetchCategoriesSnapshot = await categoryRef.get();
    var categories = [];
    fetchCategoriesSnapshot.docs.forEach((element) => {
      // utils.log.debug("element...", element.data());
      let category = VLCategory.fromJSON(element.data());
      if (category.isActive === true) {
        categories.push(category);
      }
    });
    const result = {};
    result.categories = categories;
    result.error = null;
    utils.log.debug('fetchCategoriesSnapshot...', categories);
    return result;
  }

  /**
   * Fetches all categories from the db for the admin
   */
  async fetchAffiliateDeals() {
    utils.log.debug('fetching affiliate deals...');
    const db = firebase.firestore();
    const affiliateRef = db
      .collection(constants.FIREBASE_COLLECTION_AFFILIATE_DEALS)
      .where('active', '==', true)
      .limit(100)
      .orderBy('last_updated', 'desc');

    const fetchAffiliateDealsSnapshot = await affiliateRef.get();
    var deals = [];
    fetchAffiliateDealsSnapshot.docs.forEach((element) => {
      utils.log.debug('deal json data...', element.data());
      let deal = VLAffiliateDeal.fromJSON(element.data());
      if (deal.active === true) {
        deal.user = VLUser.local(deal.creatorId);
        deals.push(deal);
      }
    });
    const result = {};
    result.deals = deals;
    result.error = null;
    utils.log.debug('fetchAffiliateDealsSnapshot...', deals);
    return result;
  }

  async createNewAffiliateDeal(creatorId, referralPercentage) {
    if (!creatorId || referralPercentage > 1.0) {
      return false;
    }
    utils.log.debug(
      'creating new affiliate deal...',
      creatorId,
      referralPercentage
    );
    const db = firebase.firestore();
    const affiliateRef = db
      .collection(constants.FIREBASE_COLLECTION_AFFILIATE_DEALS)
      .doc();

    let now = new Date();
    const obj = {};
    obj.id = affiliateRef.id;
    obj.creator_id = creatorId;
    obj.last_updated = now.getTime();
    obj.created_on = now.getTime();
    obj.referral_percent = referralPercentage;
    obj.active = true;

    utils.log.debug('creating new affiliate deal...', obj);

    return affiliateRef
      .set(obj)
      .then(function () {
        console.log('Document successfully written!');
        return true;
      })
      .catch(function (error) {
        console.error('Error writing document: ', error);
        return false;
      });
  }

  /**
   * Fetches all users from the db for the admin
   */
  async fetchAllUsers() {
    utils.log.debug('fetching all users...');
    const db = firebase.firestore();
    const usersRef = db.collection(constants.FIREBASE_COLLECTION_USERS);
    const fetchUsersSnapshot = await usersRef.get();
    var users = [];
    fetchUsersSnapshot.docs.forEach((element) => {
      // utils.log.debug("element...", element.data());
      let user = VLUser.fromJSON(element.data());
      if (user.profileId.toLowerCase() !== 'create') {
        users.push(user);
      }
    });
    const result = {};
    result.users = users;
    result.error = null;
    utils.log.debug('fetchUsersSnapshot...', users);
    return result;
  }

  /**
   * Fetches the 100 top creators (by number of bookings)
   */
  async fetchTopCreators() {
    if (!utils.currentUser()) {
      return null;
    }
    utils.log.debug('fetching top creators...', utils.currentUser());
    const db = firebase.firestore();
    var usersRef;

    if (utils.currentUser().isManager()) {
      usersRef = db
        .collection(constants.FIREBASE_COLLECTION_USERS)
        .where('manager_id', '==', utils.currentUser().managerId)
        .where('total_bookings', '>=', 3)
        .limit(100)
        .orderBy('total_bookings', 'desc');
    } else if (utils.currentUser().isAdmin()) {
      usersRef = db
        .collection(constants.FIREBASE_COLLECTION_USERS)
        .where('total_bookings', '>=', 1)
        .limit(1000);
    } else {
      return null;
    }

    const fetchUsersSnapshot = await usersRef.get();
    var users = [];
    var totalBookings = 0;
    var totalRevenue = 0;
    fetchUsersSnapshot.docs.forEach((element) => {
      // utils.log.debug("element...", element.data());
      let user = VLUser.fromJSON(element.data());
      if (user.profileId.toLowerCase() !== 'create') {
        users.push(user);
        totalBookings = totalBookings + user.totalCreations;
      }
    });

    users = _.orderBy(users, ['lastUpdated'], ['desc']);

    let average =
      Math.round((totalBookings / users.length + Number.EPSILON) * 10) / 10;
    const result = {};
    result.data = {
      title: `${totalBookings}`,
      detail: strings.parse(
        getI18n().t('admin_active_creators_detail'),
        average
      ),
      totalBookings: totalBookings,
      totalCreators: users.length,
    };
    result.users = users;
    result.error = null;
    utils.log.debug('fetchUsersSnapshot...', users);
    return result;
  }

  /**
   * Fetches the 100 top creators (by number of bookings)
   */
  async fetchActiveProfiles() {
    utils.log.debug('fetching active profiles...');

    if (!utils.currentUser()) {
      return null;
    }
    const db = firebase.firestore();
    var usersRef;

    if (utils.currentUser().isManager()) {
      usersRef = db
        .collection(constants.FIREBASE_COLLECTION_USERS)
        .where('manager_id', '==', utils.currentUser().managerId)
        .where('profileStatus', '>=', 3);
    } else if (utils.currentUser().isAdmin()) {
      usersRef = db
        .collection(constants.FIREBASE_COLLECTION_USERS)
        .where('profileStatus', '>=', 3);
    } else {
      return null;
    }

    const fetchUsersSnapshot = await usersRef.get();
    var users = [];
    fetchUsersSnapshot.docs.forEach((element) => {
      // utils.log.debug("element...", element.data());
      let user = VLUser.fromJSON(element.data());
      if (user.profileId && user.profileId.toLowerCase() !== 'create') {
        users.push(user);
      }
    });

    //sort by last updated
    utils.log.debug('before sort....', users);
    users = _.orderBy(users, ['lastUpdated'], ['desc']);
    utils.log.debug('after sort....', users);

    const result = {};
    result.data = {
      title: users.length,
      detail: 'Active creator (completed their profile)',
    };
    result.users = users;
    result.error = null;
    utils.log.debug('fetchUsersSnapshot...', users);
    return result;
  }

  /**
   * Fetches the 1000 most recently connected (by last updated)
   */
  async fetchRecentlyConnected() {
    utils.log.debug('fetching 1000 recently connected...');
    const db = firebase.firestore();
    var usersRef;
    if (utils.currentUser().isManager()) {
      usersRef = db
        .collection(constants.FIREBASE_COLLECTION_USERS)
        .where('manager_id', '==', utils.currentUser().managerId)
        .limit(500)
        .orderBy('last_updated', 'desc');
    } else if (utils.currentUser().isAdmin()) {
      usersRef = db
        .collection(constants.FIREBASE_COLLECTION_USERS)
        .limit(500)
        .orderBy('last_updated', 'desc');
    } else {
      return null;
    }
    const fetchUsersSnapshot = await usersRef.get();
    var users = [];
    var usersConnectedToday = 0;
    fetchUsersSnapshot.docs.forEach((element) => {
      // utils.log.debug("element...", element.data());
      let user = VLUser.fromJSON(element.data());
      if (user.profileId && user.profileId.toLowerCase() !== 'create') {
        users.push(user);
        if (dateUtils.isSameAsToday(new Date(user.lastUpdated))) {
          usersConnectedToday++;
        }
      }
    });

    const result = {};
    // users = _.orderBy(users, ['total_'],['desc']); // Use Lodash to sort array by 'name'
    result.data = {
      title: usersConnectedToday,
      detail: 'Users connected today',
    };
    result.users = users;
    result.error = null;
    utils.log.debug('fetchUsersSnapshot...', users);
    return result;
  }

  /**
   * Fetches the 1000 newest users (by created at)
   */
  async fetchNewlySignedUp() {
    utils.log.debug('fetching 1000 newest users...');
    if (!utils.currentUser()) {
      return null;
    }
    const db = firebase.firestore();
    var usersRef;
    if (utils.currentUser().isManager()) {
      usersRef = db
        .collection(constants.FIREBASE_COLLECTION_USERS)
        .where('manager_id', '==', utils.currentUser().managerId)
        .limit(2000)
        .orderBy('created_on', 'desc');
    } else if (utils.currentUser().isAdmin()) {
      usersRef = db
        .collection(constants.FIREBASE_COLLECTION_USERS)
        .limit(2000)
        .orderBy('created_on', 'desc');
    } else {
      return null;
    }
    const fetchUsersSnapshot = await usersRef.get();
    var users = [];
    var usersCreatedToday = 0;
    fetchUsersSnapshot.docs.forEach((element) => {
      // utils.log.debug("element...", element.data());
      let user = VLUser.fromJSON(element.data());
      if (user.profileId && user.profileId.toLowerCase() !== 'create') {
        if (dateUtils.isSameAsToday(new Date(user.createdOn))) {
          users.push(user);
          usersCreatedToday++;
        }
      }
    });

    const result = {};
    // users = _.orderBy(users, ['total_'],['desc']); // Use Lodash to sort array by 'name'
    result.users = users;
    result.data = { title: usersCreatedToday, detail: 'New Users Today' };
    result.error = null;
    // utils.log.debug("fetchUsersSnapshot...", users);
    return result;
  }

  async fetchUserAccountDetails(user) {
    const db = firebase.firestore();
    const accountRef = await db
      .collection(constants.FIREBASE_COLLECTION_ACCOUNT_DETAILS)
      .doc(user.id)
      .get();
    const adJSON = accountRef.data();

    let ad = VLAccountDetails.fromJSON(adJSON);
    user.accountDetails = ad;
    user.totalEarnings = ad.totalEarnings;
    user.saveToLocal();
    return ad;
  }

  async updateUserAccountBalance(userId, balanceInShekels) {
    const db = firebase.firestore();

    let updateObj = { balance: balanceInShekels };
    await db
      .collection(constants.FIREBASE_COLLECTION_ACCOUNT_DETAILS)
      .doc(userId)
      .update(updateObj);

    analytics.event(
      new AnalyticsEvent('Admin - updated user balance', {
        updated_user_id: userId,
      })
    );

    return balanceInShekels;
  }

  async fetchRequestsForUser(user) {
    const db = firebase.firestore();
    const requestsSnapshot = await db
      .collection(constants.FIREBASE_COLLECTION_REQUESTS)
      .where('persona_id', '==', user.id)
      .get();

    //2. Generate array of creators
    var requests = [];
    requestsSnapshot.docs.forEach((element) => {
      let request = VLBookingRequest.fromJSON(element.data());
      requests.push(request);
    });
    return requests;
  }

  async fetchKPIs() {
    utils.log.debug('fetching kpis');

    if (!utils.currentUser()) {
      return null;
    }
    const db = firebase.firestore();

    var bucketOfDays = []; //0... 29 days rolling, should be in stirng format [1/1, 1/2, 1/3...] for jan 1st, jan 2nd..

    var creatorCount = 0;
    var completedBookingCount = 0;
    var totalRevenueCount = 0;

    let now = new Date();

    var usersQuery;

    if (utils.currentUser().isManager()) {
      usersQuery = db
        .collection(constants.FIREBASE_COLLECTION_USERS)
        .where('total_bookings', '>', 0)
        .where('manager_id', '==', utils.currentUser().managerId)
        .orderBy('total_bookings', 'desc')
        .orderBy('created_on', 'desc');
    } else if (utils.currentUser().isAdmin()) {
      usersQuery = db
        .collection(constants.FIREBASE_COLLECTION_USERS)
        .where('total_bookings', '>', 0)
        .orderBy('total_bookings', 'desc')
        .orderBy('created_on', 'desc');
    } else {
      return [];
    }

    //1. Fetch active creators (have completed a booking)
    const fetchUsersSnapshot = await usersQuery.get();

    //2. Generate array of creators
    fetchUsersSnapshot.docs.forEach((element) => {
      let user = VLUser.fromJSON(element.data());
      let createdOn = new Date(user.createdOn);
      if (createdOn) {
        let daysAgo = dateUtils.daysBetweenDates(now, createdOn);
        // utils.log.debug("creator joined days ago...", daysAgo);
        let bucket = bucketOfDays[daysAgo];
        var count = 1;
        if (bucket) {
          count = bucket.creatorCount ? bucket.creatorCount + 1 : 1;
        } else {
          bucketOfDays[daysAgo] = {};
        }
        bucketOfDays[daysAgo].creatorCount = count;
      }
    });
    utils.log.debug('bucketOfDays..', bucketOfDays);

    var bookingQuery;
    if (utils.currentUser().isManager()) {
      utils.log.debug('fetching manager requests..');
      bookingQuery = db
        .collection(constants.FIREBASE_COLLECTION_REQUESTS)
        .where('status', '==', 15)
        .where('manager_id', '==', utils.currentUser().managerId)
        .orderBy('last_updated', 'desc');
    } else if (utils.currentUser().isAdmin()) {
      utils.log.debug('fetching admin requests..');
      bookingQuery = db
        .collection(constants.FIREBASE_COLLECTION_REQUESTS)
        .where('status', '==', 15)
        .orderBy('last_updated', 'desc');
    } else {
      return [];
    }

    //3. Generate array of total bookings and total revenue
    const bookingsSnapshot = await bookingQuery.get();

    bookingsSnapshot.docs.forEach((element) => {
      // utils.log.debug("analyzign requests...", element.data());
      let request = VLBookingRequest.fromJSON(element.data());
      let lastUpdated = new Date(request.lastUpdated);
      if (lastUpdated && request.isToFromCreateTeam() === false) {
        let daysAgo = dateUtils.daysBetweenDates(now, lastUpdated);
        // utils.log.debug("request last updated days ago...", daysAgo);
        let bucket = bucketOfDays[daysAgo];
        var bookingsCount = 1;
        var revenue = utils.convertToPrice(
          request.price,
          request.currency ? request.currency : masterCurrency,
          masterCurrency
        );
        if (bucket) {
          bookingsCount = bucket.bookingsCount ? bucket.bookingsCount + 1 : 1;
          revenue = bucket.bookingsRevenue
            ? bucket.bookingsRevenue + revenue
            : revenue;
        } else {
          bucketOfDays[daysAgo] = {};
        }
        bucketOfDays[daysAgo].bookingsCount = bookingsCount;
        bucketOfDays[daysAgo].bookingsRevenue = revenue;
      }
    });
    utils.log.debug('bucketOfDays pre clean..', bucketOfDays);

    //1. get the first 30
    bucketOfDays.length = 60;
    utils.log.debug('bucketOfDays first 30..', bucketOfDays);

    //2. Fill in the empty ones if needed, and set up the data
    var finalArray = [];
    for (let index = 0; index < bucketOfDays.length; index++) {
      var element = bucketOfDays[index];
      let adjustedDate = dateUtils.nowMinusDays(index);
      let name = dateUtils.getDayMonth(adjustedDate);
      if (!element) {
        const empty = {
          name: name,
          date: adjustedDate,
          creatorCount: 0,
          bookingsCount: 0,
          bookingsRevenue: 0,
        };
        finalArray[index] = empty;
      } else {
        var updated = element;
        updated.name = name;
        updated.date = adjustedDate;
        updated.creatorCount = updated.creatorCount ? updated.creatorCount : 0;
        updated.bookingsCount = updated.bookingsCount
          ? updated.bookingsCount
          : 0;
        updated.bookingsRevenue = updated.bookingsRevenue
          ? updated.bookingsRevenue
          : 0;
        finalArray[index] = updated;
      }
    }

    finalArray = finalArray.reverse();
    utils.log.debug('finalArray completed..', finalArray);
    return finalArray;
  }

  async updateBookingRequestValues(
    existingBookingRequest,
    agencyCut,
    affiliateCut,
    managerCut,
    createCut,
    donationCut,
    talentCut
  ) {
    utils.log.debug('saving booking request values...');
    let isValid =
      agencyCut +
        affiliateCut +
        managerCut +
        createCut +
        talentCut +
        donationCut ===
      1.0;
    if (isValid === false) {
      return false;
    }
    const db = firebase.firestore();
    let updateObj = {
      agency_cut: agencyCut,
      manager_cut: managerCut,
      affiliate_cut: affiliateCut,
      celebrate_cut: createCut,
      donation_cut: donationCut,
      talent_cut: talentCut,
    };

    const bookingRequestRef = await db
      .collection(constants.FIREBASE_COLLECTION_REQUESTS)
      .doc(existingBookingRequest.id)
      .update(updateObj);

    const updatedBookingRequest = await bookingRequestManager.fetchBookingRequest(
      existingBookingRequest.id
    );

    return updatedBookingRequest;
  }

  async createPayout(payout) {
    const url = `${api.CREATE_PAYOUT}`;

    //payout obj
    payout.resolver_id = utils.currentUser().id;
    payout.user_id = utils.currentUser().id;

    var body = JSON.stringify(payout);

    utils.log.debug('create payout params...', payout);

    /* refresh token */
    let headers = await userManager.getRequestHeaders();
    utils.log.debug('🏃‍♂️ creating payout...');
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    utils.log.debug('create payout result', json);
    let result = {};
    if (json.error) {
      let error = VLError.fromJSON(json.error);
      result.error = error;
      result.success = false;
      return result;
    } else {
      result.success = true;
      return result;
    }
  }

  /**
   * Fetches all categories from the db for the admin
   */
  async fetchReminders() {
    utils.log.debug('fetching remidners deals...');
    const db = firebase.firestore();
    const remindersRef = db
      .collection(constants.FIREBASE_COLLECTION_ADMIN)
      .doc('reminders')
      .collection('incomplete_booking_reminders')
      .limit(100)
      .orderBy('created_on', 'desc');

    const fetchRemindsSnapshot = await remindersRef.get();
    var reminders = [];
    fetchRemindsSnapshot.docs.forEach((element) => {
      reminders.push(new AdminNotificationReminder(element.data()));
    });
    const result = {};
    result.reminders = reminders;
    result.error = null;
    utils.log.debug('fetchRemindsSnapshot...', reminders);
    return result;
  }
}

var adminManager = new AdminManager();
export default adminManager;

export const fetchUserSnapshot = async (userId) => {
  const db = firebase.firestore();
  const userRef = await db
    .collection(constants.FIREBASE_COLLECTION_USERS)
    .doc(userId)
    .get();
  const userJSON = userRef.data();

  let user = VLUser.fromJSON(userJSON);
  if (user) {
    user.saveToLocal();
  }
  return user;
};

export const updatePostVisibility = async (postId, visibility) => {
  let updateObj = {};

  switch (visibility) {
    case PostVisibilityType.PUBLIC:
      updateObj = { visibility_type: PostVisibilityType.PUBLIC, public: true };
      break;
    case PostVisibilityType.PRIVATE:
      updateObj = {
        visibility_type: PostVisibilityType.PRIVATE,
        public: false,
      };
      break;
    case PostVisibilityType.SUBSCRIBERS_ONLY:
      updateObj = {
        visibility_type: PostVisibilityType.SUBSCRIBERS_ONLY,
        public: false,
      };
      break;
    default:
      break;
  }

  const db = firebase.firestore();
  try {
    await db.collection(constants.FIREBASE_POSTS).doc(postId).update(updateObj);
    return true;
  } catch (error) {
    return false;
  }
};

export const updatePostStar = async (postId, isStarred) => {
  let updateObj = {};
  updateObj = { starred: isStarred };

  const db = firebase.firestore();
  try {
    await db.collection(constants.FIREBASE_POSTS).doc(postId).update(updateObj);
    return true;
  } catch (error) {
    return false;
  }
};
