import React, { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import styles from './SignUp.module.scss';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { sendVerificationCode, signUp } from '../../store/routines';
import { SIGN_IN } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import * as Yup from 'yup';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { AnalyticsEvent, analytics } from '../../utils/analytics';
import userManager from '../../managers/userManager';
import PageLoader from '../../components/pageLoader';

/**
 * Loading component wrapper
 */
const LoadingView = (props: any) => {
  return props.showLoading ? (
    <div className="fixed-top w-100 h-100">
      <PageLoader darkOpacity={true} withBackground={true} />
    </div>
  ) : null;
};

const FormSection = (props: any) => {
  const { t, i18n } = useTranslation();

  const {
    values,
    onSubmit,
    onLoginClick,
    onCreateAccount,
    isHebrew,
    setFieldValue,
    errors,
    isValid,
    loading,
    handleSubmit,
    handleChange,
  } = props;
  const SubmitButton = () => {
    const isValid = isValidPhoneNumber(values.phone);
    return (
      <div className="text-center">
        <Button
          data-e2e="sign_up_button"
          className={classNames(styles.button, 'w-100', 'mt-4', 'px-3', 'py-3')}
          onClick={() => onCreateAccount()}
          disabled={loading}
        >
          {t('confirm_sign_up')}
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Form noValidate onSubmit={() => handleSubmit()}>
        <Form.Group controlId="firstname">
          <Form.Control
            data-e2e="firstname"
            className={styles.input}
            name="firstname"
            placeholder={t('first_name_placeholder')}
            aria-label={t('first_name_placeholder')}
            value={values.firstname}
            onChange={handleChange}
            isInvalid={!!errors.firstname}
            disabled={loading}
            tabIndex={1}
          />
          <Form.Control.Feedback type="invalid">
            {errors.firstname}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            data-e2e="lastname"
            className={styles.input}
            name="lastname"
            placeholder={t('last_name_placeholder')}
            aria-label={t('last_name_placeholder')}
            value={values.lastname}
            onChange={handleChange}
            isInvalid={!!errors.lastname}
            disabled={loading}
            tabIndex={2}
          />
          <Form.Control.Feedback type="invalid">
            {errors.lastname}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            data-e2e="email"
            className={styles.input}
            name="email"
            placeholder={t('email')}
            aria-label={t('email')}
            value={values.email}
            onChange={handleChange}
            isInvalid={!!errors.email}
            disabled={loading}
            tabIndex={3}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <PhoneInput
            data-e2e="phone"
            className={classNames(
              'form-control d-flex',
              styles.input,
              { 'is-invalid': !isValid },
              { rtl: i18n.language === 'he' }
            )}
            value={values.phone}
            defaultCountry={i18n.language === 'he' ? 'IL' : 'US'}
            placeholder={t('phone_number_in_form')}
            aria-label={t('phone_number_in_form')}
            disabled={loading}
            onChange={(value) => setFieldValue('phone', value)}
            tabIndex={4}
          />
          <Form.Control.Feedback type="invalid">
            {errors.phone}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Check
            data-e2e="checkbox"
            type="checkbox"
            className={classNames(styles.checkbox, {
              [styles.rtl]: isHebrew,
            })}
            label={t('agree_to_terms_signup')}
            id="agree_to_terms_signup"
            name="agree"
            onChange={handleChange}
          />
        </Form.Group>
      </Form>
      <SubmitButton />
      <div id="recaptcha-hidden-container" />
    </div>
  );
};

interface SignUpProps {
  isModal?: boolean;
  onLoginClick?: any;
  onSubmit?: any;
}

let analyticsSent = false;

const values = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  agree: false,
  link: '',
};

const SignUp: React.FC<SignUpProps> = ({ onLoginClick, isModal, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(t('required_firstname')),
    lastname: Yup.string().required(t('required_lastname')),
    email: Yup.string().email(t('valid_email')).required(t('required_email')),
    phone: Yup.string()
      .required(t('required_phone'))
      .test(
        'valid_phone',
        t('required_phone'),
        (value) => !!value && isValidPhoneNumber(value)
      ),
    agree: Yup.bool().equals([true]),
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const { confirmResult, loading, error } = useSelector<RootState, any>(
    (state) => state.auth.signIn
  );

  React.useEffect(() => {
    if (confirmResult) {
      if (isModal) {
        // console.log('confirmResult...');
        onLoginClick && onLoginClick();
      } else {
        history.push('/confirm');
      }
    }
  }, [confirmResult, history, isModal, onLoginClick]);

  const handleLoginClick = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(sendVerificationCode());
      if (onLoginClick) {
        onLoginClick();
        return;
      }
      history.push(SIGN_IN);
    },
    [dispatch, history, onLoginClick]
  );

  const isHebrew = i18n.language === 'he';

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      agree: false,
    },
    validationSchema,
    validateOnChange: false,
    onSubmit(values) {
      dispatch(signUp(values));
      onSubmit && onSubmit();
    },
  });

  if (!analyticsSent) {
    analytics.event(
      new AnalyticsEvent('signup - get started', [
        {
          language: isHebrew ? 'hebrew' : 'english',
        },
      ])
    );

    analyticsSent = true;
  }

  const Title = () => {
    return (
      <h1
        data-e2e="register_title"
        className={classNames(styles.title, { [styles.rtl]: isHebrew })}
      >
        {t('sign_up_screen_welcome')}
      </h1>
    );
  };

  const Detail = () => {
    return (
      <p
        data-e2e="register_subtitle"
        className={classNames(styles.subtitle, { [styles.rtl]: isHebrew })}
      >
        {t('fill_in_your_details')}
      </p>
    );
  };

  const ErrorAlert = () => {
    return error ? <Alert variant="danger">{error.message}</Alert> : null;
  };

  const BottomButton = () => {
    return (
      <div data-e2e="signUp_bottom" className={classNames(styles.bottom, '')}>
        {t('already_a_member')}&nbsp;
        <a href="" onClick={handleLoginClick}>
          {t('re_login')}
        </a>
      </div>
    );
  };

  /**
   * Sign up handler - creating an account for user
   * @param values
   */
  const onCreateAccount = () => {
    setShowLoading(true);

    /* Assign local var for display and signup */
    // console.log('onCreateAccount', values);

    //apply sign up
    userManager.startSignup(values).then((sentSMS) => {
      if (sentSMS) {
        //next step
        onSubmit();
      } else {
        setShowLoading(false);
        setErrorMsg(t('signup_failed_contact_suport'));
      }
    });
  };

  return (
    <div className="position-relative h-100 d-flex flex-column justify-content-center">
      <Title />
      <Detail />
      <ErrorAlert />
      <FormSection
        onCreateAccount={onCreateAccount}
        values={values}
        onLoginClick={onLoginClick}
        isHebrew={isHebrew}
        loading={loading}
        errors={errors}
        isValid={isValid}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      <BottomButton />
      <LoadingView showLoading={showLoading} />
    </div>
  );
};

SignUp.defaultProps = {
  isModal: false,
};

export default SignUp;
