import React from 'react';

export function useImageLoader(url: string, visible?: boolean) {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (visible) {
      const imageLoader = new Image();
      imageLoader.src = url;

      imageLoader.onload = () => {
        setLoaded(true);
      };

      delete imageLoader.src;
      delete imageLoader.onload;
    }
  }, [url, visible]);

  return loaded;
}
