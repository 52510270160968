import { createSlice } from '@reduxjs/toolkit';

/* Basic Activity Model */
const initialState = {
  bookingRequests: null, //vlBookingRequest objects
  lastUpdated: null,
};

const activity = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    // Redux actions
    updateBookingRequests(state, action) {
      state.bookingRequests = action.payload;
      state.lastUpdated = new Date().getTime();
    },
    resetAccountDetails(state, action) {
      state.bookingRequests = null;
      state.lastUpdated = null;
    },
  },
});

/* Export Redux Actions */
export const { updateBookingRequests, resetAccountDetails } = activity.actions;

/* Export the reducer */
export default activity.reducer;
