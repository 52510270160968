import React from 'react';
import ClassNames from 'classnames';
import styles from './AuthenticationScreen.module.scss';
import { Col, Container, Form, Row } from 'react-bootstrap';
import 'react-phone-number-input/style.css';

import { AuthDialog } from './AuthDialog';
import { withRouter } from 'react-router-dom';

import Logo from '../components/NewNavbar/logo.svg';
import { Image } from '../components/image/image.component';
import { analytics, AnalyticsEvent } from '../utils/analytics';

class AuthenticationScreen extends React.Component<any, any>
{
  componentDidMount(): void {
    analytics.event(new AnalyticsEvent("visit - signup"));
  }

  onSignupCompleted(user: any)
  {
    let nextUrl = this.redirectOnCompleted;
    window.location.href = nextUrl ? nextUrl : '/account';
  }

  get redirectOnCompleted(): any
  {
    let queryParams = new URLSearchParams(this.props.location.search);
    return queryParams.get("r");
  }

  get shouldShowSignin(): boolean
  {
    let { hash } = this.props.location;
    return hash === "#signin";
  }

  render()
  {
    return <div className={styles.signupScreen}>
      <Container className={ClassNames([styles.authContainer])} fluid="md">
        <header>
          <a href={"/"}><Image src={Logo} /></a>
          <h1>Liberating the world of content.</h1>
        </header>
        <main>
          <AuthDialog/>
        </main>
      </Container>
    </div>
  }
}

export default withRouter(AuthenticationScreen);