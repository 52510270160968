import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useTranslation } from 'react-i18next';
import { withOnboardingStage } from '../../../shared/hoc';

//Creator
import Lightning from '../images/explainer_lightning.png';
import Bank from '../images/explainer_bank@2x.png';
import Grow from '../images/explainer_grow@2x.png';
//Consumer
import LoveContent from '../images/explainer_love_content@2x.png';
import Search from '../images/explainer_search@2x.png';

//General
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../../../App';
import VLButton from '../../../Reusables/Buttons/VLButton';
import { useHistory } from 'react-router-dom';
import VLFlowManager, {
  OnboardingFlowEventCompletionScreen,
} from '../../../managers/v2/VLFlowManager';

import userManager from '../../../managers/userManager';

const useStyles = makeStyles((theme) => ({
  root: {},

  activeSceneLine: {
    background: '#5D36BD',
    opacity: 1.0,
    borderRadius: '4px',
    width: '26px',
  },
  sceneLine: {
    width: '10px',
    cursor: 'pointer',
    opacity: 0.5,
    background: '#DBCAFE',
    borderRadius: '4px',
  },
}));

const creatorExplainerValues = {
  title: 'onboarding_follow_completion_creator_title',
  detail: 'onboarding_follow_completion_creator_detail',
  image: LoveContent,
};

const consumerExplainValues = {
  title: 'onboarding_follow_completion_consumer_title',
  detail: 'onboarding_follow_completion_consumer_detail',
  image: Search,
};

const CompletionScreen = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { type, didApplyForVerification } = props;

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new OnboardingFlowEventCompletionScreen());
  });

  const value =
    type === 'creator' ? creatorExplainerValues : consumerExplainValues;

  const { title, detail, image } = value;

  const onDiscoverPressed = () => {
    userManager.userDidCompleteOnboarding();
    window.location = '/home'; //to trigger hard refresh
  };
  const onOpenProfilePressed = () => {
    userManager.userDidCompleteOnboarding();
    window.location = '/account'; //to trigger hard refresh
  };

  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box pl={2} pr={2} mt={4} mb={2}>
        <Typography variant="h2">
          <Box fontSize={24} fontWeight={700} color="text.secondary" mb={2}>
            {t(title)}
          </Box>
        </Typography>
        <Typography variant="body1">
          <Box
            display={
              !didApplyForVerification && type === 'creator' ? 'none' : 'block'
            }
            fontSize={16}
            fontWeight={600}
            color="text.secondary"
            lineHeight={1.3}
          >
            {t(detail)}
          </Box>
        </Typography>
        <Box
          mt={3}
          mb={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="192px" height="192px">
            <img alt="explainer" src={image} className="img-fluid" />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <VLButton onClick={() => onDiscoverPressed()} fullWidth={true}>
            {t('onboarding_follow_completion_cta_1')}
          </VLButton>
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <VLButton
            variant="default"
            onClick={() => onOpenProfilePressed()}
            fullWidth={true}
          >
            {t('onboarding_follow_completion_cta_2')}
          </VLButton>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(null, {})(withOnboardingStage(CompletionScreen));
