import { useHistory } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
} from '@material-ui/core';
import React, { useState, useRef, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { isHebrew } from '../../../../App';
import VLBigButton from '../../../../Reusables/Buttons/VLBigButton';
import VLButton from '../../../../Reusables/Buttons/VLButton';
import { localizedChevron } from '../../../../utils/uiutils';
import HowItWorks from '../../../Modal/HowItWorks/HowItWorks';
import { useTranslation } from 'react-i18next';
import { parse } from '../../../../utils/vlStrings';
import { ACTIVITY, CAMPAIGN_BOOKING_HOME } from '../../../../constants/routes';
import { reset } from '../../../../store/reducers/ordering';
import { startNewCampaignFromRegularOrder } from '../../../../store/reducers/campaigns';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    textAlign: 'center',
    letterSpacing: '-0.41px',
    lineHeight: '100%',
  },
  detail: {
    color: '#C8CACF',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: '120%',
  },
  detailContainer: {
    background: '#3A2D5C',
    borderRadius: '24px',
    height: '135px',
  },
  detailContainerTitle: {
    color: '#DBCAFE',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '-0.41px',
  },
  detailContainerDetail: {
    color: '#888888',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  priceDetail: {
    color: '#DBCAFE',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    letterSpacing: '-0.41px',
  },
  imageFirst: {
    overflow: 'hidden',
    borderRadius: '20px',
    height: '40px',
    width: '40px',
  },
  imageSecond: {
    marginLeft: '-10px',
    border: '4px solid #3A2D5C',
    overflow: 'hidden',
    borderRadius: '24px',
    height: '48px',
    width: '48px',
  },
  howItWorks: {
    '& $title': {
      textAlign: 'center',
      color: 'white',
      fontFamily: 'Assistant',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      letterSpacing: '-0.41px',
    },
  },
}));

const DetailContainer = (props) => {
  const { user, creator, onCTAClicked } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const title = t('order_summary');
  const detail = parse(
    parse(t('two_names'), user.fullName(), creator.fullName())
  );
  const ctatitle = t('open_inbox');

  const imageFirstURL = user.profilePictureURL;
  const imageSecondURL = creator.profilePictureURL;

  return (
    <Box mt={3} className={classes.detailContainer} p={2.5}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        justifyContent="space-between"
      >
        <Box>
          <Box className={classes.detailContainerTitle}>{title}</Box>
          <Box mt={1} className={classes.detailContainerDetail}>
            {detail}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box
            id="photos"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box id="image-first" className={classes.imageFirst}>
              <img
                alt="profile"
                src={imageFirstURL}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
            <Box id="image-second" className={classes.imageSecond}>
              <img
                alt="profile"
                src={imageSecondURL}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Box>
          <VLButton
            size="small"
            variant="text"
            color="primary"
            endIcon={localizedChevron()}
            onClick={onCTAClicked}
          >
            {ctatitle}
          </VLButton>
        </Box>
      </Box>
    </Box>
  );
};

const HowItWorksContainer = (props) => {
  const classes = useStyles();
  const title = 'How It Works';
  return (
    <Box id="how-it-works-container" className={classes.howItWorks} mt={5}>
      <Box mb={2} className={classes.title}>
        {title}
      </Box>
      <HowItWorks
        noGutters={true}
        hideClose={true}
        darkMode={true}
        hideWelcomeMessage={true}
      />
    </Box>
  );
};

const OrderSuccess = (props) => {
  const classes = useStyles();
  const [orderStep, setOrderStep] = useState('payment'); // details | payment
  const {
    show,
    user,
    creator,
    reset,
    startNewCampaignFromRegularOrder,
    allOrderDetails,
  } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    //check if we fetch creator or not
  }, []);

  if (!show) return null;

  const gotoInbox = () => {
    reset();
    history.push(ACTIVITY);
  };

  const orderFromMoreCreatorsClicked = () => {
    //duplicate important params to campaign!
    startNewCampaignFromRegularOrder({
      type: allOrderDetails.type,
      selectedEntity: allOrderDetails.selectedEntity,
      ocassion: allOrderDetails.ocassion,
      forWho: allOrderDetails.forWho,
      fromWho: allOrderDetails.fromWho,
      businessName: allOrderDetails.businessName,
      message: allOrderDetails.message,
      currency: allOrderDetails.currency,
    });
    //then reset
    reset();
    history.push(CAMPAIGN_BOOKING_HOME);
  };

  const hebrew = isHebrew();

  const title = t('order_success_generic_title');
  const detail = t('order_success_generic_detail');
  const button_title = t('order_from_more_creators');

  return (
    <Box className={classes.root} p={2}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box width="80%" textAlign="center">
          <Box width="100%" className={classes.title}>
            {title}
          </Box>
          <Box width="100%" mt={2} className={classes.detail}>
            {detail}
          </Box>
        </Box>
      </Box>
      <DetailContainer user={user} creator={creator} onCTAClicked={gotoInbox} />
      <Box mt={3}>
        <VLBigButton
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={() => orderFromMoreCreatorsClicked()}
        >
          {button_title}
        </VLBigButton>
      </Box>
      <HowItWorksContainer />
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    user: state.user.details,
    creator: state.ordering.creator,
    price: state.ordering.total_price,
    currency: state.ordering.currency,
    allOrderDetails: state.ordering,
  };
};

export default connect(mapStateToProps, {
  reset,
  startNewCampaignFromRegularOrder,
})(withRouter(OrderSuccess));
