import React, { useState, useEffect } from 'react';
import styles from './BookingProfile.module.scss';
import { useTranslation } from 'react-i18next';
import star from '../../Images/icon_star.svg';
import checkmark from '../../Images/checkmark.svg';
import Skeleton from 'react-loading-skeleton';
import { centerFlexColumn } from '../../../../utils/bootstrapUtils';

const BookingProfile = (props) => {
  const { t, i18n } = useTranslation();

  const getResponseTime = () => {
    let responseTime =
      props.User && props.User.responseTime ? props.User.responseTime : 0;

    if (responseTime === 0) {
      return t('booking_response_fast');
    } else if (typeof responseTime === 'number' && responseTime > 24) {
      let days = Math.floor(responseTime / 24);

      return `${days} ${t('days')}`;
    } else {
      return `${responseTime} ${t('hours')}`;
    }

    return '';
  };

  let profileId =
    props.User && props.User.profileId ? props.User.profileId : '';
  let name = props.User && props.User.fullName ? props.User.fullName() : '';
  let profilePic =
    props.User && props.User.profilePictureURL
      ? props.User.profilePictureURL
      : '';
  let subName = props.User && props.User.title ? props.User.title : ``;
  let rating = props.User && props.User.rating ? props.User.rating : ``;
  let verified =
    props.User && props.User.verified ? props.User.verified : false;

  const AverageResponseTime = () => {
    return name ? (
      <div>
        <div className={styles.responseTime}>
          {t('booking_profile_response_time')} <span>{getResponseTime()}</span>
        </div>
      </div>
    ) : null;
  };

  const hasLoaded = profileId;

  return (
    <div
      className={`${styles.BookingProfile} ${
        i18n.language === 'he' || !i18n.language || i18n.language === 'global'
          ? styles.heb
          : ''
      }`}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {profileId ? (
            t('booking_profile_title')
          ) : (
            <Skeleton width={180} count={1} height={40} />
          )}
        </div>
        <div className={styles.wrapperDown}>
          <div>
            <div className={styles.tag}>
              {profileId ? (
                `${t('booking_profile_from')} ${
                  i18n.language === 'he' ? `${profileId}@` : `@${profileId}`
                }`
              ) : (
                <Skeleton width={180} count={1} />
              )}
            </div>
            <div
              className={`${styles.profilePic} ${
                !profilePic ? styles.profilePicLoading : ''
              } ${centerFlexColumn()} position-relative`}
            >
              {profilePic ? (
                <img src={profilePic} className={styles.profilePic_img} />
              ) : (
                <div>
                  <Skeleton className={styles.noRows} width={140} count={9} />
                </div>
              )}

              {rating ? (
                <div className={styles.stars}>
                  <img src={star} className={styles.starIcon} />
                  <div style={{ marginLeft: '2px', marginRight: '2px' }}>
                    {rating}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div>
            <div className={styles.name}>
              {hasLoaded ? name : null}
              {verified ? (
                <img src={checkmark} className={styles.checkmark} />
              ) : null}
            </div>
            <div className={styles.subName}>{subName}</div>
          </div>
          {hasLoaded ? <AverageResponseTime /> : null}
        </div>
      </div>
    </div>
  );
};
export default BookingProfile;
