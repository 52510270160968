import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
} from '@material-ui/core';
import React, { useState, useRef, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { lclDirection } from '../../../utils/vlStrings';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import VLButton from '../../../Reusables/Buttons/VLButton';
import { Image } from '../../image/image.component';
import { vlImageVerifiedCheckmarkImage } from '../../../utils/vlImages';
import OrderWhoIsForBlock from './Blocks/Details/OrderWhoIsForBlock';
import OrderOccasionBlock from './Blocks/Details/OrderOccasionBlock';
import OrderNameEntryBlock from './Blocks/Details/OrderNameEntryBlock';
import OrderPriceBidBlock from './Blocks/Details/OrderPriceBidBlock';
import OrderDescriptionBlock from './Blocks/Details/OrderDescriptionBlock';
import OrderConfirmDetailsBlock from './Blocks/Details/OrderConfirmDetailsBlock';
import OrderCheckoutDetailBlock from './Blocks/Payment/OrderCheckoutDetailBlock';
import OrderEnterPaymentInfoBlock from './Blocks/Payment/OrderEnterPaymentInfoBlock';
import OrderAddPromoCodeBlock from './Blocks/Payment/OrderAddPromoCodeBlock';
import OrderConfirmPurchaseBlock from './Blocks/Payment/OrderConfirmPurchaseBlock';
import OrderSuccess from './Success';
import { isHebrew } from '../../../App';
import CloseIcon from '@material-ui/icons/Close';
import { localizedChevron } from '../../../utils/uiutils';
import {
  updateCreator,
  onForceUpdateStage,
  reset,
} from '../../../store/reducers/ordering';
import withErrorSnackbar from '../../../shared/hoc/with-error-snackbar';
import { useTranslation } from 'react-i18next';
import VLFlowManager, {
  BookingLandingV2,
} from '../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {
    left: 0,
    width: '100%',
    minHeight: '100vh',
    background: '#060423',
  },
  profilePicture: {
    borderRadius: '40px',
    width: '104px',
    height: '104px',
    overflow: 'hidden',
  },
  username: {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '17px',
    color: '#C8CACF',
  },
  fullname: {
    fontFamily: 'kre8font',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '100%',
    display: 'flex',
    color: '#FFFFFF',
  },
  title: {
    fontFamily: 'kre8font',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.15px',
    color: '#AE9BDE',
  },
  stepIndicatorSelected: { color: 'white' },
  stepIndicatorUnSelected: { color: '#868191' },
}));

const TopBar = (props) => {
  const { onBackPressed, showClose } = props;
  const { t } = useTranslation();
  const title = showClose ? t('close') : t('back');
  const icon = showClose ? (
    <Box p={1}>
      <CloseIcon />
    </Box>
  ) : (
    localizedChevron(true)
  );
  return (
    <Box
      width="100%"
      height="56px"
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
    >
      <VLButton
        variant="text"
        color="primary"
        startIcon={icon}
        onClick={onBackPressed}
      >
        {title}
      </VLButton>
    </Box>
  );
};

const CreatorSection = (props) => {
  const classes = useStyles();
  const { profilePictureURL, username, fullname, title, isVerified } = props;
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        mt={2}
        mb={1}
        justifyContent="flex-start"
      >
        <Box id="creator-profile-image" className={classes.profilePicture}>
          <Image src={profilePictureURL} />
        </Box>
        <Box
          id="creator-info"
          pl={2}
          pr={2}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box id="creator-username" className={classes.username} dir="ltr">
            @{username}
          </Box>
          <Box id="creator-name" className={classes.fullname}>
            {fullname}
            <Box
              display={isVerified ? 'flex' : 'none'}
              width="24px"
              ml={1}
              mr={1}
              height="24px"
            >
              <img
                style={{ height: '24px', width: '24px' }}
                src={vlImageVerifiedCheckmarkImage}
                alt="verified"
              />
            </Box>
          </Box>
          <Box id="creator-title" className={classes.title}>
            {title}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const StepIndicator = (props) => {
  const classes = useStyles();
  const { orderStage, setOrderStage } = props;
  const { t } = useTranslation();
  const StepView = (props) => {
    const { value, title, isSelected, setOrderStage } = props;
    return (
      <Box
        onClick={() => setOrderStage(value)}
        p={2}
        textAlign="center"
        className={
          isSelected
            ? classes.stepIndicatorSelected
            : classes.stepIndicatorUnSelected
        }
      >
        <Typography variant="subtitle2" color="inherit">
          <Box color="inherit">{title}</Box>
        </Typography>
        <Box color="inherit" fontSize="24px" mt={1}>
          •
        </Box>
      </Box>
    );
  };
  return (
    <Container maxWidth="xs">
      <Box display="flex" flexDirection="row" justifyContent="center">
        <StepView
          value="details"
          title={t('order_stage_1')}
          isSelected={orderStage === 'details'}
          setOrderStage={() => setOrderStage('details')}
        />
        <StepView
          value="payment"
          title={t('order_stage_2')}
          isSelected={orderStage === 'payment'}
          setOrderStage={() => setOrderStage('payment')}
        />
      </Box>
    </Container>
  );
};

const DetailsStep = (props) => {
  const { show } = props;
  const classes = useStyles();

  return (
    <Box display={show ? 'block' : 'none'} pb={5}>
      <OrderWhoIsForBlock />
      <OrderOccasionBlock />
      <OrderNameEntryBlock />
      <OrderPriceBidBlock />
      <OrderDescriptionBlock />
      <OrderConfirmDetailsBlock />
    </Box>
  );
};

const PaymentsStep = (props) => {
  const { show } = props;
  const classes = useStyles();
  if (!show) return null;
  return (
    <Box pb={5}>
      <OrderCheckoutDetailBlock />
      <OrderEnterPaymentInfoBlock />
      <OrderAddPromoCodeBlock />
      <OrderConfirmPurchaseBlock />
    </Box>
  );
};

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const OrdersPage = (props) => {
  const [creator, setCreator] = useState(props.creator);

  const {
    onBackPressed,
    orderStage,
    updateCreator,
    onForceUpdateStage,
    setShowError,
    canConfirmBooking,
    reset,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    //check if we fetch creator or not
    updateCreator(props.creator);

    VLFlowManager.sendFlowAnalytics(
      new BookingLandingV2(creator.profileId, creator.id)
    );
  }, [props.creator]);

  useEffect(() => {
    //check if we fetch creator or not
    if (props.error) setShowError(props.error);
  }, [props.error]);

  const showSuccess = orderStage === 'success';

  const setOrderStage = (stage) => {
    if (stage === 'payment') {
      if (canConfirmBooking) onForceUpdateStage(stage);
    } else {
      onForceUpdateStage(stage);
    }
  };

  const onLeaveScreen = (callback) => {
    reset();
    callback();
  };

  return (
    <div
      dir={lclDirection()}
      id="orders-page-main-root"
      style={{
        position: 'absolute',
        top: 75,
        left: 0,
        width: '100%',
        height: '100vh',
        zIndex: 1000,
        overflow: 'auto',
      }}
    >
      <Box className={classes.root}>
        <Container maxWidth="sm" disableGutters={true}>
          <TopBar
            onBackPressed={() => onLeaveScreen(onBackPressed)}
            showClose={showSuccess}
          />
          <Box style={{ display: showSuccess ? 'none' : 'block' }}>
            <CreatorSection
              profilePictureURL={creator.profilePictureURL}
              username={creator.profileId}
              fullname={creator.fullName()}
              title={creator.title}
              isVerified={creator.verified}
            />
            <StepIndicator
              orderStage={orderStage}
              setOrderStage={setOrderStage}
            />
          </Box>
          <Box pb={5}>
            <DetailsStep show={orderStage === 'details'} />
            <PaymentsStep show={orderStage === 'payment'} />
            <OrderSuccess show={orderStage === 'success'} />
          </Box>
        </Container>
      </Box>
    </div>
  );
};
const mapStateToProps = (state) => {
  const stage = state.ordering.stage;
  return {
    isConnected: state.user.isConnected,
    orderStage: stage,
    error: state.ordering.error,
    canConfirmBooking: state.ordering.canConfirmBooking,
  };
};

export default connect(mapStateToProps, {
  updateCreator,
  onForceUpdateStage,
  reset,
})(withErrorSnackbar(withRouter(OrdersPage)));
