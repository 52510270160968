import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './Footer.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { LANDING_V2 } from '../../constants/routes';
import Links from './Links';
import VLSubtitle from '../../Reusables/Texts/VLSubtitle';
import VLSmallButton from '../../Reusables/Buttons/VLSmallButton';

const Footer: React.FC<{ theme?: string }> = React.memo(({ theme = '' }) => {
  const { t } = useTranslation();
  // let theme = 'dark';
  // console.log(theme)

  let isDark = theme === 'dark';

  return (
    <Container
      fluid
      className={`${cn(
        styles.footer,
        styles.centerInMobile,
        `${!isDark ? 'py-3 my-5' : ''} `
      )} ${isDark ? styles.dark : ''}`}
    >
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <div className={styles.centerInMobileLogoContainer}>
              <div className={cn(styles.logo, 'my-4')} />
            </div>
            <p>
              Turn yourself into a digital supercreator
              <br />
              <br /> KRE8.TV is an online platform that makes it easy for
              creators to express themselves, monetize their work, and build a
              relationship with their audience. Our mission is to help creators
              around the world.
              <br />
              <br />
              <a href={`${LANDING_V2}`}>
                <VLSmallButton color="secondary">
                  Apply to our Supercreator program
                </VLSmallButton>
              </a>
            </p>
          </Col>
          <Col lg={6} md={6} sm={12} className="mt-3">
            <Links isDark={isDark} />
          </Col>
        </Row>
        <Row>
          <Col className="text-lg-right text-md-left" lg={12} md={6}>
            <div
              className={cn(
                styles.terms,
                'list-inline',
                'mb-4',
                'text-center',
                'mt-5'
              )}
            >
              <div className="list-inline-item mr-4">
                <a href="https://kre8.tv/termsofservice.html">
                  {t('terms_of_service')}
                </a>
              </div>
              <div className="list-inline-item mr-4">
                <a href="https://kre8.tv/privacypolicy.html">
                  {t('privacy_policy')}
                </a>
              </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Container>
  );
});

export default Footer;
