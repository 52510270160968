import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../App';

export const localizedChevron = (flipped = false) => {
  if (flipped) {
    return !isHebrew() ? <ChevronLeft /> : <ChevronRight />;
  } else {
    return isHebrew() ? <ChevronLeft /> : <ChevronRight />;
  }
};
