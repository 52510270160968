import userManager from '../managers/userManager';
import { parse, lclString, isHebrew } from '../utils/vlStrings';
import * as dateUtils from '../utils/dateUtils';
import * as utils from '../utils/rhutils';
import * as constants from '../constants/rhconstants';
import VLUser from './vlUser';
import memoize from 'lodash/memoize';
import React from 'react';

export const BookingRequestStatus = Object.freeze({
  PENDING: 0,
  DECLINED: 5,
  ACCEPTED: 10,
  DELIVERED: 15,
  CANCELLED: 20,
  AWAITING_PAYMENT: 25,
  EXPIRED: 30,
  ARCHIVED: 35,
  AWAITING_SYSTEM_APPROVAL: 40,
});

export const BookingType = Object.freeze({
  ON_DEMAND_CONTENT: 0, //aka CREATION, personal video / business video
  CUSTOM: 1, //zoom
});

function bookingStatusFromInt(value) {
  if (value < 5) {
    return BookingRequestStatus.PENDING;
  } else if (value === 5) {
    return BookingRequestStatus.DECLINED;
  } else if (value === 10) {
    return BookingRequestStatus.ACCEPTED;
  } else if (value === 15) {
    return BookingRequestStatus.DELIVERED;
  } else if (value === 20) {
    return BookingRequestStatus.CANCELLED;
  } else if (value === 25) {
    return BookingRequestStatus.AWAITING_PAYMENT;
  } else if (value === 30) {
    return BookingRequestStatus.EXPIRED;
  } else if (value === 35) {
    return BookingRequestStatus.ARCHIVED;
  } else if (value === 40) {
    return BookingRequestStatus.AWAITING_SYSTEM_APPROVAL;
  } else {
    return BookingRequestStatus.AWAITING_SYSTEM_APPROVAL;
  }
}

export function bookingStatusString(status) {
  switch (status) {
    case BookingRequestStatus.PENDING:
      return lclString('booking_status_pending');
    case BookingRequestStatus.DECLINED:
      return lclString('booking_status_declined');
    case BookingRequestStatus.ACCEPTED:
      return lclString('booking_status_accepted');
    case BookingRequestStatus.DELIVERED:
      return lclString('booking_status_delivered');
    case BookingRequestStatus.CANCELLED:
      return lclString('booking_status_cancelled');
    case BookingRequestStatus.AWAITING_PAYMENT:
      return lclString('booking_status_awaiting_payment');
    case BookingRequestStatus.EXPIRED:
      return lclString('booking_status_expired');
    case BookingRequestStatus.ARCHIVED:
      return lclString('booking_status_archived');
    default:
      return lclString('booking_status_archived');
  }
}

function bookingStatusStringLong(status, isIncoming) {
  switch (status) {
    case BookingRequestStatus.PENDING:
      return isIncoming
        ? lclString('request_click_to_upload_video')
        : lclString('booking_status_long_pending');
    case BookingRequestStatus.DECLINED:
      return lclString('booking_status_long_declined');
    case BookingRequestStatus.ACCEPTED:
      return lclString('booking_status_long_accepted');
    case BookingRequestStatus.DELIVERED:
      return lclString('booking_status_long_delivered');
    case BookingRequestStatus.CANCELLED:
      return lclString('booking_status_long_cancelled');
    case BookingRequestStatus.AWAITING_PAYMENT:
      return lclString('booking_status_long_awaiting_payment');
    case BookingRequestStatus.EXPIRED:
      return lclString('booking_status_long_expired');
    case BookingRequestStatus.ARCHIVED:
      return lclString('booking_status_long_archived');
    default:
      return lclString('booking_status_long_archived');
  }
}

class BookingRequest {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  id = '';
  requesterId = null;
  // requester: VLUser?;
  personaId = null;
  // persona: VLUser?;
  declineMessage = null;
  price = 0; //largest unit (100 = 100 shekels);
  currency = 'ILS';
  status = 0;
  requestDescription = null;
  requestDescriptionForCreator = null;
  forWho = null;
  fromWho = null;
  forWhoImageURL = null;
  fromWhoImageURL = null;
  forSomeoneElse = false;
  type = BookingType.ON_DEMAND_CONTENT;
  // deliveryDate: Date = Date(timeIntervalSince1970: 0);
  // lastUpdated: Date = Date(timeIntervalSince1970: 0);
  //When the requester viewed the video
  requesterViewDate = null;
  reviewed = false;
  /// Whether we remove balance directly from the user account details upon succesfull request
  withdrawFunds = false;
  businessRequest = false;
  adminDemoRequest = false;
  promotionId = null; //str
  actionURL = null;
  allowEdit = false;

  adminOverride = null;
  requesterAttachedImageURL = null;
  user = null;
  referralId = null;
  affiliateDealId = null;

  //cuts
  agencyCut = null;
  affiliateCut = null;
  managerCut = null;
  createCut = null;
  donationCut = null;
  talentCut = null;

  //payout
  payoutId = null;
  response = null;

  tip = null;

  //new props
  anonymousRequest = false;
  markedAsAllowPublicByRequester = false;

  matchesQuery(query) {
    let lcQuery = query.toLowerCase();
    if (this.user) {
      if (this.user.matchesQuery(lcQuery)) {
        return true;
      }
    }
    if (
      this.requestDescription &&
      this.requestDescription.toLowerCase().includes(lcQuery)
    ) {
      return true;
    } else if (
      this.requesterId.toLowerCase().includes(lcQuery) ||
      this.id.toLowerCase().includes(lcQuery) ||
      this.personaId.toLowerCase().includes(lcQuery)
    ) {
      return true;
    } else {
      return false;
    }
  }

  expirationDate() {
    let startDate = new Date(this.createdOn);
    let deadlineDate = dateUtils.datePlusDays(startDate, 7);
    return deadlineDate;
  }
  expiresInDateString() {
    return dateUtils.toDeadlineString(new Date(), this.expirationDate());
  }

  expirationThreshold = 2;
  expiringSoon() {
    let daysBetween = dateUtils.daysBetweenDates(
      this.expirationDate(),
      new Date()
    );

    return daysBetween < this.expirationThreshold;
  }

  hasDeadlineExpired() {
    return this.isPending() && new Date() > this.expirationDate();
  }

  lastUpdatedDateString() {
    let date = new Date(this.lastUpdated);
    return dateUtils.toLocalDateTimeString(date);
  }

  createdOnDateString(boring) {
    let date = new Date(this.createdOn);
    if (boring === true) {
      return dateUtils.toBoringDateTimeString(date);
    } else {
      return dateUtils.toLocalDateTimeString(date);
    }
  }

  requestedDateString(full) {
    if (!this.requestedDate) {
      return null;
    }
    let date = new Date(this.requestedDate);
    if (full === true) {
      return parse(
        lclString('date_to_deliver_request'),
        dateUtils.toLocalDateTimeString(date)
      );
    } else {
      return dateUtils.toLocalDateTimeString(date);
    }
  }

  outgoingFromToTitle() {}

  hasTip() {
    return this.tip !== null;
  }
  tipString() {
    let tip = this.tip;
    let currency = this.currency ? this.currency : 'ILS';

    var settings = {
      style: 'currency',
      currency: currency,
    };
    var formatter = new Intl.NumberFormat('en-US', settings);
    let priceStr = formatter.format(tip).replace(/\D00$/, '');
    return parse(lclString('tip_string'), priceStr);
  }
  priceString() {
    let price = this.price;
    let currency = this.currency ? this.currency : 'ILS';

    // console.log("currency =", currency);
    // console.log("price =", price);
    var settings = {
      style: 'currency',
      currency: currency,
    };
    var formatter = new Intl.NumberFormat('en-US', settings);

    // console.log("price...", price);
    let priceStr = formatter.format(price).replace(/\D00$/, '');
    // console.log("priceStr...", priceStr);
    let str = price === 0 ? lclString('free') : priceStr;
    // console.log("str...", str);
    return str;
  }

  totalPriceString() {
    let price = this.totalPaidPrice();
    let currency = this.currency ? this.currency : 'ILS';

    // console.log("currency =", currency);
    // console.log("price =", price);
    var settings = {
      style: 'currency',
      currency: currency,
    };
    var formatter = new Intl.NumberFormat('en-US', settings);

    // console.log("price...", price);
    let priceStr = formatter.format(price).replace(/\D00$/, '');
    // console.log("priceStr...", priceStr);
    let str = price === 0 ? lclString('free') : priceStr;
    // console.log("str...", str);
    return str;
  }

  localizedPrice(currency) {
    let price = this.totalPaidPrice();
    return utils.convertToPrice(price, this.currency, currency);
  }
  fromTitle() {
    return '';
  }
  isFree() {
    return this.price === 0;
  }
  isOutgoing() {
    return !this.isIncoming();
  }
  isIncoming() {
    return userManager.currentUserId === this.personaId;
  }

  readyForPayout() {
    return (
      this.isCompleted() &&
      utils.isNothing(this.payoutId) &&
      this.arePayoutPercentagesValid()
    );
  }

  arePayoutPercentagesValid() {
    let {
      agencyCut,
      affiliateCut,
      managerCut,
      createCut,
      talentCut,
      donationCut,
    } = this;
    return (
      agencyCut +
        affiliateCut +
        managerCut +
        createCut +
        talentCut +
        donationCut ===
      1.0
    );
  }

  requestTitlText() {
    return this.user?.fullName();
  }
  requestSubtitleText() {
    if (this.forSomeoneElse === true) {
      return parse(
        lclString('for_someone_else_request_label'),
        this.fromWho,
        this.forWho
      );
    } else {
      return parse(lclString('for_myself_request_label'), this.forWho);
    }
  }

  showStatusLabel() {
    if (this.type === BookingType.ON_DEMAND_CONTENT) {
      return true;
    } else {
      if (this.transactionId) {
        return true;
      }
      if (this.transactionId) {
        return true;
      } else if (this.actionURL) {
        return true;
      }
    }
    return false;
  }

  totalPaidPrice() {
    return this.price + this.tip;
  }
  isCellClickable() {
    return this.isReadyToWatch() === true;
  }
  isReadyToWatch() {
    return (
      this.bookingStatus === BookingRequestStatus.DELIVERED &&
      this.transactionId != null
    );
  }
  isReadyToUpload() {
    return this.bookingStatus === BookingRequestStatus.PENDING;
  }

  isCompleted() {
    return this.bookingStatus === BookingRequestStatus.DELIVERED;
  }

  isPending() {
    return this.bookingStatus === BookingRequestStatus.PENDING;
  }

  isCancelled() {
    return this.bookingStatus === BookingRequestStatus.CANCELLED;
  }

  isExpired() {
    return this.bookingStatus === BookingRequestStatus.EXPIRED;
  }

  isDeclined() {
    return this.bookingStatus === BookingRequestStatus.DECLINED;
  }

  canDecline() {
    return this.bookingStatus === BookingRequestStatus.PENDING;
  }

  setToCompleted() {
    this.bookingStatus = BookingRequestStatus.DELIVERED;
  }

  isToFromCreateTeam() {
    return (
      this.personaId === constants.CREATE_TEAM_ID ||
      this.requesterId === constants.CREATE_TEAM_ID
    );
  }

  relevantRequestDescription() {
    if (this.isIncoming()) {
      return this.requestDescriptionForCreator ?? this.requestDescription;
    } else {
      return this.requestDescription;
    }
  }
  isCurrentUserManager() {
    // utils.log.debug("this..", this);
    // utils.log.debug("userManager.currentUser..", userManager.currentUser);
    if (!userManager.currentUser) {
      return false;
    }
    return this.managerId === userManager.currentUser.managerId;
  }

  isForCurrentUser() {
    return this.requesterId === userManager.currentUser.id;
  }

  paypalOrderId() {
    if (this.paypal_data && this.paypal_data.orderID) {
      return this.paypal_data.orderID;
    } else {
      return null;
    }
  }

  renderStatus = () => (
    <div style={{ fontSize: 14, textTransform: 'lowercase' }}>
      <div
        className={`${isHebrew() ? 'ml-2' : 'mr-2'} status-icon ${
          this.bookingStatus === BookingRequestStatus.DELIVERED
            ? 'status-icon-full'
            : ''
        }`}
      ></div>
      {bookingStatusString(this.bookingStatus, this.isIncoming())}
    </div>
  );

  static fromJSON = memoize((json) => {
    var request = new BookingRequest();
    try {
      // console.log(json);
      //for now just use these
      request.referralId = json.referral_id;
      request.affiliateDealId = json.affiliate_deal_id;
      request.managerId = json.manager_id;
      request.id = json.id;
      request.personaId = json.persona_id;
      request.requesterId = json.requester_id;
      request.currency = json.currency;
      request.price = Number(json.price);
      request.lastUpdated = json.last_updated;
      request.createdOn = json.created_on;
      request.requestedDate = json.requested_date;
      request.requestDescription = json.description;
      request.requestDescriptionForCreator =
        json.description_for_creator ?? null;
      request.forSomeoneElse = Boolean(json.for_someone_else);
      request.forWho = json.for_who;
      request.fromWho = json.from_who;
      request.forWhoImageURL = json.for_who_image_url;
      request.fromWhoImageURL = json.from_who_image_url;
      request.bookingStatus = bookingStatusFromInt(Number(json.status));
      request.type = json.type ? json.type : request.type;
      request.transactionId = json.transaction_id ?? null;
      request.response = json.response ?? null;
      request.actionURL = json.action_url ?? null;
      request.allowEdit = json.allow_edit ?? false;
      request.businessRequest = json.business_request
        ? json.business_request
        : false;
      request.requesterViewDate = json.requester_view_date || null;
      request.reviewed = json.reviewed ? json.reviewed : false;
      request.paypal_payment = json.paypal_payment
        ? json.paypal_payment
        : false;
      request.paypal_data = json.paypal_data;

      //cuts
      request.agencyCut = json.agency_cut ? json.agency_cut : 0.0;
      request.affiliateCut = json.affiliate_cut ? json.affiliate_cut : 0.0;
      request.managerCut = json.manager_cut ? json.manager_cut : 0.0;
      request.createCut = json.celebrate_cut ? json.celebrate_cut : 0.0;
      request.donationCut = json.donation_cut ? json.donation_cut : 0.0;
      request.talentCut = json.talent_cut ? json.talent_cut : 0.0;

      //payouts
      request.payoutId = json.payout_id;

      //tip
      request.tip = json.tip ? json.tip : null;

      //new props
      request.anonymousRequest = request.marked_as_private_by_requester
        ? request.marked_as_private_by_requester
        : false;
      request.markedAsAllowPublicByRequester = request.marked_as_anonymous_by_requester
        ? request.marked_as_anonymous_by_requester
        : false;

      return request;
    } catch (error) {
      return null;
    }
  });

  static copy(object) {
    var br = new BookingRequest();
    for (const property in object) {
      var value;
      if (property === 'user') {
        value = VLUser.copy(object[property]);
      } else {
        value = object[property];
      }
      br[property] = value;
    }
    return br;
  }
}

export default BookingRequest;
