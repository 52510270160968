import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import React, { Component, Fragment, useEffect, useState } from 'react';

import Magic_Wand from './images/magic_wand.png';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../../../App';
import { useTranslation } from 'react-i18next';
import VLButton from '../../../Reusables/Buttons/VLButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

//Social icons
import InstagramIcon from './images/light_purple_instagram.png';
import TikTokIcon from './images/light_purple_tiktok.png';
import { useHistory } from 'react-router-dom';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import InfoDialog from '../Challenges/Detail/InfoDialog';
import { analytics, AnalyticsEvent } from '../../../utils/analytics';
import userManager from '../../../managers/userManager';
import { lclDirection } from '../../../utils/vlStrings';
import { validURL } from '../../../utils/rhutils';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    minHeight: '100vh',
    background:
      'radial-gradient(327.42% 139.78% at 141.33% -36.15%, #5D36BD 0%, #1E123E 100%)',
  },
  detail: {
    color: 'rgba(174, 155, 222, 1)',
    opacity: 0.9,
  },
  learnMore: {
    textTransform: 'none',
    color: 'white',
  },
  stepsTitles: {
    lineHeight: '24px',
    fontSize: '16px',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  textFieldRoot: {
    '& .MuiFormLabel-root': {
      color: 'white',
    },
    color: 'white',
    borderColor: 'white',
  },
  textField: {
    color: 'white',
    borderColor: 'white',
    '& p': {
      fontSize: '10px',
    },
  },
  helperText: {
    marginTop: '3px',
    color: 'white',
    fontFamily: 'Assistant',
    fontSize: '10px',
  },
  socialButton: {
    textTransform: 'none',
    color: '#DBCAFE',
    paddingLeft: 0,
    paddingRight: 0,
  },
  line: {
    marginTop: '2px',
    height: '2px',
    width: '100%',
    background: '#DBCAFE',
    borderRadius: '1px',
  },
  info: {
    color: '#DBCAFE',
  },
}));

const NumberBox = (props) => {
  return (
    <Box
      component="span"
      mr={isHebrew() ? 0 : 0.5}
      ml={isHebrew() ? 0.5 : 0}
    >{`${props.title}`}</Box>
  );
};

const SocialButton = (props) => {
  const classes = useStyles();
  const { title, image, onClick } = props;
  return (
    <Box id="cta" width="100%">
      <Button className={classes.socialButton} onClick={onClick}>
        <img
          style={{
            height: '24px',
            width: '24px',
            marginLeft: isHebrew() ? '5px' : 0,
            marginRight: isHebrew() ? 0 : '5px',
          }}
          src={image}
          alt="social"
        />
        {title}
        {isHebrew() ? (
          <ChevronLeft
            style={{
              height: '24px',
              width: '24px',
              marginLeft: !isHebrew() ? '5px' : 0,
              marginRight: !isHebrew() ? 0 : '5px',
            }}
          />
        ) : (
          <ChevronRight
            style={{
              height: '24px',
              width: '24px',
              marginLeft: !isHebrew() ? '5px' : 0,
              marginRight: !isHebrew() ? 0 : '5px',
            }}
          />
        )}
      </Button>
    </Box>
  );
};

const GetComissionScreen = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = props;
  const [copySuccess, setCopySuccess] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const [hasValidEntry, setHasValidEntry] = useState(false);
  const [submissionLink, setSubmissionlink] = useState(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    analytics.event(new AnalyticsEvent('get comission screen loaded'));
  });

  const copyLinkValue = `https://kre8.tv/${user.profileId}`;

  if (!user.id) {
    return (
      <Box
        className={classes.root}
        id="get-comission-banner-container"
        position="relative"
      >
        <Box
          height="100vh"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <CircularProgress
            size={24}
            color="white"
            style={{ color: 'white' }}
          />
        </Box>
      </Box>
    );
  }

  const onClosePressed = () => {
    history.goBack();
  };

  const onInfoPressed = () => {
    //do something
    setShowInfoDialog(true);
  };

  const onApplyPressed = () => {
    //do something
    setIsApplying(true);
    const properties = [{ link: submissionLink }];
    analytics.event(
      new AnalyticsEvent('user applied for no comission benefit', properties)
    );
    userManager.userAppliedForNoComissionBenefit();
    setTimeout(() => {
      history.goBack();
    }, 2500);
  };

  const onInstagramPressed = () => {
    window.open('https://instagram.com', '_blank');
  };

  const onTikTokPressed = () => {
    window.open('https://tiktok.com', '_blank');
  };

  const onCopySuccess = () => {
    navigator.clipboard.writeText(copyLinkValue);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2500);
  };

  const onLinkEntryTextFieldChanged = (e) => {
    const value = e.target.value;
    const isValid = validURL(value);
    if (isValid) {
      setHasValidEntry(true);
      setSubmissionlink(value);
    } else {
      setHasValidEntry(false);
      setSubmissionlink(null);
    }
  };

  const title = t('get_comission_screen_title');
  const step1 = t('get_comission_screen_step1');
  const step2 = t('get_comission_screen_step2');
  const step3 = t('get_comission_screen_step3');

  const info = t('get_comission_screen_info');
  return (
    <Box
      dir={lclDirection()}
      className={classes.root}
      id="get-comission-banner-container"
      position="relative"
    >
      <Container maxWidth="sm">
        <Box
          color="white"
          id="top-bar"
          pt={2}
          mb={2}
          height="64px"
          flexDirection="row"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <IconButton
            id="close-button"
            color="inherit"
            onClick={() => onClosePressed()}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            id="info-button"
            color="inherit"
            onClick={() => onInfoPressed()}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </Box>
        <Box p={2}>
          <Typography variant="h2">
            <Box textAlign="center" color="white" id="title">
              {title}
            </Box>
          </Typography>
          <Box
            id="image"
            flexDirection="column"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={Magic_Wand}
              alt="magic wand"
              style={{ width: '120px', height: '120px' }}
            />
          </Box>
          <Box id="step-1" mb={2}>
            <Typography variant="body1">
              <Box className={classes.stepsTitles} color="white">
                <NumberBox title="1." /> {step1}
              </Box>
            </Typography>
            <Box color="white">
              <TextField
                className={classes.textFieldRoot}
                value={copyLinkValue}
                fullWidth={true}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.textField,
                    root: classes.textField,
                  },
                  endAdornment: (
                    <InputAdornment
                      disableTypography
                      position="end"
                      onClick={() => onCopySuccess()}
                    >
                      {copySuccess ? <DoneAllIcon /> : <FileCopyOutlinedIcon />}
                    </InputAdornment>
                  ),
                }}
              />
              <Box className={classes.line} />
            </Box>
          </Box>
          <Box id="step-2" mb={2}>
            <Typography varian="body1">
              <Box color="white" className={classes.stepsTitles}>
                <NumberBox title="2." />
                {step2}
              </Box>
              <Box mb={2}>
                <SocialButton
                  image={InstagramIcon}
                  title={t('get_comission_open_instagram_button')}
                  onClick={() => onInstagramPressed()}
                />
              </Box>
              <Box mb={2}>
                <SocialButton
                  image={TikTokIcon}
                  title={t('get_comission_open_tiktok_button')}
                  onClick={() => onTikTokPressed()}
                />
              </Box>
            </Typography>
          </Box>
          <Box id="step-3" mb={2}>
            <Typography varian="body1">
              <Box color="white" className={classes.stepsTitles}>
                <NumberBox title="3." /> {step3}
              </Box>
            </Typography>
            <Box color="white">
              <TextField
                onChange={onLinkEntryTextFieldChanged}
                label={t('get_comission_screen_textfield_2_helper')}
                className={classes.textFieldRoot}
                fullWidth={true}
                inputProps={{
                  classes: {
                    input: classes.textField,
                    root: classes.textField,
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.textField,
                    root: classes.textField,
                  },
                }}
              />
              <Box className={classes.line} />
              <Box className={classes.helperText}>
                {t('get_comission_open_textfield_2_example')}
              </Box>
            </Box>
          </Box>
          <Box textAlign="center" mt={2} mb={2}>
            <Typography variant="body2">
              <Box color="white" className={classes.info}>
                {info}
              </Box>
            </Typography>
          </Box>
          <Box id="cta" width="100%" textAlign="center">
            <VLButton
              disabled={hasValidEntry === false}
              onClick={() => onApplyPressed()}
              style={{ width: '240px' }}
            >
              {isApplying ? (
                <CircularProgress
                  size={24}
                  color="white"
                  style={{ color: 'white' }}
                />
              ) : (
                t('get_comission_cta')
              )}
            </VLButton>
          </Box>
        </Box>
      </Container>
      <InfoDialog
        open={showInfoDialog}
        handleClose={() => setShowInfoDialog(false)}
        title={t('get_comission_info_modal_title')}
        body={t('get_comission_info_modal_detail')}
        buttonTitle={t('challenges_info_button_title')}
      />
    </Box>
  );
};

const mapStateToProps = ({ user }) => {
  return { user: user.details };
};

export default connect(mapStateToProps, null)(GetComissionScreen);
