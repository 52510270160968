import { createReducer } from "@reduxjs/toolkit";
import {setRegion} from "../../actions/global";

const initialState = {
  region: 'global',
};

export type GlobalState = typeof initialState;

export default createReducer<GlobalState>(initialState, {
  [setRegion.type]: (state, action) => ({
    ...state,
    region: action.payload,
  }),
});
