import React, { useState, useEffect } from 'react';
import styles from './BookingBoxEditable.module.scss';
import { useTranslation } from 'react-i18next';
import profile from '../../../Images/profile1.svg';
import { Spinner } from 'react-bootstrap';

const BookingBoxEditable = (props) => {
  const { t, i18n } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    if (props.showError && textValue.length <= 1) {
      setShowErrorText(true);
    }
  }, [props.showError]);

  const onChangeText = (e) => {
    if (e && e.target && e.target.value !== undefined) {
      props.onChange(e.target.value);
      setTextValue(e.target.value);

      if (e.target.value.length > 1 && showErrorText) {
        setShowErrorText(false);
      }
    }
  };

  return (
    <div
      className={`${styles.BookingBox} ${
        i18n.language === 'he' || i18n.language === 'global' || !i18n.language
          ? styles.heb
          : ''
      } ${props.showError ? styles.shake : ''}`}
    >
      <div className={styles.main}>
        <div className={styles.title}>{props.title}</div>
        <div className={`d-flex ${styles.profile}`}>
          {!props.imgLoading ? (
            <label for={`BookingBox_file_${props.title}`}>
              <img
                src={props.img ? props.img : profile}
                className={styles.image}
              />
            </label>
          ) : (
            <div className={`${styles.image} ${styles.imageLoading}`}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
          <input
            key={props.key}
            className={styles.input}
            placeholder={props.text}
            onChange={e => {
              onChangeText(e);
            }}
            onFocus={e => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
            autoFocus={props.autoFocus}
            value={props.value}
            readOnly={props.readOnly}
          />
          {showErrorText ? (
            <div className={styles.error}>{t('booking_editable_error')}</div>
          ) : (
            false
          )}
        </div>
      </div>
      <div className={styles.checkbox}>
        <input
          type="file"
          accept="image/*"
          id={`BookingBox_file_${props.title}`}
          style={{ display: 'none' }}
          onChange={(e) => {
            props.uploadImage(e.target);
          }}
        />
        <label
          for={`BookingBox_file_${props.title}`}
          className={styles.checkbox_text}
        >
          + {t('addPhoto')}
        </label>
      </div>
    </div>
  );
};
export default BookingBoxEditable;
