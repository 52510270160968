import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { getI18n, useTranslation } from 'react-i18next';
import { withOnboardingStage } from '../../../shared/hoc';

//Creator
import Lightning from '../images/explainer_lightning@2x.png';
import Bank from '../images/explainer_bank@2x.png';
import Grow from '../images/explainer_grow@2x.png';
//Consumer
import LoveContent from '../images/explainer_love_content@2x.png';
import Search from '../images/explainer_search@2x.png';
import Chat from '../images/explainer_chat@2x.png';

//General
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../../../App';
import VLFlowManager, {
  OnboardingFlowEventExplainers,
} from '../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {},

  activeSceneLine: {
    background: '#5D36BD',
    opacity: 1.0,
    borderRadius: '4px',
    width: '26px',
  },
  sceneLine: {
    width: '10px',
    cursor: 'pointer',
    opacity: 0.5,
    background: '#DBCAFE',
    borderRadius: '4px',
  },
}));

const creatorExplainerSteps = [
  {
    title: 'onboarding_explainer_creator_info_1',
    detail: 'onboarding_explainer_creator_info_detail_1',
    image: Lightning,
  },
  {
    title: 'onboarding_explainer_creator_info_2',
    detail: 'onboarding_explainer_creator_info_detail_2',
    image: Bank,
  },
  {
    title: 'onboarding_explainer_creator_info_3',
    detail: 'onboarding_explainer_creator_info_detail_3',
    image: Grow,
  },
];

const consumerExplainerSteps = [
  {
    title: 'onboarding_explainer_consumer_info_1',
    detail: 'onboarding_explainer_consumer_info_detail_1',
    image: LoveContent,
  },
  {
    title: 'onboarding_explainer_consumer_info_2',
    detail: 'onboarding_explainer_consumer_info_detail_2',
    image: Search,
  },
  {
    title: 'onboarding_explainer_consumer_info_3',
    detail: 'onboarding_explainer_consumer_info_detail_3',
    image: Chat,
  },
];

/**
 * Bottom component with internal steps and arrow buttons for navigation
 * @param {*} props
 * @returns
 */
const NavigationBox = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const {
    type,
    updateType,
    stages,
    current,
    onBackPressed,
    onNextPressed,
  } = props;

  const scenes = [...Array(stages)].map((x) => 0);
  return (
    <Box className={classes.typeBox} width="100%" p={3}>
      <Box
        mt={2}
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="center"
      >
        {scenes.map((scene, index) => (
          <Box
            m={0.5}
            mb={2}
            height="4px"
            className={`${
              index === current ? classes.activeSceneLine : classes.sceneLine
            }`}
          ></Box>
        ))}
      </Box>
      <Box
        color="white"
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Box m={1}>
          <Button
            style={{ textTransform: 'none', color: 'white' }}
            onClick={() => onBackPressed()}
          >
            {!isHebrew() ? <ChevronLeft /> : <ChevronRight />}
            {t('onboarding_explainer_button_prev')}
          </Button>
        </Box>
        <Box m={1}>
          <Button
            style={{ textTransform: 'none', color: 'white' }}
            onClick={() => onNextPressed()}
          >
            {t('onboarding_explainer_button_next')}
            {isHebrew() ? <ChevronLeft /> : <ChevronRight />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const maxSteps = 3;
const Explainers = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [internalStep, setInternalStep] = useState(0);

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new OnboardingFlowEventExplainers());
  });

  const { type } = props;

  const values =
    type === 'creator' ? creatorExplainerSteps : consumerExplainerSteps;

  const value = values[internalStep];
  const { title, detail, image } = value;

  const onNextPressed = () => {
    if (internalStep + 1 === maxSteps) {
      props.onConfirm();
    } else {
      setInternalStep(Math.min(2, internalStep + 1));
    }
  };

  const onBackPressed = () => {
    if (internalStep - 1 < 0) {
      props.onBack();
    } else {
      setInternalStep(Math.max(0, internalStep - 1));
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box height="50%" pl={2} pr={2} mt={4} mb={2}>
        <Typography variant="h2">
          <Box fontSize={24} fontWeight={700} color="text.secondary" mb={2}>
            {t(title)}
          </Box>
        </Typography>
        <Typography variant="body1">
          <Box
            fontSize={16}
            fontWeight={600}
            color="text.secondary"
            lineHeight={1.3}
          >
            {t(detail)}
          </Box>
        </Typography>
        <Box
          mt={3}
          mb={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="192px" height="192px" onClick={() => onNextPressed()}>
            <img alt="explainer" src={image} className="img-fluid" />
          </Box>
        </Box>
        <NavigationBox
          stages={maxSteps}
          current={internalStep}
          onNextPressed={() => onNextPressed()}
          onBackPressed={() => onBackPressed()}
        />
      </Box>
    </Box>
  );
};

export default connect(null, null)(withOnboardingStage(Explainers));
