import React, { Component, useEffect, useState } from 'react';
import { isHebrew } from '../../../App';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textDetail: {
    color: 'rgba(255, 255, 255, 0.8)',
  },
}));

export const HeadingText = (props) => {
  return (
    <Typography variant="h6">
      <Box lineHeight={1.2} fontWeight="bold" color="white" {...props}>
        {props.children}
      </Box>
    </Typography>
  );
};

export const BodyText = (props) => {
  const classes = useStyles({ isHebrew: isHebrew() });
  return (
    <Typography variant="body2">
      <Box lineHeight={1.4} className={classes.textDetail} {...props}>
        {props.children}
      </Box>
    </Typography>
  );
};
