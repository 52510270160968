import React, { useState, useEffect } from 'react';
import styles from './EditProfileTop.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import main_img from '../Images/main_image.svg';
import profileManager from '../../managers/profileManager';
import VLSpinner from '../../components/loader/VLSpinner';
import { centerFlexColumn } from '../../utils/bootstrapUtils';
import VLWaveProgressLoader from '../../components/loader/VLWaveProgressLoader';
import userManager from '../../managers/userManager';
import { Box, Button } from '@material-ui/core';
import { MdFlashOn } from 'react-icons/md';

/* the top area of the profile page */
const EditProfileTop = (props) => {
  const { t, i18n } = useTranslation();

  const user = props.user;
  // console.log('strength for user:', user);
  const strength = profileManager.profileStrength(user);
  // console.log('strength:', strength);
  const percent = strength[0] * 100;
  const title = strength[1];
  const color = strength[2];

  const Content = () => {
    /**
     * Admin edit component for admins only (temp-solution until admin dashboard done)
     */
    const AdminEditButton = () => {
      const isCurrrentUserAdmin = userManager.getUser().isAdmin();
      // console.log('isCurrrentUserAdmin:', isCurrrentUserAdmin);
      if (isCurrrentUserAdmin === false) {
        return null;
      }
      return (
        <div
          className={`${styles.AdminEditButton}`}
          onClick={() => {
            props.setShowScreen('admin');
          }}
        >
          {/* <div>ADMIN EDIT</div>
          <div>{user.id}</div> */}
          <Button variant="contained" color="primary" startIcon={<MdFlashOn />}>
            Admin Edit
          </Button>
        </div>
      );
    };

    return !user ? (
      <div
        className={`position-relative ${centerFlexColumn()}`}
        style={{ height: '220px', paddingTop: '80px' }}
      >
        <VLSpinner style={{ width: '50px', height: '50px' }} />
      </div>
    ) : (
      <div className={styles.title}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {user.fullName()}
          <AdminEditButton />
        </Box>
        <div className={`${styles.box} ${centerFlexColumn()}`}>
          <div
            className={`${styles.StrengthContainer} d-flex flex-row justify-content-between`}
          >
            <div className={`${styles.box_text}`}>
              <div className={styles.box_text_title}>
                {t('editProfile_strengh')}
              </div>
              <div
                className={styles.box_text_subTitle}
                style={{ color: `#${color}` }}
              >
                <span>{`${percent}%`}</span> {title}
              </div>
            </div>
            <div className={`${styles.ProgressContainer}`}>
              <VLWaveProgressLoader percent={strength[0]} color={color} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${styles.EditProfileTop} ${
        i18n.language === 'he' ? styles.heb : ''
      }`}
    >
      <Content />
    </div>
  );
};

export default withRouter(EditProfileTop);
