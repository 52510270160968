import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Container, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BrushImage from '../images/campaign_welcome_icon.png';
import VLButton from '../../../../../Reusables/Buttons/VLButton';
import { withBookingStage } from '../../../../../shared/hoc';
import { updateType } from '../../../../../store/reducers/campaigns/';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
  },
  typeBox: {
    height: '120px',
    cursor: 'pointer',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
  },
}));

const TypeBox = (props) => {
  const classes = useStyles();
  const {
    title,
    detail,
    bgcolor,
    titlecolor,
    detailcolor,
    type,
    updateType,
  } = props;
  return (
    <Box
      onClick={() => updateType(type)}
      className={classes.typeBox}
      mt={2}
      mb={2}
      width="100%"
      p={3}
      bgcolor={bgcolor}
      borderRadius={30}
    >
      <Typography variant="h2">
        <Box fontSize="18px" color={titlecolor}>
          {title}
        </Box>
      </Typography>
      <Typography variant="body1">
        <Box mt={1} color={detailcolor}>
          {detail}
        </Box>
      </Typography>
    </Box>
  );
};

const WhoForScreen = (props) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const title = t('campaigns_who_for');
  const personalTitle = t('campaigns_who_for_type_personal');
  const personalExamples = t('campaigns_who_for_type_personal_examples');
  const businessTitle = t('campaigns_who_for_type_business');
  const businessExamples = t('campaigns_who_for_type_business_examples');

  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box height="50%" p={4}>
        <Typography variant="h5">
          <Box fontWeight="bold" color="text.primary" textAlign="center" mb={2}>
            {title}
          </Box>
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <TypeBox
            updateType={props.updateType}
            type="personal"
            titlecolor="primary.main"
            detailcolor="black"
            bgcolor="secondary.main"
            title={personalTitle}
            detail={personalExamples}
          />
          <TypeBox
            updateType={props.updateType}
            type="business"
            titlecolor="secondary.main"
            detailcolor="#E3E3EE"
            bgcolor="primary.main"
            title={businessTitle}
            detail={businessExamples}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default connect(null, { updateType })(withBookingStage(WhoForScreen));
