import React from 'react';
import styles from '../AuthDialog.module.scss';
import { withTranslation } from 'react-i18next';
import { RoundLoader } from '../../components/loader/roundLoader.component';
import { analytics, AnalyticsEvent } from '../../utils/analytics';

class VerifyPhoneNumberBody extends React.Component<any, any> {
  inputRefs?: Array<HTMLInputElement> = [];

  constructor(props: any) {
    super(props);

    this.state = {
      code: [],
    };
  }

  componentDidMount(): void {
    analytics.event(new AnalyticsEvent('signup - verify'));

    if (this.inputRefs) this.inputRefs[0].focus();
  }

  onChange(input: number, value: any) {
    let { code } = this.state;
    code[input] = value;

    let validChars = code.filter((char: string) => /[0-9]/.test(char));

    if (validChars.length === 6) {
      if (this.inputRefs) this.inputRefs[input].blur();
      this.props.verifyCode(code.join(''));

      return;
    }

    this.setState({ code });

    if (/[0-9]/.test(value)) this.nextInput(input);
  }

  /**
   * Trigger a resend SMS
   * @param event
   */
  resendCode(event: any) {
    analytics.event(new AnalyticsEvent('signin - resend verification code'));
    this.props.resendSms();
  }

  nextInput(currentInput: number) {
    let nextInput = currentInput + 1;

    if (this.inputRefs) {
      this.inputRefs[nextInput].focus();
      this.inputRefs[nextInput].select();
    }

    return this;
  }

  render() {
    let { t, className, i18n, loading, user, error } = this.props;
    let { code } = this.state;

    let inputs = [0, 1, 2, 3, 4, 5];

    return [
      <div className={className}>
        <strong>
          {user.profile && user.profile.phone ? user.profile.phone : '+'}
        </strong>

        <div className={styles.codeWrapper}>
          {inputs.map((input, key) => {
            return (
              <input
                key={`inp.${key}`}
                ref={(ref: HTMLInputElement) =>
                  this.inputRefs ? (this.inputRefs[input] = ref) : null
                }
                type={'number'}
                value={code[input]}
                tabIndex={input + 1}
                onChange={(event) => this.onChange(input, event.target.value)}
                max={'9'}
              />
            );
          })}
        </div>

        {error && <span className={styles.errorWrapper}>{error}</span>}
        <div className={styles.resendWrapper}>
          <button onClick={this.resendCode.bind(this)}>
            <span>{t('resend_code')}</span>
          </button>
        </div>
      </div>,
      <span>
        {loading && (
          <div className={styles.loadingOverlay}>
            <RoundLoader />
          </div>
        )}
      </span>,
    ];
  }
}

export default withTranslation()(VerifyPhoneNumberBody);
