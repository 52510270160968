import { Box, CircularProgress, Container, Modal } from '@material-ui/core';
import React, { useState, useRef, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import ClaimAccountCard from './ClaimAccountCard';

import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import styles from './ClaimAccountScreen.module.scss';
import { centerFlexColumn } from '../../../utils/bootstrapUtils';

import AppLogoWhite from '../../../assets/logo/main_logo_white.svg';
import landingScreenBackgroundImage from '../../landingPage/images/screen_bg.webp';
import { AuthModal } from '../../../signup/AuthModal';
import userManager from '../../../managers/userManager';
import profileManager from '../../../managers/profileManager';

import { Fade, Zoom } from '@material-ui/core';
import { AnalyticsEvent, analytics } from '../../../utils/analytics';
import recruitmentManager from '../../../managers/recruitmentManager';
import { AirtableManager } from '../../../VLBase/Managers/VLAirtableManager';

const airtable = new AirtableManager();

const BgImage = () => {
  return (
    <div className={`${styles.heroBackgroundImage}`}>
      <img alt="background" src={landingScreenBackgroundImage}></img>
    </div>
  );
};

const Titles = () => {
  return (
    <Box className={styles.titles}>
      <Box className={`${styles.welcome} m-3`}>Welcome to</Box>
      <Box className="m-3">
        <Box className={`${styles.logo}`}>
          <img alt="logo" src={AppLogoWhite}></img>
        </Box>
        <Box className={`${styles.subtitle} mt-1`}>
          Earn what you DESERVE from your content
        </Box>
      </Box>
    </Box>
  );
};
const BottomSection = (props) => {
  const { isSettingUp } = props;
  return (
    <Zoom in={true} timeout={1000} style={{ transitionDelay: `2500ms` }}>
      <Fade in={true} timeout={1000}>
        <Box
          className={styles.bottomSection}
          justifyContent="center"
          display="flex"
          textAlign="center"
          flexDirection="column"
        >
          <Box
            justifyContent="center"
            display="flex"
            className="mt-2 mb-3 pl-2 pr-2"
          >
            <Box>
              <div className={styles.info}>
                Limited time offer - You’ll get to keep 100% of your earnings
                after payment processing fees. Offer valid only today.
              </div>
            </Box>
          </Box>
          <Box>
            <Box>
              <div
                onClick={() => isSettingUp === false && props.onCTAClick()}
                className={`${
                  styles.cta
                } btn btn-primary font-weight-bold ${centerFlexColumn()}`}
              >
                {isSettingUp ? (
                  <CircularProgress color="white" />
                ) : (
                  'Claim your account now'
                )}
              </div>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Zoom>
  );
};

const defaultDescriptionMessage = `💰 Easily accept bookings\n🧳 Brand and business deals\n⚡️ 100+ creator supertools and integreations`;

let _username = null;
const ClaimAccountScreen = (props) => {
  const [showAuth, setShowAuth] = useState(false);
  const [isSettingUp, setIsSettingUp] = useState(false);
  const [prefilledDetails, setPrefilledDetails] = useState(null);

  const onAuthSuccess = async () => {
    analytics.event(
      new AnalyticsEvent('web - flow - claim account - auth completed', {
        username: _username,
      })
    );

    //here we should save / copy their info to their logged in account
    setShowAuth(false);

    setIsSettingUp(true);
    //now we should start setup with user airtable data

    const attemptSave = await recruitmentManager.saveClaimAccountDetailsToCurrentUser(
      prefilledDetails
    );

    let { success, error } = attemptSave;

    if (!success) {
      console.error('error running function');
      window.alert(
        error ??
          'Error completing onboarding. Please contact a team member for further instructions.'
      );
      analytics.event(
        new AnalyticsEvent('web - flow - claim account - error', {
          username: _username,
        })
      );
    } else {
      analytics.event(
        new AnalyticsEvent('web - flow - claim account - completed', {
          username: _username,
        })
      );
    }
    //move to next
    // console.log('attemptSave = ', attemptSave);

    //on - finish -> redirect to where? lolz
    // setIsSettingUp(false);

    goToAccount();
  };

  useEffect(() => {
    //profile_id
    const queryParams = new URLSearchParams(props.location.search);
    let username = queryParams.get('id');

    analytics.event(
      new AnalyticsEvent('web - flow - claim account - initial', {})
    );

    if (!username) {
      //bad - no profile id
      props.history.replace('/');
      return;
    }

    _username = username;

    analytics.event(
      new AnalyticsEvent('web - flow - claim account - started', {
        username: username,
      })
    );

    //fetch data
    airtable.fetchCreatorAirtableInfo(username, (result, error) => {
      if (result) {
        setPrefilledDetails(result);
        recruitmentManager.cacheDetails(result);
      } else {
        //nop - error
        if (!result) {
          //bad - no profile id
          props.history.replace('/');
          return;
        }
      }
    });
  }, []);

  const onCTAClicked = () => {
    if (props.isConnected) {
      // props.history.replace('/account');
      window.alert('You are already logged in!');
    } else {
      analytics.event(
        new AnalyticsEvent('web - flow - claim account - cta clicked', {
          username: _username,
        })
      );
      setShowAuth(true);
    }
  };

  const goToAccount = () => {
    props.history.replace('/account');
  };

  return (
    <Box
      width="100vw"
      height="100vh"
      justifyContent="center"
      display="flex"
      className={styles.root}
    >
      <BgImage />
      <Container maxWidth="sm" className={styles.container}>
        <Box
          height="100%"
          justifyContent="center"
          display="flex"
          className="pt-4 pb-4"
        >
          <Container maxWidth="md" style={{ height: '100%' }}>
            <Box
              height="100%"
              display="flex"
              alignContent="space-between"
              flexDirection="column"
            >
              <Box>
                <Titles />
              </Box>
              <Box
                className="mt-2 mb-2"
                flexGrow={1}
                justifyContent="center"
                display="flex"
              >
                {prefilledDetails ? (
                  <ClaimAccountCard
                    disableCta={isSettingUp}
                    username={prefilledDetails.username}
                    firstName={prefilledDetails.firstName}
                    lastName={prefilledDetails.lastName}
                    title={prefilledDetails.title}
                    description={
                      prefilledDetails.description &&
                      prefilledDetails.description.length > 5
                        ? prefilledDetails.description
                        : defaultDescriptionMessage
                    }
                    profilePictureUrl={
                      prefilledDetails.profilePictureUrl ??
                      'https://res.cloudinary.com/kre8-tv/image/upload/v1621501050/kre8.tv/placeholders/placeholder_binary_c8i0j6.png'
                    }
                    onCTAClick={() => onCTAClicked()}
                  />
                ) : (
                  <Box
                    color="white"
                    height="100%"
                    flexDirection="column"
                    display="flex"
                    alignContent="center"
                    justifyContent="center"
                  >
                    <CircularProgress size={44} />
                  </Box>
                )}
              </Box>
              <Box justifyContent="center" display="flex">
                <BottomSection
                  onCTAClick={() => onCTAClicked()}
                  isSettingUp={isSettingUp}
                />
              </Box>
            </Box>
          </Container>
        </Box>
        <AuthModal
          detail="Earn what you DESERVE from your content"
          forceToPhoneAuthOnly={true}
          redirectToLink=""
          show={showAuth}
          onAuthSuccess={onAuthSuccess}
          onClose={() => {
            setShowAuth(false);
          }}
        />
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return { isConnected: state.user.isConnected };
};

export default connect(mapStateToProps)(withRouter(ClaimAccountScreen));
