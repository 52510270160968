import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useTranslation } from 'react-i18next';
import { withOnboardingStage } from '../../../shared/hoc';

import CreatorTypeImage from '../images/create_and_earn_image.svg';
import ConsumerTypeImage from '../images/explore_and_purchase_image.svg';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../../../App';
import { OnboardingFlowEventWelcome } from '../../../managers/v2';
import VLFlowManager from '../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {},
  typeBox: {
    height: '165px',
    cursor: 'pointer',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
    [theme.breakpoints.down('xs')]: {
      height: '175px',
    },
  },
}));

const TypeBox = (props) => {
  const classes = useStyles();
  const {
    title,
    detail,
    bgcolor,
    titlecolor,
    detailcolor,
    type,
    onTypeSelected,
    image,
  } = props;
  return (
    <Box
      onClick={() => onTypeSelected(type)}
      className={classes.typeBox}
      mt={2}
      mb={2}
      width="100%"
      p={3}
      bgcolor={bgcolor}
      borderRadius={30}
    >
      <Typography variant="h2">
        <Box fontSize="18px" color={titlecolor}>
          {title}
        </Box>
      </Typography>
      <Typography variant="body1">
        <Box mt={1} color={detailcolor}>
          {detail}
        </Box>
      </Typography>
      <Box
        mt={2}
        width="100%"
        display="flex"
        flexDirection="row"
        height="48px"
        justifyContent="space-between"
      >
        <img src={image} alt="type" />
        <Box height="48px" width="48px">
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            border={1}
            borderColor={titlecolor}
            justifyContent="center"
            borderRadius="50%"
            color={titlecolor}
          >
            {isHebrew() ? <ChevronLeft /> : <ChevronRight />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const WelcomeStep = (props) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const title = t('onboarding_welcome_title');
  const detail = t('onboarding_welcome_detail');
  const personalTitle = t('onboarding_welcome_card_creator_title');
  const personalExamples = t('onboarding_welcome_card_creator_detail');
  const businessTitle = t('onboarding_welcome_card_consumer_title');
  const businessExamples = t('onboarding_welcome_card_consumer_detail');

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new OnboardingFlowEventWelcome());
  });

  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        pl={2}
        pr={2}
        mt={4}
        mb={2}
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Typography variant="h2">
          <Box fontSize={24} fontWeight={700} color="text.secondary" mb={2}>
            {title}
          </Box>
        </Typography>
        <Typography variant="body1">
          <Box
            fontSize={16}
            fontWeight={600}
            color="text.secondary"
            mb={2}
            lineHeight={1.3}
          >
            {detail}
          </Box>
        </Typography>
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <TypeBox
            image={CreatorTypeImage}
            onTypeSelected={props.onTypeSelected}
            type="creator"
            titlecolor="primary.main"
            detailcolor="black"
            bgcolor="secondary.main"
            title={personalTitle}
            detail={personalExamples}
          />
          <TypeBox
            image={ConsumerTypeImage}
            onTypeSelected={props.onTypeSelected}
            type="consumer"
            titlecolor="secondary.main"
            detailcolor="#E3E3EE"
            bgcolor="primary.main"
            title={businessTitle}
            detail={businessExamples}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default connect(null, null)(withOnboardingStage(WelcomeStep));
