import React from "react";
import styles from "../AuthDialog.module.scss";
import ClassNames from 'classnames';
import { withTranslation } from 'react-i18next';

interface Goals {
  Create: number;
  Consume: number
}

const UserGoals: Goals = {
  Create: 0,
  Consume: 1
}

class UserGoalBody extends React.Component<any, any>
{
  constructor(props: any) {
    super(props);

    this.state = {
      goal: UserGoals.Create
    }
  }

  onSelectGoal(goal: number)
  {
    let { onSelectGoal } = this.props;
    onSelectGoal(goal);

    this.setState({goal});
  }

  render()
  {
    let { goal } = this.state;
    let { className } = this.props;

    return <div className={className}>
      <h5>I'm here to</h5>
      <dl>
        <dd className={goal === UserGoals.Create ? styles.activeBox : ""} onClick={() => this.onSelectGoal(UserGoals.Create)}><span>Earn<br />money</span></dd>
        <dd className={goal === UserGoals.Consume ? styles.activeBox : ""} onClick={() => this.onSelectGoal(UserGoals.Consume)}><span>Discover &<br />buy</span></dd>
      </dl>

      <p>Don’t worry, you can change your status anytime in your profile</p>
    </div>;
  }
}

export default withTranslation()(UserGoalBody);
export {
  UserGoals
}