import { createSlice } from '@reduxjs/toolkit';

export interface PaymentCardDetails {
  number?: string;
  cvv?: string;
  expiration?: string;
  is_valid?: boolean;
}
export interface OrderingState {
  creator: any; //VLUser object of creator
  step: number;
  stage: 'details' | 'payment' | 'success';
  type: 'personal' | 'business' | undefined;
  selectedEntity: 'for me' | 'someone else' | 'business' | undefined;
  ocassion: any;
  ocassion_value: any;
  forWho?: string;
  fromWho?: string;
  businessName?: string;
  message?: string;
  deliveryDeadline?: number;
  total_price?: number;
  promotion_id?: string;
  currency?: string;
  error?: string;
  lastSubmittedBookingRequest?: any;
  isAddingCard: boolean;
  isSubmitting: boolean;
  canConfirmBooking: boolean;
  hasTriedConfirmDetailsOnce: boolean;
  hasEnterredValidPaymentInfo: boolean;
  cardDetails: PaymentCardDetails;
  errors: any;
}

/* Basic Campaign Model */
const initialState: OrderingState = {
  creator: undefined,
  stage: 'details',
  step: 0,
  type: undefined, // 'personal' | 'business' | undefined;
  selectedEntity: undefined, //'for me' | 'someone else';
  ocassion: undefined, //VLBookingOccasion
  ocassion_value: undefined,
  message: undefined,
  deliveryDeadline: undefined,
  currency: undefined,
  total_price: undefined,
  promotion_id: undefined,
  error: undefined,
  lastSubmittedBookingRequest: undefined,
  isAddingCard: false,
  isSubmitting: false,
  canConfirmBooking: false,
  hasTriedConfirmDetailsOnce: false,
  hasEnterredValidPaymentInfo: false,
  cardDetails: {},
  errors: {},
};

const checkIfCanConfirmBooking = (state: OrderingState) => {
  if (!state.creator) {
    return false;
  }
  if (!state.selectedEntity) {
    return false;
  }
  if (!state.ocassion) {
    return false;
  }
  if (!state.total_price || !state.currency) {
    return false;
  }
  if (!state.message || state.message.length < 30) {
    return false;
  }
  if (state.type === 'business' && !state.businessName) {
    return false;
  }
  if (
    state.type === 'personal' &&
    state.selectedEntity === 'for me' &&
    !state.fromWho
  ) {
    return false;
  }
  if (
    state.type === 'personal' &&
    state.selectedEntity === 'someone else' &&
    !state.forWho
  ) {
    return false;
  }
  return true;
};

const updateErrors = (state: OrderingState) => {
  if (!state.hasTriedConfirmDetailsOnce) return {};
  let errors: any = {};
  errors['selected_entity'] = state.selectedEntity === undefined;
  errors['occasion'] = state.ocassion === undefined;
  if (state.type === 'business') {
    errors['names'] = state.businessName === undefined;
  } else if (state.type === 'personal' && state.selectedEntity === 'for me') {
    errors['names'] = state.fromWho === undefined;
  } else if (
    state.type === 'personal' &&
    state.selectedEntity === 'someone else'
  ) {
    errors['names'] = state.fromWho === undefined || state.forWho === undefined;
  }
  errors['message'] = !state.message || state.message.length < 30;
  return errors;
};

const orderingSlice = createSlice({
  name: 'ordering',
  initialState,
  reducers: {
    // Redux actions
    reset(state, action) {
      state.creator = undefined;
      state.stage = 'details';
      state.step = 0;
      state.type = undefined;
      state.ocassion_value = undefined;
      state.ocassion = undefined;
      state.forWho = undefined;
      state.fromWho = undefined;
      state.businessName = undefined;
      state.message = undefined;
      state.deliveryDeadline = undefined;
      state.currency = undefined;
      state.total_price = undefined;
      state.lastSubmittedBookingRequest = undefined;
      state.isAddingCard = false;
      state.isSubmitting = false;
      state.hasTriedConfirmDetailsOnce = false;
      state.hasEnterredValidPaymentInfo = false;
      state.cardDetails = {};
      state.errors = {};
    },
    previousStep(state, action) {
      state.step = Math.max(state.step - 1, 0);
    },
    updateStep(state, action) {
      state.step = action.payload;
    },
    updateCreator(state, action) {
      state.creator = action.payload;
    },
    /**
     * Update selected entity
     * @param state
     * @param action
     */
    updateSelectedEntity(state, action) {
      state.selectedEntity = action.payload;
      state.type =
        state.selectedEntity === 'business' ? 'business' : 'personal';
      state.step = state.step + 1;
      state.deliveryDeadline = new Date().getTime();
      const data = { ...state };
      state.canConfirmBooking = checkIfCanConfirmBooking(data);
      let errors = updateErrors({ ...state });
      state.errors = errors;
    },
    updateOcassion(state, action) {
      state.ocassion = action.payload.title;
      state.ocassion_value = action.payload.value;
      state.step = state.step + 1;
      const data = { ...state };
      state.canConfirmBooking = checkIfCanConfirmBooking(data);
      state.errors = updateErrors({ ...state });
    },
    updateNames(state, action) {
      state.forWho = action.payload.forWho;
      state.fromWho = action.payload.fromWho;
      state.businessName = action.payload.businessName;
      const data = { ...state };
      state.canConfirmBooking = checkIfCanConfirmBooking(data);
      state.errors = updateErrors({ ...state });
    },
    updateBrief(state, action) {
      state.message = action.payload;
      const data = { ...state };
      state.canConfirmBooking = checkIfCanConfirmBooking(data);
      state.errors = updateErrors({ ...state });
    },
    updatePrice(state, action) {
      state.total_price = action.payload.total_price;
      state.currency = action.payload.currency;
      const data = { ...state };
      state.canConfirmBooking = checkIfCanConfirmBooking(data);
    },
    applyPromotion(state, action) {
      state.total_price = action.payload.total_price;
      state.currency = action.payload.currency;
      state.promotion_id = action.payload.promotion_id;
    },
    onConfirmBookingDetailsPressed(state, action) {
      state.hasTriedConfirmDetailsOnce = true;
      if (state.canConfirmBooking === true) {
        state.errors = {};
        state.stage = 'payment';
      } else {
        //show error for relevant parts
        state.errors = updateErrors({ ...state });
      }
    },
    onForceUpdateStage(state, action) {
      state.stage = action.payload;
    },
    onAddPaymentClicked(state, action) {
      state.isAddingCard = true;
    },
    onUpdatePaymentDetails(state, action) {
      state.cardDetails = {
        number: action.payload.number,
        cvv: action.payload.cvv,
        expiration: action.payload.expiration,
        is_valid: action.payload.is_valid,
      };
    },
    onSubmitOrderClicked(state, action) {
      state.isAddingCard = false;
      state.isSubmitting = true;
    },
    onSubmitOrderFailed(state, action) {
      state.isAddingCard = false;
      state.isSubmitting = false;
      state.error = action.payload;
    },
    onDismissError(state, action) {
      state.error = undefined;
    },
    onSubmitOrderSuccess(state, action) {
      state.error = undefined;
      state.stage = 'success';
    },
  },
});

/* Export Redux Actions */
export const {
  reset,
  updateCreator,
  updateStep,
  updateSelectedEntity,
  updateOcassion,
  updatePrice,
  onAddPaymentClicked,
  onSubmitOrderClicked,
  updateNames,
  updateBrief,
  onConfirmBookingDetailsPressed,
  previousStep,
  applyPromotion,
  onDismissError,
  onUpdatePaymentDetails,
  onForceUpdateStage,
  onSubmitOrderFailed,
  onSubmitOrderSuccess,
} = orderingSlice.actions;

/* Export the reducer */
export default orderingSlice.reducer;
