import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { OrderBlockWidget } from '../../Widgets/OrderBlockWidget';
import { useTranslation } from 'react-i18next';
import {
  cleanInputPrice,
  toConvertedPriceString,
  toPriceString,
} from '../../../../../utils/priceUtils';
import userManager from '../../../../../managers/userManager';
import { updatePrice } from '../../../../../store/reducers/ordering';
import { convertToPrice } from '../../../../../utils/rhutils';
import { isHebrew } from '../../../../../App';
import { parse } from '../../../../../utils/vlStrings';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '-0.41px',
  },
  input: {
    textAlign: 'center',
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.165px',
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid white`,
    },
  },
}));

const InputBox = (props) => {
  const {
    placeholder,
    title,
    defaultValue,
    value,
    showError,
    currencySymbol,
    minPriceString,
  } = props;
  const { t } = useTranslation();
  const errorText = parse(t('minimum_price_helper'), minPriceString);
  const helperText = showError ? errorText : t('booking_desc_down_text');

  const hebrew = isHebrew();
  const classes = useStyles();

  return (
    <Box
      mb={2.5}
      width="100%"
      display="flex"
      flexDirection="column"
      fontFamily="Assistant"
    >
      <Box m={1} textAlign="center" className={classes.title}>
        {title}
      </Box>
      <TextField
        type="number"
        value={value}
        defaultValue={defaultValue}
        inputRef={props.inputRef}
        onChange={props.onChange}
        required
        InputProps={{ style: { fontWeight: 600, fontFamily: 'Assistant' } }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          classes: {
            input: classes.input,
            underline: classes.underline,
          },
          startAdornment: !isHebrew() ? (
            <InputAdornment position="start">{currencySymbol}</InputAdornment>
          ) : null,
          endAdornment: isHebrew() ? (
            <InputAdornment position="start">{currencySymbol}</InputAdornment>
          ) : null,
        }}
        InputLabelProps={{
          style: { color: 'rgba(255, 255, 255, 0.7)' },
        }}
        error={showError}
        color="secondary"
        fullWidth={true}
        label={placeholder}
        helperText={helperText}
        FormHelperTextProps={{
          style: {
            color: showError ? '#D95041' : 'white',
            fontFamily: 'Assistant',
          },
        }}
      />
    </Box>
  );
};
const OrderPriceBidBlock = (props) => {
  const { user, isBusiness, creator, updatePrice, presetPrice } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [priceBid, setPriceBid] = useState(undefined); //this will not include the currency
  const [showMinPriceError, setShowMinPriceError] = useState(false);

  const currentUserCurrency = userManager.currentUserCurrency();
  const currentUserCurrencySymbol = userManager.currentUserCurrencySymbol();

  if (!creator) return null;

  const title = t('price_bid');

  /**
   * Prepare price check values according to current user and creator
   */
  const minPriceInCreatorCurrency = creator.minPrice(isBusiness);
  const minPriceInCurrentUserCurrency = Number(
    convertToPrice(
      minPriceInCreatorCurrency,
      creator.currency,
      currentUserCurrency
    )
  );

  /**
   * Handle textfield updates
   * @param {*} event
   */
  const onPriceUpdated = (event) => {
    const val = event.target.value;
    const priceWithoutCurrency = parseInt(cleanInputPrice(val), 10);
    if (
      minPriceInCreatorCurrency > priceWithoutCurrency ||
      !priceWithoutCurrency
    ) {
      //show error
      setShowMinPriceError(true);
      setPriceBid(priceWithoutCurrency);
    } else {
      setShowMinPriceError(false);
      //dispatch to store
      updatePrice({
        total_price: priceWithoutCurrency,
        currency: currentUserCurrency,
      });
      setPriceBid(priceWithoutCurrency);
    }
  };

  return (
    <OrderBlockWidget>
      <Box className={classes.root}>
        <Box width="100%" display="flex" flexDirection="column" mt={2}>
          <InputBox
            minPriceString={toPriceString(
              currentUserCurrency,
              minPriceInCurrentUserCurrency
            )}
            currencySymbol={currentUserCurrencySymbol}
            showMinPriceError={showMinPriceError}
            value={priceBid ?? minPriceInCurrentUserCurrency}
            title={title}
            showError={showMinPriceError}
            onChange={onPriceUpdated}
          />
        </Box>
      </Box>
    </OrderBlockWidget>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    user: state.user.details,
    isBusiness: state.ordering.type === 'business',
    creator: state.ordering.creator,
    presetPrice: state.ordering.total_price,
  };
};

export default connect(mapStateToProps, { updatePrice })(OrderPriceBidBlock);
