import userManager from './userManager.js';
import * as utils from '../utils/rhutils.js';
import firebase from 'firebase';
import api, * as RHAPI from './../constants/api';
import BookingRequest, { BookingType } from '../model/bookingRequest';
import BookingItem from '../model/bookingItem';
import * as constants from '../constants/rhconstants';
import * as analytics from '../utils/amplitude.js';
import VLError from '../model/vlError';

import store from '../store/store';
import { onUpdateLastBookingRequest } from '../store/reducers/campaigns';

var _ = require('lodash');

class BookingRequestManager {
  currentUserId = undefined;
  constructor() {}

  async submitBookingRequest(obj) {
    let headers = await userManager.getRequestHeaders();

    //for now, return failure (VLError)
    return {
      bookingRequest: null,
      error: {
        hebrewText: 'לא ניתן להזמין כעת',
        text: 'Ordering is not available',
      },
    };

    var body = JSON.stringify(obj);
    // utils.log.debug('headers: ', headers);

    const url = api.SUBMIT_BOOKING_REQUEST;
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    // utils.log.debug('submitted booking request response json:', json);
    let bookingRequest = BookingRequest.fromJSON(json);
    let result = {};
    result.bookingRequest = bookingRequest;
    result.error = VLError.fromJSON(json.error);

    if (result.bookingRequest) {
      store.dispatch(onUpdateLastBookingRequest(result.bookingRequest));
      analytics.logPurchaseBookingRequest(bookingRequest);
    }
    return result; //if it worked
  }

  async submitCampaign(obj) {
    let headers = await userManager.getRequestHeaders();

    obj.campaign = 1; //true
    var body = JSON.stringify(obj);
    // utils.log.debug('headers: ', headers);

    const url = api.SUBMIT_BOOKING_REQUEST;
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    // utils.log.debug('submit campaign response json:', json);

    let result = { success: json.success === true };
    result.error = VLError.fromJSON(json.error);

    return result; //if it worked
  }

  async sendNudgeForRequest(bookingRequest, messageBody) {
    let headers = await userManager.getRequestHeaders();
    let obj = {
      nudger_id: utils.currentUser().id,
      request_id: bookingRequest.id,
      body: messageBody,
    };
    var body = JSON.stringify(obj);
    // utils.log.debug('sending nudge for request: ', obj);

    const url = api.SEND_NUDGE_REQUEST;
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    const result = {};
    result.success = response.status === 200;
    return result; //if it worked
  }

  async updateBookingRequest(
    bookingRequest,
    updatedRequestDescription,
    forCreatorOnly = false
  ) {
    let headers = await userManager.getRequestHeaders();

    let obj = {
      requester_id: bookingRequest.requesterId,
      persona_id: bookingRequest.personaId,
      request_id: bookingRequest.id,
      updated_request_description: updatedRequestDescription,
      for_creator_only: forCreatorOnly === true ? 1 : 0,
    };
    var body = JSON.stringify(obj);
    // utils.log.debug('update booking req object: ', obj);

    const url = api.UPDATE_BOOKING_REQUEST;
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    // utils.log.debug('submitted booking request response json:', json);
    let bookingRequestResult = BookingRequest.fromJSON(json);
    let result = {};
    result.bookingRequest = bookingRequestResult;
    result.error = VLError.fromJSON(json.error);
    return result; //if it worked
  }

  async fetchBookingItem(itemId) {
    // utils.log.debug('fetch booking item...', itemId);
    const db = firebase.firestore();
    const itemRef = await db
      .collection(constants.FIREBASE_COLLECTION_BOOKING_ITEMS)
      .doc(itemId)
      .get();
    const itemJSON = itemRef.data();

    // utils.log.debug('fetchBookingItem:', itemJSON);

    let bookingItem = BookingItem.fromJSON(itemJSON);
    return bookingItem;
  }

  async fetchBookingRequest(bookingRequestId) {
    // utils.log.debug('fetch booking request...', bookingRequestId);
    const db = firebase.firestore();
    const itemRef = await db
      .collection(constants.FIREBASE_COLLECTION_REQUESTS)
      .doc(bookingRequestId)
      .get();
    const itemJSON = itemRef.data();

    // utils.log.debug('fetch bookig request:', itemJSON);

    let bookingRequest = BookingRequest.fromJSON(itemJSON);
    return bookingRequest;
  }

  async deleteBookingItem(bookingItem) {
    // utils.log.debug('deleting booking request...', bookingItem.id);
    const db = firebase.firestore();
    const bookingItemRef = await db
      .collection(constants.FIREBASE_COLLECTION_BOOKING_ITEMS)
      .doc(bookingItem.id);
    let now = new Date();
    bookingItemRef.update({ removed: true, last_updated: now.getTime() });
    return true;
  }

  async fetchBookingItemsForUser(userId, activeOnly, reversed) {
    // utils.log.debug('fetch booking items for user id...', userId);
    const db = firebase.firestore();
    const itemsSnapshot = await db
      .collection(constants.FIREBASE_COLLECTION_BOOKING_ITEMS)
      .where('user_id', '==', userId)
      .get();

    var items = [];
    //2. Generate array of creators
    itemsSnapshot.docs.forEach((element) => {
      let bookingItem = BookingItem.fromJSON(element.data());
      items.push(bookingItem);
    });

    //sort
    items = _.orderBy(items, ['lastUpdated'], ['desc']);
    if (reversed === true) {
      // utils.log.debug('reversing....');
      items = items.reverse();
    }
    items = _.filter(items, function (o) {
      return !o.removed;
    });
    //filter
    if (activeOnly === true) {
      items = _.filter(items, 'active');
    }
    return items;
  }

  async fetchBookingRequestsForUser(userId) {
    // utils.log.debug('fetch booking requests for user id...', userId);
    const db = firebase.firestore();

    //1. Requests snapshot
    const requestsSnapshot = await db
      .collection(constants.FIREBASE_COLLECTION_REQUESTS)
      .where('persona_id', '==', userId)
      .orderBy('created_on', 'desc')
      .get();

    var items = [];

    //2. Generate array of creators
    requestsSnapshot.docs.forEach((element) => {
      let bookingRequest = BookingRequest.fromJSON(element.data());
      items.push(bookingRequest);
    });

    return items;
  }

  async submitBookingItem(bookingItem) {
    // utils.log.debug('submit booking item...', bookingItem);
    const db = firebase.firestore();
    const bookingItemRef = await db
      .collection(constants.FIREBASE_COLLECTION_BOOKING_ITEMS)
      .doc();

    let now = new Date();
    let obj = bookingItem.toMap();
    obj.last_updated = now.getTime();
    obj.created_on = now.getTime();
    obj.id = bookingItemRef.id;

    let cleanedObj = utils.cleanObject(obj);

    bookingItemRef.set(cleanedObj, {
      merge: true,
    });
    return bookingItem;
  }

  async updateItemActiveState(bookingItem, active) {
    // utils.log.debug('submit booking item...', bookingItem);
    const db = firebase.firestore();
    const bookingItemRef = await db
      .collection(constants.FIREBASE_COLLECTION_BOOKING_ITEMS)
      .doc(bookingItem.id);
    bookingItemRef.update({ active: active });
    return true;
  }

  async updateItem(bookingItem) {
    // utils.log.debug('submit booking item...', bookingItem);

    let obj = bookingItem.toMap();
    let now = new Date();
    obj.last_updated = now.getTime();

    let cleanedObj = utils.cleanObject(obj);

    const db = firebase.firestore();
    const bookingItemRef = await db
      .collection(constants.FIREBASE_COLLECTION_BOOKING_ITEMS)
      .doc(bookingItem.id);
    bookingItemRef.update(cleanedObj);
    return true;
  }

  async declineRequest(bookingItem, reason = 'other') {
    // utils.log.debug('declining booking item...', bookingItem);

    let headers = await userManager.getRequestHeaders();
    let obj = {
      requester_id: bookingItem.requesterId,
      persona_id: bookingItem.personaId,
      request_id: bookingItem.id,
      reason: reason,
    };
    var body = JSON.stringify(obj);
    // utils.log.debug('body: ', body);

    const url = api.DECLINE_REQUEST;
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    // utils.log.debug('response: ', response);
    return response.status;
  }

  async cancelRequest(bookingItem) {
    // utils.log.debug('cancelling booking item...', bookingItem);

    let headers = await userManager.getRequestHeaders();
    let obj = {
      requester_id: bookingItem.requesterId,
      persona_id: bookingItem.personaId,
      request_id: bookingItem.id,
    };
    var body = JSON.stringify(obj);
    // utils.log.debug('body: ', body);

    const url = api.CANCEL_REQUEST;
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    // utils.log.debug('response: ', response);
    return response.status;
  }

  async expireRequest(bookingItem) {
    // utils.log.debug('cancelling booking item...', bookingItem);

    let headers = await userManager.getRequestHeaders();
    let obj = {
      requester_id: bookingItem.requesterId,
      persona_id: bookingItem.personaId,
      request_id: bookingItem.id,
    };
    var body = JSON.stringify(obj);
    // utils.log.debug('body: ', body);

    const url = api.EXPIRE_REQUEST;
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    // utils.log.debug('response: ', response);
    return response.status;
  }
}

var bookingRequestManager = new BookingRequestManager();
export default bookingRequestManager;
