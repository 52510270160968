import { combineReducers } from 'redux';
import adminReducer from './admin';
import authReducer, { AuthState } from './auth';
import globalReducer, { GlobalState } from './global';
import homeReducer, { HomeState } from './home';
import creatorReducer, { CreatorState } from './creator/creator-data';
import mobileReducer, { MobileState } from './mobile/mobile';
import userReducer from './user/user.reducer.js';
import campaignsReducer from './campaigns';
import activityReducer from './activity/activity.reducer';
import onboardingReducer from './onboarding/onboarding.reducer';
import orderingReducer from './ordering/';

export interface RootState {
  admin: any;
  auth: AuthState;
  global: GlobalState;
  home: HomeState;
  creator: CreatorState;
  mobile: MobileState;
  user: any;
  campaign: any;
  activity: any;
  onboarding: any;
  ordering: any;
}

export const createRootReducer = () =>
  combineReducers<RootState>({
    admin: adminReducer,
    global: globalReducer,
    auth: authReducer,
    home: homeReducer,
    creator: creatorReducer,
    mobile: mobileReducer,
    user: userReducer,
    campaign: campaignsReducer,
    activity: activityReducer,
    onboarding: onboardingReducer,
    ordering: orderingReducer,
  });
