import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
  TextField,
  SvgIcon,
  Chip,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useTranslation } from 'react-i18next';
import { withOnboardingStage } from '../../../shared/hoc';

import UploadImageIcon from '../images/upload_image_icon.svg';
import ShareIcon from '@material-ui/icons/Share';

//General
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../../../App';
import VLButton from '../../../Reusables/Buttons/VLButton';
import { FaAccessibleIcon } from 'react-icons/fa';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Creator from '../../../components/pages/Homepage/Creator/Creator';
import socialManager from '../../../managers/socialManager';

import { onSelectedCreators } from '../../../store/reducers/onboarding/onboarding.reducer';
import VLFlowManager, {
  OnboardingFlowEventSelectCreators,
} from '../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    height: '50px',
    borderRadius: '25px',
    color: theme.palette.text.secondary,
    borderColor: theme.palette.primary.main,
    fontFamily: 'Assistant',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  chipSelected: {
    height: '50px',
    borderRadius: '25px',
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    borderColor: theme.palette.primary.main,
    fontFamily: 'Assistant',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  deleteIcon: {
    background: 'white',
    color: theme.palette.primary.main,
    padding: '10px',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  checkBox: {
    width: '32px',
    height: '32px',
    borderRadius: '16px',
    background: 'white',
    color: theme.palette.primary.main,
    top: 0,
    right: isHebrew() ? 0 : 10,
    left: !isHebrew() ? 0 : 10,
    zIndex: 1,
  },
  selectedChip: {},
  creatorsContainer: {
    maxHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '70vh',
    },
  },
}));

const CreatorBox = (props) => {
  const classes = useStyles();
  const { creator, index, onUserSelected, onUserDeselected, selected } = props;
  const handleClick = () => {
    onUserSelected(creator.id);
  };
  const handleDelete = () => {
    onUserDeselected(creator.id);
  };

  return (
    <Box
      position="relative"
      onClick={() =>
        selected ? handleDelete(creator.id) : handleClick(creator.id)
      }
    >
      <Box
        position="absolute"
        className={classes.checkBox}
        display={selected ? 'flex' : 'flex'}
        style={{ opacity: selected ? 1.0 : 0.3 }}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <CheckIcon />
      </Box>
      <Creator
        ignoreClick={true}
        key={creator.id}
        index={index}
        creator={creator}
        discover={false}
      />
    </Box>
  );
};

const FollowerSuggestions = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { onConfirm, allUsers } = props;

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new OnboardingFlowEventSelectCreators());
  });

  const title = t('onboarding_follow_suggestions_title');
  const detail = t('onboarding_follow_suggestions_detail');

  const onConfirmPressed = async () => {
    //@todo - should follow all users
    if (selectedUsers.length > 0) {
      setShowLoading(true);
      props.onSelectedCreators(selectedUsers);
      const perform = await socialManager.followProfiles(selectedUsers);
      onConfirm();
      setShowLoading(false);
    } else {
      onConfirm();
    }
  };

  const onUserSelected = (id) => {
    if (selectedUsers.indexOf(id) !== -1) return;
    selectedUsers.push(id);
    setSelectedUsers([...selectedUsers]);
  };

  const onUserDeselected = (id) => {
    if (selectedUsers.indexOf(id) === -1) return;
    selectedUsers.splice(selectedUsers.indexOf(id), 1);
    setSelectedUsers([...selectedUsers]);
  };

  const onSelectAllClicked = () => {
    if (!selectAllSelected) {
      const setup = allUsers.map((user) => user.id);
      setSelectedUsers([...setup]);
    } else {
      setSelectedUsers([]);
    }
    setSelectAllSelected(!selectAllSelected);
  };

  return (
    <Box
      width="100%"
      maxHeight="100%"
      height="100%"
      className={classes.root}
      display="flex"
      position="relative"
      flexDirection="column"
      justifyContent="center"
    >
      <Box pl={2} pr={2} mt={4} mb={2}>
        <Typography variant="h2">
          <Box fontSize={24} fontWeight={700} color="text.secondary" mb={2}>
            {title}
          </Box>
        </Typography>
        <Typography variant="body1">
          <Box
            fontSize={16}
            fontWeight={600}
            color="text.secondary"
            mb={2}
            lineHeight={1.3}
          >
            {detail}
          </Box>
        </Typography>
      </Box>
      <Box
        flexGrow={1}
        overflow="auto"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        flexWrap="wrap"
        className={classes.creatorsContainer}
      >
        {allUsers.map((creator, index) => {
          return (
            <Box
              width="50%"
              p={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <CreatorBox
                creator={creator}
                index={index}
                onUserSelected={(id) => onUserSelected(id)}
                onUserDeselected={(id) => onUserDeselected(id)}
                selected={selectedUsers.indexOf(creator.id) >= 0}
              />
            </Box>
          );
        })}
      </Box>
      <Box width="100%" display="flex" flexDirection="row" alignItems="center">
        <VLButton
          variant="default"
          onClick={() => onSelectAllClicked()}
          fullWidth={true}
        >
          {selectAllSelected
            ? t('onboarding_follow_suggestions_deselect_all')
            : t('onboarding_follow_suggestions_select_all')}
        </VLButton>
      </Box>
      <Box width="100%" display="flex" flexDirection="row" alignItems="center">
        <VLButton
          onClick={() => onConfirmPressed()}
          fullWidth={true}
          disabled={showLoading}
        >
          {showLoading ? (
            <CircularProgress style={{ color: 'white' }} size={20} />
          ) : (
            t('onboarding_button_confirm')
          )}
        </VLButton>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ home, onboarding }) => {
  const selectedCategories = onboarding.selectedCategories;
  let creators = home.data.allUsers ?? [];
  let empty = [];

  selectedCategories.forEach((category) => {
    let match = creators.filter(
      (creator) => creator.category_ids.join(',').indexOf(category) >= 0
    );
    empty.push(...match);
  });

  //@todo - should filter by user selected categories
  return { allUsers: empty };
};

export default connect(mapStateToProps, { onSelectedCreators })(
  withOnboardingStage(FollowerSuggestions)
);
