import React, { useState, useEffect } from 'react';
import styles from './AddLinkSelect.module.scss';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Li = (props)=>{
    return (<li onClick={props.onClick}>
        <div className={`${styles.buttonList}`}>
            {props.selected === props.text ? (<div className={styles.ButtonSelected}></div>) : null}
        </div>
        {props.text}
    </li>)
}

const Buttons = (props)=>{
    return (<div className={styles.buttons}>
        <div className={`${styles.button} ${styles.buttonSoft}`} onClick={props.cancelAddLink}>{props.cancelText}</div>
        <div className={`${styles.button} ${styles.buttonBold}`} onClick={props.save}>{props.saveText}</div>
    </div>)
}

const AddLinkSelect = (props) => {
  const { t, i18n } = useTranslation();
  const [selected,setSelected] = useState('')
 
  useEffect(()=>{
    setSelected(props.selected)
  },[props.selected])

  const save = ()=>{
    props.onChange(selected,'price')
    props.saveAddLink()
  }

  return (
    <div className={`${styles.page} ${i18n.language === 'he' ? styles.heb : ''}`}>
        <div className={styles.wrapper}>
            <div className={styles.title}>Set a price</div>
            {/* List of options */}
            <ul className={styles.list}>
                <Li onClick={()=>{setSelected(t('editProfile_list_free'))}} selected={selected} text={t('editProfile_list_free')}/>
                <Li onClick={()=>{setSelected(t('editProfile_list_setPrice'))}} selected={selected} text={t('editProfile_list_setPrice')}/>
                <Li onClick={()=>{setSelected(t('editProfile_list_from'))}} selected={selected} text={t('editProfile_list_from')}/>
                <Li onClick={()=>{setSelected(t('editProfile_list_none'))}} selected={selected} text={t('editProfile_list_none')}/>
            </ul>
        
            {/* buttons - set and cancel */}
            <Buttons cancelAddLink={props.cancelAddLink} save={save} cancelText={t('editProfile_list_cancel')} saveText={t('editProfile_list_set')}/>
        </div>
    </div>
  );
};

export default withRouter(AddLinkSelect);
