import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getI18n, useTranslation } from 'react-i18next';

import { withBookingStage } from '../../../../../shared/hoc';
import { updateCategory } from '../../../../../store/reducers/campaigns/';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../../../../../App';
import VLFlowManager, {
  CampaignFlowEventCategorySelect,
} from '../../../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
  },
  categoryBox: {
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '16px',
    cursor: 'pointer',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
  },
  imageBox: {
    color: theme.palette.primary.main,
    width: '60px',
    height: '60px',
    minHeight: '60px',
    background: '#F9F9F9',
  },
}));

const Body = (props) => {
  const { categories, title } = props;
  return (
    <Box p={2} overflow="auto" style={{ marginTop: '100px' }}>
      <Typography variant="h5">
        <Box fontWeight="bold" color="text.primary" textAlign="center" mb={2}>
          {title}
        </Box>
      </Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        {categories.map((occ, index) => {
          return (
            <CategoryBox
              updateCategory={props.updateCategory}
              key={index}
              title={occ.title}
              image={occ.campaign_image_url ?? occ.image_url}
              id={occ.id}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const CategoryBox = (props) => {
  const classes = useStyles();
  const { title, image, id, updateCategory } = props;
  return (
    <Box
      onClick={() => updateCategory(id)}
      className={classes.categoryBox}
      mt={2}
      mb={2}
      width="47.5%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="120px"
    >
      <img
        alt="ocassion"
        src={image}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          objectFit: 'cover',
        }}
      />
      <Box
        width="100%"
        height="100%"
        position="relative"
        style={{
          background:
            'linear-gradient(356.19deg, rgba(17, 17, 17, 0.8) 24.16%, rgba(17, 17, 17, 0.2) 97.11%)',
        }}
      >
        <Box
          style={{
            width: '100%',
            bottom: '16px',
            left: '0px',
            paddingLeft: '16px',
            paddingRight: '16px',
            position: 'absolute',
            display: 'flex',
            color: 'white',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h4">
            <Box fontSize="16px" color="text.secondary">
              {title}
            </Box>
          </Typography>
          <Box id="right-chevron">
            {isHebrew() ? <ChevronLeft /> : <ChevronRight />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CategoriesScreen = (props) => {
  const classes = useStyles();

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new CampaignFlowEventCategorySelect());
  }, []);

  const { t, i18n } = useTranslation();
  const title = t('campaigns_categories_title');
  let { categories } = props;
  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {!categories || categories.length === 0 ? (
        <Box textAlign="center">
          <CircularProgress color="primary" size={44} />
        </Box>
      ) : (
        <Body {...props} title={title} />
      )}
    </Box>
  );
};

const mapStateToProps = ({ home }) => {
  return { categories: home.data.categories };
};

export default connect(mapStateToProps, { updateCategory })(
  withBookingStage(CategoriesScreen)
);
