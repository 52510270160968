import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
  TextField,
  SvgIcon,
  Chip,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useTranslation } from 'react-i18next';
import { withOnboardingStage } from '../../../shared/hoc';

import UploadImageIcon from '../images/upload_image_icon.svg';
import ShareIcon from '@material-ui/icons/Share';

//General
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../../../App';
import VLButton from '../../../Reusables/Buttons/VLButton';
import { FaAccessibleIcon } from 'react-icons/fa';
import CloseIcon from '@material-ui/icons/Close';
import { onSelectedCategories } from '../../../store/reducers/onboarding/onboarding.reducer';
import { OnboardingFlowEventInterests } from '../../../managers/v2';
import VLFlowManager from '../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    height: '50px',
    borderRadius: '25px',
    color: theme.palette.text.secondary,
    borderColor: theme.palette.primary.main,
    fontFamily: 'Assistant',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  chipSelected: {
    height: '50px',
    borderRadius: '25px',
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    borderColor: theme.palette.primary.main,
    fontFamily: 'Assistant',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  deleteIcon: {
    background: 'white',
    color: theme.palette.primary.main,
    padding: '10px',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  selectedChip: {},
  categoriesContainer: {
    maxHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '50vh',
    },
  },
}));

const InterestChip = (props) => {
  const classes = useStyles();
  const {
    chipId,
    icon,
    title,
    onChipSelected,
    selected,
    onChipDeleted,
  } = props;
  const handleClick = () => {
    onChipSelected(chipId);
  };
  const handleDelete = () => {
    onChipDeleted(chipId);
  };

  return (
    <Chip
      style={{
        paddingRight: isHebrew() ? '16px' : '0px',
        paddingLeft: isHebrew() ? '16px' : '0px',
      }}
      className={!selected ? classes.chip : classes.chipSelected}
      variant={!selected ? 'outlined' : 'default'}
      avatar={
        <Avatar className={classes.avatar} style={{ background: 'none' }}>
          {icon}
        </Avatar>
      }
      label={title}
      onClick={handleClick}
      onDelete={!selected ? null : handleDelete}
      deleteIcon={
        !selected ? null : (
          <IconButton color="text.secondary" className={classes.deleteIcon}>
            <CloseIcon style={{ width: '12px', height: '12px' }} />
          </IconButton>
        )
      }
    />
  );
};

const Interests = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { onConfirm, categories } = props;

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new OnboardingFlowEventInterests());
  });

  const title = t('onboarding_interests_title');

  const onConfirmPressed = () => {
    //@todo - should save
    if (selectedCategories.length > 0) {
      props.onSelectedCategories(selectedCategories);
      onConfirm();
    } else {
      onConfirm();
      onConfirm(); //call twice
    }
  };

  const onSkipPressed = () => {
    onConfirm();
    onConfirm(); //call it twice to skip two screens?
  };

  const onChipSelected = (id) => {
    if (selectedCategories.indexOf(id) !== -1) return;
    selectedCategories.push(id);
    setSelectedCategories([...selectedCategories]);
  };

  const onChipDeleted = (id) => {
    if (selectedCategories.indexOf(id) === -1) return;
    selectedCategories.splice(selectedCategories.indexOf(id), 1);
    setSelectedCategories([...selectedCategories]);
  };

  return (
    <Box
      width="100%"
      maxHeight="100%"
      height="100%"
      className={classes.root}
      display="flex"
      position="relative"
      flexDirection="column"
      justifyContent="center"
    >
      <Box pl={2} pr={2} mt={4} mb={2}>
        <Typography variant="h2">
          <Box fontSize={24} fontWeight={700} color="text.secondary" mb={2}>
            {title}
          </Box>
        </Typography>
      </Box>
      <Box
        flexGrow={1}
        height="50%"
        overflow="auto"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        flexWrap="wrap"
        className={classes.categoriesContainer}
      >
        {categories.map((chip, index) => {
          return (
            <Box m={1}>
              <InterestChip
                onChipSelected={(id) => onChipSelected(id)}
                onChipDeleted={(id) => onChipDeleted(id)}
                chipId={chip.id}
                icon={chip.emoji}
                title={chip.title}
                selected={selectedCategories.indexOf(chip.id) >= 0}
              />
            </Box>
          );
        })}
      </Box>
      <Box width="100%" display="flex" flexDirection="row" alignItems="center">
        <VLButton onClick={() => onConfirmPressed()} fullWidth={true}>
          {t('onboarding_button_confirm')}
        </VLButton>
      </Box>
      <Box width="100%" display="flex" flexDirection="row" alignItems="center">
        <VLButton
          variant="default"
          onClick={() => onSkipPressed()}
          fullWidth={true}
        >
          {t('onboarding_button_skip')}
        </VLButton>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ home }) => {
  return { categories: home.data.categories ?? [] };
};

export default connect(mapStateToProps, { onSelectedCategories })(
  withOnboardingStage(Interests)
);
