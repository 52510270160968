import {
  Box,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../constants';
import VLButton from '../../../../Reusables/Buttons/VLButton';
import { analytics, AnalyticsEvent } from '../../../../utils/analytics';
import { Image } from '../../../image/image.component';

import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

const useStyles = makeStyles((theme) => ({
  root: {
    transition: (props) =>
      props.fill ? 'none' : 'transform 0.15s ease-in-out',
    '&:hover': {
      transform: (props) => (props.fill ? 'none' : 'scale3d(1.05, 1.05, 1)'),
    },
    cursor: 'pointer',
    paddingTop: (props) => (props.fill ? '0px' : '10px'),
    paddingBottom: (props) => (props.fill ? '0px' : '10px'),
    overflow: (props) => (props.horizontal ? 'hidden' : 'hidden'),
    height: '100%',
    width: '100%',
  },
  main: {
    position: 'relative',
    height: (props) => (props.fill ? '100%' : '310px'),
    width: (props) => (props.fill ? '100%' : '210px'),
    borderRadius: (props) => (props.fill ? '0px' : '24px'),
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      height: (props) => (props.fill ? '100%' : '232.5px'),
      width: (props) => (props.fill ? '100%' : '157.5px'),
    },
  },
  textContainer: {
    background:
      'linear-gradient(180deg,rgba(14, 9, 30, 0) 0%,rgba(14, 9, 30, 0.4) 100%)',
  },
  volumeButtonContainer: {
    borderRadius: '50%',
    color: 'white',
    border: '1px solid white',
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

const HomeVideoWidget = (props) => {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [muted, setMuted] = useState(props.isSmall ?? false);
  const {
    videoURL, //url of video
    imageURL, // url of image (thumbnail)
    profilePictureURL, // url of profile picture
    title, // title to show
    detail, // detail to show
    fill, // if we should fill the container
    profileId, // profile id of creator
    visibile, // visibile to show or not
    backupImageURL, // backup image in case thumbnail picture not working
    previewMode,
  } = props;
  const [videoLoaded, setVideoLoaded] = useState(false);

  const onBookNowClicked = () => {
    let analyticsProperties = { profile_id: profileId };
    analytics.event(
      new AnalyticsEvent('web - cta clicked - video widget - book now', [
        analyticsProperties,
      ])
    );
    const link = `${profileId}?goto=booking`;
    let win = window.open(link, '_blank');
  };

  const showVideo = visibile && fill;

  return (
    <div className={classes.root}>
      <Box
        className={classes.main}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Box position="absolute" top="0" left="0" width="100%" height="100%">
          <Box
            className="w-100 h-100"
            style={{ display: videoLoaded && visibile ? 'none' : 'block' }}
          >
            <Image
              className="w-100 h-100"
              src={imageURL}
              backupsrc={backupImageURL}
            />
          </Box>
          {showVideo ? (
            <Box className="w-100 h-100">
              <video
                style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                autoPlay
                loop
                playsInline
                muted={muted}
                key={videoURL}
                onLoadedData={() => setVideoLoaded(true)}
              >
                <source src={videoURL} type="video/mp4" />
              </video>
            </Box>
          ) : null}
        </Box>
        <Box
          color="white"
          id="info-container"
          pl={1}
          pr={1}
          position="relative"
          zIndex={1}
          pb={1}
          overflow="hidden"
          component="div"
          textOverflow="ellipsis"
          display="flex"
          flexDirection="row"
          whiteSpace="nowrap"
          alignItems="center"
          className={classes.textContainer}
        >
          <Box
            flexShrink={0}
            id="image-container"
            width="32px"
            height="32px"
            borderRadius="50%"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Image className="w-100 h-100" src={profilePictureURL} />
          </Box>
          <Box
            id="text-container"
            pl={1}
            pr={1}
            flexGrow={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="h6">
              <Box
                component="div"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                color="white"
                fontFamily="kre8font"
                fontSize="16px"
                id="title"
              >
                {title}
              </Box>
            </Typography>
            {/* <Typography variant="subtitle2">
            <Box
              mt={0.5}
              component="div"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              color="white"
              fontFamily="Assistant"
              fontSize="12px"
              id="detail"
            >
              {detail}
            </Box>
          </Typography> */}
          </Box>
          <Box
            onClick={() => onBookNowClicked()}
            display={fill && !previewMode ? 'block' : 'none'}
            flexShrink={0}
            pl={0}
            pr={0}
          >
            <VLButton variant="contained" color="secondary">
              {t('book_now')}
            </VLButton>
          </Box>
        </Box>
        <Box
          style={{ display: fill && !previewMode ? 'block' : 'none' }}
          className={classes.volumeButtonContainer}
          position="absolute"
          bottom={100}
          right={24}
          id="volume-button-container"
        >
          <IconButton
            onClick={() => setMuted(!muted)}
            style={{ color: 'white' }}
          >
            {muted ? <VolumeUpIcon /> : <VolumeOffIcon />}
          </IconButton>
        </Box>
      </Box>
    </div>
  );
};

export default HomeVideoWidget;
