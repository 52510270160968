import * as utils from '../../utils/rhutils.js';
import VLError from '../../model/vlError';
import api, * as RHAPI from '../../constants/api';
import userManager from '../userManager.js';
import VLPaymentOption from '../../model/paymentOption.js';

var _ = require('lodash');

interface AddCreditCardResponse {
  success: boolean;
  errorMsg?: string;
}
class PaymentManager2 {
  constructor() {}

  /**
   * Get all valid payment methods from the user
   * @returns
   */
  existingPaymentMethods(): VLPaymentOption[] {
    let sorted = _.orderBy(userManager.paymentMethods, ['addedOn'], ['desc']);
    // console.log('validPaymentMethod:::', sorted);
    const validMethods = _.filter(
      sorted,
      (r: VLPaymentOption) => r.removed === false
    );
    // console.log('result:::', result);
    return validMethods;
  }

  async addCreditCard(
    number: string,
    cvc: string,
    expiration: string,
    type: string
  ): Promise<AddCreditCardResponse> {
    // console.log("headers: ", headers);

    const expMonth = expiration.split('/')[0].trim();
    const expYear = expiration.split('/')[1].trim();
    var body = JSON.stringify({
      number: number,
      cvc: cvc,
      exp_year: expYear,
      exp_month: expMonth,
      card_type: type,
    });

    var headers = await userManager.getRequestHeaders();

    const url = api.SUBMIT_PAYMENT_METHOD;
    // console.log(`submit ${body} to ${url}`);
    // console.log(`token ${token}`);
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    // utils.log.debug('add payment method result', json);
    let result = <AddCreditCardResponse>{};
    if (json.error) {
      let error = VLError.fromJSON(json.error);
      result.errorMsg = error?.localizedTitle();
      result.success = false;
      return result;
    } else {
      result.success = true;
      result.errorMsg = undefined;
      //re fetch user's details to reload payment method situation
      userManager.fetchCurrentUserDetails();
      return result;
    }
  }
}

var paymentManager2 = new PaymentManager2();
export default paymentManager2;
