import React, { useState, useEffect } from 'react';
import styles from './BookingSteps.module.scss';
import { useTranslation } from 'react-i18next';
import v from '../../Images/v.svg';

/**
 * Horizontal progress stepbar of booking flow used to show current stage of flow
 * @param {*} props
 */
const BookingStepsProgressBar = (props) => {
  const { t, i18n } = useTranslation();

  let step = props.step;
  const goToPage = (page) => {
    if (props.goToPage && page < step) {
      props.goToPage(page);
    }
  };

  const { hideFirstStep } = props;
  return (
    <div
      className={`${styles.BookingSteps} ${
        i18n.language === 'he' || i18n.language === 'global' || !i18n.language
          ? styles.heb
          : ''
      }`}
    >
      <div className={styles.line}>
        {
          <div
            onClick={() => {
              goToPage(0);
            }}
            className={`${styles.dot} ${step > 0 ? styles.finished : null} ${
              step === 0 ? styles.dotActive : ''
            }`}
          >
            {step === 0 ? (
              <div className={styles.tooltip}>{t('step')} 1</div>
            ) : null}
            <img src={v} className={styles.imageFinished} />
          </div>
        }
        <div
          onClick={() => {
            goToPage(1);
          }}
          className={`${styles.dot} ${step > 1 ? styles.finished : null} ${
            step === 1 ? styles.dotActive : ''
          }`}
        >
          {step === 1 ? (
            <div className={styles.tooltip}>
              {t('step')} {hideFirstStep ? 1 : 2}
            </div>
          ) : null}
          <img src={v} className={styles.imageFinished} />
        </div>
        <div
          onClick={() => {
            goToPage(2);
          }}
          className={`${styles.dot} ${step > 2 ? styles.finished : null} ${
            step === 2 ? styles.dotActive : ''
          }`}
        >
          {step === 2 ? (
            <div className={styles.tooltip}>
              {t('step')} {hideFirstStep ? 2 : 3}
            </div>
          ) : null}
          <img src={v} className={styles.imageFinished} />
        </div>
        <div
          onClick={() => {
            goToPage(3);
          }}
          className={`${styles.dot} ${step > 3 ? styles.finished : null} ${
            step === 3 ? styles.dotActive : ''
          }`}
        >
          {step === 3 ? (
            <div className={styles.tooltip}>
              {t('step')} {hideFirstStep ? 3 : 4}
            </div>
          ) : null}
          <img src={v} className={styles.imageFinished} />
        </div>
        <div
          onClick={() => {
            goToPage(4);
          }}
          className={`${styles.dot} ${step > 4 ? styles.finished : null} ${
            step === 4 ? styles.dotActive : ''
          }`}
        >
          {step === 4 ? (
            <div className={styles.tooltip}>
              {t('step')} {hideFirstStep ? 4 : 5}
            </div>
          ) : null}
          <img src={v} className={styles.imageFinished} />
        </div>
      </div>
    </div>
  );
};
export default BookingStepsProgressBar;
