import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'react-phone-number-input/style.css';
import { lclString, parse, lclDirection } from '../utils/vlStrings.js';
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import VLButton from '../Reusables/Buttons/VLButton';

class ErrorModal extends React.PureComponent {
  render() {
    // console.log("ErrorModal displayed");
    // console.log(this.props);
    return this.renderStage();
  }

  title() {
    return this.props.title ? this.props.title : lclString('error_title');
  }
  detail() {
    return this.props.detail ? this.props.detail : '';
  }
  image() {}
  buttonTitle() {
    return this.props.buttonTitle
      ? this.props.buttonTitle
      : lclString('button_ok');
  }
  renderStage() {
    const { user, show, onCloseClicked } = this.props;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: { borderRadius: 30 },
        }}
        onClose={onCloseClicked}
        aria-labelledby="dialog-title"
        open={show}
      >
        <DialogTitle id="dialog-title">
          <Typography variant="h2">
            <Box lineHeight={1.2}>{this.title()}</Box>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box fontSize="18px" lineHeight={1.2} className="text-muted">
            {this.detail()}
          </Box>
        </DialogContent>
        <DialogActions>
          <Container maxWidth="xs" disableGutters>
            <Grid container justify="center">
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <VLButton
                  onClick={() => onCloseClicked()}
                  variant="outlined"
                  style={{
                    width: '130px',
                    background: '#DBCAFE',
                    color: '#5D36BD',
                    border: '0 none',
                  }}
                >
                  {lclString('button_ok')}
                </VLButton>
              </Box>
            </Grid>
          </Container>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ErrorModal;
