import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './NewAuthenticationFlowContainer.module.scss';
import { useTranslation } from 'react-i18next';
import {
  ButtonBase,
  Container,
  Button,
  Grid,
  Box,
  Modal,
  Dialog,
  CircularProgress,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import IconFacebook from './images/auth_small_facebook.svg';
import IconGoogle from './images/auth_small_google.svg';
import PhoneEmailInputTextField from './PhoneEmailInputTextField';
import LoadingOverlay from '../../components/Modal/Loading/LoaderOverlay';
import { CodeConfirmationScreen } from '../Verification';
import userManager from '../../managers/userManager';
import Toast from '../../Toasts/Toast';
import VerifyEmailScreen from './VerifyEmailScreen';
import FinishSignupScreen from '../FinishSignup/FinishSignup';
import VLBigTitle from '../../Reusables/Texts/VLBigTitle';
import VLParagraph from '../../Reusables/Texts/VLParagraph';
import VLButton from '../../Reusables/Buttons/VLButton';
import { centerFlexColumn } from '../../utils/bootstrapUtils';
import { isHebrew } from '../../App';
import IconClose from '../../components/Modal/GenericPaywallModal/images/icon_close_purple.svg';
import VLAppBar from '../../Reusables/Branding/VLAppBar';
import VLFlowManager from '../../managers/v2/VLFlowManager';
import {
  AuthStart,
  AuthFailure,
  AuthCompleted,
  AuthLanding,
  AuthVerified,
} from '../../managers/v2/VLFlowManager';
import Welcome from './Welcome';

type Providers = 'google' | 'facebook' | 'phone' | 'email';

type Step =
  | 'initial'
  | 'verification'
  | 'email-verification'
  | 'email-finish-connect'
  | 'completion';

type InputType = 'phone' | 'email';

const useStyles = makeStyles((theme: any) => ({
  main: {
    maxHeight: '720px',
    height: '100vh',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0px',
      maxHeight: '100%',
      height: '100%',
    },
  },
}));

/**
 * Verify code (6 digit input) screen
 */
const VerificationBody = (props?: any) => {
  const {
    handleSuccesfullAuthentication,
    showWelcomeScreenOnSuccess,
    step,
    inputValue,
  } = props;

  return step === 'verification' ? (
    <CodeConfirmationScreen
      phoneNumber={inputValue}
      showWelcomeScreenOnSuccess={showWelcomeScreenOnSuccess}
      onAuthSuccess={handleSuccesfullAuthentication}
      isModal
      onSignUpClick={() => {
        // console.log('damn');
      }}
    />
  ) : null;
};

const VerificationEmailBody = (props?: any) => {
  const { step } = props;
  return step === 'email-verification' ? <VerifyEmailScreen /> : null;
};

const FinishSignupBody = (props?: any) => {
  const { step, setNewUser } = props;
  return step === 'email-finish-connect' ? (
    <FinishSignupScreen
      onFinishSignup={(isNewUser: boolean) => setNewUser(isNewUser)}
    />
  ) : null;
};

interface NewAuthenticationFlowContainerProps {
  onCompleteFlow?: any;
  startStep?: Step;
  onClose?: Function;
  show?: boolean;
  fullScreen?: boolean;
  redirectToLink?: string;
  hideClose?: boolean;
}

const Master = (props: any) => {
  return (
    <div className={`${styles.Master} ${centerFlexColumn()}`}>
      {props.children}
    </div>
  );
};

const SimpleNavbar = (props: any) => {
  const { handleClose, step } = props;
  if (step === 'initial') return null;
  return (
    <div>
      <Box
        p={1}
        zIndex={2}
        width="100%"
        position="absolute"
        top={0}
        left={0}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <img alt="close" className="img-fluid" src={IconClose}></img>
        </IconButton>
      </Box>
    </div>
  );
};

const NewAuthenticationFlowContainer: React.FC<NewAuthenticationFlowContainerProps> = ({
  onCompleteFlow,
  startStep,
  onClose,
  show,
  fullScreen,
  redirectToLink,
  hideClose,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingFacebook, setShowLoadingFacebook] = useState(false);
  const [showLoadingGmail, setShowLoadingGmail] = useState(false);
  const [step, setStep] = useState<Step>('initial'); //new-user-signup
  const [inputValue, setInputValue] = useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [showError, setShowError] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const [isInputValid, setIsInputValid] = React.useState(false);
  const history = useHistory();
  const [inputType, setInputType] = React.useState<InputType | undefined>(
    undefined
  );

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new AuthLanding());
    if (startStep) setStep(startStep);
  }, []);

  const onConnectClick = () => {
    if (inputType == 'phone') {
      attemptSendSMSVerification(inputValue);
    } else if (inputType == 'email') {
      attemptEmailVerification(inputValue);
    } else {
      setErrorMsg('Please enter a valid phone number or email');
    }
  };

  const onEmailRecognized = (email: string) => {
    setIsInputValid(true);
    setInputType('email');
    setInputValue(email);
  };

  const onPhoneRecognized = (phone: string) => {
    // console.log('onPhoneRecognized: ', phone);
    setIsInputValid(true);
    setInputType('phone');
    setInputValue(phone);
  };

  const onButtonClicked = (providerType: Providers) => {
    switch (providerType) {
      case 'google':
        setShowLoadingGmail(true);
        attemptGoogleAuth();
        break;
      case 'facebook':
        setShowLoadingFacebook(true);
        attemptFacebookAuth();
        break;
      default:
        break;
    }
  };

  /**
   * Google auth
   */
  const attemptGoogleAuth = () => {
    VLFlowManager.sendFlowAnalytics(new AuthStart('gmail'));
    userManager.startAuthWithGoogle(
      (errorMsg?: string, isNewuser?: boolean) => {
        setShowLoading(false);
        if (errorMsg) {
          setShowLoadingGmail(false);
          setErrorMsg(errorMsg);
          showPageError(true);
          VLFlowManager.sendFlowAnalytics(new AuthFailure('gmail'));
        } else {
          setStep('completion');
          onAuthFlowCompleted(userManager.getUser());
          VLFlowManager.sendFlowAnalytics(new AuthCompleted('gmail'));
        }
      }
    );
  };

  /**
   * Facebook auth
   */
  const attemptFacebookAuth = () => {
    VLFlowManager.sendFlowAnalytics(new AuthStart('facebook'));
    userManager.startAuthWithFacebook(
      (errorMsg?: string, isNewuser?: boolean) => {
        setShowLoading(false);
        if (errorMsg) {
          setShowLoadingFacebook(false);
          setErrorMsg(errorMsg);
          showPageError(true);
          VLFlowManager.sendFlowAnalytics(new AuthFailure('facebook'));
        } else {
          setStep('completion');
          onAuthFlowCompleted(userManager.getUser());
          VLFlowManager.sendFlowAnalytics(new AuthCompleted('facebook'));
        }
      }
    );
  };

  const showPageError = (show: boolean) => {
    if (show) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  /**
   * Phone number auth
   * @param phone Phone number to send auth code to
   * @returns
   */
  const attemptSendSMSVerification = (phone: string) => {
    // console.log('parsing phone...', phone);
    if (phone.length === 0) {
      setErrorMsg(t('signup_error_enter_valid_input'));
      showPageError(true);
      return;
    }
    setShowLoading(true);
    const values = { phone: phone };
    prepareForValidation();
    VLFlowManager.sendFlowAnalytics(new AuthStart('phone'));
    userManager.startSignup(values).then((sentSMS: boolean) => {
      setShowLoading(false);
      if (sentSMS) {
        setStep('verification');
      } else {
        setErrorMsg(t('signup_failed_contact_suport'));
        VLFlowManager.sendFlowAnalytics(new AuthFailure('phone'));
      }
    });
  };

  /**
   * Email auth
   * @param email Email to send magic link to
   * @returns
   */
  const attemptEmailVerification = (email: string) => {
    // console.log('parsing email...', email);
    if (email.length === 0) {
      setErrorMsg(t('signup_error_enter_valid_input'));
      showPageError(true);
      return;
    }
    setShowLoading(true);
    prepareForValidation();
    VLFlowManager.sendFlowAnalytics(new AuthStart('email'));

    userManager.sendSignInLink(email, redirectToLink, (errorMsg?: string) => {
      setShowLoading(false);
      if (errorMsg) {
        setErrorMsg(errorMsg);
        showPageError(true);
        VLFlowManager.sendFlowAnalytics(new AuthFailure('email'));
      } else {
        setStep('email-verification');
      }
    });
  };

  const onAuthFlowCompleted = (user: any) => {
    let nextUrl = redirectOnCompleted();
    if (nextUrl) {
      //redirec url
      window.location.href = nextUrl ? nextUrl : '/home';
    } else if (onCompleteFlow) {
      //completion callback
      onCompleteFlow();
    } else {
      //or fallback
      window.location.href = '/home';
    }
  };

  const redirectOnCompleted = () => {
    let queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('r');
  };

  const redirectToHome = () => {
    window.location.href = '/home';
  };

  const onVerificationCompleted = (isNewUser: boolean) => {
    if (isNewUser) {
      // setStep('new-user-signup');//send to new onboarding flow
      onAuthFlowCompleted(userManager.getUser());
    } else {
      onAuthFlowCompleted(userManager.getUser());
    }
  };

  const prepareForValidation = () => {
    setErrorMsg('');
    showPageError(false);
  };

  const setNewUser = (newUser: boolean) => {
    if (newUser) {
      //@todo - take to onboarding flow
      //for now just completed
      onAuthFlowCompleted(userManager.getUser());
    } else {
      onAuthFlowCompleted(userManager.getUser());
    }
  };

  const onInvalidRecognized = (invalid: boolean, value: string) => {
    const valid = !invalid;
    setInputValue(value);
    setIsInputValid(valid);
  };

  const handleClose = () => {
    if (step === 'email-verification') {
      setStep('initial');
    } else if (onClose) {
      onClose();
    } else {
      //nop.. go home
      if (history.length > 2) {
        history.goBack();
      } else {
        //go to home
        history.replace('/home');
      }
    }
  };

  if (showError) {
    setTimeout(() => {
      setShowError(false);
    }, 4000);
  }

  return (
    <div
      className={`${
        fullScreen ? 'position-fixed vh-100 vw-100' : ''
      } bg-white ${classes.main}`}
      style={{ top: 0, left: 0 }}
    >
      <Grid container justify="center" className="h-100">
        <Master>
          <Welcome
            hideClose={hideClose}
            showLoadingFacebook={showLoadingFacebook}
            showLoadingGmail={showLoadingGmail}
            onInvalidRecognized={(invalid: boolean, value: string) =>
              onInvalidRecognized(invalid, value)
            }
            value={inputValue}
            onConnectClick={onConnectClick}
            isInputValid={isInputValid}
            setIsInputValid={setIsInputValid}
            step={step}
            isValid={false}
            onEmailRecognized={onEmailRecognized}
            onPhoneRecognized={onPhoneRecognized}
            onButtonClicked={onButtonClicked}
            onClose={() => onClose && onClose()}
          />
          <VerificationBody
            inputValue={inputValue}
            showWelcomeScreenOnSuccess={false}
            step={step}
            handleSuccesfullAuthentication={onVerificationCompleted}
          />
          <VerificationEmailBody step={step} />
          <FinishSignupBody step={step} setNewUser={setNewUser} />
        </Master>
      </Grid>
      <LoadingOverlay showLoading={showLoading} />
      <Toast
        error={showError}
        success={showSuccess}
        show={showError}
        onClick={() => showPageError(false)}
        title={t('auth_error_general_title')}
        detail={errorMsg}
      />
      <SimpleNavbar step={step} handleClose={handleClose} />
    </div>
  );
};

export default NewAuthenticationFlowContainer;
