import React, { Component } from 'react';

class ButtonLoader extends Component {
  render() {
    const invisible = this.props.invisible;
    const className = `spinner-border spinner-border-sm ${
      invisible ? 'invisible' : ''
    }`;
    return <span className={className} role="status" aria-hidden="true"></span>;
  }
  componentDidMount() {
    // console.log("button loader did mount");
  }
}

export default ButtonLoader;
