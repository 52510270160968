import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createRootReducer } from './reducers';
import rootSaga from './sagas';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, createRootReducer());

const createReduxStore = () => {
  // Redux store Configuration
  const middleware = [];

  // Skip redux logs in console during the tests
  if (process.env.REACT_APP_ENV === 'development') {
    middleware.push(logger);
  }

  // Saga Middleware
  middleware.push(sagaMiddleware);

  const enhancer = composeWithDevTools(applyMiddleware(...middleware));

  // create store
  return createStore(persistedReducer, {}, enhancer);
};

const store = createReduxStore();
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
export type { RootState } from './reducers';

const selectUserState = (state: any) => state.user;

/* Export selectors */
export { selectUserState };
