export enum BookingOccassion {
  NONE = -1,
  QUESTION = 0,
  BIRTHDAY = 5,
  MOTIVATION = 10,
  EVENT = 15,
  ADVICE = 20,
  PROMOTIONAL = 30,
  TESTIMONIAL = 35,
  COLLABORATION = 40,
  PRODUCT_PLACEMENT = 45,
  COMPANY_EVENT = 50,
  OTHER = 99,
}
