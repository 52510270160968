import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './RequestBox.module.scss';
import { Image } from '../../../image/image.component';
import { Button } from 'react-bootstrap';
import RankBadge from '../../../Common/RankBadge/RankBadge.component';
import { IBookingItem } from '../../../../model/interfaces';
import { isHebrew } from '../../../../App';

type RequestBoxProps = {
  index: number;
  request: IBookingItem;
  discover?: boolean;
};

const RequestBox: React.FC<RequestBoxProps> = React.memo(
  ({ request, index, discover }) => {
    const { i18n } = useTranslation();

    return (
      <div
        className={`${styles.reuqestBoxContainer} ${
          isHebrew() ? styles.heb : ''
        } ${discover ? styles.discover : ''}`}
        style={{
          marginLeft: discover || isHebrew() ? 0 : 20,
          marginRight: discover || !isHebrew() ? 0 : 20,
        }}
      >
        <div className="d-flex flex-column justify-content-between h-100">
          <a href={request.creator_deep_link_url} target="_blank">
            <div className={styles.header}>
              <div className={styles.imageContainer}>
                <Image src={request.creator_image_url} />
                <div className={styles.rankBadge}>
                  <RankBadge rank={9.2} />
                </div>
              </div>
              <div className={styles.nameTitle}>
                <span className={styles.username}>@{request.creator_info}</span>
                <span className={styles.name}>{request.creator_name}</span>
                <span className={styles.title}>{request.creator_title}</span>
              </div>
            </div>
          </a>
          <div className={styles.requestContent}>
            <a href={request.deep_link_url} target="_blank">
              <span>{request.title}</span>
            </a>
          </div>
          <a href={request.deep_link_url} target="_blank">
            <Button className={`${styles.action_button} btn-block`}>
              {request.action_title}
            </Button>
          </a>
        </div>
      </div>
    );
  }
);

export default RequestBox;
