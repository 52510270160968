import * as utils from '../../utils/rhutils.js';
import VLError from '../../model/vlError';
import api, * as RHAPI from '../../constants/api';
import userManager from '../userManager.js';
import VLPaymentOption from '../../model/paymentOption.js';
import firebase from 'firebase';
import { Creation } from '../../model/v2/creation.model.js';
import { FIREBASE_COLLECTION_CREATIONS } from '../../constants/rhconstants.js';

var _ = require('lodash');

var _ = require('lodash');

export interface IUploadTask {
  resume(): void;
  pause(): void;
  cancel(): void;
}

export class VLUploadTask implements IUploadTask {
  private _itask: firebase.storage.UploadTask;
  constructor(task: firebase.storage.UploadTask) {
    this._itask = task;
  }

  resume() {
    this._itask.resume();
  }
  pause() {
    this._itask.pause();
  }
  cancel() {
    this._itask.cancel();
  }
}
class CreationManager2 {
  constructor() {}

  async submitCreation(
    creation: Creation,
    notifyAudience: 'subscribers' | 'followers' | 'everyone' | 'none' = 'none',
    callback: (success: boolean, errorMsg?: string) => void
  ) {
    // console.log("headers: ", headers);

    var headers = await userManager.getRequestHeaders();

    const db = firebase.firestore();
    const creationItemRef = db.collection(FIREBASE_COLLECTION_CREATIONS).doc();
    const id = creationItemRef.id;
    creation.id = id;

    var body = JSON.stringify(creation);

    const url = api.SUBMIT_CREATION_ITEM;

    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    utils.log.debug('submitCreation', json);
    if (json.error) {
      utils.log.error('submitCreation error', json);
      let error = VLError.fromJSON(json.error);
      callback(false, error?.localizedTitle());
    } else {
      utils.log.debug('submitCreation success', json);
      callback(true, undefined);
    }
  }

  /**
   *
   * @param file File to upload
   * @param callback Callback handler
   */
  uploadCreation(
    file: File,
    callback: (remoteURL?: string, progress?: number, errorMsg?: string) => void
  ): VLUploadTask {
    return this.uploadFile('creations', file, callback);
  }

  /**
   *
   * @param folderName Folder name to upload in (make sure storage rules suffice)
   * @param file The file to upload
   * @param callback Callback handler
   */
  uploadFile(
    folderName: string,
    file: File,
    callback: (remoteURL?: string, progress?: number, errorMsg?: string) => void
  ): VLUploadTask {
    utils.log.debug('upload file..', file);
    const storage = firebase.storage();
    const ref = storage.ref();
    const ts = new Date().getTime() / 1000;
    let originalFilename = file.name;
    let extension = originalFilename.split('.').pop();
    var fileName = `${ts}.${extension}`;
    var path = `${folderName}/${fileName}`;

    var storageRef = ref.child(path);

    var uploadTask = storageRef.put(file);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // utils.log.debug("Upload is " + progress + "% done");
        callback(undefined, progress, undefined);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // utils.log.debug("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // utils.log.debug("Upload is running");
            break;
        }
      },
      function (error) {
        // Handle unsuccessful uploads
        utils.log.debug('🚨error uploading session file -', error);
        callback(undefined, 0, error.message);
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          utils.log.debug('✅ File available at', downloadURL);
          callback(downloadURL, 100, undefined);
        });
      }
    );

    return new VLUploadTask(uploadTask);
  }

  pauseUpload(task: IUploadTask) {
    task.pause();
  }

  resumeUpload(task: IUploadTask) {
    task.resume();
  }
}

var creationManager2 = new CreationManager2();
export default creationManager2;
