/**
 * Custom Table
 */
import React, { Component, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Container,
  Link,
} from '@material-ui/core';
import { withRouter, NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { isHebrew, lclDirection } from '../../../../utils/vlStrings';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    positon: 'relative',
    borderRadius: '32px',
    overflow: 'hidden',
  },
  thumbnail: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    objectFit: 'cover',
  },
});

const ChallengeSubmissionBox = (props) => {
  const { classes, thumbnail, author, title, link } = props;

  useEffect(() => {
    //should fetch challenge details?
  }, []);

  const preventDefault = (event) => {
    event.preventDefault();
    window.open(link, '_blank');
  };

  return (
    <div className={classes.root} dir={lclDirection()}>
      <Link onClick={preventDefault}>
        <Box position="relative" width="100%" height="100%">
          <img src={thumbnail} className={classes.thumbnail} alt="thumbnail" />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            zIndex={10}
            color="white"
            position="absolute"
            bottom={16}
            left={isHebrew() ? 0 : 16}
            right={isHebrew() ? 16 : 0}
            width="80%"
          >
            <Box>
              <Box
                fontWeight="bold"
                fontSize="17px"
                color="white"
                id="author"
                mb={1}
              >
                {author}
              </Box>
              <Box fontSize="14px" color="normal" id="title">
                {title}
              </Box>
            </Box>
          </Box>
        </Box>
      </Link>
    </div>
  );
};

export default withRouter(
  connect(
    null,
    null
  )(withStyles(styles, { withTheme: true })(ChallengeSubmissionBox))
);
