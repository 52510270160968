enum VLProfileTip {
  ProfilePicture = 0,
  ProfileVideo = 1,
  Title = 2,
  Tags = 3,
  Description = 4,
  Socials = 5,
  Cause = 6,
  PersonalRequests = 7,
  BusinessRequests = 8,
  Subscriptions = 9,
  Verify = 10,
}
export default VLProfileTip;
