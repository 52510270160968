import React, { useState, useEffect } from 'react';
import styles from './EditProfileSelect.module.scss';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Buttons = (props)=>{
    return (<div className={styles.buttons}>
        <div className={`${styles.button} ${styles.buttonSoft}`} onClick={props.close}>{props.cancelText}</div>
        <div className={`${styles.button} ${styles.buttonBold}`} onClick={props.save}>{props.saveText}</div>
    </div>)
}
/* The select list component when you are choosing the price */

const AddLinkSelect = (props) => {
  const { t, i18n } = useTranslation();
  const [desc,setDesc] = useState('')
  const [link,setLink] = useState('')
 
  const save = ()=>{
    props.save(link,desc)
  }

  return (
    <div className={`${styles.page} ${i18n.language === 'he' ? styles.heb : ''}`}>
        <div className={styles.wrapper}>
            <div className={styles.title}>{t('editProfileRequests_AddLink')}</div>
            {/* List of options */}
            <ul className={styles.list}>
              <input type="text" className={styles.input} value={desc} onChange={(e)=>{setDesc(e.target.value)}} placeholder={t('description')} />
              <input type="text" className={styles.input} value={link} onChange={(e)=>{setLink(e.target.value)}} placeholder={t('EditProfileSocial_Link')}/>
            </ul>
        
            {/* buttons - set and cancel */}
            <Buttons cancelAddLink={props.cancelAddLink} close={props.close} save={save} cancelText={t('editProfile_list_cancel')} saveText={t('editProfile_list_set')}/>
        </div>
    </div>
  );
};

export default withRouter(AddLinkSelect);
