import * as strings from '../utils/vlStrings.js';
import * as utils from '../utils/rhutils.js';
import memoize from 'lodash/memoize';

import publicPostImage from '../images/post_visibility_icon_public.png';
import privatePostImage from '../images/post_visibility_icon_private.png';
import subscribersPostImage from '../images/post_visibility_icon_subscribers.png';

import dbManager from '../managers/dbManager';
import * as constants from '../constants/rhconstants';
import PostVisibilityType from './enums/PostVisibilityType';

class VLPost {
  /**
   * Unique id of the post
   */
  id = null;
  /**
   * The title of the post
   */
  title = null;
  /**
   * The url to the video file of the post
   */
  videoURL = null;
  /**
   * The image of the post (not used for now - in future, people can send images not just videos)
   */
  imageURL = null;
  /**
   * Thumbnail for the post
   */
  thumbURL = null;
  /**
   * User id of who created the post / the user its assigned to
   */
  userId = null;
  /**
   * The id of the booking request
   */
  requesterId = null;
  /**
   * The id of the user who made the request for booking request
   */
  requesterId = null;
  /**
   * Date was created on
   */
  createdOn = Date();
  /**
   * Date last updated
   */
  lastUpdated = Date();
  /**
   * Visibility type
   */
  visibilityType = PostVisibilityType.PUBLIC;

  /**
   * Number of likes
   */
  likesCount = 0;
  /**
   * Number of comments
   */
  commentsCount = 0;
  /**
   * Number of views
   */
  viewsCount = 0;

  /**
   * Is the post starred (by admin or other user - meaning its important)
   */
  starred = false;

  localizedTitle() {
    if (strings.isHebrew()) {
      return this.titleHebrew ? this.titleHebrew : this.title;
    } else {
      return this.title;
    }
  }

  /**
   * Returns if the post Should the post be shown to subscribers only
   */
  showToSubsribersOnly() {
    return this.visibilityType === PostVisibilityType.SUBSCRIBERS_ONLY;
  }
  matchesQuery(query) {
    let lcQuery = query.toLowerCase();
    let includesQuery = this.localizedTitle()
      ? this.localizedTitle().toLowerCase().includes(lcQuery)
      : false;
    if (this.creator) {
      return this.creator.matchesQuery(query) || includesQuery;
    } else {
      return includesQuery;
    }
  }
  /**
   * Determine if the current post should be "blocked" from being viewed by the current user
   */
  blockFromCurrentUser() {
    if (this.showToSubsribersOnly() == false) {
      return false;
    }

    //if the user is a subscriber of the local user object (attached to the post)
    if (this.creator && this.creator.isCurrentUserASubscriber() == true) {
      return false;
    }

    //if the user is a subscriber of a DB object (checking by plain properties of this post)
    if (utils.currentUser()) {
      let currentUserId = utils.currentUser().id;
      const subscriptionsCollection = dbManager.getCollection(
        constants.LOCAL_DB_COLLECTION_SUBSCRIPTIONS
      );
      if (!subscriptionsCollection) {
        return null;
      }
      var subscription = subscriptionsCollection.findOne({
        creatorId: this.id,
        userId: currentUserId,
        active: true,
      });
      if (subscription) {
        return false;
      }
    }

    //If the user can edit this post, don't show blocker
    return !this.shouldAllowEdit();
  }
  shouldAllowEdit() {
    return (
      utils.showAdminTools() ||
      (utils.currentUser() && utils.currentUser().id === this.userId)
    );
  }
  /**
   * Determines if this creation is for subscribers only
   */
  isSubscriberOnly() {
    return false;
  }

  /**
   * Determines if this creation is a buy to watch
   */
  isBuyToWatch() {
    return false;
  }
  postCellImage() {
    switch (this.visibilityType) {
      case PostVisibilityType.PUBLIC:
        return publicPostImage;
      case PostVisibilityType.PRIVATE:
        return privatePostImage;
      case PostVisibilityType.SUBSCRIBERS_ONLY:
        return subscribersPostImage;
      default:
        return subscribersPostImage;
    }
  }
  saveToLocal() {
    localStorage.setItem(this.id, JSON.stringify(this));
  }
  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  static nextVisibility(currentVisibility) {
    switch (currentVisibility) {
      case PostVisibilityType.PUBLIC:
        return PostVisibilityType.PRIVATE;
      case PostVisibilityType.PRIVATE:
        return PostVisibilityType.SUBSCRIBERS_ONLY;
      case PostVisibilityType.SUBSCRIBERS_ONLY:
        return PostVisibilityType.PUBLIC;
      default:
        return PostVisibilityType.PUBLIC;
    }
  }

  static fromJSON = memoize((json) => {
    var post = new VLPost();
    try {
      post.id = json.id;
      post.title = json.title;
      post.titleHebrew = json.title_hebrew;
      post.userId = json.user_id;
      post.videoURL = json.videoURL;
      post.thumbURL = json.thumb_url;
      post.featured = json.featured ? json.featured : false;
      post.transactionId = json.transaction_id;
      let isPublic = json.public ? json.public : false;
      post.visibilityType = json.visibility_type
        ? json.visibility_type
        : isPublic
        ? PostVisibilityType.PUBLIC
        : PostVisibilityType.PRIVATE;
      post.likesCount = json.likes_count ? json.likes_count : 0;
      post.commentsCount = json.comments_count ? json.comments_count : 0;
      post.viewsCount = json.views_count ? json.views_count : 0;
      post.lastUpdated = json.last_updated
        ? new Date(json.last_updated)
        : post.lastUpdated;
      post.createdOn = json.created_on
        ? new Date(json.created_on)
        : post.createdOn;

      //new props
      post.requesterId = json.requester_id;
      post.requestId = json.request_id;
      post.starred = json.starred ?? false;
      return post;
    } catch (error) {
      return null;
    }
  });
}

export default VLPost;
