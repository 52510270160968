/**
 * Custom Table
 */
import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Container,
  ButtonBase,
  TextField,
  useMediaQuery,
  InputAdornment,
  useTheme,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import CoolHandUpper from './images/cool_hand_upper.png';
import { isHebrew } from '../../../App';
import SendIcon from '@material-ui/icons/Send';
import { useTranslation, withTranslation } from 'react-i18next';
import { analytics, AnalyticsEvent } from '../../../utils/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '160px',
    background: '#3A2D5C',
    borderRadius: '24px',
    [theme.breakpoints.down('xs')]: {
      height: '220px',
    },
  },
  inputRoot: { color: 'rgba(134, 129, 145, 1.0)' },
  inputInput: {
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
  },
  underline: {
    '&::before': {
      borderBottom: '2px solid #AE9BDE',
    },
    '&::after': {
      border: '1px solid #AE9BDE',
    },
  },
  textBox: {
    width: '70%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  imageBox: {
    width: '30%',
    [theme.breakpoints.down('xs')]: {
      width: '0%',
    },
  },
  textField: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
  },
}));

const CreatorRecommendationBox = (props) => {
  const { smallScreen } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [submitValue, setSubmitValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onChange = (e) => {
    setSubmitValue(e.target.value);
  };

  const onSubmitReccomendation = () => {
    const properties = [{ full_name: submitValue }];
    analytics.event(
      new AnalyticsEvent('creator_suggestion_submitted', properties)
    );
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 3000);
  };

  const disableSubmit = submitValue.length < 3;

  return (
    <Box
      width="100%"
      className={classes.root}
      position="relative"
      overflow="hidden"
      display="flex"
      flexDirection="row"
    >
      <Box
        p={3}
        width="70%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
        className={classes.textBox}
      >
        <Typography variant="h4">
          <Box color="text.secondary" mb={2}>
            {t('rec_creator_title')}
          </Box>
        </Typography>
        <Typography variant="body2">
          <Box className="text-muted" mb={2} width="75%" fontSize="12px">
            {t('rec_creator_detail')}
          </Box>
        </Typography>
        <Box id="textfield" className={classes.textField}>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => !disableSubmit && onSubmitReccomendation()}
                    style={{ opacity: disableSubmit ? 0.3 : 1.0 }}
                    disabled={disableSubmit}
                    p={0.5}
                    width="32px"
                    height="32px"
                    color="white"
                  >
                    {isSubmitting ? (
                      <CircularProgress color="secondary" size={24} />
                    ) : (
                      <SendIcon style={{ color: '#DBCAFE' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: 'off',
              classes: {
                input: classes.inputInput,
                root: classes.inputRoot,
                underline: classes.underline,
              },
            }}
            inputProps={{ disableUnderline: false }}
            style={{
              width: '100%',
              maxWidth: '320px',
              marginLeft: !isHebrew() ? '0px' : '16px',
              marginRight: isHebrew() ? '0px' : '16px',
            }}
            type="text"
            name="search-users"
            id="search"
            placeholder={t('rec_creator_placeholder')}
            onChange={onChange}
          />
        </Box>
      </Box>
      <Box
        className={classes.imageBox}
        position="relative"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <img
          src={CoolHandUpper}
          alt="cool hand"
          style={{
            height: '75%',
            objectFit: 'contain',
            width: 'auto',
          }}
        />
      </Box>
    </Box>
  );
};

export default CreatorRecommendationBox;
