import React from "react";
import styles from "../AuthDialog.module.scss";
import ClassNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { RoundLoader } from '../../components/loader/roundLoader.component';

import { Image } from '../../components/image/image.component';

import Instagram from '../../images/social_networks/instagram.svg';
import Facebook from '../../images/social_networks/facebook.svg';
import Twitter from '../../images/social_networks/twitter.svg';
import YouTube from '../../images/social_networks/youtube.svg';
import TikTok from '../../images/social_networks/tiktok.svg';
import Website from '../../images/social_networks/link.svg';

const Valid = (props: any) => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 7.99996L6.15743 10.9182C6.4068 11.1487 6.79483 11.137 7.02987 10.8919L13 4.66663" stroke="#4CD964" strokeWidth="2.5" strokeLinecap="round"/>
  </svg>;
};

const Invalid = (props: any) => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.resetButton} onClick={props.onClick}>
    <g clipPath="url(#clip0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9996 1.25216C12.3461 1.16177 12.5537 0.807589 12.4633 0.461084C12.3729 0.114579 12.0187 -0.0930412 11.6722 -0.00264865L9.00103 0.694185L8.93914 0.710331L8.8816 0.738257C8.6323 0.859248 8.4371 1.06889 8.33418 1.32618C8.23126 1.58348 8.22803 1.86991 8.32512 2.12945L8.3293 2.14064L8.3339 2.15167L9.49529 4.939C9.63302 5.26956 10.0126 5.42587 10.3432 5.28814C10.6738 5.15041 10.8301 4.77079 10.6923 4.44023L10.0106 2.80407C11.0251 3.20472 11.9075 3.88525 12.5533 4.76904C13.2537 5.72741 13.6418 6.87818 13.6651 8.06484C13.6631 9.26921 13.2847 10.4429 12.5829 11.4218C11.8797 12.4025 10.8871 13.1385 9.74435 13.5264C9.36477 13.6552 9.1615 14.0674 9.29035 14.447C9.4192 14.8266 9.83136 15.0299 10.211 14.901C11.6408 14.4157 12.8828 13.4948 13.7626 12.2676C14.6425 11.0405 15.116 9.56867 15.1168 8.0587H15.1169L15.1166 8.04496C15.0892 6.55713 14.6034 5.11401 13.7254 3.91255C12.9734 2.88359 11.9653 2.07482 10.8061 1.5635L11.9996 1.25216Z" fill="#DF1338"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.14106 2.70866C6.52155 2.5825 6.72773 2.17178 6.60157 1.79129C6.47541 1.4108 6.06469 1.20462 5.6842 1.33078C4.27277 1.79877 3.04713 2.70422 2.18504 3.9158C1.32717 5.12146 0.873693 6.56788 0.889468 8.04714C0.824306 9.55588 1.25728 11.0444 2.12196 12.283C2.81741 13.2792 3.76087 14.0689 4.85207 14.5786L3.62562 14.7319C3.27028 14.7764 3.01823 15.1004 3.06265 15.4558C3.10707 15.8111 3.43113 16.0631 3.78647 16.0187L6.55283 15.6729C6.73805 15.6581 6.87921 15.5774 6.94837 15.5325C7.04341 15.4707 7.13117 15.391 7.20294 15.3013C7.26991 15.2176 7.35763 15.0818 7.39164 14.9033C7.41829 14.7633 7.40979 14.5935 7.3344 14.4328L6.66062 11.5131C6.5801 11.1642 6.23196 10.9466 5.88303 11.0271C5.5341 11.1076 5.31651 11.4558 5.39703 11.8047L5.7632 13.3914C4.77647 13.0002 3.9231 12.3271 3.31225 11.452C2.62669 10.47 2.28506 9.28891 2.34053 8.09256L2.34124 8.04895C2.325 6.87056 2.68458 5.71763 3.36782 4.7574C4.05107 3.79716 5.02244 3.07956 6.14106 2.70866Z" fill="#DF1338"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
};

class SocialNetworksBody extends React.Component<any, any>
{
  constructor(props: any) {
    super(props);

    this.state = {

    }
  }

  render()
  {
    let { className, networks, onChange, validateNetwork, resetNetwork } = this.props;

    let sections = [
      {
        name: "Instagram",
        icon: Instagram,
        key: "instagram"
      },
      {
        name: "Facebook",
        icon: Facebook,
        key: "facebook"
      },
      {
        name: "Twitter",
        icon: Twitter,
        key: "twitter"
      },
      {
        name: "YouTube",
        icon: YouTube,
        key: "youtube"
      },
      {
        name: "TikTok",
        icon: TikTok,
        key: "tiktok"
      },
      {
        name: "Website",
        icon: Website,
        key: "website"
      }
    ];

    return <div className={className}>
      {sections.map((network, index) => {

        return <section>
          <label htmlFor={network.key}>
            <Image src={network.icon} />
          </label>
          <input type={"text"} value={networks[network.key] ? networks[network.key].value : ""} id={network.key} tabIndex={index+1} onChange={event => onChange(network.key, event.target.value)} placeholder={network.name} onBlur={() => validateNetwork(network.key)}/>
          {networks[network.key] && networks[network.key].state > 0 && <i>
            {networks[network.key].state === 1 && <RoundLoader />}
            {networks[network.key].state === 2 && <Valid />}
            {networks[network.key].state === 3 && <Invalid onClick={() => resetNetwork(network.key)} />}
          </i>}

          {networks[network.key] && networks[network.key].state === 3 && <span>There is no user by name “{networks[network.key].value}”</span>}
        </section>
      })}
    </div>;
  }
}

export default withTranslation()(SocialNetworksBody);