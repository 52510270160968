
export function getReferral(url, returnDefault = null)
{
  let ref = new URLSearchParams(url).get('ref');
  return ref ? ref : returnDefault;
}

export function getAffiliate(url, returnDefault = null)
{
  let aff = new URLSearchParams(url).get('aff');
  return aff ? aff : returnDefault;
}