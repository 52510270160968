import * as utils from '../../utils/rhutils';
import React, { useState, useEffect } from 'react';
import styles from './Modal.module.scss';
import Success from './Success/Success';
import { useTranslation } from 'react-i18next';
import * as anim from '../animations/vlFadeIn';
import GenericPaywallModal from './GenericPaywallModal/GenericPaywallModal';
import CauseInfoView from './CauseInfoView/CauseInfoView';
import Booking, {
  allSupportedFlows,
  ORDER_FLOW_INITIAL,
} from './Booking/Booking';
import ProfileV2 from '../../Profile/Profile';

import VLUser from '../../model/vlUser';
import VLCreationItem from '../../model/vlCreationItem';
import PageLoader from '../../components/pageLoader';

//MATERIAL
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { centerFlexColumn } from '../../utils/bootstrapUtils';
import { UnderConstruction } from '../WebsiteBlockerView';

let startY = 0;
let lastY = 0;

interface ModalProps {
  onClose: any; // on close callback method
  showClose?: boolean;
  wrapperCustomClass?: any;
  wrapperCustomStyle?: any;
  User?: VLUser;
  title?: string;
  detail?: string;
  buttonText?: string;
  bookingFlow?: typeof allSupportedFlows[number];
  onAddPaymentMethodSuccess?: any; // optional - on auth success callback method
  creationData?: VLCreationItem; //optional - creation data (to purchase / access)
  kind:
    | 'subscription'
    | 'booking'
    | 'cause'
    | 'order_paywall_personal'
    | 'order_paywall_business'
    | 'success'
    | 'profile'
    | 'campaign';
  fullScreen?: boolean;
  paymentData?: { price: number; currency: string };
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

/**
 * Loading component wrapper
 */
const Loading = (props: any) => {
  return props.showLoading ? (
    <div className="fixed-top w-100 h-100">
      <PageLoader darkOpacity={true} withBackground={true} />
    </div>
  ) : null;
};

let creator: VLUser;

/**
 * Shows the ordering booking flow
 */
const OrderingFlow = (props: any) => {
  return props.kind === 'booking' ? (
    <Booking
      onClose={props.onClose}
      creator={props.User}
      preFlow={props.bookingFlow ?? ORDER_FLOW_INITIAL}
      forceAuth={false}
      fullScreen={false}
      removeBackgroundFromEarn={false}
    />
  ) : null;
};

/**
 * Shows a cause/charity
 */
const CauseFlow = (props: any) => {
  return props.kind === 'cause' ? (
    <CauseInfoView User={props.User} closeClicked={() => props.onClose()} />
  ) : null;
};

/**
 * Shows the subscription flow with paywall if needed
 */
const SubscriptionFlow = (props: any) => {
  if (props.kind !== 'subscription' || !props.User) {
    return null;
  }
  // console.log('props:', props);

  const { User, paymentData, onAddPaymentMethodSuccess, onClose } = props;

  //validate all data exists
  if (!User || !paymentData || !paymentData.price || !paymentData.currency) {
    return null;
  }

  return (
    <GenericPaywallModal
      price={props.paymentData.price}
      currency={props.paymentData.currency}
      type={'subscription'}
      creator={props.User!}
      creationData={props.creationData}
      onAddPaymentMethodSuccess={onAddPaymentMethodSuccess}
      onClose={onClose}
    />
  );
};

/**
 * Shows the paywall flow for orders
 */
const OrderPaywallFlow = (props: any) => {
  const { User, paymentData, onAddPaymentMethodSuccess, onClose } = props;
  const { t, i18n } = useTranslation();

  if (
    props.kind !== 'order_paywall_personal' &&
    props.kind !== 'order_paywall_business'
  ) {
    return null;
  }

  // console.log('OrderPaywallFlow props', props);

  //validate all data exists
  if (!User || !paymentData || !paymentData.price || !paymentData.currency) {
    return null;
  }

  return (
    <GenericPaywallModal
      price={paymentData.price}
      currency={paymentData.currency}
      type={
        props.kind === 'order_paywall_personal'
          ? 'personalized_video'
          : 'promotional_video'
      }
      onAddPaymentMethodSuccess={onAddPaymentMethodSuccess}
      onClose={onClose}
      creator={User}
      buttonText={t('confirm_purchase')}
    />
  );
};

/**
 * Shows the paywall flow for Campaigns
 */
const CampaignOrderFlow = (props: any) => {
  const { paymentData, onAddPaymentMethodSuccess, onClose } = props;
  const { t, i18n } = useTranslation();

  if (props.kind !== 'campaign') {
    return null;
  }

  // console.log('OrderPaywallFlow props', props);

  //validate all data exists
  if (!paymentData || !paymentData.price || !paymentData.currency) {
    return null;
  }

  return (
    <GenericPaywallModal
      cardInputTitle={t('add_payment_method')}
      hideRedeemCouponCode
      price={paymentData.price}
      currency={paymentData.currency}
      type="campaign"
      onAddPaymentMethodSuccess={onAddPaymentMethodSuccess}
      onClose={onClose}
      buttonText={t('confirm_purchase')}
    />
  );
};

/**
 * Shows a generic success modal with texts
 */
const ShowSuccessFlow = (props: any) => {
  return props.kind === 'success' ? (
    <Success
      onClose={props.onClose}
      showClose={true}
      title={props.title}
      detail={props.detail}
      buttonText={props.buttonText}
    />
  ) : null;
};

/**
 * Shows a specific user's profile
 */
const ShowProfileFlow = (props: any) => {
  return props.kind === 'profile' ? (
    <div>
      <ProfileV2
        //@ts-ignore
        hideBackground={true}
        onClose={props.onClose}
        overflowHidden={true}
        hideNavBar={true}
        user={props.User}
        onExitClicked={props.onClose}
      />
    </div>
  ) : null;
};

/**
 * Main content container for all possible flows
 */
const MainContentContainer = (props: any) => {
  // console.log('MainContentContainer');

  const { onClose } = props;

  return (
    <div className={`${styles.wrapper} ${centerFlexColumn()} `}>
      <div className={`${styles.wrapperScroll} ${centerFlexColumn()} `}>
        <OrderingFlow {...props} />
        <CampaignOrderFlow {...props} />
        <CauseFlow {...props} onClose={onClose} />
        <SubscriptionFlow {...props} />
        <OrderPaywallFlow {...props} />
        <ShowSuccessFlow {...props} />
        <ShowProfileFlow {...props} />
      </div>
    </div>
  );
};

const Modal = React.memo((props: ModalProps) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const [move, setMove] = useState(0);
  const [opacity, setOpacity] = useState(0.8);
  const [hideEffect, setHideEffect] = useState(false);
  const [canDragFromBody, setCanDragFromBody] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  return <UnderConstruction />;

  /**
   * On close function called for when main content is closed
   */
  const onClose = () => {
    if (props.onClose) {
      utils.log.debug('modal props.onClose called');
      props.onClose();
    } else {
      setIsOpen(false);
      // console.error('this component need onClose event');
    }
  };

  /**
   * Esc keystroke handler
   * @param {*} event Event
   */
  const escFunction = (event: any) => {
    // utils.log.debug("esc function pressed on home screen", event);
    if (event.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  /**
   * Check what to do on a "close" event from the modal
   * @param {*} e Event
   */
  const checkIfOnClose = (e: any) => {
    if (
      e &&
      e.target &&
      e.target.className &&
      typeof e.target.className === 'string' &&
      (e.target.className.includes('Modal_close_') ||
        e.target.className.includes('Modal_modal'))
    ) {
      if (window.innerWidth < 1024) {
        setHideEffect(true);
        setTimeout(() => {
          setMove(-window.innerHeight);
          setOpacity(0);
        }, 50);
        setTimeout(() => {
          onClose();
          setMove(0);
          setOpacity(0.8);
          setHideEffect(false);
        }, 300);
      } else {
        onClose();
      }
    }
  };

  if (!isOpen) {
    return <div></div>;
  }

  return (
    <Dialog
      maxWidth={props.maxWidth ?? false}
      fullScreen={props.fullScreen ?? fullScreen}
      open={true}
      disableEnforceFocus={true}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      className={`${styles.customDialog}`}
    >
      <div className={`${centerFlexColumn()} w-100 h-100`}>
        <MainContentContainer {...props} onClose={onClose} />
        <Loading showLoading={showLoading} />
      </div>
    </Dialog>
  );
});
export default Modal;
