import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../hooks/useFetch';
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb.component';
import Footer from '../../NewFooter/Footer';
import { NewNavbar } from '../../NewNavbar';
import Sections from '../Homepage/Sections';
import styles from './Discover.module.scss';
import * as bootstrapUtils from '../../../utils/bootstrapUtils';
import PageLoader from '../../pageLoader';
import { fetchDiscover } from '../../../managers/v2/feedManager';

type SeeMoreProps = {};

const Discover: React.FC<SeeMoreProps> = React.memo(({}) => {
  const { i18n } = useTranslation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const category_id = params.get('category_id');
  const [loading, setLoading] = useState(true);
  const [feed, setFeed] = useState([]);

  useEffect(() => {
    fetchDiscover(category_id ?? '', (response: any, error: any) => {
      setLoading(false);
      if (response.feed) {
        setFeed(response.feed);
      } else {
      }
    });
  }, []);

  return (
    <div className={styles.seeMoreContainer}>
      {loading && <PageLoader darkOpacity={true} withBackground={true} />}
      {!loading && (
        <>
          <NewNavbar showSearch={false} theme="dark" />
          <Breadcrumb pageTitle={params.get('title')} />
          <div className={styles.title} dir={bootstrapUtils.lclDirection()}>
            {params.get('title')}
          </div>
          <div>
            <Sections feed={feed} discover={true} />
          </div>
          <Footer theme="dark" />
        </>
      )}
    </div>
  );
});

export default Discover;
