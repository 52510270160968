import BaseTracker from './base.tracker';
import amplitude from 'amplitude-js/amplitude';
import moment from 'moment';

const TAG = 'IntercomTracker: ';
const SESSION_KEY = 'kreSession';

export class IntercomTracker extends BaseTracker {
  init() {
    return this;
  }

  event(name, properties = []) {
    return new Promise((resolve) => {
      let props = {};

      if (properties instanceof Array)
        properties.map((property) => {
          for (let key in property) props[key] = property[key];
        });

      // console.log(TAG, 'sending event [ ', name, ' ]: ', props);
      resolve(window.Intercom('trackEvent', name, props));
    });
  }

  setUserProperty(name, property) {
    // console.log(
    //   TAG,
    //   'set intercom user property [ ',
    //   name,
    //   ' : ',
    //   property,
    //   ' ]'
    // );
    let userProperties = {};
    userProperties[name] = property;
    window.Intercom('update', userProperties);
    return this;
  }

  setUserId(userId) {
    // console.log(TAG, "set user id [ ", userId, " ]");
    window.Intercom('update', { user_id: userId });
    this.setUserProperty('platform', 'web');
    return this;
  }
  setEmail(email) {
    // console.log(TAG, "set user id [ ", userId, " ]");
    window.Intercom('update', { email: email });
    return this;
  }

  setIsCreator = (isCreator) =>
    this.setUserProperty('is_creator', isCreator ? true : false);
  setDesktop = () => this.setUserProperty('Mobile', 'No');
  setMobile = () => this.setUserProperty('Mobile', 'Yes');
  setName = (name) => {
    this.setUserProperty('Name', name);
    this.setUserProperty('name', name);
  };
  setProfileId = (profileId) => this.setUserProperty('ProfileId', profileId);
}
