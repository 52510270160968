import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { withBookingStage } from '../../../../../shared/hoc';
import { updateBrief } from '../../../../../store/reducers/campaigns/';
import { useTranslation } from 'react-i18next';
import VLFlowManager, {
  CampaignFlowEventBriefEntry,
} from '../../../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
  },
  input: {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.165px',
  },
  inputMulti: {},
}));

const FromWhoName = (props) => {
  const { t } = props;
  const classes = useStyles();

  const placeholder = t('campaigns_brief_placeholder_from');
  const helperText = t('campaigns_brief_decorator_optional');

  return (
    <Box
      mt={2}
      width="100%"
      display="flex"
      flexDirection="column"
      fontFamily="Assistant"
    >
      <TextField
        defaultValue={props.default}
        inputRef={props.inputRef}
        onChange={props.onChange}
        InputProps={{ style: { fontWeight: 600, fontFamily: 'Assistant' } }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        InputLabelProps={{
          style: { color: 'rgba(0, 0, 0, 0.3)' },
        }}
        color="primary"
        fullWidth={true}
        label={placeholder}
        helperText={helperText}
        FormHelperTextProps={{
          style: { color: 'black', fontFamily: 'Assistant' },
        }}
      />
    </Box>
  );
};

const ForWhoName = (props) => {
  const { t, inputRef } = props;
  const classes = useStyles();

  const placeholder = t('campaigns_brief_placeholder_for');
  const helperText = t('campaigns_brief_decorator_optional');

  return (
    <Box
      mt={2}
      width="100%"
      display="flex"
      flexDirection="column"
      fontFamily="Assistant"
    >
      <TextField
        defaultValue={props.default}
        inputRef={props.inputRef}
        onChange={props.onChange}
        InputProps={{ style: { fontWeight: 600, fontFamily: 'Assistant' } }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        InputLabelProps={{
          style: { color: 'rgba(0, 0, 0, 0.3)' },
        }}
        color="primary"
        fullWidth={true}
        label={placeholder}
        helperText={helperText}
        FormHelperTextProps={{
          style: { color: 'black', fontFamily: 'Assistant' },
        }}
      />
    </Box>
  );
};

const ContentBusinessName = (props) => {
  const { t } = props;
  const classes = useStyles();

  const placeholder = t('campaigns_brief_placeholder_business_name');
  const helperText = '';

  return (
    <Box
      mt={2}
      width="100%"
      display="flex"
      flexDirection="column"
      fontFamily="Assistant"
    >
      <TextField
        defaultValue={props.default}
        inputRef={props.inputRef}
        onChange={props.onChange}
        required
        InputProps={{ style: { fontWeight: 600, fontFamily: 'Assistant' } }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        InputLabelProps={{
          style: { color: 'rgba(0, 0, 0, 0.3)' },
        }}
        color="primary"
        fullWidth={true}
        label={placeholder}
        helperText={helperText}
        FormHelperTextProps={{
          style: { color: 'black', fontFamily: 'Assistant' },
        }}
      />
    </Box>
  );
};

const ContentMessage = (props) => {
  const { t } = props;
  const classes = useStyles();

  const placeholder = t('campaigns_brief_placeholder_message');
  const helperText = t('campaigns_brief_helperText_message');

  return (
    <Box
      mt={2}
      width="100%"
      display="flex"
      flexDirection="column"
      fontFamily="Assistant"
    >
      <TextField
        defaultValue={props.default}
        inputRef={props.inputRef}
        onChange={props.onChange}
        required
        multiline
        InputProps={{ style: { fontWeight: 600, fontFamily: 'Assistant' } }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          classes: {
            input: `${classes.input} ${classes.inputMulti}`,
          },
        }}
        InputLabelProps={{
          style: { color: 'rgba(0, 0, 0, 0.3)' },
        }}
        color="primary"
        fullWidth={true}
        label={placeholder}
        helperText={helperText}
        FormHelperTextProps={{
          style: { color: 'black', fontFamily: 'Assistant' },
        }}
      />
    </Box>
  );
};

const BriefScreen = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const fromWhoRef = useRef(null);
  const forWhoRef = useRef(null);
  const businessNameRef = useRef(null);
  const messageRef = useRef(null);
  const { type, fromWho, forWho, businessName, message } = props;

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new CampaignFlowEventBriefEntry());
  }, []);

  const title = t('campaigns_brief_title');

  const isBusiness = type === 'business';

  const onAnyTextFieldChanged = () => {
    const values = {
      fromWho: fromWhoRef.current?.value,
      forWho: forWhoRef.current?.value,
      businessName: businessNameRef.current?.value,
      message: messageRef.current?.value,
    };
    props.updateBrief(values);
  };

  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box height="50%" p={4}>
        <Typography variant="h5">
          <Box fontWeight="bold" color="text.primary" textAlign="center" mb={2}>
            {title}
          </Box>
        </Typography>
        <div style={{ display: isBusiness ? 'none' : 'block' }}>
          <FromWhoName
            t={t}
            default={fromWho}
            onChange={onAnyTextFieldChanged}
            inputRef={fromWhoRef}
          />
          <ForWhoName
            t={t}
            default={forWho}
            onChange={onAnyTextFieldChanged}
            inputRef={forWhoRef}
          />
        </div>
        <div style={{ display: !isBusiness ? 'none' : 'block' }}>
          <ContentBusinessName
            t={t}
            default={businessName}
            onChange={onAnyTextFieldChanged}
            inputRef={businessNameRef}
          />
        </div>
        <ContentMessage
          t={t}
          default={message}
          onChange={onAnyTextFieldChanged}
          inputRef={messageRef}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ campaign }) => {
  return {
    type: campaign.type,
    fromWho: campaign.fromWho,
    forWho: campaign.forWho,
    businessName: campaign.businessName,
    message: campaign.message,
  };
};

export default connect(mapStateToProps, { updateBrief })(
  withBookingStage(BriefScreen)
);
