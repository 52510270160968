import React, { useState, useEffect } from 'react';
import styles from './VLProgressLoader.module.scss';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const VLProgressLoader = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <div style={{color:props.color ? props.color : 'white' }} className={`${i18n.language === 'he' || i18n.language==='global' || !i18n.language ? styles.heb : ''} ${styles.bookingLoading} ${styles.loading}`}>
            {/* <Spinner animation="border" role="status">
                <span className="sr-only">{t('booking_loading_loading')}</span>
            </Spinner> */}
            <CircularProgressbar value={props.percentage} text={``} strokeWidth={10} styles={{trail:{stroke:'rgb(142,114,209)'},path:{stroke:'white', strokeLinecap:'white'}}} />
            {/* {props.percentage ? (<div className={styles.percentage}>{props.percentage}%</div>) : null} */}
        </div>
    );
};
export default VLProgressLoader;
