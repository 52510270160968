import React, { useState, useEffect } from 'react';
import styles from './EditProfileCharityExplore.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import Footer from '../../../components/NewFooter/Footer';
import EditProfileHeader from '../../EditProfileHeader/EditProfileHeader';
import info from '../../Images/info.svg';
import plus from '../../Images/plus.svg';
import v from '../../Images/v.svg';
import { useTranslation } from 'react-i18next';
import profileManager from '../../../managers/profileManager';
import { centerFlexColumn } from '../../../utils/bootstrapUtils';

const Tabs = (props) => {
  return (
    <div className={styles.tabs}>
      {props.data.map((tab, index) => {
        let isSelected =
          (!props.selectedTab && !index) || tab.name === props.selectedTab
            ? true
            : false;
        return (
          <div
            key={index}
            onClick={() => {
              props.setSelectedTab(tab.name);
            }}
            className={`${styles.tab} ${isSelected ? styles.tabSelected : ''}`}
          >
            {tab.name}
          </div>
        );
      })}
      <div className={styles.spacer}>s</div>
    </div>
  );
};

const Button = (props) => {
  let selected = props.selectedCharity;
  // console.log('selected charity', selected);
  return (
    <div
      className={`${!selected ? 'opacity-2' : ''} ${
        styles.buttonWrapper
      } ${centerFlexColumn()}`}
    >
      <div
        className={`${styles.button} ${centerFlexColumn()}`}
        onClick={props.save}
      >
        {props.text}
      </div>
    </div>
  );
};
const Rows = (props) => {
  // @todo For when we support groups of causes
  // let selectedGroup = props.data.find(
  //   (tab, index) =>
  //     (!props.selectedTab && !index) || tab.name === props.selectedTab
  // );
  // let selectedGroup = props.data;
  // let charities = selectedGroup.charities;

  let charities = props.data;

  return (
    <div className={styles.rows}>
      {charities.map((item, index) => {
        let selected =
          props.selectedCharity && props.selectedCharity.name === item.name;
        let title = item.name;
        let subTitle = 'Charity';

        let url = item.infoURL;
        let imageURL = item.imageURL;
        if (
          props.search &&
          !title.toLowerCase().includes(props.search) &&
          !subTitle.toLowerCase().includes(props.search)
        ) {
          return <span key={index}></span>;
        }

        const openCauseURL = (url) => {
          window.open(url);
        };

        const Image = () => {
          return <img src={imageURL} className={styles.img} />;
        };

        const TextWrapper = () => {
          return (
            <div className={styles.row_textWrapper}>
              <div className={styles.rowTitle}>{title}</div>
              <div className={styles.rowSubTitle}>{subTitle}</div>
              <div className={styles.rowLink}>
                <img
                  src={info}
                  className={styles.info}
                  onClick={() => openCauseURL(url)}
                />{' '}
                {url}
              </div>
            </div>
          );
        };

        const SelectionButton = () => {
          return (
            <div
              className={`${styles.selectButton} ${
                selected ? styles.selectButtonSelected : ''
              }`}
              onClick={() => {
                props.setSelectedCharity(item);
              }}
            >
              <img src={selected ? v : plus} />
            </div>
          );
        };

        return (
          <div className={`${styles.row} d-flex flex-row`} key={index}>
            <Image />
            <TextWrapper />
            <SelectionButton />
          </div>
        );
      })}
    </div>
  );
};

const EditProfileCharityExplore = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('');
  const [selectedCharity, setSelectedCharity] = useState(props.selectedCharity);
  const [search, setSearch] = useState('');

  const [isFetching, setIsFetching] = useState(false);

  const [data, setData] = useState([]);

  const save = async () => {
    if (!selectedCharity) {
      return;
    }

    const updateObj = {
      charity: selectedCharity.toMap(),
    };
    props.setShowLoading(true);
    const attemptSave = await profileManager.updateProfileCause(
      updateObj,
      props.hijackedUserId
    );
    props.setShowLoading(false);

    // console.log('attemptSave = ', attemptSave);
    if (attemptSave.success) {
      props.setSelectedCharity(selectedCharity);
      props.setShowExplore(false);
    } else {
      const errorDetail = attemptSave.error;
      props.onError(null, errorDetail);
    }
  };

  const fetchCauses = async () => {
    if (isFetching === true) {
      return;
    }
    setIsFetching(true);
    props.setShowLoading(true);
    // console.log('fetchResult start');
    const fetchResult = await profileManager.fetchCauses();
    props.setShowLoading(false);
    // console.log(('fetchResult done', fetchResult));
    if (fetchResult.success === true && fetchResult.result) {
      const causes = fetchResult.result;
      setData(causes);
    } else {
      const errorTitle = 'Error';
      const errorDetail = fetchResult.error ?? 'Could not load causes';
      props.onError(errorTitle, errorDetail);
    }
  };

  fetchCauses();

  return (
    <div>
      <div
        className={`${styles.MasterContainer} ${
          i18n.language === 'he' ? styles.heb : ''
        }`}
      >
        <EditProfileHeader
          title={t('editProfileCharity')}
          close={() => {
            props.setShowExplore(false);
          }}
          onChangeSearch={(newSearch) => {
            setSearch(newSearch);
          }}
        />

        <Tabs
          data={data}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
        />
        <div className={styles.wrapper}>
          <Rows
            search={search}
            isHeb={i18n.language === 'he' ? true : false}
            data={data}
            selectedTab={selectedTab}
            selectedCharity={selectedCharity}
            setSelectedCharity={setSelectedCharity}
          />
        </div>
        <div className="fixed-bottom">
          {data.length === 0 ? null : (
            <Button
              save={save}
              text={t('editProfileAccount_save')}
              selectedCharity={selectedCharity}
            />
          )}
        </div>
        <div className={styles.footer}>
          <Footer theme={'white'} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(EditProfileCharityExplore);
