import api, * as RHAPI from './../constants/api';
import userManager from './userManager.js';
import SessionAccess from '../model/sessionAccess';
import Session from '../model/session';
import CloseFan from '../model/closeFan';
import VLCreationPurchase from '../model/vlCreationPurchase';
import VLError from '../model/vlError';
import firebase, { storage } from 'firebase';
import * as utils from '../utils/rhutils.js';
import * as constants from '../constants/rhconstants.js';
import dBManager from './dbManager';

class SocialManager {
  constructor() {}

  userToken() {
    return userManager.tokenId;
  }

  async fetchSocial(userId) {
    // utils.log.debug('fetching user id');
    let userToken = this.userToken();
    if (!userToken) {
      //no token!
      // utils.log.debug('no token...exiting add card');
      return null;
    } else {
      // utils.log.debug("submitting with token ", userToken);
    }

    const headers = await userManager.getRequestHeaders();
    const url = `${api.FETCH_SOCIAL}?user_id=${userId}`;
    // utils.log.debug('fetch social url...', url);

    let response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });
    let json = await response.json();
    // utils.log.debug('fetchSocial response json: ', json);

    /* close circle subscriptions */
    let closeFansJSONArray = json['close_fans'] ? json['close_fans'] : [];
    // utils.log.debug('closeFansJSONArray: ', closeFansJSONArray);
    var subscriptions = [];
    closeFansJSONArray.forEach(function (subjson) {
      let subscrtipion = CloseFan.fromJSON(subjson);
      subscriptions.push(subscrtipion);
    });

    /* created sessions subscriptions */
    let createdSessionsJSONArray = json['sessions'] ? json['sessions'] : [];
    // utils.log.debug('createdSessionsJSONArray: ', createdSessionsJSONArray);
    var createdSessions = [];
    createdSessionsJSONArray.forEach(function (subjson) {
      let createdSession = Session.fromJSON(subjson);
      createdSessions.push(createdSession);
    });

    /* purchased access to sessions */
    let purchasedSessionsJSONArray = json['session_access']
      ? json['session_access']
      : [];
    // utils.log.debug('purchasedSessionsJSONArray: ', purchasedSessionsJSONArray);
    var purchasedSessions = [];
    purchasedSessionsJSONArray.forEach(function (subjson) {
      let purchasedSession = SessionAccess.fromJSON(subjson);
      purchasedSessions.push(purchasedSession);
    });

    /* purchased access to sessions */
    let purchaseCreationsJSONArray = json['creation_purchases']
      ? json['creation_purchases']
      : [];
    // utils.log.debug('purchaseCreationsJSONArray: ', purchaseCreationsJSONArray);
    var purchasedCreations = [];
    purchaseCreationsJSONArray.forEach(function (subjson) {
      let purchasedCreation = VLCreationPurchase.fromJSON(subjson);
      purchasedCreations.push(purchasedCreation);
    });

    //remove original from db
    dBManager.clearCollection(constants.LOCAL_DB_COLLECTION_SUBSCRIPTIONS);
    dBManager.clearCollection(constants.LOCAL_DB_COLLECTION_SESSIONS);
    dBManager.clearCollection(constants.LOCAL_DB_PURCHASED_SESSIONS_ACCESS);
    dBManager.clearCollection(constants.LOCAL_DB_COLLECTION_CREATION_PURCHASES);

    //add new objects to db
    // utils.log.debug('subscriptions fetched for current user...', subscriptions);
    dBManager.addObjects(
      constants.LOCAL_DB_COLLECTION_SUBSCRIPTIONS,
      subscriptions
    );
    dBManager.addObjects(
      constants.LOCAL_DB_COLLECTION_SESSIONS,
      createdSessions
    );
    dBManager.addObjects(
      constants.LOCAL_DB_PURCHASED_SESSIONS_ACCESS,
      purchasedSessions
    );
    dBManager.addObjects(
      constants.LOCAL_DB_COLLECTION_CREATION_PURCHASES,
      purchasedCreations
    );

    let results = {};
    results.subscriptions = subscriptions;
    results.createdSessions = createdSessions;
    results.purchasedSessions = purchasedSessions;
    results.purchasedCreations = purchasedCreations;
    return results;
  }

  isCloseFan(creatorId) {
    if (userManager.currentUserId === creatorId) {
      return true; //we return true when we check against ourselves for simplicity
    }
    let closeFanObject = this.closeFanSubscription(creatorId);
    // utils.log.debug(userManager);
    // utils.log.debug(
    //   `${userManager.currentUserId} is ${
    //     closeFanObject ? 'a valid' : 'not a'
    //   } close fan of ${creatorId}`
    // );
    return closeFanObject != null;
  }

  /// Get the local user's Close Fan object for the creator
  /// - Parameter creatorId: The creator id of the close fan membership
  closeFanSubscription(creatorId) {
    //we return true when we check against ourselves for simplicity
    const currentUserId = userManager.currentUserId;
    if (currentUserId === creatorId) {
      return false;
    }

    //grab the subscriptions colletion
    const subscriptionsCollection = dBManager.getCollection(
      constants.LOCAL_DB_COLLECTION_SUBSCRIPTIONS
    );
    //if no collection, return null

    if (!subscriptionsCollection) {
      return null;
    }
    //fetch subscription object
    var subscription = subscriptionsCollection.findOne({
      creatorId: creatorId,
      userId: currentUserId,
      active: true,
    });
    return subscription;
  }

  async followProfiles(profiles) {
    const url = api.FOLLOW_PROFILES;
    let headers = await userManager.getRequestHeaders();
    let body = JSON.stringify({ profile_ids: profiles });
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });

    let status = response.status;
    // console.log('followProfiles response:', response);

    return {
      success: status === 200 || status === 304,
      error: null,
    };
  }
}

var socialManager = new SocialManager();
export default socialManager;
