import * as utils from "../utils/rhutils";
class VLAffiliateDeal {
  id = ""; //the unique id of the referral deal
  creatorId = ""; //the creator this deal refers to
  lastUpdated = null;
  createdOn = null;
  referralPercent = 0.0; //[0,1]
  active = false;
  total_bookings = 0;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  personalBookingCopyURLForCurrentUser(user) {
    return `https://kre8.tv/ask/${user.profileId}/${
      utils.currentUser().affiliateId
    }?ref=${this.id}`;
  }

  businessBookingCopyURLForCurrentUser(user) {
    return `https://kre8.tv/biz/${user.profileId}/${
      utils.currentUser().affiliateId
    }?ref=${this.id}`;
  }

  saveToLocal() {
    localStorage.setItem(this.id, JSON.stringify(this));
  }

  static local(id) {
    let local = localStorage.getItem(id);
    if (local) {
      let json = JSON.parse(local);
      let user = new VLAffiliateDeal(json);
      return user;
    } else {
      return null;
    }
  }

  static copy(object) {
    var user = new VLAffiliateDeal();
    for (const property in object) {
      // console.log(`${property}: ${object[property]}`);
      let value = object[property];
      user[property] = value;
    }
    return user;
  }

  static fromJSON(json) {
    var deal = new VLAffiliateDeal();
    deal.id = json.id;
    deal.creatorId = json.creator_id;
    deal.lastUpdated = json.last_updated;
    deal.createdOn = json.created_on;
    deal.referralPercent = json.referral_percent;
    deal.active = json.active;
    return deal;
  }
}

export default VLAffiliateDeal;
