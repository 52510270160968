import BookingRequest from '../model/bookingRequest';
import api, * as RHAPI from './../constants/api';
import * as constants from '../constants/rhconstants';
import VLError from '../model/vlError';
import userManager from './userManager';
import VLTransaction from '../model/vlTransaction';
import firebase, { storage } from 'firebase';
import * as utils from '../utils/rhutils.js';

class TransactionManager {
  currentUserId = undefined;
  constructor() {}

  async fetchTransactionFromBookingId(bookingRequestId) {
    // console.log("fetching transction from booking id:", bookingRequestId);
    const url = `${api.FETCH_TRANSACTION}?bookingRequestId=${bookingRequestId}`;
    let response = await fetch(url, {
      method: 'GET',
      headers: userManager.requestHeaders(),
    });
    let json = await response.json();
    // console.log('fetched transction from booking id json:', json);
    let transaction = VLTransaction.fromJSON(json);
    let result = {};
    result.transaction = transaction;
    result.error = VLError.fromJSON(json.error);
    return result; //if it worked
  }

  async fetchTransactionFromId(transactionId) {
    utils.log.debug('fetchTransactionFromId...', transactionId);
    const db = firebase.firestore();
    const itemRef = await db
      .collection(constants.FIREBASE_COLLECTION_TRANSACTIONS)
      .doc(transactionId)
      .get();

    const itemJSON = itemRef.data();

    utils.log.debug('fetchTransactionFromId result:', itemJSON);

    if (itemJSON) {
      let transaction = VLTransaction.fromJSON(itemJSON);
      return transaction;
    } else {
      return null;
    }
  }

  async submitTransaction(transaction) {
    //let's upload the file first

    var params = {};
    params.requester_id = transaction.requesterId;
    params.persona_id = transaction.personaId;
    params.request_id = transaction.requestId;
    params.video_url = transaction.videoURL;
    // params.public = transaction.public === true ? 1 : 0;
    params.public = false;
    params.check_for_charity = 1;

    var body = JSON.stringify(params);

    const url = api.SUBMIT_TRANSACTION;
    let response = await fetch(url, {
      method: 'POST',
      headers: userManager.requestHeaders(),
      body: body,
    });
    let json = await response.json();
    // console.log('transaction submit response json:', json);
    let resultTransaction = VLTransaction.fromJSON(json);
    let result = {};
    result.transaction = resultTransaction;
    result.error = VLError.fromJSON(json.error);
    return result; //if it worked
  }
  async uploadFile(file, transaction, callback) {
    utils.log.debug('upload file..', file);
    utils.log.debug('transaction ..', transaction);
    const storage = firebase.storage();
    const ref = storage.ref();
    const folderName = 'transactions';
    const ts = new Date().getTime() / 1000;
    const userId = userManager.currentUserId;
    var fileName = transaction.fileName();
    var path = `${folderName}/${fileName}`;

    var storageRef = ref.child(path);

    var uploadTask = storageRef.put(file);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // utils.log.debug("Upload is " + progress + "% done");
        callback(null, progress, null);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // utils.log.debug("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // utils.log.debug("Upload is running");
            break;
        }
      },
      function (error) {
        // Handle unsuccessful uploads
        utils.log.debug('🚨error uploading session file -'.error);
        callback(null, null, error);
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          utils.log.debug('✅ File available at', downloadURL);
          callback(downloadURL, 100, null);
        });
      }
    );
  }

  /* Transaction Review */
  async submitTransactionReview(
    transaction,
    rating,
    content,
    tip,
    tipCurrency
  ) {
    //let's upload the file first

    var params = {};
    params.reviewer_id = userManager.currentUserId;
    params.transaction_id = transaction.id;
    params.persona_id = transaction.personaId;
    params.request_id = transaction.requestId;
    params.rating = rating;

    if (content) {
      params.content = content;
    }

    if (tip && tipCurrency) {
      params.tip = tip;
      params.currency = tipCurrency;
    }

    var body = JSON.stringify(params);
    utils.log.debug('submitting transaction review with params...', body);

    const headers = await userManager.requestHeaders();
    const url = api.SUBMIT_TRANSACTION_REVIEW;
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });

    let json = await response.json();
    // console.log('transaction review submit response json:', json);
    let result = {};
    result.error = VLError.fromJSON(json.error);
    result.success = result.error === null && response.status === 200;
    return result; //if it worked
  }

  async transactionVideoViewed(transaction) {
    //let's upload the file first

    var params = {};
    params.user_id = userManager.currentUserId;
    params.transaction_id = transaction.id;
    params.user_data = utils.fullCurrentUserParams();

    var body = JSON.stringify(params);
    utils.log.debug('submitting transaction video viewed...', body);

    const headers = await userManager.requestHeaders();
    const url = api.TRANSACTION_VIDEO_VIEWED;
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    // console.log('transaction review submit response json:', json);
    return response.status === 200;
  }
}

var transactionManager = new TransactionManager();
export default transactionManager;
