import styles from './PhoneEmailInputTextField.module.scss';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { centerFlexColumn, lclDirection } from '../../utils/bootstrapUtils';
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../utils/rhutils';
import {
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { countryData, countryDetails } from './countries';
import { isNumericLiteral } from 'typescript';
import * as utils from '../../utils/rhutils';

var emojiFlags = require('emoji-flags');

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0),
    },
  },
  textField: {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    color: 'black',
    letterSpacing: '-0.165px',
  },
  select: {
    '& ul': {
      backgroundColor: '#ffffff',
      fontSize: '18px',
    },
  },
}));

interface PhoneInputField {
  onPhoneRecognized: any;
  onEmailRecognized: any;
  onInvalidRecognized: any;
  onEnterPressed?: any;
  childvalue?: any;
  value?: any;
}

const countries = countryData;
const CountrySelector = (props: any) => {
  const { selectedCountryCode, setSelectedCountryCode } = props;
  // console.log('selectedCountryCode', selectedCountryCode);
  // console.log('countries', countries);

  const classes = useStyles();

  const country = countryDetails(selectedCountryCode);
  const countryNumber = country.dial_code;
  const countryName = country.name;
  const emojiFlag = emojiFlags.countryCode(selectedCountryCode);
  // console.log('country', country);
  const countryText = `${emojiFlag.emoji} ${countryNumber}`;

  const handleChanges = (event?: any, child?: any) => {
    if (event.target.value) setSelectedCountryCode(event.target.value);
  };

  return (
    <Select
      onChange={handleChanges}
      displayEmpty
      value={props.countryText}
      MenuProps={{ classes: { paper: classes.select } }}
    >
      <MenuItem value={props.country}>{`${countryText}`}</MenuItem>
      {countries.map((data) => {
        return (
          <MenuItem value={data.code}>{`${data.name} ${
            emojiFlags.countryCode(data.code)?.emoji
          } ${data.dial_code}`}</MenuItem>
        );
      })}
    </Select>
  );
};

export default function PhoneEmailInputTextField(props: PhoneInputField) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [innerValue, setInnerValue] = useState('');
  /**
   * This is the textfield country code (2 letter) that we can get the country code prefix from
   */
  const [selectedCountryCode, setSelectedCountryCode] = useState('US');

  const isEmailValid = validateEmail(innerValue);

  const country = countryDetails(selectedCountryCode);
  let countryNumber = country.dial_code;

  let validPhoneValue = '';
  let isPhoneValid = false;
  if (isValidPhoneNumber(innerValue)) {
    validPhoneValue = innerValue;
    validPhoneValue = parsePhoneNumber(innerValue)?.number ?? validPhoneValue;
    isPhoneValid = true;
  } else if (isValidPhoneNumber(countryNumber + innerValue)) {
    validPhoneValue =
      parsePhoneNumber(countryNumber + innerValue)?.number ?? validPhoneValue;
    isPhoneValid = true;
  } else if (isValidPhoneNumber('+' + countryNumber + innerValue)) {
    validPhoneValue =
      parsePhoneNumber('+' + countryNumber + innerValue)?.number ??
      validPhoneValue;
    isPhoneValid = true;
  } else {
    //nop - false
  }

  const canPossibleBeAPhone =
    utils.isNumeric(innerValue) || utils.isNumeric(countryNumber + innerValue);
  const isValid = isPhoneValid || isEmailValid;
  // const isPhoneNumber = isPhoneValid;
  // const isEmail = isEmailValid;

  const showCountrySelector =
    isPhoneValid || (canPossibleBeAPhone && innerValue.length > 3);
  if (isPhoneValid) {
    const phone = parsePhoneNumber(validPhoneValue);
    if (phone?.country) {
      countryNumber = phone.countryCallingCode;
      // console.log(
      //   'countryNumber',
      //   countryNumber,
      //   innerValue,
      //   isValidPhoneNumber(innerValue)
      // );
      // setSelectedCountryCode(phone.country);
      // setInnerValue(phone.nationalNumber);
    }
    props.onPhoneRecognized(validPhoneValue);
  } else if (isEmailValid) {
    props.onEmailRecognized(innerValue);
  } else {
    props.onInvalidRecognized(true, innerValue);
  }

  // console.log('rth: value', innerValue);

  const error = !isValid && innerValue.length > 0;
  const helperLabel = '';

  const handleTextFieldChange = (event: any) => {
    const value = event.target.value;
    setInnerValue(value);
  };

  const onEnterPressed = () => {
    if (isPhoneValid || isEmailValid) {
      props.onEnterPressed();
    }
  };

  return (
    <FormControl className="w-100">
      <form
        dir="ltr"
        className={`${centerFlexColumn()} ${styles.kr_input}`}
        noValidate
        autoComplete="off"
      >
        <div className="d-flex flex-row w-100">
          {showCountrySelector ? (
            <CountrySelector
              setSelectedCountryCode={setSelectedCountryCode}
              selectedCountryCode={selectedCountryCode}
            />
          ) : null}
          <TextField
            autoFocus
            dir={showCountrySelector === false ? lclDirection() : 'ltr'}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                onEnterPressed();
              }
            }}
            InputProps={{
              classes: {
                input: classes.textField,
              },
            }}
            className={`${classes.textField} w-100 mr-2 ml-2`}
            error={error}
            value={innerValue}
            onChange={handleTextFieldChange}
            id="standard-error"
            label={helperLabel}
            placeholder={t('enter_phone_or_email_placeholder')}
          />
        </div>
      </form>
    </FormControl>
  );
}
