class Challenge {
  id = null;
  name = ''; //the unique id of the subscription
  data = null; //the json file

  static fromJSON(json) {
    var challenge = new Challenge();
    challenge.id = json.id;
    challenge.name = json.name;
    try {
      challenge.data = JSON.parse(json.data);
      return challenge;
    } catch (error) {
      console.error('error parsing challenge: ', error);
      return null;
    }
  }

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

export default Challenge;
