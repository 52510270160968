import React, { useState, useEffect } from 'react';
import styles from './AddLinkEmpty.module.scss';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import empty_main_image from '../Images/empty_main_image.svg'
import plus from '../Images/plus.svg'

const AddLinkEmpty = (props) => {
  const { t, i18n } = useTranslation();
 
  return (
    <div className={`${i18n.language === 'he' ? styles.heb : ''}`}>
        <div className={styles.text}>
            {t('editProfile_empty_text')}
        </div>
        <div className={styles.box}>
            <div className={styles.title}>{t('editProfile_empty_title')}</div>
            <img className={styles.img} src={empty_main_image} onClick={props.openAddLink} />
            <div className={styles.addLink} onClick={props.openAddLink}>
                <img src={plus} className={styles.plus} />
                <div className={styles.addText}>{t('editProfile_empty_add_new')}</div>
            </div>
        </div>
    </div>
  );
};

export default withRouter(AddLinkEmpty);
