import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel } from 'react-bootstrap';
import { OrderBlockWidget } from '../../Widgets/OrderBlockWidget';
import { useTranslation } from 'react-i18next';
import { toPriceString } from '../../../../../utils/priceUtils';
import { parse } from '../../../../../utils/vlStrings';
import paymentManager2 from '../../../../../managers/v2/paymentManager2';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    textAlign: 'center',
    letterSpacing: '-0.41px',
  },
  detail: {
    color: '#C8CACF',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    textAlign: 'center',
  },
  detailContainer: {
    background: '#3A2D5C',
    borderRadius: '24px',
    height: '135px',
  },
  detailContainerTitle: {
    color: '#DBCAFE',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '-0.41px',
  },
  detailContainerDetail: {
    color: '#888888',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  priceDetail: {
    color: '#DBCAFE',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    letterSpacing: '-0.41px',
  },
  imageFirst: {
    overflow: 'hidden',
    borderRadius: '20px',
    height: '40px',
    width: '40px',
  },
  imageSecond: {
    marginLeft: '-10px',
    border: '4px solid #3A2D5C',
    overflow: 'hidden',
    borderRadius: '24px',
    height: '48px',
    width: '48px',
  },
}));

const DetailContainer = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, creator, price, currency, type } = props;
  const title =
    type === 'personal' ? t('order_type_personal') : t('order_type_business');
  const detail = parse(
    parse(t('two_names'), user.fullName(), creator.fullName())
  );

  const priceString = toPriceString(currency, price, true, false);

  const imageFirstURL = user.profilePictureURL;
  const imageSecondURL = creator.profilePictureURL;
  return (
    <Box mt={3} className={classes.detailContainer} p={2.5} m={1}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        justifyContent="space-between"
      >
        <Box>
          <Box className={classes.detailContainerTitle}>{title}</Box>
          <Box mt={1} className={classes.detailContainerDetail}>
            {detail}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box
            id="photos"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box id="image-first" className={classes.imageFirst}>
              <img
                alt="profile"
                src={imageFirstURL}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
            <Box id="image-second" className={classes.imageSecond}>
              <img
                alt="profile"
                src={imageSecondURL}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Box>
          <Box id="price" className={classes.priceDetail}>
            {priceString}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const OrderCheckoutDetailBlock = (props) => {
  const {
    user,
    creator,
    price,
    currency,
    hasValidPaymentMethods,
    type,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const title = t('checkout');
  const detail = t('order_checkout_detail');

  return (
    <OrderBlockWidget noSpacing={true}>
      <Box className={classes.root} pt={2} pb={2} pl={1} pr={1}>
        <Box width="100%" display="flex" flexDirection="column" mt={2}>
          <Box className={classes.title}>{title}</Box>
          <Box
            mt={2}
            className={classes.detail}
            display={hasValidPaymentMethods ? 'none' : 'block'}
          >
            {detail}
          </Box>
          <DetailContainer
            type={type}
            user={user}
            creator={creator}
            price={price}
            currency={currency}
          />
        </Box>
      </Box>
    </OrderBlockWidget>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.ordering.type,
    isConnected: state.user.isConnected,
    user: state.user.details,
    creator: state.ordering.creator,
    price: state.ordering.total_price,
    currency: state.ordering.currency,
    hasValidPaymentMethods: paymentManager2.existingPaymentMethods().length > 0,
  };
};

export default connect(mapStateToProps, {})(OrderCheckoutDetailBlock);
