import React, { useState, useEffect, useRef } from 'react';
import styles from './EditProfileRequests.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditProfileHeader from '../EditProfileHeader/EditProfileHeader';
import { Form, Spinner } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import profileManager from '../../managers/profileManager';
import { centerFlexColumn } from '../../utils/bootstrapUtils';
import { cleanInputPrice, symbolFromCode } from '../../utils/priceUtils';
import { Box, InputAdornment, Switch, TextField } from '@material-ui/core';

/* checkbox component that contains both text and the custom switch component */
const CheckBox = (props) => {
  return (
    <div className={styles.checkboxRow}>
      <div className={styles.CheckboxText}>{props.text}</div>
      <span onClick={props.onChange}>
        <Switch
          color="primary"
          checked={props.value}
          onChange={() => {}}
          name="main-checkbox"
          inputProps={{ 'aria-label': 'main product checkbox' }}
        />
      </span>
    </div>
  );
};
/* the inputs area */

const Subscription = (props) => {
  return (
    <div>
      <div className={styles.sub_title}>{props.title}</div>
      <TextareaAutosize
        className={styles.textarea}
        maxRows={5}
        type="text"
        placeholder={props.placeholderText}
        value={props.welcomeMessage}
        onChange={(e) => {
          props.setWelcomeMessage(e.target.value);
        }}
      />
      <TextareaAutosize
        className={styles.textarea}
        maxRows={5}
        type="text"
        placeholder={props.placeholderSecond}
        value={props.thankYouMessage}
        onChange={(e) => {
          props.setThankYouMessage(e.target.value);
        }}
      />
    </div>
  );
};
/* slider area */

const Slider = (props) => {
  const { price, currencySymbol, setPrice } = props;
  const stepSize = Number(props.isBusiness === true ? 100 : 1);

  const handleTextFieldPriceChange = (e) => {
    const val = e.target.value;
    const cleanPrice = cleanInputPrice(val);
    setPrice(cleanPrice);
  };

  return (
    <div className={styles.slider}>
      <div className={styles.sliderText}>{props.text}</div>
      <Box color="black" m={2}>
        <TextField
          onChange={handleTextFieldPriceChange}
          color="primary"
          type="number"
          variant="filled"
          label="Amount"
          value={price}
          InputProps={{
            startAdornment: (
              <InputAdornment disableTypography position="start">
                {currencySymbol}
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <div className={styles.buttons}>
        <div
          className={styles.button}
          onClick={() => {
            props.price > 0 &&
              props.setPrice(Number(Number(props.price) - stepSize));
          }}
        >
          -
        </div>
        <div
          className={styles.button}
          onClick={() => {
            props.setPrice(Number(Number(props.price) + stepSize));
          }}
        >
          +
        </div>
      </div>
      {/* <input 
            type="range" 
            style={{
                background
            }}
            className={styles.sliderInput}
            min="0" max="1000" 
            value={props.price} 
            onChange={props.setPrice}
            step="1"/> */}
    </div>
  );
};

const Input = (props) => {
  return (
    <div className={styles.Input}>
      <div
        className={styles.input_checkbox}
        onClick={() => {
          props.changeCheckbox(props.data.id);
        }}
      >
        {props.data.checked ? (
          <div className={styles.input_checkbox_checked}></div>
        ) : null}
      </div>
      <div className={styles.input_wrapper}>
        <div
          className={styles.input_wrapper_text}
          onClick={() => {
            props.changeCheckbox(props.data.id);
          }}
        >
          {props.data.text}
        </div>
        {props.data.checked ? (
          <input
            type="text"
            value={props.data.inputValue}
            onChange={(e) => {
              props.changeText(props.data.id, e.target.value);
            }}
            className={styles.inputElement}
            placeholder={props.placeholder}
          />
        ) : null}
      </div>
    </div>
  );
};

const PriceSelectorBox = (props) => {
  const { price, user, t, i18n, setPrice } = props;

  return (
    <Slider
      id="price-selector-box"
      {...props}
      isHeb={`${i18n.language === 'he' ? true : false}`}
      text={t('editProfileRequests_price')}
      price={price}
      currencySymbol={symbolFromCode(user.currency)}
      setPrice={(newPrice) => {
        setPrice(Number(newPrice));
      }}
    />
  );
};

const SubscriptionSection = (props) => {
  const { t, i18n } = useTranslation();
  const {
    welcomeMessageInputEl,
    thankyouMessageInputEl,
    thankYouMessage,
    welcomeMessage,
  } = props;

  return props.kind === 'subscription' ? (
    <Subscription
      placeholderText={t('editProfileRequests_text')}
      placeholderSecond={t('editProfileRequests_thanks_sub')}
      thankYouMessage={thankYouMessage}
      welcomeMessage={welcomeMessage}
      welcomeMessageInputEl={welcomeMessageInputEl}
      thankyouMessageInputEl={thankyouMessageInputEl}
      title={t('edit_profile_sub_title')}
    />
  ) : null;
};

/**
 * A component displaying custom product descriptions (birthdays, motivation, etc) allowing the user to customize their offerings even more
 * @param {*} props
 */
const Forum = (props) => {
  return (
    <div>
      <div className={styles.forumTitle}>
        Select occasion: <span className={styles.forumTitleMini}>(max 6)</span>
      </div>
      {props.inputs.map((inputData) => {
        return (
          <Input
            placeholder={props.placeholder}
            key={inputData.id}
            data={inputData}
            changeCheckbox={props.changeCheckbox}
            changeText={props.changeText}
          />
        );
      })}
    </div>
  );
};

/* All the edit profile component, it's for business requests private requests and subscription */
const EditProfileRequests = (props) => {
  const { t, i18n } = useTranslation();
  const user = props.User;
  const [welcomeMessage, setWelcomeMessage] = useState(props.welcomeMessage);
  const [thankYouMessage, setThankYouMessage] = useState(props.thankYouMessage);

  //Avaiilable for personalize booking requests
  let availableForBooking = user.availableForBooking;
  let pricePersonal = user.price ?? 0;

  //Avaiilable for busiesss booking requests
  let availableForBookingBusiness = user.availableForBookingBusiness;
  let priceBusiness = user.priceBusiness ? user.priceBusiness : 0;

  let availableForSubscription = user.hasActiveCloseCircleSubscription();
  let priceSubscription = user.closeCirclePriceValue();

  //the main product checkbox (personalized request, business requests, subscription, etc)
  const [mainCheckBox, setMainCheckbox] = useState(
    props.kind === 'personalRequests'
      ? availableForBooking
      : props.kind === 'businessRequests'
      ? availableForBookingBusiness
      : availableForSubscription
  );
  //Anonymous purchasers (for requests only atm)
  const [anonymousCheckBox, setAnonymousCheckBox] = useState(false);

  // The price of the main product
  const [price, setPrice] = useState(
    props.kind === 'personalRequests'
      ? pricePersonal
      : props.kind === 'businessRequests'
      ? priceBusiness
      : priceSubscription
  );

  //list of all the additional custom inputs
  const [inputs, setInputs] = useState(
    props.kind === 'personalRequests'
      ? [
          {
            id: 'question',
            checked: true,
            inputValue: '',
            text: t('askQuestion'),
          },
          {
            id: 'birthday',
            inputValue: '',
            checked: true,
            text: t('birthday'),
          },
          {
            id: 'motivation',
            inputValue: '',
            checked: true,
            text: t('motivation'),
          },
          { id: 'event', inputValue: '', checked: true, text: t('Event') },
          {
            id: 'getAdvice',
            inputValue: '',
            checked: false,
            text: t('getAdvice'),
          },
          { id: 'other', inputValue: '', checked: false, text: t('other') },
          {
            id: 'anniversary',
            inputValue: '',
            checked: true,
            text: t('anniversary'),
          },
        ]
      : [
          {
            id: 'promotional',
            checked: true,
            inputValue: 'aa',
            text: t('promotional'),
          },
          {
            id: 'testimonial',
            inputValue: '',
            checked: true,
            text: t('testimonial'),
          },
          {
            id: 'collaboration',
            inputValue: '',
            checked: true,
            text: t('collaboration'),
          },
          {
            id: 'productPlacement',
            inputValue: '',
            checked: true,
            text: t('productPlacement'),
          },
          {
            id: 'getAdvice',
            inputValue: '',
            checked: false,
            text: t('getAdvice'),
          },
          { id: 'other', inputValue: '', checked: false, text: t('other') },
        ]
  );

  /**
   * Checks if should allow saving (if there are any changes effectively)
   */
  const allowSave = () => {
    if (!user || !user.id) {
      return false;
    } else {
      // console.log('user = ', user);

      let diff = false;
      switch (props.kind) {
        case 'personalRequests':
          diff = user.price !== price;
          diff = diff || user.availableForBooking !== mainCheckBox;
          return diff;
        case 'businessRequests':
          diff = user.priceBusiness !== price;
          diff = diff || user.availableForBookingBusiness !== mainCheckBox;
          return diff;
        case 'subscription':
          diff = diff || user.availableForBookingBusiness !== mainCheckBox;
          //@todo implement
          return true;
        default:
          return diff;
      }
    }
  };

  /**
   * Save function that saves the current screens data to the user's profile
   */
  const save = async () => {
    // console.log('Business/personal requests checked', mainCheckBox);
    // console.log('price', price);
    // console.log('anonymousCheckBox', anonymousCheckBox);
    // console.log('text', welcomeMessage);
    // console.log('Thanks', thankYouMessage);

    const customWelcomeMessage = welcomeMessage;
    const customSuccessMessage = thankYouMessage;

    // let allInputsValues = inputs.filter((item) => {
    //   return item.checked;
    // });
    // console.log('allInputsData', allInputsValues);

    props.setShowLoading(true);
    let attemptSave;
    let updateObj = {};
    switch (props.kind) {
      case 'personalRequests':
        updateObj = {
          available_for_booking: mainCheckBox,
          price: price,
        };
        attemptSave = await profileManager.updateProfilePersonalRequests(
          updateObj,
          props.hijackedUserId
        );
        break;
      case 'businessRequests':
        updateObj = {
          available_for_booking_business: mainCheckBox,
          price_business: price,
        };
        attemptSave = await profileManager.updateProfileBusinessRequests(
          updateObj,
          props.hijackedUserId
        );
        break;
      case 'subscription':
        let closeFanPlan = {
          active: mainCheckBox,
          price: price,
          custom_welcome_message: customWelcomeMessage,
          custom_success_message: customSuccessMessage,
        };
        updateObj = {
          close_fan_plan: closeFanPlan,
        };
        attemptSave = await profileManager.updateProfileSubscription(
          updateObj,
          props.hijackedUserId
        );
        break;
      default:
        return;
    }

    props.setShowLoading(false);
    // console.log('attemptSave = ', attemptSave);
    if (attemptSave.success) {
      props.onEditSaveSuccess();
    } else {
      //show error
      window.alert(
        attemptSave.error && attemptSave.error.localizedTitle()
          ? attemptSave.error && attemptSave.error.localizedTitle()
          : 'error'
      );
      const errorTitle = '';
      const errorDetail = '';
      props.onError(errorTitle, errorDetail);
    }
  };

  const checkboxChanged = (kind) => {
    if (kind === 'main') {
      setMainCheckbox(!mainCheckBox);
    } else if (kind === 'anonymous') {
      setAnonymousCheckBox(!anonymousCheckBox);
    }
  };

  const changeText = (id, newText) => {
    //when changing the text
    let newInputs = inputs.map((input) => {
      if (input.id === id) {
        input.inputValue = newText;
      }

      return input;
    });

    setInputs(newInputs);
  };

  const changeCheckbox = (id) => {
    //when changing the checkbox
    let newInputs = inputs.map((input) => {
      if (input.id === id) {
        input.checked = !input.checked;
      }

      return input;
    });

    setInputs(newInputs);
  };

  const getTitle = () => {
    if (props.kind === 'personalRequests') {
      return t('editProfile_personal_equests');
    } else if (props.kind === 'businessRequests') {
      return t('editProfile_business_requests');
    } else if (props.kind === 'subscription') {
      return t('editProfile_subscription');
    }
  };

  const AnonymousRequestsToggle = () => {
    return props.kind === 'personalRequests' ? (
      <CheckBox
        text={t('editProfileRequests_anonymous')}
        onChange={() => {
          checkboxChanged('anonymous');
        }}
        value={anonymousCheckBox}
      />
    ) : null;
  };

  const RequestOcassionDescriptions = () => {
    return mainCheckBox && props.kind !== 'subscription' ? (
      <Forum
        placeholder={t('editProfileRequests_addDescription')}
        inputs={inputs}
        changeCheckbox={changeCheckbox}
        changeText={changeText}
      />
    ) : null;
  };

  const MainProductToggle = () => {
    const getCheckBoxText = () => {
      if (props.kind === 'personalRequests') {
        return t('editProfile_personal_equests');
      } else if (props.kind === 'businessRequests') {
        return t('editProfile_business_requests');
      } else if (props.kind === 'subscription') {
        return t('edit_profile_active');
      }
    };
    return (
      <div id="main-product-toggle-row">
        <CheckBox
          text={getCheckBoxText()}
          onChange={() => {
            checkboxChanged('main');
          }}
          value={mainCheckBox}
        />
      </div>
    );
  };

  const { closeFanPlan } = user;

  const customWelcomeMessage = closeFanPlan
    ? closeFanPlan.customWelcomeMessage
    : null;
  const customSuccessMessage = closeFanPlan
    ? closeFanPlan.customSuccessMessage
    : null;

  return (
    <div className={` ${i18n.language === 'he' ? styles.heb : ''}`}>
      <EditProfileHeader
        title={getTitle()}
        save={save}
        close={props.close}
        allowSave={allowSave()}
      />
      <div className={`${centerFlexColumn()}`}>
        <div className={`${styles.wrapper}`}>
          <MainProductToggle />
          {/* <AnonymousRequestsToggle /> */}
          <PriceSelectorBox
            price={price}
            setPrice={setPrice}
            isBusiness={props.kind === 'businessRequests'}
            user={user}
            {...props}
            t={t}
            i18n={i18n}
          />
          {/* <RequestOcassionDescriptions /> */}
          <SubscriptionSection
            {...props}
            setThankYouMessage={setThankYouMessage}
            setWelcomeMessage={setWelcomeMessage}
            welcomeMessage={customWelcomeMessage}
            thankYouMessage={customSuccessMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(EditProfileRequests);
