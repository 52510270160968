import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Creator.module.scss';
import { Image } from '../../../image/image.component';
import { ICreator } from '../../../../model/interfaces';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import BadgeView from './BadgeView';

type CreatorProps = {
  index: number;
  creator: ICreator;
  discover?: boolean;
  ignoreClick?: boolean;
  disableSpacing?: boolean;
};

const Creator: React.FC<CreatorProps> = React.memo(
  ({ index, creator, discover, ignoreClick, disableSpacing }) => {
    const { i18n } = useTranslation();
    const history = useHistory();

    return (
      <div
        className={`${styles.creatorContainer} ${
          discover ? styles.creatorContainerDiscover : ''
        }`}
        style={{
          marginLeft: discover
            ? 0
            : i18n.language === 'he'
            ? 0
            : index === 0
            ? disableSpacing
              ? 0
              : 20
            : 0,
        }}
      >
        {creator.image_url && (
          <a
            href={ignoreClick ? undefined : creator.deep_link_url}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.imageContainer}>
              <Image src={creator.image_url} />
              <Box
                display="flex"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                width="100%"
                mb={1}
                style={{ zIndex: 0, position: 'absolute', bottom: '0' }}
              >
                <BadgeView
                  followersInstagram={creator.followers_instagram}
                  followersYoutube={creator.followers_youtube}
                  followersTiktok={creator.followers_tiktok}
                />
              </Box>
            </div>
          </a>
        )}
        <Typography variant="h3">
          <span className={styles.creatorName}>{creator.title}</span>
        </Typography>

        <Typography variant="subtitle1">
          <span className={styles.creatorTitle}>{creator.detail}</span>
        </Typography>
      </div>
    );
  }
);

export default Creator;
