import * as dateUtils from '../utils/dateUtils';
import VLUser from './vlUser';
import memoize from 'lodash/memoize';
import { baseAppURL } from '../utils/rhutils';

class CloseFan {
  id = ''; //the unique id of the subscription
  userId = ''; //the user who purchased
  creatorId = ''; //the creator to subscribe to
  lastUpdated = null;
  createdOn = null;

  //Pricing
  price = 0.0;
  currency = 'USD';
  active = true;

  user = null;
  creator = null;

  //v2
  // - billingCycle
  billingCycle = 'monthly';

  // - benefits (5 total atm)
  benefit_free_personalized_video = false;
  benefit_zoom_and_live_events = false;
  benefit_access_to_member_only_content = false;
  benefit_giveaways_enabled = false;
  benefit_discounts_and_more = false;

  member_id = null; //number

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  getCreator() {
    if (!this.creator && VLUser.local(this.creatorId)) {
      this.creator = VLUser.local(this.creatorId);
      return this.creator;
    } else {
      return null;
    }
  }

  membershipShareURL() {
    return `${baseAppURL()}/membership/${this.id}`;
  }
  accountScreenTitle() {
    return this.creatorId;
  }
  lastUpdatedDateString() {
    let date = new Date(this.lastUpdated);
    return dateUtils.toLocalDateTimeString(date);
  }
  priceString(full = false) {
    let price = this.price;
    let currency = this.currency ? this.currency : 'ILS';

    // console.log("currency =", currency);
    // console.log("price =", price);
    var settings = {
      style: 'currency',
      currency: currency,
    };
    var formatter = new Intl.NumberFormat('en-US', settings);

    // console.log("price...", price);
    let priceStr = formatter.format(price).replace(/\D00$/, '');
    // console.log("priceStr...", priceStr);
    // console.log("str...", str);
    return `${priceStr}${full === true ? '/month' : ''}`;
  }

  toAnalyticsMap() {
    var map = {};
    map['id'] = this.id;
    map['creator_id'] = this.creatorId;
    map['user_id'] = this.userId;
    map['price'] = this.price;
    map['currency'] = this.currency;
    return map;
  }
  toMap() {
    var map = {};
    map['creator_id'] = this.creatorId;
    map['user_id'] = this.userId;
    map['price'] = this.price;
    map['currency'] = this.currency;

    map['active'] = Number(this.active);
    map['price'] = this.price;
    map['currency'] = this.currency;

    map['custom_welcome_message'] = this.customWelcomeMessage;
    map['custom_success_message'] = this.customSuccessMessage;

    //benefits
    map['benefit_free_personalized_video'] = this.benefit_free_personalized_video;
    map['benefit_zoom_and_live_events'] = this.benefit_zoom_and_live_events;
    map['benefit_access_to_member_only_content'] = this.benefit_access_to_member_only_content;
    map['benefit_giveaways_enabled'] = this.benefit_giveaways_enabled;
    map['benefit_discounts_and_more'] = this.benefit_discounts_and_more;

    return map;
  }

  static fromJSON = memoize((json) => {
    var sub = new CloseFan();

    //@todo - need to fix how we serialize these
    try {
      if (
        !json.id ||
        !json.user_id ||
        !json.creator_id ||
        !json.last_updated ||
        !json.created_on ||
        !json.price ||
        !json.currency ||
        !json.active
      ) {
        return null;
      }

      sub.id = json.id; //subscription id
      sub.userId = json.user_id; //subscriber id
      sub.creatorId = json.creator_id; //creator id

      sub.lastUpdated = json.last_updated; //last updated
      sub.createdOn = json.created_on; //when created
      sub.price = Number(json.price); //price paid
      sub.currency = json.currency; //currency paid
      sub.active = Boolean(json.active); //active or not
      sub.member_id = json.member_id ? String(json.member_id) : null;
      sub.benefit_free_personalized_video = Boolean(json.benefit_free_personalized_video);
    } catch (error) {
      return null;
    }

    //benefits
    sub.benefit_free_personalized_video = json.benefit_free_personalized_video
      ? Boolean(json.benefit_free_personalized_video)
      : false;
    sub.benefit_zoom_and_live_events = json.benefit_zoom_and_live_events
      ? Boolean(json.benefit_zoom_and_live_events)
      : false;
    sub.benefit_access_to_member_only_content = json.benefit_access_to_member_only_content
      ? Boolean(json.benefit_access_to_member_only_content)
      : false;
    sub.benefit_access_to_giveaways = json.benefit_access_to_giveaways
      ? Boolean(json.benefit_access_to_giveaways)
      : false;
    sub.benefit_discounts_and_more = json.benefit_discounts_and_more
      ? Boolean(json.benefit_discounts_and_more)
      : false;

    return sub;
  });
}

export default CloseFan;
