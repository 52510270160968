import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import VLBigButton from '../../../../../Reusables/Buttons/VLBigButton';
import { onConfirmBookingDetailsPressed } from '../../../../../store/reducers/ordering';
import { AuthModal } from '../../../../../signup/AuthModal';

const useStyles = makeStyles((theme) => ({
  root: {},
  detail: {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    color: theme.palette.error.main,
  },
}));

const OrderConfirmDetailsBlock = (props) => {
  const {
    isConnected,
    hasTriedConfirmDetailsOnce,
    onConfirmBookingDetailsPressed,
    canConfirmBooking,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [showAuth, setShowAuth] = useState(false);
  const detail =
    !canConfirmBooking && hasTriedConfirmDetailsOnce === true
      ? t('check_error_on_booking')
      : '';

  const onConfirmPressed = () => {
    //make sure user is authed
    if (!isConnected) {
      setShowAuth(true);
    } else {
      onConfirmBookingDetailsPressed();
    }
  };

  return (
    <Box className={classes.root} textAlign="center" pl={2} pr={2} pt={2}>
      <VLBigButton
        variant="contained"
        color="primary"
        fullWidth={true}
        onClick={() => onConfirmPressed()}
      >
        {t('confirm_booking')}
      </VLBigButton>
      <Box mt={2} className={classes.detail}>
        {detail}
      </Box>
      <AuthModal
        show={showAuth}
        onClose={() => setShowAuth(false)}
        onAuthSuccess={() => {
          setShowAuth(false);
          onConfirmBookingDetailsPressed();
        }}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    user: state.user.details,
    canConfirmBooking: state.ordering.canConfirmBooking,
    hasTriedConfirmDetailsOnce: state.ordering.hasTriedConfirmDetailsOnce,
  };
};

export default connect(mapStateToProps, { onConfirmBookingDetailsPressed })(
  OrderConfirmDetailsBlock
);
