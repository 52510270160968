import {
  Box,
  Dialog,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { lclDirection } from '../../../../utils/vlStrings';
import { Image } from '../../../image/image.component';
import HomeVideoWidget from '../../Homepage/Videos/HomeVideoWidget';
import CloseIcon from '@material-ui/icons/Close';
import VisibilitySensor from 'react-visibility-sensor';
import { analytics, AnalyticsEvent } from '../../../../utils/analytics';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogPaper: {
    maxHeight: '700px',
    borderRadius: '24px',
    overflow: 'hidden',
    width: '100%',
    background: '#3A2D5C',
    padding: 0,
    margin: 0,
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0px',
      maxHeight: '100%',
      height: '100%',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
    scrollSnapPointsY: 'repeat(3rem)',
    scrollSnapType: 'y mandatory',
    position: 'relative',
    padding: 0,
  },
  item: {
    position: 'relative',
    height: '100%',
    width: '100%',
    scrollSnapAlign: 'start',
  },
  closeButton: {
    borderRadius: '50%',
    color: 'white',
    border: '1px solid white',
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

const StickyScrollVideoFeed = (props) => {
  const classes = useStyles();
  const { didExit, videos } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentVisibleIndex, setCurrentVisibleIndex] = useState(0); //0 = first video

  useEffect(() => {
    analytics.event(
      new AnalyticsEvent('web - short form video feed shown', {})
    );
  });

  const onClose = () => {
    //do something
    didExit && didExit();
  };

  return (
    <Box maxWidth="100%" dir={lclDirection()} id="modal-container">
      <Dialog
        scroll={'paper'}
        maxWidth="xs"
        open={true}
        onClose={onClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <Box
          className={classes.closeButton}
          position="absolute"
          top={0}
          left={0}
          m={1}
          zIndex={2}
        >
          <IconButton onClick={() => onClose()} style={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          id="feed-container"
          className={classes.container}
          maxHeight="100%"
          position="relative"
          height="100%"
          dir={lclDirection()}
        >
          {videos.map((video, index) => {
            return (
              <Box key={index} className={classes.item} id="item-container">
                <VisibilitySensor
                  partialVisibility={false}
                  onChange={(isVisible) => {
                    if (isVisible) setCurrentVisibleIndex(index);
                  }}
                >
                  <HomeVideoWidget
                    isSmall={isSmall}
                    visibile={currentVisibleIndex === index}
                    fill={true}
                    key={video.id}
                    index={index}
                    videoURL={video.video_url}
                    imageURL={video.image_url}
                    profilePictureURL={video.profile_picture_url}
                    title={video.title}
                    detail={video.detail}
                    profileId={video.profile_id}
                    backupImageURL={video.backupImageURL}
                    discover={false}
                  />
                </VisibilitySensor>
              </Box>
            );
          })}
        </Box>
      </Dialog>
    </Box>
  );
};

export default StickyScrollVideoFeed;
