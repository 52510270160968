import React, { useState } from 'react';
import styles from './BookingOccasion.module.scss';
import { useTranslation } from 'react-i18next';
import UserManager from '../../../../managers/userManager';
import icon_ask from '../../Images/icon_ask.svg';
import icon_birthday from '../../Images/icon_birthday.svg';
import icon_motivation from '../../Images/icon_motivation.svg';
import icon_event from '../../Images/icon_event.svg';
import icon_advice from '../../Images/icon_advice.svg';
import icon_que from '../../Images/icon_que.svg';
import icon_promotional from '../../Images/icon_promotional.svg';
import icon_testimonial from '../../Images/icon_testimonial.svg';
import icon_product from '../../Images/icon_product.svg';
import icon_collaboration from '../../Images/icon_collaboration.svg';

const BookingOccasion = (props) => {
  const { t, i18n } = useTranslation();

  console.log('BookingOccasion props', props);

  return (
    <div
      className={`${styles.BookingOccasion} ${
        i18n.language === 'he' || i18n.language === 'global' || !i18n.language
          ? styles.heb
          : ''
      }`}
    >
      {props.flow === 'forMe' || props.flow === 'forFriend' ? (
        <div className={styles.boxes}>
          <div
            onClick={() => {
              props.setSelectedOccasion(t('askQuestion'), icon_ask, 0);
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('askQuestion')
                ? styles.boxSelected
                : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_ask} />
            </div>
            <div className={styles.text}>{t('askQuestion')}</div>
          </div>
          <div
            onClick={() => {
              props.setSelectedOccasion(t('birthday'), icon_birthday, 5);
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('birthday')
                ? styles.boxSelected
                : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_birthday} />
            </div>
            <div className={styles.text}>{t('birthday')}</div>
          </div>
          <div
            onClick={() => {
              props.setSelectedOccasion(t('motivation'), icon_motivation, 10);
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('motivation')
                ? styles.boxSelected
                : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_motivation} />
            </div>
            <div className={styles.text}>{t('motivation')}</div>
          </div>
          <div
            onClick={() => {
              props.setSelectedOccasion(
                t('ocassion_type_event'),
                icon_event,
                15
              );
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('ocassion_type_event')
                ? styles.boxSelected
                : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_event} />
            </div>
            <div className={styles.text}>{t('ocassion_type_event')}</div>
          </div>
          <div
            onClick={() => {
              props.setSelectedOccasion(t('getAdvice'), icon_advice, 20);
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('getAdvice')
                ? styles.boxSelected
                : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_advice} />
            </div>
            <div className={styles.text}>{t('getAdvice')}</div>
          </div>
          <div
            onClick={() => {
              props.setSelectedOccasion(t('other'), icon_que, 99);
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('other') ? styles.boxSelected : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_que} />
            </div>
            <div className={styles.text}>{t('other')}</div>
          </div>
        </div>
      ) : (
        <div className={styles.boxes}>
          <div
            onClick={() => {
              props.setSelectedOccasion(t('promotional'), icon_promotional, 30);
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('promotional')
                ? styles.boxSelected
                : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_promotional} />
            </div>
            <div className={styles.text}>{t('promotional')}</div>
          </div>
          <div
            onClick={() => {
              props.setSelectedOccasion(t('testimonial'), icon_testimonial, 35);
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('testimonial')
                ? styles.boxSelected
                : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_testimonial} />
            </div>
            <div className={styles.text}>{t('testimonial')}</div>
          </div>
          <div
            onClick={() => {
              props.setSelectedOccasion(
                t('collaboration'),
                icon_collaboration,
                40
              );
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('collaboration')
                ? styles.boxSelected
                : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_collaboration} />
            </div>
            <div className={styles.text}>{t('collaboration')}</div>
          </div>
          <div
            onClick={() => {
              props.setSelectedOccasion(
                t('productPlacement'),
                icon_product,
                45
              );
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('productPlacement')
                ? styles.boxSelected
                : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_product} />
            </div>
            <div className={styles.text}>{t('productPlacement')}</div>
          </div>
          <div
            onClick={() => {
              props.setSelectedOccasion(t('getAdvice'), icon_advice, 20);
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('getAdvice')
                ? styles.boxSelected
                : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_advice} />
            </div>
            <div className={styles.text}>{t('getAdvice')}</div>
          </div>
          <div
            onClick={() => {
              props.setSelectedOccasion(t('other'), icon_que, 99);
            }}
            className={`d-flex flex-column align-items-center ${styles.box} ${
              props.selectedOccasion === t('other') ? styles.boxSelected : null
            }`}
          >
            <div className={styles.imgBox}>
              <img src={icon_que} />
            </div>
            <div className={styles.text}>{t('other')}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingOccasion;
