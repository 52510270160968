/* Converts everything to ILS - this is what we have in our account balance..what we should remove and add */
export function convertPriceToBase(price: number, currency: string) {
  if (currency === 'ILS') {
    return price;
  } else if (currency === 'USD') {
    return (price * 35) / 10;
  } else if (currency === 'VEE') {
    return price / 10;
  } else {
    return null;
  }
}
export function convertPriceFromBase(price: number, toCurrency: string) {
  if (toCurrency === 'ILS') {
    return price;
  } else if (toCurrency === 'USD') {
    return price / 3.5;
  } else if (toCurrency === 'VEE') {
    return price * 10;
  } else {
    return null;
  }
}
export function convertPrice(
  price: number,
  fromCurrecy: string,
  toCurrency: string
) {
  //1. Convert to base
  const basePrice = convertPriceToBase(price, fromCurrecy);
  if (!basePrice) {
    return -1.0;
  }

  let value = basePrice;

  const fromBase = convertPriceFromBase(value, toCurrency);
  if (!fromBase) {
    return -1.0;
  }

  //2. Convert from base
  value = fromBase;

  //return converted value
  return toCurrency === 'USD' ? value.toFixed(2) : value.toFixed(0);
}

export function symbolFromCurrency(currency: string): string {
  if (currency === 'ILS') {
    return '₪';
  } else if (currency === 'USD') {
    return '$';
  } else {
    return currency;
  }
}
