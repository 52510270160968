import BaseTracker from './base.tracker';
import amplitude from 'amplitude-js/amplitude';
import moment from 'moment';

const TAG = 'AmplitudeTracker: ';
const SESSION_KEY = 'kreSession';

export class AmplitudeTracker extends BaseTracker {
  init() {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE, null, {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true,
    });

    this.createNewSessionIfNeeded();

    return this;
  }

  /**
   * Create a new session if needed -- IMPORTANT method - will help calculating DAU, WAU and MAU
   * @return void
   */
  createNewSessionIfNeeded() {
    let savedSession = localStorage.getItem(SESSION_KEY);

    if (!savedSession) {
      this.createSession().then(() => this.event('start web session'));
    } else {
      this.validateSession(JSON.parse(savedSession)).then((shouldCreateNew) => {
        if (shouldCreateNew)
          this.createSession().then(() => this.event('start web session'));
      });
    }
  }

  /**
   * Check if the stored session is larger than 30min.
   * @param session
   * @returns {Promise<unknown>}
   */
  validateSession(session) {
    return new Promise((resolve) => {
      if (!session['time']) {
        // console.log(TAG, "invalid session object, start a new session");
        return resolve(true);
      }

      let diff = moment().diff(moment(session['time']), 'minutes');
      resolve(diff > 30);
    });
  }

  /**
   * Create new session object and save it to local storage
   * @returns {Promise<unknown>}
   */
  createSession() {
    return new Promise((resolve) => {
      let session = {
        id: amplitude.getInstance().getSessionId(),
        time: new Date(),
      };

      // console.log(TAG, "Creating new session: ", session);
      localStorage.setItem(SESSION_KEY, JSON.stringify(session));
      resolve(session);
    });
  }

  event(name, properties = []) {
    return new Promise((resolve) => {
      let props = {};

      if (properties instanceof Array)
        properties.map((property) => {
          for (let key in property) props[key] = property[key];
        });

      // console.log(TAG, "sending event [ ", name, " ]: ", props);
      amplitude.getInstance().logEvent(name, props, resolve);
    });
  }

  setUserProperty(name, property) {
    // console.log(TAG, "set user property [ ", name, " : ", property, " ]");
    let userProperties = {};
    userProperties[name] = property;
    amplitude.getInstance().setUserProperties(userProperties);
    return this;
  }

  setUserId(userId) {
    // console.log(TAG, "set user id [ ", userId, " ]");
    amplitude.getInstance().setUserId(userId);
    this.setUserProperty('platform', 'web');
    return this;
  }

  setIsCreator = (isCreator) =>
    this.setUserProperty('is_creator', isCreator ? true : false);
  setDesktop = () => this.setUserProperty('Mobile', 'No');
  setMobile = () => this.setUserProperty('Mobile', 'Yes');
  setName = (name) => this.setUserProperty('Name', name);
  setProfileId = (profileId) => this.setUserProperty('ProfileId', profileId);
  setEmail(email) {
    this.setUserProperty('email', email);
  }
}
