import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { toSocialString } from '../../../../utils/vlStrings';
import styles from './BadgeView.module.scss';
import InstagramIcon from './images/instagram_16.png';
import TikTokIcon from './images/tik_tok_16.png';
import YouTubeIcon from './images/youtube_16.png';

const BadgeBox = (props) => {
  const { icon, number, show } = props;
  return show ? (
    <Box
      style={{ paddingLeft: '1px', paddingRight: '1px' }}
      display="flex"
      // alignContent="center"
      // justifyContent="center"
      alignItems="center"
      flexDirection="row"
    >
      <img
        alt="social"
        src={icon}
        style={{ width: '16px', height: '16px' }}
      ></img>

      <Box
        style={{ paddingLeft: '1px', paddingRight: '1px' }}
        display="flex"
        alignContent="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography variant="body2">{toSocialString(number)}</Typography>
      </Box>
    </Box>
  ) : null;
};

const BadgeView = (props) => {
  const { followersInstagram, followersYoutube, followersTiktok } = props;

  let hasAnyFollowers =
    followersInstagram + followersYoutube + followersTiktok > 0;

  const min = Math.min(followersInstagram, followersYoutube, followersTiktok);

  return hasAnyFollowers ? (
    <Box
      display="flex"
      flexdirection="row"
      className={styles.root}
      maxWidth="75%"
    >
      <BadgeBox
        show={followersInstagram > 0 && followersInstagram > min}
        icon={InstagramIcon}
        number={followersInstagram}
      />
      <BadgeBox
        show={followersYoutube > 0 && followersYoutube > min}
        icon={YouTubeIcon}
        number={followersYoutube}
      />
      <BadgeBox
        show={followersTiktok > 0 && followersTiktok > min}
        icon={TikTokIcon}
        number={followersTiktok}
      />
    </Box>
  ) : null;
};

export default BadgeView;
