import React from 'react';
import styles from './ProfileActionButton.module.scss';
import * as bootstrapUtils from '../../utils/bootstrapUtils';
import * as utils from '../../utils/rhutils';
import LazyLoad from 'react-lazyload';
const ProfileActionButton = (props) => {
  let id = props.customId;
  let title = props.title;
  let image = props.image;
  let outline = props.outline;
  let customImageURL = props.customImageURL;
  let price = props.price;
  let specialStyle = styles[props.specialStyle];
  const disabled = props.disabled ?? false;
  // let specialStyle = "GradientButtonOne";
  // utils.log.debug(`loaded profile action button props:`, props);

  const customImageSection = () => {
    return (
      <div
        className={`d-flex flex-column rounded-circle justify-content-center align-items-center ${styles.SideImageCustom}`}
      >
        <img
          src={customImageURL}
          style={{ width: '30px', height: '30px', objectFit: 'cover' }}
        />
      </div>
    );
  };

  const regularImageSection = () => {
    return (
      <div
        className={`d-flex flex-column rounded-circle justify-content-center align-items-center ${styles.SideImage}`}
      >
        <img src={image} style={{ width: '20px', height: '20px' }} />
      </div>
    );
  };
  const imageSection = () => {
    return image
      ? regularImageSection()
      : customImageURL
      ? customImageSection()
      : null;
  };

  return (
    <div
      id={id}
      className={`${
        disabled ? 'opacity-2' : ''
      } d-flex flex-row align-items-center ${
        styles.MainContainer
      } ${specialStyle} ${
        props.outline ? styles.outline_btn : ''
      } action-btn-over`}
    >
      {imageSection()}
      <div className={`d-flex flex-row flex-fill`}>
        <div
          className={`${styles.ButtonTitle} ${bootstrapUtils.lclMarginLeft(3)}`}
        >
          {title}
        </div>
        <div
          className={`${styles.PriceTitle} ${
            image ? bootstrapUtils.lclFlexRightFill() : ''
          } ${bootstrapUtils.lclFlexRightFill()} ${bootstrapUtils.lclMarginRight(
            3
          )}`}
        >
          {price}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProfileActionButton);
