import React, { useState, useEffect, useRef } from 'react';
import styles from './EditProfileAccount.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditProfileAccountTags from './EditProfileAccountTags/EditProfileAccountTags';
import EditProfileHeader from '../EditProfileHeader/EditProfileHeader';
import image_box from '../Images/image_box.svg';
import video_box from '../Images/video_box.svg';
import icon_delete from '../Images/icon_delete.svg';
import icon_edit from '../Images/icon_edit.svg';
import TextareaAutosize from 'react-textarea-autosize';
import profileManager from '../../managers/profileManager';
import userManager from '../../managers/userManager';
import uploadManager from '../../managers/uploadManager';
import { centerFlexColumn } from '../../utils/bootstrapUtils';
import VLSpinner from '../../components/loader/VLSpinner';

const ProfileImage = (props) => {
  // console.log('ProfileImage');
  const { image, userProfilePic, deleteBox, uploadImage, t } = props;
  let asset = null;
  if (image && image.imgLink) {
    asset = image.imgLink;
  } else if (userProfilePic) {
    asset = userProfilePic;
  } else {
    asset = null;
  }
  // console.log('asset: ', asset);
  return (
    <ImageBox
      deleteBox={deleteBox}
      uploadImage={uploadImage}
      text={t('editProfileAccount_addImage')}
      link={asset}
    />
  );
};

const ProfileVideo = (props) => {
  // console.log('ProfileVideo');
  const { video, userProfileVideo, deleteBox, uploadImage, t } = props;
  let asset = null;
  if (video && video.imgLink) {
    // console.log('1...');
    asset = video.imgLink;
  } else if (userProfileVideo) {
    // console.log('2...');
    asset = userProfileVideo;
  } else {
    // console.log('3...');
    asset = null;
  }

  // console.log('asset: ', video);

  return (
    <VideoBox
      deleteBox={deleteBox}
      text={t('editProfileAccount_addVideo')}
      uploadImage={uploadImage}
      Link={asset}
    />
  );
};

const AssetsContainer = (props) => {
  const { setImage, setVideo, deleteBox, image, video } = props;
  return (
    <div className={styles.buttons}>
      <ProfileImage
        {...props}
        setImage={setImage}
        image={image}
        deleteBox={deleteBox}
      />
      <ProfileVideo
        {...props}
        setVideo={setVideo}
        video={video}
        deleteBox={deleteBox}
      />
    </div>
  );
};
const ProfileImageAndVideo = (props) => {
  const { setImage, setVideo, deleteBox, image, video } = props;

  // console.log('ProfileImageAndVideo', video);

  return <AssetsContainer {...props} />;
};

/* 
Upload image box
*/
const ImageBox = (props) => {
  const EditButton = () => {
    return props.link ? (
      <div>
        <img src={icon_edit} className={styles.iconEdit} />
        <input
          accept="image/*"
          type="file"
          id="EditProfileAccountImageEdit"
          style={{ display: 'none' }}
          onChange={(e) => {
            props.uploadImage(e.target, 'image');
          }}
        />
      </div>
    ) : null;
  };
  return (
    <div className={styles.button}>
      {props.link ? (
        <img
          src={icon_delete}
          className={styles.iconDelete}
          onClick={() => {
            props.deleteBox('image');
          }}
        />
      ) : null}
      <label htmlFor="EditProfileAccountImage">
        <input
          type="file"
          accept="image/*"
          id="EditProfileAccountImage"
          style={{ display: 'none' }}
          onChange={(e) => {
            props.uploadImage(e.target, 'image');
          }}
        />
        {props.link ? (
          <img src={props.link} />
        ) : (
          <img src={image_box} className={styles.imgEmpty} />
        )}
        {!props.link ? <div className={styles.text}>{props.text}</div> : null}
      </label>
      <EditButton />
    </div>
  );
};

/* 
Upload video box
*/
const VideoBox = (props) => {
  const EditButton = () => {
    return props.Link ? (
      <div>
        <img src={icon_edit} className={styles.iconEdit} />
        <input
          accept="video/*"
          type="file"
          id="EditProfileAccountVideoEdit"
          style={{ display: 'none' }}
          onChange={(e) => {
            props.uploadImage(e.target, 'video');
          }}
        />
      </div>
    ) : null;
  };
  //All the video component

  const VideoView = () => {
    // console.log('VideoView');
    return props.Link ? (
      <video controls>
        <source src={props.Link} type="video/mp4" />
      </video>
    ) : (
      <img src={video_box} className={styles.imgEmpty} />
    );
  };
  return (
    <div className={styles.button}>
      <label htmlFor="EditProfileAccountVideo">
        <VideoView />
        {!props.Link ? <div className={styles.text}>{props.text}</div> : null}
        <input
          type="file"
          id="EditProfileAccountVideo"
          style={{ display: 'none' }}
          onChange={(e) => {
            props.uploadImage(e.target, 'video');
          }}
        />
        <EditButton />
        {props.Link ? (
          <img
            src={icon_delete}
            className={styles.iconDelete}
            onClick={() => {
              props.deleteBox('video');
            }}
          />
        ) : null}
      </label>
    </div>
  );
};

/* 
A regular input
*/
const Input = (props) => {
  // console.log('props.ref', props);
  return (
    <span>
      <input
        ref={props.userRef}
        type="text"
        className={styles.input}
        placeholder={props.text}
        value={props.value}
        onChange={(e) => {
          props.changeInput(e.target.value, props.kind);
        }}
      />
      <div className={`${styles.inputDown} ${!props.name ? styles.hide : ''}`}>
        {props.text}
      </div>
    </span>
  );
};

/* 
A regular input
*/
const UsernameInput = (props) => {
  const [usernameState, setUsernameState] = useState('idle'); //idle, loading, success, error

  const changeInput = async (newValue, kind) => {
    //change input data
    // console.log('changeInput ');
    const value = newValue.toLowerCase();
    //check if valid on server
    setUsernameState('loading');

    props.changeInput(newValue, kind);

    setTimeout(async () => {
      // console.log('check if should validate username');
      const currentValue = props.userRef.current.value;
      if (value === currentValue.toLowerCase()) {
        // console.log('validating username...', value);
        const isUsernameAvailable = await userManager.validateSuggestedUserName(
          value
        );
        // console.log('result fetched for', value);
        // console.log('result isUsernameAvailable = ', isUsernameAvailable);
        setUsernameState(isUsernameAvailable ? 'success' : 'error');
        props.setUsernameValid(isUsernameAvailable);
      }
    }, 500);
  };

  const UsernameStateLoader = () => {
    return usernameState === 'idle' ? null : (
      <div className={`${styles.UsernameStateLoader}`}>
        <VLSpinner
          type={usernameState}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    );
  };

  return (
    <span>
      <div className={`${'position-relative'} d-flex flex-row`}>
        <input
          ref={props.userRef}
          type="text"
          className={styles.input}
          placeholder={props.text}
          value={props.value}
          onChange={(e) => {
            changeInput(e.target.value, props.kind);
          }}
        ></input>
        <UsernameStateLoader />
      </div>
      <div className={`${styles.inputDown} ${!props.name ? styles.hide : ''}`}>
        {props.text}
      </div>
    </span>
  );
};
/* 
textarea component with option to change the component size
*/
const Textarea = (props) => {
  //
  return (
    <span>
      <TextareaAutosize
        ref={props.userRef}
        className={styles.input}
        maxRows={5}
        type="text"
        placeholder={props.text}
        value={props.value}
        onChange={(e) => {
          props.changeInput(e.target.value, props.kind);
        }}
      />
      <div className={`${styles.inputDown} ${!props.name ? styles.hide : ''}`}>
        {props.text}
      </div>
    </span>
  );
};

const Inputs = (props) => {
  const [name, setName] = useState(null);
  const [username, setUsername] = useState(null);
  const [title, setTitle] = useState(null);
  const [about, setAbout] = useState(null);

  // console.log('props...', props.nameRef);

  const changeInput = (newValue, kind) => {
    //change input data
    // console.log('changeInput');
    if (kind === 'name') {
      setName(newValue);
    } else if (kind === 'username') {
      setUsername(newValue.toLowerCase());
    } else if (kind === 'title') {
      setTitle(newValue);
    } else if (kind === 'about') {
      setAbout(newValue);
    }
    props.inputChanged();
  };

  return (
    <div className={styles.inputs}>
      <Input
        userRef={props.nameRef}
        value={name ?? props.name}
        text={props.t('editProfileAccount_name')}
        kind="name"
        changeInput={changeInput}
      />
      <UsernameInput
        userRef={props.usernameRef}
        setUsernameValid={props.setUsernameValid}
        value={username ?? props.username}
        text={props.t('editProfileAccount_username')}
        kind="username"
        changeInput={changeInput}
      />
      <Input
        userRef={props.titleRef}
        value={title ?? props.title}
        text={props.t('editProfileAccount_title')}
        kind="title"
        changeInput={changeInput}
      />
      <Textarea
        userRef={props.descriptionRef}
        value={about ?? props.about}
        text={props.t('editProfileAccount_about')}
        kind="about"
        changeInput={changeInput}
      />
    </div>
  );
};

/* 
all the edit profile account page
*/
const EditProfileAccount = (props) => {
  let didUploadProfilePicture = false;
  let didUploadProfileVideo = false;

  let updatedProfilePictureURL = null;
  let updatedProfileVideoURL = null;

  const nameRef = useRef(null);
  const titleRef = useRef(null);
  const usernameRef = useRef(null);
  const descriptionRef = useRef(null);

  const [shouldAllowSave, setShouldAllowSave] = useState(false);

  const { t, i18n } = useTranslation();
  const [user, setUser] = useState(null);
  const [image, setImage] = useState({});
  const [video, setVideo] = useState({});
  const [name, setName] = useState(null);
  const [username, setUsername] = useState(null);
  const [title, setTitle] = useState(null);
  const [about, setAbout] = useState(null);
  const [tags, setTags] = useState([]);
  const [showTags, setShowTags] = useState(false);
  const [userNameValid, setUsernameValid] = useState(true);

  let userProfilePic =
    props.User && props.User.profilePictureURL
      ? props.User.profilePictureURL
      : false;
  let userProfileVideo =
    props.User && props.User.profileVideoURL
      ? props.User.profileVideoURL
      : false;

  useEffect(() => {
    //if the user data has changed we need to update the state
    // console.log('use effect called');

    if (user) {
      return;
    }

    if (props.User) {
      setUser(props.User);
    }

    if (props.User && props.User.profileId) {
      setUsername(props.User.profileId);
    }

    if (props.User && props.User.title) {
      setTitle(props.User.title);
    }

    if (props.User && props.User.desc) {
      setAbout(props.User.desc);
    }

    if (props.User && props.User.tags) {
      setTags(props.User.tags);
    }

    if (props.User && props.User.fullName()) {
      setName(props.User.fullName());
    }
  }, []);

  /**
   * Checks if we can save allow this user to saved based on original and current data
   */
  const allowSave = () => {
    // console.log('allowSave called');
    const user = props.User;
    if (!user || !user.id) {
      return false;
    } else {
      //profile pic
      let diff =
        image && image.imgLink && didUploadProfilePicture === false
          ? true
          : false;
      // console.log('diff 1= ', diff);
      //profile video
      diff =
        diff ||
        (video && video.imgLink && didUploadProfileVideo === false
          ? true
          : false);
      // console.log('diff 2= ', diff);

      //user details
      // console.log('nameRef.current', nameRef.current.value);
      diff = diff || user.fullName() !== nameRef.current.value;
      diff = diff || user.profileId !== usernameRef.current.value;
      diff = diff || user.title !== titleRef.current.value;
      diff = diff || user.desc !== descriptionRef.current.value;
      diff = diff || user.tags !== tags;

      // console.log('diff = ', diff);
      return diff && userNameValid;
    }
  };

  /**
   * Complex save function that handles uploading assets (profile pic/video) and data
   */
  const save = async () => {
    if (allowSave() === false) {
      // console.log('not allowed to save');
      return;
    }
    //save the data in the server
    // console.log('tags', tags);
    // console.log('name', name);
    // console.log('username', username);
    // console.log('title', title);
    // console.log('about', about);
    // console.log('image', image);
    // console.log('video', video);

    const firstname = nameRef.current.value.split(' ')[0];
    const lastName = nameRef.current.value.substring(firstname.length).trim();

    const uploadProfileVideo = (callback) => {
      return uploadManager.uploadProfileVideo(
        user.id,
        video.imgFile,
        (downloadURL, progress, error) => {
          // console.log('👨‍💻 callbacks...', downloadURL, progress, error);
          // console.log('this...', this);
          if (error) {
            const errorTitle = 'Error';
            const errorDetail = 'Could not upload profile picture';
            props.onError(errorTitle, errorDetail);
            callback(false);
          } else if (downloadURL) {
            // console.log('✅ finnished uploading profile video!', downloadURL);
            updatedProfileVideoURL = downloadURL;
            didUploadProfileVideo = true;
            //great .. continue
            callback(true);
          } else if (progress) {
            // console.log('🔼 updating progress... ', progress);
            //todo: do something with progress
          }
        }
      );
    };

    const uploadProfilePicture = (callback) => {
      return uploadManager.uploadProfilePicture(
        image.imgFile,
        (downloadURL, progress, error) => {
          // console.log('👨‍💻 callbacks...', downloadURL, progress, error);
          // console.log('this...', this);
          if (error) {
            const errorTitle = 'Error';
            const errorDetail = 'Could not upload profile picture';
            props.onError(errorTitle, errorDetail);
            callback(false);
          } else if (downloadURL) {
            // console.log('✅ finished uploading profile picture!', downloadURL);
            updatedProfilePictureURL = downloadURL;
            didUploadProfilePicture = true;
            //great .. continue
            callback(true);
          } else if (progress) {
            // console.log('🔼 updating progress... ', progress);
            //todo: do something with progress
          }
        }
      );
    };

    if (image.imgLink && didUploadProfilePicture == false) {
      //upload profile picture
      props.setShowLoading(true);
      uploadProfilePicture((success) => {
        //continue recursively
        if (success) {
          save();
        } else {
          props.setShowLoading(false);
          showError('Could not upload profile picture');
        }
      });
      return;
    }

    if (video.imgLink && didUploadProfileVideo == false) {
      //upload profile picture
      props.setShowLoading(true);
      uploadProfileVideo((success) => {
        //continue recursively
        if (success) {
          save();
        } else {
          props.setShowLoading(false);
          showError('Could not upload profile video');
        }
      });
      return;
    }

    let updateObj = {
      first_name: firstname,
      last_name: lastName,
      profile_id: usernameRef.current.value,
      profilePictureURL: null,
      profileVideoURL: null,
      title: titleRef.current.value,
      desc: descriptionRef.current.value,
      tags: tags,
    };

    if (didUploadProfilePicture && updatedProfilePictureURL) {
      updateObj['profilePictureURL'] = updatedProfilePictureURL;
    }

    if (didUploadProfileVideo && updatedProfileVideoURL) {
      updateObj['profileVideoURL'] = updatedProfileVideoURL;
    }

    props.setShowLoading(true);
    const attemptSave = await profileManager.updateProfileAccount(
      updateObj,
      props.hijackedUserId
    );
    props.setShowLoading(false);
    // console.log('attemptSave = ', attemptSave);
    if (attemptSave.success) {
      props.onEditSaveSuccess();
    } else {
      const errorDetail =
        attemptSave.error && attemptSave.error.localizedTitle();
      showError(errorDetail);
    }
  };

  const showError = (detail) => {
    const errorTitle = 'Error';
    const errorDetail = detail;

    props.onError(errorTitle, errorDetail);
  };

  const addTags = (newTags) => {
    //save all the tags in the server
    setTags(newTags);
    setShowTags(false);
  };

  if (showTags) {
    //show tags component
    return (
      <EditProfileAccountTags
        addTags={addTags}
        saveTags={tags ? tags : []}
        hide={() => {
          setShowTags(false);
        }}
      />
    );
  }

  const Tags = () => {
    return (
      <div className={styles.tags}>
        {tags.map((tag) => {
          return (
            <div className={styles.tag} key={tag}>
              {tag}
            </div>
          );
        })}
        <div
          className={styles.addTag}
          onClick={() => {
            setShowTags(true);
          }}
        >
          {t('editProfileAccount_AddTag')}
        </div>
      </div>
    );
  };

  const deleteBox = (kind) => {
    //delete image or video
    // console.log('delete box', kind);
    if (kind === 'image') {
      setImage({});
    } else if (kind === 'video') {
      setVideo({});
    }
  };

  /**
   * Logic for taking user selected resource and and processing to result as local link which can be uploaded and saved
   * on our end (firebase hosting rn)
   * @param {*} data
   * @param {*} kind
   */
  const uploadImage = (data, kind) => {
    //upload image to server and update the image in client side
    let reader = new FileReader();
    reader.onload = (e) => {
      // console.log('FileReader', kind);

      let img = { imgLink: e.target.result, imgFile: data.files[0] };
      if (kind === 'image') {
        setImage(img);
      } else if (kind === 'video') {
        // console.log('imgLink', e);
        setVideo(img);
      }
    };

    reader.readAsDataURL(data.files[0]);
  };

  return (
    <div
      className={`${styles.wrapperBig} ${
        i18n.language === 'he' ? styles.heb : ''
      }`}
    >
      <EditProfileHeader
        allowSave={shouldAllowSave}
        title={t('editProfileAccount_account')}
        save={save}
        close={props.close}
      />

      <div className={`${centerFlexColumn()} w-100`}>
        <div className={styles.wrapper}>
          <ProfileImageAndVideo
            deleteBox={deleteBox}
            uploadImage={uploadImage}
            t={t}
            image={image}
            video={video}
            setImage={setImage}
            setVideo={setVideo}
            userProfilePic={userProfilePic}
            userProfileVideo={userProfileVideo}
          />
          <Inputs
            inputChanged={() => setShouldAllowSave(allowSave())}
            nameRef={nameRef}
            titleRef={titleRef}
            usernameRef={usernameRef}
            descriptionRef={descriptionRef}
            setUsernameValid={setUsernameValid}
            about={about}
            title={title}
            t={t}
            name={name}
            username={username}
          />
          {/* <Tags /> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(EditProfileAccount);
