import React from 'react';
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  button: {
    height: '64px',
    borderRadius: '32px',
    margin: theme.spacing(1),
    textTransform: 'none',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '120%',
    textAlign: 'center',
    letterSpacing: '-0.41px',
    color: '#FFFFFF',
  },
}));

export default function VLButton(props: ButtonProps) {
  const classes = useStyles();

  return (
    <Button
      disableElevation={props.disableElevation ?? true}
      variant={props.variant ?? 'contained'}
      color="primary"
      className={classes.button}
      {...props}
      style={props.style}
    >
      {props.children}
    </Button>
  );
}
