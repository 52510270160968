import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import styles from './RedeemCode.module.scss';
import { useTranslation } from 'react-i18next';
import IconClose from '../images/icon_close_purple.svg';
// @ts-ignore
import CreditCardInput from 'react-credit-card-input';
import { Dialog, TextField } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import LoadingOverlay from '../Loading/LoaderOverlay';
import Toast from '../../../Toasts/Toast';
import promotionManager from '../../../managers/promotionManager';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VLButton from '../../../Reusables/Buttons/VLButton';
import { isHebrew } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: 'white',
      borderRadius: '16px',
    },
  },
  labelRoot: {
    //@ts-ignore
    right: (props) => (props.isHebrew ? 0 : 'none'),
  },
  shrink: {
    //@ts-ignore
    transformOrigin: (props) => (props.isHebrew ? 'top right' : 'top left'),
  },
}));

interface ErrorSnackBarProps {
  show: boolean;
  onClick: any;
}

let enteredCode = '';
let error: string = '';
const ErrorSnackBar = ({ show, onClick }: ErrorSnackBarProps) => {
  const { t, i18n } = useTranslation();
  return (
    <Toast
      error
      title={t('redeem_code_error_title')}
      detail={t('redeem_code_error_detail')}
      show={show}
      onClick={() => onClick}
    />
  );
};

interface RedeemCodeProps {
  open: boolean;
  type: 'subscription' | 'signup' | 'booking';
  creatorId?: string | null; //id of creator
  onClose: any; // required - on close callback method
  onSuccess: any; //required - on success of redemption
}
const RedeemCode = (props: RedeemCodeProps) => {
  const { t, i18n } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const theme = useTheme();

  const inputRef = useRef(null);

  const onApplyCodeClicked = async () => {
    setShowLoading(true);

    const fetchPromotion = await promotionManager.fetchPromotionForCreator(
      props.type,
      props.creatorId ?? 'none',
      undefined,
      undefined,
      enteredCode
    );

    setShowLoading(false);

    if (fetchPromotion.success && fetchPromotion.promotion) {
      props.onSuccess(fetchPromotion.promotion);
    } else {
      //error
      error = fetchPromotion.errorMessage ?? 'not good';
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  };

  const classes = useStyles({ isHebrew: isHebrew() });

  return (
    <Dialog
      maxWidth="xs"
      fullScreen={false}
      open={props.open}
      disableEnforceFocus={false}
      onClose={props.onClose}
      style={{ backgroundColor: 'transparent' }}
      aria-labelledby="responsive-dialog-title"
      className={`${styles.customDialog} ${classes.root}`}
    >
      <div className={`${styles.Master}`}>
        <div
          className={`${styles.container} d-flex flex-column justify-content-between`}
        >
          <div className={`${styles.title}`}>{t('enter_promo_code')}</div>
          <TextField
            inputProps={{ style: { textAlign: 'center' } }}
            InputLabelProps={{
              classes: { root: classes.labelRoot, shrink: classes.shrink },
              style: { color: 'rgba(0, 0, 0, 0.7)', textAlign: 'center' },
            }}
            onChange={(e: any) => {
              enteredCode = e.target.value;
              setDisabled(enteredCode.length === 0);
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                onApplyCodeClicked();
              }
            }}
            className="custom-input"
            id="standard-basic"
            label={t('enter_code_textfield_placeholder')}
          />
          <VLButton disabled={disabled} onClick={() => onApplyCodeClicked()}>
            {t('apply')}
          </VLButton>
        </div>
      </div>
      <LoadingOverlay showLoading={showLoading} />
      <ErrorSnackBar onClick={() => setShowError(false)} show={showError} />
    </Dialog>
  );
};

const mapStateToProps = (state?: any) => {
  return { isConnected: state.user.isConnected };
};

export default connect(mapStateToProps)(RedeemCode);
