import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import React, { Component, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MobileProfileHeader.module.scss';
import * as bootstrapUtils from '../../utils/bootstrapUtils';
import * as utils from '../../utils/rhutils';
import * as vlImages from '../../utils/vlImages';
import VLUser from '../../model/vlUser';
import * as anim from '../../components/animations/vlFadeIn';
import ProfileActionButtonContainer from './ProfileActionButtonContainer';
import MobileProfileHeaderExclusives from './MobileProfileHeaderExclusives';
import AboutSection from '../About/About';
import Links from '../Links/Links';
import { textSpanIntersection } from 'typescript';
import { lclFlexRightFill, lclFlexRow } from '../../utils/vlStrings';
import { FaPlay, FaPause } from 'react-icons/fa';
import { Box, Container, Fade, makeStyles, Zoom } from '@material-ui/core';
import BackgroundGradientSVG from './background_gradient_border.svg';

const useStyles = makeStyles((theme) => ({
  sectionSpacing: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

const VideoComponent = (props) => {
  const {
    videoPlayMode,
    rippleAnimationFinished,
    rippleReverseAnimation,
    videoInFront,
    setShowVideoButtons,
    showVideoButtons,
    user,
    setVideoReady,
    videoReady,
    onVideoClicked,
  } = props;

  const videoRef = React.useRef(null);

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (videoPlayMode && videoRef.current) {
      //restart video
      videoRef.current.currentTime = 0;
    }
  }, [videoPlayMode]);

  return (
    <div
      id="video-background-container"
      onClick={() => onVideoClicked()}
      style={{
        zIndex: videoInFront ? '9999' : 0,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
      }}
    >
      {videoPlayMode && false && (
        <div
          className={`
          ${styles.video_ripple}
          ${videoPlayMode && rippleAnimationFinished ? styles.video_rip : ''}
          ${rippleReverseAnimation ? styles.video_rip_reverse : ''}
        `}
        ></div>
      )}
      <div
        className={`${videoPlayMode ? styles.video_play_mode : ''} ${
          styles.video_background
        }`}
        onClick={() => setShowVideoButtons(!showVideoButtons)}
      >
        <anim.FadeInDiv className="w-100 h-100">
          {user && user.profileVideoURL && (
            <>
              <div>
                <video
                  ref={videoRef}
                  style={{ objectFit: 'cover' }}
                  onLoadedData={() => setVideoReady(true)}
                  onPlay={() => {}}
                  autoPlay
                  muted={!videoPlayMode}
                  loop
                  playsInline={true}
                >
                  <source src={user.profileVideoURL} type="video/mp4" />
                </video>
              </div>
            </>
          )}
        </anim.FadeInDiv>
      </div>
      {videoPlayMode && (
        <svg
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          className={`
            ${styles.video_ripple}
            ${videoPlayMode ? styles.video_rip : ''}
        `}
        >
          <defs>
            <mask id="msk1">
              <rect width="100%" height="100%" fill="white" />
              <circle cx="50%" cy="93%" r="200" fill="black">
                <animate
                  attributeName="r"
                  values="0;1000"
                  dur="0.5s"
                  repeatCount="1"
                  fill="freeze"
                ></animate>
              </circle>
            </mask>
          </defs>
          <g mask="url(#msk1)">
            <title>background</title>
            <rect
              fill="#3c3a5099"
              id="canvas_background"
              height="100%"
              width="100%"
              y="0"
              x="0"
            />
            <g
              display="none"
              overflow="visible"
              y="0"
              x="0"
              height="100%"
              width="100%"
              id="canvasGrid"
            >
              <rect
                fill="url(#gridpattern)"
                stroke-width="0"
                y="0"
                x="0"
                height="100%"
                width="100%"
              />
            </g>
          </g>
        </svg>
      )}
      {rippleReverseAnimation && (
        <svg
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          className={`
            ${styles.video_ripple}
            ${styles.video_rip}
        `}
        >
          <defs>
            <mask id="msk2">
              <rect width="100%" height="100%" fill="white" />
              <circle cx="50%" cy="93%" r="200" fill="black">
                <animate
                  attributeName="r"
                  values="1000;0"
                  dur="0.5s"
                  repeatCount="1"
                  fill="freeze"
                ></animate>
              </circle>
            </mask>
          </defs>
          <g mask="url(#msk2)">
            <title>background</title>
            <rect
              fill="#3c3a5099"
              id="canvas_background"
              height="100%"
              width="100%"
              y="0"
              x="0"
            />
            <g
              display="none"
              overflow="visible"
              y="0"
              x="0"
              height="100%"
              width="100%"
              id="canvasGrid"
            >
              <rect
                fill="url(#gridpattern)"
                stroke-width="0"
                y="0"
                x="0"
                height="100%"
                width="100%"
              />
            </g>
          </g>
        </svg>
      )}
    </div>
  );
};
const ProfileActionButtonBoxContainer = (props) => {
  const {
    user,
    showSkeleton,
    videoPlayMode,
    onProfileActionButtonClicked,
    showVideoButtons,
    allowSubscribe,
  } = props;

  const profileActionButtons = () => {
    return (
      <ProfileActionButtonContainer
        allowSubscribe={allowSubscribe}
        user={user}
        showSkeleton={showSkeleton}
        outline={videoPlayMode}
        onActionButtonClicked={(actionType) =>
          onProfileActionButtonClicked(actionType)
        }
      />
    );
  };

  return (
    <div
      className={`w-100 ${
        styles.action_buttons_container
      } ${bootstrapUtils.centerFlexColumn()}`}
      style={{ zIndex: showVideoButtons ? 99999 : 9 }}
    >
      <div className={`${styles.buttonWrapper}`}>{profileActionButtons()}</div>
    </div>
  );
};

const ProfileOverlayButton = (props) => {
  const { videoReady, videoPlayMode, toggleVideoMode } = props;
  if (videoReady !== true) {
    return null;
  }
  return (
    <div
      onClick={() => (videoPlayMode ? toggleVideoMode(!videoPlayMode) : null)}
      className={`${styles.video_play_btn} center ${
        videoPlayMode ? styles.pause_btn : ''
      }`}
    >
      {
        <anim.FadeInDiv className="w-100">
          <>
            <div
              className={styles.play_loader}
              onClick={() => toggleVideoMode(!videoPlayMode)}
            >
              <svg viewbox="0 0 60 60">
                <circle cx="31" cy="31" r="29" />
              </svg>
            </div>
            <button
              className=""
              style={{ width: '46px', height: '46px' }}
              onClick={() => toggleVideoMode(!videoPlayMode)}
            >
              {!videoPlayMode ? <FaPlay /> : <FaPause />}
            </button>
          </>
        </anim.FadeInDiv>
      }
    </div>
  );
};

const TopContainer = (props) => {
  const {
    videoPlayMode,
    showSkeleton,
    onProfileActionButtonClicked,
    imageLoaded,
    setImageLoaded,
    mainImage,
    user,
    userHandle,
    onCauseClicked,
    charityDisplay,
    userFullName,
    userTitle,
    isVerified,
    videoReady,
    onShowProfileVideoClicked,
  } = props;
  return (
    <div
      className={`${styles.top_container} ${
        videoPlayMode ? styles.top_container_hidden : ''
      }`}
    >
      <div className="position-relative w-100">
        <div className="position-relative w-100 text-center">
          <ProfilePictureView
            onShowProfileVideoClicked={onShowProfileVideoClicked}
            videoReady={videoReady}
            showSkeleton={showSkeleton}
            onProfileActionButtonClicked={onProfileActionButtonClicked}
            imageLoaded={imageLoaded}
            setImageLoaded={setImageLoaded}
            mainImage={mainImage}
            user={user}
          />
        </div>
      </div>
      <div className={'text-center'}>
        <ProfileTextsSection
          isVerified={isVerified}
          showSkeleton={showSkeleton}
          userHandle={userHandle}
          onCauseClicked={onCauseClicked}
          charityDisplay={charityDisplay}
          userFullName={userFullName}
          userTitle={userTitle}
        />
      </div>
      {/* Button sections */}
      <UserLinks user={user} />
    </div>
  );
};

const ExclusivesSection = (props) => {
  const {
    videoPlayMode,
    toggleViewContentModal,
    onProfileActionButtonClicked,
    user,
    showSkeleton,
    showSkeletonContent,
  } = props;
  return (
    <div className={`${videoPlayMode ? styles.top_container_hidden : ''}`}>
      <MobileProfileHeaderExclusives
        toggleViewContentModal={props.toggleViewContentModal}
        onShowSubscriptionClicked={() =>
          onProfileActionButtonClicked('subscription')
        }
        user={user}
        showSkeleton={showSkeleton || showSkeletonContent}
      />
    </div>
  );
};

const UserLinks = (props) => {
  const { user } = props;
  return (
    <Box p={3}>
      <Links User={user} />
    </Box>
  );
};
const ProfileTextsSection = (props) => {
  const {
    showSkeleton,
    userHandle,
    onCauseClicked,
    charityDisplay,
    userFullName,
    userTitle,
    isVerified,
  } = props;
  if (!showSkeleton) {
    return (
      <div>
        <anim.FadeInDiv>
          <div className={`${styles.Mobile_header_user_handle}`}>
            {userHandle}
          </div>
          <div
            className={`d-flex ${lclFlexRow()} justify-content-center align-items-center`}
          >
            <Box
              display={isVerified ? 'flex' : 'none'}
              width="24px"
              ml={1}
              mr={1}
              height="24px"
            >
              <img
                style={{ height: '24px', width: '24px' }}
                src={vlImages.vlImageVerifiedCheckmarkImage}
                alt="verified"
              />
            </Box>
            <div className={styles.Mobile_header_full_name} data-e2e="username">
              <Box>{userFullName}</Box>
            </div>
            <Box
              width="24px"
              ml={1}
              mr={1}
              height="24px"
              onClick={onCauseClicked}
              className={`${styles.charityIcon} ${charityDisplay}`}
            >
              <img
                style={{ height: '24px', width: '24px' }}
                alt="charity"
                id="charity-icon"
                src={vlImages.vlImageCauseHeart}
              ></img>
            </Box>
          </div>
          <Box mt={1}>
            <div className={styles.Mobile_header_user_title}>{userTitle}</div>
          </Box>
        </anim.FadeInDiv>
      </div>
    );
  } else {
    return (
      <SkeletonTheme color="#202020" highlightColor="#444">
        <div className="d-flex flex-column">
          <Skeleton
            width={120}
            height={20}
            style={{ borderRadius: '10px' }}
            className="mt-2"
          />
          <Skeleton
            width={120}
            height={20}
            style={{ borderRadius: '10px' }}
            className="mt-2"
          />
          <Skeleton
            width={120}
            height={20}
            style={{ borderRadius: '10px' }}
            className="mt-2"
          />
          <div className="d-flex flex-row justify-content-center">
            <Skeleton
              width={90}
              height={40}
              style={{ borderRadius: '20px' }}
              className="mt-2 mr-2 ml-2"
            />
            <Skeleton
              width={90}
              height={40}
              style={{ borderRadius: '20px' }}
              className="mt-2 mr-2 ml-2"
            />
            <Skeleton
              width={90}
              height={40}
              style={{ borderRadius: '20px' }}
              className="mt-2 mr-2 ml-2"
            />
          </div>
        </div>
      </SkeletonTheme>
    );
  }
};

const ProfilePictureView = (props) => {
  const {
    showSkeleton,
    imageLoaded,
    setImageLoaded,
    mainImage,
    user,
    videoReady,
    onShowProfileVideoClicked,
  } = props;
  if (showSkeleton) {
    return (
      <div className={`${styles.skeletonContainer}`}>
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton width={70} height={70} style={{ borderRadius: '16px' }} />
        </SkeletonTheme>
      </div>
    );
  }
  return (
    <anim.FadeInDiv className="w-100">
      <Box
        position="absolute"
        width="100%"
        top={-3}
        zIndex={0}
        display={videoReady && imageLoaded ? 'flex' : 'none'}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={BackgroundGradientSVG}
          alt="border"
          style={{
            width: '126px',
            height: '126px',
          }}
        />
      </Box>

      <div className={`position-relative ${styles.Mobile_header_user_image}`}>
        <anim.FadeInDiv className={imageLoaded ? '' : 'invisible'}>
          <Box>
            <img
              onClick={() => videoReady && onShowProfileVideoClicked()}
              style={{
                cursor: videoReady ? 'pointer' : 'none',
                transition: videoReady ? 'transform 0.15s ease-in-out' : 'none',
                '&:hover': {
                  transform: videoReady ? 'scale3d(1.05, 1.05, 1)' : 'none',
                },
              }}
              alt="main"
              src={mainImage}
              onLoad={() => setImageLoaded(true)}
            />
          </Box>
        </anim.FadeInDiv>
        <Box
          id="profile-play-button"
          className={videoReady && imageLoaded ? '' : 'invisible'}
          position="absolute"
          width="100%"
          bottom={-15}
          zIndex={10}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            style={{
              backdropFilter: 'blur(12px)',
              // '-webkit-filter': 'blur(12px)',
              // '-moz-filter': 'blur(12px)',
              // '-ms-filter': 'blur(12px)',
            }}
            width="32px"
            height="32px"
          >
            <FaPlay
              style={{ marginLeft: '1px', width: '12px', height: 'auto' }}
            />
          </Box>
        </Box>
      </div>
    </anim.FadeInDiv>
  );
};

const MobileProfileHeader = (props) => {
  const { setHideNavBar } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [videoReady, setVideoReady] = useState(false);
  const [videoPlayMode, setVideoPlayMode] = useState(false);
  const [videoInFront, setVideoInFront] = useState(false);
  const [rippleAnimationFinished, setRippleAnimationFinished] = useState(false);
  const [rippleReverseAnimation, setRippleReverseAnimation] = useState(false);
  const [showVideoButtons, setShowVideoButtons] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);

  let showSkeleton = props.showSkeleton;

  // utils.log.debug('loaded mobile profile header w/ props', props);

  const isSmallScreen = window.innerWidth < 1024;

  const user = props.user;

  if (!user) {
    return <div className="h-100 w-100 bg-warning"></div>;
  }

  const vlUser = new VLUser(user);
  // utils.log.debug('vlUser = ', vlUser);

  let mainImage = vlUser.profilePictureURL;
  const readyToDisplay = true;

  const userHandle = vlUser.handle();
  const userFullName = vlUser.fullName();
  const userTitle = vlUser.title;
  const isVerified = vlUser.verified;

  // console.log('userTitle', userTitle);

  //click logic

  const onCauseClicked = () => {
    props.onCauseClicked();
  };

  const onProfileActionButtonClicked = (actionType) => {
    // console.log('rth: ', actionType);
    props.onProfileActionButtonClicked(actionType);
  };

  const charityDisplay = vlUser.hasCause() ? '' : 'd-none';

  const toggleVideoMode = (value) => {
    if (value) {
      setRippleReverseAnimation(false);
      setTimeout(() => {
        setVideoInFront(true);
        setRippleAnimationFinished(true);
      }, 600);
      setVideoPlayMode(true);
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
      setHideNavBar(true);
    } else {
      setRippleReverseAnimation(true);
      setTimeout(() => {
        setRippleAnimationFinished(false);
        setRippleReverseAnimation(false);
        setVideoInFront(false);
        setVideoPlayMode(false);
        setShowVideoButtons(true);
        document.body.style.overflow = 'unset';
      }, 600);
      setHideNavBar(false);
    }
  };

  return (
    <>
      {!showSkeleton ? (
        <VideoComponent
          onVideoClicked={() => videoPlayMode && toggleVideoMode(false)}
          videoPlayMode={videoPlayMode}
          rippleAnimationFinished={rippleAnimationFinished}
          rippleReverseAnimation={rippleReverseAnimation}
          videoInFront={videoInFront}
          setShowVideoButtons={setShowVideoButtons}
          showVideoButtons={showVideoButtons}
          user={vlUser}
          setVideoReady={setVideoReady}
          videoReady={videoReady}
        />
      ) : null}
      <Container maxWidth="sm">
        <div
          id="mobile-header-top"
          className={`w-100 position-relative flex-column align-items-center ${styles.Mobile_header_top_main}`}
        >
          <Fade in={true} timeout={2000}>
            <div className="w-100">
              <Box pt={3}>
                <TopContainer
                  onShowProfileVideoClicked={() => toggleVideoMode(true)}
                  videoReady={videoReady}
                  isVerified={isVerified}
                  videoPlayMode={videoPlayMode}
                  showSkeleton={showSkeleton}
                  onProfileActionButtonClicked={onProfileActionButtonClicked}
                  imageLoaded={imageLoaded}
                  setImageLoaded={setImageLoaded}
                  mainImage={mainImage}
                  user={user}
                  userHandle={userHandle}
                  onCauseClicked={onCauseClicked}
                  charityDisplay={charityDisplay}
                  userFullName={userFullName}
                  userTitle={userTitle}
                />
                <ProfileActionButtonBoxContainer
                  user={user}
                  showSkeleton={showSkeleton}
                  videoPlayMode={videoPlayMode}
                  onProfileActionButtonClicked={onProfileActionButtonClicked}
                  showVideoButtons={showVideoButtons}
                  allowSubscribe={false}
                />
              </Box>
            </div>
          </Fade>
        </div>
      </Container>
    </>
  );
};

export default MobileProfileHeader;
