import React from "react";
import styles from "../AuthDialog.module.scss";

import { withTranslation } from 'react-i18next';
import { Image } from '../../components/image/image.component';
import currency from 'currency.js';

import PersonalContent from '../../assets/icons/icon_person.svg';
import BusinessContent from '../../assets/icons/icon_business.svg';
import Subscription from '../../assets/icons/icon_subscribers.svg';

class SetPricesBody extends React.Component<any, any>
{
  constructor(props: any) {
    super(props);

    this.state = {
      prices: {
        personal: null,
        business: null,
        subscription: null
      }
    }
  }

  onChange(name: string, price: string)
  {
    let processedPrice = currency(price, { precision: 0 });

    this.setState({
      prices: {
        [name]: `${processedPrice.intValue === 0 ? "FREE" : processedPrice.format()}`
      }
    })
  }

  /**
   * Updating price (sending to parent component that handling server update)
   * @param name
   * @param price
   */
  updatePrice(name: string, price: string)
  {
    let processedPrice = currency(price, { precision: 0 });
    this.props.onSetPrice(name, processedPrice.intValue);
  }

  render()
  {
    let { className } = this.props;
    let { prices } = this.state;

    let items = [
      {
        icon: PersonalContent,
        title: "Personal requests",
        desc: "Ornare arcu consequat suspendisse eu Ornare arcu consequat suspendisse eu ",
        key: "personal"
      },
      {
        icon: BusinessContent,
        title: "Business requests",
        desc: "Ornare arcu consequat suspendisse eu Ornare arcu consequat suspendisse eu ",
        key: "business"
      },
      {
        icon: Subscription,
        title: "Subscription",
        desc: "Ornare arcu consequat suspendisse eu Ornare arcu consequat suspendisse eu ",
        key: "subscription"
      }
    ];

    return <div className={className}>
      {items.map((item, index) => {

        return <section key={`price.${index}`}>
          <header>
            <div>
              <Image src={item.icon} />
              <span>{item.title}</span>
            </div>
            <p>{item.desc}</p>
          </header>
          <aside>
            <input tabIndex={index+1} type={"text"} placeholder={"$0"} value={prices[item.key]} onChange={event => this.onChange(item.key, event.target.value)} onBlur={event => this.updatePrice(item.key, event.target.value)} maxLength={6}/>
          </aside>
        </section>;

      })}
    </div>
  }
}

export default withTranslation()(SetPricesBody);
