import React, { useState, useEffect } from 'react';
import styles from './EarnCredit.module.scss';
import { useTranslation } from 'react-i18next';
import icon_copy from '../Images/icon_copy.svg';
import icon_share from '../Images/icon_share.svg';
import icon_share_mobile from '../Images/icon_share_mobile.svg';
import userManager from '../../../managers/userManager';
import { Link, Redirect } from 'react-router-dom';

const EarnCredit = (props) => {
  const { t, i18n } = useTranslation();
  const [copied, setCopied] = useState(false);

  const copyLink = () => {
    setCopied(true);

    //copy to clipboard
    var input = document.body.appendChild(document.createElement('input'));
    input.value = invitationUrl;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode.removeChild(input);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const { currentUserAccountDetails } = userManager;

  if (!currentUserAccountDetails) {
    return <Redirect to={'/activity'}></Redirect>;
  }
  const invitationCode = currentUserAccountDetails.invitationCode ?? null;
  const invitationUrl = currentUserAccountDetails.inviteURL();

  const doneButton = () => {
    return (
      <Link to={{ pathname: `/activity` }}>
        <div className={styles.button}>{t('earnCredit_done')}</div>
      </Link>
    );
  };

  const textSection = () => {
    const inputFieldDesktop = () => {
      return (
        <div
          className={`${styles.code} ${styles.codeDesktop} justify-content-between`}
        >
          {invitationUrl}
          {copied ? (
            <span onClick={copyLink} className={styles.copied}>
              {t('earnCredit_copied')}
            </span>
          ) : (
            <img src={icon_copy} onClick={copyLink} className={styles.copy} />
          )}
        </div>
      );
    };
    return (
      <div className={`flex-column d-flex ${styles.side}`}>
        <div className={styles.title}>{t('earnCredit_title')}</div>
        <div className={styles.subTitle}>{t('earnCredit_subTitle')}</div>
        <div className={styles.text}>{t('earnCredit_text')}</div>

        {inputFieldDesktop()}
      </div>
    );
  };

  const inputField = () => {
    return (
      <div
        onClick={copyLink}
        className={`${styles.code} ${styles.codeMobile} justify-content-between`}
      >
        {invitationUrl}{' '}
        {copied ? (
          <span onClick={copyLink} className={styles.copied}>
            {t('earnCredit_copied')}
          </span>
        ) : (
          <img src={icon_copy} onClick={copyLink} className={styles.copy} />
        )}
      </div>
    );
  };
  return (
    <div
      className={`d-flex ${
        i18n.language === 'he' || !i18n.language || i18n.language === 'global'
          ? `${styles.heb}`
          : 'justify-content-center'
      } ${styles.EarnCredit} ${
        props.removeBackgroundFromEarn ? styles.noBackground : ''
      }`}
    >
      <div
        className={`d-flex flex-wrap justify-content-between ${styles.sides}`}
      >
        <div
          className={`d-flex flex-wrap justify-content-between ${styles.wrapper}`}
        >
          {textSection()}
          <div className={`${styles.side} d-flex flex-column flex-wrap`}>
            <img
              onClick={copyLink}
              src={window.innerWidth < 1024 ? icon_share_mobile : icon_share}
              className={styles.img}
            />
            <div className={styles.referralTitle}>
              {t('earnCredit_your_referral')}
            </div>
            <div className={styles.referralCode}>{invitationCode}</div>
            {inputField()}
          </div>
        </div>
      </div>
      {doneButton()}
    </div>
  );
};
export default EarnCredit;
