import { useIntercom } from 'react-intercom-hook';
import * as utils from '../../utils/rhutils';

const IntercomBot = () => {
  const { boot, show } = useIntercom();
  utils.log.debug('IntercomBot booting...');
  if (window.location.pathname) {
    boot();
    console.log('📢 intercom booted');
  }
  if (window.location.search === '?contact-us=true') {
    show();
    console.log('📢 intercom shown');
  }
  utils.log.debug('IntercomBot booted...');
  return null;
};

export default IntercomBot;
