import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, TextField } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { OrderBlockWidget } from '../../Widgets/OrderBlockWidget';

import { useTranslation } from 'react-i18next';
import { updateNames } from '../../../../../store/reducers/ordering';
import { isHebrew } from '../../../../../App';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '-0.41px',
  },
  input: {
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.165px',
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid white`,
    },
  },
  labelRoot: {
    right: (props) => (props.isHebrew ? 0 : 'none'),
  },
  shrink: {
    transformOrigin: (props) => (props.isHebrew ? 'top right' : 'top left'),
  },
}));

const InputBox = (props) => {
  const { show, title, placeholder, helperText, inputRef, onBlur } = props;

  const classes = useStyles({ isHebrew: isHebrew() });

  return (
    <Box
      mb={2.5}
      width="100%"
      display={show ? 'flex' : 'none'}
      flexDirection="column"
      fontFamily="Assistant"
    >
      <Box m={1} textAlign="center" className={classes.title}>
        {title}
      </Box>
      <TextField
        defaultValue={props.default}
        inputRef={inputRef}
        onBlur={onBlur}
        onChange={props.onChange}
        required
        InputProps={{
          style: { fontWeight: 600, fontFamily: 'Assistant' },
          classes: {
            input: classes.input,
            underline: classes.underline,
          },
        }}
        InputLabelProps={{
          classes: { root: classes.labelRoot, shrink: classes.shrink },
          style: { color: 'rgba(255, 255, 255, 0.7)' },
        }}
        color="secondary"
        fullWidth={true}
        label={placeholder}
        helperText={helperText}
        FormHelperTextProps={{
          style: { color: 'white', fontFamily: 'Assistant' },
        }}
      />
    </Box>
  );
};
const OrderNameEntryBlock = (props) => {
  const {
    user,
    isBusiness,
    updateNames,
    forSomeoneElse,
    showStepError,
  } = props;
  const theirNameRef = useRef(null);
  const yourNameRef = useRef(null);
  const brandNameRef = useRef(null);
  const classes = useStyles();

  const onTextFieldBlur = () => {
    updateValues();
  };

  const onChange = () => {
    updateValues();
  };

  const updateValues = () => {
    const forWho = theirNameRef.current?.value;
    const fromWho = yourNameRef.current?.value;
    const businessName = brandNameRef.current?.value;
    const values = { forWho, fromWho, businessName };

    //dispatch
    updateNames(values);
  };

  const { t } = useTranslation();

  return (
    <OrderBlockWidget showError={showStepError}>
      <Box className={classes.root}>
        <Box width="100%" display="flex" flexDirection="column" mt={2}>
          <InputBox
            onChange={onChange}
            onBlur={onTextFieldBlur}
            inputRef={theirNameRef}
            show={!isBusiness && forSomeoneElse === true}
            title={t('rcs_their_name')}
            placeholder={t('enter_their_name')}
          />
          <InputBox
            onChange={onChange}
            onBlur={onTextFieldBlur}
            inputRef={yourNameRef}
            show={!isBusiness}
            title={t('rcs_your_name')}
            placeholder={t('enter_your_name')}
          />
          <InputBox
            onChange={onChange}
            onBlur={onTextFieldBlur}
            inputRef={brandNameRef}
            show={isBusiness}
            title={t('enter_business_name')}
            placeholder={t('rcs_business_name_2')}
          />
        </Box>
      </Box>
    </OrderBlockWidget>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    user: state.user.details,
    isBusiness: state.ordering.type === 'business',
    forSomeoneElse: state.ordering.selectedEntity === 'someone else',
    showStepError: state.ordering.errors.names === true,
  };
};

export default connect(mapStateToProps, { updateNames })(OrderNameEntryBlock);
