import React, { useState, useRef, useEffect } from 'react';

import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#231942',
    borderRadius: '32px',
    [theme.breakpoints.down('xs')]: {
      color: '#121029',
      borderRadius: '0px',
    },
  },
  rootNoBackground: {
    borderRadius: '32px',
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0px',
    },
  },
  error: {
    border: '1px solid #DF1338',
  },
}));

export const OrderBlockWidget = (props) => {
  const { hideBackground, noSpacing, hidden, showError } = props;
  const classes = useStyles();
  const errorClass = showError ? classes.error : '';
  return (
    <Box
      display={hidden ? 'none' : 'block'}
      className={`${
        hideBackground ? classes.rootNoBackground : classes.root
      } ${errorClass}`}
      p={noSpacing ? 0 : 1.5}
      mt={2}
      mb={2}
    >
      <Container disableGutters={noSpacing}>{props.children}</Container>
    </Box>
  );
};
