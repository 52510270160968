import React, { useState } from 'react';
import styles from './SignIn.module.scss';
import { Alert, Button, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { SIGN_UP } from '../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { sendVerificationCode, signUp } from '../../store/routines';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { RootState } from '../../store';
import { analytics, AnalyticsEvent } from '../../utils/analytics';
import userManager from '../../managers/userManager';
import PageLoader from '../../components/pageLoader';

interface SignInProps {
  onSignUpClick?: any;
  onSubmit?: any;
  onError?: any;
}

/**
 * Loading component wrapper
 */
const LoadingView = (props: any) => {
  return props.showLoading ? (
    <div className="fixed-top w-100 h-100">
      <PageLoader darkOpacity={true} withBackground={true} />
    </div>
  ) : null;
};

let analyticsSent = false;

const SignIn: React.FC<SignInProps> = ({
  onSignUpClick,
  onSubmit,
  onError,
}) => {
  const { t, i18n } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required(t('required_phone'))
      .test(
        'valid_phone',
        t('required_phone'),
        (value) => !!value && isValidPhoneNumber(value)
      ),
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, confirmResult, error } = useSelector<RootState, any>(
    (state) => state.auth.signIn
  );

  const handleSignUpClick = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(signUp());
      if (onSignUpClick) {
        onSignUpClick();
        return;
      }
      history.push(SIGN_UP);
    },
    [dispatch, history, onSignUpClick]
  );

  const { values, errors, handleSubmit, isValid, setFieldValue } = useFormik<{
    phone: string;
  }>({
    initialValues: {
      phone: '',
    },
    validationSchema,
    validateOnChange: false,
    onSubmit({ phone }) {
      // console.log('start signup with phone ', phone);
      onSignInPressed();
    },
  });

  const setErrorMsg = (error: string) => {
    onError(error);
  };

  const onSignInPressed = () => {
    setShowLoading(true);
    userManager.startSignup(values).then((sentSMS) => {
      setShowLoading(false);
      if (sentSMS) {
        onSubmit();
      } else {
        setErrorMsg(t('signup_failed_contact_suport'));
      }
    });
  };
  const handleKeyPress = React.useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  if (!analyticsSent) {
    analytics.event(new AnalyticsEvent('signin - verify'));
    analyticsSent = true;
  }

  const BottomButton = () => {
    return (
      <div data-e2e="signIn_bottom" className={classNames(styles.bottom, '')}>
        {t('not_a_member')}&nbsp;
        <a href="" onClick={handleSignUpClick}>
          {t('sign_up')}
        </a>
      </div>
    );
  };

  const isHebrew = i18n.language === 'he';

  const Title = () => {
    return (
      <h1
        data-e2e="login_title"
        className={classNames(styles.title, 'position-relative', {
          [styles.rtl]: isHebrew,
        })}
      >
        {t('log_in')}
      </h1>
    );
  };

  const Detail = () => {
    return (
      <p data-e2e="login_subtitle" className={styles.subtitle}>
        {t('glad_to_see_you')}
      </p>
    );
  };

  const ErrorAlert = () => {
    return error && error.length > 0 ? (
      <Alert variant="danger">{error.message}</Alert>
    ) : null;
  };

  const SubmitButton = () => {
    const isValid = isValidPhoneNumber(values.phone);
    return (
      <div className="text-center">
        <Button
          data-e2e="signIn_button"
          type="submit"
          className={classNames(
            styles.button,
            'w-100',
            'mt-4',
            'px-3',
            'py-3',
            isValid ? '' : 'opacity-3'
          )}
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          {loading ? (
            <div className={styles.loader}>
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : (
            t('send_verification_code')
          )}
        </Button>
      </div>
    );
  };
  return (
    <div className="position-relative h-100">
      <Title />
      <Detail />
      <ErrorAlert />
      <Form.Group controlId="phone">
        <PhoneInput
          className={classNames('form-control d-flex mt-4', styles.input, {
            'is-invalid': !!errors.phone,
            rtl: i18n.language === 'he',
          })}
          value={values.phone}
          defaultCountry={i18n.language === 'he' ? 'IL' : 'US'}
          placeholder={t('enter_phone_number_placeholder')}
          aria-label={t('enter_phone_number_placeholder')}
          onKeyPress={handleKeyPress}
          disabled={loading}
          autoFocus
          data-e2e="phone_number"
          onChange={(value) => setFieldValue('phone', value)}
        />
        <Form.Control.Feedback type="invalid">
          {errors.phone}
        </Form.Control.Feedback>
      </Form.Group>
      <SubmitButton />
      <BottomButton />
      <LoadingView showLoading={showLoading} />
      <div id="recaptcha-hidden-container" />
    </div>
  );
};

export default SignIn;
