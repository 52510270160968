export const LANDING = '/';
export const LANDING_V2 = '/welcome-home';
export const LANDING_BUSINESS = '/for-business';
export const PROFILE_LANDING = '/:profile_id';
export const PROFILE_LANDING_PAYMENT = '/:profile_id/payment';

export const WEB_APP = '/web';
export const WEB_SITE = '/hello/';
export const WEB_SITE_IL = '/israel/';
export const WEB_SITE_IL_WEB_APP = '/israel/web';

export const CLOSE_CIRCLE = '/closeCircle/:id';
export const CLOSE_CIRCLE_FROM_APP = '/closeCircle/:profileId/token/:tokenId';
export const ADD_CARD = '/addCard';
export const PAY_WALL = '/paywall';
export const CHECKOUT = '/checkout/';
export const PROFILE = '/p/:id';
export const EDIT_PROFILE = '/editProfile';
export const EDIT_PROFILE_NOT_LOGIN = '/editProfile';
export const EDIT_PROFILE_ACCOUNT = '/editProfile?showScreen=editAccount';

export const POST = '/post.html';
export const ACCOUNT_DETAILS = '/accountDetails/:tokenId';
export const CREATIONS_REQUEST = '/personalized/:profileId';
export const CREATIONS_REQUEST_SHORT = '/old_ask/:profileId';
export const CREATIONS_REQUEST_SHORT2 = '/ask/:profileId';
export const CREATIONS_REQUEST_SHORT_REFERRAL = '/ask/:profileId/:referralId';
export const CREATIONS_REQUEST_REFERRAL =
  '/personalized/:profileId/:referralId';
export const BUSINESS_REQUEST = '/business/:profileId';
export const BUSINESS_REQUEST_REFERRAL = '/business/:profileId/:referralId';
export const BUSINESS_REQUEST_REFERRAL_SHORT = '/biz/:profileId';
export const BUSINESS_REQUEST_REFERRAL_SHORT_REFERRAL =
  '/biz/:profileId/:referralId';
export const BUSINESS_REQUEST_FROM_IOS = '/business/*';
export const BOOKING_GENERAL = '/booking/:profileId';
export const BOOKING_WITH_ITEM = '/booking/:profileId/:itemId';
export const SUBSCRIPTION = '/subscribe';
export const SUBSCRIPTION_PROFILE = '/subscribe/:profileId';

export const PROFILE_GOTO = '/:profileId?goto=';

export const CATEGORY = '/creatorCategory/:categoryId';
export const CATEGORY_MABROOK = '/mabrook';
export const CATEGORY_BUSINESS = '/business';
export const CATEGORY_TIKTOK = '/tiktok';
export const CATEGORY_TIKTOK_IL = '/tiktokil';
export const CATEGORY_TIKTOK_IL_2 = '/tiktokfyp';
export const CATEGORY_TIKTOK_IL_3 = '/foryourpage';

/* CAMPAIGNS */
export const CAMPAIGN_BOOKING_HOME = '/campaign';

export const PROFILE_PAGE = '/profilev2/:profileId';
export const EDIT_PROFILE_PAGE = '/editProfile/:profileId';
export const PAGE_404 = '/ghostpage';

/* New category screen */
export const CATEGORY_CREATOR = '/category/:categoryId';
export const CATEGORY_CREATOR_EMPTY = '/category';

/* SESSION */
export const SESSION_PLAYER = '/watch/:sessionId';
export const SESSION_FROM_APP_WITH_TOKEN = '/session/:sessionId/token/:tokenId';
export const SESSION_NO_AUTH = '/session/:sessionId';
export const SESSION_FROM_APP = '/s/:sessionId';
export const NEW_SESSION = '/createSession/';
export const NEW_SESSION_SHORT = '/new/';
export const SESSION_DASHBOARD = '/dashboard/:sessionId';
export const ADMIN_DASHBOARD = '/admin';
export const USER_DASHBOARD = '/userOverview/';

export const CONFIRMATION = '/confirmation';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LOG_OUT = '/logout';
export const HOME = '/home';
export const DISCOVER = '/discover';
export const ACCOUNT_TOKEN = '/account/:token';
export const ACTIVITY = '/activity';
export const ACCOUNT_AUTHED = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const VIEW_DELIVERY_SCREEN = '/transaction/:id'; //t for transaction
export const VIEW_POST = '/vpv/:postId'; //t for transaction
export const RATE_DELIVERY = '/rate/:transactionId';
export const QUICK_SIGN_UP = '/newCreator';
export const INVITE = '/join';
export const AUTH_CONNECT = '/connect';
export const FINISH_CONNECT = '/finishConnect';

export const GET_APP = '/get';

export const INVITATION = '/i/:invite_id';

export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const CREATOR_PAYOUT_PROFILE = '/creatorPayout/:profileId';
export const CREATOR_PAYOUT = '/creatorPayout';
export const CONTACT_US = '/?contact-us=true';

export const UPLOAD_CONTENT = '/upload-content';
export const SETTINGS = '/settings';

export const BASE_PROFILE_HOME = '/base/:profileId';

//CLAIMINGS
export const CLAIM_ACCOUNT = '/claim';

//CHALLENGES
export const CHALLENGE_DETAIL = '/challenges/:challengeId';

//OFFERS
export const OFFER_UNLOCK_100_PERCENT_EARNINGS = '/unlock-100-percent-earnings';

//ONBOARDING
export const ONBOARDING = '/onboarding';

//SEARCH
export const SEARCH = '/search';
