import { type } from 'os';
import { store } from '../../store';
import { LogEvent } from '../../utils/amplitude';
import { analytics, AnalyticsEvent } from '../../utils/analytics';

export interface AnalyticsEvents {
  eventName: string;
  getProperty(): Map<string, string>;
}

const MAIN_PREFIX = 'web - flow - ';

export function getEventName(flow: string, step: string): string {
  return `${MAIN_PREFIX}${flow} - ${step}`;
}

//Home flow
class BaseHomeEvent implements AnalyticsEvents {
  eventName = '';
  getProperty() {
    let map = new Map<string, string>();
    return map;
  }
}
export class MainHomeEvent extends BaseHomeEvent {
  constructor() {
    super();
    this.eventName = getEventName('home', 'landing');
  }
}

//Home flow
class ProfileViewEvent implements AnalyticsEvents {
  eventName = '';
  creator_profile_id: string;
  constructor(creator_profile_id: string) {
    this.creator_profile_id = creator_profile_id;
  }
  getProperty() {
    let map = new Map<string, string>();
    map.set('creator_profile_id', this.creator_profile_id!);
    return map;
  }
}
export class MainProfileViewEvent extends ProfileViewEvent {
  constructor(creator_profile_id: string) {
    super(creator_profile_id);
    this.eventName = getEventName('profile view', 'landing');
  }
}

//Auth flow events
class BaseAuthEvent implements AnalyticsEvents {
  eventName = '';
  kind?: string;
  constructor(kind?: string) {
    this.kind = kind;
  }
  getProperty() {
    let map = new Map<string, string>();
    if (this.kind) {
      map.set('kind', this.kind!);
    }
    return map;
  }
}

export class AuthLanding extends BaseAuthEvent {
  constructor() {
    super();
    this.eventName = getEventName('auth', 'landing');
  }
}
export class AuthStart extends BaseAuthEvent {
  constructor(kind?: string) {
    super();
    this.kind = kind;
    this.eventName = getEventName('auth', 'start');
  }
}
export class AuthVerified extends BaseAuthEvent {
  constructor(kind?: string) {
    super();
    this.kind = kind;
    this.eventName = getEventName('auth', 'verified');
  }
}
export class AuthCompleted extends BaseAuthEvent {
  constructor(kind?: string) {
    super();
    this.kind = kind;
    this.eventName = getEventName('auth', 'completed');
  }
}
export class AuthFailure extends BaseAuthEvent {
  constructor(kind?: string) {
    super();
    this.kind = kind;
    this.eventName = getEventName('auth', 'failure');
  }
}

//Add payment method flow
class BaseAddPaymentMethodEvent implements AnalyticsEvents {
  eventName = '';
  constructor() {}
  getProperty() {
    let map = new Map<string, string>();
    return map;
  }
}

export class AddPaymentMethodLanding extends BaseAddPaymentMethodEvent {
  constructor() {
    super();
    this.eventName = getEventName('add payment method', 'landing');
  }
}
export class AddPaymentMethodStart extends BaseAddPaymentMethodEvent {
  constructor() {
    super();
    this.eventName = getEventName('add payment method', 'start');
  }
}
export class AddPaymentMethodVerified extends BaseAddPaymentMethodEvent {
  constructor() {
    super();
    this.eventName = getEventName('add payment method', 'verified');
  }
}
export class AddPaymentMethodCompleted extends BaseAddPaymentMethodEvent {
  constructor() {
    super();
    this.eventName = getEventName('add payment method', 'completed');
  }
}
export class AddPaymentMethodFailure extends BaseAddPaymentMethodEvent {
  constructor() {
    super();
    this.eventName = getEventName('add payment method', 'failure');
  }
}

//Booking events
interface BaseBookingEventInterface {
  creatorProfileId?: string;
  occasionNumber?: string;
  price?: string;
  currency?: string;
  creatorId?: string;
}

type RequestType = 'for self' | 'for someone else' | 'business';

//Booking Events
class BaseBookingEvent implements AnalyticsEvents, BaseBookingEventInterface {
  eventName = '';

  creator_profile_id?: string;
  creator_id?: string;
  request_type?: RequestType;
  ocassion_number?: string;
  price?: string;
  currency?: string;

  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    this.creator_profile_id = creator_profile_id;
    this.creator_id = creator_id;
    this.request_type = request_type;
    this.ocassion_number = ocassion_number;
    this.price = price;
    this.currency = currency;
  }

  getProperty() {
    let map = new Map<string, string>();
    if (this.creator_profile_id) {
      map.set('creator_profile_id', this.creator_profile_id!);
      //deprecated suppport
      map.set('name', this.creator_profile_id!);
    }
    if (this.creator_id) {
      map.set('creator_id', this.creator_id!);
    }
    if (this.request_type) {
      map.set('request_type', String(this.request_type!));
    }
    if (this.ocassion_number) {
      map.set('ocassion_number', String(this.ocassion_number!));
    }
    if (this.price) {
      map.set('price', this.price!);
    }
    if (this.currency) {
      map.set('currency', this.currency!);
    }
    return map;
  }
}

export class BookingLanding extends BaseBookingEvent {
  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    super(
      creator_profile_id,
      creator_id,
      request_type,
      ocassion_number,
      price,
      currency
    );
    this.eventName = getEventName('booking', 'landing');
  }
}

export class BookingStarted extends BaseBookingEvent {
  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    super(
      creator_profile_id,
      creator_id,
      request_type,
      ocassion_number,
      price,
      currency
    );
    this.eventName = getEventName('booking', 'started');
  }
}
export class BookingSelectedOcassion extends BaseBookingEvent {
  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    super(
      creator_profile_id,
      creator_id,
      request_type,
      ocassion_number,
      price,
      currency
    );
    this.eventName = getEventName('booking', 'selected occasion');
  }
}
export class BookingDescriptionConfirmed extends BaseBookingEvent {
  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    super(
      creator_profile_id,
      creator_id,
      request_type,
      ocassion_number,
      price,
      currency
    );
    this.eventName = getEventName('booking', 'description confirmed');
  }
}
export class BookingPriceConfirmed extends BaseBookingEvent {
  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    super(
      creator_profile_id,
      creator_id,
      request_type,
      ocassion_number,
      price,
      currency
    );
    this.eventName = getEventName('booking', 'price confirmed');
  }
}
export class BookingAttemptingSubmit extends BaseBookingEvent {
  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    super(
      creator_profile_id,
      creator_id,
      request_type,
      ocassion_number,
      price,
      currency
    );
    this.eventName = getEventName('booking', 'submit order');
  }
}
export class BookingShowingPaywall extends BaseBookingEvent {
  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    super(
      creator_profile_id,
      creator_id,
      request_type,
      ocassion_number,
      price,
      currency
    );
    this.eventName = getEventName('booking', 'showing paywall');
  }
}
export class BookingSubmitting extends BaseBookingEvent {
  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    super(
      creator_profile_id,
      creator_id,
      request_type,
      ocassion_number,
      price,
      currency
    );
    this.eventName = getEventName('booking', 'submitting order');
  }
}
export class BookingCompleted extends BaseBookingEvent {
  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    super(
      creator_profile_id,
      creator_id,
      request_type,
      ocassion_number,
      price,
      currency
    );
    this.eventName = getEventName('booking', 'completed');
  }
}
export class BookingFailure extends BaseBookingEvent {
  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    super(
      creator_profile_id,
      creator_id,
      request_type,
      ocassion_number,
      price,
      currency
    );
    this.eventName = getEventName('booking', 'landing');
  }
}
//Booking v2 - (ordering)
export class BookingLandingV2 extends BaseBookingEvent {
  constructor(
    creator_profile_id?: string,
    creator_id?: string,
    request_type?: RequestType,
    ocassion_number?: string,
    price?: string,
    currency?: string
  ) {
    super(
      creator_profile_id,
      creator_id,
      request_type,
      ocassion_number,
      price,
      currency
    );
    this.eventName = getEventName('booking - v2', 'landing');
  }
}

//Subscription events
interface BaseSubscriptionEventInterface {
  name?: string; //creator profile id
  price?: number;
  currency?: string;
  creator_id?: string;
  creator_profile_id?: string;
  charge_type?: string;
}
//Subscription events
class BaseSubscriptionEvent
  implements AnalyticsEvents, BaseSubscriptionEventInterface {
  eventName = '';

  name?: string; //creator profile id
  price?: number;
  currency?: string;
  creator_id?: string;
  creator_profile_id?: string;
  charge_type?: string;
  constructor(
    name?: string,
    price?: number,
    currency?: string,
    creator_id?: string,
    creator_profile_id?: string,
    charge_type?: string
  ) {
    this.name = name;
    this.price = price;
    this.currency = currency;
    this.creator_id = creator_id;
    this.creator_profile_id = creator_profile_id;
    this.charge_type = charge_type;
  }

  getProperty() {
    let map = new Map<string, string>();
    if (this.name) {
      map.set('name', this.name!);
    }
    if (this.price) {
      map.set('price', String(this.price!));
    }
    if (this.currency) {
      map.set('currency', this.currency!);
    }
    if (this.creator_id) {
      map.set('creator_id', this.creator_id!);
    }
    if (this.creator_profile_id) {
      map.set('creator_profile_id', this.creator_profile_id!);
    }
    if (this.charge_type) {
      map.set('charge_type', this.charge_type!);
    }
    return map;
  }
}

export class SubscriptionLanding extends BaseSubscriptionEvent {
  constructor(
    name?: string,
    price?: number,
    currency?: string,
    creator_id?: string,
    creator_profile_id?: string,
    charge_type?: string
  ) {
    super(name, price, currency, creator_id, creator_profile_id, charge_type);
    this.eventName = getEventName('subscription', 'landing');
  }
}
export class SubscriptionPaywallAppear extends BaseSubscriptionEvent {
  constructor(
    name?: string,
    price?: number,
    currency?: string,
    creator_id?: string,
    creator_profile_id?: string,
    charge_type?: string
  ) {
    super(name, price, currency, creator_id, creator_profile_id, charge_type);
    this.eventName = getEventName('subscription', 'paywall');
  }
}
export class SubscriptionAttemptingSubmit extends BaseSubscriptionEvent {
  constructor(
    name?: string,
    price?: number,
    currency?: string,
    creator_id?: string,
    creator_profile_id?: string,
    charge_type?: string
  ) {
    super(name, price, currency, creator_id, creator_profile_id, charge_type);
    this.eventName = getEventName('subscription', 'attempting submit');
  }
}
export class SubscriptionSubmitting extends BaseSubscriptionEvent {
  constructor(
    name?: string,
    price?: number,
    currency?: string,
    creator_id?: string,
    creator_profile_id?: string,
    charge_type?: string
  ) {
    super(name, price, currency, creator_id, creator_profile_id, charge_type);
    this.eventName = getEventName('subscription', 'submitting');
  }
}
export class SubscriptionCompleted extends BaseSubscriptionEvent {
  constructor(
    name?: string,
    price?: number,
    currency?: string,
    creator_id?: string,
    creator_profile_id?: string,
    charge_type?: string
  ) {
    super(name, price, currency, creator_id, creator_profile_id, charge_type);
    this.eventName = getEventName('subscription', 'completed');
  }
}
export class SubscriptionFailure extends BaseSubscriptionEvent {
  constructor(
    name?: string,
    price?: number,
    currency?: string,
    creator_id?: string,
    creator_profile_id?: string,
    charge_type?: string
  ) {
    super(name, price, currency, creator_id, creator_profile_id, charge_type);
    this.eventName = getEventName('subscription', 'failure');
  }
}

//Generic flow events
//1. Newsletter signup collection

class BaseLandingPageEvent implements AnalyticsEvents {
  eventName = '';
  ctaType?: string;

  constructor(ctaType?: string) {
    this.ctaType = ctaType;
  }

  getProperty() {
    let map = new Map<string, string>();
    return map;
  }
}

export class LandingPageStart extends BaseLandingPageEvent {
  constructor() {
    super();
    this.eventName = getEventName('landing', 'start');
  }
}

export class LandingPageCTAPressed extends BaseLandingPageEvent {
  constructor(ctaType?: string) {
    super(ctaType);
    this.eventName = getEventName('landing', 'cta');
  }

  getProperty() {
    let map = new Map<string, string>();
    if (this.ctaType) map.set('ctaType', this.ctaType!);
    return map;
  }
}

export class SignupToNewsLetter implements BaseLandingPageEvent {
  eventName = 'flow - web - landing - newsletter signup';
  email: string;
  constructor(email: string) {
    this.email = email;
  }
  getProperty() {
    let map = new Map<string, string>();
    map.set('email', this.email!);
    return map;
  }
}

export default class VLFlowManager {
  constructor() {}
  //Main functions
  static sendFlowAnalytics(event: AnalyticsEvents) {
    // console.log(`v2 - logging event - ${event.eventName}`, event.getProperty());
    let properties = event.getProperty();

    var sanitized: any[] = [];
    var obj = {};

    properties.forEach((value: string, key: string) => {
      // console.log(key, value);
      //@ts-ignore
      obj[key] = value;
      sanitized.push(obj);
    });

    analytics.event(new AnalyticsEvent(event.eventName, sanitized));
  }
}

//Campaign Events
interface BaseCampaignEventInterface {
  type?: 'personal' | 'business';
  occasion?: number;
  occassion_title?: string;
  category_id?: string;
  selected_creators?: number;
  business_name?: string;
  from_who?: string;
  for_who?: string;
  brief?: string;
}
class BaseCampaignEvent implements AnalyticsEvents, BaseCampaignEventInterface {
  eventName = '';

  type?: 'personal' | 'business';
  occasion?: number;
  occassion_title?: string;
  category_id?: string;
  selected_creators?: number;
  business_name?: string;
  from_who?: string;
  for_who?: string;
  brief?: string;

  constructor() {
    const type = store.getState().campaign.type;
    const occasion = store.getState().campaign.occasion_value;
    const occassion_title = store.getState().campaign.occasion;
    const category_id = store.getState().campaign.selectedCategory?.id;
    const selected_creators = store.getState().campaign.selectedCreators.length;
    const business_name = store.getState().campaign.businessName;
    const from_who = store.getState().campaign.fromWho;
    const for_who = store.getState().campaign.forWho;
    const brief = store.getState().campaign.brief;

    this.type = type;
    this.occasion = occasion;
    this.occassion_title = occassion_title;
    this.category_id = category_id;
    this.selected_creators = selected_creators;
    this.business_name = business_name;
    this.from_who = from_who;
    this.for_who = for_who;
    this.brief = brief;
  }

  getProperty() {
    let map = new Map<string, string>();
    if (this.type) map.set('type', this.type);
    if (this.occasion) map.set('occasion', String(this.occasion));
    if (this.occassion_title)
      map.set('occassion_title', String(this.occassion_title));
    if (this.category_id) map.set('category_id', this.category_id);
    if (this.selected_creators)
      map.set('selected_creators', String(this.selected_creators));
    if (this.business_name) map.set('business_name', this.business_name);
    if (this.from_who) map.set('from_who', this.from_who);
    if (this.for_who) map.set('for_who', this.for_who);
    if (this.brief) map.set('brief', this.brief);
    return map;
  }
}

export class CampaignFlowEventLanding extends BaseCampaignEvent {
  constructor() {
    super();
    this.eventName = getEventName('campaign', 'landing');
  }
}

export class CampaignFlowEventTypeSelection extends BaseCampaignEvent {
  constructor() {
    super();
    this.eventName = getEventName('campaign', 'type selection');
  }
}

export class CampaignFlowEventOccassionSelect extends BaseCampaignEvent {
  constructor() {
    super();
    this.eventName = getEventName('campaign', 'occasion select');
  }
}

export class CampaignFlowEventCategorySelect extends BaseCampaignEvent {
  constructor() {
    super();
    this.eventName = getEventName('campaign', 'category select');
  }
}

export class CampaignFlowEventCreatorSelect extends BaseCampaignEvent {
  constructor() {
    super();
    this.eventName = getEventName('campaign', 'creator select');
  }
}

export class CampaignFlowEventBriefEntry extends BaseCampaignEvent {
  constructor() {
    super();
    this.eventName = getEventName('campaign', 'brief entry');
  }
}

export class CampaignFlowEventSummary extends BaseCampaignEvent {
  constructor() {
    super();
    this.eventName = getEventName('campaign', 'summary');
  }
}

export class CampaignFlowEventSubmission extends BaseCampaignEvent {
  constructor() {
    super();
    this.eventName = getEventName('campaign', 'submission screen');
  }
}

export class CampaignFlowEventSuccess extends BaseCampaignEvent {
  constructor() {
    super();
    this.eventName = getEventName('campaign', 'success screen');
  }
}

export class CampaignFlowEventFailure extends BaseCampaignEvent {
  constructor() {
    super();
    this.eventName = getEventName('campaign', 'submission failure');
  }
}

//Campaign Events
interface BaseOnboardingEventInterface {
  type?: 'consumer' | 'creator';
  selected_creators?: number;
}

//Onboardinfg
class BaseOnboardingEvent
  implements AnalyticsEvents, BaseOnboardingEventInterface {
  eventName = '';

  type?: 'consumer' | 'creator';
  selected_creators?: number;

  constructor() {
    const type = store.getState().onboarding.onboardingType;
    const selected_creators = store.getState().onboarding.selectedCreators
      .length;

    this.type = type;
    this.selected_creators = selected_creators;
  }

  getProperty() {
    let map = new Map<string, string>();
    if (this.type) map.set('type', this.type);
    if (this.selected_creators)
      map.set('selected_creators', String(this.selected_creators));

    return map;
  }
}

export class OnboardingFlowEventWelcome extends BaseOnboardingEvent {
  constructor() {
    super();
    this.eventName = getEventName('onboarding', 'welcome');
  }
}

export class OnboardingFlowEventExplainers extends BaseOnboardingEvent {
  constructor() {
    super();
    this.eventName = getEventName('onboarding', 'explainers');
  }
}

export class OnboardingFlowEventEditProfile extends BaseOnboardingEvent {
  constructor() {
    super();
    this.eventName = getEventName('onboarding', 'edit profile');
  }
}

export class OnboardingFlowEventInterests extends BaseOnboardingEvent {
  constructor() {
    super();
    this.eventName = getEventName('onboarding', 'interests');
  }
}

export class OnboardingFlowEventSelectCreators extends BaseOnboardingEvent {
  constructor() {
    super();
    this.eventName = getEventName('onboarding', 'select creators');
  }
}

export class OnboardingFlowEventVerifyProfile extends BaseOnboardingEvent {
  constructor() {
    super();
    this.eventName = getEventName('onboarding', 'verify profile');
  }
}

export class OnboardingFlowEventCompletionScreen extends BaseOnboardingEvent {
  constructor() {
    super();
    this.eventName = getEventName('onboarding', 'completion screen');
  }
}
