import dbManager from "../managers/dbManager";
import { parse, lclString } from "../utils/vlStrings";
import VLUser from "./vlUser";
import * as utils from "../utils/rhutils";
import * as constants from "../constants/rhconstants";
import memoize from 'lodash/memoize'

const VLSessionStatus = Object.freeze({
  AWAITING_SUBMISSION: -1, //awaiting to be submitted (should be local only)
  AWAITING_SESSION_APPROVAL: 0, //awaiting for review/decision by moderators
  DECLINED: 1, //declined by moderators
  INCOMPLETE: 2, //the session is missing data
  APPROVED: 3, //the session is missing data
  IN_PROGRESS: 5, // is isfundraising
  CANCELLED: 6, //approved and waiting for fundraise
  AWAITING_SUBMISSION_APPROVAL: 10, //means they're submissin URL is awaiting to be approved
  AWAITING_RELEASE: 11, //means they've approved and not yet release
  LIVE: 15, //they are now delivering the content
  COMPLETED: 20, //the content has been delivered and will no longer be available
  DID_NOT_FUNDRAISE: 25, //did not reach their goal amount
  ARCHIVED: 35, //archived
});

class Session {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }
  id = "";
  userId = null;

  /* Dates */
  createdOn = null;
  lastUpdated = null;
  startFundraiseDate = null;
  endFundraiseDate = null;
  releaseDate = null;

  /* Numbers */
  spectatorGoal = 0;
  currentRegisteredSpectators = 0;
  currentAmountFundraised = 0;

  /* Info */
  title = "";
  desc = "";

  user = null;

  //For Review
  sessionSubmissionVideoURL = null;
  //Preview
  previewVideoURL = null;
  //Actual
  sessionVideoURL = null;

  currentEarnings = 0;
  totalEarnings = 0;
  totalPurchases = 0;

  maxPreviewDuration = 60.0;

  /**
   * @return Boolean whether or not the preview video is the actual full video
   */
  isPreviewingFullSubmissionVideo() {
    if (
      this.sessionVideoURL !== null &&
      utils.isValidUrl(this.sessionVideoURL)
    ) {
      return true;
    } else {
      return false;
    }
  }

  maxSessionPreviewDuration() {
    if (this.maxPreviewDuration > 0) {
      return this.maxPreviewDuration;
    } else {
      return constants.MAX_SESSION_PREVIEW_DURATION;
    }
  }

  /**
   * Checks if the current user can even purchase this session
   */
  canPurchase() {
    if (this.isPurchased()) {
      return false;
    }

    if (this.hasSpotLimit()) {
      return (
        this.hasStartedFundraising() &&
        this.currentGoalPercentageSpectators() < 1.0
      );
    } else {
      //on demand - no limit, make sure its status is live and there a video submission url
      return (
        this.status == VLSessionStatus.LIVE && this.sessionVideoURL != null
      );
    }
  }

  /**
   * Checks if the current user can even purchase this session
   */
  canWatch() {
    //make sure the session url exists
    if (!this.sessionVideoURL) {
      return false;
    }

    //make sure the session properties are valid
    if (this.status !== VLSessionStatus.LIVE) {
      return false;
    }

    //current user is a subscriber..allow watch
    if (this.isCloseFan()) {
      return true;
    }

    return this.sessionAccess() != null && this.sessionAccess().valid;
  }

  isCloseFan() {
    if (!utils.currentUser()) {
      return null;
    }
    let currentUserId = utils.currentUser().id;
    const subscriptionsCollection = dbManager.getCollection(
      constants.LOCAL_DB_COLLECTION_SUBSCRIPTIONS
    );
    if (!subscriptionsCollection) {
      return null;
    }
    var subscription = subscriptionsCollection.findOne({
      creatorId: this.userId,
      userId: currentUserId,
      active: true,
    });
    utils.log.debug("found session subscription...", subscription);
    return subscription;
  }

  actionButtonTitle() {
    if (this.isPurchased()) {
      return this.canWatch()
        ? lclString("watch_exclusively_now")
        : lclString("exclusive_access_available_soon");
    } else if (this.canPurchase()) {
      return parse(
        lclString("view_session_purchase_button"),
        this.priceString()
      );
    }
  }

  /**
   * Check if the session has been purchased by the current user
   * @returns True if the the current user has access to this session (either purchased, promotion, or close member subscription)
   */
  isPurchased() {
    let sa = this.sessionAccess();
    if (this.isCloseFan()) {
      return true;
    }
    if (!sa) {
      return false;
    }
    if (sa.valid) {
      return true;
    }
    return false;
  }

  /**
   * @returns the local session access object fetched from the server
   */
  sessionAccess() {
    if (!utils.currentUser()) {
      return null;
    }
    let currentUserId = utils.currentUser().id;
    const purchasedSesssionAccessCollection = dbManager.getCollection(
      constants.LOCAL_DB_PURCHASED_SESSIONS_ACCESS
    );
    if (!purchasedSesssionAccessCollection) {
      return null;
    }
    var access = purchasedSesssionAccessCollection.findOne({
      sessionId: this.id,
      userId: currentUserId,
      valid: true,
    });
    return access;
  }
  /**
   * Lets us know if this session is available only to a limited number of people
   * @returns True if there is a limit of number of purchasers to this sessions
   */
  hasSpotLimit() {
    return this.spectatorGoal > 0;
  }

  /**
   * @returns True if there is no limit for the amount of purchasers to this session
   */
  noPurchaseLimit() {
    return this.spectatorGoal == 0;
  }
  /**
   * Price strings
   * @returns Formatted price/cost string of the session
   */
  priceString() {
    let price = this.pricePerAccess;
    let currency = this.priceCurrency;

    // console.log("currency =", currency);
    var settings = {
      style: "currency",
      currency: currency,
    };
    if (currency === "ILS") {
      settings.maximumSignificantDigits = 1;
      settings.minimumSignificantDigits = 1;
    }
    var formatter = new Intl.NumberFormat("en-US", settings);

    let str = formatter.format(price);
    return str;
  }

  purchaseButtonTitleString() {
    return parse(
      lclString("actually_purchase_session_button"),
      this.priceString()
    );
  }

  shareURL() {
    return `${utils.baseShareURL()}/s/${this.id}`;
  }

  currentEarningsString() {
    let { priceCurrency, currentEarnings } = this;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: priceCurrency,
    })
      .format(currentEarnings)
      .replace(/\D00$/, "");
  }

  static fromJSON = memoize((json)=> {
    var session = new Session();
    try {
      session.id = json.id;
      session.userId = json.user_id;
      session.status = json.status;
      session.title = json.title;
      session.desc = json.desc;

      session.lastUpdated = json.last_updated;
      session.createdOn = json.created_on;
      session.startFundraiseDate = json.start_fundraise_date;
      session.endFundraiseDate = json.end_fundraise_date;
      session.releaseDate = json.release_date;
      session.pricePerAccess = json.price_per_access;

      session.priceCurrency = json.price_currency;
      session.spectatorGoal = json.spectator_goal;
      session.user = memoize(VLUser.fromJSON(json.user));
      session.maxPreviewDuration =
        json.max_preview_duration ?? session.maxPreviewDuration;

      session.previewVideoURL = json.preview_video_url;
      session.sessionVideoURL = json.session_video_url
        ? json.session_video_url
        : json.session_submission_video_url;

      session.totalEarnings = json.total_earnings
        ? json.total_earnings
        : session.totalEarnings;
      session.currentEarnings = json.current_earnings
        ? json.current_earnings
        : session.currentEarnings;
      session.totalPurchases = json.total_purchases
        ? json.total_purchases
        : session.totalPurchases;

      return session;
    } catch (error) {
      return null;
    }
  })
}

export default Session;
