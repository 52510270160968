import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { withBookingStage } from '../../../../../shared/hoc';
import {
  onSubmitCampaignClicked,
  previousStep,
  goToTypeSelection,
  goToOcassionSelection,
  goToCreatorSelection,
  goToBriefSelection,
  onDismissError,
} from '../../../../../store/reducers/campaigns/';
import VLButton from '../../../../../Reusables/Buttons/VLButton';
import { capitalizeFirstLetter } from '../../../../../utils/rhutils';
import {
  roundMinutes,
  toLocalDateTimeString,
} from '../../../../../utils/dateUtils';
import { convertPrice } from '../../../../../model/helpers/payment.helper';
import userManager from '../../../../../managers/userManager';
import { toPriceString } from '../../../../../utils/priceUtils';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew } from '../../../../../App';
import paymentManager from '../../../../../managers/paymentManager';
import ErrorModal from '../../../../errorModal';
import { useTranslation } from 'react-i18next';
import VLFlowManager, {
  CampaignFlowEventSummary,
} from '../../../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    marginTop: '80px',
  },
  input: {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.165px',
  },
  PropertyBox: {
    // cursor: 'pointer',
    // transition: 'transform 0.15s ease-in-out',
    // '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
    minHeight: '60px',
    borderRadius: '16px',
    borderWidth: '2px',
    borderColor: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
}));

const PropertyBox = (props) => {
  const classes = useStyles();
  const { title, detail, numbers, disabled, onClick } = props;
  return (
    <Box
      // onClick={onClick}
      p={2}
      border={1}
      className={classes.PropertyBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography variant="h6">
        <Box color="primary.main" fontWeight="600" mb={1}>
          {title}
        </Box>
      </Typography>
      <Typography variant={numbers ? 'h6' : 'body1'}>
        <Box
          lineHeight={1.2}
          fontWeight="600"
          fontSize="18px"
          className={disabled ? 'text-muted' : ''}
        >
          {detail}
        </Box>
      </Typography>
    </Box>
  );
};

/**
 * Returns the loaclized price for the current user from a creator's data
 **/
const localizedPrice = (targetCurrency, creator, type) => {
  let creatorCurrency = creator.currency;
  let targetPrice =
    type === 'business' ? creator.price_business : creator.price;
  let convertedPrice = convertPrice(
    targetPrice,
    creatorCurrency,
    targetCurrency
  );
  const retValue = Number(convertedPrice);
  return retValue;
};

const getCampaignCostDetails = (selectedCreators, type) => {
  const currentUserCurrency = userManager.currentUserCurrency();
  const reducer = (accumulator, currentValue) =>
    Number(accumulator) +
    localizedPrice(currentUserCurrency, currentValue, type);
  const totalCost = selectedCreators.reduce(reducer, 0);
  return { currency: currentUserCurrency, price: totalCost };
};

const PropertiesContainer = (props) => {
  const {
    type,
    ocassion,
    deliveryDeadline,
    message,
    selectedCreators,
    fromWho,
    forWho,
    businessName,
    t,
  } = props;

  const totalCreators = selectedCreators.length;
  const priceDetails = getCampaignCostDetails(selectedCreators, type);
  const totalCostString = toPriceString(
    priceDetails.currency,
    priceDetails.price
  );

  const typeTitle =
    type === 'personal'
      ? t('campaigns_who_for_type_personal')
      : t('campaigns_who_for_type_business');

  return (
    <Box p={2}>
      <Box id="campaign name" display="none">
        <PropertyBox title={t('campaigns_summary_title')} detail="Birthday" />
      </Box>
      <Box id="type">
        <PropertyBox
          title={t('campaigns_summary_campaign_title')}
          detail={capitalizeFirstLetter(typeTitle)}
          onClick={() => props.goToTypeSelection()}
        />
      </Box>
      <Box id="ocassion">
        <PropertyBox
          title={t('campaigns_summary_type_title')}
          onClick={() => props.goToOcassionSelection()}
          detail={capitalizeFirstLetter(ocassion)}
        />
      </Box>
      <Box
        width="100%"
        id="total-budget-and-details"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box width="47.5%">
          <PropertyBox
            numbers
            title={t('campaigns_summary_occassion_title')}
            detail={totalCostString}
            onClick={() => props.goToCreatorSelection()}
          />
        </Box>
        <Box width="47.5%">
          <PropertyBox
            numbers
            title={t('campaigns_summary_total_title')}
            detail={totalCreators}
            onClick={() => props.goToCreatorSelection()}
          />
        </Box>
      </Box>
      <Box id="due-date" display={deliveryDeadline ? 'flex' : 'none'}>
        <PropertyBox
          disabled
          title={t('campaigns_summary_delivery_title')}
          detail={toLocalDateTimeString(
            roundMinutes(new Date(deliveryDeadline))
          )}
        />
      </Box>
      <Box
        width="100%"
        id="from-and-for-who"
        display={type === 'personal' ? 'flex' : 'none'}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box width="47.5%">
          <PropertyBox
            title={t('campaigns_summary_from_title')}
            onClick={() => props.goToBriefSelection()}
            detail={fromWho && fromWho.length > 0 ? fromWho : 'None'}
            disabled={!fromWho}
          />
        </Box>
        <Box width="47.5%">
          <PropertyBox
            title={t('campaigns_summary_fo_title')}
            onClick={() => props.goToBriefSelection()}
            detail={forWho && forWho.length > 0 ? forWho : 'None'}
            disabled={!forWho}
          />
        </Box>
      </Box>
      <Box id="business_name" display={type === 'business' ? 'block' : 'none'}>
        <PropertyBox
          title={t('campaigns_summary_business_name_title')}
          detail={businessName}
          onClick={() => props.goToBriefSelection()}
        />
      </Box>
      <Box id="message">
        <PropertyBox
          title={t('campaigns_summary_message_title')}
          detail={message}
          onClick={() => props.goToBriefSelection()}
        />
      </Box>
      <Box id="attachments-info" display="none">
        <PropertyBox
          disabled
          title={t('campaigns_summary_attachment_title')}
          detail="3 files (354 Mb)"
        />
      </Box>
    </Box>
  );
};

const SummaryScreen = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [isFetchingUserInfo, setIsFetchingUserInfo] = useState(false);

  const title = t('campaigns_summary_title');
  const { error } = props;

  useEffect(() => {
    VLFlowManager.sendFlowAnalytics(new CampaignFlowEventSummary());
  }, []);

  const onSubmitClicked = async () => {
    if (props.userId) {
      //fetch paymeny methods
      setIsFetchingUserInfo(true);
      const result = await userManager.fetchCurrentUserDetails();
      let currentUserAlreadyHasPaymentMethods =
        paymentManager.validPaymentMethod() != null;
      if (currentUserAlreadyHasPaymentMethods) {
        const priceDetails = getCampaignCostDetails(
          props.selectedCreators,
          props.type
        );

        props.onSubmitCampaignClicked({
          currency: priceDetails.currency,
          total_price: priceDetails.price,
        });
        setIsFetchingUserInfo(false);
      } else {
        //should add card?
        const priceDetails = getCampaignCostDetails(
          props.selectedCreators,
          props.type
        );
        props.onNeedAddPaymentMethod(priceDetails);
        setIsFetchingUserInfo(false);
      }
    } else {
      //show auth
      props.onNeedAuth();
    }
  };

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column">
      <Box p={4} className={classes.root}>
        <Typography variant="h5">
          <Box fontWeight="bold" color="text.primary" textAlign="center">
            {title}
          </Box>
        </Typography>
      </Box>
      <PropertiesContainer t={t} {...props} />
      <Box
        pb={5}
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <VLButton
          onClick={() => props.previousStep()}
          style={{ width: '64px', background: 'white', color: '#5D36BD' }}
        >
          {!isHebrew() ? <ChevronLeft /> : <ChevronRight />}
        </VLButton>
        <VLButton onClick={() => onSubmitClicked()} style={{ width: '260px' }}>
          {isFetchingUserInfo ? (
            <CircularProgress
              size={24}
              color="white"
              style={{ color: 'white' }}
            />
          ) : (
            t('campaigns_summary_submit_campaign')
          )}
        </VLButton>
      </Box>
      <ErrorModal
        onCloseClicked={() => props.onDismissError()}
        show={error}
        title={'Submission Error'}
        detail={error}
      />
    </Box>
  );
};

const mapStateToProps = ({ campaign, user }) => {
  return {
    error: campaign.error,
    isConnected: user.isConnected,
    userId: user.userId,
    fromWho: campaign.fromWho,
    forWho: campaign.forWho,
    businessName: campaign.businessName,
    step: campaign.step,
    type: campaign.type,
    ocassion: campaign.ocassion,
    selectedCreators: campaign.selectedCreators,
    message: campaign.message,
    deliveryDeadline: campaign.deliveryDeadline,
  };
};

export default connect(mapStateToProps, {
  onSubmitCampaignClicked,
  previousStep,
  goToTypeSelection,
  goToOcassionSelection,
  goToCreatorSelection,
  goToBriefSelection,
  onDismissError,
})(withBookingStage(SummaryScreen));
