import { Dialog, makeStyles } from '@material-ui/core';
import React, { useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';
import NewAuthenticationFlowContainer from '../NewAuthenticationFlow/NewAuthenticationFlowContainer';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import styles from './AuthModal.module.scss';
import { Step } from '../../model/v2/type';
import { routes } from '../../constants';
import { useHistory } from 'react-router-dom';

/**
 * Simple component that displays the auth screen in a modal
 */

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxHeight: '700px',
    borderRadius: '24px',
    overflow: 'hidden',
    width: '100%',
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0px',
      maxHeight: '100%',
      height: '100%',
    },
  },
}));

/**
 * Basic required props
 */
interface AuthModalProps {
  onClose: any; // on close callback method
  onAuthSuccess: any; // on auth success callback method
  show: boolean;
  redirectToLink?: string;
  startStep?: Step;
  accountDetails?: any;
  allowOnboarding?: boolean;
}
/**
 * Main component
 */
const AuthModal: React.FC<AuthModalProps> = React.memo(
  ({
    onClose,
    onAuthSuccess,
    show,
    redirectToLink,
    startStep,
    accountDetails,
    allowOnboarding,
  }) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
      // console.log('AUTH - AuthModal useEffect called');
    }, []);

    const classes = useStyles();
    const history = useHistory();

    /**
     * Handler for what happens when the authentication is succesfull
     * Effecively - should we just call onClose or is there an onAuthSuccess callback for custom handling
     */
    const handleSuccesfullAuthentication = () => {
      // console.log('AUTH - handleSuccesfullAuthentication', onAuthSuccess);
      if (onAuthSuccess) {
        onAuthSuccess();
      } else if (onClose) {
        onClose();
      }
    };

    return (
      <div>
        <Dialog
          id="auth-modal-dialog"
          maxWidth="xs"
          open={show}
          classes={{ paper: classes.dialogPaper }}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <NewAuthenticationFlowContainer
            redirectToLink={redirectToLink}
            startStep={startStep ?? 'initial'}
            show={show}
            onCompleteFlow={handleSuccesfullAuthentication}
            onClose={onClose}
          />
        </Dialog>
      </div>
    );
  }
);

const mapStateToProps = (state?: any) => {
  return { accountDetails: state.user.accountDetails };
};
export default connect(mapStateToProps, null)(AuthModal);
