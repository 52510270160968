import { createSlice } from '@reduxjs/toolkit';
import { isInIsraelTimeZone } from '../../../App';
import userManager from '../../../managers/userManager';
import { isHebrew } from '../../../utils/vlStrings';

/* Basic User Model */
const initialState = {
  onboardingStep: 0,
  onboardingType: null,
  selectedCategories: [], //array of ids
  selectedCreators: [], //array of profile_ids
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    resetOnboarding(state, action) {
      state.onboardingStep = 0;
      state.onboardingType = null;
      state.selectedCategories = [];
      state.selectedCreators = [];
    },
    goToNextOnboardingStep(state, action) {
      state.onboardingStep = state.onboardingStep + 1;
    },
    selectOnboardingUserType(state, action) {
      state.onboardingStep = state.onboardingStep + 1;
      state.onboardingType = action.payload;
    },
    onSelectedCategories(state, action) {
      let categories = action.payload;
      state.selectedCategories = categories;
    },
    onSelectedCreators(state, action) {
      let creators = action.payload;
      state.selectedCreators = creators;
    },
  },
});

/* Export Redux Actions */
export const {
  resetOnboarding,
  goToNextOnboardingStep,
  selectOnboardingUserType,
  onSelectedCategories,
  onSelectedCreators,
} = onboardingSlice.actions;

/* Export the reducer */
export default onboardingSlice.reducer;
