import React from 'react';
import './VLWaveProgressLoader.css';
import { Liquid } from '@ant-design/charts';

const VLWaveProgressLoader = (props: any) => {
  //@todo remove label
  //@todo update color

  console.log('props.color ', `#${props.color}`);
  const config = {
    percent: Math.min(0.97, Math.max(props.percent, 0.3)),
    outline: {
      border: 4,
      distance: 0,
    },
    color: `#${props.color}`,
    wave: {
      length: 128,
    },
  };

  return (
    // @ts-ignore
    <div className={`w-100 h-100`} style={props.style}>
      <Liquid {...config} />
    </div>
  );
};

export default VLWaveProgressLoader;
