import { put, takeLatest, select } from 'redux-saga/effects';
import { verifyCode } from '../../routines';
import * as utils from '../../../utils/rhutils';
import { RootState } from '../../reducers';
import { SignInState } from '../../reducers/auth/sign-in';

function* handleTriggerAction(action: ReturnType<typeof verifyCode.trigger>) {
  try {
    yield put(verifyCode.request());
    const { confirmResult }: SignInState = yield select(
      (state: RootState) => state.auth.signIn
    );
    const credentials = yield confirmResult?.confirm(String(action.payload));
    utils.log.debug('✅ did verify code on LOG IN');
    yield put(verifyCode.success(credentials));
  } catch (e) {
    yield put(verifyCode.failure(e));
  }
}

export default function* verifyCodeSaga() {
  yield takeLatest(verifyCode.TRIGGER, handleTriggerAction);
}
