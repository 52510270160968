import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Container, Dialog, IconButton } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { lclDirection } from '../../utils/vlStrings';
import { fetchHomeFeed } from '../../managers/v2';

import CloseIcon from '@material-ui/icons/Close';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { makeStyles } from '@material-ui/styles';

import WelcomeStep from './Welcome';
import Explainers from './Explainers';

import {
  goToNextOnboardingStep,
  selectOnboardingUserType,
  resetOnboarding,
} from '../../store/reducers/onboarding/onboarding.reducer';
import { OnboardingStep } from '../../model/enums/v2/onboarding-steps.enum';
import ProfileEdit from './ProfileEdit';
import Interests from './Interests';
import FollowSuggestions from './FollowSuggestions';
import VerifyProfile from './VerifyProfile';
import CompletionScreen from './CompletionScreen';
import { useHistory } from 'react-router-dom';
import userManager from '../../managers/userManager';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxHeight: '700px',
    borderRadius: '24px',
    overflow: 'hidden',
    width: '100%',
    background: '#3A2D5C',
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0px',
      maxHeight: '100%',
      height: '100%',
    },
  },
  IconButton: {
    background: theme.palette.primary.main,
    color: 'white',
  },
  Button: {
    textTransform: 'capitalize',
    color: 'white',
  },
}));

const SimpleNavbar = (props) => {
  const { handleClose, step, contained } = props;
  const classes = useStyles();

  return (
    <div>
      <Box
        p={1.5}
        zIndex={2}
        width="100%"
        position="absolute"
        top={0}
        left={0}
        display={contained && step === OnboardingStep.WELCOME ? 'none' : 'flex'} //don't show on first step
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <IconButton
          className={classes.IconButton}
          onClick={() => {
            handleClose();
          }}
        >
          {(!contained && step === OnboardingStep.WELCOME) ||
          step === OnboardingStep.COMPLETION_SCREEN ? (
            <HomeOutlinedIcon />
          ) : (
            <CloseIcon />
          )}
        </IconButton>
      </Box>
    </div>
  );
};

const SimpleBottomBar = (props) => {
  const { handleSkip, show, onClick } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  if (!show) return null;
  return (
    <Box
      p={1.5}
      zIndex={2}
      width="100%"
      position="absolute"
      bottom={0}
      left={0}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Button onClick={() => onClick()} className={classes.Button}>
        {t('onboarding_button_skip')}
      </Button>
    </Box>
  );
};

const OnboardingFlow = (props) => {
  const { t, i18n } = useTranslation();
  const { step, type, contained, didExit } = props;
  const history = useHistory();
  const classes = useStyles();
  const [didApplyForVerification, setDidApplyForVerification] = useState(false);

  useEffect(() => {
    fetchHomeFeed((response, error) => {});
  }, []);

  const handleClose = () => {
    //do something
    if (
      step === OnboardingStep.WELCOME ||
      step === OnboardingStep.COMPLETION_SCREEN
    ) {
      if (contained) {
        onClose();
      } else {
        history.push('/home');
      }
    } else {
      props.resetOnboarding();
    }
  };

  const onClose = () => {
    //do something
    didExit && didExit();
  };

  const onCompletedOnboarding = () => {
    history.push('/home');
  };

  const onUserSelectedOnboardingType = async (type) => {
    const isCreator = type === 'creator';
    const perform = await userManager.updateUserType(isCreator);
    props.selectOnboardingUserType(type);
  };

  return (
    <Box maxWidth="100%" dir={lclDirection()}>
      <Dialog
        maxWidth="xs"
        open={true}
        onClose={onClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <Box
          p={2}
          maxHeight="100%"
          position="relative"
          height="100%"
          dir={lclDirection()}
        >
          <SimpleNavbar
            step={step}
            handleClose={handleClose}
            contained={contained}
          />
          <WelcomeStep
            show={step === OnboardingStep.WELCOME}
            onTypeSelected={(type) => onUserSelectedOnboardingType(type)}
          />
          <Explainers
            onBack={() => props.resetOnboarding()}
            onConfirm={() => props.goToNextOnboardingStep()}
            type={type}
            show={step === OnboardingStep.EXPLAINER}
          />
          <ProfileEdit
            onConfirm={() => props.goToNextOnboardingStep()}
            type={type}
            show={step === OnboardingStep.PROFILE_EDIT}
          />
          <Interests
            type={type}
            show={step === OnboardingStep.INTERESTS}
            onConfirm={() => props.goToNextOnboardingStep()}
          />
          <FollowSuggestions
            type={type}
            onConfirm={() => props.goToNextOnboardingStep()}
            show={step === OnboardingStep.FOLLOW_SUGGESTIONS}
          />
          <VerifyProfile
            type={type}
            onSkip={() => {
              setDidApplyForVerification(false);
              props.goToNextOnboardingStep();
            }}
            onConfirm={() => props.goToNextOnboardingStep()}
            show={step === OnboardingStep.VERIFICATION}
          />
          <CompletionScreen
            type={type}
            didApplyForVerification={didApplyForVerification}
            onConfirm={() => onCompletedOnboarding()}
            show={step === OnboardingStep.COMPLETION_SCREEN}
          />
        </Box>
        <SimpleBottomBar
          show={step === OnboardingStep.EXPLAINER}
          onClick={() => props.goToNextOnboardingStep()}
        />
      </Dialog>
    </Box>
  );
};

const mapStateToProps = ({ onboarding }) => {
  return { step: onboarding.onboardingStep, type: onboarding.onboardingType };
};

export default connect(mapStateToProps, {
  goToNextOnboardingStep,
  selectOnboardingUserType,
  resetOnboarding,
})(OnboardingFlow);
