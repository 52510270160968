import React, { useState } from 'react';
import styles from './AuthDialog.module.scss';
import { useTranslation, withTranslation } from 'react-i18next';
import CreateAccountBody from './steps/createAccount.body';
import UserGoalBody, { UserGoals } from './steps/userGoal.body';
import SocialNetworksBody from './steps/socialNetworks.body';
import UploadPictureBody from './steps/uploadPicture.body';
import SetPricesBody from './steps/setPrices.body';
import CreationDescBody from './steps/creationDesc.body';
import ShareProfileBody from './steps/shareProfile.body';
import { analytics, AnalyticsEvent } from '../utils/analytics';
import VerifyPhoneNumberBody from './steps/verifyPhoneNumber.body';

/* InApp data flow and server interactions */
import { connect } from 'react-redux';
import userManager from '../managers/userManager';
import profileManager from '../managers/profileManager';
import creationManager from '../managers/creationManager';

const AuthBody = (props: any) => <main>{props.children}</main>;

const AuthHeader = (props: any) => (
  <header>
    <h5>{props.title}</h5>
    <p>{props.desc}</p>
  </header>
);

const AuthFooter = (props: any) => (
  <footer className={styles.dialogFooter}>
    {props.steps && (
      <div className={styles.stepsCounter}>
        {Array.from(Array(props.steps).keys()).map((step: any, key: number) => {
          return (
            <i
              key={`sin.${key}`}
              className={props.activeStep == step ? styles.activeStep : ''}
            />
          );
        })}
      </div>
    )}

    {props.ctaTitle && (
      <button className={styles.CTA} onClick={props.onClick}>
        <span>{props.ctaTitle}</span>
      </button>
    )}

    {props.onSecondaryClick && (
      <button className={styles.secondaryCTA} onClick={props.onSecondaryClick}>
        <span>{props.secondaryCtaTitle}</span>
      </button>
    )}
  </footer>
);

/**
 * The initial form component displayed to a user when signing up
 * @param props
 */
const CreateAccountStep = (props: any) => {
  let { t, i18n } = useTranslation();

  let headerTitle = props.isSignup
    ? t('sign_up_screen_welcome')
    : t('re_login');
  let headerDesc = props.isSignup
    ? t('fill_in_your_details')
    : t('glad_to_see_you');

  let footerTitle = props.isSignup ? `${t('re_login')}` : `${t('sign_up')}`;

  return (
    <AuthStep>
      <AuthHeader title={headerTitle} desc={headerDesc} />
      <AuthBody>
        <CreateAccountBody
          creatorSignup={props.creatorSignup}
          isSignup={props.isSignup}
          userLinkError={props.userLinkError}
          className={styles.createAccountBody}
          onCreateAccount={props.onCreateAccount}
          loading={props.loading}
        />
      </AuthBody>
      <AuthFooter
        secondaryCtaTitle={footerTitle}
        onSecondaryClick={
          props.isSignup ? props.onChangeToLogin : props.onChangeToSignup
        }
      />
    </AuthStep>
  );
};

/**
 *
 * @param props The phone verification component for both user's signing up and those logging in
 */
const VerifyPhoneNumberStep = (props: any) => {
  let { t, i18n } = useTranslation();

  return (
    <AuthStep>
      <AuthHeader
        title={t('signup_code_verification_step_title')}
        desc={t('signup_code_verification_step_desc')}
      />
      <AuthBody>
        <VerifyPhoneNumberBody
          verifyCode={props.verifyCode}
          resendSms={props.resendSms}
          className={styles.verifyPhoneNumberBody}
          user={props.user}
          loading={props.loading}
          error={props.error}
        />
      </AuthBody>
      <AuthFooter
        ctaTitle={t('signup_code_verification_cta')}
        onSecondaryClick={props.back}
        secondaryCtaTitle={t('signup_code_verification_secondary_cta')}
        onClick={props.verifyCode}
      />
    </AuthStep>
  );
};

/**
 * This is the step where the user chooses if they are a consumer or a creator
 * @param props
 */
const UserGoalStep = (props: any) => {
  let { t, i18n } = useTranslation();

  return (
    <AuthStep>
      <AuthHeader
        title={t('signup_goal_step_title')}
        desc={t('signup_goal_step_desc')}
      />
      <AuthBody>
        <UserGoalBody
          className={styles.userGoalBody}
          onSelectGoal={props.onSelectGoal}
        />
      </AuthBody>
      <AuthFooter
        activeStep={props.currentStep}
        steps={props.steps}
        ctaTitle={t('signup_goal_step_cta')}
        onSecondaryClick={props.skip}
        secondaryCtaTitle={t('signup_goal_step_secondary')}
        onClick={props.next}
      />
    </AuthStep>
  );
};

/**
 * This is the component in which the user can enter their social networks information
 * @param props
 */
const SocialNetworksStep = (props: any) => {
  let { t } = useTranslation();

  interface initMap {
    [key: string]: { [key: string]: any };
  }
  let init: initMap = {};

  let [networks, setNetworks] = useState(init);

  const updateNetwork = (name: string, value: string, state: number = 0) => {
    setNetworks({
      ...networks,
      [name]: {
        value: value,
        state: networks[name]
          ? state > networks[name].state
            ? state
            : networks[name].state
          : state,
      },
    });
  };

  const resetNetwork = (name: string) => {
    setNetworks({
      ...networks,
      [name]: {
        value: '',
        state: 0,
      },
    });
  };

  const validateNetwork = (name: string) => {
    if (networks[name]) {
      updateNetwork(name, networks[name].value, 1);

      // FUNCTIONS: Validate network

      setTimeout(() => {
        let state =
          !networks[name].value || networks[name].value.length === 0
            ? 3 /* invalid */
            : 2; /* valid */
        updateNetwork(name, networks[name].value, state); // Valid
      }, 500);

      return;

      // setTimeout(() => {
      //   updateNetwork(name, networks[name].value, 3); // Invalid
      // }, 2000);
    }
  };

  return (
    <AuthStep>
      <AuthHeader
        title={t('socials_step_title')}
        desc={t('socials_step_desc')}
      />
      <AuthBody>
        <SocialNetworksBody
          className={styles.socialNetworksBody}
          networks={networks}
          onChange={updateNetwork}
          validateNetwork={validateNetwork}
          resetNetwork={resetNetwork}
        />
      </AuthBody>
      <AuthFooter
        activeStep={props.currentStep}
        steps={props.steps}
        ctaTitle={t('socials_step_cta')}
        onSecondaryClick={props.skip}
        secondaryCtaTitle={t('signup_goal_step_secondary')}
        onClick={() => props.onSocialsUpdated(networks)}
      />
    </AuthStep>
  );
};

/**
 * Upload profile picture step for adding profile picture and title
 * @param props
 */
const UploadProfilePicture = (props: any) => {
  let { t } = useTranslation();

  return (
    <AuthStep>
      <AuthHeader
        title={t('upload_pic_step_title')}
        desc={t('upload_pic_step_desc')}
      />
      <AuthBody>
        <UploadPictureBody
          className={styles.uploadPictureBody}
          uploadProfilePicture={props.uploadProfilePicture}
        />
      </AuthBody>
      <AuthFooter
        activeStep={props.currentStep}
        steps={props.steps}
        ctaTitle={t('socials_step_cta')}
        onSecondaryClick={props.skip}
        secondaryCtaTitle={t('signup_goal_step_secondary')}
        onClick={props.next}
      />
    </AuthStep>
  );
};

/**
 * The component where the user sets the price for the various products (personalized videos, biz videos, subs, etc)
 * @param props
 */
const PriceStep = (props: any) => {
  let { t } = useTranslation();

  return (
    <AuthStep>
      <AuthHeader title={t('price_step_title')} desc={t('price_step_desc')} />
      <AuthBody>
        <SetPricesBody
          className={styles.setPricesBody}
          onSetPrice={props.onSetPrice}
        />
      </AuthBody>
      <AuthFooter
        activeStep={props.currentStep}
        steps={props.steps}
        ctaTitle={t('socials_step_cta')}
        onSecondaryClick={props.skip}
        secondaryCtaTitle={t('signup_goal_step_secondary')}
        onClick={props.next}
      />
    </AuthStep>
  );
};

/**
 * A component which helps the user create the first subscriber only post (currently text only)
 * @param props
 */
const ProfileMessageStep = (props: any) => {
  let { t } = useTranslation();

  return (
    <AuthStep>
      <AuthHeader
        title={t('details_step_title')}
        desc={t('details_step_desc')}
      />
      <AuthBody>
        <CreationDescBody
          onSaveCreation={props.onSaveCreation}
          className={styles.creationDescBody}
        />
      </AuthBody>
      <AuthFooter
        activeStep={props.currentStep}
        steps={props.steps}
        ctaTitle={t('details_step_cta')}
        onSecondaryClick={props.skip}
        secondaryCtaTitle={t('signup_goal_step_secondary')}
        onClick={props.next}
      />
    </AuthStep>
  );
};

/**
 * A component which is used to help the user share their unique profile link across their other platforms
 * @param props
 */
const ShareProfileStep = (props: any) => {
  let { t } = useTranslation();

  return (
    <AuthStep>
      <AuthHeader
        title={t('share_profile_step_title')}
        desc={t('share_profile_step_desc')}
      />
      <AuthBody>
        <ShareProfileBody
          className={styles.shareProfileBody}
          user={props.user}
        />
      </AuthBody>
      <AuthFooter
        activeStep={props.currentStep}
        steps={props.steps}
        ctaTitle={t('share_profile_step_cta')}
        onClick={props.next}
      />
    </AuthStep>
  );
};

class AuthStep extends React.Component<any, any> {
  render() {
    return <section className={styles.authStep}>{this.props.children}</section>;
  }
}

interface SignUpUser {
  profile?: {
    firstname?: string;
    lastname?: string;
    email?: string;
    agree?: boolean;
    link?: string;
    phone?: string;
  };
}

const TAG = 'AuthDialog: ';

class AuthDialog extends React.Component<any, any> {
  user: SignUpUser = {};

  constructor(props: any) {
    super(props);

    this.state = {
      step: 0,
      signup: true, // true if Signup, false if Signin
      networks: {},
      loading: false,
      error: null,
      signupType: UserGoals.Create,
      userLinkError: null,
    };
  }

  componentDidMount(): void {
    analytics.event(new AnalyticsEvent('signup - get started'));

    if (this.props.signin)
      this.setState({
        signup: false,
      });
  }

  get isSignupFlow() {
    return this.state.signup;
  }

  next() {
    let { step } = this.state;
    this.loading(false);

    let nextStep = ++step;

    if (nextStep === this.totalSignupSteps) {
      // console.log(
      //   TAG,
      //   `signupCompleted nextStep ${nextStep} === totalSignupSteps ${this.totalSignupSteps}`
      // );
      return this.signupCompleted();
    }

    this.setState({
      step: nextStep,
    });
  }

  /**
   * Skip signup secondaries steps
   * @return void
   */
  skip() {
    // console.log(TAG, 'user skip secondaries, finishing signup');
    analytics.event(
      new AnalyticsEvent('signup - completed', [
        {
          skipped: 'Yes',
        },
      ])
    );

    let { onSignupCompleted } = this.props;
    if (onSignupCompleted) onSignupCompleted(userManager.getUser());
  }

  /**
   * Signup Completed
   */
  signupCompleted() {
    // console.log(TAG, 'signup completed. Notify parent');
    analytics.event(
      new AnalyticsEvent('signup - completed', [
        {
          skipped: 'Yes',
        },
      ])
    );

    let { onSignupCompleted } = this.props;
    if (onSignupCompleted) onSignupCompleted(userManager.getUser());
  }

  back() {
    let { step } = this.state;

    this.setState({
      step: --step,
    });
  }

  loading = (on: boolean = true) => {
    this.setState({ loading: on });
    return this;
  };

  error = (msg?: string) => {
    this.setState({ error: msg });
    return this;
  };

  /**
   * Sign up handler - creating an account for user
   * @param values
   */
  onCreateAccount(values: any) {
    this.loading();
    this.setState({ userLinkError: null });

    /* Assign local var for display and signup */
    this.user.profile = values;

    let { creatorSignup } = this.props;

    /* Apply signin only */
    if (!this.isSignupFlow || !creatorSignup) {
      userManager.startSignup(values).then((sentSMS) => {
        if (sentSMS) return this.next();

        let { t } = this.props;
        return this.loading(false).error(t('signup_failed_contact_suport'));
      });

      return;
    } else {
      //apply sign up
      userManager
        .validateSuggestedUserName(values['link'])
        .then((isUsernameAvailable) => {
          if (!isUsernameAvailable) {
            let { t } = this.props;
            this.loading(false);
            this.setState({
              userLinkError: t('signup_occupied_username'),
            });

            return;
          }

          userManager.startSignup(values).then((sentSMS) => {
            if (sentSMS) return this.next();

            let { t } = this.props;
            return this.loading(false).error(t('signup_failed_contact_suport'));
          });
        });
    }
  }

  /**
   * Resend SMS and restart the registration process
   * @TODO: Implement, Idan
   */
  resendSMS() {
    // console.log(TAG, 'Resend SMS');
  }

  /**
   * Verify SMS code
   * @param code
   */
  verifyCode(code: string) {
    this.loading();

    userManager.finishSignup(code, this.isSignupFlow).then((verified) => {
      if (!verified) {
        analytics.event(
          new AnalyticsEvent('signup - vetification code failure')
        );

        let { t } = this.props;
        return this.loading(false).error(t('invalid_verification_code'));
      }

      analytics.event(new AnalyticsEvent('signup - code verified'));

      let user = userManager.getUser();
      let { profile } = this.user;

      if (profile) {
        /* if flow is not a signup, finishing the flow */
        if (!this.isSignupFlow) return this.signupCompleted();
        this.next();
      } else {
        this.error(this.props.t('signup_error'));
      }
    });
  }

  /**
   * Change the user's funnel according to its goal - being a creator or a consumer
   * @param goal
   */
  onSelectUserGoal(goal: any) {
    this.setState({
      signupType: goal,
    });
  }

  /**
   * Update the social networks / external sites - according to user's request.
   * Finish implementation - @TODO: Idan
   * @param networks
   */
  onSocialsUpdated(socials: any) {
    userManager
      .updateUserSocialAccounts(socials)
      .then((response: boolean) => {
        // console.log(TAG, 'network updated successfully ');
        if (response) this.next();
      })
      .catch((error) => {});

    // console.log(TAG, 'NET UPDATE: ', socials, userManager.isConnected());
  }

  /**
   * Upload profile picture once it's ready
   * @param file
   * @param title
   */
  uploadProfilePicture(file: File, title: string) {
    // console.log(
    //   TAG,
    //   'Upload profile picture: ',
    //   file,
    //   ' to user, ',
    //   userManager.getUser(),
    //   ' with title: ',
    //   title
    // );
    userManager.updateSignupProfilePicture(file, title).then((success: any) => {
      // TODO: add upload indicator within the profile picture preview.
      // console.log(TAG, 'Profile picture upload ended with response: ', success);
    });
  }

  /**
   * Update products price.
   * This is usually called when the focus of the price input field is left (removed focus)
   * @param type
   * @param price
   */
  async onSetPrice(type: string, price: number) {
    this.loading();
    const response = await profileManager.updateProfileData(
      type,
      { price },
      true
    );

    if (response.success === true) {
      this.loading(false);
    } else {
      this.loading(false);
    }
  }

  /**
   * Save creator's creation/description
   * @param content
   */
  async onSaveCreation(content: string) {
    // console.log(
    //   'Update creation called:',
    //   content,
    //   ' USEr: ',
    //   userManager.getUserId()
    // );
    // this.loading();
    const response = await profileManager.submitFirstCreation(content);
    if (response.success === true) {
      // console.log('Update creation: ', content, ' result ', 'success');
      this.loading(false).next();
    } else {
      this.loading(false);
      console.error('Update creation: ', content, ' result ', 'failure');
    }
  }

  /**
   * Change flow to a signup flow
   * Include secondaries steps for details
   */
  onChangeToSignup() {
    this.setState({
      signup: true,
    });
  }

  /**
   * Change flow to a signin flow
   * Exclude secondaries steps (i.e. - profile picture, creation, and etc).
   */
  onChangeToLogin() {
    this.setState({
      signup: false,
    });

    analytics.event(new AnalyticsEvent('signup - switched to login'));
  }

  /**
   * Return the generic steps for any signup
   * @return array
   */
  get genericSteps() {
    return [
      (props: any) => (
        <CreateAccountStep
          onChangeToSignup={this.onChangeToSignup.bind(this)}
          onChangeToLogin={this.onChangeToLogin.bind(this)}
          isSignup={this.isSignupFlow}
          userLinkError={this.state.userLinkError}
          onCreateAccount={this.onCreateAccount.bind(this)}
          {...props}
        />
      ),
      (props: any) => (
        <VerifyPhoneNumberStep
          verifyCode={this.verifyCode.bind(this)}
          resendSms={this.resendSMS.bind(this)}
          {...props}
          back={this.back.bind(this)}
          next={this.next.bind(this)}
        />
      ),
      (props: any) => (
        <UserGoalStep
          skip={this.skip.bind(this)}
          onSelectGoal={this.onSelectUserGoal.bind(this)}
          next={this.next.bind(this)}
          {...props}
        />
      ),
    ];
  }

  /**
   * Return specific steps for creator signups
   * @return array
   */
  get creatorSteps() {
    return [
      (props: any) => (
        <SocialNetworksStep
          skip={this.skip.bind(this)}
          onSocialsUpdated={this.onSocialsUpdated.bind(this)}
          next={this.next.bind(this)}
          {...props}
        />
      ),
      (props: any) => (
        <UploadProfilePicture
          uploadProfilePicture={this.uploadProfilePicture.bind(this)}
          skip={this.skip.bind(this)}
          next={this.next.bind(this)}
          {...props}
        />
      ),
      (props: any) => (
        <PriceStep
          onSetPrice={this.onSetPrice.bind(this)}
          skip={this.skip.bind(this)}
          next={this.next.bind(this)}
          {...props}
        />
      ),
      // (props: any) => <ProfileMessageStep onSaveCreation={this.onSaveCreation.bind(this)} skip={this.skip.bind(this)} next={this.next.bind(this)} {...props} />,
      (props: any) => (
        <ShareProfileStep
          skip={this.skip.bind(this)}
          next={this.next.bind(this)}
          {...props}
        />
      ),
    ];
  }

  /**
   * Return the amount of steps for the signup process
   * @return int
   */
  get totalSignupSteps() {
    let { signupType } = this.state;

    if (signupType === UserGoals.Consume) return this.genericSteps.length;
    return this.genericSteps.length + this.creatorSteps.length;
  }

  render() {
    let { step, loading, error, signupType } = this.state;
    let { creatorSignup } = this.props;

    let steps = this.genericSteps;

    if (signupType === UserGoals.Create)
      steps = steps.concat(this.creatorSteps);

    return (
      <div className={styles.authDialog}>
        {steps[step]({
          steps: steps.length,
          currentStep: step,
          loading: loading,
          user: this.user,
          error: error,
          creatorSignup: creatorSignup ? true : false,
        })}
      </div>
    );
  }
}

/* Connect Auth */
const mapState = (state: any) => {
  return { user: state.user };
};
const AuthDialogComponent = withTranslation()(connect(mapState)(AuthDialog));

export { AuthDialogComponent as AuthDialog };
