import * as utils from '../utils/rhutils.js';
import api, * as RHAPI from '../constants/api';
import userManager from './userManager.js';
import VLUser from '../model/vlUser.js';
import VLPromotion from '../model/vlPromotion';
import VLError from '../model/vlError.jsx';

var _ = require('lodash');

interface FetchPromotionResponse {
  success: boolean;
  errorMessage?: string;
  promotion?: VLPromotion;
}

class PromotionManager {
  constructor() {}

  async fetchPromotionForCreator(
    objectType: string,
    objectId: string,
    creator?: VLUser,
    businessRequest?: boolean,
    code?: string
  ): Promise<FetchPromotionResponse> {
    // console.log("headers: ", headers);

    //setup params
    var params = {
      user_id: userManager.getUserId(),
      creator_id: creator?.id,
      object_id: objectId,
      object_type: objectType,
      business_request: businessRequest,
      code: code,
    };

    // prepare headers;
    var headers = await userManager.getRequestHeaders();

    //url

    var url = utils.queryString(api.FETCH_PROMOTION, params);

    //make request

    let response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    let json = await response.json();
    // utils.log.debug('purchase subscription result', json);

    let result = <FetchPromotionResponse>{};
    let promotion = VLPromotion.fromJSON(json);

    if (promotion) {
      result.success = true;
      result.promotion = promotion;
    } else {
      result.success = false;
      let error = VLError.fromJSON(json.error);
      result.errorMessage =
        error?.localizedTitle() ?? 'Could not complete purchase';
    }

    // utils.log.debug('purchase subscription parsed result:', result);
    return result;
  }
}

var promotionManager = new PromotionManager();
export default promotionManager;
