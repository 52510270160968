import { combineReducers } from 'redux';
import currentUserReducer, { CurrentUserState } from './current-user';
import signInReducer, { SignInState } from './sign-in';

export interface AuthState {
  currentUser: CurrentUserState;
  signIn: SignInState;
}

export default combineReducers<AuthState>({
  currentUser: currentUserReducer,
  signIn: signInReducer,
});
