import {
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '62px',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '120%',
    letterSpacing: '-0.41px',
    borderRadius: '20px',
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
}));

export const VLBigButton = (props: ButtonProps) => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();

  return (
    <Button disableElevation className={classes.root} {...props}>
      {props.children}
    </Button>
  );
};

export default VLBigButton;
