import firebase from 'firebase';
import * as analytics from '../utils/amplitude.js';
import api, * as RHAPI from './../constants/api';
import userManager from './userManager.js';
import * as utils from '../utils/rhutils.js';
import * as constants from '../constants/rhconstants.js';
import dbManager from '../managers/dbManager';
import VLCreationPurchase from '../model/vlCreationPurchase';
import { util } from 'prettier';
import VLCreationItem from '../model/vlCreationItem.js';
import PostVisibilityType from '../model/enums/PostVisibilityType';
import CreationDataType from '../model/enums/CreationDataType';
import VLError from '../model/vlError.jsx';

/**
 * Creation Manager in charge of all things to creation
 */
class CreationManager {
  constructor() {}

  userToken() {
    return userManager.tokenId;
  }

  /**
   * Checks if the user is close fanof a specific creator
   * @param {*} creatorId
   */
  isCloseFan(creatorId) {
    if (userManager.userId === creatorId) {
      return true; //we return true when we check against ourselves for simplicity
    }
    let closeFanObject = this.closeFanSubscription(creatorId);
    return closeFanObject != null;
  }

  /**
   * Gets the creation purchase object for a creation id
   * @param {*} creationId The id of a creation
   */
  creationPurchase(creationId) {
    // utils.log.debug(
    //   'attempting fetch creation purchase for creationId',
    //   creationId
    // );
    //we return true when we check against ourselves for simplicity
    const currentUserId = userManager.currentUserId;
    if (!currentUserId) {
      return null;
    }

    //grab the local colletion
    const creationPurchasesCollection = dbManager.getCollection(
      constants.LOCAL_DB_COLLECTION_CREATION_PURCHASES
    );
    //if no collection, return null
    if (!creationPurchasesCollection) {
      // utils.log.debug('cant find creation purchase for id ', creationId);
      return null;
    }
    // fetch subscription object
    var purchase = creationPurchasesCollection.findOne({
      creationId: creationId,
      userId: currentUserId,
      valid: true,
    });
    // utils.log.debug('found creation purchase for ', purchase);
    return purchase;
  }

  /**
   * Submit and purchase a creation
   * @param {*} creation Creation to purchase
   * @param {*} price Price of the creation purchase
   * @param {*} currency Currency of purchase
   * @param {*} referralId Referral id if exists
   * @param {*} affiliateDealId Affiliate deal id if it exists
   */
  async purchaseCreationAccess(
    creation,
    price,
    currency,
    referralId,
    affiliateDealId
  ) {
    let userToken = this.userToken();
    if (!userToken) {
      //no token!
      utils.log.debug('no token...exiting add card');
      return null;
    } else {
      // utils.log.debug("submitting with token ", userToken);
    }

    let headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${userToken}`,
    };

    // utils.log.debug("headers: ", headers);

    var params = {
      creation_id: creation.id,
      purchaser_id: userManager.currentUserId,
      price: price,
      currency: currency,
      withdraw_funds: false.toInt, //to charge card and not the user's balance
      referral_id: referralId,
      affiliate_deal_id: affiliateDealId,
    };

    var body = JSON.stringify(params);

    const url = api.PURCHASE_CREATION_ACCESS;
    utils.log.debug(`submit ${body} to ${url}`);
    let response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let json = await response.json();
    let purchase = VLCreationPurchase.fromJSON(json);
    if (purchase) {
      //save to db
      dbManager.addObject(
        constants.LOCAL_DB_COLLECTION_CREATION_PURCHASES,
        purchase
      );
      analytics.LogEvent(
        'creation_access_purchased',
        purchase.toAnalyticsMap()
      );
      analytics.logPurchaseCreation(purchase);
    }
    utils.log.debug('purchase session purchase result:', json);
    return purchase;
  }

  async submitTextCreation(text) {
    return await this.internalSubmitCreationItem(
      text,
      null,
      null,
      null,
      null,
      null,
      PostVisibilityType.SUBSCRIBERS_ONLY,
      CreationDataType.TEXT
    );
  }
  /**
   * Submit and purchase a creation
   * @param {*} text The text content of the item
   * @param {*} imageURL Hosted URL of image
   * @param {*} videoURL Hosted URL of video
   * @param {*} thumburl Hosted URL of thumbnail
   * @param {*} title Detail title of creation
   * @param {*} desc Detail desciprtion of creation
   * @param {*} visibilityType The type of visibility
   * * @param {*} Data type The type of creation
   */
  async internalSubmitCreationItem(
    text, //content
    imageURL, //content
    videoURL, //content
    thumbURL, //content
    title, //details
    desc, //details
    visibilityType,
    dataType
  ) {
    console.log(
      'submitCreationItem calling:',
      text,
      title,
      desc,
      imageURL,
      videoURL,
      visibilityType,
      dataType
    );

    let params = {};

    switch (dataType) {
      case CreationDataType.VIDEO:
        params['thumb_url'] = thumbURL;
        params['video_url'] = videoURL;
      case CreationDataType.TEXT:
        break;
      case CreationDataType.IMAGE:
        params['image_url'] = imageURL;
      default:
        return {
          success: false,
          creation: null,
          error: 'unsupported creation data type',
        };
    }

    const isDataValid = true; //todo: add validation logic

    if (!isDataValid) {
      return { success: false, creation: null, error: 'invalid creation data' };
    }

    const db = firebase.firestore();
    const creationItemRef = db
      .collection(constants.FIREBASE_COLLECTION_CREATIONS)
      .doc();
    const id = creationItemRef.id;

    //add additional fields
    params['id'] = id;
    params['text'] = text; //we should always add this in case user adds multiple layers of content (text + image)

    params['data_type'] = dataType;

    const url = api.SUBMIT_CREATION_ITEM;
    let headers = await userManager.getRequestHeaders();
    var body = JSON.stringify(params);
    console.log('submitting body', body);
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });

    let json = await response.json();
    console.log('submitCreationItem response:', json);
    const success = response.status === 200;
    if (success) {
      const error = VLError.fromJSON(json.error);
      const creation = VLCreationItem.fromJSON(json);
      let result = { success: true, creation: creation, error: error };
      console.log('submitCreationItem result:', result);
      return result;
    } else {
      let result = {
        success: false,
        creation: null,
        error: 'could not complete request',
      };
      return result;
    }
  }
}

var creationManager = new CreationManager();
export default creationManager;
