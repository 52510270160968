import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HomepageSection.module.scss';
import * as bootstrapUtils from '../../../../utils/bootstrapUtils';
import VLSubtitle from '../../../../Reusables/Texts/VLSubtitle';

type HomepageSectionProps = {
  title: string;
  actionLabel: string;
  actionLink?: string;
  discover?: boolean;
  hideSeeMore?: boolean;
};

const HomepageSection: React.FC<HomepageSectionProps> = React.memo(
  ({ title, actionLabel, actionLink, discover, children, hideSeeMore }) => {
    const { t, i18n } = useTranslation();

    return (
      <div className={styles.homepageSectionContainer}>
        {!discover && (
          <div
            className={`${styles.sectionHeader} ${bootstrapUtils.lclFlexRow()}`}
          >
            <VLSubtitle color="inherit">{t(title)}</VLSubtitle>
            {hideSeeMore ? null : (
              <a href={actionLink} target="_blank" rel="noreferrer">
                {t('see_all')}
              </a>
            )}
          </div>
        )}
        <div
          className={`${styles.sectionContent} ${
            discover ? styles.sectionContentDiscover : ''
          }`}
          dir={bootstrapUtils.lclDirection()}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default HomepageSection;
