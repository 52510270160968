import React from 'react';
import { CircleProgress } from 'react-gradient-progress';

export default function CircularGradientProgress(props) {
  const { percentage, width } = props;
  return (
    <CircleProgress
      percentage={percentage}
      strokeWidth={8}
      fontSize={0}
      width={width}
      primaryColor={['#5D36BD', '#E07C64']}
      secondaryColor="#414054"
    />
  );
}
