import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Breadcrumb.module.scss';
import * as bootstrapUtils from '../../../utils/bootstrapUtils';

type BreadcrumbProps = {
    pageTitle: string | null;
};

const Breadcrumb: React.FC<BreadcrumbProps> = React.memo(({
    pageTitle
}) => {
    const { i18n } = useTranslation();
  
    return (
        <div className={styles.breadcrumbContainer} dir={bootstrapUtils.lclDirection()}>
            <a href="/"><span className={styles.home}>{i18n.t('profile_main_home')}</span></a>
            <span className={styles.separator}>//</span>
            <span className={styles.pageTitle}>{pageTitle}</span>
        </div>
    );
});

export default Breadcrumb;