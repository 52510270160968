import { AmplitudeTracker } from './trackers/amplitude.tracker';
import { GtmTracker } from './trackers/gtm.tracker';
import { FirebaseTracker } from './trackers/firebase.tracker';
import { FullStoryRecorder } from './recorders/fullstory.recorder';
import { isMobile } from 'react-device-detect';
import { IntercomTracker } from './trackers/intercom.tracker';

const TAG = 'Analytics: ';

const CONSUMABLES = {
  Subscription: 'subscription',
  PersonalContent: 'personal-content',
  BusinessContent: 'business-content',
};

class Analytics {
  constructor() {
    this.isProduction = false;
    this.recorder = null;
  }

  init(isProduction) {
    this.isProduction = isProduction;

    this.trackers = [
      new AmplitudeTracker().init(),
      new GtmTracker().init(),
      new FirebaseTracker().init(),
      new IntercomTracker().init(),
    ];

    if (window.location.href.match(/\/admin/)) {
      // console.log(TAG, "Admin page detected, recorders won't start.");
      return;
    }

    isMobile ? analytics.setMobile() : analytics.setDesktop();

    this.initRecorder();
  }

  initRecorder() {
    this.recorder = new FullStoryRecorder();
    this.recorder.init(this.isProduction);
  }

  /**
   * Main method to send analytics events.
   * @param eventModel
   * @param callback
   */
  event(eventModel, callback = null) {
    // console.log(TAG, 'Sending event: ', eventModel);
    let promises = [];

    this.trackers.forEach((tracker) => {
      promises.push(tracker.event(eventModel.name, eventModel.properties));
    });

    Promise.all(promises).then(() => {
      if (typeof callback === 'function') callback();
    });

    if (this.recorder && this.recorder['event'])
      this.recorder.event(eventModel.name, eventModel.properties);
  }

  /**
   * Special Event - announcing about adding item to a cart
   * @param item
   * @param currency
   * @param price
   */
  addToCart(item, currency, price) {
    this.event(
      new AnalyticsEvent('add to cart', [
        {
          item,
          currency,
          price,
        },
      ])
    );
  }

  /**
   * Performing analytics logout
   */
  logout() {
    this.setUserId(null);
    this.event(new AnalyticsEvent('logout'));

    if (this.recorder && this.recorder['logout']) this.recorder.logout();
  }

  setUserId(userId) {
    this.trackers.forEach((tracker) => {
      if (tracker['setUserId']) tracker.setUserId(userId);
    });

    if (this.recorder && this.recorder['setUserId'])
      this.recorder['setUserId'](userId);

    return this;
  }

  setEmail(email) {
    this.trackers.forEach((tracker) => {
      if (tracker['setEmail']) tracker.setEmail(email);
    });

    if (this.recorder && this.recorder['setEmail'])
      this.recorder['setEmail'](email);

    return this;
  }

  setIsCreator(isCreator) {
    this.trackers.forEach((tracker) => {
      if (tracker['setIsCreator']) tracker.setIsCreator(isCreator);
    });

    if (this.recorder && this.recorder['setIsCreator'])
      this.recorder['setIsCreator'](isCreator);

    return this;
  }

  setName(name) {
    this.trackers.forEach((tracker) => {
      if (tracker['setName']) tracker.setName(name);
    });

    if (this.recorder && this.recorder['setName'])
      this.recorder['setName'](name);

    return this;
  }

  setProfileId(profileId) {
    this.trackers.forEach((tracker) => {
      if (tracker['setProfileId']) tracker.setProfileId(profileId);
    });

    if (this.recorder && this.recorder['setProfileId'])
      this.recorder['setProfileId'](profileId);

    return this;
  }

  setMobile() {
    this.trackers.forEach((tracker) => {
      if (tracker['setMobile']) tracker.setMobile();
    });

    return this;
  }

  setDesktop() {
    this.trackers.forEach((tracker) => {
      if (tracker['setDesktop']) tracker.setDesktop();
    });

    return this;
  }

  /** Legacy support **/

  /** @deprecated **/
  LogEvent = (name, properties) => {
    console.warn(TAG, 'Legacy LogEvent call 🥶');
    this.event(new AnalyticsEvent(name, properties));
  };
}

class AnalyticsEvent {
  name = '';
  properties = [];

  constructor(name, properties = []) {
    this.name = name;
    this.properties = properties;
  }

  addProperty(property) {
    this.properties.push(property);
    return this;
  }
}

const analytics = new Analytics();

export { analytics, AnalyticsEvent, CONSUMABLES as Consumables };
