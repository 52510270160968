import React from 'react';
import { Box } from '@material-ui/core';
import styles from './ClaimAccountCard.module.scss';
import { fullName } from '../../../utils/rhutils';

const BGHalfCard = () => {
  return <div className={styles.halfCard}></div>;
};
const Headers = () => {
  return (
    <Box className={styles.headers}>
      <div className={styles.specialOffer}>SPECIAL OFFER</div>
      {/* <div className={styles.title}>Free plan + 100% earnings*</div> */}
      <div className={styles.title}>Free plan</div>
    </Box>
  );
};

const ProfilePicture = (props) => {
  const { pictureUrl } = props;
  return (
    <Box className={styles.profilePicture} textAlign="center" m={3}>
      <img alt="profile" src={pictureUrl}></img>
    </Box>
  );
};

const Titles = (props) => {
  return (
    <Box className={styles.titles}>
      <div className={styles.handle}>@{props.handle}</div>
      <div className={styles.name}>{`${fullName(
        props.firstName,
        props.lastName
      )}`}</div>
      <div className={styles.title}>{props.title}</div>
    </Box>
  );
};

const Description = (props) => {
  return (
    <Box pt={2} pb={2} className={styles.description}>
      {props.description}
    </Box>
  );
};

const CTAButton = (props) => {
  return (
    <Box
      pl={2}
      pr={2}
      onClick={() => !props.isSettingUp && props.onCTAClick()}
      className={`btn btn-outline-primary ${styles.ctaButton} d-flex  justify-content-between`}
    >
      <div className={`${styles.title} font-weight-bold`}>
        Available for booking
      </div>
      <div className={styles.amount}>$$$</div>
    </Box>
  );
};

const ClaimAccountCard = (props) => {
  const {
    username,
    firstName,
    lastName,
    title,
    description,
    profilePictureUrl,
  } = props;

  return (
    <Box
      textAlign="center"
      className={styles.cardContainer}
      pb={3}
      pl={2}
      pr={2}
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
    >
      <BGHalfCard />

      <Box
        pt={3}
        className={styles.cardChildren}
        display="flex"
        justifyContent="space-between"
        width="100%"
        height="100%"
        flexDirection="column"
      >
        <Box>
          <Headers />
          <ProfilePicture pictureUrl={profilePictureUrl} />
          <Titles
            handle={username}
            firstName={firstName}
            lastName={lastName}
            title={title}
          />
        </Box>
        <Description description={description} />
        <CTAButton {...props} />
      </Box>
    </Box>
  );
};

export default ClaimAccountCard;
