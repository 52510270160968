import React, { Component } from 'react';
import styles from './Links.module.scss';
import link from '../Images/link.svg';
import instagram from '../Images/instagram.svg';
import facebook from '../Images/facebook.svg';
import tikTok from '../Images/tikTok.svg';
import youtube from '../Images/youtube.svg';
import twitter from '../Images/twitter.svg';
import { useTranslation } from 'react-i18next';

const Links = (props) => {
  const { t, i18n } = useTranslation();

  const addHttpIfNeeded = (url) => {
    if (!url.includes('http') && !url.includes('https')) {
      return 'https://' + url;
    }

    return url;
  };

  const validURL = (str) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  };

  const user = props.User;

  let tikTokLink =
    user && user.tiktokProfileURL ? addHttpIfNeeded(user.tiktokProfileURL) : '';
  let twitterLink =
    user && user.twitterProfileURL
      ? addHttpIfNeeded(user.twitterProfileURL)
      : '';
  let youTubeLink =
    user && user.youtubeProfileURL
      ? addHttpIfNeeded(user.youtubeProfileURL)
      : '';
  let facebookLink =
    user && user.facebookProfileURL
      ? addHttpIfNeeded(user.facebookProfileURL)
      : '';
  let instagramLink =
    user && user.instagramProfileURL
      ? addHttpIfNeeded(user.instagramProfileURL)
      : '';
  let websiteLink =
    user && user.customProfileURL ? addHttpIfNeeded(user.customProfileURL) : '';

  return (
    <div
      className={`${styles.Links} ${i18n.language === 'he' ? styles.heb : ''}`}
    >
      {tikTokLink ? (
        <a href={tikTokLink} target="_blank">
          <div className={styles.Links_item}>
            <img src={tikTok} />
            {t('profile_platform1')}
          </div>
        </a>
      ) : null}
      {facebookLink ? (
        <a href={facebookLink} target="_blank">
          <div className={styles.Links_item}>
            <img src={facebook} />
            {t('profile_platform2')}
          </div>
        </a>
      ) : null}
      {instagramLink ? (
        <a href={instagramLink} target="_blank">
          <div className={styles.Links_item}>
            <img src={instagram} />
            {t('profile_platform3')}
          </div>
        </a>
      ) : null}
      {twitterLink ? (
        <a href={twitterLink} target="_blank">
          <div className={styles.Links_item}>
            <img src={twitter} />
            {t('profile_platform4')}
          </div>
        </a>
      ) : null}
      {websiteLink ? (
        <a href={websiteLink} target="_blank">
          <div className={styles.Links_item}>
            <img src={link} />
            {t('profile_platform5')}
          </div>
        </a>
      ) : null}
      {youTubeLink ? (
        <a href={youTubeLink} target="_blank">
          <div className={styles.Links_item}>
            <img src={youtube} />
            {t('profile_platform6')}
          </div>
        </a>
      ) : null}
    </div>
  );
};

export default Links;
