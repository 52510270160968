import React, { useState, useEffect } from 'react';
import styles from './BookingDescription.module.scss';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { FLOW_BOOKING_MIN_DESCRIPTION_CHARS } from '../../../../constants/rhconstants';

const BookingDescription = (props) => {
  const { t, i18n } = useTranslation();
  const [showErrorStyle, setShowErrorStyle] = useState(false);
  let price = props.User && props.User.getPrice ? props.User.getPrice() : '';

  useEffect(() => {
    if (props.showInputError && !showErrorStyle) {
      setShowErrorStyle(true);
    }
  }, [props.showInputError]);

  const onChange = (e) => {
    if (e && e.target && e.target.value !== undefined) {
      props.setDescription(e.target.value);
      if (e.target.value.length >= FLOW_BOOKING_MIN_DESCRIPTION_CHARS) {
        setShowErrorStyle(false);
      }
    }
  };

  let leftChars = 30 - props.description.length;
  return (
    <div
      key="description"
      className={`${
        styles.BookingDescription
      } d-flex flex-column justify-content-center align-items-center ${
        i18n.language === 'he' || i18n.language === 'global' || !i18n.language
          ? `${styles.heb}`
          : null
      }`}
    >
      <TextareaAutosize
        ref={(ref) => ref && ref.focus()}
        className={`${styles.input} ${
          props.showInputError ? styles.shake : ''
        }`}
        maxRows={5}
        type="text"
        placeholder={t('booking_desc_text')}
        value={props.description}
        onChange={onChange}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )
        }
      />
      {leftChars > 0 ? (
        <div
          className={`${styles.minChar} ${
            showErrorStyle ? styles.textError : ''
          } ${props.showInputError ? styles.shake : ''}`}
        >
          {t('booking_write_more_then')} {leftChars} {t('chars')}
        </div>
      ) : null}
      <div className={styles.text}>{t('booking_desc_down_text')}</div>
    </div>
  );
};
export default BookingDescription;
