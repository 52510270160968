import React from 'react';
import { centerFlexColumn } from '../../../../utils/bootstrapUtils';
import styles from './ProcessingOverlay.module.scss';
import CloseIcon from '../../../../assets/icons/icon_close_white.svg';
import { CircularProgress, Dialog, LinearProgress } from '@material-ui/core';

interface ProcessingOverlayProps {
  show: boolean;
  title: string;
  detail: string;
  onClose?: any;
  linear?: boolean;
}
/**
 * General processing text overlay
 */
const ProcessingOverlay = ({
  show,
  title,
  detail,
  onClose,
  linear,
}: ProcessingOverlayProps) => {
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: { borderRadius: 30 },
      }}
      onClose={() => onClose()}
      aria-labelledby="dialog-title"
      open={show}
    >
      <div className={`${styles.main} w-100 ${centerFlexColumn()}`}>
        <div className="d-flex flex-column">
          <div className={`${styles.title}`}>{title}</div>
          <div className={`${styles.detail}`}>{detail}</div>
          <div className="mt-5 w-100 text-center">
            {linear !== true ? (
              <CircularProgress size={36} style={{ color: 'white' }} />
            ) : null}
            {linear === true ? <LinearProgress color="primary" /> : null}
          </div>
        </div>
        {onClose ? (
          <img
            alt="close"
            onClick={() => onClose}
            src={CloseIcon}
            style={{ width: '30px', height: '30px' }}
          />
        ) : null}
      </div>
    </Dialog>
  );
};

export default ProcessingOverlay;
