import React, { useState } from 'react';
import styles from './Creations.module.scss';
import Apple from '../Images/appstore.svg';
import Google from '../Images/googleplay.svg';
import CreationDataType from '../../model/enums/CreationDataType';
import { useTranslation } from 'react-i18next';
import Creation from '../Creation/Creation';
// const Creation = React.lazy(()=>import('../Creation/Creation'))
import Modal from '../../components/Modal/Modal';
import AppBanner from '../AppBanner/AppBanner';
// const Modal = React.lazy(()=>import('../../components/Modal/Modal'))
const Creations = React.memo((props) => {
  const { t, i18n } = useTranslation();
  const [pages, setPages] = useState(5);
  const [showModal, setShowModal] = useState(false);

  let creations =
    props.User && props.User.creations ? props.User.creations : [];
  const kind = (type) => {
    switch (type) {
      case CreationDataType.VIDEO:
        return 'video';
      case CreationDataType.TEXT:
        return 'text';
      case CreationDataType.IMAGE:
        return 'image';
      default:
        return 'text';
    }
  };

  const addMorePages = () => {
    setPages(pages + 5);
  };

  const toggleShowModal = (bool) => {
    if (bool !== showModal) {
      setShowModal(bool);
    }
  };

  if (!creations.length) {
    return <div></div>;
  }
  return (
    <div
      className={`${i18n.language === 'he' ? styles.heb : styles.default} ${
        props.theme === 'white' ? styles.white : ''
      }`}
      style={{ opacity: props.opacity }}
    >
      {showModal ? (
        <Modal
          title={t('subscription_modal_title')}
          subtitle={t('subscription_modal_subtitle')}
          kind="success"
          onClose={() => {
            toggleShowModal(false);
          }}
        />
      ) : null}
      {props.theme !== 'white' ? (
        <div className={styles.Creations_title}>{t('profile_creations')}</div>
      ) : null}
      <div
        className={`${styles.Creations_wrapper} d-flex flex-row align-items-center`}
      >
        {creations.map((item, index) => {
          if (window.innerWidth < 1024 && index > pages) {
            return <span key={index}></span>;
          }
          return (
            <div className={`${styles.responsiveWrapper} m-2`}>
              <Creation
                showModal={() => {
                  toggleShowModal(true);
                }}
                width="allPage"
                theme={props.theme}
                toggleShowSubscription={props.toggleShowSubscription}
                toggleViewContentModal={props.toggleViewContentModal}
                kind={kind(item.dataType)}
                data={item}
                key={item.id}
                showVideo={props.showVideo}
              />
            </div>
          );
        })}
      </div>
      {creations && creations.length > 3 ? (
        <div className={styles.more} onClick={addMorePages}>
          {t('profile_showMore')}
        </div>
      ) : null}

      <AppBanner />
    </div>
  );
});

export default Creations;
