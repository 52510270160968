import React, { Component } from "react";
import closeIcon from "../images/web_close_button.svg";

class CloseButton extends Component {
  render() {
    const topClass = `backButton ${this.props.customClass}`;
    return (
      <div className={topClass} onClick={() => this.props.onClose()}>
        <img className="centered-img p-2" alt="Close button" src={closeIcon} />
      </div>
    );
  }
}

export default CloseButton;
