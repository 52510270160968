import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaStar } from 'react-icons/fa';
import styles from './RankBadge.module.scss';

type RankBadgeProps = {
    rank: number;
};

const RankBadge: React.FC<RankBadgeProps> = React.memo(({
    rank
}) => {
    const { i18n } = useTranslation();
  
    return (
        <div className={styles.rankBadgeContainer}>
            <FaStar /> <span>{rank}</span>
        </div>
    );
});

export default RankBadge;