import React, { useRef, useState } from 'react';
import { sendVerificationCode, signUp, verifyCode } from '../../store/routines';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Confirm.module.scss';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import { SignInState } from '../../store/reducers/auth/sign-in';
import { analytics, AnalyticsEvent } from '../../utils/analytics';
import WelcomeIllustration from '../welcome_illustration.svg';
import userManager from '../../managers/userManager';
import PageLoader from '../../components/pageLoader';
import { AuthCompleted, AuthFailure } from '../../managers/v2/VLFlowManager';
import VLFlowManager from '../../managers/v2/VLFlowManager';
import { lclDirection } from '../../utils/bootstrapUtils';
import { Box } from '@material-ui/core';

interface ConfirmProps {
  phoneNumber?: string;
  isModal?: boolean;
  onSignUpClick?: any;
  onAuthSuccess?: any;
  showWelcomeScreenOnSuccess?: boolean;
}

let analyticsSignupSent = false;
let analyticsEventType: any = null;

const VerificationScreen = (props?: any) => {
  const { t } = useTranslation();
  const {
    phoneNumber,
    handleSubmit,
    isValid,
    loading,
    handleResend,
    handleSignUpClick,
    showWelcomeScreen,
    codeInputRef,
    handleCodeChange,
    handleKeyPress,
    values,
    mainError,
    inputEl,
  } = props;

  if (showWelcomeScreen === true) {
    return null;
  }

  // console.log('AUTH - Showing verification screen', props);

  // console.log('values = ', values);
  const Title = () => {
    return (
      <h1 data-e2e="login_title" className={styles.title}>
        {t('verify')}
      </h1>
    );
  };

  const Detail = () => {
    return (
      <div>
        <p className={styles.subtitle}>{`${t(
          'enter_code_placeholder'
        )} ${phoneNumber}`}</p>
      </div>
    );
  };

  //for invalid code veirfication
  // t('invalid_verification_code')
  // error.code === 'auth/invalid-verification-code'

  const ErrorAlert = () => {
    return mainError ? <Alert variant="danger">{mainError}</Alert> : null;
  };

  const ActionButtons = () => {
    return (
      <div className="text-center d-flex flex-column">
        <Button
          data-e2e="submit_button"
          className={classNames(styles.button, 'mt-5', 'px-5', 'py-3')}
          onClick={() => handleSubmit()}
          disabled={!isValid || loading}
        >
          {t('confirm_code')}
        </Button>
        <Button
          variant="link"
          className={classNames(styles.button, 'mt-2', 'px-5', 'py-3')}
          disabled={!isValid || loading}
          onClick={handleResend}
        >
          {t('resend_code')}
        </Button>
      </div>
    );
  };

  const BottomButton = () => {
    return (
      <div
        data-e2e="signIn_bottom"
        className={classNames(
          styles.bottom,
          'mt-4 d-none d-lg-block d-xl-block text-center'
        )}
      >
        {t('not_a_member')}&nbsp;
        <a href="" onClick={handleSignUpClick}>
          {t('sign_up')}
        </a>
      </div>
    );
  };

  // console.log('values', values);
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <div>
        <Title />
        <Detail />
      </div>
      <ErrorAlert />
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Form.Group
          controlId="code"
          className="mt-5"
          style={{ maxWidth: '280px' }}
        >
          <Form.Row ref={codeInputRef} style={{ direction: 'ltr' }}>
            {Array.from(Array(6).keys()).map((i) => {
              const code = values.join('');
              // console.log('code = ', code.length);
              return (
                <Col key={`code-input-${i}`}>
                  <Form.Control
                    maxLength={1}
                    data-e2e="number"
                    disabled={loading}
                    className={classNames('text-center', styles.input)}
                    onChange={(e: any) => handleCodeChange(e, i)}
                    onKeyDown={(e: any) => handleKeyPress(e, i)}
                    value={values[i] || ''}
                    tabIndex={0}
                    max={9}
                    onInput={(e?: any) => {
                      const val = Math.max(0, parseInt(e.target.value));
                      if (val) {
                        e.target.value = val;
                      }
                    }}
                    {...(i === values.join('').length ? { ref: inputEl } : {})}
                  />
                </Col>
              );
            })}
          </Form.Row>
          <Form.Control.Feedback type="invalid">
            {mainError}
          </Form.Control.Feedback>
        </Form.Group>
      </Box>
      <div id="recaptcha-container" />
      <Box mb={5}>
        <ActionButtons />
      </Box>
      {/* <BottomButton /> */}
    </Box>
  );
};

/**
 * Loading component wrapper
 */
const LoadingView = (props: any) => {
  return props.showLoading ? (
    <div className="fixed-top w-100 h-100">
      <PageLoader darkOpacity={true} withBackground={true} />
    </div>
  ) : null;
};

const TARGET_LENGTH = 6;

const CodeConfirmationScreen: React.FC<ConfirmProps> = ({
  phoneNumber,
  onAuthSuccess,
  showWelcomeScreenOnSuccess,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [mainError, setMainError] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [values, setValues] = useState(['', '', '', '', '', '']);

  const handleSubmit = () => {
    handleVerificationProcess(values.join(''));
  };

  const { loading, authorized, phone, error, stage } = useSelector<
    RootState,
    SignInState
  >((state) => state.auth.signIn);
  const inputEl = useRef(null);

  /**
   * Always Focus on the input label
   */
  React.useEffect(() => {
    // console.log('use effect', inputEl.current);
    setIsValid(values.join('').length === TARGET_LENGTH);
  }, []);

  /**
   * Always Focus on the input label
   */
  React.useEffect(() => {
    // console.log('AUTH - Confirm useEffect called');
    // @ts-ignore
    //for focussing on element to input verification code
    if (inputEl.current) inputEl.current.focus();
  }, []);

  const codeInputRef = useRef(null);

  //Analytics
  const setupAnalytics = () => {
    if (!analyticsEventType) {
      analyticsEventType = stage === 'signup' ? 'signup' : 'signin';
    }

    if (authorized && !analyticsSignupSent) {
      analytics.event(new AnalyticsEvent(`${analyticsEventType} - completed`));
      analyticsSignupSent = true;
    }
  };
  setupAnalytics();

  /**
   * Post success screen we show for new users that just signed up
   * @param props
   */
  const NewSignupWelcomeScreen = (props?: any) => {
    if (showWelcomeScreen === false) {
      return null;
    }
    // console.log('AUTH - Showing welcome screen', props);
    return (
      <div className={`${styles.NewSignupWelcomeScreen}`}>
        <div className="h-100 w-100 d-flex flex-column">
          <div className={`${styles.assetContainer}`}>
            <div className={`${styles.imgContainer}`}>
              <img className="img-fluid" src={WelcomeIllustration} />
            </div>
            <div className={`${styles.title}`}>{t('sign_up_success')}</div>
          </div>
          <div className="w-100 pl-4 pr-4 text-center">
            <Button
              data-e2e="submit_button"
              className={classNames(styles.button, 'mt-5', 'px-5', 'py-3')}
              onClick={() => props?.onButtonClick()}
            >
              {t('welcome_screen_complete_sign_up_buton')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  //INPUT

  const handleCodeChange = (e: any, index: number) => {
    // console.log('handleCodeChange', e.target.value, index);
    let newValues = values;
    newValues[index] = e.target.value;
    // console.log('handleCodeChange newValues', newValues);
    // setValues({ ...newValues });
    const emptyValues: string[] = [];
    setValues([...emptyValues, ...newValues]);
    const input = codeInputRef?.current as any;
    if (index < 5) {
      input.childNodes[index + 1].childNodes[0].focus();
    }
    const completed = values.every((value) => value !== '');
    if (completed) {
      handleSubmit();
    }
  };

  const handleKeyPress = (e: any, i: number) => {
    const input = codeInputRef.current as any;
    if (e.keyCode === 8 && i > 0) {
      if (i === 5) {
        values[i] = '';
      } else {
        values[i - 1] = '';
      }
      const newValues = [...values];
      setValues(newValues);
      input.childNodes[i - 1].childNodes[0].focus();

      // console.log('handleKeyPress', e, i);
    }
  };

  //Authentication

  /**
   * Handle what happens after the authentication is succesfull
   */
  const handleVerificationSuccess = (isNewUser: boolean) => {
    // console.log('AUTH - handleVerificationSuccess');
    if (showWelcomeScreenOnSuccess === true) {
      // console.log('AUTH - showWelcomeScreenOnSuccess');
      setShowWelcomeScreen(true);
    } else {
      // console.log('AUTH - calling onAuthSuccess', onAuthSuccess);
      onAuthSuccess(isNewUser);
    }
  };

  /**
   * Handle resend
   */
  const handleResend = React.useCallback(() => {
    if (phone) {
      dispatch(sendVerificationCode(phone));
    }
  }, [dispatch, phone]);

  /**
   * Method to handle the verif code and auth user
   * @param code The verification code
   */
  const handleVerificationProcess = (code?: string) => {
    //begin loading
    setShowLoading(true);

    userManager.finishSignup(code, false).then((response) => {
      setShowLoading(false);

      // @ts-ignore
      const { success, isNewUser } = response;

      if (!success) {
        VLFlowManager.sendFlowAnalytics(new AuthFailure('phone'));
        //log event
        analytics.event(
          new AnalyticsEvent('signup - vetification code failure')
        );

        //stop loading
        setMainError(t('invalid_verification_code'));
      } else {
        //log event
        analytics.event(new AnalyticsEvent('signup - code verified'));
        let user = userManager.getUser();
        if (user) {
          //success
          handleVerificationSuccess(isNewUser);
          VLFlowManager.sendFlowAnalytics(new AuthCompleted('phone'));
        } else {
          //error
          setMainError(t('signup_error'));
          VLFlowManager.sendFlowAnalytics(new AuthFailure('phone'));
        }
      }
    });
  };

  return (
    <div
      className={`${styles.VerificationScreen} position-relative h-100`}
      dir={lclDirection()}
    >
      <Box p={3} mt={2} height="75%">
        <VerificationScreen
          phoneNumber={phoneNumber}
          error={error}
          mainError={mainError}
          handleSubmit={handleSubmit}
          isValid={isValid}
          loading={loading}
          handleResend={handleResend}
          showWelcomeScreen={showWelcomeScreen}
          codeInputRef={codeInputRef}
          handleCodeChange={(e: any, index: number) =>
            handleCodeChange(e, index)
          }
          handleKeyPress={(e: any, index: number) => handleKeyPress(e, index)}
          values={values}
          inputEl={inputEl}
        />
        <NewSignupWelcomeScreen onButtonClick={onAuthSuccess} />
        <LoadingView showLoading={showLoading} />
      </Box>
    </div>
  );
};

CodeConfirmationScreen.defaultProps = {
  isModal: false,
};

export default CodeConfirmationScreen;
