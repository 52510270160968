import * as React from 'react';
import { Button } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import VLCreationBaseView from '../VLCreationBaseView/VLCreationBaseView';
import { stringify } from 'querystring';
import {
  postVisibilityFromStringTypeToString,
  PostVisibilityType,
} from '../../model/enums/v2/post-visibility-type.enum';

export function VLColumnsForTable(type: string) {
  switch (type) {
    case 'creations':
      return TableColumnsCreations;
    default:
      break;
  }
  return columns;
}
export function VLRowsForTable(data: any) {
  return rows;
}

/*Table Headers */

const TableColumnsCreations: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 250 },
  // {
  //   field: 'created_on',
  //   headerName: 'Created on ts',
  //   type: 'dateTime',
  //   width: 0,
  // },
  {
    field: 'created_on_pretty',
    headerName: 'Created on',
    type: 'string',
    width: 250,
  },
  // {
  //   field: 'released_on',
  //   headerName: 'Released on',
  //   type: 'dateTime',
  //   width: 0,
  // },
  {
    field: 'released_on_pretty',
    headerName: 'Released on',
    type: 'string',
    width: 250,
  },
  { field: 'data_type', headerName: 'Type', width: 150 },
  {
    field: 'visibility_type_nice',
    headerName: 'Visibility',
    width: 150,
    valueGetter: (params: GridValueGetterParams) =>
      `${postVisibilityFromStringTypeToString(
        parseInt(params.getValue('visibility_type')?.toString() ?? '0')
      )}`,
  },
  {
    field: 'preview_url',
    headerName: 'Thumbnail',
    width: 250,
    renderCell: (params: GridCellParams) => {
      return (
        <img
          src={String(params.value ?? '')}
          style={{ width: '150px', height: '150px', objectFit: 'cover' }}
        ></img>
      );
    },
    valueGetter: (params: GridValueGetterParams) =>
      `${params.getValue('thumb_url')}`,
  },
  {
    field: 'creation_payload',
    headerName: 'Creation Preview',
    width: 250,
    renderCell: (params: GridCellParams) => {
      const obj = JSON.parse(String(params.value));
      return (
        <VLCreationBaseView
          text={obj.text}
          thumb_url={obj.thumb_url}
          image_url={obj.image_url}
          video_url={obj.video_url}
          title={obj.title}
          detail={obj.description}
          type={obj.creation_type}
          display={obj.display}
        />
      );
    },
  },
  { field: 'title', headerName: 'Caption', width: 250 },
  { field: 'desc', headerName: 'Description', width: 350 },
  { field: 'likes_count', headerName: 'Likes', width: 150, type: 'number' },
  { field: 'views_count', headerName: 'Views', width: 150, type: 'number' },
  {
    field: 'comments_count',
    headerName: 'Comments',
    width: 150,
    type: 'number',
  },
];
export interface ITableRowsCreations {
  id?: string;
  created_on?: number;
  released_on?: number;
  data_type?: string;
  title?: string;
  desc?: string;
  visibility_type?: number;
  likes_count?: number;
  views_count?: number;
  comments_count?: number;
  thumb_url?: string;
  created_on_pretty?: string;
  released_on_pretty?: string;
  creation_payload?: string;
}

export interface ICreatorRecruitmentDetails {
  //general
  kre8tv_user_id?: number;
  username?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  description?: string;
  profilePictureUrl?: string;
  tags?: string[];
  //internal
  category_id?: string;
  currency?: string;
  //socials
  custom_url?: string;
  instagram_url?: string;
  facebook_url?: string;
  youtube_url?: string;
  twitter_url?: string;
  tiktok_url?: string;
  //personal requests_status
  personal_requests?: boolean;
  personal_requests_price?: number;
  //business_requests
  business_requests?: boolean;
  business_requests_price?: number;
}

export class TableRowsCreations implements ITableRowsCreations {
  id = '';
  created_on = 0;
  released_on = 0;
  data_type = '';
  title = '';
  desc = '';
  visibility_type = 0;
  likes_count = 0;
  views_count = 0;
  comments_count = 0;
  thumb_url = undefined;
  created_on_pretty = '';
  released_on_pretty = '';
  creation_payload? = '';
}

export function MapRecordToRow(record: any): TableRowsCreations {
  let obj = new TableRowsCreations();
  obj.id = record.get('id');
  obj.created_on = record.get('created_on');
  obj.released_on = record.get('released_on') ?? obj.created_on;
  obj.data_type = record.get('data_type');
  obj.title = record.get('title');
  obj.desc = record.get('desc');
  obj.visibility_type = parseInt(record.get('visibility_type'));
  obj.likes_count = record.get('likes_count');
  obj.views_count = record.get('views_count');
  obj.comments_count = record.get('comments_count');
  obj.thumb_url = record.get('thumb_url');

  let pretty = new Date(obj.created_on);
  obj.created_on_pretty = pretty.toLocaleString();

  pretty = new Date(obj.released_on);
  obj.released_on_pretty = pretty.toLocaleString();

  const creation_payload = JSON.stringify(obj);
  obj.creation_payload = creation_payload;

  return obj;
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.getValue('firstName') || ''} ${
        params.getValue('lastName') || ''
      }`,
  },
  { field: 'created_on', headerName: 'Created on', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Franssces', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  { id: 10, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 11, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 12, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 13, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 14, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 15, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 16, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 17, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 18, lastName: 'Melisandre', firstName: null, age: 150 },
];
