import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { withBookingStage } from '../../../../../shared/hoc';
import {
  updateType,
  reset,
  startNewCampaignWithCategory,
  startNewCampaignFromScratch,
} from '../../../../../store/reducers/campaigns';

import RocketStatic from './images/rocket_static.png';
import VLButton from '../../../../../Reusables/Buttons/VLButton';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isHebrew, parse } from '../../../../../utils/vlStrings';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#2E1B5E',
    paddingTop: '80px',
  },
  dialog: {
    background: '#FFFFFF',
    borderRadius: '30px',
  },
  NewCampaignBox: {
    background: '#F9F9F9',
    width: '100%',
    borderRadius: '20px 20px 0px 0px',
  },
  CategoryBox: {
    height: '70px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 16px rgba(118, 138, 149, 0.2)',
    borderRadius: '16px',
  },
}));

const StartNewCampaignDialog = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { onClose, category, open } = props;

  const handleClose = () => {
    onClose();
  };

  const title = parse(t('campaigns_success_modal_title'), category?.title);
  const detail = t('campaigns_success_modal_detail');

  const fromScratchClicked = () => {
    props.startNewCampaignFromScratch(category.id);
  };

  const duplicateClicked = () => {
    props.startNewCampaignWithCategory(category.id);
  };

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 30 },
      }}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle id="dialog-title">
        <Typography variant="h2">
          <Box lineHeight={1.2}>{title}</Box>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box fontSize="18px" lineHeight={1.2} className="text-muted">
          {detail}
        </Box>
      </DialogContent>
      <DialogActions>
        <Container maxWidth="xs" disableGutters>
          <Grid container justify="center">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <VLButton
                onClick={() => fromScratchClicked()}
                variant="outlined"
                style={{
                  width: '130px',
                  background: '#DBCAFE',
                  color: '#5D36BD',
                  border: '0 none',
                }}
              >
                {t('campaigns_success_modal_cta_1')}
              </VLButton>
              <VLButton
                onClick={() => duplicateClicked()}
                style={{ width: '130px' }}
              >
                {t('campaigns_success_modal_cta_2')}
              </VLButton>
            </Box>
          </Grid>
        </Container>
      </DialogActions>
    </Dialog>
  );
};

const CategoryBox = (props) => {
  const classes = useStyles();
  const { title, category, onCategoryClick } = props;
  return (
    <Grid item xs={6}>
      <Box p={1}>
        <Box
          onClick={() => onCategoryClick(category)}
          className={classes.CategoryBox}
          p={2}
          width="100%"
          display="flex"
          flexDirection="row"
          color="primary.main"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">
            <Box color="primary.main" fontWeight="bold">
              {title}
            </Box>
          </Typography>
          <Box id="right-chevron">
            {isHebrew() ? <ChevronLeft /> : <ChevronRight />}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
/**
 * Compoent that show a list of categories to create quick start another campaign for
 * */
const NewCampaignBox = (props) => {
  const classes = useStyles();
  const { categories, onCategoryClick } = props;

  const { t, i18n } = useTranslation();

  const title = t('campaigns_success_title');
  const detail = t('campaigns_success_detail');

  return (
    <Box className={classes.NewCampaignBox} p={2}>
      <Typography variant="h5">
        <Box color="text.primary" id="ncb-title" fontWeight="bold">
          {title}
        </Box>
      </Typography>

      <Typography variant="body2">
        <Box color="grey.500" fontSize="18px" mt={1} id="ncb-detail">
          {detail}
        </Box>
      </Typography>
      <Box component="div" mt={3} display="flex">
        <Grid container>
          {categories.map((category, index) => {
            return (
              <CategoryBox
                category={category}
                title={category.title}
                key={index}
                onCategoryClick={onCategoryClick}
              />
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

const SuccessScreen = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const info = t('campaigns_success_info');
  const goToInbox = t('campaigns_success_go_to_inbox');

  const onCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const onCTAClicked = () => {
    history.push({
      pathname: '/activity',
    });
  };

  const openDialog = Boolean(selectedCategory);

  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box p={2} width="100%">
        <Typography variant="body2">
          <Box
            fontSize="16px"
            id="info"
            color="white"
            lineHeight={1.2}
            maxWidth="400px"
            width="75%"
          >
            {info}
          </Box>
        </Typography>
        <Box
          id="rocket"
          width="100%"
          height="300px"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <img
            src={RocketStatic}
            style={{ height: '100%', width: 'auto' }}
            alt="rocket"
          ></img>
        </Box>
        <Box
          id="cta-button"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <VLButton
            onClick={() => onCTAClicked()}
            variant="contained"
            color="primary"
            style={{ width: '180px', background: '#DBCAFE', color: '#5D36BD' }}
          >
            {goToInbox}
          </VLButton>
        </Box>
      </Box>
      <NewCampaignBox
        {...props}
        onCategoryClick={(cat) => onCategoryClick(cat)}
      />
      <StartNewCampaignDialog
        {...props}
        open={openDialog}
        category={selectedCategory}
        onClose={() => setSelectedCategory(null)}
      />
    </Box>
  );
};

const mapStateToProps = ({ home }) => {
  return { categories: home.data.categories };
};

export default connect(mapStateToProps, {
  updateType,
  reset,
  startNewCampaignWithCategory,
  startNewCampaignFromScratch,
})(withBookingStage(SuccessScreen));
