import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel } from 'react-bootstrap';
import { OrderBlockWidget } from '../../Widgets/OrderBlockWidget';
import CreditCardInput from 'react-credit-card-input';
import { getI18n, useTranslation } from 'react-i18next';
import VLBigButton from '../../../../../Reusables/Buttons/VLBigButton';
import VLProgressLoaderDialog from '../../../../../Reusables/Loaders/VLProgressLoaderDialog';
import { parse } from '../../../../../utils/vlStrings';
import { toPriceString } from '../../../../../utils/priceUtils';

import {
  onSubmitOrderClicked,
  onAddPaymentClicked,
  onSubmitOrderFailed,
  onSubmitOrderSuccess,
} from '../../../../../store/reducers/ordering';
import { store } from '../../../../../store';
import bookingRequestManager from '../../../../../managers/bookingRequestManager';
import paymentManager2 from '../../../../../managers/v2/paymentManager2';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '-0.41px',
  },
  container: {
    background: '#3A2D5C',
    borderRadius: '24px',
    height: '135px',
  },
  detail: {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#E3E3EE',
  },
}));

const addPaymentMethod = async (props) => {
  const {
    t,
    onSubmitOrderFailed,
    onAddPaymentMethodSuccess,
    onAddPaymentClicked,
  } = props;

  onAddPaymentClicked();

  const { number, cvv, expiration } = store.getState().ordering.cardDetails;

  const requestAddCard = await paymentManager2.addCreditCard(
    number,
    cvv,
    expiration,
    'none'
  );

  if (requestAddCard.success) {
    onAddPaymentMethodSuccess();
  } else {
    onSubmitOrderFailed(
      requestAddCard.errorMsg ?? t('paywall_add_card_generic_error')
    );
  }
};

const executeSubmission = async (props) => {
  const {
    onSubmitOrderClicked,
    onSubmitOrderFailed,
    onSubmitOrderSuccess,
  } = props;

  //submit
  onSubmitOrderClicked();

  const orderingState = store.getState().ordering;
  const currentUserState = store.getState().user;

  var params = {
    promotion_id: orderingState.promotion_id,
    business_request: orderingState.type === 'business',
    requester_id: currentUserState.userId, //current user id
    persona_id: orderingState.creator.id,
    price: orderingState.total_price,
    ocassion: orderingState.ocassion_value,
    ocassion_title: orderingState.ocassion,
    currency: orderingState.currency,
    for_who:
      orderingState.type === 'business'
        ? orderingState.businessName
        : orderingState.forWho,
    from_who: orderingState.fromWho,
    marked_as_private_by_requester: false,
    for_someone_else: orderingState.selectedEntity === 'someone else',
    description: orderingState.message,
    withdraw_funds:
      orderingState.total_price && orderingState.total_price > 0 ? 0 : 1, //for credit card withdrawal (1 means take from credits on iOS (old))
    marked_as_anonymous_by_requester: false, //@todo
    // from_who_image_url: allImages.fromWhoImageUploadedLink,
    // for_who_image_url: allImages.forWhoImageUploadedLink,
    // requester_attached_image_url: allImages.mainImageUploadedLink, //@todo
    // referral_id: UserManager.referralId, //@todo
  };

  const result = await bookingRequestManager.submitBookingRequest(params);

  if (
    result &&
    result.error &&
    (result.error?.hebrewText || result.error?.text)
  ) {
    // on fail
    const errorMsg =
      getI18n().language === 'he'
        ? result.error?.hebrewText
        : result.error?.text;
    onSubmitOrderFailed(errorMsg);
  } else {
    //on success
    onSubmitOrderSuccess();
  }
};

const OrderConfirmPurchaseBlock = (props) => {
  const {
    isAddingCard,
    isSubmitting,
    price,
    currency,
    hasValidPaymentMethods,
    hasEnteredNewValidPayment,
    onSubmitOrderFailed,
    onAddPaymentClicked,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const title = parse(
    t('complete_purchase_for'),
    toPriceString(currency, price, true, false)
  );
  const detail = t('add_card_bottom_subtitle');

  const showLoader = isAddingCard || isSubmitting;
  const loaderTitle = isAddingCard
    ? t('loader_adding_card')
    : isSubmitting
    ? t('loader_submitting')
    : '';

  /**
   * Add the payment method in store to the user's customer info
   */
  const onAddCard = async () => {
    const onAddPaymentMethodSuccess = async () => {
      await executeSubmission(props);
    };

    const paymentProps = {
      t: t, //translator
      onAddPaymentClicked: onAddPaymentClicked, //show loading
      onSubmitOrderFailed: onSubmitOrderFailed, //show failure
      onAddPaymentMethodSuccess: onAddPaymentMethodSuccess, //continue to success
    };
    await addPaymentMethod(paymentProps);
  };

  /**
   * Submit the booking request (after checked valid payment methods)
   */
  const onCtaClicked = async () => {
    if (hasValidPaymentMethods) {
      await executeSubmission(props);
    } else if (hasEnteredNewValidPayment) {
      onAddCard();
    }
  };

  return (
    <OrderBlockWidget hideBackground={true} noSpacing={true}>
      <Box className={classes.root} p={1}>
        <VLBigButton
          disabled={
            hasValidPaymentMethods === false && !hasEnteredNewValidPayment
          }
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={() => onCtaClicked()}
        >
          {title}
        </VLBigButton>
        <Box mt={2} className={classes.detail}>
          {detail}
        </Box>
      </Box>
      <VLProgressLoaderDialog title={loaderTitle} show={showLoader} />
    </OrderBlockWidget>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    user: state.user.details,
    currency: state.ordering.currency,
    price: state.ordering.total_price,
    isAddingCard: state.ordering.isAddingCard,
    isSubmitting: state.ordering.isSubmitting,
    hasValidPaymentMethods: paymentManager2.existingPaymentMethods().length > 0,
    hasEnteredNewValidPayment:
      state.ordering.cardDetails.is_valid !== undefined &&
      state.ordering.cardDetails.is_valid === true,
  };
};

export default connect(mapStateToProps, {
  onSubmitOrderClicked,
  onAddPaymentClicked,
  onSubmitOrderFailed,
  onSubmitOrderSuccess,
})(OrderConfirmPurchaseBlock);
