import firebase from 'firebase';
import * as utils from '../utils/rhutils.js';
import * as constants from '../constants/rhconstants.js';
import * as app from '../App';
import VLUser from '../model/vlUser';
import VLError from '../model/vlError';
import VLBookingRequest from '../model/bookingRequest';
import api from './../constants/api';
import VLCategory from '../model/vlCategory.js';
import VLPost from '../model/vlPost.js';
import VLTransactionReview from '../model/vlTransactionReview.js';
import VLCreationItem from '../model/vlCreationItem.js';
import socialManager from './socialManager.jsx';
import Session from '../model/session.js';
import CloseFan from '../model/closeFan.js';
import { isHebrew } from '../utils/vlStrings.js';
import VLAccountDetails from '../model/vlAccountDetails.js';
import { analytics } from '../utils/analytics';
import * as ROUTES from '../constants/routes';
import { getI18n } from 'react-i18next';
import store, { selectUserState } from '../store/store';
import {
  updateConnectStatus,
  updateUserDetails,
  resetUser,
  updateUserId,
  updatePaymentMethods,
  updateAccountDetails,
} from '../store/reducers/user/user.reducer.js';
import uploadManager from './uploadManager.jsx';
import profileManager from './profileManager.tsx';
import { AuthVerified } from './v2/VLFlowManager';
import VLFlowManager from './v2/VLFlowManager';
import { isMobile } from 'react-device-detect';
import recruitmentManager from './recruitmentManager';
import {
  resetAccountDetails,
  updateBookingRequests,
} from '../store/reducers/activity/activity.reducer.js';

const TAG = 'UserManager: ';

class UserManager {
  referralId = null;
  affiliateDealId = null;
  currentUser = null;
  currentUserAccountDetails = null;
  currentUserId = null;
  paymentMethods = [];
  tokenId = null;
  currentFirebaseUser = null;

  /* Call .unsubscribe() to stop subscription */
  userStoreSubscription = null;

  authRuntimeConfig = {};
  userInitialized = false; // indicate if user already initialized and data available.

  /**
   * Main user manager construction - should happen once.
   * External initializing is permitted. Initialize once, at the bottom of this file.
   * ** Async tasks should to to .init() **
   * ** constructor() content is for configuration only **
   */
  constructor() {
    this.userStoreSubscription = store.subscribe(
      this.onUserStoreChanged.bind(this)
    );
  }

  /**
   * Secondary init - non-configuration
   */
  init() {
    // Legacy flow
    this.initUser();
  }

  /**
   * Dispatched when the store is being updated.
   * Store should be the single source of truth regarding the user's state -- any non-view update, after user's state has changed,
   * should happen as a follower to this method.
   *
   * To make a view-changes as state change followers, @see AuthDialog.tsx.
   */
  onUserStoreChanged() {
    let user = selectUserState(store.getState()).details;
    if (!user.id) return;

    //continue and do stuff
  }

  /**
   * Allow users to sign up via their facebook account
   * @param {*} callback errorMsg : String?, isNewuser : boolean?
   */

  startAuthWithFacebook(callback) {
    var provider = new firebase.auth.FacebookAuthProvider();
    if (isMobile) {
      this.startAuthWithFacebookWithRedirect(provider, callback, 'facebook');
    } else {
      this.startAuthWithProvider(provider, callback, 'facebook');
    }
  }

  /**
   * Allow users to sign up via their facebook account
   * @param {*} callback errorMsg : String?, isNewuser : boolean?
   */
  startAuthWithFacebookWithRedirect(callback) {
    var provider = new firebase.auth.FacebookAuthProvider();
    this.startAuthWithProviderWithRedirect(
      provider,
      callback,
      'facebook',
      true
    );
  }
  /**
   * Allow users to sign up via their gmail account
   * @param {*} callback errorMsg : String?, isNewuser : boolean?
   */
  startAuthWithGoogle(callback) {
    var provider = new firebase.auth.GoogleAuthProvider();

    if (isMobile) {
      this.startAuthWithProviderWithRedirect(provider, callback, 'gmail');
    } else {
      this.startAuthWithProvider(provider, callback, 'gmail');
    }
  }

  /**
   * Allow users to sign up via their facebook account
   * @param {*} callback errorMsg : String?, isNewuser : boolean?
   */
  startAuthWithGoogleWithRedirect(callback) {
    var provider = new firebase.auth.FacebookAuthProvider();
    this.startAuthWithProviderWithRedirect(provider, callback, 'gmail', true);
  }

  startAuthWithProviderWithRedirect(provider, callback, kind) {
    firebase.auth().signInWithRedirect(provider);
  }

  async fetchAuthRedirectResult() {
    firebase
      .auth()
      .getRedirectResult()
      .then(async (result) => {
        if (result.credential) {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;

          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          var token = credential.accessToken;
          // ...
        }
        // The signed-in user info.
        var user = result.user;

        // console.log(TAG, 'User is authenticated. Apply authentication flow.');
        let saved = user && (await this.onAuthenticatedUser(user));

        if (saved) {
          let displayName = user.displayName;
          let firstName = 'Unknown';
          let lastName = 'Creator';
          let email = user.email;
          if (displayName && displayName.length > 0) {
            let parts = displayName.split(' ');
            firstName = parts.length > 0 ? parts[0] : firstName;
            lastName = parts.length > 1 ? parts[1] : lastName;
          }
          saved = await this.updateUserBasicDetails(
            user.uid,
            firstName,
            lastName,
            email
          );
        } else {
          // callback(getI18n().t('couldnt_auth_user'), false);
          return;
        }

        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null

        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        // const isNewUser = result.additionalUserInfo.isNewUser;
        // callback(null, isNewUser);
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...

        // console.log('log - auth error message -', errorMessage);
        // console.log('log - auth error code -', errorCode);

        // callback(errorMessage);
      });
  }

  startAuthWithProvider(provider, callback, kind) {
    if (window.innerWidth <= 768) {
      firebase.auth().signInWithRedirect(provider);
    } else {
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          VLFlowManager.sendFlowAnalytics(new AuthVerified(kind));

          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;

          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = credential.accessToken;

          let { user } = result;

          // console.log(TAG, 'User is authenticated. Apply authentication flow.');
          let saved = await this.onAuthenticatedUser(user);

          if (saved) {
            let displayName = user.displayName;
            let firstName = 'Unknown';
            let lastName = 'Creator';
            let email = user.email;
            if (displayName && displayName.length > 0) {
              let parts = displayName.split(' ');
              firstName = parts.length > 0 ? parts[0] : firstName;
              lastName = parts.length > 1 ? parts[1] : lastName;
            }
            saved = await this.updateUserBasicDetails(
              user.uid,
              firstName,
              lastName,
              email
            );
          } else {
            callback(getI18n().t('couldnt_auth_user'), false);
          }

          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null

          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          const isNewUser = result.additionalUserInfo.isNewUser;
          callback(null, isNewUser);
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...

          // console.log('log - auth error message -', errorMessage);
          // console.log('log - auth error code -', errorCode);

          callback(errorMessage);
        });
    }
  }

  getSignUpFlowEmail() {
    return localStorage.getItem('emailForSignIn');
  }
  /**
   * Allow users to sign up via their email and a "magic link" sent to them
   * @param {*} email Email to send verification to
   * @param {*} callback errorMsg : String?
   */
  sendSignInLink(email, redirectToLink, callback) {
    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `${utils.baseAppURL()}${ROUTES.FINISH_CONNECT}${
        redirectToLink ? `?r=${redirectToLink}` : ''
      }`,
      // This must be true.
      handleCodeInApp: true,
      iOS: {
        bundleId: utils.baseAppIdentifieriOS(),
      },
      android: {
        packageName: utils.baseAppIdentifierAndroid(),
        installApp: true,
        minimumVersion: '12',
      },
      dynamicLinkDomain: utils.baseAppDeepLinking(),
    };

    this.authRuntimeConfig = {}; //reset runtime config
    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        localStorage.setItem('emailForSignIn', email);
        // ...

        callback(null); //continue
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
        callback(errorMessage);
      });
  }

  /**
   * Complete email signup
   * @param location the window.location.href the user is currently in
   * @param {*} callback errorMsg : String?, isNewUser : Bool
   */
  async finishEmailSignup(location, callback) {
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(location)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = this.getSignUpFlowEmail();
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, location)
        .then(async (result) => {
          // Clear email from storage.
          localStorage.removeItem('emailForSignIn');

          let { user } = result;

          // console.log(TAG, 'User is authenticated. Apply authentication flow.');
          let saved = await this.onAuthenticatedUser(user);

          if (saved) {
            let displayName = user.displayName;
            let firstName = 'Unknown';
            let lastName = 'Creator';
            if (displayName && displayName.length > 0) {
              let parts = displayName.split(' ');
              firstName = parts.length > 0 ? parts[0] : firstName;
              lastName = parts.length > 1 ? parts[1] : lastName;
            }
            saved = await userManager.updateUserBasicDetails(
              user.uid,
              firstName,
              lastName,
              email
            );

            const prefilledDetails = recruitmentManager.grabDetails();
            // console.log(
            //   'recruitmentManager prefilledDetails:',
            //   prefilledDetails
            // );
            // let's try to do the claim account logic here

            if (prefilledDetails) {
              try {
                const attemptSave = await recruitmentManager.saveClaimAccountDetailsToCurrentUser(
                  prefilledDetails
                );
                const { success, error } = attemptSave;

                if (success) {
                  console.error('error claiming user account: ', error);
                } else {
                  console.error(
                    'error claiming user account: ',
                    error ?? 'unknown error'
                  );
                }
              } catch (error) {
                console.error('error claiming user account: ', error);
                //continue..
              }
            }
          } else {
            callback(getI18n().t('couldnt_auth_user'), false);
          }

          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null

          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          const isNewUser = result.additionalUserInfo.isNewUser;
          callback(null, isNewUser);
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          var errorCode = error.code;
          var errorMessage = error.message;
          // ...
          callback(errorMessage, false);
        });
    }
  }

  /**
   * Register a user
   * @param values
   */
  startSignup(values) {
    return new Promise((resolve, reject) => {
      this.authRuntimeConfig = {};

      // console.log('startSignup 1...');

      this.authRuntimeConfig['user'] = values;
      this.authRuntimeConfig['captcha'] = new firebase.auth.RecaptchaVerifier(
        'recaptcha-hidden-container',
        {
          size: 'invisible',
        }
      );

      // console.log('startSignup 2...');

      firebase
        .auth()
        .signInWithPhoneNumber(
          values['phone'],
          this.authRuntimeConfig['captcha']
        )
        .then((confirmationResult) => {
          this.authRuntimeConfig['phoneCodeVerification'] = confirmationResult;
          resolve(true);
        })
        .catch((error) => {
          // console.log(
          // TAG,
          // 'Failed to send verification code. Finish with error: ',
          // error
          // );
          resolve(false);
        });
    });
  }

  /**
   * Signup second step - validating the phone code and proceed if succeeded.
   * @param verificationCode
   * @return {Promise<unknown> {success: boolean, isNewUser:boolean}
   */

  finishSignup(verificationCode, autoDetailsUpdate = true) {
    return new Promise(async (resolve) => {
      if (!this.authRuntimeConfig['phoneCodeVerification']) {
        // console.log(TAG, 'no verification function, aborting. ');
        return resolve({ success: false, isNewUser: false });
      }

      try {
        let result = await this.authRuntimeConfig[
          'phoneCodeVerification'
        ].confirm(verificationCode);
        let { user } = result;

        VLFlowManager.sendFlowAnalytics(new AuthVerified('phone'));
        // console.log(TAG, 'User is authenticated. Apply authentication flow.');
        let saved = await this.onAuthenticatedUser(user);

        if (saved && autoDetailsUpdate) {
          let firstname = this.authRuntimeConfig['user'].firstname ?? 'Unknown';
          let lastname = this.authRuntimeConfig['user'].lastname ?? 'Creator';
          saved = await userManager.updateUserBasicDetails(
            user.uid,
            firstname,
            lastname,
            null
          );
        }

        const isNewUser = result.additionalUserInfo.isNewUser;
        resolve({ success: true, isNewUser: isNewUser });
      } catch (error) {
        // console.log(TAG, 'code verification failed. Error: ', error);
        return resolve({ success: false, isNewUser: false });
      }
    });
  }

  /**
   * Validating thre suggestecdfs
   * @param userId
   * @param username
   */
  async validateSuggestedUserName(username) {
    const url = `${api.VALIDATE_USERNAME}?user_name=${username}`;

    // console.log(TAG, 'validating username..', url);
    try {
      const result = await fetch(url, {
        method: 'GET',
        headers: await this.getRequestHeaders(),
      }).then((response) => {
        return response.status === 200;
      });

      return result;
    } catch (error) {
      // console.log(TAG, 'Failed to validate the suggested user name');
      return false;
    }
  }

  /**
   * Booting up a user - filling up stores and other required elements if user exists.
   * Besides that, adding a listener to Firebase state change.
   * @return {Promise<void>}
   */
  async initUser() {
    // console.log(TAG, 'Initializing user');

    /* Add a listener to state changes from Firebase. */
    this.onAuthChanged();
    // Turn off phone auth app verification.
    if (
      app.isProduction() === false &&
      /automation=true/.test(window.location.search)
    ) {
      firebase.auth().settings.appVerificationDisabledForTesting = true;
    } else if (window.location.host.indexOf('localhost') !== -1) {
      firebase.auth().settings.appVerificationDisabledForTesting = true;
    }

    //let's try to load a cache user (in case they had an existing session  )
    this.userInitialized = true;
  }

  /**
   * Return user's details that stored in main Store
   * @return {null|*}
   */
  getUser() {
    let userStore = selectUserState(store.getState());
    if (userStore) return userStore['details'];
    return null;
  }

  /**
   * Return the user's id - helper method to prevent boilerplate. x`
   * @return {*}
   */
  getUserId() {
    let user = this.getUser();
    return user ? user['id'] : null;
  }

  /**
   * Return boolean response if user is connected (based on Store).
   * App update store once Firebase notify - therefor it should be the most updated boolean
   * @return boolean
   */
  isConnected() {
    let userStore = selectUserState(store.getState());
    return userStore && userStore['isConnected'];
  }

  /* LEGACY: */

  async signOut() {
    // utils.log.debug('⚠️ signing user out');
    firebase
      .auth()
      .signOut()
      .then(function () {
        userManager.currentUser = null;
        userManager.currentUserAccountDetails = null;
        userManager.currentUserId = null;
        userManager.currentFirebaseUser = null;
        userManager.paymentMethods = null;
        userManager.tokenId = null;
        userManager.currentUserAccountDetailsObserver = null;
      })
      .catch(function (error) {
        // An error happened.
        // utils.log.debug('🚨 Error signing out - ', error);
      });

    analytics.logout();
  }

  async refreshToken() {
    if (!firebase.auth().currentUser) {
      return null;
    }
    const getIdToken = await firebase.auth().currentUser.getIdToken(false);
    this.tokenId = getIdToken;
    // utils.log.debug('token refreshed: ', getIdToken);
    return getIdToken;
  }
  requestHeaders() {
    var headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Accept-Language': getI18n().language,
      locale: getI18n().language,
    };

    if (this.hasToken()) {
      headers['Authorization'] = `Bearer ${this.tokenId}`;
    }
    return headers;
  }

  isLoggedIn() {
    //utils.log.debug("current fb user: ", firebase.auth().currentUser);
    // alert(this.currentUserId)
    return this.currentUserId != null;
    // return this.currentUserId;
  }

  hasToken() {
    return this.tokenId != null && this.tokenId.length > 0;
  }

  currentUserCurrency() {
    if (this.currentUser) {
      return this.currentUser.currency ?? (isHebrew ? 'ILS' : 'USD');
    } else {
      return isHebrew ? 'ILS' : 'USD';
    }
  }
  currentUserCurrencySymbol() {
    return this.currentUserCurrency() === 'ILS' ? '₪' : '$';
  }

  currentUserIntercomSettings() {
    let dic = {};

    if (this.currentUser) {
      const user = this.currentUser;
      dic.name = user.fullName();
      dic.email = user.email;
      dic.user_id = user.id;
    }
    return dic;
  }

  /**
   * Listener for authentication changes - triggered when Firebase update user's state
   * -- any analytics, segmentation, or other settings should assigned once again once the state is update --
   */
  onAuthChanged() {
    firebase.auth().onAuthStateChanged(async (user) => {
      /* Dispatch methods accordingly to user's state */
      user ? this.onAuthenticatedUser(user) : this.onNotAuthenticatedUser();

      this.fetchAuthRedirectResult(); //check for fetch auth
    });
  }

  /**
   * Dispatched when a user is not authenticated - logout or base state -
   * Method making sure everything is set to an anonymous session
   * @return {Promise<boolean>}
   */
  async onNotAuthenticatedUser() {
    // console.log(TAG, 'User is not authenticated.');

    store.dispatch(resetUser());
    store.dispatch(resetAccountDetails());
    userManager.currentFirebaseUser = null;
    userManager.currentUserId = null;

    return true;
  }

  /**
   * Dispatch when a user become authenticated - convert a Firebase User object to Kre8 User
   * @param user
   * @return {Promise<boolean>}
   */
  async onAuthenticatedUser(user) {
    // utils.log.debug('✅ onAuthenticatedUser: ', user);

    this.currentFirebaseUser = user;

    let userId = user.uid;
    let local = VLUser.local(userId);

    if (local) {
      userManager.currentUser = local;
    } else {
      userManager.currentUser = new VLUser();
    }
    userManager.currentUser.id = userId;
    userManager.currentUser.uid = userId;
    userManager.currentUserId = userId;

    //update with cached data
    store.dispatch(updateUserDetails(userManager.currentUser));
    store.dispatch(updateUserId(userId));
    this.fetchUserActivity();

    // const refresh = await userManager.refreshToken();
    // utils.log.debug('👋 currentUserId', userManager.currentUserId); // TODO: Figure what's the goal?

    /* Some hack to deal with the sign up process */ if (
      localStorage.getItem('first_name')
    ) {
      this.populateWithAuthDetails();
    }

    /* loading expanded details object */
    // console.log(TAG, 'Loading extended user details if available.');
    let userDetails = await userManager.fetchCurrentUserDetails();

    try {
      userManager.updateUserDetails();
    } catch (error) {
      // console.log(
      //   TAG,
      //   'Failed to update user details. Ended with error: ',
      //   error
      // );
    }

    try {
      socialManager.fetchSocial(userId);
    } catch (error) {
      // console.log(
      //   TAG,
      //   'Failed to fetch social details for user. Ended with error: ',
      //   error
      // );
    }

    // console.log(TAG, '✅ Submitted update user details from auth change');

    /* User id is available, finalizing boot */
    if (userId && userDetails) {
      let { user } = userDetails;

      /* Update store */
      store.dispatch(updateConnectStatus(true));
      store.dispatch(updateUserDetails(user));
      store.dispatch(updateUserId(userId));
    }

    this.addUseAccountDetailsObserver(userId);
    this.addUserDetailsObserver(userId);
    return true;
  }

  /**
   * Updating basic details about the user
   * @param id
   * @param firstName
   * @param lastName
   * @param email
   * @return {Promise<boolean>}
   */
  async updateUserBasicDetails(id, firstName, lastName, email) {
    // console.log(
    // TAG,
    //   'Updating user [ ',
    //   id,
    //   ' ] details: [ First name: ',
    //   firstName,
    //   ', Last name: ',
    //   lastName,
    //   ' Email: ',
    //   email,
    //   ' ]'
    // );

    if (!id || !firstName || !lastName) {
      // console.log(TAG, 'missing parameter. Aborting update basic details.');
      return false;
    }

    let params = this.currentUser.toMap();

    params['first_name'] = firstName;
    params['last_name'] = lastName;
    params['email'] = email;
    params['platform'] = 'web';
    params['version'] = constants.CR_APP_VERSION;
    params['locale'] = navigator.language;

    params['uid'] = id;
    params['id'] = id;
    params['last_updated'] = new Date().getTime();

    if (process.env.REACT_APP_ENV === 'test') params['is_test'] = true;

    let body = JSON.stringify(params);
    let headers = this.requestHeaders();

    const results = await fetch(api.UPDATE_USER, {
      method: 'POST',
      headers: headers,
      body: body,
    });

    return results.status === 200;
  }

  /**
   * Updating User's Social networks
   * @param networks
   * @return {Promise<boolean>}
   */
  async updateUserSocialAccounts(networks) {
    let { facebook, instagram, twitter, youtube, tiktok, website } = networks;

    profileManager.updateProfileSocialNetworks(
      this.getUserId(),
      instagram ? instagram['value'] : null,
      facebook ? facebook['value'] : null,
      youtube ? youtube['value'] : null,
      twitter ? twitter['value'] : null,
      tiktok ? tiktok['value'] : null,
      website ? website['value'] : null
    );

    return true;
  }

  /** Sign up the user using the inputted base info
   * @param {*} firstName The user's first name
   * @param {*} lastName The user's last name
   * @param {*} email The user's email
   * @deprecated
   * @see .updateUserBasicDetails();
   */
  async signUpUser(id, firstName, lastName, email) {
    /* Forwarder */
    this.updateUserBasicDetails(id, firstName, lastName, email);

    // if (!id || !firstName || !lastName || !email) {
    //   return false;
    // }
    //
    // var params = this.currentUser.toMap();
    // utils.log.debug('sign up user...', firstName, lastName, email);
    // params['first_name'] = firstName;
    // params['last_name'] = lastName;
    // params['email'] = email;
    // params['platform'] = 'web';
    // params['version'] = constants.CR_APP_VERSION;
    // params['locale'] = navigator.language;
    // params['uid'] = id;
    // params['id'] = id;
    //
    // if (process.env.REACT_APP_ENV === 'test') {
    //   params['is_test'] = true;
    // }
    //
    // let currentDate = new Date();
    // let timestamp = currentDate.getTime();
    // params['last_updated'] = timestamp;
    //
    // var body = JSON.stringify(params);
    //
    // const url = api.UPDATE_USER;
    //
    // let headers = this.requestHeaders();
    //
    // const results = await fetch(url, {
    //   method: 'POST',
    //   headers: headers,
    //   body: body,
    // });
    // let success = results.status === 200;
    // //utils.log.debug("signUpUser results:", results);
    // return success;
  }
  /** Updates the user details on the server
   * @param {*} firstName The user's first name
   * @param {*} lastName The user's last name
   * @param {*} email The user's email
   */
  async updateUserDetails() {
    //update local user first
    if (!this.currentUser) {
      // utils.log.debug("skipping updating user details...");
      return;
    }
    // utils.log.debug('updating user details...');
    var params = this.currentUser.toMap();
    params['platform'] = 'web';
    params['version'] = constants.CR_APP_VERSION;
    params['locale'] = navigator.language;
    params['uid'] = this.currentFirebaseUser && this.currentFirebaseUser.uid;

    let currentDate = new Date();
    let timestamp = currentDate.getTime();
    params['last_updated'] = timestamp;

    var body = JSON.stringify(params);
    // utils.log.debug('submitting user details update...', body);

    const url = api.UPDATE_USER;
    //utils.log.debug(`submit ${body} to ${url}`);

    let headers = this.requestHeaders();
    //utils.log.debug("signUpUser headers:", headers);

    const results = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });
    let success = results.status === 200;
    //utils.log.debug("signUpUser results:", results);
    return success;
  }
  /**
   * Fetches the current user's (from token) user obj and payment obj
   * (Including Private Data)
   */
  async fetchCurrentUserDetails() {
    const url = `${api.FETCH_ACCOUNT_DETAILS}`;
    const headers = await this.getRequestHeaders();
    // utils.log.debug('headers: ', headers);

    /* if no token, exit */
    if (!firebase.auth().currentUser) {
      return null;
    }

    const results = await fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then(async (response) => response.json())
      .then(async (json) => {
        let error = VLError.fromJSON(json.error);
        if (error) {
          utils.log.error('fetchCurrentUserDetails error:', error);
          return null;
        }

        let vlUser = VLUser.fromJSON(json.user);
        if (vlUser) {
          vlUser.saveToLocal();

          let paymentMethods = json.payment_methods;
          userManager.paymentMethods = paymentMethods;
          store.dispatch(updatePaymentMethods(paymentMethods));
          let purchasedSessionsResult = json.purchased_sessions;
          var purchasedSessions = [];
          if (purchasedSessionsResult) {
            purchasedSessions = purchasedSessionsResult.map((json) =>
              Session.fromJSON(json)
            );
          }

          let subscriptionsResult = json.subscriptions;
          var subscriptions = [];
          if (subscriptionsResult) {
            subscriptions = subscriptionsResult.map((json) =>
              CloseFan.fromJSON(json)
            );
          }

          this.currentUser = vlUser;
          return {
            user: vlUser,
            paymentMethods: paymentMethods,
            purchasedSessions: purchasedSessions,
            subscriptions: subscriptions,
          };
        } else {
          return {};
        }
      })
      .catch(async (error) => {
        utils.log.error('fetchCurrentUserDetails error:', error);
        return null;
      });
    return results;
  }

  /**
   * Fetches the user from a profile id (roytor, roy, otg...)
   * @param {*} profileId Profile id of the creator
   * @param {*} incomingURL
   */
  async fetchUserFromProfile(profileId, sendBackOnError = false) {
    if (!profileId) {
      return null;
    }

    const url = `${api.FETCH_USER_DETAILS}?profile_id=${profileId}`;

    let headers = await this.getRequestHeaders();
    const results = await fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then(async (response) => response.json())
      .then(async (json) => {
        // utils.log.debug("Success fetching user from profile id:", json);
        if (json.error && sendBackOnError) {
          return json;
        }
        let vlUser = VLUser.fromJSON(json);
        // utils.log.debug('Success fetching user from profile id:', vlUser);
        let vlError = VLError.fromJSON(json);
        if (!vlUser) {
          utils.log.error('Failed fetching user from profile id:', json);
          return;
        }
        let postsJSON = json.posts;
        let transactionReviewsJSON = json.reviews;
        let creationsJSON = json.creations;
        // utils.log.debug('postsJSON:', postsJSON);
        //posts parsing (request videos)

        if (postsJSON) {
          let posts = postsJSON.map((json) => {
            return VLPost.fromJSON(json);
          });
          vlUser.posts = posts;
        } else {
          vlUser.posts = [];
        }

        //transaction reviews parsing
        if (transactionReviewsJSON) {
          let transactionReviews = transactionReviewsJSON.map((json) => {
            return VLTransactionReview.fromJSON(json);
          });
          vlUser.transactionReviews = transactionReviews;
        } else {
          vlUser.transactionReviews = [];
        }
        //creation parsing
        if (creationsJSON) {
          let creations = creationsJSON.map((json) => {
            return VLCreationItem.fromJSON(json);
          });
          vlUser.creations = creations;
        } else {
          vlUser.creations = [];
        }
        // utils.log.debug("posts:", posts);
        vlUser.saveToLocal();
        return vlUser;
      })
      .catch(async (error) => {
        console.error('Error fetching profileid :', profileId);
        console.error('Error fetching user from profile:', error);
        return null;
      });
    return results;
  }

  async fetchCategoryBusiness(categoryId) {
    var url = `${api.FETCH_CATEGORY_INFO}?categoryId=${categoryId}`;

    // utils.log.debug('fetching category info..', categoryId);
    let headers = await this.getRequestHeaders();
    const results = await fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then(async (response) => response.json())
      .then(async (json) => {
        return json;
      })
      .catch(async (error) => {
        console.error('Error fetching category id :', categoryId);
        console.error('Error fetching user from profile:', error);
        return null;
      });
    return results;
  }
  /**
   * Fetches the user from a profile id (roytor, roy, otg...)
   * @param {*} categoryId Category id to fetch
   */
  async fetchCategoryInfo(categoryId, categoryTag) {
    var url = `${api.FETCH_CATEGORY_INFO}?categoryId=${categoryId}`;
    if (categoryTag) {
      url = `${url}&categoryTag=${categoryTag}`;
    }

    // utils.log.debug('fetching category info..', categoryId);
    let headers = await this.getRequestHeaders();
    const results = await fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then(async (response) => response.json())
      .then(async (json) => {
        // utils.log.debug('✅ fetch category infon result:', json);
        let result = {};
        result.category = VLCategory.fromJSON(json.category);
        // utils.log.debug('category...', VLCategory.fromJSON(json.category));
        result.error = VLError.fromJSON(json.error);
        return result;
      })
      .catch(async (error) => {
        console.error('Error fetching category id :', categoryId);
        console.error('Error fetching user from profile:', error);
        return null;
      });
    return results;
  }

  /**
   * Fetches the user from a profile id (roytor, roy, otg...)
   * @param {*} categoryId Category id to fetch
   */
  async fetchAgencyInfo(agencyId) {
    const url = `${api.FETCH_CATEGORY_INFO}?agencyId=${agencyId}`;

    let headers = await this.getRequestHeaders();
    const results = await fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then(async (response) => response.json())
      .then(async (json) => {
        // utils.log.debug('✅ fetch category infon result:', json);
        let result = {};
        result.category = VLCategory.fromJSON(json.category);
        // utils.log.debug('category...', VLCategory.fromJSON(json.category));
        result.error = VLError.fromJSON(json.error);
        return result;
      })
      .catch(async (error) => {
        console.error('Error fetching category id :', agencyId);
        console.error('Error fetching user from profile:', error);
        return null;
      });
    return results;
  }

  async getRequestHeaders() {
    const refresh = await this.refreshToken();
    return this.requestHeaders();
  }
  /**
   * Fetches the user activity for the current user - requests, sessions, etc
   */
  async fetchUserActivity() {
    const url = new URL(`${api.FETCH_ACTIVITY}`);

    /* refresh token */
    const params = { show_custom_requests: true };
    url.search = new URLSearchParams(params).toString();

    let headers = await this.getRequestHeaders();
    // utils.log.debug('🏃‍♂️ fetching user activity w/ headers...', headers);
    // utils.log.debug('🏃‍♂️ fetching user activity w/ url...', url);
    const results = await fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then(async (response) => response.json())
      .then(async (json) => {
        // utils.log.debug('Success:', json);
        let allRequestsJSON = json.all_requests;
        var allRequests = [];
        for (var i = 0; i < allRequestsJSON.length; i++) {
          let bookingRequestJSON = allRequestsJSON[i];
          let br = VLBookingRequest.fromJSON(bookingRequestJSON);
          allRequests.push(br);
        }

        let response = {};
        response.bookingRequests = allRequests;
        //dispatch to redux store
        store.dispatch(updateBookingRequests(allRequests));
        return response;
      })
      .catch(async (error) => {
        console.error('fetchuseractivity Error:', error);
        return null;
      });
    return results;
  }

  async fetchPublicUserData(user_id) {
    // utils.log.debug('fetching user...', user_id);
    const db = firebase.firestore();
    const usersRef = db
      .collection(constants.FIREBASE_COLLECTION_USERS)
      .doc(user_id);
    const fetchUsersSnapshot = await usersRef.get();
    const userJson = fetchUsersSnapshot.data();

    // utils.log.debug('fetchPublicUserData:', userJson);

    let user = VLUser.fromJSON(userJson);
    return user;
  }

  /**
   * Add a user detail listener for a given user which listens updates
   * @param {*} user_id The user id of the user we want to adda User DB data listener on
   * @param {*} onUpdated On Update result callback where value is the returns user details obj (VLUser)
   */
  addUserDetailsListener(user_id, onUpdated) {
    // utils.log.debug('fetching user...', user_id);
    let fire = firebase.firestore();
    let doc = fire.collection(constants.FIREBASE_COLLECTION_USERS).doc(user_id);
    doc.onSnapshot(
      (docSnapshot) => {
        // utils.log.debug(`Received user doc snapshot: ${docSnapshot}`);
        if (docSnapshot.exists) {
          // utils.log.debug("✅ snapshot data exists:", docSnapshot.data());
          let userDetails = VLUser.fromJSON(docSnapshot.data());
          onUpdated(userDetails);
        } else {
          // utils.log.debug(`🚨 snapshot data does not exists`);
        }
      },
      (err) => {
        // utils.log.debug(`Encountered error: ${err}`);
      }
    );
  }

  async fetchBasicUserInfo(userId) {
    if (!userId) {
      return null;
    }
    var queryUserId = userId.replace('+', '%2B');
    const url = `${api.FETCH_BASIC_USER_DETAILS}?user_id=${queryUserId}`;

    const results = await fetch(url, {
      method: 'GET',
      headers: await this.getRequestHeaders(),
    })
      .then(async (response) => response.json())
      .then(async (json) => {
        //utils.log.debug("Success:", json);
        let vlUser = VLUser.fromJSON(json);
        vlUser.saveToLocal();
        return vlUser;
      })
      .catch(async (error) => {
        //console.error("Error:", error);
        return null;
      });
    return results;
  }

  async didViewProfile(user) {
    // utils.log.debug('called didViewProfile:', user);
    if (!user) {
      return null;
    }
    const url = api.DID_VIEW_PROFILE;

    var params = {
      user_id: user.id, //user id of user we viewed
      profile_id: user.profileId, //profile id of user we are viewing
      location: 'web', //location of where we are viewing from
    };

    if (this.currentUser !== null) {
      params['viewer_id'] = utils.currentUser().id; //the id of the current user
    } else {
      params['viewer_id'] = 'unauthed';
    }

    const body = JSON.stringify(params);
    const headers = await this.getRequestHeaders();
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });

    let json = await response.json();
    let success = response.status === 200;
    // utils.log.debug(response);
    // utils.log.debug('didViewProfile:', response);
    return success;
  }

  async saveUserProfile(
    forcedId,
    firstName,
    lastName,
    profileId,
    title,
    currency,
    personalAvailability,
    personalPrice,
    businessAvailability,
    businessPrice,
    desc,
    tags,
    categoryId,
    profilePictureURL,
    profileVideoURL,
    managerId,
    agencyId,
    referralId,
    active,
    verified,
    customURL,
    instagramURL,
    facebookURL,
    youtubeURL,
    twitterURL,
    tiktokURL
  ) {
    var params = {};
    params['id'] = forcedId;
    params['first_name'] = firstName;
    params['last_name'] = lastName;
    params['profile_id'] = profileId;
    params['title'] = title;
    params['available_for_booking'] = personalAvailability;
    params['currency'] = currency;
    params['price'] = personalPrice;
    params['available_for_booking_business_web'] = businessAvailability;
    params['available_for_booking_business'] = businessAvailability;
    params['price_business'] = businessPrice;
    params['desc'] = desc;
    params['manager_id'] = managerId;
    params['agency_id'] = agencyId;
    params['referral_id'] = referralId;
    params['active'] = active;
    params['verified'] = verified;

    params['custom_url'] = customURL;
    params['instagram_url'] = instagramURL;
    params['facebook_url'] = facebookURL;
    params['youtube_url'] = youtubeURL;
    params['twitter_url'] = twitterURL;
    params['tiktok_url'] = tiktokURL;

    if (tags && tags.length > 0 && Array.isArray(tags) === false) {
      // utils.log.debug('tags === ', tags);
      // let tagsJSONArray = JSON.stringify(
      //   tags.split(",").map((str) => str.trim())
      // );
      params['tags'] = tags.split(',').map((str) => str.trim());
    }

    params['category_id'] = categoryId;
    params['profilePictureURL'] = profilePictureURL;
    params['profileVideoURL'] = profileVideoURL;

    // utils.log.debug('updating profile...', params);

    var body = JSON.stringify(params);

    const url = api.UPDATE_PROFILE;
    // utils.log.debug(`submit ${body} to ${url}`);

    let headers = this.requestHeaders();
    //utils.log.debug("signUpUser headers:", headers);

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });

    let json = await response.json();
    let result = {};
    if (json.error) {
      result.error = VLError.fromJSON(json.error);
    }
    result.success = response.status === 200;
    // utils.log.debug('update profile results:', result);
    return result;
  }

  populateWithAuthDetails() {
    userManager.currentUser.firstName = localStorage.getItem('first_name');
    userManager.currentUser.lastName = localStorage.getItem('last_name');
    userManager.currentUser.email = localStorage.getItem('email');
  }

  //COOKIES

  updateReferralId(referralId) {
    if (!referralId) {
      return;
    }
    this.referralId = referralId;
  }

  //Listeners
  /* Logic */
  currentUserAccountDetailsObserver = null;
  addUseAccountDetailsObserver(userId) {
    if (!userId) {
      utils.log.error(
        'attempting to call addUseAccountDetailsObserver without a user'
      );
      return;
    }
    // utils.log.debug(
    //   'initializing user account details listener for user:',
    //   userId
    // );
    let fire = firebase.firestore();
    let doc = fire
      .collection(constants.FIREBASE_COLLECTION_ACCOUNT_DETAILS)
      .doc(userId);
    this.currentUserAccountDetailsObserver = doc.onSnapshot(
      (docSnapshot) => {
        // // utils.log.debug(
        //   `Received account details doc snapshot: ${docSnapshot}`
        // );
        if (docSnapshot.exists) {
          // utils.log.debug("✅ snapshot data exists:", docSnapshot.data());
          let accountDetails = VLAccountDetails.fromJSON(docSnapshot.data());
          if (
            accountDetails &&
            userManager.currentUser &&
            userManager.currentUser.id === accountDetails.id
          ) {
            // utils.log.debug(`✅ updated account details`);
            store.dispatch(updateAccountDetails(accountDetails));
            userManager.currentUserAccountDetails = accountDetails;

            /* EMAIL IS STORED IN ACCOUNT_DETAILS */
            const email = accountDetails.email;
            if (email && email.length > 0) analytics.setEmail(email);
          }
        } else {
          // utils.log.debug(`🚨 snapshot data does not exists`);
        }
      },
      (err) => {
        // utils.log.debug(`Encountered error: ${err}`);
      }
    );
  }

  /**
   * Adds a listener to the user's node and notifies on updates
   * @param {*} userId The user id to add observer for - should be only current logged in user
   */
  addUserDetailsObserver(userId) {
    // utils.log.debug(`addUserDetailsObserver:`, userId);
    this.addUserDetailsListener(userId, (result) => {
      if (result) {
        store.dispatch(updateUserDetails(result));

        const user = result;
        if (!user.id) return;

        const userId = user.id;
        const isCreator = user.isUserCreator();
        const fullName = user.fullName();
        const profileId = user.profileId;
        const email = user.email;

        /* THIS IS WHERE WE UPDATE USER PROPERTIES - SINGLE POINT OF ENTRY */
        analytics
          .setUserId(userId)
          .setIsCreator(isCreator)
          .setName(fullName)
          .setProfileId(profileId);

        //SUPPORT OLD ACCOUNTS
        if (email && email.length > 0) analytics.setEmail(email);
      }
    });
  }
  //Fast signup methods

  /**
   *
   * @param {*} file Image file to upload
   * @param {*} success
   */
  updateSignupProfilePicture(file, title) {
    return new Promise((resolve) => {
      uploadManager.uploadProfilePicture(
        file,
        async (downloadURL, progress, error) => {
          if (error) {
            // console.log(
            //   TAG,
            //   'failed to upload profile picture. Error: ',
            //   error
            // );
            return resolve(false);
          }

          if (downloadURL) {
            const updateProfile = await profileManager.updateProfilePicture(
              downloadURL,
              title ? title : 'profile picture'
            );
            return resolve(updateProfile.success);
          }
        }
      );
    });
  }

  async userAppliedForNoComissionBenefit() {
    const db = firebase.firestore();

    let updateObj = { no_commission_application_status: 'applied' };
    await db
      .collection(constants.FIREBASE_COLLECTION_ACCOUNT_DETAILS)
      .doc(this.getUserId())
      .update(updateObj);

    return true;
  }

  async userDidCompleteOnboarding() {
    const db = firebase.firestore();

    let updateObj = { did_complete_onboarding: true };
    await db
      .collection(constants.FIREBASE_COLLECTION_ACCOUNT_DETAILS)
      .doc(this.getUserId())
      .update(updateObj);

    return true;
  }

  async updateUserType(isCreator) {
    const db = firebase.firestore();

    let updateObj = { is_creator: isCreator };
    await db
      .collection(constants.FIREBASE_COLLECTION_USERS)
      .doc(this.getUserId())
      .update(updateObj);

    return true;
  }

  /**
   * Push a card to the payment methods array
   * @param {*} card Payment method card obj
   */
  addPaymentMethod(card) {
    if (
      userManager.paymentMethods &&
      Array.isArray(userManager.paymentMethods)
    ) {
      userManager.paymentMethods.push(card);
    } else {
      userManager.paymentMethods = [card];
    }
  }
}

var userManager = new UserManager();
export default userManager;
