import React from "react";
import styles from "../AuthDialog.module.scss";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { withTranslation } from 'react-i18next';
import { Formik, Field, Form, ErrorMessage  } from 'formik';
import * as Yup from 'yup';
import AppIcon from '../../images/app_icon_512.png';
import ClassNames from 'classnames';
import { RoundLoader } from '../../components/loader/roundLoader.component';
import { Image } from '../../components/image/image.component';

interface PersonalLink {
  onChange?: any;
  value: any,
  placeholder?: string,
  tabIndex?: number,
  defaultValue?: any
}

const PersonalLink: React.FC<PersonalLink> = ({ onChange, value, placeholder , tabIndex, defaultValue}) => {

  if(value.length === 0) value = defaultValue;

  return <div className={styles.kre8LinkInput}>
    <Image src={AppIcon} />
    <em>kre8.tv/</em>
    <input placeholder={placeholder} value={value} name={"link"} onChange={value => {
      let { target } = value;
      onChange(target.value);
    }} tabIndex={tabIndex}/>
  </div>
};

class CreateAccountBody extends React.Component<any, any>
{
  form?: any = null;

  constructor(props: any) {
    super(props);
  }

  get validationSchema()
  {
    let { t, isSignup, creatorSignup } = this.props;

    /* Return schema for signin flow */
    if(!isSignup)
    {
      return Yup.object().shape({
        phone: Yup.string().required(t('required_phone')).test('valid_phone', t('required_phone'), (value) => !!value && isValidPhoneNumber(value))
      })
    }

    let signupFields: any = {
      firstname: Yup.string().required(t('required_firstname')),
      lastname: Yup.string().required(t('required_lastname')),
      email: Yup.string().email(t('valid_email')).required(t('required_email')),
      agree: Yup.boolean().equals([true]),
      phone: Yup.string().required(t('required_phone')).test('valid_phone', t('required_phone'), (value) => !!value && isValidPhoneNumber(value))
      // link: Yup.string().required(t("required_link")),
    };

    if(creatorSignup) signupFields["link"] = Yup.string().required(t('required_link'));

    return Yup.object().shape(signupFields)
  }

  get initialValues()
  {
    return {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      agree: false,
      link: ''
    };
  }

  buildDefaultPersonalLink(firstName: string, lastName: string)
  {
    if(firstName) firstName = firstName.toLowerCase();
    if(lastName) lastName = lastName.toLowerCase();

    return `${firstName}${lastName ? `-${lastName}` : ""}`;
  }

  render()
  {
      let { t, className, i18n, onCreateAccount, loading, userLinkError, isSignup, creatorSignup } = this.props;

      return [<div id="recaptcha-hidden-container"/>, <Formik initialValues={this.initialValues} validationSchema={this.validationSchema} onSubmit={onCreateAccount}>
        {(form: any) => {

          let { values, setFieldValue, errors, submitForm } = form;
          // console.log("ERROR: ", errors, "Values: ", values);

          return <Form className={className} onSubmit={async event => { // This is a hack to support auto-link generated

            event.preventDefault();
            event.stopPropagation();

            if(values.link.length === 0) setFieldValue("link", this.buildDefaultPersonalLink(values.firstname, values.lastname), false);
              setTimeout(() => submitForm(), 50);
            }}>

            {isSignup && <div>
              <Field name={"firstname"} type="text" placeholder={t('first_name_placeholder')} aria-label={t('first_name_placeholder')} tabIndex={1} />
              <span><ErrorMessage name={"firstname"} /></span>
            </div>}

            {isSignup && <div>
              <Field name={"lastname"} type="text" placeholder={t('last_name_placeholder')} aria-label={t('last_name_placeholder')} tabIndex={2} />
              <span><ErrorMessage name={"lastname"} /></span>
            </div>}

            {isSignup && <div>
              <Field name={"email"} type="email" placeholder={t('email')} aria-label={t('email')} tabIndex={3} />
              <span><ErrorMessage name={"email"} /></span>
            </div>}

            <div>
              <Field name={"phone"} >
                {(properties: any) => {
                  let { field } = properties;
                  return <PhoneInput
                    onChange={value => setFieldValue("phone", value ? value : "")}
                    value={field.value}
                    placeholder={t('phone_number_in_form')}
                    defaultCountry={i18n.language === 'he' ? 'IL' : 'US'}
                    tabIndex={4}
                    name={"phone"}
                  />;
                }}
              </Field>
              <span><ErrorMessage name={"phone"} /></span>
            </div>

            {isSignup && creatorSignup && <div>
              <Field name={"link"} >
                {(properties: any) => {
                  let { field } = properties;
                  return <PersonalLink
                    onChange={(value: any) => setFieldValue("link", value)}
                    value={field.value}
                    placeholder={t('link_alias_form')}
                    tabIndex={5}
                    defaultValue={this.buildDefaultPersonalLink(values.firstname, values.lastname)}
                  />;
                }}
              </Field>
              <span>{userLinkError}<ErrorMessage name={"link"} /></span>
            </div>}

            {isSignup && <div className={ClassNames([styles.checkbox, values.agree ? styles.checked : null])}>
              <Field name={"agree"} type={"checkbox"} id={"terms"} tabIndex={6} />
              <label htmlFor="terms">Creating an account means that you agree to the <a href={"https://docs.google.com/document/d/1niL9J8gsHlqS4BuND6oJ2askCnmC0w4WDAfgvMx1oNM/preview"} target={"_blank"}>Terms of service</a> and <a href={"https://docs.google.com/document/d/1xGdGilAfS1DQUPoWrNDlBZ1Zwkmbl6ouRbxumy6OQuo/preview"} target={"_blank"}>Privacy policy</a> of Kre8 platform</label>
              <span><ErrorMessage name={"agree"}/></span>
            </div>}

            <button type={"submit"} className={ClassNames([styles.ctaButton, styles.createAccountCTA])}>
              <span>{isSignup ? t("create_new_user") : t('re_login')}</span>
            </button>
          </Form>
        }}
      </Formik>,
      <span>{loading && <div className={styles.loadingOverlay}>
        <RoundLoader />
      </div>}</span>];
  }
}


export default withTranslation()(CreateAccountBody);