import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './PaywallContent.module.scss';
import { useTranslation } from 'react-i18next';
import VLUser from '../../../../model/vlUser';

import VLCreationItem from '../../../../model/vlCreationItem';
import userManager from '../../../../managers/userManager';
import { convertToPrice } from '../../../../utils/rhutils';
import { Box, Button, Card, CardContent } from '@material-ui/core';
import {
  centerFlexColumn,
  lclDirection,
} from '../../../../utils/bootstrapUtils';
import IconClose from '../images/icon_close_purple.svg';
// @ts-ignore
import CreditCardInput from 'react-credit-card-input';
import RedeemCode from '../../Promotions/RedeemCode';
import ProcessingOverlay from '../../Loading/ProcesingOverlay/ProcessingOverlay';
import { parse } from '../../../../utils/vlStrings';
import Toast from '../../../../Toasts/Toast';
import { validateCard } from '../../../../utils/paymentMethodUtils';
import { toPriceString } from '../../../../utils/priceUtils';
import { ButtonBase } from '@material-ui/core';
import PaymentMethodCardSquare from '../../../payment/PaymentMethodCardSquare';
import VLPaymentOption from '../../../../model/paymentOption';
import VLPromotion from '../../../../model/vlPromotion';
import VLFlowManager, {
  AddPaymentMethodCompleted,
  AddPaymentMethodFailure,
  AddPaymentMethodStart,
} from '../../../../managers/v2/VLFlowManager';
import paymentManager2 from '../../../../managers/v2/paymentManager2';

//@todo IMPLEMENT... pretty much ready
const ExistingPaymentMethods = () => {
  const methods = paymentManager2.existingPaymentMethods();

  const [activeMethod, setActiveMethod] = useState<VLPaymentOption | undefined>(
    undefined
  );

  return (
    <div>
      {methods.map((item: VLPaymentOption, index: number) => {
        return (
          <div>
            <PaymentMethodCardSquare
              key={index}
              title={`**** ${item.last4}`}
              imageURL={'google.com'}
              isActive={activeMethod?.id === item.id}
              handleClick={() => setActiveMethod(item)}
            />
            <div style={{ width: '15px', height: '100%' }} />
          </div>
        );
      })}
    </div>
  );
};

const CardInput = (props: any) => {
  const { t, i18n } = useTranslation();

  const cardInputTitle = props.cardInputTitle ?? t('confirm_purchase');
  return (
    <>
      <Box mt={5} color="primary.main">
        <div className={`${styles.confirmTitle}`}>{cardInputTitle}</div>
      </Box>
      <div className={`${styles.cardInput} ${centerFlexColumn()}`}>
        <CreditCardInput
          inputClassName={styles.input}
          containerClassName={styles.containerClassName}
          cardImageClassName={styles.cardImageClassName}
          fieldClassName={styles.fieldClassName}
          cardNumberInputProps={{
            value: props.creditNumber,
            onChange: (e: any) => {
              props.onChange(e, 'creditNumber');
            },
          }}
          cardExpiryInputProps={{
            value: props.creditExpirationDate,
            onChange: (e: any) => {
              props.onChange(e, 'creditExpirationDate');
            },
          }}
          cardCVCInputProps={{
            value: props.creditCvv,
            onChange: (e: any) => {
              props.onChange(e, 'creditCvv');
            },
          }}
          customTextLabels={{
            cardNumberPlaceholder: t('subscribe_number'),
            cvcPlaceholder: t('cvv'),
            expiryPlaceholder: t('subscribe_valid'),
          }}
        />
      </div>
    </>
  );
};
interface PaymentOptionCardProps {
  title?: string;
  price: string;
  originalPrice?: string;
  discount?: string;
  bestDeal: boolean;
  period: 'annually' | 'monthly';
  isActive: boolean;
  onClick: any;
  promotion?: any;
  hideRedeemCouponCode?: any;
}
const PaymentOptionCard = (props: PaymentOptionCardProps) => {
  const { t, i18n } = useTranslation();

  const customClass = props.isActive
    ? `${styles.active}`
    : `${styles.notActive}`;
  return (
    <ButtonBase
      onClick={props.onClick}
      className={`${customClass} d-flex flex-column flex-fill`}
    >
      <div className={`w-100 h-100`}>
        <div className="d-flex flex-column justify-content-center h-100">
          <div
            className={`${styles.title}`}
            style={{
              display: props.title && props.title.length > 0 ? 'block' : 'none',
            }}
          >
            {props.title}
          </div>
          <div className="d-flex flex-column">
            {props.discount && !props.promotion ? (
              <div className="w-100">
                <span className={`${styles.originalPrice}`}>
                  {props.originalPrice}
                </span>
                <span className={`${styles.discount}`}>{`${props.discount} ${t(
                  'deal_off'
                )}`}</span>
              </div>
            ) : null}
            <div className={`${styles.price}`}>{props.price}</div>
          </div>
          <div className={`${styles.period}`}>{props.period}</div>
        </div>
      </div>
    </ButtonBase>
  );
};

const PaymentOptionsViewContainer = (props: any) => {
  let show = props.type === 'subscription';
  if (!show || !props.creator) {
    return null;
  }

  return <PaymentOptionsView {...props} />;
};
const PaymentOptionsView = (props: any) => {
  const { creator, promotion } = props;
  const { t, i18n } = useTranslation();
  const [annualIsActive, setAnnualIsActive] = useState(false);

  const priceDetailsMonthly = creator.subscriptionPricingInfoMonthly(promotion);
  const priceDetailsAnnually = creator.subscriptionPricingInfoAnually(
    promotion
  );

  useEffect(() => {
    //setup once
    if (props.onSubscriptionChargeTypeSelected) {
      props.onSubscriptionChargeTypeSelected(
        creator.defaultSubscriptionPaymentData()
      );
    }
  }, []);

  const updateChargeType = (chargeType: 'monthly' | 'annually') => {
    // console.log('updateChargeType...');
    if (chargeType === 'monthly') {
      setAnnualIsActive(false);
      let info = creator.subscriptionPricingInfoMonthly(promotion);
      props.onSubscriptionChargeTypeSelected({
        price: info.price,
        currency: info.currency,
        chargeType,
      });
    } else {
      let info = creator.subscriptionPricingInfoAnually(promotion);
      setAnnualIsActive(true);
      props.onSubscriptionChargeTypeSelected({
        price: info.price,
        currency: info.currency,
        chargeType,
      });
    }
  };

  return (
    <div className={`${styles.pricingOptions} d-flex flex-row`}>
      <PaymentOptionCard
        onClick={() => updateChargeType('annually')}
        title={t('best_deal')}
        bestDeal={true}
        price={priceDetailsAnnually.title}
        originalPrice={priceDetailsAnnually.originalPrice}
        discount={priceDetailsAnnually.discount}
        period={t('period_annually')}
        isActive={annualIsActive}
        promotion={promotion}
      />
      <div style={{ width: '10px' }}></div>
      <PaymentOptionCard
        bestDeal={false}
        onClick={() => updateChargeType('monthly')}
        price={priceDetailsMonthly.title}
        period={t('period_monthly')}
        isActive={!annualIsActive}
        promotion={promotion}
      />
    </div>
  );
};

const OneTimePurchaseViewContainer = (props: any) => {
  let show = props.type === 'personalized_video';
  if (!show || !props.creator) {
    return null;
  }

  const creator: VLUser = props.creator;

  const imageURL: string = creator.profilePictureURL;
  const title: string = 'Personalized video from';
  const detail: string = creator.fullName();
  const priceString: string = toPriceString(
    props.priceCurrency,
    props.priceBid,
    true
  );
  const detailPriceString: string = 'one time';

  return (
    <OneTimePurchaseView
      imageURL={imageURL}
      title={title}
      detail={detail}
      priceString={priceString}
      detailPriceString={detailPriceString}
    />
  );
};

interface OneTimePurchaseViewProps {
  imageURL: string;
  title: string;
  detail: string;
  priceString: string;
  detailPriceString: string;
}

const OneTimePurchaseView = (props: OneTimePurchaseViewProps) => {
  return (
    <div className={`${styles.OneTimePurchaseView} d-flex flex-column`}>
      <div className="d-flex flex-row">
        <img className={styles.picture} src={props.imageURL}></img>
        <div className="d-flex flex-column flex-fill ml-2 mr-2">
          <div className={`${styles.title} flex-fill w-100`}>{props.title}</div>
          <div className={`${styles.detail} flex-fill`}>{props.detail}</div>
        </div>
      </div>

      <div className="d-flex flex-column">
        <Box p={2} textAlign="center">
          <div className={`${styles.priceTitle}`}>{props.priceString}</div>
        </Box>
        <div className={`${styles.priceDetail}`}>{props.detailPriceString}</div>
      </div>
    </div>
  );
};

const ItemPriceView = (props: any) => {
  return (
    <>
      <PaymentOptionsViewContainer {...props} />
      <OneTimePurchaseViewContainer {...props} />
    </>
  );
};

interface PaywallContentProps {
  type:
    | 'personalized_video'
    | 'promotional_video'
    | 'subscription'
    | 'campaign';
  creator?: VLUser; // we always need to have the user data
  onClose: any; // required - on close callback method
  onAddPaymentMethodSuccess: any; // required - onAddPaymentMethodSuccess
  priceBid: number; //required - price to charge
  priceCurrency: string; //required - currency to charge
  hideCloseButton?: boolean; //optional - hide close button
  isConnected?: boolean; // inherited - redux
  buttonText?: string; // optional - button text
  onShowAuth: any; //required - show auth (on submit before authed)
  onSubscriptionChargeTypeSelected?: any; //for callback when selecting the pricing options
  onActionButtonClicked?: any; //for when the action button is tapped
  onPromotionSet?: any; //optional - for when the promotion is set
  hideRedeemCouponCode?: any; //true if to hide the 'redeem code' button
  cardInputTitle?: any; //optional title above the card input box
}

interface CardInterface {
  number: string;
  expiration: string;
  cvv: string;
}

interface ErrorSnackBarProps {
  show: boolean;
  onClick: any;
  errorTitle: string;
  errorDetail: string;
}
const ErrorSnackBar = ({
  show,
  onClick,
  errorTitle,
  errorDetail,
}: ErrorSnackBarProps) => {
  const { t, i18n } = useTranslation();
  return (
    <Toast
      error
      title={errorTitle}
      detail={errorDetail}
      show={show}
      onClick={() => onClick()}
    />
  );
};

const PaywallContent = (props: PaywallContentProps) => {
  let isConnected = props?.isConnected;
  const [cardDetails, setCardDetails] = useState<CardInterface>({
    number: '',
    expiration: '',
    cvv: '',
  });
  const [errorMsg, setErrorMsg] = useState('');
  const [showError, setShowError] = useState(false);
  const [showRedeemCode, setShowRedeemCode] = useState(false);
  const [showProcessingPayment, setShowProcessingPayment] = useState(false);
  const [promotion, setPromotion] = useState<VLPromotion | undefined>(
    undefined
  );

  const { t, i18n } = useTranslation();
  useEffect(() => {
    //nop
  }, []);

  // console.log('PaywallContent', props);

  const alreadyHasPaymentMethods =
    paymentManager2.existingPaymentMethods().length > 0;

  /**
   *
   * @param e event
   * @param kind The kind of input number | expiration | cvv
   */
  const onChange = (e: any, kind: any) => {
    let value = e.target.value;

    // console.log('onChange...', e, kind);

    if (kind === 'creditNumber') {
      cardDetails.number = value;
    } else if (kind === 'creditExpirationDate') {
      cardDetails.expiration = value;
    } else if (kind === 'creditCvv') {
      cardDetails.cvv = value;
    }
    setCardDetails({ ...cardDetails });
  };

  let title: string = t('finalize_order');
  let actionButtonTitle: string = t('paywall_confirm');
  switch (props.type) {
    case 'personalized_video':
      title = t('finalize_order');
      break;
    case 'promotional_video':
      title = t('finalize_order');
      break;
    case 'subscription':
      title = t('select_sub_plan');
      break;
    case 'campaign':
      title = '';
      break;
    default:
      title = '';
      break;
  }

  if (isConnected === false) {
    actionButtonTitle = t('paywall_generic_sign_in_to_submit');
  }

  const isPaymentMethodValid = () => {
    return validateCard(
      cardDetails.number,
      cardDetails.expiration,
      cardDetails.cvv
    );
  };

  const onActionButtonClicked = async () => {
    if (props.onActionButtonClicked) props.onActionButtonClicked();
    if (isConnected) {
      VLFlowManager.sendFlowAnalytics(new AddPaymentMethodStart());

      if (isPaymentMethodValid() === false) {
        if (alreadyHasPaymentMethods) {
          props.onAddPaymentMethodSuccess();
          return;
        } else {
          //bad - edge case
          return;
        }
      }
      // console.log('should attempt add card');
      //1. check validity of card
      setShowProcessingPayment(true);
      const requestAddCard = await paymentManager2.addCreditCard(
        cardDetails.number,
        cardDetails.cvv,
        cardDetails.expiration,
        'none'
      );
      setShowProcessingPayment(false);

      if (requestAddCard.success) {
        // console.log('success addding payment method');
        props.onAddPaymentMethodSuccess();
        VLFlowManager.sendFlowAnalytics(new AddPaymentMethodCompleted());
      } else {
        VLFlowManager.sendFlowAnalytics(new AddPaymentMethodFailure());
        console.error('error addding payment method');
        setErrorMsg(
          requestAddCard.errorMsg ?? t('paywall_add_card_generic_error')
        );
        setShowError(true);
      }

      //2. Add their card

      //3. Hide and continue with what they originally intended
    } else {
      props.onShowAuth();
    }
  };

  const canProceedToNextStep = () => {
    return isPaymentMethodValid() || alreadyHasPaymentMethods;
  };

  const onRedeemSuccess = (promotion: VLPromotion) => {
    setPromotion(promotion);
    setShowRedeemCode(false);
    props.onPromotionSet(promotion);
  };

  const PromoCodeSection = () => {
    return (
      <Box mt={5}>
        <div
          className={`${styles.couponSection} ${
            promotion ? `${styles.active}` : ''
          }`}
          onClick={() => setShowRedeemCode(true)}
        >
          {promotion ? t('coupon_applied') : t('i_have_a_coupon_code')}
        </div>
      </Box>
    );
  };

  const isHebrew = i18n.language === 'he';
  return (
    <div className={`${styles.Master}`} dir={lclDirection()}>
      <div className={`${styles.navbar}`}>
        <div
          onClick={() => props.onClose()}
          className={`${styles.closeButton} ${centerFlexColumn()}`}
        >
          <img className="img-fluid" src={IconClose}></img>
        </div>
      </div>
      <div className={`${styles.title}`}>{title}</div>
      <ItemPriceView {...props} promotion={promotion} />
      <div style={{ display: props.hideRedeemCouponCode ? 'none' : 'block' }}>
        <PromoCodeSection />
      </div>
      {alreadyHasPaymentMethods ? null : (
        <CardInput onChange={onChange} cardInputTitle={props.cardInputTitle} />
      )}
      <div className={`${styles.infoLabel}`}>
        To keep you protected, we use <span>Stripe</span> as processing service
        provider and never store your full credit card information
      </div>
      <div className={`${centerFlexColumn()}`}>
        <ButtonBase
          onClick={() => onActionButtonClicked()}
          className={`${styles.confirmButton} ${
            canProceedToNextStep() ? '' : 'opacity-3'
          } ${centerFlexColumn()}`}
        >
          {actionButtonTitle}
        </ButtonBase>
      </div>
      <div
        onClick={() => props.onClose()}
        className={`${styles.cancelButton} ${centerFlexColumn()}`}
      >
        {t('paywall_generic_button_maybe_later')}
      </div>
      <RedeemCode
        open={showRedeemCode}
        type="booking"
        creatorId={props.creator?.id}
        onClose={() => setShowRedeemCode(false)}
        onSuccess={(promotion: VLPromotion) => onRedeemSuccess(promotion)}
      />
      <ProcessingOverlay
        show={showProcessingPayment}
        title={t('processing_payment_title')}
        detail={t('processing_payment_detail')}
      />
      <ErrorSnackBar
        onClick={() => setShowError(false)}
        show={showError}
        errorTitle={t('toast_error_default_title')}
        errorDetail={errorMsg}
      />
    </div>
  );
};

const mapStateToProps = (state?: any) => {
  return { isConnected: state.user.isConnected };
};

export default connect(mapStateToProps)(PaywallContent);
