import { createRoutine } from 'redux-saga-routines';

export const fetchCurrentUserDetails = createRoutine(
  'FETCH_CURRENT_USER_DETAILS'
);
export const fetchAdminDashboardData = createRoutine(
  'FETCH_ADMIN_DASHBOARD_DATA'
);
export const fetchHome = createRoutine('FETCH_HOME');
export const signUp = createRoutine('SIGN_UP');
export const sendVerificationCode = createRoutine<string>(
  'SEND_VERIFICATION_CODE'
);
export const verifyCode = createRoutine<number>('VERIFY_CODE');
export const signOut = createRoutine('SIGN_OUT');
export const hideAuth = createRoutine('HIDE_AUTH');
export const fetchCreatorDetails = createRoutine('FETCH_CREATOR_DETAILS');
export const setMobileView = createRoutine('SET_MOBILE_VIEW');
