import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel } from 'react-bootstrap';
import { OrderBlockWidget } from '../../Widgets/OrderBlockWidget';
import { useTranslation } from 'react-i18next';
import { parse } from '../../../../../utils/vlStrings.js';
import { updateBrief } from '../../../../../store/reducers/ordering';
import { isHebrew } from '../../../../../App';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '-0.41px',
  },
  input: {
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.165px',
    '& ::placeholder': {
      color: 'white',
    },
  },
  label: { color: 'white' },
  underline: {
    '&:before': {
      borderBottom: `1px solid white`,
    },
  },
  labelRoot: {
    right: (props) => (props.isHebrew ? 0 : 'none'),
  },
  shrink: {
    transformOrigin: (props) => (props.isHebrew ? 'top right' : 'top left'),
  },
}));

const InputBox = (props) => {
  const { value } = props;
  const { t } = useTranslation();
  const classes = useStyles({ isHebrew: isHebrew() });

  const title = t('order_description');
  const placeholder = t('booking_description_placeholder');

  const charsLeft = 30 - value.length;
  const helperText =
    charsLeft > 0
      ? parse(t('please_enter_more_chars'), charsLeft)
      : t('booking_description_advice');

  return (
    <Box
      mb={2.5}
      width="100%"
      display="flex"
      flexDirection="column"
      fontFamily="Assistant"
    >
      <Box m={1} textAlign="center" className={classes.title}>
        {title}
      </Box>
      <TextField
        value={value}
        multiline={true}
        defaultValue={props.default}
        inputRef={props.inputRef}
        onChange={props.onChange}
        onBlur={props.onBlur}
        required
        InputLabelProps={{
          classes: { root: classes.labelRoot, shrink: classes.shrink },
          style: { color: 'rgba(255, 255, 255, 0.7)' },
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          classes: {
            input: classes.input,
            underline: classes.underline,
          },
        }}
        color="secondary"
        fullWidth={true}
        label={placeholder}
        helperText={helperText}
        FormHelperTextProps={{
          style: { color: 'white', fontFamily: 'Assistant' },
        }}
      />
    </Box>
  );
};
const OrderDescriptionBlock = (props) => {
  const { user, isBusiness, updateBrief, showStepError } = props;
  const [briefValue, setBriefValue] = useState('');
  const classes = useStyles();

  const onChange = (event) => {
    const val = event.target.value;
    setBriefValue(val);
    updateBrief(val);
  };

  const onBlur = (event) => {
    const val = event.target.value;
    setBriefValue(val);
    updateBrief(val);
  };

  return (
    <OrderBlockWidget showError={showStepError}>
      <Box className={classes.root}>
        <Box width="100%" display="flex" flexDirection="column" mt={2}>
          <InputBox value={briefValue} onChange={onChange} onBlur={onBlur} />
        </Box>
      </Box>
    </OrderBlockWidget>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    user: state.user.details,
    showStepError: state.ordering.errors.message === true,
  };
};

export default connect(mapStateToProps, { updateBrief })(OrderDescriptionBlock);
