import BaseTracker from './base.tracker';

import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/analytics';

const TAG = 'FirebaseTracker: ';

export class FirebaseTracker extends BaseTracker {
  init() {
    return this;
  }

  event(name, properties = [], resolve) {
    // return new Promise((resolve, reject) => {
    //we need to convert event name to something firebase likes
    let eventName = name
      .toLowerCase()
      .replaceAll(/ /g, '_')
      .replaceAll('-', '_');
    let props = {};

    if (properties instanceof Array)
      properties.map((property) => {
        for (let key in property) props[key] = property[key];
      });

    return new Promise((resolve) => {
      resolve(firebase.analytics().logEvent(eventName));
    });
  }

  setUserId = (userId) => {
    firebase.analytics().setUserId(userId);
    firebase.analytics().setUserProperties({ platform: 'web' });
  };
  setIsCreator = (isCreator) =>
    firebase
      .analytics()
      .setUserProperties({ is_creator: isCreator ? true : false });
  setName = (name) => firebase.analytics().setUserProperties({ Name: name });
  setProfileId = (profileId) =>
    firebase.analytics().setUserProperties({ ProfileId: profileId });
}
