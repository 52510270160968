import React from 'react';
import styles from './roundLoader.module.scss';

const RoundLoader = (props: any) => {

  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className={styles.roundLoader}>
    <circle cx="8" cy="8" r="6" stroke="#C8CACF" strokeWidth="1"/>
  </svg>
}

export {
  RoundLoader
}