import Session from './session';

class VLCreationPurchase {
  //purchase info
  id = '';
  userId = ''; //the purchaser of the creation access

  //creation info
  creationId = '';
  creatorId = ''; //the creator of the creation (content)

  //dates/timestamps
  createdOn = null;
  lastUpdated = null;

  //Pricing
  price = 0.0;
  currency = 'USD';
  valid = true;

  //user objects
  user = null;
  creator = null;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  toAnalyticsMap() {
    var map = {};
    map['id'] = this.id;
    map['creation_id'] = this.creationId;
    map['creator_id'] = this.creatorId;
    map['user_id'] = this.userId;
    map['price'] = this.price;
    map['currency'] = this.currency;
    return map;
  }

  static fromJSON(json) {
    var purchase = new VLCreationPurchase();
    purchase.id = json.id;
    purchase.userId = json.user_id;
    purchase.creationId = json.creation_id;
    purchase.creatorId = json.creator_id;
    purchase.createdOnTimestamp = json.created_on;
    purchase.lastUpdatedTimestamp = json.last_updated;
    purchase.price = json.price;
    purchase.currency = json.currency;
    purchase.active = json.valid ? json.valid : true;
    return purchase;
  }
}

export default VLCreationPurchase;
