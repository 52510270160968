import { createReducer } from '@reduxjs/toolkit';
import {
  fetchCreatorDetails,
  fetchCurrentUserDetails,
  signOut,
} from '../../routines';

export interface CreatorState {
  data: any;
}

const initialState: CreatorState = {
  data: {},
};

export default createReducer<CreatorState>(initialState, {
  [fetchCreatorDetails.SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
  }),
});
