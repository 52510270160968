import styles from './AppBanner.module.scss';
import React from 'react';
import { centerFlexRow } from '../../utils/bootstrapUtils';
import { useTranslation } from 'react-i18next';

import Apple from '../Images/appstore.svg';
import Google from '../Images/googleplay.svg';
const AppBanner = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.appsText}>
      {t('profile_apps')}
      <div className={`${centerFlexRow()} w-100`}>
        <div className={`${styles.buttons} w-100 justify-content-between`}>
          <a href="http://kre8.tv/ios" target="_blank">
            <img
              src={Apple}
              className={`${styles.button} ${styles.buttonFirst}`}
            />
          </a>
          <a href="http://kre8.tv/android" target="_blank">
            <img src={Google} className={styles.button} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppBanner;
