import { takeLatest, put } from 'redux-saga/effects';
import { fetchHome } from '../../routines';
import axios from 'axios';
import api from '../../../constants/api';
import { getI18n } from 'react-i18next';
import VLUser from '../../../model/vlUser';
import VLPost from '../../../model/vlPost';
import VLRegion from '../../../model/vlRegion';
import * as utils from './../../../utils/rhutils';

function* handleTriggerAction(action: ReturnType<typeof fetchHome.trigger>) {
  try {
    yield put(fetchHome.request());

    const apiObj = axios.create({
      baseURL: api.BASE_URL,
    });

    utils.log.debug(
      'action.payload.currentRegion',
      action.payload.currentRegion
    );
    utils.log.debug('action:::', action);

    const { data } = yield apiObj.get('/fetchHome', {
      params: {
        locale: getI18n().language,
        region: action.payload.region,
        web: true,
        show_regions: 1,
      },
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        locale: getI18n().language,
        region: getI18n().language === 'he' ? 'ISR' : 'US',
      },
    });

    var allUsers = [] as any;
    for (var index in data.categories) {
      var cat = data.categories[index];
      const personas = cat.personas.map((personaJSON: any) =>
        VLUser.fromJSON(personaJSON)
      );
      data.categories[index].personas = personas;
      allUsers = allUsers.concat(personas);
    }

    var posts = [];
    for (var index in data.posts) {
      var postJSON = data.posts[index];
      const post = VLPost.fromJSON(postJSON) as any;
      if (post) {
        post.creator = VLUser.local(post.userId);
        posts.push(post);
      }
    }

    var regions = [];
    for (var index in data.regions) {
      var regionJSON = data.regions[index];
      const region = VLRegion.fromJSON(regionJSON);
      if (region) {
        regions.push(region);
      }
    }

    var waitlisted_regions = [];
    for (var index in data.waitlisted_regions) {
      var regionJSON = data.waitlisted_regions[index];
      const region = VLRegion.fromJSON(regionJSON);
      if (region) {
        waitlisted_regions.push(region);
      }
    }

    // console.log('waitslited regions = ', waitlisted_regions);

    yield put(
      fetchHome.success({
        allUsers: allUsers,
        categories: data.categories,
        regions: regions.concat(waitlisted_regions),
        waitlisted_regions: waitlisted_regions,
        posts: posts,
        currentRegion: regions[0],
      })
    );
  } catch (e) {
    yield put(fetchHome.failure(e));
  }
}

export default function* fetchHomeSaga() {
  yield takeLatest(fetchHome.TRIGGER, handleTriggerAction);
}
