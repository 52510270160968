import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel } from 'react-bootstrap';
import { OrderBlockWidget } from '../../Widgets/OrderBlockWidget';
import CreditCardInput from 'react-credit-card-input';
import { useTranslation } from 'react-i18next';
import paymentManager2 from '../../../../../managers/v2/paymentManager2';
import { onUpdatePaymentDetails } from '../../../../../store/reducers/ordering';
import { validateCard } from '../../../../../utils/paymentMethodUtils';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '-0.41px',
  },
}));
const details = {};
const CreditCardInputContainer = (props) => {
  const { onUpdatePaymentDetails } = props;

  const handleCardInputChange = (name, event) => {
    if (!event || !event.target || !event.target.value) return;

    details[name] = event.target.value;
    const isValid = validateCard(
      details.number ?? '',
      details.expiration ?? '',
      details.cvv ?? ''
    );
    details['is_valid'] = isValid;
    onUpdatePaymentDetails(details);
  };

  return (
    <Box width="100%" pt={2}>
      <CreditCardInput
        containerStyle={{
          width: '100%',
          fontFamily: 'Assistant',
          fontWeight: 600,
          fontSize: '16px',
          background: 'none',
        }}
        fieldStyle={{
          background: 'none',
          width: '100%',
          justifyContent: 'space-between',
          fontFamily: 'Assistant',
          fontSize: '16px',
          fontWeight: 600,
        }}
        inputStyle={{
          color: 'white',
          background: 'none',
          width: '100%',
          justifyContent: 'space-between',
          fontFamily: 'Assistant',
          fontSize: '16px',
          fontWeight: 600,
        }}
        onError={({ inputName, err }) =>
          console.log(`credit card input error: ${err}`)
        }
        cardNumberInputProps={{
          onChange: (event) => handleCardInputChange('number', event),
        }}
        cardExpiryInputProps={{
          onChange: (event) => handleCardInputChange('expiration', event),
        }}
        cardCVCInputProps={{
          onChange: (event) => handleCardInputChange('cvv', event),
        }}
        fieldClassName="input"
      />
    </Box>
  );
};

const OrderEnterPaymentInfoBlock = (props) => {
  const {
    hasValidPaymentMethods,
    onUpdatePaymentDetails,
    showStepError,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const title = t('enter_card_details');

  return (
    <OrderBlockWidget hidden={hasValidPaymentMethods} showError={showStepError}>
      <Box className={classes.root}>
        <Box m={1} textAlign="center" className={classes.title}>
          {title}
        </Box>
        <CreditCardInputContainer
          onUpdatePaymentDetails={onUpdatePaymentDetails}
        />
      </Box>
    </OrderBlockWidget>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    user: state.user.details,
    paymentMethods: state.user.paymentMethods,
    hasValidPaymentMethods: paymentManager2.existingPaymentMethods().length > 0,
    showStepError:
      state.ordering.cardDetails.is_valid !== undefined &&
      state.ordering.cardDetails.is_valid === false,
  };
};

export default connect(mapStateToProps, { onUpdatePaymentDetails })(
  OrderEnterPaymentInfoBlock
);
