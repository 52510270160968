import React, { useState, useEffect } from 'react';
import styles from './HowItWorks.module.scss';
import { useTranslation } from 'react-i18next';
import icon_close2 from '../Images/icon_close2.svg';

const jsonData = [
  //this is the texts of the faq page
  {
    //1
    title: 'Welcome to KRE8.TV',
    title_heb: 'ברוכים הבאים לKRE8.TV',
    subTitle:
      'On this page you can order custom personalized or promotional videos from your creator. Go through the basic steps, fill in the information and submit your order!',
    subTitle_heb:
      'בעמוד הזה להזמין תכנים אישיים או מסחריים מהיוצר. מלאו את הפרטים הבסיסיים בעמוד, והזמינו תוך כמה קליקים פשוטים!',
  },
  {
    //1
    title: 'When will my order be completed?',
    title_heb: 'מתי אקבל את הסרטון שהזמנתי?',
    subTitle:
      'Your request will be completed within 7 days. If you order isn not fulfilled by the Creator within 7 days you will be fully refunded',
    subTitle_heb:
      'ליוצרים שיש עד 7 ימים לכל היותר לצלם את הסרטון שהזמנתם, אך בדרך כלל לוקח להם בין יום ליומיים, תלוי ביוצר',
  },
  {
    //2
    title: 'How will I know when my order is ready?',
    title_heb: 'מתי אדע שהסרטון שלי מוכן?',
    subTitle: 'Your receipt and order details will be send to your email.',
    subTitle_heb:
      'אם עדיין לא עשיתם זאת, תוכלו להפעיל התראות שיידעו אותכם שההזמנה שלכם מוכנה. בכל מקרה, תקבלו גם חשבונית למייל שלכם כדי שתדעו שהסרטון שהזמנתם מוכן לצפייה',
  },
  {
    //3
    title: 'When do I get charged?',
    title_heb: 'מתי בעצם מחייבים אותי?',
    subTitle:
      'When your order is completed, we’ll send you a link to the order to view, share or download',
    subTitle_heb:
      'ברגע שאתם שולחים את הבקשה, נוצר פקדון זמני בחשבונכם על סך מחיר ההזמנה. החיוב עצמו מתבצע רק לאחר שהסרטון מוכן ונשלח אליכם',
  },
  {
    //4
    title: 'Where will I get my order?',
    title_heb: 'איפה אני אקבל את הסרטון שהזמנתי?',
    subTitle:
      'If you’d like your order to be done ASAP, or with additional extra effort, you can incentivize the Creator by choosing a higher amount than their base asking price.',
    subTitle_heb:
      'תוכלו לקבל ולצפות בסרטון ישירות דרך האפלקיציה בעמוד ה״פעילות״ שלכם',
  },
  {
    //5
    title: 'Can I download the order?',
    title_heb: 'אפשר להוריד את הסרטון למכשיר שלי?',
    subTitle:
      'Yes, you can absolutely download your order delivarables to your personal device if needed',
    subTitle_heb:
      'כמובן! - בעמוד צפיית הסרטון שלכם, יש אפשרות להוריד את הסרטון ישירות למכשירכם',
  },
  {
    //6
    title: 'What should I do if I need my order to be done earlier?',
    title_heb: 'מה לעשות אם אני צריך/ה את הסרטון עד תאריך מסוים?',
    subTitle:
      'If you need your order done earlier or if it is a time sensitive manner, please contact support and we will do the best to help you get it ASAP',
    subTitle_heb:
      'אם אתם צריכים שהסרטון שלכם יוכן בהקדם, קודם כל חשוב לרשום זאת בתיאור. בנוסף, אפשר לדרבן את היוצר לתעדף את הבקשה שלכם או לתת אקסטרה מאמץ על ידי מתן הצעת מחיר יותר גבוהה ממחירו הבסיסי של היוצר',
  },
  {
    //7
    title: "Will I get charged if I don't get my order?",
    title_heb: 'האם אני אחוייב במידה ולא קיבלתי את הסרטון?',
    subTitle:
      'No! You will not get charged if you do not get your order. Your temporary hold will be expunged from your account within 5-7 business days after the final deadline',
    subTitle_heb:
      'חס ושלום - במידה ולא קיבלתם את הסרטון מאיזושהי סיבה, לא תחויבו כלל והפקדון הזמני על כרטיסכם יעלם לצמיתות.',
  },
  {
    //8
    title: 'What can I do with my order?',
    title_heb: 'מה אני יכול/ה לעשות עם הסרטון שהזמנתי?',
    subTitle:
      'It depends on the specific order you made. Personal orders can be used for personal use cases such as viewing and sharing with friends, where as business related orders have various permitted use cases. Please content support if you are not sure!',
    subTitle_heb:
      'אם הזמנתם ״סרטון אישי״ אז השימוש שלכם יכול להיות בדיוק כך - לשימוש אישי, להראות לחברים, ולא לבצע שום דבר מסחרי עם הסרטון. לעומת זאת, אם הגדרת את הבקשה כ״בקשה עסקית״, אז השימוש שתוכלו לעשות בסרטון הוא שונה כמובן. תוכלו לקרוא את התנאים המפורטים לגבי השימוש ב״תנאי השימוש״ שלנו (שאפשר למצוא באתר או דרך עמוד ה״הגדרות״ שבאפלקיציה)',
  },
];
const HowItWorks = (props) => {
  const { t, i18n } = useTranslation();
  const { noGutters, darkMode, hideClose, hideWelcomeMessage } = props;

  const boxClass = darkMode ? styles.boxDark : styles.boxLight;
  const lineClass = darkMode ? styles.boxLineDark : styles.boxLineLight;
  const titleClass = darkMode ? styles.titleDark : styles.title;
  const subtitleClass = darkMode ? styles.subTitleDark : styles.subtitle;

  let adjustedJsonData = jsonData;
  if (hideWelcomeMessage) {
    jsonData.shift();
    adjustedJsonData = jsonData;
  }

  return (
    <div
      className={`${styles.HowItWorks} ${
        i18n.language === 'he' || !i18n.language || i18n.language === 'global'
          ? `${styles.heb}`
          : ''
      }`}
    >
      {adjustedJsonData.map((item) => {
        return (
          <div
            className={`${
              noGutters ? styles.boxWrapperNoGutters : styles.boxWrapper
            } ${boxClass}`}
          >
            <div className={lineClass}>
              <div className={titleClass}>
                {i18n.language === 'he' ? item.title_heb : item.title}
              </div>
              <div className={subtitleClass}>
                {i18n.language === 'he' ? item.subTitle_heb : item.subTitle}
              </div>
            </div>
          </div>
        );
      })}
      {hideClose ? null : (
        <div className={styles.close} onClick={props.close}>
          <img src={icon_close2} />
        </div>
      )}
    </div>
  );
};

export default HowItWorks;
