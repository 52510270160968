import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styles from './Profile.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import userManager from '../managers/userManager';
import close from './Images/close.svg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import * as utils from '../utils/rhutils.js';
import { vlImageGenericCloseWhite } from '../utils/vlImages.js';
import { fetchCreatorDetails } from '../store/routines';
import { useSelector } from 'react-redux';
import VLUser from '../model/vlUser';
import { getAffiliate, getReferral } from '../utils/marketing';

import { AnalyticsEvent, analytics } from '../utils/analytics';
import { useEventLogger } from '../shared/hooks';
import { isMobile } from 'react-device-detect';
import MobileProfileHeader from './ProfileV2/MobileProfileHeader';
import NewNavbar from '../components/NewNavbar/Navbar';
import Main from './Main/Main';
import Channel from './Channel/Channel';
import Requests from './Requests/Requests';
import Creations from './Creations/Creations';
import Footer from '../components/NewFooter/Footer';
import About from './About/About';
import Modal from '../components/Modal/Modal';
import SubscriptionFlow from '../Flows/SusbcriptionFlow/SusbcriptionFlow';
import { centerFlexColumn } from '../utils/bootstrapUtils';
import {
  ORDER_FLOW_FOR_BUSINESS,
  ORDER_FLOW_INITIAL,
} from '../components/Modal/Booking/Booking';
import MembershipIDScreen from '../membership/MembershipID/MembershipIDScreen';
import withIntercom from '../shared/hoc/with-intercom';
import { propTypes } from 'react-bootstrap/esm/Image';
import VLFlowManager, {
  MainProfileViewEvent,
} from '../managers/v2/VLFlowManager';
import currentUser from '../store/reducers/auth/current-user';
import VLAppBar from '../Reusables/Branding/VLAppBar';
import {
  Box,
  Container,
  Dialog,
  Fade,
  makeStyles,
  Typography,
} from '@material-ui/core';
import HomeVideoWidget from '../components/pages/Homepage/Videos/HomeVideoWidget';
import StickyScrollVideoFeed from '../components/pages/Feed/StickyScrollVideoFeed';
import AboutSection from './About/About';
import { AuthModal } from '../signup/AuthModal';
import OrdersPage from '../components/pages/OrdersV2/OrdersPage';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#0e091e',
  },
  sectionSpacing: {
    paddingTop: '40px',
    paddingBottom: '40px',
  },
}));

const PageFooter = (props) => {
  const { hasFetchedUserData } = props;
  return hasFetchedUserData ? (
    <div className={`pt-5 ${styles.Master}`}>
      <Footer theme={'dark'} />
    </div>
  ) : null;
};
const MobileProfile = (props) => {
  const {
    toggleViewContentModal,
    showSkeleton,
    user,
    setShowCauseModal,
    onProfileActionButtonClicked,
    setHideNavBar,
  } = props;
  return (
    <div style={{ background: '#0e091e' }}>
      <div>
        <MobileProfileHeader
          setHideNavBar={setHideNavBar}
          toggleViewContentModal={toggleViewContentModal}
          showSkeleton={showSkeleton}
          user={user}
          onCauseClicked={() => setShowCauseModal(true)}
          onProfileActionButtonClicked={(actionType) => {
            onProfileActionButtonClicked(actionType);
          }}
        />
      </div>
    </div>
  );
};

const HorizontalFeedSection = (props) => {
  const {
    posts,
    title,
    videoTitle,
    profilePictureURL,
    profileId,
    onVideoClicked,
    backupImageURL,
  } = props;
  if (!posts || posts.length === 0) return null;

  return (
    <Box>
      <Box id="feed-title">
        <Typography variant="h2">
          <Box color="text.secondary" lineHeight={1.2}>
            {title}
          </Box>
        </Typography>
      </Box>
      <Box
        component="div"
        display="flex"
        whiteSpace="nowrap"
        flexDirection="row"
        flexWrap="nowrap"
        minWidth="100%"
        overflow="auto"
      >
        {posts.map((post, index) => {
          return (
            <Box
              flexShrink={0}
              m={1}
              onClick={() =>
                onVideoClicked({
                  id: post.id,
                  title: videoTitle,
                  image_url: post.thumbURL,
                  video_url: post.videoURL,
                  profile_picture_url: profilePictureURL,
                  backupImageURL: profilePictureURL,
                  profile_id: profileId,
                })
              }
            >
              <HomeVideoWidget
                horizontal={true}
                key={post.id}
                index={index}
                backupImageURL={backupImageURL}
                videoURL={post.videoURL}
                imageURL={post.thumbURL}
                profilePictureURL={profilePictureURL}
                title={videoTitle}
                profileId={profileId}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const BookingModalContainer = (props) => {
  if (!props.showBooking) {
    return null;
  }

  utils.log.debug('BookingModalContainer props', props);

  const flow = props.flow ? props.flow : '';
  const step = props.flow && props.flow !== 'reset' ? 1 : 0;
  const activeField = 'forWho';

  const { User, onClose, onSuccess } = props;

  return (
    <Modal
      kind="booking"
      bookingFlow={flow}
      User={User}
      step={step}
      activeField={activeField}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
};

const Modals = (props) => {
  const {
    User,
    showViewContentModal,
    profileId,
    showSubscription,
    flow,
    toggleMobileModal,
    showDownloadAppModalAndReload,
    showMobileModal,
    showBooking,
    setShowCauseModal,
    showCauseModal,
    setShowBooking,
    creationData,
    setShowViewContentModal,
    handleShowSubscription,
    setShowSubscription,
    isCurrentUserASubscriber,
  } = props;

  const { t, i18n } = useTranslation();

  const ViewContentModal = () => {
    return showViewContentModal ? (
      <Modal
        showClose={true}
        User={User}
        kind="success"
        title={t('download_app_to_view')}
        subtitle={t('subscription_modal_subtitle')}
        onClose={() => setShowViewContentModal(false)}
        onSuccess={() => setShowViewContentModal(false)}
      />
    ) : null;
  };

  const subscriptionModal = () => {
    const onCreditCardAdded = () => {
      //should attempt now purchase subscription
    };

    return showSubscription ? (
      isCurrentUserASubscriber ? (
        <MembershipIDScreen
          hideOpenChannel={true}
          viewMode={true}
          creator={User}
          membershipObj={
            User &&
            User.closeCircleSubscription &&
            User.closeCircleSubscription()
          }
          onClose={() => setShowSubscription(false)}
          onOpenVerse={() => setShowSubscription(false)}
        />
      ) : (
        <SubscriptionFlow
          creator={User}
          profileId={User.profileId}
          onFlowCanceled={() => setShowSubscription(false)}
        />
      )
    ) : null;
  };

  const goToAppModal = () => {
    return showMobileModal ? (
      <Modal
        User={User}
        title={t('creation_purchase_go_to_app_modal_title')}
        detail={t('creation_purchase_go_to_app_modal_subtitle')}
        kind="success"
        onClose={() => toggleMobileModal(false)}
      />
    ) : null;
  };

  const causeModal = () => {
    return showCauseModal ? (
      <Modal
        maxWidth="sm"
        kind="cause"
        onClose={() => setShowCauseModal(false)}
        profileId={profileId}
        User={User}
      />
    ) : null;
  };

  const showModal =
    showBooking ||
    showSubscription ||
    showMobileModal ||
    showCauseModal ||
    showViewContentModal;

  // ${showBooking}
  // ${showSubscription}
  // ${showMobileModal}
  // ${showCauseModal}
  // ${showViewContentModal}`);

  return (
    <Dialog
      fullScreen={true}
      maxWidth="lg"
      open={showModal}
      aria-labelledby="form-dialog-title"
    >
      {showBooking ? (
        <BookingModalContainer
          showBooking={showBooking}
          User={User}
          flow={flow}
          onClose={() => setShowBooking(false)}
          onSuccess={() => setShowBooking(false)}
        />
      ) : null}
      <ViewContentModal />
      {subscriptionModal()}
      {causeModal()}
      {goToAppModal()}
    </Dialog>
  );
};

const CreatorVideos = (props) => {
  const { user, onVideoClicked } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  if (!user || !user.id) return null;

  return (
    <>
      <Box className={classes.sectionSpacing}>
        <HorizontalFeedSection
          type="all"
          posts={user.posts}
          title={t('profile_section_all_requests')}
          videoTitle={user.fullName()}
          backupImageURL={user.profilePictureURL}
          profilePictureURL={user.profilePictureURL}
          profileId={user.profileId}
          onVideoClicked={(video) => onVideoClicked(video, 'all')}
        />
      </Box>
      <Box className={classes.sectionSpacing} display="none">
        <HorizontalFeedSection
          type="business"
          posts={user.posts}
          title={t('profile_section_business_requests')}
          videoTitle={user.fullName()}
          backupImageURL={user.profilePictureURL}
          profilePictureURL={user.profilePictureURL}
          profileId={user.profileId}
          onVideoClicked={(video) => onVideoClicked(video, 'business')}
        />
      </Box>
      <Box className={classes.sectionSpacing} display="none">
        <HorizontalFeedSection
          type="personal"
          posts={user.posts}
          title={t('profile_section_personal_requests')}
          videoTitle={user.fullName()}
          backupImageURL={user.profilePictureURL}
          profilePictureURL={user.profilePictureURL}
          profileId={user.profileId}
          onVideoClicked={(video) => onVideoClicked(video, 'personal')}
        />
      </Box>
    </>
  );
};

const ProfileSection = (props) => {
  const {
    bgClassName,
    mainImage,
    setHideNavBar,
    toggleViewContentModal,
    showSkeleton,
    user,
    setShowCauseModal,
    onProfileActionButtonClicked,
    onVideoClicked,
    hasFetchedUserData,
    hide,
  } = props;

  const classes = useStyles();

  return (
    <div style={{ display: hide ? 'none' : 'block' }}>
      <div className={`${bgClassName}`}>
        <div className={styles.backgroundOverlay}></div>
        <img alt="main" src={mainImage} className={styles.imagePro} />
      </div>
      <div
        className={`${styles.allVisible}${
          styles.wrapper
        } ${centerFlexColumn()}`}
      >
        <div className={`w-100 position-relative ${centerFlexColumn()}`}>
          <div className={`${styles.mainContentWrapper} center`}>
            <MobileProfile
              setHideNavBar={setHideNavBar}
              toggleViewContentModal={toggleViewContentModal}
              showSkeleton={showSkeleton}
              user={user}
              setShowCauseModal={setShowCauseModal}
              onProfileActionButtonClicked={onProfileActionButtonClicked}
            />
            <Box
              className={classes.container}
              visibility={showSkeleton ? 'hidden' : 'visible'}
            >
              <Container maxWidth="sm">
                <Box className={classes.container}>
                  <Box className={classes.sectionSpacing}>
                    <AboutSection user={user} />
                  </Box>
                  <CreatorVideos user={user} onVideoClicked={onVideoClicked} />
                </Box>
              </Container>
              <PageFooter hasFetchedUserData={hasFetchedUserData} />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

let feedVideos = [];
let businessVideos = [];
let personalVideos = [];
let allVideos = [];

let loadApi = false;
let profileActionCallback = null;
const Profilev2 = React.memo((props) => {
  const authorized = useSelector((state) => state.auth.signIn.authorized);
  const { t, i18n } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [User, setUser] = useState({});
  const [hasFetchedUserData, setHasFetchedUserData] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [video, setVideo] = useState('');
  const [showSubscription, setShowSubscription] = useState(false);
  const [showViewContentModal, setShowViewContentModal] = useState(false);
  const [scrollFromTop, setScrollFromTop] = useState(1);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [snapToTop, setSnapToTop] = useState(true);
  const [showCauseModal, setShowCauseModal] = useState(false);
  const [creationData, setCreationData] = useState(false);
  const [backToHome, setBackToHome] = useState(false);
  const [showMembershipScreen, setShowMembershipScreen] = useState(false);
  const [showBooking, setShowBooking] = useState(
    window.location.href.toString().includes('/payment') ||
      (props.flow && props.flow.length > 0) ||
      props.showBooking
      ? true
      : false
  );
  const [mediaReady, setMediaReady] = useState(false);
  const [flow, setFlow] = useState(props.flow ? props.flow : '');
  const [showHorizontalFeed, setShowHorizontalFeed] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showOrderPage, setShowOrderPage] = useState(false);
  const [hideNavBar, setHideNavBar] = useState(false);
  const classes = useStyles();

  const setFlowFromActionButton = (flowName) => {
    if (flowName === 'requests_business') {
      showBusinessdVideoOrdering();
    }
  };

  const profileId =
    props && props.profileId
      ? props.profileId
      : props && props.match && props.match.params
      ? props.match.params.profileId
      : null;

  const toggleMobileModal = () => {
    setShowMobileModal(!showMobileModal);
  };

  const onUserDataFetched = (userData) => {
    setUser(userData);
    userManager.didViewProfile(userData);
  };

  const getUserData = async (profileId, forceReload = false) => {
    if (
      isFetching ||
      (loadApi === profileId && User.fullName !== undefined) ||
      forceReload
    ) {
      return;
    }

    setIsFetching(true);

    loadApi = profileId ?? props.profileId;

    let UserAPI = await userManager.fetchUserFromProfile(loadApi);

    if (UserAPI) {
      setHasFetchedUserData(true);
      onUserDataFetched(UserAPI);

      //set up videos
      businessVideos = []; //@todo - add some filtering once properties available on object
      personalVideos = []; //@todo - add some filtering once properties available on object
      allVideos = UserAPI.posts.map((post) => {
        return {
          id: post.id,
          title: post.title,
          image_url: post.thumbURL,
          video_url: post.videoURL,
          profile_picture_url: UserAPI.profilePictureURL,
          backupImageURL: UserAPI.profilePictureURL,
          profile_id: UserAPI.profileId,
        };
      });

      setIsFetching(false);
      // utils.log.debug('USERAPI success = ', UserAPI);

      //setup "goto"
      const queryParams = new URLSearchParams(props.location.search);
      let goto = queryParams.get('goto');

      switch (goto) {
        case 'subscribe':
          setShowSubscription(true);
          break;
        case 'booking':
          setShowOrderPage(true);
          break;
        case 'booking_home':
          setShowOrderPage(true);
          break;
        case 'booking_personal':
          showPersonalizedVideoOrdering();
          break;
        case 'booking_biz':
          showBusinessdVideoOrdering();
          break;
        default:
          break;
      }
    } else {
      //error
      // utils.log.debug('USERAPI error = ', UserAPI);
      setBackToHome(true);
      setIsFetching(false);
    }
    setOpacity(1);
  };

  if (!props.user || props.user.needFetchData === true) {
    // utils.log.debug('USERAPI starting fetch w/ props = ', profileId);
    //scroll to top
    getUserData(profileId);
  } else if (hasFetchedUserData === false) {
    setHasFetchedUserData(true);
    onUserDataFetched(props.user);
    setOpacity(1);
    // utils.log.debug('USERAPI fetched from props = ', props.user);
  }

  const hideVideo = () => {
    setVideo('');
  };

  const toggleShowSubscription = (bool, creation = null) => {
    utils.log.debug('toggleShowSubscription', bool);
    analytics.event(new AnalyticsEvent('creator - exclusive content click'));

    setCreationData(creation);
    handleShowSubscription(bool !== undefined ? bool : !showSubscription);
  };

  const toggleViewContentModal = (bool) => {
    setShowViewContentModal(bool);
  };

  const showVideo = (link) => {
    setVideo(link);
  };

  /* this is for submitting analytics on componentDidMount */
  useEffect(() => {
    // utils.log.debug('profile screen did mount...props', props);
    let referralId = getReferral(props.location.search);
    userManager.updateReferralId(referralId);
    let affiliateDealId = getAffiliate(props.location.search);
    userManager.affiliateDealId = affiliateDealId;
    let map = {
      profile_id: profileId,
      referral_id: referralId,
    };

    VLFlowManager.sendFlowAnalytics(new MainProfileViewEvent(profileId));

    analytics.event(
      new AnalyticsEvent('visit - creator', [
        {
          name: profileId,
        },
      ])
    );

    //snap to top
    if (snapToTop) {
      setSnapToTop(false);
      window.scrollTo(0, 0);
    }

    // utils.log.debug('trackers map...', map);
  }, []);

  let desc = User && User.desc ? User.desc : '';
  let about = User && User.transactionReviews ? User.transactionReviews : [];
  let aboutIsHidden = !desc && !about.length ? true : false;
  let fullName = User && User.fullName ? User.fullName() : '';
  let mainImage = User && User.profilePictureURL ? User.profilePictureURL : '';

  var helemetObj = null;

  if (backToHome === true) {
    return (
      <Redirect
        to={{
          pathname: `/`,
        }}
      />
    );
  }

  //if no user objectyet, don't show anything
  // utils.log.debug('if no user objectyet, dont show anything');
  if (!User) {
    setBackToHome(true);
    return;
  }

  //For setting headers
  if (User && User.fullName) {
    helemetObj = (
      <Helmet>
        <title>
          {fullName} on KRE8.TV - Exclusive content, chat, personalized videos,
          and more
        </title>
        <link rel="canonical" href={User.shareURL()} />
      </Helmet>
    );
  } else {
    helemetObj = (
      <Helmet>
        <title>
          1000+ creators on KRE8.TV. Enjoy exclusive content, personalized
          videos, and more
        </title>
      </Helmet>
    );
  }

  /**
   * Main entry point for starting personal use booking flow
   */
  const showPersonalizedVideoOrdering = () => {
    // setFlow(ORDER_FLOW_INITIAL);
    setShowOrderPage(true);
  };

  /**
   * Main entry point for starting business booking flow
   */
  const showBusinessdVideoOrdering = () => {
    // setFlow(ORDER_FLOW_FOR_BUSINESS);
    setShowOrderPage(true);
  };

  //Handle new mobile header profile action button logic
  const onProfileActionButtonClicked = (actionType) => {
    if (User.isAvailableForAction(actionType) === false) {
      return;
    }

    switch (actionType) {
      case 'requests_personal':
        //check if user is logged in
        if (props.isConnected === false) {
          //show auth modal
          setShowAuthModal(true);
          //@todo - automatically continue what user wanted to do
          // profileActionCallback = () => {
          //   onProfileActionButtonClicked(actionType);
          // };
          return;
        }
        showPersonalizedVideoOrdering();
        break;
      case 'requests_business':
        showBusinessdVideoOrdering();
        break;
      case 'subscription':
        handleShowSubscription(true);
        break;
      case 'business_promotion':
        //should open intercom!!
        let analyticsProperties = { profile_id: profileId };
        analytics.event(
          new AnalyticsEvent(
            'web - profile action - order business promotion',
            [analyticsProperties]
          )
        );
        break;
      default:
        break;
    }
  };

  /**
   * Helper method to handle trigger events related to showing subscription modal
   * @param {*} show
   */
  const handleShowSubscription = (show) => {
    setShowSubscription(show);
  };

  const onVideoClicked = (video, type) => {
    feedVideos =
      type === 'business'
        ? businessVideos
        : type === 'personal'
        ? personalVideos
        : allVideos;
    //move this video to the front!
    feedVideos = feedVideos.filter((v) => v.id !== video.id);
    feedVideos.unshift(video);
    setShowHorizontalFeed(true);
  };

  const userObj = User && VLUser.copy(User);
  const isCurrentUserASubscriber =
    userObj && userObj.isCurrentUserASubscriber();
  // utils.log.debug('showMobileModal before render: ', showMobileModal);

  const showSkeleton = hasFetchedUserData === false;

  const hideBackground = props.hideBackground
    ? `${styles.ProfileBackgroundHidden}`
    : '';

  const SimpleNavbar = (props) => {
    const history = useHistory();

    const onCloseClicked = () => {
      if (history.length < 3) {
        //go to home
        // history.push('/');
        window.location.href = '/home';
      } else {
        //go to profile
        history.goBack();
      }
    };

    return isMobile ? (
      <div className="position-relative">
        <VLAppBar dark={true} small={true} loose={false}>
          <div
            onClick={() => {
              onCloseClicked();
            }}
            className={`${styles.closeButton} ${centerFlexColumn()}`}
          >
            <img className="img-fluid" src={vlImageGenericCloseWhite}></img>
          </div>
        </VLAppBar>
      </div>
    ) : null;
  };

  /**
   * This is called after a subscription of purchase, so we want to "reload the full data" and display new information
   * to the user
   */
  const showDownloadAppModalAndReload = () => {
    setShowMobileModal(true);
    getUserData(User.profileId);
  };

  const isHebrew = i18n.language === 'he';
  const mainClassName = `${hideBackground} ${styles.Profile} ${
    isHebrew ? styles.heb : ''
  }`;
  const bgClassName = `${styles.background} ${
    aboutIsHidden ? styles.backgroundSmall : ''
  }`;

  return (
    <div
      className={`${styles.Master} ${isHebrew ? `${styles.heb}` : ''}`}
      style={{
        width: '100%',
        minHeight: '100vh',
      }}
    >
      <Fade in={!hideNavBar}>
        <Box>
          <NewNavbar showSearch={false} theme="dark" />
        </Box>
      </Fade>
      <div className={`${mainClassName}`} data-auth={authorized}>
        {helemetObj}
        <ProfileSection
          hide={showOrderPage}
          bgClassName={bgClassName}
          mainImage={mainImage}
          setHideNavBar={setHideNavBar}
          toggleViewContentModal={toggleViewContentModal}
          showSkeleton={showSkeleton}
          user={User}
          setShowCauseModal={setShowCauseModal}
          onProfileActionButtonClicked={onProfileActionButtonClicked}
          onVideoClicked={onVideoClicked}
          hasFetchedUserData={hasFetchedUserData}
        />
      </div>
      {showHorizontalFeed ? (
        <StickyScrollVideoFeed
          videos={feedVideos}
          didExit={() => setShowHorizontalFeed(false)}
        />
      ) : null}
      <AuthModal
        show={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        onAuthSuccess={() => {
          setShowAuthModal(false);
        }}
      />
      {showOrderPage ? (
        <OrdersPage
          creator={User}
          onBackPressed={() => setShowOrderPage(false)}
        />
      ) : null}
    </div>
  );
});

const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    accountDetails: state.user.accountDetails,
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withIntercom(Profilev2)));
