import React, { useState, useEffect } from 'react';
import styles from './image.module.scss';
import ClassNames from 'classnames';

interface ImageProps {
  src: string;
  className?: string;
  onLoad?: any;
  backupsrc?: string;
}

const TAG = 'Image: ';

const LazyImage: React.FC<ImageProps> = ({
  src,
  className,
  onLoad,
  backupsrc,
}) => {
  const [source, setSource] = useState(''); // Possible to add placeholder - for skeleton loading
  const [loaded, setLoaded] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  useEffect(() => {
    if (loaded) return;

    let image: HTMLImageElement = new Image();
    image.src = src;

    image.onload = (event) => {
      // console.log(TAG, "Image [ ", src, " ] loaded. Assign value to DOM.");
      setSource(src);
      setLoaded(true);
    };

    image.onerror = (event) => {
      console.error(
        TAG,
        'Error loading image [ ',
        src,
        ' ] loaded. Assign value to DOM.'
      );
      if (backupsrc) {
        setSource(backupsrc);
        setLoaded(true);
        setErrorLoading(true);
      }
    };

    // console.log(TAG, "Lazy load image [ ", src, " ]");
  }, []);

  return (
    <figure
      className={ClassNames([
        styles.image,
        className,
        loaded ? styles.loaded : null,
        errorLoading ? styles.blur : null,
      ])}
    >
      <img src={source} draggable={false} alt="some" />
    </figure>
  );
};

export { LazyImage as Image };
