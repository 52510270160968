export enum CampaignBookingStep {
  WELCOME = 0,
  SELECT_TYPE = 1,
  SELECT_OCCASION = 2,
  CATEGORIES = 3,
  CREATORS = 4,
  BRIEF = 5,
  SUMMARY = 6,
  SUBMISSION = 7,
  SUCCESS = 8,
}
