import { createReducer, createSlice } from '@reduxjs/toolkit';
import { fetchHome } from '../../routines';
import { filterUsers } from '../../actions';
import VLUser from '../../../model/vlUser';
import VLCategory from '../../../model/vlCategory';
import VLPost from '../../../model/vlPost';
import { IVideo } from '../../../model/interfaces';

export interface HomeState {
  data: {
    allUsers: VLUser[] | null;
    filteredUsers: any[];
    categories: VLCategory[] | null;
    regions: any[];
    posts: VLPost[];
    videos: IVideo[];
    currentRegion: any;
  };
  loading: boolean;
  search: boolean;
  error: any;
  shouldReload: boolean;
}

const initialState: HomeState = {
  data: {
    allUsers: [],
    filteredUsers: [],
    categories: null,
    regions: [],
    posts: [],
    videos: [],
    currentRegion: null,
  },
  loading: false,
  search: false,
  error: null,
  shouldReload: true,
};

const homeSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    // Redux actions
    updateData(state, action) {
      state.data.allUsers = action.payload.allUsers;
      state.data.categories = action.payload.categories;
      state.data.regions = action.payload.regions;
      state.data.videos = action.payload.videos;
      state.loading = false;
    },
    updateCreators(state, action) {
      state.data.allUsers = action.payload;
    },
  },
});

/* Export Redux Actions */
export const { updateData, updateCreators } = homeSlice.actions;

/* Export the reducer */
export default homeSlice.reducer;
