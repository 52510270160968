import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import BaseTracker from './base.tracker';

const TAG = 'GtmTracker: ';

export class GtmTracker extends BaseTracker {
  constructor() {
    super();
    this.tracker = null;
  }

  init() {
    this.tracker = Analytics({
      app: 'kre8.tv',
      plugins: [googleTagManager({ containerId: process.env.REACT_APP_GTM })],
    });

    return this;
  }

  event(name, properties = []) {
    // console.log(TAG, "Sending GTM event [ ", name, " ] with properties: ", properties);
    return new Promise((resolve, reject) => {
      let props = {};

      if (properties instanceof Array)
        properties.map((property) => {
          for (let key in property) props[key] = property[key];
        });

      // console.log(TAG, 'sending event', props);
      this.tracker.track(name, props, resolve);
    });
  }
}
