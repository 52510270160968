import valid from 'card-validator';

//@todo Currently works just based on char length - need to add some more stable logic
export function validateCard(
  cardNumber: string,
  expiration: string,
  cvv: string
) {
  // console.log(
  //   `validateCard: parsing validity of cc - number : ${cardNumber} | exp : ${expiration} | cvv: ${cvv}`
  // );

  //clean values
  const cleanNumber = cardNumber.trim().replace(/\s/g, '');
  const cleanExpiration = expiration ? expiration.split('/') : '';
  const cleanExpirationMonth =
    cleanExpiration.length == 2 ? cleanExpiration[0].trim() : '';
  const cleanExpirationYear =
    cleanExpiration.length == 2 ? cleanExpiration[1].trim() : '';
  const cleanCVV = cvv;

  // console.log(
  //   `validateCard: cleaning values of cc - number : ${cleanNumber} | exp month : ${cleanExpirationMonth} | exp year : ${cleanExpirationYear} | cvv: ${cleanCVV}`
  // );

  //check if valid
  // const isNumberValid = valid.number(cleanNumber);
  // const isExpirationValid = cleanExpiration.length === 4;
  // const isCVVValid = valid.number(cleanCVV);

  const isNumberValid = cleanNumber.length === 16;
  const isExpirationValid =
    cleanExpirationMonth.length === 2 && cleanExpirationYear.length === 2;
  const isCVVValid = cleanCVV.length >= 3;

  //return
  // console.log(
  //   `validateCard validate: ${cleanExpirationMonth} length: ${cleanExpirationMonth.length}`
  // );

  // console.log(
  //   `validateCard validate: ${isNumberValid} ${
  //     cleanExpirationMonth.length === 2
  //   } ${cleanExpirationYear.length === 2} ${isCVVValid}`
  // );

  // return isNumberValid && isExpirationValid && isCVVValid;
  return isNumberValid && isExpirationValid && isCVVValid;
}
