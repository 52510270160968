import React, { useState, useEffect } from 'react';
import styles from './BookingPrice.module.scss';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-bootstrap';

const BookingPrice = (props) => {
  // console.log('BookingPrice', props);
  const [showError, setShowError] = useState(false);
  // const [showErrorEffect, setShowErrorEffect] = useState(false)
  const { t, i18n } = useTranslation();

  let userPrice =
    props.User && props.User.price !== undefined
      ? Number(props.User.price)
      : '';
  let currency =
    props.User && props.User.getCurrencySymbol
      ? props.User.getCurrencySymbol()
      : '';

  const isBusinessFlow = props.flow === 'forBusiness';
  if (isBusinessFlow) {
    userPrice =
      props.User && props.User.priceBusiness !== undefined
        ? Number(props.User.priceBusiness)
        : '';
  }

  const { startEmpty } = props;

  useEffect(() => {
    if (startEmpty === false) notifyOnPriceChange(props.User.price);
  }, []);

  const keyDown = (e) => {
    if (e.key === 'Enter') {
      props.next();
    }
  };

  const notifyOnPriceChange = (price) => {
    props.setPrice(price);
  };

  const onChange = (e) => {
    if (e && e.target && e.target.value !== undefined) {
      let isNum = /^\d+$/.test(e.target.value);
      if (!isNum && e.target.value !== '') {
        //the user can write only numbers
        return;
      }
      props.setPrice(e.target.value);

      if (e.target.value < userPrice) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    }
  };

  return (
    <div
      className={`${
        styles.BookingPrice
      } d-flex flex-column justify-content-center align-items-center ${
        i18n.language === 'he' || i18n.language === 'global' || !i18n.language
          ? styles.heb
          : ''
      }`}
    >
      <div
        className={`${styles.inputRow} ${
          props.showPriceInputError ? styles.shake : ''
        } w-100 text-center`}
      >
        <div className="rounded p-2">
          <span className={styles.currency}>{currency}</span>
          <input
            type="number"
            placeholder={userPrice}
            className={styles.input}
            defaultValue={
              props.price ??
              (startEmpty
                ? null
                : isBusinessFlow
                ? props.User.priceBusiness
                : props.User.price) ??
              null
            }
            onChange={onChange}
            onKeyDown={keyDown}
            autoFocus={true}
          />
          {showError ||
          props.showPriceInputError ||
          (props.price > 0 && props.price < userPrice) ? (
            <div className={styles.error}>
              {t('booking_price_error')} {currency + userPrice}
            </div>
          ) : null}
        </div>
      </div>
      {/* <div
        className={`${styles.text} ${
          showError ? `${styles.hideOnMobile}` : ''
        }`}
      >
        {t('booking_price_text')}
      </div> */}
    </div>
  );
};
export default BookingPrice;
