import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchCurrentUserDetails } from '../../routines';
import firebase from 'firebase';

function* handleTriggerAction(action: any) {
  try {
    yield put(fetchCurrentUserDetails.request());
    const phoneNumber = action.payload.phone;
    //console.log("sending verification code to: ", phoneNumber);

    //@ts-ignore
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recpatcha-container',
      {
        size: 'invisible',
        callback: function (response: any) {
          // console.log('****Recaptch solved');
        },
        'expired-callback': function () {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
          // console.log(
          //   '****Response expired. Ask user to solve reCAPTCHA again.'
          // );
        },
      }
    );

    //@ts-ignore
    const appVerifier = window.recaptchaVerifier;
    const confirmResult = yield call(
      firebase.auth().signInWithPhoneNumber,
      phoneNumber,
      appVerifier
    );
    yield put(fetchCurrentUserDetails.success(confirmResult));
  } catch (e) {
    console.log('e', e);
    yield put(fetchCurrentUserDetails.failure());
  }
}

export default function* fetchCurrentUserDetailsSaga() {
  yield takeLatest(fetchCurrentUserDetails.TRIGGER, handleTriggerAction);
}
