import * as strings from "../utils/vlStrings.js";
import memoize from 'lodash/memoize'
export const ERROR_MUST_SIGN_IN = 3;
class VLError {
  hebrewText = null;
  text = null;
  code = null;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  localizedTitle() {
    return strings.isHebrew ? this.hebrewText : this.text;
  }

  static mustSignInError() {
    var err = new VLError();
    err.code = ERROR_MUST_SIGN_IN;
    err.text = "You need to be signed in before continuing";
    err.hebrewText = "צריכים להתחבר לפני שממשיכים";
    return err;
  }
  static fromJSON = memoize((json)=> {
    var err = new VLError();
    try {
      err.code = Number(json.code);
      err.text = json.text;
      err.hebrewText = json.hebrew_text;
      return err;
    } catch (error) {
      return null;
    }
  })
}

export default VLError;
