import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import styles from './Toast.module.scss';
import { useTranslation } from 'react-i18next';
import { centerFlexColumn } from '../utils/bootstrapUtils';
import * as utils from '../utils/rhutils';

import ErrorImage from './images/toast_repeat.svg';
import WarningImage from './images/toast_warning.svg';
import SuccessImage from './images/toast_success.svg';
import { FadeInDiv } from '../components/animations/vlFadeIn';
import { Fade, Slide } from '@material-ui/core';

export interface ToastInterface {
  show: boolean; //req - show or hide
  error?: any; //optional
  warning?: any; //optional
  success?: any; //optional
  title?: any; //optional
  detail?: any; //optional
  onRedoClick?: any; //optional
  onClick?: any; //optional
  autoShow?: boolean; //optional
}

const Toast = (props?: ToastInterface) => {
  const { t, i18n } = useTranslation();

  const show = props?.show;
  const autoShow = props?.autoShow;

  // console.log('props:', props);

  const ErrorSyle = props?.error ? `${styles.Error}` : '';
  const WarningSyle = props?.warning ? `${styles.Warning}` : '';
  const SuccessSyle = props?.success ? `${styles.Success}` : '';
  const TypeStyle = `${ErrorSyle} ${WarningSyle} ${SuccessSyle}`;

  const image: any = props?.error
    ? ErrorImage
    : props?.warning
    ? WarningImage
    : props?.success
    ? SuccessImage
    : null;

  // console.log('image:', image);

  const Container = () => {
    return show ? (
      <Fade
        in={show}
        timeout={{
          appear: 500,
          enter: 300,
          exit: autoShow ? 1500 : 1500,
        }}
      >
        <div className={`${centerFlexColumn()} w-100`}>
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <div className={`w-100 ${centerFlexColumn()}`}>
              <div
                onClick={() => props?.onClick()}
                className={`d-flex flex-row align-items-center ${styles.Container} ${TypeStyle}`}
              >
                <div className={styles.Icon}>
                  <Icon {...props} src={image} />
                </div>
                <div className={`${''} pl-3 pr-3 d-flex flex-column`}>
                  <Title {...props} />
                  <Detail {...props} />
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </Fade>
    ) : null;
  };

  const Icon = (props?: any) => {
    return (
      <div className="w-100 h-100">
        <img className="w-100 h-100" src={props.src}></img>
      </div>
    );
  };

  const Title = (props?: any) => {
    return (
      <div className={styles.Title}>
        {props?.title ?? t('toast_error_default_title')}
      </div>
    );
  };

  const Detail = (props?: any) => {
    return (
      <div className={styles.Detail}>
        {props?.detail ?? t('toast_error_default_detail')}
      </div>
    );
  };

  return (
    <div
      className={`fixed-bottom ${i18n.language === 'he' ? styles.heb : ''} ${
        styles.Master
      } ${centerFlexColumn()}`}
    >
      <Container />
    </div>
  );
};

export default Toast;
