import React, { useState, useEffect } from 'react';
import styles from './AddLinkList.module.scss';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import plus from '../Images/plus.svg'

/*
  props:
    link - array of all the links data (example: [{img:link, title:string, subTitle:string, date:string}])
 */
const AddLinkList = (props) => {
  const { t, i18n } = useTranslation();
 
  return (
    <div className={`${styles.wrapper} ${i18n.language === 'he' ? styles.heb : ''}`}>
        <div className={styles.text}>
            {t('editProfile_list_text')}
        </div>
        <div className={styles.boxs}>
          {props.links.map((link,index)=>{//all the links the user have
            return (
              <div className={styles.box} key={index}>
                <img src={link.img} className={styles.boxImg} />
                <div className={styles.textBox}>
                  <div className={styles.title}>{link.title}</div>
                  <div className={styles.subTitle}>{link.subTitle}</div>
                  <div className={styles.date}>{link.date}</div>
                </div>
              </div>
            )
          })}
          
        </div>
        <div className={styles.addLink} onClick={props.openAddLink}>
            <img src={plus} className={styles.plus} />
            <div className={styles.addText}>{t('editProfile_empty_add_new')}</div>
        </div>
    </div>
  );
};

export default withRouter(AddLinkList);
