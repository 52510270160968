export enum PostVisibilityType {
  PUBLIC,
  PRIVATE,
  SUBSCRIBERS_ONLY,
}

export function postVisibilityFromStringType(stringType: string) {
  switch (stringType) {
    case 'public':
      return PostVisibilityType.PUBLIC;
    case 'private':
      return PostVisibilityType.PRIVATE;
    case 'subscribers':
      return PostVisibilityType.SUBSCRIBERS_ONLY;
    default:
      return PostVisibilityType.PUBLIC;
  }
}

export function postVisibilityFromStringTypeToString(
  postVisibilityType: PostVisibilityType
): string {
  switch (postVisibilityType) {
    case PostVisibilityType.PUBLIC:
      return 'public';
    case PostVisibilityType.PRIVATE:
      return 'private';
    case PostVisibilityType.SUBSCRIBERS_ONLY:
      return 'subscribers';
    default:
      return 'none';
  }
}
