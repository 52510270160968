import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { updateStep } from '../../../../store/reducers/campaigns/';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  titleDefault: {
    color: 'white',
  },
  titleWhite: {
    color: 'white',
  },
  titlePrimary: {
    color: theme.palette.primary.main,
  },
  detail: {
    color: theme.palette.secondary.main,
  },
  closeButtonTransparent: {
    background: 'none',
    color: 'white',
  },
  closeButtonNormal: {
    background: '#F9F9F9',
    color: theme.palette.primary.main,
  },
  closeButtonWhite: {
    background: 'white',
    color: theme.palette.primary.main,
  },
}));

const NavigationBar = (props) => {
  let { step, detail, type, fromRegularBooking } = props;

  const history = useHistory();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  let rootClass = classes.root;
  let titleClass = classes.titleDefault;
  let closeButtonClass = classes.closeButtonTransparent;
  let icon = <CloseIcon />;
  let hideIcon = false;
  let title = '';

  const onCloseClicked = () => {
    if (step < 8) {
      props.updateStep(0);
    } else {
      history.push('/home');
    }
  };

  let style = 'default';

  switch (step) {
    case 0:
      style = 'default';
      break;
    case 1:
      style = 'white';
      title = fromRegularBooking
        ? t('quick_order')
        : t('campaigns_general_title');
      break;
    case 2:
      style = 'white';
      title = fromRegularBooking
        ? t('quick_order')
        : t('campaigns_general_title');
      break;
    case 3:
      style = 'white';
      title = fromRegularBooking
        ? t('quick_order')
        : t('campaigns_general_title');
      break;
    case 4:
    case 5:
    case 6:
    case 7: //SUBMISSION LOADING
      style = 'white';
      title = fromRegularBooking
        ? t('quick_order')
        : t('campaigns_general_title');
      hideIcon = true;
      break;
    case 8:
      style = 'primary';
      title = fromRegularBooking
        ? t('quick_order_launched')
        : t('campaigns_launched_title');
      icon = <HomeOutlinedIcon />;
      break;
    default:
      break;
  }

  switch (style) {
    case 'default':
      rootClass = classes.root;
      titleClass = classes.titleWhite;
      closeButtonClass = classes.closeButtonTransparent;
      break;
    case 'white':
      rootClass = classes.rootWhite;
      titleClass = classes.titlePrimary;
      closeButtonClass = classes.closeButtonNormal;
      break;
    case 'primary':
      rootClass = classes.rootPrimary;
      titleClass = classes.titleWhite;
      closeButtonClass = classes.closeButtonWhite;
      break;
    default:
      break;
  }

  return (
    <Box width="100%" height="80px" className={classes.root} p={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography variant="h2">
            <Box className={titleClass}>{title}</Box>
          </Typography>
          <Box
            display={detail && detail.length > 0 ? 'block' : 'none'}
            className={classes.detail}
          >
            {detail}
          </Box>
        </Box>
        <IconButton
          style={{ visibility: hideIcon ? 'hidden' : 'visible' }}
          className={closeButtonClass}
          onClick={() => onCloseClicked()}
        >
          {icon}
        </IconButton>
      </Box>
    </Box>
  );
};

export default connect(null, { updateStep })(NavigationBar);
