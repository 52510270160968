import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Container, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BrushImage from '../images/campaign_welcome_icon.png';
import VLButton from '../../../../../Reusables/Buttons/VLButton';
import { withBookingStage } from '../../../../../shared/hoc';
import { updateStep, reset } from '../../../../../store/reducers/campaigns/';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VLFlowManager, {
  CampaignFlowEventLanding,
} from '../../../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#2E1B5E',
  },
}));

const WelcomeScreen = (props) => {
  const { onStartClicked } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const title = t('campaigns_welcome_title');
  const detail = t('campaigns_welcome_detail');
  const p1 = t('campaigns_welcome_p1');
  const p2 = t('campaigns_welcome_p2');
  const start = t('campaigns_welcome_cta');
  const nothanks = t('campaigns_welcome_no_thanks');

  const onNoThanksClicked = () => {
    // history.push('/home');
    history.goBack();
  };

  useEffect(() => {
    props.reset();
    VLFlowManager.sendFlowAnalytics(new CampaignFlowEventLanding());
  }, []);

  return (
    <Box
      width="100%"
      height="100%"
      maxHeight="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      position="relative"
      justifyContent="flex-end"
    >
      <Box width="100%" position="absolute" top={0} left={0}>
        <img className="img-fluid" alt="campaign" src={BrushImage} />
      </Box>
      <Box p={4} zIndex={1}>
        <Box mb={2}>
          <Typography variant="h2">
            <Box mb={1} color="text.secondary">
              {title}
            </Box>
          </Typography>
          <Typography variant="h2">
            <Box letterSpacing={-0.17} color="primary.light" fontSize="18px">
              {detail}
            </Box>
          </Typography>
        </Box>
        <Typography variant="body1">
          <Box color="text.secondary" fontSize="18px" lineHeight={1.5}>
            {p1}
          </Box>
        </Typography>
        <br />
        <br />
        <br />
        <Typography variant="body1">
          <Box color="text.secondary" fontSize="18px" lineHeight={1.5}>
            {p2}
          </Box>
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
          <Box>
            <VLButton
              onClick={() => props.updateStep(1)}
              color="primary"
              variant="contained"
              style={{ width: '210px' }}
            >
              {start}
            </VLButton>
          </Box>
          <Typography variant="body1">
            <Button
              style={{ textTransform: 'none' }}
              onClick={() => onNoThanksClicked()}
            >
              <Box color="text.secondary">{nothanks}</Box>
            </Button>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(null, { updateStep, reset })(
  withBookingStage(WelcomeScreen)
);
