import React, { useState, useEffect } from 'react';
import styles from './BookingWhoIsFor.module.scss';
import { useTranslation } from 'react-i18next';
import bag from '../../Images/bag.svg';
import forMeIcon from '../../Images/profile.svg';
import friendsIcon from '../../Images/friends.svg';
import UserManager from '../../../../managers/userManager';
import Skeleton from 'react-loading-skeleton';
import { centerFlexColumn } from '../../../../utils/bootstrapUtils';

let timeout;
let timeout1;
const BookingWhoIsFor = (props) => {
  const { t, i18n } = useTranslation();
  const [profilePic, setProfilePic] = useState('');

  // console.log('BookingWhoIsFor props', props);

  let skeletonWidth = window.innderWidth < 1024 ? 80 : 100;
  let price =
    props.User && props.User.getPrice ? (
      `${props.User.getPrice()}`
    ) : (
      <Skeleton width={skeletonWidth} count={1} />
    );
  let priceBusiness =
    props.User && props.User.getPriceBusiness ? (
      `${props.User.getPriceBusiness()}`
    ) : (
      <Skeleton width={skeletonWidth} count={1} />
    );
  let profilePictureURL =
    UserManager &&
    UserManager.currentUser &&
    UserManager.currentUser.profilePictureURL
      ? UserManager.currentUser.profilePictureURL
      : '';

  if (!profilePictureURL) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      let profilePictureURLRendered =
        UserManager &&
        UserManager.currentUser &&
        UserManager.currentUser.profilePictureURL
          ? UserManager.currentUser.profilePictureURL
          : '';
      setProfilePic(profilePictureURLRendered);
    }, 2000);

    timeout1 = setTimeout(() => {
      let profilePictureURLRendered =
        UserManager &&
        UserManager.currentUser &&
        UserManager.currentUser.profilePictureURL
          ? UserManager.currentUser.profilePictureURL
          : '';
      setProfilePic(profilePictureURLRendered);
    }, 4000);
  }

  const user = props.User;
  let availableForBooking = user && user.availableForBooking ? true : false;
  let availableForBookingBusiness =
    user && user.availableForBookingBusiness ? true : false;

  const showHeb =
    i18n.language === 'he' || i18n.language === 'global' || !i18n.language;
  const extra = `${showHeb ? styles.heb : ''}`;

  const businessBottomSection = () => {
    return (
      <div
        className={`w-100 ${styles.borderSmall} d-flex flex-row align-items-end`}
      >
        <div className={`${styles.regular}`}>
          {availableForBookingBusiness && user && user.getPriceBusiness
            ? t('from_price')
            : t('booking_whoisfor_not_available')}
        </div>
        <div className={`${styles.highlighted}`}>
          {!availableForBookingBusiness ? '' : ` ${priceBusiness}`}
        </div>
      </div>
    );
  };

  const businessContentSection = () => {
    return (
      <div
        className={`w-100 ${
          styles.forBusinessContainer
        } flex-column d-flex align-items-center ${
          !availableForBookingBusiness ? styles.disable : ''
        }`}
        onClick={() => {
          availableForBookingBusiness && props.setFlow('forBusiness');
        }}
      >
        <div
          className={`${styles.img} ${
            props.flow === 'forBusiness' ? styles.marked : ''
          }`}
        >
          <img src={bag} className={styles.icon} />
        </div>
        <div className={styles.text}>{t('forBusiness')}</div>
      </div>
    );
  };

  const forMeContainer = () => {
    const photo =
      profilePictureURL || profilePic || props.userProfilePic || forMeIcon;
    const isIcon = photo === forMeIcon;
    // console.log(`isIcon = `, isIcon);
    return (
      <div
        className={`${
          styles.forMeContainer
        } flex-column d-flex align-items-center ${
          !availableForBooking ? styles.disable : ''
        }`}
        onClick={() => {
          availableForBooking && props.setFlow('forMe');
        }}
      >
        <div
          className={`${styles.img} ${
            props.flow === 'forMe' ? styles.marked : ''
          }`}
        >
          <img
            src={photo}
            className={isIcon ? styles.icon : styles.imgProfile}
          />
        </div>
        <div className={styles.text}>{t('forMe')}</div>
      </div>
    );
  };

  const forSomeonElseContainer = () => {
    return (
      <div
        className={`${
          styles.forSomeoneElseContainer
        } flex-column d-flex align-items-center ${
          !availableForBooking ? styles.disable : ''
        }`}
        onClick={() => {
          availableForBooking && props.setFlow('forFriend');
        }}
      >
        <div
          className={`${styles.img} ${
            props.flow === 'forFriend' ? styles.marked : ''
          }`}
        >
          <img src={friendsIcon} className={styles.icon} />
        </div>
        <div className={styles.text}>{t('forMyFriend')}</div>
      </div>
    );
  };

  const personalizedContentSection = () => {
    return (
      <>
        {forMeContainer()}
        {forSomeonElseContainer()}
      </>
    );
  };
  const personalizedBorderSection = () => {
    return (
      <div
        className={`w-100 ${styles.borderBig} d-flex flex-row align-items-end`}
      >
        <div className={`${styles.regular}`}>
          {availableForBooking && user && user.getPrice
            ? t('from_price')
            : t('booking_whoisfor_not_available')}
        </div>
        <div className={`${styles.highlighted}`}>
          {!availableForBooking ? '' : ` ${price}`}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${
        styles.BookingWhoIsFor
      } ${extra} flex-fill ${centerFlexColumn()}`}
    >
      {/* <div className={styles.title}>Who is this for?</div> */}
      <div
        className={`d-flex flex-row justify-content-between ${styles.boxes}`}
      >
        <div
          className={`${''} d-flex flex-row flex-fill justify-content-between`}
        >
          <div className="d-flex flex-column flex-fill justify-content-between">
            <div
              className={`d-flex flex-row flex-fill justify-content-between`}
            >
              {personalizedContentSection()}
            </div>
            {personalizedBorderSection()}
          </div>
        </div>
        <div style={{ width: '10px' }}></div>
        <div className="d-flex flex-column flex-fill justify-content-between">
          <div className={`d-flex flex-row flex-fill justify-content-between`}>
            {businessContentSection()}
          </div>
          {businessBottomSection()}
        </div>
      </div>
    </div>
  );
};

export default BookingWhoIsFor;
