import { put, takeLatest, call } from 'redux-saga/effects';
import { sendVerificationCode } from '../../routines';
import { auth } from 'firebase';

function* handleTriggerAction(
  action: ReturnType<typeof sendVerificationCode.trigger>
) {
  const recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
    size: 'invisible',
    callback: (response: any) => {
      // console.log('success', response);
    },
    'expired-callback': () => {
      // console.log('expired-callback');
    },
  });
  const phoneNumber = action.payload;
  if (!phoneNumber) {
    yield put(sendVerificationCode.failure());
    return;
  }
  try {
    yield put(sendVerificationCode.request());
    yield recaptchaVerifier.render();
    yield recaptchaVerifier.verify();
    try {
      const confirmResult = yield auth().signInWithPhoneNumber(
        phoneNumber,
        recaptchaVerifier
      );
      yield put(sendVerificationCode.success(confirmResult));
    } catch (e) {
      yield put(sendVerificationCode.failure(e));
    }
  } catch (e) {
    console.error('e', e);
    yield put(sendVerificationCode.failure(e));
  } finally {
    yield put(sendVerificationCode.fulfill());
  }
}

export default function* sendVerificationCodeSaga() {
  yield takeLatest(sendVerificationCode.TRIGGER, handleTriggerAction);
}
