import React, { useState, useEffect } from 'react';
import styles from './EditProfileSocial.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditProfileHeader from '../EditProfileHeader/EditProfileHeader';
import EditProfileSelect from '../EditProfileSelect/EditProfileSelect';
import profileManager from '../../managers/profileManager';
import { centerFlexColumn } from '../../utils/bootstrapUtils';

const Input = (props) => {
  return (
    <div className={styles.input}>
      <div className={styles.text}>{props.text}</div>
      <input
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        type="text"
        placeholder={props.placeholder}
        className={styles.inputElement}
      />
    </div>
  );
};

const LinksContainerSection = (props) => {
  const { t, i18n } = useTranslation();

  const { showAddLinkFlow, addLink, setShowAddLinkFlow } = props;
  const AddLink = () => {
    return showAddLinkFlow ? (
      <EditProfileSelect
        save={addLink}
        close={() => {
          setShowAddLinkFlow(false);
        }}
      />
    ) : null;
  };

  const AddLinkButton = () => {
    return (
      <div
        className={styles.button}
        onClick={() => {
          setShowAddLinkFlow(true);
        }}
      >
        {t('EditProfileSocial_addLink')}
      </div>
    );
  };
  return (
    <div className={`${centerFlexColumn()} w-100`}>
      <div className={styles.wrapper}>
        <Input
          value={props.facebookValue}
          onChange={props.setFacebookValue}
          text={t('profile_platform2')}
          placeholder={t('EditProfileSocial_addLink')}
        />
        <Input
          value={props.instagramValue}
          onChange={props.setInstagramValue}
          text={t('profile_platform3')}
          placeholder={t('EditProfileSocial_addLink')}
        />
        <Input
          value={props.tiktokValue}
          onChange={props.setTikTokValue}
          text={t('profile_platform1')}
          placeholder={t('EditProfileSocial_addLink')}
        />
        <Input
          value={props.twitterValue}
          onChange={props.setTwitterValue}
          text={t('profile_platform4')}
          placeholder={t('EditProfileSocial_addLink')}
        />
        <Input
          value={props.youtubeValue}
          onChange={props.setYoutubeValue}
          text={t('profile_platform6')}
          placeholder={t('EditProfileSocial_addLink')}
        />
        <Input
          value={props.websiteValue}
          onChange={props.setWebsiteValue}
          text={t('profile_platform5')}
          placeholder={t('EditProfileSocial_addLink')}
        />
        {props.otherLinks.map((item) => {
          return (
            <Input
              key={item}
              value={item.link}
              onChange={(newValue) => {
                props.changeNewLink(item.text, newValue);
              }}
              text={item.text}
              placeholder={t('EditProfileSocial_addLink')}
            />
          );
        })}
        {/* <AddLinkButton /> */}
      </div>
      <AddLink />
    </div>
  );
};

/* Add social links */
const EditProfileSocial = (props) => {
  const { t, i18n } = useTranslation();

  const user = props.User;

  const [instagramValue, setInstagramValue] = useState(
    user.instagramProfileURL
  );
  const [tiktokValue, setTikTokValue] = useState(user.tiktokProfileURL);
  const [twitterValue, setTwitterValue] = useState(user.twitterProfileURL);
  const [youtubeValue, setYoutubeValue] = useState(user.youtubeProfileURL);
  const [facebookValue, setFacebookValue] = useState(user.facebookProfileURL);
  const [websiteValue, setWebsiteValue] = useState(user.customProfileURL);
  const [showAddLinkFlow, setShowAddLinkFlow] = useState(false);
  const [otherLinks, setOtherLinks] = useState([]);

  const save = async () => {
    // console.log('facebook', facebookValue);
    // console.log('instagram', instagramValue);
    // console.log('tiktok', tiktokValue);
    // console.log('twitter', twitterValue);
    // console.log('youtube', youtubeValue);
    // console.log('website', websiteValue);
    // console.log('otherLinks', otherLinks);

    const updateObj = {
      custom_url: websiteValue,
      instagram_url: instagramValue,
      facebook_url: facebookValue,
      youtube_url: youtubeValue,
      twitter_url: twitterValue,
      tiktok_url: tiktokValue,
    };

    props.setShowLoading(true);
    const attemptSave = await profileManager.updateProfileSocials(
      updateObj,
      props.hijackedUserId
    );
    props.setShowLoading(false);
    // console.log('attemptSave = ', attemptSave);
    if (attemptSave.success) {
      props.onEditSaveSuccess();
    } else {
      //show error
      window.alert(
        attemptSave.error && attemptSave.error.localizedTitle()
          ? attemptSave.error && attemptSave.error.localizedTitle()
          : 'error'
      );
      const errorTitle = '';
      const errorDetail = '';
      props.onError(errorTitle, errorDetail);
    }
  };

  const addLink = (link, text) => {
    let newOtherLinks = [...otherLinks];
    newOtherLinks.push({ link, text });

    setOtherLinks(newOtherLinks);
    setShowAddLinkFlow(false);
  };

  const changeNewLink = (text, newLink) => {
    let newOtherLinks = otherLinks.map((item) => {
      if (item.text === text) {
        item.link = newLink;
      }

      return item;
    });

    setOtherLinks(newOtherLinks);
  };

  const allowSave = () => {
    const user = props.User;
    if (!user || !user.id) {
      return false;
    } else {
      // console.log('user = ', user);
      let diff = user.facebookProfileURL !== facebookValue;
      diff = diff || user.instagramProfileURL !== instagramValue;
      diff = diff || user.tiktokProfileURL !== tiktokValue;
      diff = diff || user.twitterProfileURL !== twitterValue;
      diff = diff || user.twitterProfileURL !== twitterValue;
      diff = diff || user.youtubeProfileURL !== youtubeValue;
      diff = diff || user.customcustomProfileURL !== websiteValue;
      // console.log('diff = ', diff);
      return diff;
    }
  };

  return (
    <div className={`${i18n.language === 'he' ? styles.heb : ''}`}>
      <EditProfileHeader
        allowSave={allowSave()}
        title={t('editProfile_socials')}
        save={save}
        close={props.close}
      />
      <LinksContainerSection
        user={props.User}
        showAddLinkFlow={showAddLinkFlow}
        setShowAddLinkFlow={setShowAddLinkFlow}
        addLink={addLink}
        facebookValue={facebookValue}
        setFacebookValue={setFacebookValue}
        instagramValue={instagramValue}
        setInstagramValue={setInstagramValue}
        tiktokValue={tiktokValue}
        setTikTokValue={setTikTokValue}
        twitterValue={twitterValue}
        setTwitterValue={setTwitterValue}
        youtubeValue={youtubeValue}
        setYoutubeValue={setYoutubeValue}
        websiteValue={websiteValue}
        setWebsiteValue={setWebsiteValue}
        otherLinks={otherLinks}
      />
    </div>
  );
};

export default withRouter(EditProfileSocial);
