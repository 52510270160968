import React from 'react';
import PageLoader from '../../pageLoader';

interface LoadingOverlayProps {
  showLoading: boolean;
  darkOpacity?: boolean;
  withBackground?: boolean;
}
/**
 * Loading component wrapper
 */
const LoadingOverlay = ({
  showLoading,
  darkOpacity,
  withBackground,
}: LoadingOverlayProps) => {
  return showLoading ? (
    <div className="fixed-top w-100 h-100">
      <PageLoader
        darkOpacity={darkOpacity ?? true}
        withBackground={withBackground ?? true}
      />
    </div>
  ) : null;
};

export default LoadingOverlay;
