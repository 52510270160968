import React, { useState, useEffect } from 'react';
import styles from './EditProfileBox.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/*
    props:
        img - img url
        text - string
*/
const EditProfileBox = (props) => {
  const { t, i18n } = useTranslation();

  // console.log('props...', props.tip);

  return (
    <div
      onClick={() => props.onTipClicked(props.tip)}
      className={`clickable ${styles.wrapper} ${
        i18n.language === 'he' ? styles.heb : ''
      }`}
    >
      {props.img ? <img src={props.img} /> : null}
      {props.text ? <div className={styles.text}>{props.text}</div> : null}
    </div>
  );
};

export default withRouter(EditProfileBox);
