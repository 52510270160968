import { useIntercom } from 'react-intercom-hook';
import React, { useState, useEffect } from 'react';
import styles from './UserSettingsScreen.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import icon_withdraw from '../../Images/icon_withdraw.svg';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ResponsiveLine } from '@nivo/line';
import { lclFlexRightFill } from '../../utils/vlStrings';
import Navbar from '../../components/NewNavbar/Navbar';
import { centerFlexColumn } from '../../utils/bootstrapUtils';

import IconAccount from './images/icon_account.svg';
import IconAdmin from './images/icon_admin.svg';
import IconAlerts from './images/icon_alerts.svg';
import IconDots from './images/icon_dots.svg';
import IconPayments from './images/icon_payments.svg';
import IconReferrals from './images/icon_referrals.svg';
import CarrotRight from './images/carrot_right.svg';
import {
  CONTACT_US,
  LOG_OUT,
  EDIT_PROFILE_ACCOUNT,
} from '../../constants/routes';
import {
  URL_PRIVACY_POLICY,
  URL_TERMS_OF_SERVICE,
} from '../../constants/rhconstants';
import { ButtonBase } from '@material-ui/core';

/*
    props: 
        type: line/timeline - the chart time
        icon: img/svg
        User: all the vlUser
        timeLineData: the timeline chart data
        percent: percent data 
        showWithdraw: true/false - show withdraw button
        text: string, the chart text
*/

const UserSettingsScreen = (props?: any) => {
  const { t, i18n } = useTranslation();
  const [shouldLogout, setShouldLogout] = useState(false);
  const [pathToPush, setPathToPush] = useState('');
  const { boot, show } = useIntercom();
  //Navbar

  //Sections

  const AccountSettingsSection = (props?: any) => {
    return (
      <div className={`${styles.Section} d-flex flex-column w-100`}>
        <Header title="Account Settings" image={IconAccount} />
        <Row
          title="Main details"
          onClick={() => pushToPath(EDIT_PROFILE_ACCOUNT)}
        />
        <Row
          isHidden
          title="Interests"
          onClick={() => console.log('interests')}
        />
        <Row
          isHidden
          title="Subscriptions"
          onClick={() => console.log('subscirptions')}
        />
      </div>
    );
  };

  const ReferralsSettingsSection = (props?: any) => {
    const hidden = props.hidden;
    if (hidden) {
      return null;
    }
    return (
      <div className={`${styles.Section} d-flex flex-column w-100`}>
        <Header title="Referrals" image={IconReferrals} />
        <Row
          title="Invite friends & Earn Cash"
          onClick={() => console.log('rfeer and earn')}
        />
        <Row title="Waitlist" onClick={() => console.log('Waitlist')} />
      </div>
    );
  };

  const PaymentSection = (props?: any) => {
    const hidden = props.hidden;
    if (hidden) {
      return null;
    }
    return (
      <div className={`${styles.Section} d-flex flex-column w-100`}>
        <Header title="Payment" image={IconPayments} />
        <Row
          title="Invite friends & Earn Cash"
          onClick={() => console.log('rfeer and earn')}
        />
        <Row title="Waitlist" onClick={() => console.log('Waitlist')} />
      </div>
    );
  };

  const AlertsSection = (props?: any) => {
    const hidden = props.hidden;
    if (hidden) {
      return null;
    }
    return (
      <div className={`${styles.Section} d-flex flex-column w-100`}>
        <Header title="Alerts" image={IconAlerts} />
        <Row
          title="Notifications are enabled"
          onClick={() => console.log('notification')}
        />
      </div>
    );
  };

  const OtherSection = (props?: any) => {
    const openIntercom = () => {
      boot();
      show();
    };

    const openToMailToSupport = () => {
      window.open('mailto:feedback@kre8.tv');
    };

    return (
      <div className={`${styles.Section} d-flex flex-column w-100`}>
        <Header title="Other" image={IconDots} />
        <Row title="Language & Country" onClick={() => console.log('region')} />
        <Row
          title="Privacy policy"
          onClick={() => openWebsiteInNewTab(URL_PRIVACY_POLICY)}
        />
        <Row
          title="Terms of Service"
          onClick={() => openWebsiteInNewTab(URL_TERMS_OF_SERVICE)}
        />
        <Row
          title="Need help? Send us a message!"
          onClick={() => openIntercom()}
        />
        <Row title="Feedback" onClick={() => openToMailToSupport()} />
        <Row
          title="Share KRE8.TV"
          onClick={() => console.log('share')}
          isHidden
        />
        <br />
        <Row
          title="Log out"
          titleVariant="light"
          hideCarrot={true}
          onClick={() => setShouldLogout(true)}
        />
      </div>
    );
  };

  //Headers
  const Header = (props?: any) => {
    const title: string = props.title;
    const hidden = props.hidden;
    if (hidden) {
      return null;
    }
    return (
      <div>
        <div
          className={`${styles.Header} w-100 d-flex flex-column justify-content-center`}
        >
          <div
            className={`${styles.content} d-flex flex-row justify-content-start`}
          >
            <div className={`${styles.image}`}>
              <img src={props.image}></img>
            </div>
            <div
              className={`${styles.title} d-flex flex-column justify-content-center`}
            >
              {title}
            </div>
          </div>
          <div className={`${styles.divider}`}></div>
        </div>
      </div>
    );
  };

  //Rows
  const Row = (props?: any) => {
    const title: string = props.title;
    const variant = props.variant;
    const titleVariant = styles[props.titleVariant];
    console.log('titleVariant = ', titleVariant);
    const hideCarrot = props.hideCarrot ?? false;
    const isHidden = props.isHidden ?? false;
    if (isHidden) {
      return null;
    }
    return (
      <ButtonBase className={`${styles.Row} w-100`} onClick={props.onClick}>
        <div
          className={`${styles.content} ${styles.variant} d-flex flex-row justify-content-between`}
        >
          <div className={`${styles.title} ${titleVariant}`}>{title}</div>
          <div className={`${styles.carrot}`}>
            {hideCarrot ? null : <img src={CarrotRight}></img>}
          </div>
        </div>
        <div className={`${styles.divider}`}></div>
      </ButtonBase>
    );
  };

  const NavBar = () => {
    return <Navbar />;
  };

  //Methods
  const pushComponentWithTitle = (title: string, component: any) => {
    return <div>{title}</div>;
  };

  const openWebsiteInNewTab = (url: string) => {
    window.open(url);
  };

  const pushQueryToUrl = (queryToPush: string) => {
    props.history.replace(queryToPush);
  };

  const pushToPath = (path: string) => {
    setPathToPush(path);
  };

  if (pathToPush.length > 0) {
    return <Redirect push to={pathToPush} />;
  }

  //Conditionals
  if (shouldLogout) {
    return <Redirect push to={LOG_OUT} />;
  }

  return (
    <div className={`${styles.MasterContainer} ${centerFlexColumn()}`}>
      <NavBar />
      <div className={`${styles.ContentContainer}`}>
        <AccountSettingsSection />
        <ReferralsSettingsSection hidden={true} />
        <PaymentSection hidden={true} />
        <AlertsSection hidden={true} />
        <OtherSection />
      </div>
    </div>
  );
};

export default withRouter(UserSettingsScreen);
