import Session from "./session";

class VLTransaction {
  id = ""; //the unique id of the subscription
  userId = ""; //the user who purchased
  creatorId = ""; //the creator to subscribe to
  requestId = null;
  lastUpdated = null;
  createdOn = null;
  videoURL = null;
  fullQualityVideoURL = null;
  reviewed = true;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  fileName() {
    let date = new Date();
    let seconds = date.getTime() * 1000;
    return `${this.requesterId}_${this.personaId}_${seconds}.mp4`;
  }

  static fromBookingRequest(request) {
    let transaction = new VLTransaction();
    transaction.request = request;
    transaction.requestId = request.id;
    transaction.personaId = request.personaId;
    // transaction.persona = RHMainManager.sharedInstance.userManager.userFromId(
    //   personaId
    // );
    transaction.requesterId = request.requesterId;
    // transaction.requester = RHMainManager.sharedInstance.userManager.userFromId(
    //   requesterId
    // );
    return transaction;
  }

  static fromJSON(json) {
    var transaction = new VLTransaction();
    transaction.id = json.id;
    transaction.personaId = json.persona_id;
    transaction.requesterId = json.requester_id;
    transaction.videoURL = json.video_url;
    transaction.requestId = json.request_id;
    transaction.reviewed = json.reviewed ? json.reviewed : false;
    transaction.originalVideoURL = json.original_video_url;
    return transaction;
  }
}

export default VLTransaction;
