import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Container, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getI18n, useTranslation } from 'react-i18next';

import { withBookingStage } from '../../../../../shared/hoc';
import { updateOcassion } from '../../../../../store/reducers/campaigns/';
import {
  OcassionImageQuestion,
  OcassionImageMotivation,
  OcassionImageEvent,
  OcassionImageAdvice,
  OcassionImageBirthday,
  OcassionImageOther,
  OcassionImageProductPlacement,
  OcassionImagePromotional,
  OcassionImageCollaboration,
  OcassionImageTestimonial,
} from '../../../../../utils/vlImages';
import { BookingOccassion } from '../../../../../model/enums/v2/booking-occassion.enum';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
  },
  ocassionTypeBox: {
    cursor: 'pointer',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.25, 1.25, 1)' },
  },
  imageBox: {
    color: theme.palette.primary.main,
    width: '60px',
    height: '60px',
    minHeight: '60px',
    borderRadius: '18px',
    background: '#F9F9F9',
  },
}));

const OcassionBox = (props) => {
  const classes = useStyles();
  const { title, image, updateOcassion, value } = props;
  return (
    <Box
      onClick={() => updateOcassion({ title, value })}
      className={classes.ocassionTypeBox}
      mt={2}
      mb={2}
      width="33%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100px"
      p={0}
    >
      <Box
        className={classes.imageBox}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <img
          alt="ocassion"
          src={image}
          style={{ width: '32px', height: '32px' }}
        />
      </Box>
      <Typography variant="body1">
        <Box mt={1} textAlign="center">
          {title}
        </Box>
      </Typography>
    </Box>
  );
};

const personalOcassions = [
  {
    title: 'profile_req1',
    image: OcassionImageQuestion,
    value: BookingOccassion.QUESTION,
  },
  {
    title: 'profile_req2',
    image: OcassionImageMotivation,
    value: BookingOccassion.MOTIVATION,
  },
  {
    title: 'profile_req3',
    image: OcassionImageEvent,
    value: BookingOccassion.EVENT,
  },
  {
    title: 'profile_req4',
    image: OcassionImageAdvice,
    value: BookingOccassion.ADVICE,
  },
  {
    title: 'profile_req5',
    image: OcassionImageBirthday,
    value: BookingOccassion.BIRTHDAY,
  },
  {
    title: 'profile_req6',
    image: OcassionImageOther,
    value: BookingOccassion.OTHER,
  },
];

const businessOcassions = [
  {
    title: 'promotional',
    image: OcassionImagePromotional,
    value: BookingOccassion.PROMOTIONAL,
  },
  {
    title: 'testimonial',
    image: OcassionImageTestimonial,
    value: BookingOccassion.TESTIMONIAL,
  },
  {
    title: 'collaboration',
    image: OcassionImageCollaboration,
    value: BookingOccassion.COLLABORATION,
  },
  {
    title: 'productPlacement',
    image: OcassionImageProductPlacement,
    value: BookingOccassion.PRODUCT_PLACEMENT,
  },
  {
    title: 'profile_req4',
    image: OcassionImageAdvice,
    value: BookingOccassion.ADVICE,
  },
  {
    title: 'profile_req6',
    image: OcassionImageOther,
    value: BookingOccassion.OTHER,
  },
];

const OcassionsScreen = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const title = t('campaigns_ocassion_title');
  const { type } = props;
  const ocassions = type === 'personal' ? personalOcassions : businessOcassions;

  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box height="50%" p={2}>
        <Typography variant="h5">
          <Box fontWeight="bold" color="text.primary" textAlign="center" mb={2}>
            {title}
          </Box>
        </Typography>
        <Container maxWidth="xs" disableGutter="true">
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            {ocassions.map((occ, index) => {
              return (
                <OcassionBox
                  updateOcassion={props.updateOcassion}
                  key={index}
                  title={getI18n().t(occ.title)}
                  image={occ.image}
                  value={occ.value}
                />
              );
            })}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
const mapStateToProps = ({ campaign }) => {
  return { type: campaign.type };
};

export default connect(mapStateToProps, { updateOcassion })(
  withBookingStage(OcassionsScreen)
);
