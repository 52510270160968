import { IHomepageSection } from '../../model/interfaces';
import { AirtableManager } from '../../VLBase/Managers/VLAirtableManager';
import store from '../../store/store';

const airtable = new AirtableManager();

/**
 * Fetch home feed
 */

export const fetchHomeFeed = (
  callback: (
    response?: { regions: any; feed: IHomepageSection[] },
    error?: any
  ) => void
) => {
  const regionId = store.getState().user.regionId;

  airtable.fetchHomeFeed((response: any, error: any) => {
    if (!error && response) {
      let adjustedResponse = response;
      //move current region to front
      let regions = adjustedResponse.regions;
      let grab = regions.filter((item: any) => item.id === regionId);
      regions = regions.filter((item: any) => item.id !== regionId);
      if (grab.length > 0) regions.unshift(grab[0]);
      adjustedResponse.regions = regions;
      callback(response, error);
    } else {
      callback(response, error);
    }
  });
};

export const fetchDiscover = (
  categoryId: string,
  callback: (
    response?: { regions: any; feed: IHomepageSection[] },
    error?: any
  ) => void
) => {
  airtable.fetchDiscoverFeed(categoryId, (response: any, error: any) => {
    callback(response, error);
  });
};
