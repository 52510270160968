import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { validURL } from '../../../../utils/rhutils';
import OnSuccessImage from './images/success_video.png';
import { analytics, AnalyticsEvent } from '../../../../utils/analytics';
import { store } from '../../../../store';
import { lclDirection } from '../../../../utils/vlStrings';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  paper: {
    borderRadius: '24px',
  },
  title: {
    color: 'black !important',
    fontSize: '20px',
    lineHeight: '24px',
  },
  body: {
    color: 'black !important',
    fontSize: '16px',
    lineHeight: '24px',
  },
  buttons: {
    justifyContent: 'center',
    marginBottom: '16px',
  },
  button: {
    height: '56px',
    borderRadius: '20px',
    minWidth: '120px',
    boxShadow: 'none',
  },
  stepper: {
    direction: 'ltr',
    padding: 0,
    color: 'black',
  },
  stepBoxContainer: {
    border: '1px #231942 solid',
    borderRadius: '4px',
  },
  helperText: {
    color: '#231942',
    fontFamily: 'Assistant',
  },
});

const PreSubmitView = (props) => {
  const {
    classes,
    title,
    details,
    soundUrl,
    onChange,
    isSubmissionValid,
    buttonTitle,
    onSubmitClicked,
    isSubmitting,
    valueRef,
    t,
  } = props;

  const onSoundClicked = (soundUrl) => {
    window.open(soundUrl, '_blank');
  };
  return (
    <>
      <DialogTitle id="info-dialog-title">
        <Box className={classes.title}>{title}</Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText className={classes.body}>
          <Stepper orientation="vertical" className={classes.stepper}>
            {details.map((label, index) => (
              <Step key={index} expanded={true} active={true}>
                <StepLabel>
                  <Typography>
                    <Box
                      dir={lclDirection()}
                      color="black"
                      className={classes.stepBoxContainer}
                      p={1.5}
                    >
                      {label}
                      {index === 0 ? (
                        <Box mt={1} style={{ wordBreak: 'break-all' }}>
                          <Link onClick={() => onSoundClicked(soundUrl)}>
                            {soundUrl}
                          </Link>
                        </Box>
                      ) : null}
                    </Box>
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogContentText>
        <TextField
          inputRef={valueRef}
          margin="dense"
          id="tiktokVideoURL"
          label={t('challenge_submit_text_field_label')}
          helperText={t('challenge_submit_text_field_helper')}
          type="url"
          fullWidth
          onChange={onChange}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.buttons }}>
        <Button
          onClick={onSubmitClicked}
          disabled={isSubmissionValid === false}
          color="primary"
          variant="contained"
          className={classes.button}
        >
          {isSubmitting ? (
            <CircularProgress size={20} style={{ color: 'white' }} />
          ) : (
            buttonTitle
          )}
        </Button>
      </DialogActions>
    </>
  );
};

const PostSubmitView = (props) => {
  const { classes, handleClose, t } = props;
  return (
    <>
      <DialogTitle id="info-dialog-title">
        <Box className={classes.title}>
          {t('challenge_post_submit_dialog_title')}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box color="black">
          <Box
            id="image"
            mt={2}
            mb={2}
            display="flex"
            alignContent="center"
            justifyContent="center"
          >
            <img
              alt="success"
              src={OnSuccessImage}
              style={{ width: '50%', height: 'auto', objectFit: 'cover' }}
            />
          </Box>
          <DialogContentText>
            <Box color="black">{t('challenge_post_submit_dialog_body_1')}</Box>
          </DialogContentText>
          <DialogContentText>
            <Box color="black">{t('challenge_post_submit_dialog_body_2')}</Box>
          </DialogContentText>
          <DialogContentText>
            <Box color="black">{t('challenge_post_submit_dialog_body_3')}</Box>
          </DialogContentText>
        </Box>
        <DialogActions classes={{ root: classes.buttons }}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            className={classes.button}
          >
            {t('button_title_close')}
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};

function SimpleDialog(props) {
  const classes = useStyles();

  const [isSubmissionValid, setIsSubmissionValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);
  const valueRef = useRef(''); //creating a refernce for TextField Component

  const { onClose, open, challengeId, t } = props;

  const handleClose = () => {
    onClose();

    //reset
    setTimeout(() => {
      setIsSubmissionValid(false);
      setIsSubmitting(false);
      setDidSubmit(false);
    }, 500);
  };

  const onChange = (e) => {
    let val = e.target.value;
    if (validURL(val)) {
      setIsSubmissionValid(true);
    } else {
      setIsSubmissionValid(false);
    }
  };

  const onSubmitClicked = () => {
    setIsSubmitting(true);

    //submit!

    const properties = [
      { challenge_id: challengeId },
      { user_id: store.getState().user.userId },
      { submission_url: valueRef.current.value },
    ];
    analytics.event(
      new AnalyticsEvent('Challenge entry submitted', properties)
    );

    setTimeout(() => {
      setIsSubmitting(false);
      setDidSubmit(true);
    }, 2000);
  };

  return (
    <Dialog
      dir={lclDirection()}
      onClose={handleClose}
      aria-labelledby="info-dialog-title"
      open={open}
      classes={{ paper: classes.paper }}
    >
      {didSubmit ? null : (
        <PreSubmitView
          classes={classes}
          title={props.title}
          details={props.details}
          soundUrl={props.soundUrl}
          onChange={onChange}
          isSubmissionValid={isSubmissionValid}
          isSubmitting={isSubmitting}
          buttonTitle={props.buttonTitle}
          onSubmitClicked={() => onSubmitClicked()}
          valueRef={valueRef}
          t={t}
        />
      )}
      {didSubmit ? (
        <PostSubmitView t={t} handleClose={handleClose} classes={classes} />
      ) : null}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ChallengeSubsmissionDialog(props) {
  return (
    <SimpleDialog open={props.open} onClose={props.handleClose} {...props} />
  );
}
