import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import styles from './GenericPaywallModal.module.scss';
import { useTranslation } from 'react-i18next';
import VLUser from '../../../model/vlUser';
import VLCreationItem from '../../../model/vlCreationItem';
import userManager from '../../../managers/userManager';
import { convertToPrice } from '../../../utils/rhutils';
import PaywallContent from './V2/PaywallContent';
import VLFlowManager, {
  AddPaymentMethodLanding,
} from '../../../managers/v2/VLFlowManager';
const AuthModal = React.lazy(
  () => import('../../../signup/AuthModal/AuthModal')
);

/**
 * Container for the auth modal
 */
const AuthModalContainer = (props?: any) => {
  const { showAuth, onCloseAuth, onAuthSuccess } = props;
  return (
    <AuthModal
      show={showAuth}
      onClose={onCloseAuth}
      onAuthSuccess={onAuthSuccess}
    />
  );
};

interface GenericPaywallModalProps {
  creator?: VLUser; //user data
  onClose: any; // required - on close callback method
  hideCloseButton?: boolean; //optional - hide close button
  creationData?: VLCreationItem; //optional - creation data (to purchase / access)
  onAddPaymentMethodSuccess: any; // optional - on auth success callback method
  isConnected?: boolean; // inherited - redux
  buttonText?: string; // optional - button text
  type:
    | 'personalized_video'
    | 'promotional_video'
    | 'subscription'
    | 'campaign';
  price: number;
  currency: string;
  onSubscriptionChargeTypeSelected?: any;
  onActionButtonClicked?: any;
  onPromotionSet?: any;
  hideRedeemCouponCode?: any;
  cardInputTitle?: any; //optional title above the card input box
}

const GenericPaywallModal = (props: GenericPaywallModalProps) => {
  let isConnected = props?.isConnected;
  const { t, i18n } = useTranslation();
  useEffect(() => {
    //nop
    //log event
    VLFlowManager.sendFlowAnalytics(new AddPaymentMethodLanding());
  }, []);
  const [showAuth, setShowAuth] = useState(false);

  // console.log('GenericPaywallModal', props);

  /**
   * On close auth - should hide auth
   */
  const onCloseAuth = () => {
    // console.log('onCloseAuth');
    setShowAuth(false);
  };

  /**
   * On auth success, should hide auth
   */
  const onAuthSuccess = () => {
    // console.log('onAuthSuccess');
    setShowAuth(false);
  };

  const isHebrew = i18n.language === 'he';
  const masterClass = `${styles.master} ${isHebrew ? styles.heb : ''}`;
  return (
    <div className={masterClass}>
      <AuthModalContainer
        onAuthSuccess={onAuthSuccess}
        showAuth={showAuth}
        onCloseAuth={onCloseAuth}
      />
      <PaywallContent
        hideRedeemCouponCode={props.hideRedeemCouponCode}
        priceBid={props.price}
        priceCurrency={props.currency}
        onAddPaymentMethodSuccess={props.onAddPaymentMethodSuccess}
        onShowAuth={() => setShowAuth(true)}
        type={props.type}
        creator={props.creator}
        onClose={() => props.onClose()}
        onSubscriptionChargeTypeSelected={
          props.onSubscriptionChargeTypeSelected
        }
        onActionButtonClicked={props.onActionButtonClicked}
        onPromotionSet={props.onPromotionSet}
        cardInputTitle={props.cardInputTitle}
      />
    </div>
  );
};

const mapStateToProps = (state?: any) => {
  return { isConnected: state.user.isConnected };
};

export default connect(mapStateToProps)(GenericPaywallModal);
