import React, { useState, useEffect } from 'react';
import styles from './BookingLoading.module.scss';
import loading_image from '../../Images/loading_image1.svg';
import { Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import VLProgressLoader from '../../../VLProgressLoader/VLProgressLoader';

let interval;
const BookingLoading = (props) => {
  const { t, i18n } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [loadPercentage, setLoadPercentage] = useState(0);

  const startLoading = () => {
    setLoadPercentage(0);
    let startLoading = 0;
    interval = setInterval(() => {
      let newLoading = Number(startLoading) + 8;
      startLoading += 1;

      // console.log('loadPercentage', loadPercentage);
      // console.log('newLoading', newLoading);
      if (newLoading < 95) {
        setLoadPercentage(newLoading);
      }
    }, 300);
  };

  // console.log('BookingLoading isLoading', props.loading);

  const stopLoading = () => {
    clearInterval(interval);
    setLoadPercentage('');
  };

  useEffect(() => {
    if (props.loading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [props.loading]);

  const onChange = () => {
    setChecked(!checked);
  };

  const clickedOnNextStep = () => {
    if (!props.loading) {
      props.nextStep();
    }
  };

  return (
    <div
      className={`d-flex flex-column justify-content-between align-items-center ${
        i18n.language === 'he' || i18n.language === 'global' || !i18n.language
          ? styles.heb
          : ''
      } ${styles.bookingLoading} ${props.loading ? styles.loading : ''}`}
    >
      <div className="d-flex flex-column w-100 align-items-center">
        <div className={styles.title}>{t('booking_loading_title')}</div>
        <div className={styles.subTitle}>{t('booking_loading_subTitle')}</div>
      </div>
      <div className={styles.imgWrapper}>
        <img src={props.profilePictureURL} className={styles.userProfile} />
        <img
          src={props.creatorProfilePictureURL}
          className={styles.creatorProfile}
        />
        <img src={loading_image} className={styles.img} />
      </div>
      <div className={styles.downText}>
        <div onClick={onChange}>
          <Form.Check
            type="switch"
            checked={checked}
            className={styles.checkboxBootstrap}
          />
        </div>
        <div>Notify me when video ready</div>
      </div>
      {props.loading ? (
        <div className={styles.loading_text}>
          {t('booking_loading_button_loading')}
        </div>
      ) : null}
      <div
        className={`${styles.button} ${
          props.loading ? styles.buttonLoading : ''
        }`}
        onClick={clickedOnNextStep}
      >
        {props.loading ? (
          <VLProgressLoader
            color="rgb(93,54,189)"
            percentage={loadPercentage}
          />
        ) : (
          t('booking_loading_share')
        )}
      </div>
    </div>
  );
};
export default BookingLoading;
