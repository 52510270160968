import { createSlice } from '@reduxjs/toolkit';
import { isInIsraelTimeZone } from '../../../App';
import { isHebrew } from '../../../utils/vlStrings';

/* Basic User Model */
const initialState = {
  isConnected: false,
  details: {}, //vlUser object
  accountDetails: {}, //vlAccountDetails
  paymentMethods: [],
  regionId: localStorage.getItem('region_id')
    ? localStorage.getItem('region_id')
    : isInIsraelTimeZone()
    ? 'israel'
    : 'usa',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Redux actions
    updateConnectStatus(state, action) {
      state.isConnected = action.payload;
    },
    updateUserDetails(state, action) {
      // console.log('updateUserDetails:', action);
      state.details = action.payload;
    },
    updateAccountDetails(state, action) {
      // console.log('updateUserDetails:', action);
      state.accountDetails = action.payload;
    },
    resetUser(state, action) {
      // console.log('resetUser:', action);
      state.isConnected = false;
      state.details = {};
      state.userId = null;
    },
    updateRegion(state, action) {
      localStorage.setItem('region_id', action.payload);
      state.regionId = action.payload;
    },
    updateUserId(state, action) {
      state.userId = action.payload;
    },
    updatePaymentMethods(state, action) {
      state.paymentMethods = action.payload;
    },
  },
});

/* Export Redux Actions */
export const {
  updateConnectStatus,
  updateUserDetails,
  resetUser,
  updateRegion,
  updateUserId,
  updatePaymentMethods,
  updateAccountDetails,
} = userSlice.actions;

/* Export the reducer */
export default userSlice.reducer;
