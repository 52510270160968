import React, { useState, useEffect } from 'react';
import styles from './EditProfileHeader.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import back from '../Images/back.svg';
import info from '../Images/info.svg';
import icon_search_soft from '../Images/icon_search_soft.svg';
import icon_search_main from '../Images/icon_search_main.svg';
import close from '../Images/close.svg';
/*
header of the edit profile
 props:
    title: string
*/
const EditProfileHeader = (props) => {
  const { t, i18n } = useTranslation();
  const [showSearch, setShowSearch] = useState(false);

  const save = () => {
    //click on the save button
    if (props.save) {
      props.save();
    }
  };

  const toggleSearch = () => {
    //open and close the search
    props.onChangeSearch('');
    setShowSearch(!showSearch);
  };

  const regularHeader = () => {
    //if it's a regular header

    const allowSave = props.allowSave;
    const SaveButton = () => {
      return props.save ? (
        <div
          className={`${styles.save} ${
            allowSave ? 'clickable opacity-5' : 'opacity-3'
          } `}
          onClick={save}
        >
          {t('editProfileAccount_save')}
        </div>
      ) : !props.hideSave ? (
        <img src={icon_search_main} onClick={toggleSearch} />
      ) : (
        <div></div>
      );
    };
    return (
      <div className={styles.wrapper}>
        <img
          className={`clickable ${styles.backCarrot}`}
          src={back}
          onClick={props.close}
        />
        <div className={styles.title}>{props.title}</div>
        <SaveButton />
      </div>
    );
  };

  const onChangeSearch = (e) => {
    //if the search is open
    if (props.onChangeSearch) {
      props.onChangeSearch(e.target.value);
    }
  };

  const searchOpen = () => {
    return (
      <div className={`${styles.wrapper} ${styles.wrapperSearch}`}>
        <img src={close} onClick={toggleSearch} />
        <input
          type="text"
          placeholder={t('regionPicker_search')}
          className={styles.search}
          onChange={onChangeSearch}
          autoFocus
        />
        <img src={icon_search_soft} />
      </div>
    );
  };

  return (
    <div className={`${i18n.language === 'he' ? styles.heb : ''}`}>
      {showSearch ? searchOpen() : regularHeader()}
    </div>
  );
};

export default withRouter(EditProfileHeader);
