import React, { useState } from 'react';
import styles from './BookingStepsBoxes.module.scss';
import { useTranslation } from 'react-i18next';
import BookingBox from './BookingBox/BookingBox';
import BookingBoxEditable from './BookingBoxEditable/BookingBoxEditable';
import UserManager from '../../../../managers/userManager';
import userManager from '../../../../managers/userManager';
import {
  ORDER_STEP_SELECT_OCASSION,
  ORDER_STEP_SELECT_PRICE,
  ORDER_STEP_SET_DESCRIPTION,
} from '../Booking';

const ForWhoStep = (props) => {
  const { t } = props;
  return props.flow === 'forFriend' ? (
    <BookingBoxEditable
      key="forWho"
      imgLoading={props.forWhoImageLoading}
      showError={props.showForWhoError}
      img={props.whoIsFromImage ? props.whoIsFromImage : ''}
      uploadImage={(input) => {
        props.uploadImage(input, 'whoIsFor');
      }}
      onChange={(text) => {
        props.setActiveField('forWho');
        props.changedText(text, 'whoIsFor');
      }}
      title={t('whoIsThisFor')}
      text={t('enterFriendName')}
      showCheckbox={true}
      value={props.whoIsFor}
      readOnly={props.step >= 2}
      autoFocus={props.activeField === 'forWho'}
    />
  ) : null;
};

const FromWhoStep = (props) => {
  const { t } = props;
  return props.flow === 'forFriend' ? (
    <BookingBoxEditable
      key="fromWho"
      imgLoading={props.fromWhoImageLoading}
      showError={props.showFromWhoError}
      img={props.fromWhoImage ? props.fromWhoImage : props.profilePictureURL}
      uploadImage={(input) => {
        props.uploadImage(input, 'fromWho');
      }}
      onChange={(text) => {
        props.setActiveField('fromWho');
        props.changedText(text, 'fromWho');
      }}
      title={t('fromWhoItWillBe')}
      text={t('forMe')}
      showCheckbox={true}
      value={props.fromWho}
      readOnly={props.step >= 2}
      autoFocus={props.activeField === 'fromWho'}
    />
  ) : null;
};

const ForBusinessStep = (props) => {
  const { t } = props;
  return props.flow === 'forBusiness' ? (
    <BookingBoxEditable
      key="whoIsForBusiness"
      showError={props.showBusinessNameInputError}
      onChange={(text) => {
        props.changedText(text, 'whoIsForBusiness');
      }}
      img={props.fromWhoImage ? props.fromWhoImage : props.profilePictureURL}
      uploadImage={(input) => {
        props.uploadImage(input, 'whoIsForBusiness');
      }}
      title={t('brandName')}
      text={t('yourBrandName')}
      showCheckbox={true}
      value={props.whoIsForBusiness}
      readOnly={props.step >= 2}
      autoFocus={true}
    />
  ) : null;
};

const OrderingFromStep = (props) => {
  const { profilePic, name, t } = props;
  return <BookingBox img={profilePic} title={t('requestTo')} text={name} />;
};

const OcassionStep = (props) => {
  const { t } = props;
  return props.step > ORDER_STEP_SELECT_OCASSION ? (
    <BookingBox
      img={props.selectedOccasionImg}
      icon={true}
      title={t('occasion')}
      text={props.selectedOccasion}
    />
  ) : null;
};
const DescriptionStep = (props) => {
  const { t } = props;
  return props.step > ORDER_STEP_SET_DESCRIPTION ? (
    <BookingBox title={t('description')} text={props.description} />
  ) : null;
};

const PriceStep = (props) => {
  const { t } = props;
  return props.step > ORDER_STEP_SELECT_PRICE ? (
    <BookingBox title={t('priceBid')} text={props.currency + props.price} />
  ) : null;
};

/**
 * Vertical booking steps which are used to show summary and also allow input/changes to be made
 * @param {*} props
 */
const BookingStepsBoxes = (props) => {
  const { t, i18n } = useTranslation();

  const creator = props.User;
  if (!creator) {
    return null;
  }
  let profilePic = creator.profilePictureURL ? creator.profilePictureURL : '';
  let name = creator.fullName();
  let profilePictureURL = userManager.getUser().profilePictureURL
    ? userManager.getUser().profilePictureURL
    : '';
  let currency = creator.getCurrencySymbol ? creator.getCurrencySymbol() : '';

  return (
    <div
      className={`${styles.BookingSteps} ${
        i18n.language === 'he' || !i18n.language || i18n.language === 'global'
          ? styles.heb
          : ''
      }`}
    >
      <OrderingFromStep {...props} t={t} name={name} profilePic={profilePic} />
      <ForWhoStep {...props} t={t} />
      <FromWhoStep {...props} t={t} profilePictureURL={profilePictureURL} />
      <ForBusinessStep {...props} t={t} profilePictureURL={profilePictureURL} />
      <OcassionStep {...props} t={t} />
      <DescriptionStep {...props} t={t} />
      <PriceStep {...props} t={t} currency={currency} />
    </div>
  );
};

export default BookingStepsBoxes;
