import React, { useEffect } from 'react';
import styles from './Sidebar.module.scss';
import classNames from 'classnames';
import { LangSwitcher } from '../LangSwitcher';
import { Image, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useComponentVisible } from '../../shared/hooks';
import userManager from '../../managers/userManager';
import { useHistory } from 'react-router-dom';
import placeholder from './placeholder.svg';
import { useDispatch } from 'react-redux';
import { withCurrentUser } from '../../shared/hoc';
import icon_home from '../NewNavbar/Home.svg';
import icon_home_soft from '../NewNavbar/Home_soft.svg';
import icon_profile from '../NewNavbar/Profile.svg';
import icon_profile_soft from '../NewNavbar/ProfileSoft.svg';
import icon_activity from '../NewNavbar/header_ask.svg';
import icon_activity_soft from '../NewNavbar/header_askSoft.svg';
import { Link } from 'react-router-dom';
import { signOut, fetchHome } from '../../store/routines';
import RegionPicker from '../NewNavbar/RegionPicker';
import VLRegion from './../../model/vlRegion';
import { setRegion } from '../../store/actions/global';
import { ACCOUNT_AUTHED } from '../../constants/routes';

type SidebarProps = {
  onClose: any;
  onLanguageChanged: any;
  currentUser: any;
  theme: any;
  isVisible: any;
  showLogin: any;
  regions: any;
  currentRegion?: VLRegion;
  onRegionSelected: any;
  hasClickedOutside: any;
};

const Sidebar: React.FC<SidebarProps> = React.memo(
  ({
    onClose,
    onLanguageChanged,
    currentUser,
    theme,
    isVisible,
    showLogin,
    regions,
    currentRegion,
    onRegionSelected,
  }) => {
    const { t, i18n } = useTranslation();
    const authorized = !!currentUser;

    // const { ref, isComponentVisible } = useComponentVisible(true);
    const [isHebrew, setHebrew] = React.useState(i18n.language === 'he');
    const dispatch = useDispatch();

    const history = useHistory();
    const handleClick = () => {
      // console.log('handle click');
      if (authorized) {
        history.push(ACCOUNT_AUTHED);
      } else {
        // console.log('handle login');
        showLogin();
        // history.push(SIGN_UP);
      }
    };

    const changeLang = () => {
      onLanguageChanged && onLanguageChanged();
      // font-family: 'Rubik', sans-serif;

      setHebrew(!isHebrew);
    };

    const logout = () => {
      // history.push(LOG_OUT);
      // onClose();

      userManager.signOut();
      dispatch(signOut());
      // this.props.onLogout();
    };

    if (userManager.getUser() === false) {
      return <div></div>;
    }

    const user = userManager.getUser();
    let profilePictureURL = user.profilePictureURL;

    const LoggedInSideButtons = (props: any) => {
      if (!authorized) return null;
      return (
        <div>
          <Link to={{ pathname: `/` }}>
            <div className={`${styles.row}`}>
              <img
                alt="home"
                src={theme === 'dark' ? icon_home : icon_home_soft}
              />
              <div className={``}>{t('tab_home')}</div>
            </div>
          </Link>
          <Link to={{ pathname: `${ACCOUNT_AUTHED}` }}>
            <div className={styles.row}>
              <img
                alt="profile"
                src={theme === 'dark' ? icon_profile : icon_profile_soft}
              />
              <div className={styles.row_text}>{t('tab_profile')}</div>
            </div>
          </Link>
          <Link to={{ pathname: `/activity` }}>
            <div className={styles.row}>
              <img
                alt="activity"
                src={theme === 'dark' ? icon_activity : icon_activity_soft}
              />
              <div className={styles.row_text}>{t('tab_activity')}</div>
            </div>
          </Link>
        </div>
      );
    };

    return (
      <div
        className={classNames(styles.sidebar, {
          [styles.rtl]: isHebrew,
          [styles.isVisible]: isVisible,
          [styles.dark]: theme === 'dark',
          [styles.white]: theme === 'white',
        })}
      >
        <div className={styles.wrapper}>
          {/* <div
           className={classNames(styles.close, 'p-3', 'm-3')}
           onClick={onClose}/> */}
          {/* @ts-ignore */}
          <div style={{ marginTop: '15px' }}>
            <LangSwitcher isSidebar onLanguageChanged={changeLang} />
          </div>
          <div className={`${styles.marginTop}`} onClick={handleClick}>
            {authorized && (
              <Image
                style={{ objectFit: 'cover' }}
                src={profilePictureURL ? profilePictureURL : placeholder}
                roundedCircle
                width={60}
                height={60}
              />
            )}
            <span className={classNames('mx-2', styles.fullname)}>
              {authorized ? (
                userManager?.currentUser?.localizedFullName()
              ) : (
                <>
                  <span>{t('log_in')}</span>
                </>
              )}
            </span>
          </div>

          <ListGroup className={classNames(styles.menu, 'mt-2')}>
            <LoggedInSideButtons />
            {authorized && (
              <div className={styles.row} onClick={logout}>
                <div className={styles.row_text}>{t('log_out')}</div>
              </div>
            )}
          </ListGroup>
          <ul className={classNames(styles.links, 'list-unstyled')}>
            <li className="py-0" style={{ marginTop: '20px' }}>
              <a href="https://kre8.tv/termsofservice.html">
                {t('terms_of_service')}
              </a>
            </li>
            <li className="py-0" style={{ marginTop: '20px' }}>
              <a href="https://kre8.tv/privacypolicy.html">
                {t('privacy_policy')}
              </a>
            </li>
            <li className="py-0" style={{ marginTop: '20px' }}>
              <RegionPicker
                currentRegion={currentRegion}
                regions={regions}
                menuAlignToBottom={true}
                theme={theme}
                onRegionSelected={(region: VLRegion) => {
                  onRegionSelected(region);
                }}
                fromSideBar={true}
                hideText={false}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
);

// @ts-ignore
export default withCurrentUser(Sidebar);
