import { getI18n } from 'react-i18next';
import api, * as RHAPI from '../constants/api';
import userManager from './userManager.js';
import SessionAccess from '../model/sessionAccess';
import Session from '../model/session';
import CloseFan from '../model/closeFan';
import VLCreationPurchase from '../model/vlCreationPurchase';
import VLError from '../model/vlError';
import firebase, { storage } from 'firebase';
import * as utils from '../utils/rhutils.js';
import * as constants from '../constants/rhconstants.js';
import dBManager from './dbManager';
import VLUser from '../model/vlUser';
import VLProfileAction from '../model/enums/VLProfileAction';
import VLProfileStrength from '../model/enums/VLProfileStrength';
import VLProfileTip from '../model/enums/VLProfileTip';

import creationManager from './creationManager';
import * as vlImages from '../utils/vlImages';
import VLCharityCause from '../model/vlCharityCause';

class ProfileManager {
  constructor() {}

  userToken() {
    return userManager.tokenId;
  }

  //Edit profile update functions
  /**
   * 
   * @param data The data to update the account info with
   * @param data Hijacked user id if we are admins and want to edit their profiles (there is server side validation)
   * @tutorial
   updateObj = {
      first_name : String;
      last_name : String;
      profile_id : String;
      profilePictureURL : String?;
      profileVideoURL : String?;
      title : String?;
      desc : String?;
      tags : [String];
      }; 
      - See editProfileV2 (edit-profile-v2.function.ts the simple backend logic on this for help)
      * @returns success : boolean, user :  VLUser?, error : VLError? 
   */

  async updateProfileAccount(data: any, hijackedUserId?: string): Promise<any> {
    return this.updateProfileData('account', data, false, hijackedUserId);
  }

  /**
   * 
   * @param data The data to update the profile personal requests info with
   * @tutorial
   updateObj = {
      available_for_booking : true/false;
      price : double;
      }; 
      - See editProfileV2 (edit-profile-v2.function.ts the simple backend logic on this for help)
   */
  async updateProfilePersonalRequests(
    data: any,
    hijackedUserId?: string
  ): Promise<any> {
    return this.updateProfileData(
      'personal_requests',
      data,
      false,
      hijackedUserId
    );
  }

  /**
   * 
   * @param data The data to update the profile business requests info with
   * @tutorial
   updateObj = {
      available_for_booking_business : true/false;
      price_business : double;
      }; 
      - See editProfileV2 (edit-profile-v2.function.ts the simple backend logic on this for help)
   */
  async updateProfileBusinessRequests(
    data: any,
    hijackedUserId?: string
  ): Promise<any> {
    return this.updateProfileData(
      'business_requests',
      data,
      false,
      hijackedUserId
    );
  }

  /**
   * 
   * @param data The data to update the subscription wit
   * @tutorial
   updateObj = {
      close_fan_plan : {
        active : true/false;
        price: Double,
        custom_welcome_message : String?
        custom_success_message : String?,
      }
      }; 
      - See editProfileV2 (edit-profile-v2.function.ts the simple backend logic on this for help)
   */
  async updateProfileSubscription(
    data: any,
    hijackedUserId?: string
  ): Promise<any> {
    return this.updateProfileData('subscription', data, false, hijackedUserId);
  }

  /**
   * 
   * @param data The social networks to add
   * @tutorial
   updateObj = {
      charity : {
        imageURL: string?;
        info: string?;
        //@IsUrl()
        infoURL: string?;
        name: string?;
        name_hebrew: string?;
      }
      }; 
      - See editProfileV2 (edit-profile-v2.function.ts the simple backend logic on this for help)
   */
  async updateProfileCause(data: any, hijackedUserId?: string): Promise<any> {
    return this.updateProfileData('cause', data, false, hijackedUserId);
  }

  /**
   * 
   * @param networks The social networks to add
   * @tutorial
   updateObj = {
        custom_url: String?,
        instagram_url: String?,
        facebook_url: String?,
        youtube_url: String?,
        twitter_url: String?,
        tiktok_url: String?,
      }; 
      - See editProfileV2 (edit-profile-v2.function.ts line 217 on the simple backend logic on this for help)
   */
  async updateProfileSocials(
    networks: any,
    hijackedUserId?: string
  ): Promise<any> {
    const data = networks;
    return this.updateProfileData('socials', data, false, hijackedUserId);
  }

  /**
   *
   * @param data The data to update wit
   * @param hijackedUserId  Hijacked user id (nullable)
   */
  async updateProfileAdmin(data: any, hijackedUserId?: string): Promise<any> {
    return this.updateProfileData('admin', data, false, hijackedUserId);
  }

  //essential steps for sign up
  /**
   * 
   * @param networks The social networks to add
   @ TODO: See formatting here:
   updateObj = {
        custom_url: String?,
        instagram_url: String?,
        facebook_url: String?,
        youtube_url: String?,
        twitter_url: String?,
        tiktok_url: String?,
      }; 
      - See editProfileV2 (edit-profile-v2.function.ts line 217 on the simple backend logic on this for help)
   */
  async updateSocials(networks: any): Promise<any> {
    const data = networks;
    return this.updateProfileData('socials', data, true);
  }

  async updateProfilePicture(
    profilePictureURL: string,
    title: string
  ): Promise<any> {
    const data = { profilePictureURL: profilePictureURL, title: title };
    return this.updateProfileData('profile_picture', data, true);
  }

  async submitFirstCreation(text: string): Promise<any> {
    // console.log('submitFirstCreation called:', text);
    let result = await creationManager.submitTextCreation(text);
    let creation = result.creation;
    let hasCreation = creation !== null;
    let response: { success: boolean };
    response = { success: hasCreation };
    // console.log('submitFirstCreation response:', response);
    return response;
  }

  //extended logic

  async updateProfileSocialNetworks(
    userId: string,
    instagram?: string,
    facebook?: string,
    youtube?: string,
    twitter?: string,
    tiktok?: string,
    website?: string
  ) {
    const url = api.EDIT_PROFILE_V2;
    let headers = await userManager.getRequestHeaders();

    interface SignupSocialsBody {
      update_type: string;
      id: string;
      instagram_url?: string;
      facebook_url?: string;
      youtube_url?: string;
      twitter_url?: string;
      tiktok_url?: string;
      custom_url?: string;
    }

    let data: SignupSocialsBody = {
      update_type: 'socials',
      id: userId,
    };

    if (instagram) data['instagram_url'] = instagram;
    if (facebook) data['facebook_url'] = facebook;
    if (youtube) data['youtube_url'] = youtube;
    if (twitter) data['twitter_url'] = twitter;
    if (tiktok) data['tiktok_url'] = tiktok;
    if (website) data['custom_url'] = website;

    let body = JSON.stringify(data);
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });

    let json = await response.json();
    // console.log('networks', json);

    return {
      success: response.status === 200,
      error: null,
    };
  }

  async updateProfileData(
    updateType: string,
    data: any,
    isSignup = false,
    hijackedUserId?: string
  ): Promise<any> {
    const url = api.EDIT_PROFILE_V2;
    let headers = await userManager.getRequestHeaders();
    //we mask the update type by adding 'signup' prefix to custom handle it on backend :)
    data['update_type'] = isSignup ? `signup_${updateType}` : updateType;

    if (hijackedUserId) {
      data['hijacked_id'] = hijackedUserId;
    } else {
      data['id'] = userManager.getUser().id;
    }

    let body = JSON.stringify(data);
    // console.log('updateProfileData calling:', body);

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
    });

    /* Sync user to local storage, shouldn't impact the response */
    let json = await response.json();
    // console.log('updateProfileData response:', json, response.status);

    const userResponse = VLUser.fromJSON(json);
    const error = VLError.fromJSON(json.error);

    return {
      success: userResponse !== null,
      user: userResponse,
      error: error,
    };
  }

  async submitVerificationRequest(
    type: string,
    full_name: string,
    active_profile_url: string
  ) {
    try {
      const verificationObj = {
        type: type,
        full_name: full_name,
        active_profile_url: active_profile_url,
      };
      const url = api.SUBMIT_VERIFICATION_REQUEST;
      let headers = await userManager.getRequestHeaders();
      let body = JSON.stringify(verificationObj);

      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: body,
      });

      let json = await response.json();
      let status = await response.status;
      console.log('submitVerificationRequest response json:', json);
      return {
        success: status === 200,
        error: null,
      };
    } catch (error) {
      return {
        success: false,
        error: null,
      };
    }
  }

  async fetchCauses(): Promise<any> {
    const url = api.FETCH_CHARITIES;
    let headers = await userManager.getRequestHeaders();
    //we mask the update type by adding 'signup' prefix to custom handle it on backend :)
    // console.log('fetchCauses calling:');

    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    /* Sync user to local storage, shouldn't impact the response */
    let json = await response.json();
    // console.log('fetchCauses response:', json, response.status);

    let allCharitiesJSON = json;
    // console.log('allCharitiesJSON:', allCharitiesJSON['ahava']);
    const charities: VLCharityCause[] = [];

    Object.keys(allCharitiesJSON).forEach(function (key, index) {
      // key: the name of the object key
      // index: the ordinal position of the key within the object
      let cause = VLCharityCause.fromJSON(allCharitiesJSON[key]);
      if (cause) {
        // console.log('adding cause - ', cause);
        charities.push(cause);
      }
    });

    const error = VLError.fromJSON(json.error);
    return {
      success: response.status === 200 && !error,
      result: charities,
      error: error,
    };
  }

  shouldShowUserSubscriptionRelatedInfo(user: VLUser) {
    return (
      this.availableActionsForUser(user).indexOf(
        VLProfileAction.Subscription
      ) !== -1
    );
  }
  availableActionsForUser(user: VLUser): VLProfileAction[] {
    var actions: VLProfileAction[] = [];
    actions = [
      VLProfileAction.Account,
      VLProfileAction.Socials,
      VLProfileAction.PersonalRequests,
      VLProfileAction.BusinessRequests,
      VLProfileAction.Subscription,
      VLProfileAction.Cause,
      VLProfileAction.Verify,
    ];
    return actions;
  }

  /// Returns the profile strength for a user
  /// - Parameter user: The user to check again
  /// - Returns:
  /// percent -  a percentage value of profile strength [0,1]
  /// title -  A human readable title for the strength
  /// color -  The primary color for the strength
  profileStrength(user: VLUser): [number, string, string] {
    if (!user || !user.id) {
      return [0.0, '', 'black'];
    }

    let levelValue = 10;
    var strengthValue: number = 0;

    //account
    strengthValue += this.didCompleteProfilePicture(user) ? levelValue : 0; //1
    strengthValue += this.didCompleteProfileVideo(user) ? levelValue : 0; //2
    strengthValue += this.didCompleteTitle(user) ? levelValue : 0; //3
    strengthValue += this.didCompleteDescription(user) ? levelValue : 0; //4
    strengthValue += this.didCompleteTags(user) ? levelValue : 0; //5

    //socials
    strengthValue += this.didCompleteSocials(user) ? levelValue : 0; //6

    //personal requests
    strengthValue += this.didCompletePersonalRequests(user) ? levelValue : 0; //7

    //business requests
    strengthValue += this.didCompleteBusinessRequests(user) ? levelValue : 0; //8

    //subscription
    //special check here - since not all users are allowed subscription
    if (this.shouldShowUserSubscriptionRelatedInfo(user)) {
      strengthValue += this.didCompleteSubscritiption(user) ? levelValue : 0; //9
    } else {
      //otherwise give them the points anyways
      strengthValue += levelValue;
    }

    //cause - doesn't count
    //        strengthValue += (didCompleteCause( user)) ? levelValue : 0 //10

    //verification
    strengthValue += this.didCompleteVerification(user) ? levelValue : 0; //11

    let strength = this.VLProfileStrengthfromProfilePercent(
      Math.min(100, strengthValue)
    );

    var percent = Number(strengthValue) / Number(100);
    percent = Math.min(1, percent);
    return [
      percent,
      this.VLProfileStrengthtoString(strength),
      this.VLProfileStrengthtoColor(strength),
    ];
  }

  //MARK: Profile Strength Helpers
  VLProfileStrengthtoString(action: VLProfileStrength): string {
    switch (action) {
      case VLProfileStrength.Beginner:
        return getI18n().t('profile_strength_beginner');
      case VLProfileStrength.Intermediate:
        return getI18n().t('profile_strength_intermediate');
      case VLProfileStrength.Advanced:
        return getI18n().t('profile_strength_advanced');
      case VLProfileStrength.Expert:
        return getI18n().t('profile_strength_expert');
      default:
        return '';
    }
  }

  VLProfileStrengthtoColor(action: VLProfileStrength): string {
    switch (action) {
      case VLProfileStrength.Beginner:
        return '68A691';
      case VLProfileStrength.Intermediate:
        return 'FFB681';
      case VLProfileStrength.Advanced:
        return '721B26';
      case VLProfileStrength.Expert:
        return '5D36BD';
      default:
        return '';
    }
  }

  VLProfileStrengthfromProfilePercent(percent: number): VLProfileStrength {
    if (percent < 30) {
      return VLProfileStrength.Beginner;
    } else if (percent < 60) {
      return VLProfileStrength.Intermediate;
    } else if (percent < 90) {
      return VLProfileStrength.Advanced;
    } else {
      return VLProfileStrength.Expert;
    }
  }

  /**
   * Return the relevant image and title for each poptip
   * @param profileTip The profileTip to use
   * @returns [any, string, string] Image, title, deep link suffix
   */
  VLProfileTipImageTitle(profileTip: VLProfileTip): [any, string, string] {
    switch (profileTip) {
      case VLProfileTip.ProfilePicture:
        return [
          vlImages.vlImageTipProfileImage,
          getI18n().t('profile_tip_profile_picture'),
          'account',
        ];
      case VLProfileTip.ProfileVideo:
        return [
          vlImages.vlImageTipProfileVideo,
          getI18n().t('profile_tip_profile_video'),
          'account',
        ];
      case VLProfileTip.Title:
        return [
          vlImages.vlImageTipAddTitle,
          getI18n().t('profile_tip_title'),
          'account',
        ];
      case VLProfileTip.Description:
        return [
          vlImages.vlImageTipAddTitle,
          getI18n().t('profile_tip_description'),
          'account',
        ];
      case VLProfileTip.Tags:
        return [
          vlImages.vlImageTipAddTags,
          getI18n().t('profile_tip_cause'),
          'account',
        ];
      case VLProfileTip.Socials:
        return [
          vlImages.vlImageTipSocials,
          getI18n().t('profile_tip_socials'),
          'socials',
        ];
      case VLProfileTip.PersonalRequests:
        return [
          vlImages.vlImageTipPersonalRequests,
          getI18n().t('profile_tip_personal_requests'),
          'personalRequests',
        ];
      case VLProfileTip.BusinessRequests:
        return [
          vlImages.vlImageTipBusinessRequests,
          getI18n().t('profile_tip_business_requests'),
          'businessRequests',
        ];
      case VLProfileTip.Subscriptions:
        return [
          vlImages.vlImageTipSubscription,
          getI18n().t('profile_tip_subscriptions'),
          'subscriptions',
        ];
      case VLProfileTip.Verify:
        return [
          vlImages.vlImageTipProfileImage,
          getI18n().t('profile_tip_verify'),
          'verification',
        ];
      case VLProfileTip.Cause:
        return [
          vlImages.vlImageTipCharity,
          getI18n().t('profile_tip_cause'),
          'causes',
        ];
      default:
        return [null, '', ''];
    }
  }

  //MARK: Tips
  availableTipsForUser(user: VLUser): VLProfileTip[] {
    var actions: VLProfileTip[] = [];

    if (!this.didCompleteProfilePicture(user)) {
      actions.push(VLProfileTip.ProfilePicture);
    }
    if (!this.didCompleteProfileVideo(user)) {
      actions.push(VLProfileTip.ProfileVideo);
    }
    if (!this.didCompleteTitle(user)) {
      actions.push(VLProfileTip.Title);
    }
    if (!this.didCompleteDescription(user)) {
      actions.push(VLProfileTip.Description);
    }
    if (!this.didCompleteTags(user)) {
      actions.push(VLProfileTip.Tags);
    }
    if (!this.didCompleteSocials(user)) {
      actions.push(VLProfileTip.Socials);
    }

    if (!this.didCompletePersonalRequests(user)) {
      actions.push(VLProfileTip.PersonalRequests);
    }

    if (!this.didCompleteBusinessRequests(user)) {
      actions.push(VLProfileTip.BusinessRequests);
    }

    if (this.shouldShowUserSubscriptionRelatedInfo(user)) {
      if (!this.didCompleteSubscritiption(user)) {
        actions.push(VLProfileTip.Subscriptions);
      }
    }

    if (!this.didCompleteVerification(user)) {
      actions.push(VLProfileTip.Verify);
    }
    if (!this.didCompleteCause(user)) {
      actions.push(VLProfileTip.Cause);
    }
    return actions;
  }

  //MARK: Strength - account
  didCompleteProfilePicture(user: VLUser): boolean {
    return (
      user.profilePictureURL !== null &&
      !user.profilePictureURL?.includes('avatar')
    );
  }

  didCompleteProfileVideo(user: VLUser): boolean {
    return user.hasProfileVideo();
  }

  didCompleteTitle(user: VLUser): boolean {
    const didComplete = user.title !== null && user.title!.length > 0;
    return didComplete;
  }

  didCompleteDescription(user: VLUser): boolean {
    return user.hasDescription();
  }

  didCompleteTags(user: VLUser): boolean {
    return user.hasTags();
  }

  //MARK: Socials
  didCompleteSocials(user: VLUser): boolean {
    return user.hasSocial();
  }

  //MARK: Personal Requests
  didCompletePersonalRequests(user: VLUser): boolean {
    return user.availableForBooking === true;
  }

  //MARK: Business Requests
  didCompleteBusinessRequests(user: VLUser): boolean {
    return user.availableForBookingBusiness === true;
  }

  //MARK: Subscriptions
  didCompleteSubscritiption(user: VLUser): boolean {
    // console.log(
    // 'user.hasActiveCloseCircleSubscription();',
    // user.hasActiveCloseCircleSubscription()
    // );
    return user.hasActiveCloseCircleSubscription();
  }

  //MARK: Cause
  didCompleteCause(user: VLUser): boolean {
    return user.cause !== null;
  }

  //MARK: Verification
  didCompleteVerification(user: VLUser): boolean {
    return user.verified === true;
  }
}

var profileManager = new ProfileManager();
export default profileManager;
