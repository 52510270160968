import firebase from 'firebase';
import amplitude from 'amplitude-js/amplitude';
// import { FullStoryAPI } from 'react-fullstory';
import * as utils from './rhutils';
import userManager from '../managers/userManager';
import { analytics, AnalyticsEvent } from './analytics';

// export const initAmplitude = () => {
//   amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE);
// };

export function SetAmplitudeUserDevice(installationToken) {
  amplitude.getInstance().setDeviceId(installationToken);
}

export function LogEvent(eventType, eventProperties) {
  // console.log('----> TYPE: ', eventType);
  // console.log('----> PROP: ', eventProperties);

  analytics.event(new AnalyticsEvent(eventType, eventProperties));

  return;

  //Amplitude
  amplitude.getInstance().logEvent(eventType, eventProperties);

  //facebook
  // ReactPixel.trackCustom(eventType, eventProperties);

  //firebase
  firebase
    .analytics()
    .logEvent(toFirebaseAnalyticsString(eventType), eventProperties);

  // @ts-ignore
  window.Intercom('trackEvent', eventType, eventProperties);
}

export const logPurchaseBookingRequest = (bookingRequest) => {
  logPurchaseMaster(
    bookingRequest.personaId,
    'request',
    bookingRequest.price,
    bookingRequest.currency
  );
};

export const logPurchaseSession = (sessionAccess) => {
  logPurchaseMaster(
    sessionAccess.sessionId,
    'session',
    sessionAccess.price,
    sessionAccess.currency
  );
};

export const logPurchaseCreation = (creationPurchase) => {
  logPurchaseMaster(
    creationPurchase.id,
    'creation',
    creationPurchase.price,
    creationPurchase.currency
  );
};

export const logPurchaseMembership = (closeFan) => {
  logPurchaseMaster(
    closeFan.creatorId,
    'subscription',
    closeFan.price,
    closeFan.currency
  );
};
export const logPurchaseMaster = (productId, contentType, price, currency) => {
  //facebook
  let fbParams = {
    fb_content_id: productId,
    fb_content_type: contentType,
    value: price,
    currency: currency,
  };
  // ReactPixel.trackCustom('Purchase', fbParams);

  //Amplitude
  let priceInUSD = utils.convertToPrice(price, currency, 'USD');
  var revenue = new amplitude.Revenue()
    .setProductId(productId)
    .setPrice(priceInUSD)
    .setRevenueType(contentType);
  amplitude.getInstance().logRevenueV2(revenue);

  //firebase
  let firebaseParams = {
    item_id: productId,
    item_category: contentType,
    price: price,
    value: price,
    currency: currency,
  };
  // firebase.analytics().logEvent('ecommerce_purchase', firebaseParams);
  // firebase.analytics().logEvent('purchase', firebaseParams);
  // firebase.analytics().logEvent('web_purchase', firebaseParams);

  // utils.log.debug('logging purchase event amplitude:', priceInUSD);
  // utils.log.debug('logging purchase event firebase:', firebaseParams);
};

/* ADD TO CART */

/**
 * @deprecated
 */
export const logAddToCartBookingRequest = (bookingRequest) => {
  logAddToCartMaster(
    bookingRequest.personaId,
    'request',
    bookingRequest.price,
    bookingRequest.currency
  );
};

export const logAddToCartSession = (session) => {
  logAddToCartMaster(
    session.id,
    'session',
    session.pricePerAccess,
    session.priceCurrency
  );
};

export const logAddToCartMembership = (closeFanPlan, creator) => {
  logAddToCartMaster(
    creator.id,
    'subscription',
    closeFanPlan.price,
    closeFanPlan.currency
  );
};

export const logAddToCartMaster = (productId, contentType, price, currency) => {
  //facebook
  let fbParams = {
    fb_content_id: productId,
    fb_content_type: contentType,
    value: price,
    currency: currency,
  };
  // ReactPixel.trackCustom('AddToCart', fbParams);

  //Amplitude
  let priceInUSD = utils.convertToPrice(price, currency, 'USD');
  let amplitudeParams = {
    fb_content_id: productId,
    fb_content_type: contentType,
    price: priceInUSD,
    currency: currency,
  };
  amplitude.getInstance().logEvent('web_add_to_cart', amplitudeParams);
  amplitude.getInstance().logEvent('add_to_cart', amplitudeParams);

  //firebase
  let firebaseParams = {
    item_id: productId,
    item_category: contentType,
    price: price,
    value: price,
    currency: currency,
  };
  firebase.analytics().logEvent('add_to_cart', firebaseParams);
  firebase.analytics().logEvent('web_add_to_cart', firebaseParams);

  utils.log.debug('logging add to cart event:', firebaseParams);
};

export const toFirebaseAnalyticsString = (str) => {
  return str.toLowerCase().replace(' ', '_');
};
