import { lclDirection, lclTextAlignmentClass } from '../utils/vlStrings.js';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import 'react-phone-number-input/style.css';
import transactionManager from '../managers/transactionManager.js';
import VLUser from '../model/vlUser';
import BookingRequest from '../model/bookingRequest';
import VLTransaction from '../model/vlTransaction';
import * as utils from '../utils/rhutils.js';
import * as analytics from '../utils/amplitude.js';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ButtonLoader from './buttonLoader';
import { withTranslation } from 'react-i18next';
import styles from './SubmitTransactionModal.module.scss';

import close from '../assets/icons/icon_modal_close.svg';

class SubmitTransactionModal extends React.Component {
  state = {
    submitting: false,
    success: false,
    isReadyToSubmit: false,
    createdTransaction: null,
    fileUploadProgress: null,
  };

  render() {
    // console.log("SubmitTransactionModal displayed");
    // console.log(this.props);
    let { error } = this.state;
    if (error) {
      return this.renderError();
    } else {
      return this.renderBody();
    }
  }

  previousRequest = null;

  renderBody() {
    const { bookingRequest } = this.props;
    if (!bookingRequest || !bookingRequest.user) {
      return null;
    }
    if (this.previousRequest !== bookingRequest) {
      // resetUI();
    }
    this.previousRequest = bookingRequest;

    const { fileUploadProgress, isReadyToSubmit } = this.state;
    let user = VLUser.copy(bookingRequest.user);
    return (
      <Modal
        onHide={() => this.closeModal()}
        id="modal-container"
        show={this.props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: 99999 }}
        dialogClassName="transaction-modal"
      >
        <img src={close} className="img-close" onClick={() => this.closeModal()} />
        <div className="d-flex mb-1 flex-column pl-4 pr-4">
          <h3 className="mt-2 mb-4 text-center">
            {this.props.t('submit_video')}
          </h3>
          {this.renderHeaderSection()}
          <h5 className="text-center mt-0" dir={lclDirection()}>
            {bookingRequest.requestSubtitleText()}
          </h5>
          <br></br>
          <div className="text-center mt-0">{bookingRequest.desc}</div>
        </div>

        <Modal.Body className="justify-content-center pl-3 pr-3">
          <div className="custom-file">
            <input
              onChange={this.handleTransactionFileChange.bind(this)}
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              className="custom-file-input"
              id="transactionFile"
              data-browse="Upload"
              custom="true"
              ref={(ref) => (this.transactionFileUploadRef = ref)}
            />
            <label
              className={`custom-file-label ${lclTextAlignmentClass()}`}
              htmlFor="transactionFile"
            >
              {this.state.transactionFile
                ? this.state.transactionFile.name
                : this.props.t('upload_video_to_send')}
            </label>
          </div>
          <ProgressBar
            className={`mt-2 mt-4 ${fileUploadProgress ? '' : 'd-none'}`}
            label={`${Math.ceil(fileUploadProgress)}%`}
            animated={fileUploadProgress < 100}
            now={fileUploadProgress}
          />
        </Modal.Body>

        {this.renderBottomButton()}
      </Modal>
    );
  }
  renderError() {
    let { error } = this.state;
    return (
      <Modal
        onHide={() => this.closeModal()}
        id="modal-container"
        show={this.props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="d-flex mb-1 flex-column pl-4 pr-4">
          <h2 className="mt-2 mb-0 text-center">Error</h2>
          <h5 className="text-center mt-0">{error}</h5>
          <br></br>
        </div>

        <Modal.Body className="justify-content-center">
          <div className="d-flex flex-column mb-2 mt-2">
            <div
              dir={lclDirection()}
              className={`btn btn-danger mb-2}`}
              onClick={() => this.props.onCloseClicked()}
            >
              DISMISS
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  handleTransactionFileChange(event) {
    utils.log.debug('event..', event.target);
    utils.log.debug(
      'transaction file..',
      this.transactionFileUploadRef.files[0]
    );
    let file = this.transactionFileUploadRef.files[0];
    if (file && this.isFileValid(file)) {
      this.setState({ transactionFile: file, isReadyToSubmit: true });
      utils.log.debug('✅ transaction file selected');
      analytics.LogEvent('Upload video for request file selected', {
        request_id: this.props.bookingRequest.id,
      });
    } else {
      this.setState({ transactionFile: null, isReadyToSubmit: false });
      utils.log.debug('🚨 transaction file removed');
    }
  }

  isFileValid(file) {
    return true;
  }
  renderBottomButton() {
    let {
      createdTransaction,
      isReadyToSubmit,
      isUploadingFile,
      isSubmitting,
    } = this.state;
    if (isSubmitting) {
      return (
        <Modal.Body className="justify-content-center">
          <div className="d-flex flex-column mb-2 mt-2">
            <div dir={lclDirection()} className={`btn btn-create mb-2}`}>
              <ButtonLoader />
            </div>
          </div>
        </Modal.Body>
      );
    }
    if (createdTransaction) {
      return (
        <Modal.Body className="justify-content-center">
          <div className="d-flex flex-column mb-2 mt-2">
            <div
              dir={lclDirection()}
              className={`btn btn-success mb-2}`}
              onClick={() => this.oncloseAfterTransactionSuccess()}
            >
              {this.props.t('upload_success_tap_to_close')}
            </div>
          </div>
        </Modal.Body>
      );
    } else {
      return (
        <Modal.Body className="justify-content-center">
          <div className="d-flex flex-column mt-2">
            <div
              dir={lclDirection()}
              className={`btn btn-create ${
                isReadyToSubmit || isUploadingFile ? '' : 'disabled'
              }`}
              onClick={() => this.uploadFile()}
            >
              {isUploadingFile
                ? this.props.t('uploading_file')
                : isReadyToSubmit
                ? this.props.t('click_to_upload')
                : this.props.t('choose_file_first')}
            </div>
          </div>
        </Modal.Body>
      );
    }
  }
  renderHeaderSection() {
    // console.log("state..", this.state);
    let { bookingRequest } = this.props;
    let user = VLUser.copy(bookingRequest.user);
    // console.log("user", user);
    return (
      <div className="d-flex align-items-center flex-column align-self-center  align-content-center bd-highlight mb-3">
        <div className="mb-2">
          <img
            className="circular mx-auto d-block user-profile-picture mt-2 mb-2"
            alt="Profile Picture"
            src={user.profilePictureURL}
          />
        </div>
        <div className="mb-autobd-highlight text-center text-dark">
          {user.fullName()} | {user.handle()}
        </div>
      </div>
    );
  }

  async uploadFile() {
    let { isSubmitting, transactionFile, isUploadingFile } = this.state;
    if (isSubmitting === true || isUploadingFile === true || !transactionFile) {
      return;
    }

    this.setState({ isUploadingFile: true });
    const { bookingRequest } = this.props;
    utils.log.debug('props bookingRequest...', bookingRequest);
    let br = new BookingRequest(bookingRequest);
    utils.log.debug('new booking request object...', br);
    let transaction = VLTransaction.fromBookingRequest(br);
    utils.log.debug('transaction from booking request...', transaction);
    if (!transaction) {
      utils.log.error('no transaction when trying to upload file!');
      return;
    }

    transactionManager.uploadFile(
      transactionFile,
      transaction,
      (downloadURL, progress, error) => {
        utils.log.debug('👨‍💻 callbacks...', downloadURL, progress, error);
        utils.log.debug('this...', this);
        if (error) {
          // This is where the magic will happen
          this.setState({ error: error, isUploadingFile: false });
        } else if (downloadURL) {
          utils.log.debug('✅ finnished uploading video!');
          this.setState({
            fileUploadProgress: 100,
            transactionVideoURL: downloadURL,
            isUploadingFile: false,
          });
          this.submitTransaction();
        } else if (progress) {
          utils.log.debug('🔼 updating progress... ', progress);
          this.setState({ fileUploadProgress: progress });
        }
      }
    );
  }

  async submitTransaction() {
    this.setState({ isSubmitting: true });

    let { transactionVideoURL } = this.state;
    const { bookingRequest } = this.props;
    let transaction = VLTransaction.fromBookingRequest(bookingRequest);
    transaction.videoURL = transactionVideoURL;

    if (!transactionVideoURL) {
      this.setState({
        isSubmitting: false,
        error: 'No video url when trying to submit transaction',
      });
      utils.log.error(
        'error!! - now transaction video url when trying to upload transaction'
      );
      return;
    }

    analytics.LogEvent('Attempted submit transaction');
    utils.log.debug('🚀 subimtting transaction!');
    const result = await transactionManager.submitTransaction(transaction);
    utils.log.debug('👋 submit session result...', result);
    if (result.error) {
      this.setState({
        error: result.error.localizedTitle(),
        isSubmitting: false,
      });
      analytics.LogEvent('Error creating session', {
        error: result.error.localizedTitle(),
      });
    } else if (result.transaction) {
      analytics.LogEvent('New transaction submitted');
      this.setState({
        createdTransaction: result.transaction,
        isSubmitting: false,
      });
    } else {
      utils.log.debug('🚨 neither clauses matched...', result);
    }
  }

  closeModal() {
    this.props.onCloseClicked();
    this.resetUI();
  }

  resetUI() {
    this.setState({
      transactionFile: null,
      fileUploadProgress: null,
      isReadyToSubmit: false,
      createdTransaction: null,
    });
  }

  oncloseAfterTransactionSuccess() {
    analytics.LogEvent('Upload video for request file success', {
      request_id: this.props.bookingRequest.id,
      transaction_id: this.state.createdTransaction.id,
    });
    this.props.onTransactionSuccess();
    this.resetUI();
  }
}

export default withTranslation()(SubmitTransactionModal);
