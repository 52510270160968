import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { updateStep } from '../../../../store/reducers/campaigns';
import VLButton from '../../../../Reusables/Buttons/VLButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'none',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  titleDefault: {
    color: 'white',
  },
  titleWhite: {
    color: 'white',
  },
  titlePrimary: {
    color: theme.palette.primary.main,
  },
  detail: {
    color: theme.palette.secondary.main,
  },
  closeButtonTransparent: {
    background: 'none',
    color: 'white',
  },
  closeButtonNormal: {
    background: '#F9F9F9',
    color: theme.palette.primary.main,
  },
  closeButtonWhite: {
    background: 'white',
    color: theme.palette.primary.main,
  },
}));

const BottomBar = (props) => {
  let {
    step,
    type,
    ocassion,
    selectedCreators,
    forWho,
    fromWho,
    businessName,
    message,
    fromRegularBooking,
    deliveryDeadline,
  } = props;

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  switch (step) {
    case 0:
      return null;
    case 6:
    case 7:
    case 8:
      return null;
    default:
      break;
  }

  let allowBack = false;
  let hideBack = false;
  let allowNext = false;
  switch (step) {
    case 0:
      allowBack = false;
      allowNext = false;
      break;
    case 1:
      allowBack = true;
      allowNext = Boolean(type);
      break;
    case 2: //ocassion
      allowBack = true;
      allowNext = Boolean(ocassion);
      break;
    case 3: //categories
      hideBack = fromRegularBooking ? true : false;
      allowBack = fromRegularBooking ? false : true;
      allowNext = Boolean(type);
      break;
    case 4: //creators
      allowBack = true;
      allowNext = selectedCreators.length > 0;
      break;
    case 5: //BRIEF
      allowBack = true;
      allowNext = Boolean(
        (type === 'business' &&
          businessName &&
          message &&
          message.length > 10) ||
          (type === 'personal' && message && message.length > 10)
      );
      break;
    case 6: //SUMMARY
    case 7: //SUBMISSION LOADING
      allowBack = true;
      allowNext = Boolean(type);
      break;
    case 8:
      allowBack = true;
      allowNext = Boolean(type);
      break;
    default:
      break;
  }

  return (
    <Box
      width="100%"
      height="80px"
      className={classes.root}
      mb={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Container maxWidth="xs" disableGutters>
        <Grid container justify="center">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={hideBack ? 'center' : 'space-between'}
            width="75%"
          >
            <VLButton
              disabled={allowBack === false}
              onClick={() => props.updateStep(step - 1)}
              variant="outlined"
              style={{
                width: '150px',
                background: 'white',
                color: '#5D36BD',
                display: hideBack ? 'none' : 'block',
              }}
            >
              {t('back')}
            </VLButton>
            <VLButton
              disabled={allowNext === false}
              onClick={() => props.updateStep(step + 1)}
              style={{ width: '150px' }}
            >
              {t('next')}
            </VLButton>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

const mapStateToProps = ({ campaign }) => {
  return {
    step: campaign.step,
    type: campaign.type,
    ocassion: campaign.ocassion,
    selectedCreators: campaign.selectedCreators,
    message: campaign.message,
    deliveryDeadline: campaign.deliveryDeadline,
    forWho: campaign.forWho,
    fromWho: campaign.fromWho,
    businessName: campaign.businessName,
    message: campaign.message,
  };
};

export default connect(mapStateToProps, { updateStep })(BottomBar);
