import memoize from 'lodash/memoize';
import * as utils from '../utils/rhutils.js';
import * as dateUtils from '../utils/dateUtils';

import publicPostImage from '../images/post_visibility_icon_public.png';
import privatePostImage from '../images/post_visibility_icon_private.png';
import subscribersPostImage from '../images/post_visibility_icon_subscribers.png';

import creationManager from '../managers/creationManager';
import userManager from '../managers/userManager';
import socialManager from '../managers/socialManager';

import PostVisibilityType from './enums/PostVisibilityType';
import CreationDataType from './enums/CreationDataType';

class VLCreationItem {
  id = null;
  text = null;
  title = null;
  desc = null;
  videoURL = null;
  imageURL = null;
  thumbURL = null;
  userId = null;
  createdOn = Date();
  lastUpdated = Date();
  visibilityType = PostVisibilityType.PUBLIC;
  dataType = CreationDataType.UNKNOWN;

  //what they paid for
  priceSubscribers = 0;
  priceNonSubscribers = 0;
  currency = null;

  likesCount = 0;
  commentsCount = 0;
  viewsCount = 0;

  creator = null;

  /**
   * Returns if the post Should the post be shown to subscribers only
   */
  showToSubsribersOnly() {
    return this.visibilityType === PostVisibilityType.SUBSCRIBERS_ONLY;
  }
  matchesQuery(query) {
    let lcQuery = query.toLowerCase();
    let includesQuery = this.localizedTitle()
      ? this.localizedTitle().toLowerCase().includes(lcQuery)
      : false;
    if (this.creator) {
      return this.creator.matchesQuery(query) || includesQuery;
    } else {
      return includesQuery;
    }
  }

  /* determine if this creation was created by the current user logged in */
  isByCurrentUser() {
    if (userManager.userId === this.userId) {
      return true;
    }
    return false;
  }
  /**
   * Determine if the current post should be "blocked" from being viewed by the current user
   */
  blockFromCurrentUser() {
    if (this.showToSubsribersOnly() == false) {
      return false;
    }

    //if the user is a subscriber of the local user object (attached to the post)
    if (this.creator && this.creator.isCurrentUserASubscriber() == true) {
      return false;
    }

    //If the user can edit this post, don't show blocker
    return !this.shouldAllowEdit();
  }

  /**
   * Determines if this creation is a buy to watch
   */
  isBuyToWatch() {
    const price = this.priceSubscribers + this.priceNonSubscribers;
    return this.visibilityType === PostVisibilityType.PUBLIC && price > 0;
  }

  /**
   * Returns the price and currency for the current user
   */
  priceAndCurrencyForCurrentUser() {
    let baseCurrency = this.currency;
    let targetCurrency = userManager.currentUserCurrency();
    if (this.isSubscriber()) {
      utils.log.debug('priceAndCurrencyForCurrentUser is sub');
      let basePrice = this.priceSubscribers;
      let currentUserPrice = utils.convertToPrice(
        basePrice,
        baseCurrency,
        targetCurrency
      );
      utils.log.debug(`returnging ${currentUserPrice} ${targetCurrency}`);
      return { price: currentUserPrice, currency: targetCurrency };
    } else {
      utils.log.debug('priceAndCurrencyForCurrentUser is not sub');
      let basePrice = this.priceNonSubscribers;
      let currentUserPrice = utils.convertToPrice(
        basePrice,
        baseCurrency,
        targetCurrency
      );
      utils.log.debug(`returnging ${currentUserPrice} ${targetCurrency}`);
      return { price: currentUserPrice, currency: targetCurrency };
    }
  }

  timeAgoString(isHebrew = false) {
    //created on
    return dateUtils.timeAgoString(this.createdOn, isHebrew);
  }

  /// Checks if this creation item should be displayed to the current user or not
  /// - Returns: True if this item should be locked/blocked from the current user
  isLocked() {
    if (this.isByCurrentUser()) {
      return false;
    }
    //If for subscribers only and current user is not a subscriber (close fan)
    if (this.isForSubscribersOnly() && this.isSubscriber() == false) {
      return true;
    } else if (this.visibilityType === PostVisibilityType.PUBLIC) {
      if (this.isSubscriber()) {
        //show if not free for subscribers and the user has not purchased access to view this creation
        return this.priceSubscribers > 0 && this.isPurchased() == false;
      } else {
        //show if not free for non-subscribers and the user has not purchased access to view this creation
        return this.priceNonSubscribers > 0 && this.isPurchased() == false;
      }
    } else {
      return false;
    }
  }

  /* check if current user is subscribers of this creation's creator */
  isSubscriber() {
    return socialManager.isCloseFan(this.userId);
  }

  isPurchased = memoize(() => {
    let purchase = this.creationPurchase();
    if (purchase) {
      // utils.log.debug(`creation item is for ${this.id} is purchased`);
      return true;
    } else {
      // utils.log.debug(`creation item is for ${this.id} is purchased`);
      return false;
    }
  });

  creationPurchase() {
    return creationManager.creationPurchase(this.id);
  }

  /* check if this creation is for subscribers only */
  isForSubscribersOnly() {
    return this.visibilityType === PostVisibilityType.SUBSCRIBERS_ONLY;
  }

  /* check if current user is allowed to edit this creation's content */
  shouldAllowEdit() {
    return (
      utils.showAdminTools() ||
      (utils.currentUser() && utils.currentUser().id === this.userId)
    );
  }

  /* convience method for the image used for the post cell */
  postCellImage() {
    switch (this.visibilityType) {
      case PostVisibilityType.PUBLIC:
        return publicPostImage;
      case PostVisibilityType.PRIVATE:
        return privatePostImage;
      case PostVisibilityType.SUBSCRIBERS_ONLY:
        return subscribersPostImage;
      default:
        return subscribersPostImage;
    }
  }

  /* saves the object to local */
  saveToLocal() {
    localStorage.setItem(this.id, JSON.stringify(this));
  }

  /* check if this creation costs something */
  costsSomething() {
    return this.priceSubscribers + this.priceNonSubscribers > 0;
  }

  /* Is this creation a freebie (free for everyone) */
  isFreebie() {
    return (
      this.visibilityType == PostVisibilityType.PUBLIC &&
      this.costsSomething() === false
    );
  }
  /* Getting button assets for creation item */
  buttonTitle(lang) {
    switch (this.visibilityType) {
      case PostVisibilityType.PRIVATE:
        return 'title_private';
      case PostVisibilityType.PUBLIC:
        let costsSomething = this.costsSomething();
        if (costsSomething) {
          if (this.priceSubscribers === 0) {
            return 'free_for_subscribers';
          } else if (this.priceSubscribers === 0) {
            return 'discount_for_subscribers';
          } else {
            return this.isPurchased() ? 'title_purchased' : 'buy_to_watch';
          }
        } else {
          return this.isByCurrentUser() ? 'title_public' : null;
        }
      case PostVisibilityType.SUBSCRIBERS_ONLY:
        return 'cell_subscribers_only';
      default:
        return 'cell_subscribers_only';
    }
  }

  /* relevant image for creation visibility */
  buttonImage() {
    switch (this.visibilityType) {
      case PostVisibilityType.PRIVATE:
        return privatePostImage;
      case PostVisibilityType.PUBLIC:
        let costsSomething = this.costsSomething();
        if (costsSomething) {
          return subscribersPostImage;
        } else {
          return this.isByCurrentUser() ? publicPostImage : null;
        }
      case PostVisibilityType.SUBSCRIBERS_ONLY:
        return subscribersPostImage;
      default:
        return subscribersPostImage;
    }
  }

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  static nextVisibility(currentVisibility) {
    switch (currentVisibility) {
      case PostVisibilityType.PUBLIC:
        return PostVisibilityType.PRIVATE;
      case PostVisibilityType.PRIVATE:
        return PostVisibilityType.SUBSCRIBERS_ONLY;
      case PostVisibilityType.SUBSCRIBERS_ONLY:
        return PostVisibilityType.PUBLIC;
      default:
        return PostVisibilityType.PUBLIC;
    }
  }

  static dummy(dataType = CreationDataType.IMAGE) {
    let json = {};
    json.id = 'dummy';
    json.title = 'How to edit photo in 4 steps in Lightroom';
    json.desc = 'Longer description about something';
    json.text = 'This is for a text creation';
    json.user_id = '7rYFiLA0ZSP7nAHrTNwTCC9DZMX2';
    json.image_url = 'https://picsum.photos/200/300';
    json.videoURL =
      'https://storage.googleapis.com/download/storage/v1/b/celebrate-io.appspot.com/o/profile_videos%2Fortega_take_2_scaled.mp4?generation=1580734166958048&alt=media';
    json.featured = false;
    json.visibility_type = PostVisibilityType.PUBLIC;
    json.data_type = dataType;
    json.thumb_url = 'https://picsum.photos/300/400';
    json.likes_count = 1;
    json.comments_count = 2;
    json.views_count = 3;

    json.price_subscribers = 0;
    json.price_non_subscribers = 10;
    json.currency = 'USD';

    json.last_updated = 1610665374000;
    json.created_on = 1610665374000;

    return VLCreationItem.fromJSON(json);
  }
  static fromJSON(json) {
    var creation = new VLCreationItem();
    try {
      creation.id = json.id;
      creation.title = json.title;
      creation.desc = json.desc;
      creation.text = json.text;
      creation.userId = json.user_id;
      creation.imageURL = json.image_url;
      creation.videoURL = json.video_url;
      creation.featured = json.featured ? json.featured : false;
      creation.transactionId = json.transaction_id;
      creation.visibilityType = json.visibility_type;
      creation.dataType = json.data_type;
      creation.thumbURL = json.thumb_url;
      creation.likesCount = json.likes_count;
      creation.commentsCount = json.comments_count;
      creation.viewsCount = json.views_count;

      creation.priceSubscribers = json.price_subscribers
        ? json.price_subscribers
        : 0;
      creation.priceNonSubscribers = json.price_non_subscribers
        ? json.price_non_subscribers
        : 0;

      creation.currency = json.currency;
      if (creation.priceSubscribers > 0 || creation.priceNonSubscribers > 0) {
        if (!creation.currency) {
          return false;
        }
      }

      creation.lastUpdated = json.last_updated
        ? new Date(json.last_updated)
        : creation.lastUpdated;
      creation.createdOn = json.created_on
        ? new Date(json.created_on)
        : creation.createdOn;

      return creation;
    } catch (error) {
      return null;
    }
  }
}

export default VLCreationItem;
