import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  root: {
    padding: '0 !important',
    paddingTop: '0',
  },
  paper: {
    borderRadius: '24px',
  },
  title: {
    color: 'black !important',
    fontSize: '20px',
    lineHeight: '24px',
  },
  body: {
    color: 'black !important',
    fontSize: '16px',
    lineHeight: '24px',
  },
  buttons: {
    justifyContent: 'center',
    marginBottom: '16px',
  },
  button: {
    height: '56px',
    borderRadius: '20px',
    minWidth: '120px',
    boxShadow: 'none',
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, videoUrl, open } = props;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="info-dialog-title"
      open={open}
      classes={{ paper: classes.paper, root: classes.root }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <video
          controls
          autoPlay
          loop
          playsInline
          muted={false}
          style={{ width: '100%', height: '100%' }}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function PreviewVideoDialog(props) {
  return (
    <SimpleDialog open={props.open} onClose={props.handleClose} {...props} />
  );
}
