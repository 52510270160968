export const FIREBASE_COLLECTION_USERS = 'users';
export const FIREBASE_COLLECTION_REQUESTS = 'booking_requests';
export const FIREBASE_COLLECTION_ACCOUNT_DETAILS = 'account_details';
export const FIREBASE_POSTS = 'posts';
export const FIREBASE_COLLECTION_CATEGORIES = 'categories';
export const FIREBASE_COLLECTION_AFFILIATE_DEALS = 'affiliate_deals';
export const FIREBASE_COLLECTION_BOOKING_ITEMS = 'booking_items';
export const FIREBASE_COLLECTION_PAYOUTS = 'payouts';
export const FIREBASE_COLLECTION_TRANSACTIONS = 'transactions';
export const FIREBASE_COLLECTION_SUBSCRIPTIONS = 'close_fans';
export const FIREBASE_COLLECTION_CREATIONS = 'creations';
export const FIREBASE_COLLECTION_ADMIN = 'admin';

export const LOCAL_DB_COLLECTION_USERS = 'users';
export const LOCAL_DB_COLLECTION_SUBSCRIPTIONS = 'subscriptions';
export const LOCAL_DB_COLLECTION_CREATION_PURCHASES = 'creation_purchases';
export const LOCAL_DB_COLLECTION_SESSIONS = 'sessions';
export const LOCAL_DB_PURCHASED_SESSIONS_ACCESS = 'purchased_sessions_access';
export const LOCAL_DB_COLLECTION_BOOKING_REQUESTS = 'booking_requests';

export const CREATE_TEAM_ID = 'fXhJ5Kfe9tYNYQPauMHgPheqezF2';

export const MAX_SESSION_PREVIEW_DURATION = 60.0;

export const MINIMUM_TIP_IN_DOLLARS = 5.0;

//BOOKING
export const FLOW_BOOKING_MIN_DESCRIPTION_CHARS = 30;

//META
export const CR_APP_VERSION = '2.0';
export const CR_PLATFORM = 'web';

export const PAYOUT_MILESTONE_DEFAULT = 1000; //1000 NIS

//URLS
export const URL_PRIVACY_POLICY = 'https://kre8.tv/privacypolicy.html';
export const URL_TERMS_OF_SERVICE = 'https://kre8.tv/termsofservice.html';
