import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { lclDirection } from '../../../../utils/vlStrings';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  paper: {
    borderRadius: '24px',
  },
  title: {
    color: 'black !important',
    fontSize: '20px !important',
    lineHeight: '24px',
  },
  body: {
    color: 'black !important',
    fontSize: '16px',
    lineHeight: '24px',
  },
  buttons: {
    justifyContent: 'center',
    marginBottom: '16px',
  },
  button: {
    height: '56px',
    borderRadius: '20px',
    minWidth: '120px',
    boxShadow: 'none',
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      dir={lclDirection()}
      onClose={handleClose}
      aria-labelledby="info-dialog-title"
      open={open}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="info-dialog-title">
        <Box className={classes.title}>{props.title}</Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText className={classes.body}>
          {props.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.buttons }}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={handleClose}
        >
          {props.buttonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function InfoDialog(props) {
  return (
    <SimpleDialog open={props.open} onClose={props.handleClose} {...props} />
  );
}
