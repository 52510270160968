import React from 'react';
import { AuthModal } from '../../signup/AuthModal';

const withAuthModal = (Component: React.FC) => (props: any) => {
  const [show, setShow] = React.useState(!props.currentUser);
  console.log('showshowshowshow');
  return (
    <>
      {show && (
        <AuthModal
          show={show}
          onClose={() => setShow(false)}
          onAuthSuccess={props.onAuthSuccess}
        />
      )}
      <Component {...props} />
    </>
  );
};
export default withAuthModal;
