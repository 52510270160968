import { all, fork } from 'redux-saga/effects';
import fetchCurrentUserDetailsSaga from './fetch-current-user-details';
import sendVerificationCodeSaga from './send-verification-code';
import signUpSaga from './sign-up';
import signOutSaga from './sign-out';
import verifyCodeSaga from './verify-code';

export default function* authSaga() {
  yield all([
    fork(fetchCurrentUserDetailsSaga),
    fork(signUpSaga),
    fork(sendVerificationCodeSaga),
    fork(signOutSaga),
    fork(verifyCodeSaga),
  ]);
}
