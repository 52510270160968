import * as utils from '../utils/rhutils.js';
import firebase, { storage } from 'firebase';

class UploadManager {
  constructor() {}

  /**
   *
   * @param {*} file The file to upload
   * @param {*} callback downloadURL?: string, progress?: double, error?: any
   */
  async uploadProfilePicture(file, callback) {
    utils.log.debug('upload file..', file);

    let ref;
    let path;
    let uploadTask;
    try {
      const storage = firebase.storage();
      ref = storage.ref();
      const folderName = 'profile_pictures';
      const ts = new Date().getTime() / 1000;
      let fileName = `${ts}.jpg`;
      path = `${folderName}/${fileName}`;

      let storageRef = ref.child(path);
      uploadTask = storageRef.put(file);
    } catch (error) {
      utils.log.error(error);
      callback(null, null, error);
      return;
    }

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // utils.log.debug("Upload is " + progress + "% done");
        callback(null, progress, null);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // utils.log.debug("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // utils.log.debug("Upload is running");
            break;
        }
      },
      function (error) {
        // Handle unsuccessful uploads
        utils.log.debug('🚨error uploading session file -'.error);
        callback(null, null, error);
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          utils.log.debug('✅ File available at', downloadURL);
          callback(downloadURL, 100, null);
        });
      }
    );
  }

  async uploadProfileVideo(userId, file, callback) {
    utils.log.debug('upload file..', file);

    let ref;
    let path;
    let uploadTask;
    try {
      const storage = firebase.storage();
      ref = storage.ref();
      const folderName = 'profile_videos';
      const ts = new Date().getTime() / 1000;
      var fileName = `${userId}_${ts}.mp4`;
      path = `${folderName}/${fileName}`;
      var storageRef = ref.child(path);
      uploadTask = storageRef.put(file);
    } catch (error) {
      utils.log.error(error);
      callback(null, null, error);
      return;
    }

    utils.log.debug('begining upload..', file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        utils.log.debug('Upload is ' + progress + '% done');
        callback(null, progress, null);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // utils.log.debug("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // utils.log.debug("Upload is running");
            break;
        }
      },
      function (error) {
        // Handle unsuccessful uploads
        utils.log.debug('🚨error uploading session file -'.error);
        callback(null, null, error);
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          utils.log.debug('✅ File available at', downloadURL);
          callback(downloadURL, 100, null);
        });
      }
    );
  }

  async uploadFile(folderName, file, callback) {
    utils.log.debug('upload file..', file);
    const storage = firebase.storage();
    const ref = storage.ref();
    const ts = new Date().getTime() / 1000;
    let originalFilename = file.name;
    let extension = originalFilename.split('.').pop();
    var fileName = `${ts}.${extension}`;
    var path = `${folderName}/${fileName}`;

    var storageRef = ref.child(path);

    var uploadTask = storageRef.put(file);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // utils.log.debug("Upload is " + progress + "% done");
        callback(null, progress, null);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // utils.log.debug("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // utils.log.debug("Upload is running");
            break;
        }
      },
      function (error) {
        // Handle unsuccessful uploads
        utils.log.debug('🚨error uploading session file -'.error);
        callback(null, null, error);
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          utils.log.debug('✅ File available at', downloadURL);
          callback(downloadURL, 100, null);
        });
      }
    );
  }
}

var uploadManager = new UploadManager();
export default uploadManager;
