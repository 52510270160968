import React, { Component } from 'react';
import styles from './pageLoader.module.scss';
import VLAnimatedLoader from './../Reusables/Loaders/VLAnimatedLoader';
import { centerFlexColumn } from '../utils/bootstrapUtils';
class PageLoader extends Component {
  render() {
    let darkOpacity = this.props.darkOpacity ?? false;

    const { animatedLoader } = this.props;

    const BaseLoader = () => {
      return (
        <div className={`lds-ring ${styles.center}`} id="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    };

    return (
      <div
        className={`${darkOpacity ? styles.darkOpacity : ''} ${
          styles.regular
        } ${
          this.props.withoutBackground ? styles.withoutBackground : ''
        } ${centerFlexColumn()}`}
      >
        <div
          className={`${
            this.props.withBackground ? styles.withBackground : ''
          }`}
        ></div>
        {animatedLoader ? null : <BaseLoader />}
        {animatedLoader ? <VLAnimatedLoader /> : null}
      </div>
    );
  }
}

export default PageLoader;
