import { connect } from 'react-redux';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styles from './Header.module.scss';
import { Box, ButtonBase, Fade, Hidden, Zoom } from '@material-ui/core';

import appleAppIcon from './images/apple_app_icon_vector.png';
import androidAppIcon from './images/google_app_icon_vector.png';
import {
  centerFlexColumn,
  lclDirection,
} from '../../../../utils/bootstrapUtils';
import { useTranslation } from 'react-i18next';
import { baseAppURL } from '../../../../utils/rhutils';
import { analytics, AnalyticsEvent } from '../../../../utils/analytics';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../constants';
import { AuthModal } from '../../../../signup/AuthModal';
import OnboardingFlow from '../../../../Flows/OnboardingFlow';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    height: '200px',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  topCreators: {
    height: '200px',
  },
}));

const FeaturedChallenge = () => {
  const { t } = useTranslation();

  const onAcceptChallengeClick = () => {
    const challengeId = 'noyfadlon';
    const properties = [{ challenge_id: challengeId }];
    analytics.event(
      new AnalyticsEvent('home header challenge button clicked', properties)
    );
    window.open(`${baseAppURL()}/challenges/${challengeId}`, '_blank');
  };
  return (
    <Box
      dir={lclDirection()}
      onClick={onAcceptChallengeClick}
      className={`${styles.featuredChallenge} clickable`}
    >
      <img
        src="https://res.cloudinary.com/kre8-tv/image/upload/v1629140616/kre8.tv/challenges/noy_fadlon_pw4xbo.jpg"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        alt="cover"
      />
      <div className={styles.gradientBackground}></div>
      <div className={styles.title}>{t('noy_fadlon_challenge')}</div>
    </Box>
  );
};
const WebsiteCard = (props) => {
  const { isConnected, didCompleteOnboarding } = props;
  const { t } = useTranslation();
  const [showAuth, setShowAuth] = useState(false);
  const [showOnboarding, setShowonboarding] = useState(false);
  const history = useHistory();

  const takeToAccount = () => {
    setShowonboarding(false);
    history.push('/account');
  };

  return (
    <Box>
      <Box
        dir={lclDirection()}
        onClick={() => {
          if (!isConnected) {
            setShowAuth(true);
          } else {
            //if not completed onboarding, show them onboarding
            if (!didCompleteOnboarding) {
              setShowonboarding(true);
            } else {
              //else, take them to their account!
              takeToAccount();
              //(and open landing page website?)
              window.open('https://kre8.tv/welcome-home', '_blank');
            }
          }
        }}
        // onClick={() => window.open('https://kre8.tv/welcome-home', '_blank')}
        className={`${styles.websiteCard} d-flex flex-column justify-content-between clickable`}
      >
        <div className={styles.title}>{t('home_card_supercreator_title')}</div>
        <div className={styles.subtitle}>
          {t('home_card_supercreator_learn_more')}
        </div>
      </Box>
      <AuthModal
        show={showAuth}
        onAuthSuccess={() => setShowAuth(false)}
        hideCloseButton={false}
        onClose={() => {
          setShowAuth(false);
        }}
      />
      {showOnboarding ? (
        <OnboardingFlow didExit={() => takeToAccount()} contained />
      ) : null}
    </Box>
  );
};

const TopCreators = () => {
  const { t } = useTranslation();
  return (
    <Box
      className={`${styles.topCreators} d-flex flex-column justify-content-end align-items-center clickable`}
    >
      <div className={styles.title}>{t('top_10_creators')}</div>
    </Box>
  );
};
const Campaigns = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const onClick = () => {
    history.push(routes.CAMPAIGN_BOOKING_HOME);
  };
  return (
    <Box
      onClick={() => onClick()}
      className={`${styles.campaigns} d-flex flex-column justify-content-end align-items-center clickable`}
    >
      <div className={styles.title}>{t('new_feature_campaigns')}</div>
    </Box>
  );
};

const ForBusiness = () => {
  const { t } = useTranslation();
  const onClick = () => {
    window.open(routes.LANDING_BUSINESS, '__blank');
  };
  return (
    <Box
      onClick={() => onClick()}
      className={`${styles.campaigns} d-flex flex-column justify-content-end align-items-center clickable`}
    >
      <div className={styles.title}>{t('for_business_banner_title')}</div>
    </Box>
  );
};

const NewsAndCommunity = () => {
  const { t } = useTranslation();
  const onClick = () => {
    window.open('https://discord.gg/zFkMMZkC8z', '__blank');
    // window.open('https://www.facebook.com/kre8.tv', '_blank');
  };
  return (
    <Box
      minHeight="100px"
      flexDirection="column"
      display="flex"
      justifyContent="center"
      dir={lclDirection()}
      onClick={() => onClick()}
      className={`${styles.newsAndCommunity} d-flex flex-column h-50 clickable`}
    >
      <div className={styles.title}>{t('news_and_community')}</div>
    </Box>
  );
};

const AppStoreButtons = () => {
  return (
    <Box
      className={`${styles.appStoreButtons} d-flex flex-row h-50 justify-content-center`}
    >
      <div
        className={`${centerFlexColumn()} ml-2 mr-2 clickable`}
        onClick={() => window.open('https://kre8.tv/ios', '_blank')}
      >
        <img alt="apple" src={appleAppIcon}></img>
      </div>
      <div
        className={`${centerFlexColumn()} ml-2 mr-2 clickable`}
        onClick={() => window.open('https://kre8.tv/android', '_blank')}
      >
        <img alt="android" src={androidAppIcon}></img>
      </div>
    </Box>
  );
};

const Header = (props) => {
  const classes = useStyles();
  const { isConnected, didCompleteOnboarding } = props;

  return (
    <div className={`${classes.root} ${styles.homepageSectionContainer}`}>
      <Zoom in={true} timeout={1000} style={{ transitionDelay: `0ms` }}>
        <Fade in={true} timeout={2000}>
          <Grid
            container
            spacing={3}
            width="100%"
            height="100%"
            alignItems="stretch"
          >
            {/* <Grid item xs={6} lg={3}>
              <TopCreators />
            </Grid> */}
            {/* <Grid item xs={12} sm={4} md={4} lg={6}>
              <FeaturedChallenge />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <WebsiteCard
                isConnected={isConnected}
                didCompleteOnboarding={didCompleteOnboarding}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <ForBusiness />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Box>
                <NewsAndCommunity />
                <AppStoreButtons />
              </Box>
            </Grid>
          </Grid>
        </Fade>
      </Zoom>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    user: state.details,
    didCompleteOnboarding:
      state.user.accountDetails &&
      state.user.accountDetails.didCompleteOnboarding === true,
  };
};
export default connect(mapStateToProps, null)(Header);
