import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import styles from './MembershipIDScreen.module.scss';
import { useTranslation } from 'react-i18next';
import IconClose from '../../components/Modal/GenericPaywallModal/images/icon_close_purple.svg';
// @ts-ignore
import CreditCardInput from 'react-credit-card-input';
import { Dialog, TextField } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import VLUser from '../../model/vlUser';
import CloseFan from '../../model/closeFan';
import { centerFlexColumn, centerFlexRow } from '../../utils/bootstrapUtils';
import IconOpen from './images/external_link.svg';
import { parse } from '../../utils/vlStrings';
import { QRCode } from 'react-qrcode-logo';

interface MembershipIDScreenProps {
  creator: VLUser;
  membershipObj: CloseFan;
  onClose: any;
  onOpenVerse: any;
  hideOpenChannel?: boolean;
  viewMode?: boolean;
  show: boolean;
}

const MembershipIDScreen = (props: MembershipIDScreenProps) => {
  const { t, i18n } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const isHebrew = i18n.language === 'he';
  const theme = useTheme();

  if (!props.show) {
    return null;
  }

  const onOpenMembershipClicked = () => {
    props.onOpenVerse();
    return;
  };

  const onShareClicked = () => {
    //@todo some other implementation here...
    props.onOpenVerse();
    return;
  };

  const TopSection = () => {
    return (
      <div className={`${styles.topSection}`}>
        <div className={`${styles.title}`}>
          {t(
            props.viewMode === true
              ? 'membership_id_screen_title_view_mode'
              : 'membership_id_screen_title'
          )}
        </div>
        <div className={`${styles.detail}`}>
          {parse(
            t(
              props.viewMode
                ? 'membership_id_screen_detail_view_mode'
                : 'membership_id_screen_detail'
            ),
            props.creator.firstName
          )}
        </div>
      </div>
    );
  };

  const CardSection = () => {
    const InfoRow = (title: string, detail: string) => {
      return (
        <div className="w-100 d-flex flex-row justify-content-between">
          <div>{title}</div>
          <div>{detail}</div>
        </div>
      );
    };

    const ForegroundLayer = () => {
      return (
        <div
          className={`${styles.foregroundLayer} d-flex flex-column justify-content-between align-items-center`}
        >
          <div className="d-flex flex-row w-100">
            <div className={`${styles.profilePicture}`}>
              <img
                className="img-fluid"
                src={props.creator.profilePictureURL}
              />
            </div>
            <div className="d-flex flex-column flex-fill ml-4 mr-4">
              <div className={`${styles.title}`}>
                {props.creator.fullName()}
              </div>
              <div className={`${styles.detail}`}>Member ID</div>
            </div>
          </div>
          <div className={`w-100 text-center ${centerFlexColumn()}`}>
            <div className={`${styles.qrCode}`}>
              <QRCode
                size={200}
                logoWidth={200}
                logoOpacity={0.3}
                logoImage={props.creator.profilePictureURL}
                qrStyle="dots"
                value={props.membershipObj.membershipShareURL()}
              />
            </div>
          </div>
          {/* <div className={`${styles.tag}`}>#morethanafan</div> */}
          <div className={`${styles.tag}`}>{props.creator.handle()}</div>
          <div className={`${styles.infoContainer} d-flex flex-column w-100`}>
            {props.membershipObj.member_id
              ? InfoRow(
                  t('member_id-your_member_id'),
                  props.membershipObj.member_id
                )
              : null}
            {/* {InfoRow(t('member_id-purchased_on'), '23.12.2021')} */}
          </div>
          {/* <div
            onClick={() => onShareClicked()}
            className={`${styles.shareButton} ${centerFlexColumn()}`}
          >
            {t('share')}
          </div> */}
        </div>
      );
    };
    return (
      <div className={`w-100 ${centerFlexColumn()}`}>
        <div className={`${styles.idSection} d-flex flex-column`}>
          <div className={`${styles.backgroundLayer}`}>
            <img
              src={props.creator.profilePictureURL}
              className={`${styles.backgroundPicture}`}
            />
          </div>
          <ForegroundLayer />
        </div>
      </div>
    );
  };

  const BottomButton = () => {
    return props.hideOpenChannel == true ? null : (
      <div className={`${centerFlexColumn()}`}>
        <div
          onClick={() => onOpenMembershipClicked()}
          className={`${styles.openButton} ${centerFlexRow()}`}
        >
          {t('open_verse')}
          <img src={IconOpen} />
        </div>
      </div>
    );
  };

  const Navbar = () => {
    return (
      <div className={`${styles.navbar}`}>
        <div
          onClick={() => props.onClose()}
          className={`${styles.closeButton} ${centerFlexColumn()}`}
        >
          <img className="img-fluid" src={IconClose}></img>
        </div>
      </div>
    );
  };
  return (
    <div className={`${styles.Master} ${centerFlexColumn()}`}>
      <div
        className={`${styles.container} w-100 d-flex flex-column justify-content-between`}
      >
        <Navbar />
        <TopSection />
        <CardSection />
        <BottomButton />
      </div>
    </div>
  );
};

const mapStateToProps = (state?: any) => {
  return { isConnected: state.user.isConnected };
};

export default connect(mapStateToProps)(MembershipIDScreen);
