import React, { useEffect } from 'react';
import firebase, { User } from 'firebase';
import PageLoader from '../../components/pageLoader';

const withCurrentUser = (Component: React.FC) => (props: any) => {
  const [checking, setChecked] = React.useState(true);
  const [user, setUser] = React.useState<User | null>(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setChecked(false);
    });
  }, []);
  if (checking) {
    return <PageLoader />;
  }
  return <Component {...props} currentUser={user} />;
};
export default withCurrentUser;
