import {
  vlImagePaywallBirthday,
  vlImagePaywallDiscounts,
  vlImagePaywallStar,
  vlImagePaywallVideo,
  vlImagePaywallZoom,
  vlImagePaywallGenericBenefit,
} from '../utils/vlImages';
import { getI18n } from 'react-i18next';
/**
 * CloseFanPlan - object representing a "subscription" plan that other user's can purchase from said creator
 */
class CloseFanPlan {
  id = 'default';
  active = false;
  price = 0;
  currency = 'USD';
  customMessage = null;
  customWelcomeMessage = null;
  customSuccessMessage = null;
  customName = null;

  //v2
  // - billingCycle
  billingCycle = 'monthly';

  // - benefits (5 total atm)
  benefit_free_personalized_video = false;
  benefit_zoom_and_live_events = false;
  benefit_access_to_member_only_content = false;
  benefit_giveaways_enabled = false;
  benefit_discounts_and_more = false;

  //extras

  additionalBenefits = [];

  isFree() {
    return this.price === 0;
  }

  isActive() {
    return this.price && this.active === true;
  }

  /**
   * Returns an array of supported benefits from this close fan plan
   */
  benefitsList() {
    let benefitsList = [];
    if (this.benefit_free_personalized_video) {
      benefitsList.push({
        title: getI18n().t('subscription_flow_benefit_free_personalized_video'),
        image: vlImagePaywallVideo,
      });
    }
    if (this.benefit_zoom_and_live_events) {
      benefitsList.push({
        title: getI18n().t('subscription_flow_benefit_zoom'),
        image: vlImagePaywallZoom,
      });
    }
    if (this.benefitAccessToMemberOnlyContent) {
      benefitsList.push({
        title: getI18n().t('subscription_flow_benefit_exclusive_content'),
        image: vlImagePaywallStar,
      });
    }
    if (this.benefit_giveaways_enabled) {
      benefitsList.push({
        title: getI18n().t('subscription_flow_benefit_giveaways'),
        image: vlImagePaywallBirthday,
      });
    }
    if (this.benefit_discounts_and_more) {
      benefitsList.push({
        title: getI18n().t('subscription_flow_benefit_discounts_more_benefits'),
        image: vlImagePaywallDiscounts,
      });
    }
    this.additionalBenefits.forEach((item) => {
      benefitsList.push({
        title: item,
        image: vlImagePaywallGenericBenefit,
      });
    });

    return benefitsList;
  }

  toMap() {
    var map = {};
    map['id'] = this.id;
    map['creator_id'] = this.creatorId;
    map['user_id'] = this.userId;
    map['price'] = this.price;
    map['currency'] = this.currency;

    map['active'] = Number(this.active);
    map['price'] = this.price;
    map['currency'] = this.currency;
    map['custom_welcome_message'] = this.customWelcomeMessage;
    map['custom_success_message'] = this.customSuccessMessage;
    map['custom_name'] = this.customName;

    //benefits
    map[
      'benefit_free_personalized_video'
    ] = this.benefit_free_personalized_video;
    map['benefit_zoom_and_live_events'] = this.benefit_zoom_and_live_events;
    map[
      'benefit_access_to_member_only_content'
    ] = this.benefit_access_to_member_only_content;
    map['benefit_giveaways_enabled'] = this.benefit_giveaways_enabled;
    map['benefit_discounts_and_more'] = this.benefit_discounts_and_more;

    return map;
  }

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  static fromJSON(json) {
    var plan = new CloseFanPlan();
    try {
      // console.log(json);
      plan.active = Boolean(json.active);
      plan.price = json.price ? Math.round(Number(json.price) * 100) / 100 : 0;
      plan.currency = json.currency;
      plan.customMessage = json.custom_message;
      plan.customWelcomeMessage = json.custom_welcome_message
        ? json.custom_welcome_message
        : getI18n().t('subscription_flow_default_welcome_message');
      plan.customSuccessMessage = json.custom_success_message;

      //benefits
      plan.benefit_free_personalized_video = json.benefit_free_personalized_video
        ? Boolean(json.benefit_free_personalized_video)
        : false;
      plan.benefit_zoom_and_live_events = json.benefit_zoom_and_live_events
        ? Boolean(json.benefit_zoom_and_live_events)
        : true;
      plan.benefit_access_to_member_only_content = json.benefit_access_to_member_only_content
        ? Boolean(json.benefit_access_to_member_only_content)
        : true;
      plan.benefit_access_to_giveaways = json.benefit_access_to_giveaways
        ? Boolean(json.benefit_access_to_giveaways)
        : true;
      plan.benefit_discounts_and_more = json.benefit_discounts_and_more
        ? Boolean(json.benefit_discounts_and_more)
        : true;

      //additional
      plan.additionalBenefits = json.additional_benefits
        ? json.additional_benefits
        : [];

      // console.log(json);
      return plan;
    } catch (error) {
      return null;
    }
  }
}

export default CloseFanPlan;
