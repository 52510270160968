import React, { useState, useEffect } from 'react';
import styles from './Path.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

/*
    props:
        data:[path1,path2,path3] - array of strings
*/
const Path = (props) => {
  const { t, i18n } = useTranslation();

  const skeleton = props.skeleton;

  return (
    <div
      className={`${i18n.language === 'he' ? styles.heb : ''} d-flex flex-row`}
    >
      {skeleton ? (
        <Skeleton width={100} height={16} />
      ) : (
        <div className={`${styles.path} d-flex flex-row w-100`}>
          {props.data.map((item, index) => {
            //loop on all the path children
            if (index === 0) {
              // if it's the first item don't put //
              return (
                <div
                  className="clickable"
                  onClick={() => props.onPathClicked(item)}
                >
                  <span key={index}>{item}</span>
                </div>
              );
            } else if (index === props.data.length - 1) {
              //if it's an item in the middle
              return (
                <div
                  className="clickable"
                  onClick={() => props.onPathClicked(item)}
                >
                  <span key={index} className={styles.bold}>
                    &nbsp;&nbsp;//&nbsp;&nbsp;{item}
                  </span>
                </div>
              );
            } else {
              //if it's the last child out // only on the begging and not in the end
              return (
                <div
                  className="clickable"
                  onClick={() => props.onPathClicked(item)}
                >
                  <span key={index}>&nbsp;&nbsp;//&nbsp;&nbsp;{item}</span>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default withRouter(Path);
