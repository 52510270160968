import { put, takeLatest } from 'redux-saga/effects';
import { signUp, sendVerificationCode } from '../../routines';

function* handleTriggerAction(action: ReturnType<typeof signUp>) {
  try {
    yield put(signUp.request());
    localStorage.setItem('first_name', action.payload.firstname);
    localStorage.setItem('last_name', action.payload.lastname);
    localStorage.setItem('email', action.payload.email);
    yield put(sendVerificationCode(action.payload.phone));
    yield put(signUp.success());
  } catch (e) {
    console.error('e', e);
    yield put(signUp.failure());
  } finally {
    yield put(signUp.fulfill());
  }
}

export default function* signUpSaga() {
  yield takeLatest(signUp.TRIGGER, handleTriggerAction);
}
