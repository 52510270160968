import * as strings from '../utils/vlStrings.js';

/* taken from ios class */
// enum VLCharityTheme: Int {
//     case charity = 0,
//     fundraiser

//     static func fromInt(value: Int) -> VLCharityTheme {
//         if value == 0 {
//             return .charity
//         } else if value == 1 {
//             return .fundraiser
//         } else {
//             return .charity
//         }
//     }

//     func toString() -> String {
//         switch self {
//         case .charity:
//             return "cause_theme_charity".toLocal()
//         case .fundraiser:
//             return "cause_theme_fundraiser".toLocal()
//         default:
//             return "".toLocal()
//         }
//     }
// }

class VLCharityCause {
  //unqiue id
  id = null;

  //name
  name = null;
  // iamge url
  imageURL = null;
  //info url (website)
  infoURL = null;
  //text based info
  info = null;

  /// A cause is a fundraiser for example vs a charity (album release, personal project, etc)
  cause = false; //true if special cause (album creation, etc..)

  //theme 0 for regular charity, 1 for fundraiser
  causeThemeRaw = 0; //0

  //total money raised for this cause
  totalRaised = -1.0;
  //total completed booking related to this cause / charity
  totalCompletedBookings = 0;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  static EmptyCharity() {
    var charityCause = new VLCharityCause();
    charityCause.id = 'empty';
    return charityCause;
  }
  static fromJSON(json) {
    var charityCause = new VLCharityCause();
    try {
      // console.log('incoming cause json...', json);
      charityCause.id = json.id;
      if (!charityCause.id) {
        // console.log('invalid charirty json...', json);
        return false;
      }
      charityCause.name = json.name;
      charityCause.imageURL = json.imageURL;
      charityCause.info = json.info;
      charityCause.infoURL = json.infoURL;

      charityCause.cause = json.cause ? json.cause : false;

      charityCause.causeThemeRaw = json.theme ? json.theme : 0;

      charityCause.totalRaised = json.total_fundraised
        ? json.total_fundraised
        : 0;
      charityCause.totalCompletedBookings = json.total_completed_bookings
        ? json.total_completed_bookings
        : 0;

      return charityCause;
    } catch (error) {
      return null;
    }
  }

  toMap() {
    var map = {};
    map['id'] = this.id;
    map['name'] = this.name;
    map['imageURL'] = this.imageURL;
    map['info'] = this.info;
    map['infoURL'] = this.infoURL;
    map['cause'] = this.cause;

    return map;
  }
}

export default VLCharityCause;
