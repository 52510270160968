import React from 'react';
import styles from './VLSpinner.module.scss';

import IconCheckmark from './Images/icon_checkmark.svg';
import IconError from './Images/icon_error.svg';

const VLSpinner = (props: any) => {
  const success = props.type === 'success';
  const error = props.type === 'error';

  const Content = (props: any) => {
    const Spinner = () => {
      return (
        <div
          className="spinner-border text-primary"
          role="status"
          style={props.style}
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
    };

    const Checkmark = () => {
      return <img src={IconCheckmark}></img>;
    };

    const Error = () => {
      return <img src={IconError}></img>;
    };

    if (success === true) {
      return <Checkmark />;
    }

    if (error === true) {
      return <Error />;
    }

    //default
    return <Spinner />;
  };
  return <Content {...props} />;
};

export default VLSpinner;
