import { isProduction } from '../App';
import * as utils from './rhutils.js';
import { parse, lclString, dateLocale } from './vlStrings';

export function daysBetweenDates(first, second) {
  var time = first.getTime() - second.getTime();
  var days = time / (1000 * 3600 * 24);
  return parseInt(days);
}

export function secondsBetweenDates(first, second) {
  var time = first.getTime() - second.getTime();
  var seconds = time / 1000;
  return parseInt(seconds);
}

export function isSameCalendarDay(first, second) {
  return (
    first.getDate() === second.getDate() &&
    first.getMonth() === second.getMonth()
  );
}

export function nowMinusDays(days) {
  var ts = Math.round(new Date().getTime());
  let daysInTs = 24 * 3600 * days * 1000;
  var tsYesterday = ts - daysInTs;
  return new Date(tsYesterday);
}

export function nowPlusDays(days) {
  var ts = Math.round(new Date().getTime());
  let daysInTs = 24 * 3600 * days * 1000;
  var tsYesterday = ts + daysInTs;
  return new Date(tsYesterday);
}

export function datePlusDays(date, days) {
  var ts = Math.round(date.getTime());
  let daysInTs = 24 * 3600 * days * 1000;
  var tsYesterday = ts + daysInTs;
  return new Date(tsYesterday);
}

export function nowPlusMinutes(minutes) {
  var ts = Math.round(new Date().getTime());
  return new Date(ts + minutes * 60000);
}

export function getDayMonth(date) {
  var month = date.getUTCMonth() + 1; //months from 1-12
  var day = date.getUTCDate();
  var year = date.getUTCFullYear();
  var newdate = day + '/' + month;
  return newdate;
}

export function isSameAsToday(date) {
  if (!date) {
    return false;
  }
  let now = new Date();
  return now.getDate() === date.getDate() && now.getMonth() === date.getMonth();
}

export function yesterday() {
  var date = new Date();
  var yesterday = date.getDate() - 1;
  date.setDate(yesterday);
  return date;
}
export function isSameCalendarWeek(first, second) {
  return daysBetweenDates(first, second) < 7;
}

export function isSameCalendarMonth(first, second) {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth()
  );
}

export function toLocalDateTimeString(date) {
  var options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return `${date.toLocaleString(
    dateLocale(),
    options
  )} ${date.toLocaleTimeString(dateLocale())}`;
}

export function toBoringDateTimeString(date) {
  var options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  return `${date.toLocaleString(dateLocale())}`;
}
export function timeAgoString(date, isHeb = false) {
  let now = new Date();
  var timeAgo = now.getTime() - date.getTime();
  timeAgo = timeAgo / 1000; //turn in to seconds

  if (timeAgo < 60) {
    return isHeb ? 'עכשיו' : 'Just now';
  } else if (timeAgo < 60 * 60) {
    let minutes = parseInt(timeAgo / 60);
    return isHeb ? ` לפני ${minutes} דקות` : `${minutes} minutes ago`;
    // return `${minutes} minutes ago`;
  } else if (timeAgo < 60 * 60 * 24) {
    let hours = parseInt(timeAgo / (60 * 60));
    return isHeb ? ` לפני ${hours} שעות ` : `${hours} hours ago`;
    // return `${hours} hours ago`;
  } else if (timeAgo < 60 * 60 * 24 * 7) {
    let days = parseInt(timeAgo / (60 * 60 * 24));
    return isHeb ? ` לפני ${days} ימים ` : `${days} days ago`;
    // return `${days} days ago`;
  } else {
    return toLocalDateTimeString(date);
  }
}

export function toDeadlineString(startDate, deadlineDate, isHeb = false) {
  let prefix = '';
  let suffix = '';
  if (startDate.getTime() > deadlineDate.getTime()) {
    prefix = `EXPIRED `;
    suffix = ` ago`;
  }

  var seconds = Math.floor(
    Math.abs(deadlineDate.getTime() - startDate.getTime()) / 1000
  );
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  if (days > 0) {
    return `${prefix}${days} days ${hours} hours ${minutes} minutes${suffix}`;
  } else if (hours > 0) {
    return `${prefix}${hours} hours ${minutes} minute${suffix}`;
  } else if (minutes > 0) {
    return `${prefix}${minutes} minute${suffix}`;
  } else {
    return `${prefix}${seconds} second${suffix}`;
  }
}

export function roundMinutes(date) {
  date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
  date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

  return date;
}
