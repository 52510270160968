const CreationDataType = Object.freeze({
    ANNOUNCEMENT: 0,
    TEXT : 1, //regular text message
    IMAGE : 2, //image (can have optional text)
    VIDEO : 3, //video (can have optional text)
    RECORDING : 4, //voice recording
    REQUEST : 5, //booking request
    POLL : 6,
    THREAD : 7,
    AUDIO : 8, //like songs, podcast
    UKNOWN : 99
  });

  export default CreationDataType