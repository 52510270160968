import React, { useState, useEffect } from 'react';
import styles from './EditProfileCharity.module.scss';
import { withRouter, Redirect } from 'react-router-dom';
import Footer from '../../components/NewFooter/Footer';
import EditProfileHeader from '../EditProfileHeader/EditProfileHeader';
import { useTranslation } from 'react-i18next';
import icon_heart_big from '../Images/icon_heart_big.svg';
import EditProfileCharityExplore from './EditProfileCharityExplore/EditProfileCharityExplore';
import charity1 from '../Images/charity1.png';
import info from '../Images/info.svg';
import icon_trash from '../Images/icon_trash.svg';
import { centerFlexColumn } from '../../utils/bootstrapUtils';
import VLCharityCause from '../../model/vlCharityCause';
import profileManager from '../../managers/profileManager';
import { Container } from '@material-ui/core';
/* 
If the user didn't select any charity
*/
const EmptyCharity = (props) => {
  return (
    <div className={`${styles.text} ${centerFlexColumn()}`}>
      {props.t('editProfileCharity_text')}
      <img src={icon_heart_big} className={styles.img} />
      <div
        className={`${styles.button} ${centerFlexColumn()}`}
        onClick={() => {
          props.setShowExplore(true);
        }}
      >
        {props.t('editProfileCharity_button')}
      </div>
    </div>
  );
};

/* 
If the user selected a charity
*/
const FilledCharity = (props) => {
  const { selectedCharity } = props;

  let profilePicture = selectedCharity.imageURL;
  let name = selectedCharity.name;
  let desc = selectedCharity.info;
  let url = selectedCharity.infoURL;

  return (
    <div className={`${styles.selected} mb-auto`}>
      <img src={profilePicture} className={styles.selectedImage} />
      <div className={styles.selectedTitle}>{name}</div>
      <div className={styles.selectedSubTitle}>{desc}</div>
      <div className={styles.selectedLink}>
        <img src={info} className={styles.infoImg} />
        {url}
      </div>
    </div>
  );
};

const Buttons = (props) => {
  return (
    <div className={styles.buttons}>
      <div
        className={`${styles.saveButton} ${centerFlexColumn()}`}
        onClick={props.onAdd}
      >
        {props.text}
      </div>
      <div className={styles.trash} onClick={props.onDelete}>
        <img src={icon_trash} />
      </div>
    </div>
  );
};
const EditProfileCharity = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedCharity, setSelectedCharity] = useState(props.User.cause);
  const [showExplore, setShowExplore] = useState(false);

  const save = () => {
    // console.log('selectedCharity', selectedCharity);
    alert('save');
  };

  const onAdd = () => {
    // if (props.close) {
    //   props.close();
    // }
    setShowExplore(true);
  };

  const onDelete = async () => {
    const emptyCharity = VLCharityCause.EmptyCharity();
    const updateObj = {
      charity: { emptyCharity },
    };
    props.setShowLoading(true);
    const attemptSave = await profileManager.updateProfileCause(
      updateObj,
      props.hijackedUserId
    );
    props.setShowLoading(false);

    // console.log('attemptSave = ', attemptSave);
    if (attemptSave.success) {
      setSelectedCharity(null);
    } else {
      const errorDetail = attemptSave.error;
      props.onError(null, errorDetail);
    }
  };

  if (showExplore) {
    return (
      <EditProfileCharityExplore
        setShowLoading={props.setShowLoading}
        setShowExplore={setShowExplore}
        selectedCharity={selectedCharity}
        setSelectedCharity={setSelectedCharity}
        {...props}
      />
    );
  }

  const ActiveCharity = () => {
    return selectedCharity ? (
      <FilledCharity
        selectedCharity={selectedCharity}
        isHeb={i18n.language === 'he' ? true : false}
      />
    ) : (
      <EmptyCharity t={t} setShowExplore={setShowExplore} />
    );
  };
  return (
    <div className={`${i18n.language === 'he' ? styles.heb : ''}`}>
      <EditProfileHeader
        title={t('editProfileCharity')}
        hideSave={true}
        close={props.close}
      />

      <div className={`${styles.wrapper} d-flex flex-column align-items-start`}>
        <Container maxWidth="sm">
          <ActiveCharity />
          {selectedCharity ? (
            <Buttons
              text={t('edit_charity_button_change')}
              onDelete={onDelete}
              onAdd={onAdd}
            />
          ) : null}
        </Container>
      </div>
      <div className={styles.footer}>
        <Footer theme={'white'} />
      </div>
    </div>
  );
};

export default withRouter(EditProfileCharity);
