import { isHebrew } from './vlStrings';

export function lclTextAlignmentClass() {
  return isHebrew() ? 'text-right' : 'text-left';
}

export function lclDirection() {
  return isHebrew() ? 'rtl' : 'ltr';
}

export function flippedHorizImage() {
  return isHebrew() ? 'flip-image-horiz' : '';
}

export function lclFlexRow() {
  return isHebrew() ? 'flex-row-reverse' : 'flex-row';
}

export function lclFlexRightFill() {
  return isHebrew() ? 'mr-auto' : 'ml-auto';
}

export function flexWrapAlignment() {
  return isHebrew() ? 'justify-content-end' : 'justify-content-start';
}

export function alignItemsTrailing() {
  return isHebrew() ? 'align-items-end' : 'align-items-start';
}

export function lclMarginRight(spacing) {
  return isHebrew() ? `ml-${spacing}` : `mr-${spacing}`;
}

export function lclMarginLeft(spacing) {
  return isHebrew() ? `mr-${spacing}` : `ml-${spacing}`;
}

export function centerFlexColumn() {
  return 'd-flex flex-column align-items-center justify-content-center';
}

export function centerFlexRow() {
  return 'd-flex flex-row align-items-center justify-content-center';
}
