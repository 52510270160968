import React, { useEffect, useState } from 'react';
import styles from './FinishSignupScreen.module.scss';
import { useTranslation } from 'react-i18next';
import { Container, Button, Grid, Box } from '@material-ui/core';
import { CodeConfirmationScreen } from '../Verification';
import { centerFlexColumn } from '../../utils/bootstrapUtils';
import LoadingOverlay from '../../components/Modal/Loading/LoaderOverlay';
import userManager from '../../managers/userManager';
import Toast from '../../Toasts/Toast';
import ProcessingOverlay from '../../components/Modal/Loading/ProcesingOverlay/ProcessingOverlay';
import VLFlowManager, {
  AuthCompleted,
  AuthFailure,
} from '../../managers/v2/VLFlowManager';
type Providers = 'google' | 'facebook' | 'phone' | 'email';

interface FinishSignupScreenProps {
  onClick?: any;
  onFinishSignup?: any;
}

const FinishSignupScreen: React.FC<FinishSignupScreenProps> = ({
  onClick,
  onFinishSignup,
}) => {
  const { t, i18n } = useTranslation();
  const [showLoading, setShowLoading] = useState(true);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [showError, setShowError] = React.useState(false);

  useEffect(() => {
    const href = window.location.href;
    userManager.finishEmailSignup(
      href,
      (errorMsg?: string, isNewUser?: Boolean) => {
        if (errorMsg) {
          setErrorMsg(errorMsg ?? t('toast_error_default_detail'));
          setShowError(true);
          VLFlowManager.sendFlowAnalytics(new AuthFailure('email'));
        } else {
          onFinishSignup(isNewUser);
          VLFlowManager.sendFlowAnalytics(new AuthCompleted('email'));
        }
      }
    );
  }, []);

  return (
    <div className={`${centerFlexColumn()} h-100`}>
      <Box height="100%">
        <Toast
          error
          show={showError}
          onClick={() => setShowError(false)}
          title={t('auth_error_general_title')}
          detail={errorMsg}
        />
        <ProcessingOverlay
          show={showLoading}
          linear={true}
          title={t('validating_email_title')}
          detail={t('validating_email_detail')}
        />
      </Box>
    </div>
  );
};

export default FinishSignupScreen;
