import EditProfileHeader from '../EditProfile/EditProfileHeader/EditProfileHeader';
import React, { useState, useEffect } from 'react';
import styles from './AddLink.module.scss';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../components/NewFooter/Footer';
import { NewNavbar } from '../components/NewNavbar';
import AddLinkEmpty from './AddLinkEmpty/AddLinkEmpty';
import AddLinkList from './AddLinkList/AddLinkList';
import AddLinkCreate from './AddLinkCreate/AddLinkCreate';
import profile2 from './Images/profile2.svg';
import profile1 from './Images/profile1.svg';
import info_icon from './Images/info_icon.svg';

let firstTimeLoaded = true;
const AddLink = (props) => {
  const { t, i18n } = useTranslation();
  const [links, setLinks] = useState([]); //array of all the links data (example: [{img,title,subTitle,date}])
  const [addLinkOpen, setAddLinkOpen] = useState(false); //If add link screen is visible
  const [addLinkData, setAddLinkData] = useState({
    title: '',
    url: '',
    img: { imgLink: '', imgFile: {} },
    price: { type: '', value: '' },
  }); //the add link data

  const save = () => {
    //when user try to save everything
    console.log('data', addLinkData);
    alert('Save');
  };

  const getLinksFromServer = () => {
    //load the api
    if (!firstTimeLoaded) {
      return;
    }
    firstTimeLoaded = false;

    let data = [
      {
        img: profile1,
        title: 'Check my new merch',
        subTitle: 'from $2,000',
        date: 'Added 12.05.2018',
      },
      {
        img: profile2,
        title: 'Check my new merch',
        subTitle: 'from $2,000',
        date: 'Added 12.05.2018',
      },
    ];

    setLinks(data);
  };
  getLinksFromServer();

  const updateLinkData = (data, kind) => {
    let addLinkDataCopy = Object.assign({}, addLinkData);

    if (kind === 'image') {
      //update the image
      if (data.value && data.files && data.files[0]) {
        //in this case data is the input element

        let reader = new FileReader();

        reader.onload = (e) => {
          addLinkDataCopy.img = {
            imgLink: e.target.result,
            imgFile: data.files[0],
          };
          setAddLinkData(addLinkDataCopy);
        };

        reader.readAsDataURL(data.files[0]);
      }
    } else if (kind === 'title' || kind === 'url') {
      addLinkDataCopy[kind] = data;
      setAddLinkData(addLinkDataCopy);
    } else if (kind === 'price') {
      addLinkDataCopy[kind].type = data;
      setAddLinkData(addLinkDataCopy);
    } else if (kind === 'priceValue') {
      addLinkDataCopy['price'].value = data;
      setAddLinkData(addLinkDataCopy);
    }
  };

  const onClosePressed = () => {
    if (addLinkOpen) {
      setAddLinkOpen(false);
    } else {
      props.close();
    }
  };

  const headerTitle = () => {
    return addLinkOpen
      ? t('editProfile_addLink')
      : t('editProfile_customLinks');
  };
  return (
    <div className={`${i18n.language === 'he' ? styles.heb : ''}`}>
      <EditProfileHeader
        title={headerTitle()}
        save={save}
        close={onClosePressed}
      />
      <div className={styles.wrapper}>
        {!links.length && !addLinkOpen ? (
          <AddLinkEmpty
            openAddLink={() => {
              setAddLinkOpen(true);
            }}
          />
        ) : null}
        {links.length && !addLinkOpen ? (
          <AddLinkList
            links={links}
            openAddLink={() => {
              setAddLinkOpen(true);
            }}
          />
        ) : null}
        {addLinkOpen ? (
          <AddLinkCreate
            save={save}
            data={addLinkData}
            updateLinkData={updateLinkData}
          />
        ) : null}
      </div>
      <Footer theme={'white'} />
    </div>
  );
};

export default withRouter(AddLink);
