import { isProduction } from '../App';
import userManager from '../managers/userManager.js';
import * as constants from '../constants/rhconstants';

export var log = require('loglevel');

export function baseURL(url) {
  // console.log("url = ", url.href);
  const path = url.href;
  if (path.includes('create-app.io') || path.includes('kre8.tv')) {
    return 'https://us-central1-celebrate-io.cloudfunctions.net';
  } else {
    return 'https://us-central1-celebrate-staging-1337.cloudfunctions.net';
  }
}
export function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}
export function baseAPIURL() {
  if (isProduction) {
    return 'https://us-central1-celebrate-io.cloudfunctions.net';
  } else {
    return 'https://us-central1-celebrate-staging-1337.cloudfunctions.net';
  }
}
export function cleanObject(obj) {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj;
}
export function isValidUrl(string) {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }

  return true;
}

export function validURL(str) {
  var regexQuery =
    '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
  var url = new RegExp(regexQuery, 'i');
  return url.test(str);
}

export function bytesToSizeClean(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return 0;
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2);
}

export function bytesToSizeString(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

/**
 * Base URL which should also be used for universal deep linking
 */
export function baseShareURL() {
  if (isProduction()) {
    return 'https://kre8.tv';
  } else {
    return 'https://celebrate-staging-1337.web.app';
  }
}

export function baseAppURL() {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    return 'http://localhost:3000';
  } else if (isProduction()) {
    return 'https://kre8.tv';
  } else {
    return 'https://celebrate-staging-1337.web.app';
  }
}

export function baseAppIdentifieriOS() {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    return 'com.piiymlabs.celebrate-dbg';
  } else if (isProduction()) {
    return 'com.clb.celebrate';
  } else {
    return 'com.piiymlabs.celebrate-dbg';
  }
}
export function baseAppIdentifierAndroid() {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    return 'com.piiym.celebrate.staging'; //dev
  } else if (isProduction()) {
    return 'com.piiym.celebrate'; //release
  } else {
    return 'com.piiym.celebrate.staging'; //dev
  }
}
export function baseAppDeepLinking() {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    return 'kre8email.page.link'; //dev
  } else if (isProduction()) {
    return 'kre8.page.link'; //release
  } else {
    return 'kre8email.page.link'; //dev
  }
}
export function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!
  if (str.length === 0) return false;
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
export var cashify = function (amountIn) {
  var amount = parseFloat(amountIn).toFixed(2);
  var splitAmount = amount.split('.')[0];
  var i = splitAmount.length - 4;

  while (i >= 0) {
    splitAmount = splitAmount.slice(0, i + 1) + ',' + splitAmount.slice(i + 1);
    i = i - 3;
  }
  return '\u00A3' + splitAmount + '.' + amount.split('.')[1];
};
function clone(obj) {
  if (null == obj || 'object' != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}
export function validateEmail(mail) {
  if (
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
}
export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function isNothing(obj) {
  if (!obj) return true;
  if (typeof obj == 'number') return false;
  else if (typeof obj == 'string') return obj.length == 0;
  else if (Array.isArray(obj)) return obj.length == 0;
  else if (typeof obj == 'object')
    return obj == null || Object.keys(obj).length == 0;
  else if (typeof obj == 'boolean') return false;
}
export function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != '') return false;
  }
  return true;
}

export function convertToPrice(price, fromCurrency, toCurrency) {
  if (fromCurrency === toCurrency) {
    return price;
  } else if (fromCurrency === 'USD' && toCurrency === 'ILS') {
    return price * 3.5;
  } else if (fromCurrency === 'ILS' && toCurrency === 'USD') {
    return price / 3.5;
  } else {
    return price;
  }
}
export function symbolFromCurrency(currency) {
  if (currency === 'USD') {
    return '$';
  } else if (currency === 'ILS') {
    return '₪';
  }
}
export function currentUser() {
  return userManager.currentUser;
}

export function queryString(base, obj) {
  var str = '';
  for (var key in obj) {
    if (str != '') {
      str += '&';
    }
    if (obj[key] !== undefined && obj[key] !== null) {
      str += key + '=' + encodeURIComponent(obj[key]);
    }
  }
  return `${base}?${str}`;
}

export function fullCurrentUserParams() {
  let map = {};
  map['platform'] = 'web';
  map['version'] = constants.CR_APP_VERSION;
  return map;
}

export function showAdminTools() {
  if (!currentUser()) {
    return null;
  }
  return currentUser().isAdmin() || currentUser().isManager();
}

export function showAffiliateTools() {
  if (!currentUser()) {
    return null;
  }
  return currentUser().isAffiliate() || showAdminTools();
}

export function toPercentage(value, showDecimals = false) {
  let pct = value * 100;
  return `${pct.toFixed(showDecimals ? 2 : 0)}%`;
}

export const downloadFile = async (myData) => {
  // is an object and I wrote it to file as
  // json
  const fileName = 'file';
  const json = JSON.stringify(myData);
  const blob = new Blob([json], { type: 'application/json' });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + '.json';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function fullName(first, last) {
  return `${first}${last && last.length > 0 ? ' ' : ''}${last}`;
}
