import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { withBookingStage } from '../../../../../shared/hoc';
import {
  onCampaignSubmitted,
  onSubmitCampaignFailed,
} from '../../../../../store/reducers/campaigns/';

import RocketLaunching from './images/rocket-launching.json';
import { Player } from '@lottiefiles/react-lottie-player';
import bookingRequestManager from '../../../../../managers/bookingRequestManager';
import { store } from '../../../../../store';
import userManager from '../../../../../managers/userManager';
import { convertPrice } from '../../../../../model/helpers/payment.helper';
import { useTranslation } from 'react-i18next';
import VLFlowManager, {
  CampaignFlowEventSubmission,
} from '../../../../../managers/v2/VLFlowManager';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
  },
  progressBackground: {
    background: theme.palette.primary.main,
    borderRadius: '50%',
    width: '52px',
    height: '52px',
  },
}));

const SubmissionScreen = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);

  const loadingMessage = t('campaigns_submitting_loader_title');

  useEffect(() => {
    const incrementProgress = (progress) => {
      if (progress > 90) {
        // props.onCampaignSubmitted();
        return;
      }
      setProgress(progress);
      setTimeout(() => {
        incrementProgress(progress + 3);
      }, 100);
    };

    VLFlowManager.sendFlowAnalytics(new CampaignFlowEventSubmission());
    submitCampaign();
    incrementProgress(1);
  }, [props.show]);

  const submitCampaign = async () => {
    setIsSubmitting(true);
    const {
      campaign_name,
      type,
      ocassion,
      ocassion_value,
      fromWho,
      forWho,
      business_name,
      message,
      currency,
      total_price,
      selectedCreators,
    } = store.getState().campaign;

    const creator_booking_details = [];

    //calculate prices
    const currentUserCurrency = userManager.currentUserCurrency();
    selectedCreators.forEach(function (creator) {
      let base_price =
        type === 'personal' ? creator.price : creator.price_business;
      let price = convertPrice(
        base_price,
        creator.currency,
        currentUserCurrency
      );
      creator_booking_details.push({
        price: Number(price),
        user_id: creator.kre8tv_user_id,
      });
    });

    const number_of_creators = selectedCreators.length;

    let params = {
      campaign_name,
      type,
      ocassion: ocassion_value,
      ocassion_title: ocassion,
      fromWho,
      forWho,
      business_name,
      message,
      number_of_creators,
      total_price,
      currency,
      creator_booking_details,
    };

    //update this - in case its for business, the forWho should be the business
    params.forWho =
      params.type === 'business' ? params.business_name : params.forWho;
    //validate

    const result = await bookingRequestManager.submitCampaign(params);
    setIsSubmitting(false);
    if (result.success) {
      props.onCampaignSubmitted();
    } else {
      //uh oh
      props.setShowError(t('campaigns_failure_generic_error'));
      props.onSubmitCampaignFailed(t('campaigns_failure_generic_error'));
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Player
          autoplay
          loop
          src={RocketLaunching}
          style={{
            height: '300px',
            width: '300px',
            transform: `translate(0px, ${progress * -0.75}px)`,
            transition: 'transform 0.15s ease-out',
          }}
        ></Player>
      </Box>

      <Box>
        <Typography variant="h6">
          <Box color="primary.main" textAlign="center" fontWeight="bold">
            {loadingMessage}
          </Box>
        </Typography>
        <Box
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          m={2}
          className={classes.progressBackground}
        >
          <CircularProgress
            size={24}
            variant="determinate"
            value={progress}
            color="white"
            style={{ color: 'white' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default connect(null, { onCampaignSubmitted, onSubmitCampaignFailed })(
  withBookingStage(SubmissionScreen)
);
