import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './SubscriptionFlow.module.scss';
import { useTranslation } from 'react-i18next';
import VLUser from '../../model/vlUser';
import SubscriptionPaywall from '../../components/Paywall/SubscriptionPaywall';
import userManager from '../../managers/userManager';
import * as utils from '../../utils/rhutils';
import LoadingOverlay from '../../components/Modal/Loading/LoaderOverlay';
import storeManager2 from '../../managers/storeManager2';
import MembershipIDScreen from '../../membership/MembershipID/MembershipIDScreen';
import CloseFan from '../../model/closeFan';
import Toast from '../../Toasts/Toast';
import GenericPaywallModal from '../../components/Modal/GenericPaywallModal/GenericPaywallModal';
import { centerFlexColumn } from '../../utils/bootstrapUtils';
import { analytics, AnalyticsEvent } from '../../utils/analytics';
import withIntercom from '../../shared/hoc/with-intercom';
import VLPromotion from '../../model/vlPromotion';
import { PROFILE } from '../../constants/routes';
import VLFlowManager, {
  SubscriptionLanding,
  SubscriptionPaywallAppear,
  SubscriptionAttemptingSubmit,
  SubscriptionSubmitting,
  SubscriptionCompleted,
  SubscriptionFailure,
} from '../../managers/v2/VLFlowManager';

const triggers = [
  'initial',
  'showing paywall',
  'submit',
  'submitting',
  'completed',
  'failed',
];

const LogFlowEvent = (
  trigger:
    | 'initial'
    | 'showing paywall'
    | 'submit'
    | 'submitting'
    | 'completed'
    | 'failed',
  creator_id?: string,
  profileId?: string,
  price?: number,
  currency?: string,
  chargeType?: string
) => {
  const data = {
    trigger: trigger,
    profileId: profileId,
    price: price,
    currency: currency,
    creator_id: creator_id,
    chargeType: chargeType,
    is_connected: userManager.isConnected(),
  };

  utils.log.debug(`logging analytics trigger - ${trigger}`);
  utils.log.debug('logging analytics data -', data);

  analytics.event(
    new AnalyticsEvent(`flow - subscription - ${trigger}`, [data])
  );
};

interface SubscriptionFlowProps {
  profileId: string;
  creator?: VLUser;
  onFlowCanceled?: any;
}

enum SubscriptionFlowStep {
  Initial = 0,
  Payment = 1,
  Purchasing = 2,
  Success = 3,
}

function SubscriptionFlowStepToTrigger(
  step: SubscriptionFlowStep
):
  | 'initial'
  | 'showing paywall'
  | 'submit'
  | 'submitting'
  | 'completed'
  | 'failed' {
  switch (step) {
    case SubscriptionFlowStep.Initial:
      return 'initial';
    case SubscriptionFlowStep.Payment:
      return 'showing paywall';
    case SubscriptionFlowStep.Purchasing:
      return 'submitting';
    case SubscriptionFlowStep.Success:
      return 'completed';
  }
}

interface LoadedCreatorContentProps {
  creator: VLUser;
  setStep: any;
  step: SubscriptionFlowStep;
  setRedirectToProfile: any;
  onFlowCanceled?: any;
  setShowLoading: any;
}
interface PaymentData {
  price: number;
  currency: string;
  chargeType: 'monthly' | 'annually';
}
const LoadedCreatorContent = ({
  creator,
  setStep,
  step,
  setRedirectToProfile,
  onFlowCanceled,
  setShowLoading,
}: LoadedCreatorContentProps) => {
  const [membershipObj, setMembershipObj] = useState<CloseFan | undefined>(
    undefined
  );

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [paymentData, setPaymentData] = useState<PaymentData | undefined>(
    undefined
  );
  const [promotion, setPromotion] = useState<VLPromotion | undefined>(
    undefined
  );

  // console.log('LoadedCreatorContent step:', step);

  const ErrorToast = (props: any) => {
    return showError ? (
      <Toast
        error
        title={props.title}
        detail={props.detail}
        show={showError}
        onClick={() => setShowError(false)}
      />
    ) : null;
  };

  const attemptPurchaseSubscription = async () => {
    if (!paymentData) {
      console.error('no payment data');
      return null;
    }

    updateToStep(SubscriptionFlowStep.Purchasing);
    const result = await storeManager2.purchaseCloseFanSubscription(
      creator,
      paymentData!.price,
      paymentData!.currency,
      paymentData!.chargeType,
      undefined,
      undefined,
      promotion?.id
    );

    if (result.subscription) {
      //show success
      setMembershipObj(result.subscription);
      updateToStep(SubscriptionFlowStep.Success);
    } else {
      logEventForTrigger('failed');
      utils.log.debug('🚨 FAILED PURCHASED SUBSCRIPTION');
      //show error
      updateToStep(SubscriptionFlowStep.Payment);
      setErrorMsg(result.errorMessage ?? 'Error purchasing subscription');
      setShowError(true);
    }
  };

  const handleFlowCancelled = () => {
    if (onFlowCanceled) {
      onFlowCanceled();
    }
    setRedirectToProfile(true);
  };

  const onShowVerse = () => {
    setShowLoading(true);
    setTimeout(() => {
      setRedirectToProfile(true);
    }, 1000);
  };

  const updateToStep = (toStep: SubscriptionFlowStep) => {
    const trigger = SubscriptionFlowStepToTrigger(toStep);
    logEventForTrigger(trigger);
    setStep(toStep);
  };

  const logEventForTrigger = (
    trigger:
      | 'initial'
      | 'showing paywall'
      | 'submit'
      | 'submitting'
      | 'completed'
      | 'failed'
  ) => {
    if (trigger) {
      LogFlowEvent(
        trigger,
        creator.profileId,
        creator.id,
        paymentData?.price,
        paymentData?.currency,
        paymentData?.chargeType
      );
    }

    switch (trigger) {
      case 'showing paywall':
        VLFlowManager.sendFlowAnalytics(
          new SubscriptionPaywallAppear(
            creator.profileId,
            paymentData?.price,
            paymentData?.currency,
            creator.id,
            creator.profileId,
            paymentData?.chargeType
          )
        );
        break;
      case 'submit':
        VLFlowManager.sendFlowAnalytics(
          new SubscriptionAttemptingSubmit(
            creator.profileId,
            paymentData?.price,
            paymentData?.currency,
            creator.id,
            creator.profileId,
            paymentData?.chargeType
          )
        );
        break;
      case 'submitting':
        VLFlowManager.sendFlowAnalytics(
          new SubscriptionSubmitting(
            creator.profileId,
            paymentData?.price,
            paymentData?.currency,
            creator.id,
            creator.profileId,
            paymentData?.chargeType
          )
        );
        break;
      case 'completed':
        VLFlowManager.sendFlowAnalytics(
          new SubscriptionCompleted(
            creator.profileId,
            paymentData?.price,
            paymentData?.currency,
            creator.id,
            creator.profileId,
            paymentData?.chargeType
          )
        );
        break;
      case 'failed':
        VLFlowManager.sendFlowAnalytics(
          new SubscriptionFailure(
            creator.profileId,
            paymentData?.price,
            paymentData?.currency,
            creator.id,
            creator.profileId,
            paymentData?.chargeType
          )
        );
        break;
      default:
        break;
    }
  };

  const NoSubscriptionPlanFound = () => {
    handleFlowCancelled();
    return <Redirect to={`${creator.profileId}`} />;
  };

  const { closeFanPlan } = creator;

  return (
    <div className={`${centerFlexColumn()} vw-100 vh-100 `}>
      {step === SubscriptionFlowStep.Initial ? (
        closeFanPlan ? (
          <SubscriptionPaywall
            closeFanPlan={closeFanPlan}
            creator={creator}
            onSubscribeClicked={() =>
              updateToStep(SubscriptionFlowStep.Payment)
            }
            onClose={() => handleFlowCancelled()}
          />
        ) : (
          <NoSubscriptionPlanFound />
        )
      ) : null}
      {step === SubscriptionFlowStep.Payment ||
      step === SubscriptionFlowStep.Purchasing ? (
        <GenericPaywallModal
          price={
            paymentData?.price ?? creator.defaultSubscriptionPaymentData().price
          }
          currency={
            paymentData?.currency ??
            creator.defaultSubscriptionPaymentData().currency
          }
          type={'subscription'}
          creator={creator}
          onAddPaymentMethodSuccess={attemptPurchaseSubscription}
          onClose={() => updateToStep(SubscriptionFlowStep.Initial)}
          onSubscriptionChargeTypeSelected={(newData: PaymentData) =>
            setPaymentData(newData)
          }
          onPromotionSet={(promotion: VLPromotion) => setPromotion(promotion)}
          onActionButtonClicked={() => {
            logEventForTrigger('submit');
          }}
        />
      ) : null}
      <MembershipIDScreen
        show={
          step === SubscriptionFlowStep.Success && membershipObj !== undefined
        }
        creator={creator}
        onOpenVerse={() => onShowVerse()}
        membershipObj={membershipObj!}
        onClose={() => setRedirectToProfile(true)}
      />
      <ErrorToast title={t('toast_error_title_generic')} detail={errorMsg} />
    </div>
  );
};

const SubscriptionFlow = (props: SubscriptionFlowProps) => {
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState(SubscriptionFlowStep.Initial);
  const [loadedCreator, setLoadedCreator] = useState<VLUser | undefined>(
    undefined
  );
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [redirectToProfile, setRedirectToProfile] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  const isHebrew = i18n.language === 'he';

  useEffect(() => {
    // console.log('use effect', props);

    //log first initial render

    //@todo: deprecate
    LogFlowEvent(
      'initial',
      props.creator?.profileId ?? props.profileId ?? 'none',
      undefined,
      undefined,
      undefined,
      undefined
    );

    //first appearance
    VLFlowManager.sendFlowAnalytics(
      new SubscriptionLanding(
        props.creator?.profileId ?? props.profileId ?? 'none'
      )
    );

    if (props.creator) {
      // console.log('setting loaded creator', props.creator);
      //populate
      populateWithCreator(props.creator);
    } else if (props.profileId !== null) {
      //fetch
      // console.log('fetching user', props.profileId);
      fetchUser(props.profileId);
    }
  }, []);

  const populateWithCreator = (creator: VLUser) => {
    // console.log('shoulld remove loading', creator);
    setShowLoading(false);
    setLoadedCreator(creator);

    //check if should jump to flow
    let queryParams = new URLSearchParams(window.location.search);
    const redirect = queryParams.get('goto');
    if (redirect === 'payment') {
      setStep(SubscriptionFlowStep.Payment);
    }
  };

  /**
   * Redirect to home component
   */
  if (redirectToHome) {
    window.open('', '_self');
  }

  /**
   * Redirect to home component
   */
  if (redirectToProfile) {
    let path = `/${loadedCreator?.profileId}`;
    window.open(path, '_self');
  }

  /**
   * Attempts fetching the creator info
   * @param profileId Profile id of creator
   */
  const fetchUser = async (profileId: string) => {
    utils.log.debug('step 1 - begin fetch:', profileId);
    const result = await userManager.fetchUserFromProfile(profileId);
    utils.log.debug('step 2 - parse result:', result);
    if (result && !result.error && result.closeFanPlan) {
      utils.log.debug('step 3 - populating:', result);
      populateWithCreator(result);
    } else {
      utils.log.debug('step 3 - back to home:', result);
      setRedirectToHome(true);
    }
    return result;
  };

  return (
    <div
      className={`w-100 h-100  ${styles.Container} ${
        isHebrew ? `${styles.heb}` : ''
      }`}
    >
      {loadedCreator ? (
        <LoadedCreatorContent
          creator={loadedCreator}
          setStep={setStep}
          setRedirectToProfile={setRedirectToProfile}
          step={step}
          onFlowCanceled={props.onFlowCanceled}
          setShowLoading={setShowLoading}
        />
      ) : null}
      <LoadingOverlay
        showLoading={showLoading || step === SubscriptionFlowStep.Purchasing}
      />
    </div>
  );
};

const mapStateToProps = (state?: any) => {
  return { isConnected: state.user.isConnected };
};

export default connect(mapStateToProps)(withIntercom(SubscriptionFlow));
