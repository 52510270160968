import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Button,
  IconButton,
  Modal,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel } from 'react-bootstrap';
import { OrderBlockWidget } from '../../Widgets/OrderBlockWidget';
import { Image } from '../../../../image/image.component';

import PersonalIcon from './../images/who_is_for_personal_icon.svg';
import ForMePlaceholderIcon from './../images/who_is_for_personal_for_me_placeholder.svg';
import BusinessIcon from './../images/who_is_for_business_icon.svg';
import { useTranslation } from 'react-i18next';

import {
  updateSelectedEntity,
  updatePrice,
} from '../../../../../store/reducers/ordering';
import { parse } from '../../../../../utils/vlStrings';
import userManager from '../../../../../managers/userManager';
import { toPriceString } from '../../../../../utils/priceUtils';
import { convertToPrice } from '../../../../../utils/rhutils';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '-0.41px',
  },
  entityImage: {
    width: '80px',
    height: '80px',
    borderRadius: '16px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  entitySelected: {
    border: '1px solid #DBCAFE',
  },
  entityDefault: {
    border: '1px solid #3A2D5C',
  },
  pricingLabels: {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'white',
  },
}));

const EntityBox = (props) => {
  const { available, imageUrl, title, selected, icon, onSelect } = props;
  const classes = useStyles();
  return (
    <>
      <Box
        style={{ opacity: available ? 1.0 : 0.3, cursor: 'pointer' }}
        onClick={() => onSelect()}
        className={`${classes.entityImage} ${
          selected ? classes.entitySelected : classes.entityDefault
        }`}
      >
        {icon ? (
          <img
            alt="icon"
            src={imageUrl}
            style={{ width: '24px', height: '24px' }}
          />
        ) : (
          <Image src={imageUrl} />
        )}
      </Box>
      <Box mt={1} color="white" textAlign="center">
        <Typography variant="body2" color="inherit">
          {title}
        </Typography>
      </Box>
    </>
  );
};

const EntityBoxes = (props) => {
  const { user, selectedEntity, updateSelectedEntity, creator } = props;
  const { t } = useTranslation();
  if (!creator) return null;

  const availablePersonal = creator.availableForPersonalizedRequests();
  const availableBiz = creator.availableForBusinessRequests();

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        id="buttons"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box id="for-me">
          <EntityBox
            available={availablePersonal}
            onSelect={() => availablePersonal && updateSelectedEntity('for me')}
            imageUrl={user?.profilePictureURL ?? ForMePlaceholderIcon}
            icon={!user?.profilePictureURL}
            title={t('forMe')}
            selected={selectedEntity === 'for me'}
          />
        </Box>
        <Box id="for-someone-else">
          <EntityBox
            available={availablePersonal}
            onSelect={() =>
              availablePersonal && updateSelectedEntity('someone else')
            }
            icon={true}
            title={t('someoneElse')}
            selected={selectedEntity === 'someone else'}
            imageUrl={PersonalIcon}
          />
        </Box>
        <Box id="for-business">
          <EntityBox
            available={availableBiz}
            onSelect={() => availableBiz && updateSelectedEntity('business')}
            icon={true}
            title={t('forBusiness')}
            selected={selectedEntity === 'business'}
            imageUrl={BusinessIcon}
          />
        </Box>
      </Box>
    </Box>
  );
};

const PriceBoxes = (props) => {
  const { creator } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  if (!creator) return null;

  const availablePersonal = creator.availableForPersonalizedRequests();
  const availableBiz = creator.availableForBusinessRequests();

  const currentUserCurrency = userManager.currentUserCurrency();
  const strPricePersonal = toPriceString(
    currentUserCurrency,
    convertToPrice(creator.price, creator.currency, currentUserCurrency),
    true,
    false
  );
  const strPriceBiz = toPriceString(
    currentUserCurrency,
    convertToPrice(
      creator.priceBusiness,
      creator.currency,
      currentUserCurrency
    ),
    true,
    false
  );

  const priceStrPersonal = creator.availableForPersonalizedRequests()
    ? parse(t('from_price_prefix'), strPricePersonal)
    : t('booking_not_available');
  const priceStrBiz = creator.availableForBusinessRequests()
    ? parse(t('from_price_prefix'), strPriceBiz)
    : t('booking_not_available');

  return (
    <Box
      mt={2}
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent="space-between"
    >
      <Box
        id="personal-pricing"
        width="60%"
        style={{ opacity: availablePersonal ? 1.0 : 0.3 }}
      >
        <Box
          height="2px"
          borderRadius="1px"
          bgcolor="secondary.main"
          mb={1}
        ></Box>
        <Box className={classes.pricingLabels}>{priceStrPersonal}</Box>
      </Box>
      <Box
        id="biz-pricing"
        width="25%"
        style={{ opacity: availableBiz ? 1.0 : 0.3 }}
      >
        <Box height="2px" borderRadius="1px" bgcolor="#AE9BDE" mb={1}></Box>
        <Box className={classes.pricingLabels}>{priceStrBiz}</Box>
      </Box>
    </Box>
  );
};

const OrderWhoIsForBlock = (props) => {
  const {
    user,
    selectedEntity,
    updateSelectedEntity,
    creator,
    isBusiness,
    updatePrice,
    showStepError,
  } = props;
  const classes = useStyles();

  const { t } = useTranslation();
  const currentUserCurrency = userManager.currentUserCurrency();
  const currentUserCurrencySymbol = userManager.currentUserCurrencySymbol();

  useEffect(() => {
    if (creator) {
      //let's set the min price whenever these change
      const minPriceInCreatorCurrency = creator.minPrice(isBusiness);
      const minPriceInCurrentUserCurrency = Number(
        convertToPrice(
          minPriceInCreatorCurrency,
          creator.currency,
          currentUserCurrency
        )
      );
      updatePrice({
        total_price: minPriceInCurrentUserCurrency,
        currency: currentUserCurrency,
      });
    }
  }, [props.creator, props.isBusiness]);

  const title = t('whoIsFor');

  return (
    <OrderBlockWidget showError={showStepError}>
      <Box className={classes.root}>
        <Box m={1} textAlign="center" className={classes.title}>
          {title}
        </Box>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" mt={2}>
        <EntityBoxes
          creator={creator}
          user={user}
          selectedEntity={selectedEntity}
          updateSelectedEntity={updateSelectedEntity}
        />
        <PriceBoxes creator={creator} />
      </Box>
    </OrderBlockWidget>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: state.user.isConnected,
    user: state.user.details,
    selectedEntity: state.ordering.selectedEntity,
    creator: state.ordering.creator,
    isBusiness: state.ordering.type === 'business',
    showStepError: state.ordering.errors.selected_entity === true,
  };
};

export default connect(mapStateToProps, { updateSelectedEntity, updatePrice })(
  OrderWhoIsForBlock
);
